import _ from 'underscore';
import Person from 'edge/quoteandbind/common/models/Person';
import EntityUtil from 'gw-portals-util-js/EntityUtil';
import PolicyLineExtensionConfiguration from 'integration/quoteandbind/PolicyLineExtensionConfiguration';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import Instant from 'edge/platform/time/Instant';
import moment from 'moment';

const PAYMENT_PLAN_ANNUAL = 'Annual';
const PAYMENT_PLAN_INSTALLMENT = 'Monthly';

/**
 * Format Days and Months to be 0-padded.
 * @param {int} value
 * @returns {string}
 * @private
 */
function _formatDate_AND(value) {
    return (`0${value}`).slice(-2);
}

/**
 * @param {String} planType Payment Plan Type
 * @param {String} [periodID] Policy Period Public ID
 * @returns {{}|*} Payment plan for the periodID, or for the selected period if periodID is undefined.
 * @private
 */
function _getPaymentPlan_AND(planType, periodID) {
    if (this.bindData && this.bindData.paymentPlans) {
        if (!periodID) { // Default to selected period payment plan
            const quote = this.quoteData.offeredQuotes.find((q) => q.isCustom === true);
            if (quote) {
                periodID = quote.publicID;
            }
        }
        if (periodID) {
            if(planType === 'Annual') {
                return this.bindData.paymentPlans.find((plan) => (plan.numberOfInstallments === 0) && (plan.periodID === periodID));
            }
            else {
                return this.bindData.paymentPlans.find((plan) => (plan.numberOfInstallments > 0) && (plan.periodID === periodID));
            }
        }
    }
    return {};
}

function Submission(data) {
    if (data) {
        if (typeof data !== 'string') {
            this._constructWithData(data);
        } else {
            this._constructWithLob(data);
        }

        PolicyLineExtensionConfiguration.mixinLobDataExtensions(this);
    } else {
        this._constructNew();
    }
}

Submission.prototype._constructNew = function () {
    this.persons = [];

    const startYear = new Date();
    this.baseData = {
        accountHolder: {
            subtype: 'Person',
            accountHolder: true
        },
        periodStartDate: {
            year: startYear.getFullYear(),
            month: startYear.getMonth(),
            day: startYear.getDate()
        },
        questionSetNames: []
    };
    this.lobData = {};
};

Submission.prototype._constructWithLob = function (lob) {
    this._constructNew();
    this.baseData.productCode = lob;
};


Submission.prototype._constructWithData = function (data) {
    data.persons = [];

    data.baseData.accountHolder = this._forcePerson(data, data.baseData.accountHolder);
    if (data.baseData.accountHolder) {
        if (!data.baseData.accountHolder.subtype) {
            data.baseData.accountHolder.subtype = 'Person';
        }
        data.baseData.accountHolder.accountHolder = true;

        // Sanitisation for data coming from core products. This can cause problems returning to backend
        ['suffix', 'middleName'].forEach((key) => { // ANDIE Don't clear out prefix
            data.baseData.accountHolder[key] = undefined;
        });
    }

    _.extend(this, data);
    this.persons.forEach((p) => {
        ['suffix', 'middleName'].forEach((key) => { // ANDIE Don't clear out prefix
            p[key] = undefined;
        });
    });
    if (data.quoteData && data.quoteData.offeredQuotes) {
        this.quoteData.offeredQuotes = data.quoteData.offeredQuotes.map(quote => {
            // include coverages in quote object
            quote.lobs = _.mapObject(data.lobData, lobdata => {
                if (lobdata.coverages) {
                    return lobdata.coverages;
                }
                if (lobdata.offerings) {
                    const offering = lobdata.offerings.find(offer => offer.branchName === quote.branchName);
                    return offering ? offering.coverages : null;
                }
            });
            return quote;
        });
    }
};

Submission.prototype.createId = function () {
    return EntityUtil.nextId();
};

Submission.prototype.nextId = function () {
    return EntityUtil.nextId();
};

Submission.prototype.createPerson = function (/* options*/) {
    const person = new Person({
        tempId: EntityUtil.nextId()
    });
    this.persons.push(person);
    return person;
};

// UTILS
Submission.prototype.getAccountHolderPerson = function () {
    return this.baseData.accountHolder;
};

Submission.prototype.submissionExists = function () {
    return !!this.quoteID;
};

Submission.prototype.findPerson = function (submission, person) {
    if (_.isEmpty(person)) {
        return null;
    }

    return this._forcePerson(submission, person);
};

Submission.prototype._forcePerson = function (submission, person) {
    let p = person.publicID ? _.findWhere(submission.persons, {
        publicID: person.publicID
    }) : null;
    if (!p) {
        p = new Person(person);
        p.tempId = EntityUtil.nextId();
        submission.persons.push(p);
    }
    return p;
};

/**
 * @returns {string} Period Start Date in format 'dd-mm-yyyy hh:mm:ss'
 */
Submission.prototype.getPSDLongDisplayName_AND = function () {
    const psd = this.baseData.periodStartDate;
    return `${_formatDate_AND(psd.day)}-${_formatDate_AND(psd.month + 1)}-${psd.year} 00:00:00`;
};

/**
 * @returns {string} Period End Date in format 'dd-mm-yyyy hh:mm:ss'
 */
Submission.prototype.getPEDLongDisplayName_AND = function () {
    const ped = Instant.toJSDate(this.baseData.endOfEffectiveDate_itb);
    return `${_formatDate_AND(ped.getDate())}-${_formatDate_AND(ped.getMonth() + 1)}-${ped.getFullYear()} ${_formatDate_AND(ped.getHours())}:${_formatDate_AND(ped.getMinutes())}:${_formatDate_AND(ped.getSeconds())}`;
};

/**
 * @returns {{}} Minimum Period Start Date
 */
Submission.prototype.getMinPeriodStartDate_AND = function () {
    return LocalDateUtil.today();
};

/**
 * @returns {{}} Maximum Period Start Date
 */
Submission.prototype.getMaxPeriodStartDate_AND = function () {
    return LocalDateUtil.today(30);
};

/**
 * @returns {{}} Maximum Renewal Start Date
 */
Submission.prototype.getMaxPeriodRenewalStartDate_AND = function () {
    return LocalDateUtil.today(365);
};

/**
 * @returns {boolean} If selected payment plan is Annual
 */
Submission.prototype.isAnnualPaymentPlan_AND = function () {
    if (this.bindData && this.bindData.selectedPaymentPlan) {
        return this.bindData.selectedPaymentPlan.numberOfInstallments === 0;
    }
};

/**
 * @returns {boolean} If selected payment plan is Installments
 */
Submission.prototype.isInstallmentsPaymentPlan_AND = function () {
    if (this.bindData && this.bindData.selectedPaymentPlan) {
        return this.bindData.selectedPaymentPlan.numberOfInstallments > 0;
    }
};

/**
 * Annual Payment Plan. If periodID is defined, returns the payment plan for that period; if not defined,
 * returns the payment plan for the selected policy period.
 * @param {string} [periodID] PolicyPeriod PublicID
 * @returns {*}
 */
Submission.prototype.getAnnualPaymentPlan_AND = function (periodID) {
    return _getPaymentPlan_AND.call(this, PAYMENT_PLAN_ANNUAL, periodID);
};
/**
 * Monthly Payment Plan. If periodID is defined, returns the payment plan for that period; if not defined,
 * returns the payment plan for the selected policy period.
 * @param {string} [periodID] PolicyPeriod PublicID
 * @returns {*}
 */
Submission.prototype.getInstallmentsPaymentPlan_AND = function (periodID) {
    return _getPaymentPlan_AND.call(this, PAYMENT_PLAN_INSTALLMENT, periodID);
};

/**
 * @returns {boolean} If this is an Aggregator submission and is quoted.
 */
Submission.prototype.isAggsQuoted_AND = function () {
    if (this.baseData) {
        return !!this.baseData.aggregator_itb && this.baseData.periodStatus === 'Quoted';
    }
};

/**
 * @returns {boolean} If period start date of Submission is after 14/10/2020
 */
Submission.prototype.policyEffDateBeforeAndUk = function () {
    if (this.baseData) {
        return moment(this.baseData.periodStartDate).isBefore(moment('20201201').startOf('day'));
    }
};

/**
 * @returns {boolean} If period start date is on or after 07/07/2022 PMBAU-2840 - defacto 5* changes
 */
Submission.prototype.isPolicyStartDateBeforeDefacto5 = function () {
    if (this.baseData) {
        return moment(this.baseData.periodStartDate).isBefore(moment('20220806').startOf('day'));
    }
};

/**
 * @returns {boolean} If period start date is on or after 19/12/2022 PMBAU-2790 - supercover address change
 */
Submission.prototype.isPolicyStartDateBeforeSuperCoverAddressChange = function () {
    if (this.baseData) {
        return moment(this.baseData.periodStartDate).isBefore(moment('20221219').startOf('day'));
    }
};

/**
 * @returns {boolean} If period start date of Submission is after "date" passed in through FirstDayOfDefactoAddedCoverages_itb script parameter from PC
 */
Submission.prototype.isPolEffDateBeforeDefactoDate = function (date) {
    if (this.baseData) {
        return moment(this.baseData.periodStartDate).isBefore(moment(date).startOf('day'));
    }
};
export default Submission;
