import templateStr from 'text!./templates/document-upload.html';

export default [
    '$document', 'httpSettings', 'maxFileUploadSize', 'oAuth', '$log', 'FileUploadUtil',
    ($document, httpSettings, maxFileUploadSize, oAuth, $log, FileUploadUtil) => {
        return {
            restrict: 'A',
            template: templateStr,
            transclude: true,
            scope: {
                metadataTemplate: '&metadata',
                documents: '=',
                disableAutoUpload: '@disableAutoUpload',
                dropZoneSelector: '&',
                widgetText: '@?',
                customClass: '='
            },
            link: (scope) => {
                scope.sessionToken = 'dl';
                scope.serviceName = 'driverlicense';
                scope.buttonText = scope.widgetText || 'platform.documentupload.templates.document-upload.Scan driver license barcode';
                scope.tooltipText = scope.buttonText;
                scope.isDriverLicenseUpload = true;
                scope.allowedFilesTypes = '.png,.jpg';
                const dropZoneElement = FileUploadUtil.createDropZoneElement(scope.dropZoneSelector(), $document);
                scope.options = FileUploadUtil.createOptions(
                    httpSettings.serviceBaseURLs.pc + scope.serviceName,
                    !scope.disableAutoUpload,
                    maxFileUploadSize,
                    dropZoneElement
                );

                FileUploadUtil.addAuthTokenHeader(scope.options, oAuth)
                    .then((options) => {
                        options.dropZone = dropZoneElement;
                        scope.options = options;
                    });

                scope.metadata = [];
                let newDocuments = [];
                let errorUploading = false;

                scope._unbind = function (eventname) {
                    if (eventname in scope._unbind.handlers) {
                        scope._unbind.handlers[eventname]();
                    }
                };
                scope._unbind.handlers = {};

                const bind = (eventname, cb) => {
                    scope._unbind.handlers[eventname] = scope.$on(eventname, cb);
                };

                bind('fileuploadadd', (e, data) => {
                    data.files[0].metadata = FileUploadUtil.createDocMetadata(scope.metadataTemplate(), data, scope.sessionToken);
                });

                bind('fileuploadsubmit', (e, data) => {
                    data.formData = {
                        document: JSON.stringify(data.files[0].metadata)
                    };
                });

                bind('fileuploaddone', (e, data) => {
                    newDocuments.push(data.result.result);
                    e.targetScope.clear(data.files);
                });

                /**
                 * Add the temporarily held documents in newDocuments to the documents array.
                 * We reset the newDocuments array back to zero after we have added it to the documents array.
                 * We do this because old references to previously added documents may remain, and
                 * an exception will be thrown if you try to add the same document twice.
                 */
                bind('fileuploadstop', () => {
                    const result = FileUploadUtil.handleFileUploadStop(scope.documents, newDocuments, errorUploading, true);
                    scope.documents = result.documents;
                    newDocuments = result.newDocuments;
                    errorUploading = result.errorUploading;
                });

                bind('fileuploadfail', () => {
                    errorUploading = true;
                    $log.error('platform.documentupload.DocumentUploadDirective.Unable to scan the driver license barcode. Please make sure that the image you have uploaded displays a barcode.');
                });

                bind('fileuploadprocessfail', () => {
                    errorUploading = true;
                    $log.error('platform.documentupload.DocumentUploadDirective.Processing file failed.');
                });
            }
        };
    }];