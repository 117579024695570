/**
 * Show a cancel confirmation
 * @param {Object} ModalComponent
 * @param {Object} StateUtils
 * @returns {Function}
 */
export default function (ModalComponent, StateUtils) {
    return function (model, {
        title, message, type
    }) {
        title = title || 'platform.flow.angularhelpers.CancelConfirmation.Are you sure you want to cancel?';
        message = message || 'platform.flow.angularhelpers.CancelConfirmation.The information previously entered will be stored as a draft';
        type = type || 'warning';

        const validModel = (model && model.aspects && model.aspects.valid && model.aspects.subtreeValid) ? model : StateUtils.activeDataForState(true).flowModel.snapshot;

        let cancelConfirmedPromise;
        if (type === 'info') {
            const actionLabel = 'platform.modal.ModalService.Yes';
            cancelConfirmedPromise = ModalComponent.showWarning(title, message, null, actionLabel, null, null, null, null, null, null, 'gw-messageInformationIcon').result;
        } else {
            cancelConfirmedPromise = ModalComponent.showWarning(title, message).result;
        }

        return cancelConfirmedPromise.then(() => validModel);
    };
}