import cssUtil from 'gw-portals-util-js/CssUtil';
import _ from 'lodash';
import styles from './GuidanceRecommendations-hashed.scss';
import templateStr from 'text!./GuidanceRecommendations.html';

export default [
    () => {
        return {
            restrict: 'E',
            scope: {
                continueToNextPanel: '&',
                recommendations: '=',
                accountNumber: '=',
                displayInfo: '&',
                isSelectedProduct: '&',
                callback: '&',
                isProductAvailable: '&',
                isProductSelected: '&'
            },
            template: cssUtil.hashTemplate(templateStr, styles),
            controller: ['$scope', 'guidance.DynamicContent', ($scope, dynamicContent) => {
                const THROTTLE_TIME = 300;

                $scope.getState = (recommendation) => {
                    return $scope.isSelectedProduct({product: recommendation}) ? 'Completed' : 'Selectable';
                };

                $scope.selectedProduct = {};
                $scope.updateSelectedProduct = (recommendation) => {
                    $scope.selectedProduct = recommendation.value;
                    $scope.callback({product: recommendation});
                };

                $scope.updateSelectionAndContinueToFullQuote = _.throttle((recommendation) => {
                    if ($scope.isProductAvailable({selectedProduct: recommendation})) {
                        $scope.updateSelectedProduct(recommendation);
                        $scope.transitionToFullQuotePromise = $scope.continueToNextPanel();
                    }
                }, THROTTLE_TIME);

                $scope.getIcon = dynamicContent.getProductCodeIcon;
                $scope.getCustomImage = dynamicContent.getProductCodeImage;
            }]
        };
    }
];