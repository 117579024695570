class InfoBoxCtrl {
    constructor($scope) {
        this.message = $scope.message;
        this.iconPath = $scope.iconPath;
        this.show = $scope.show === undefined ? true : $scope.show;
        this.readonly = $scope.readonly === undefined ? true : $scope.readonly;
    }

    closeInfoBox() {
        this.show = false;
    }
}

InfoBoxCtrl.$inject = ['$scope'];

export default InfoBoxCtrl;