/**
 * Provides segmentation data based on the geolocation suing Google Maps API
 */

import q from 'q';

// get current geolocation
function getCurrentPosition() {
    const currentPositionDefer = q.defer();

    // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
    navigator.geolocation.getCurrentPosition((position) => {
        currentPositionDefer.resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        });
    }, (err) => {
        console.error('An error occurred during Google Maps loading', err);
        currentPositionDefer.reject(err);
    });

    return currentPositionDefer.promise;
}

const provider = {
    $get: (GeoCodeService) => {
        return {
            /**
             * @param {String} compareBy
             * @returns {Promise}
             */
            getSegmentationData(compareBy) {
                return getCurrentPosition()
                    .then(({lat, lng}) => GeoCodeService.getGeoCodedAddr(lat, lng, false))
                    .then((locationData) => locationData[compareBy])
                    .catch(console.error);
            }
        };
    }
};

provider.$get.$inject = ['GeoCodeService'];

export default provider;

