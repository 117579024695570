import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./tile-summary-row.html';
import styles from './tile-summary-row-hashed.scss';

export default [() => ({
    restrict: 'E',
    replace: true,
    transclude: true,
    template: cssUtil.hashTemplate(template, styles)
})];
