import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'underscore';
import CoverageUtil from '../util/CoverageUtil';
import DateUtil from 'gw-portals-util-js/DateUtil';

const fn = ($filter, $scope, StateUtils, submissionViewModel, CustomQuoteService, LoadSaveService, ViewModelService, ViewModelContext, ScrollService_AND, $rootScope,  $q, StaticDocumentsService_AND, SessionService_AND, brandingData, PolicyService) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const draftSubmissionVM = submissionViewModel();
    const brand = brandingData.toLowerCase();
    let setMinDate = DateUtil.currentDate();
    let setMaxDate = DateUtil.currentDate();
    let isVolExcessChanged = false;

    const retrieveDocs = () => $q.all([
            StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
            StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE),
            StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY),
        ])
        .then((result) => {
            $scope.privacyPolicyDoc = result[0];
            $scope.privacyNoticeDoc = result[1];
            $scope.cookiePolicyDoc = result[2];
        });
    const retrieveDocsPromise = retrieveDocs();

    const setHiddenVersion = function (quotes) {
        if (quotes.length === 1) {
            return;
        }
        for (let i = 0; i < quotes.length; i++) {
            if (quotes[i].isCustom === true) {
                switch (i) {
                    case 0:
                    case 1:
                        quotes[2].isHidden = true;
                        break;
                    case 2:
                    default:
                        quotes[0].isHidden = true;
                }
            }
        }
    };

    const getUnadjustedAmount = (amount = {amount: 0}) => Math.floor((amount.amount / 12) * 100) / 100;

    const getMonthlyAmount = (cov) => Object.assign(
        {},
        cov.amount || {currency: 'gbp', amount: 0},
        {amount: getUnadjustedAmount(cov.amount)} // Do not round
    );

    const getBaseOffering = (quotes) => quotes.find((quote) => quote.isCustom === true);

    const updateModel = (qdd) => {
        $scope.page = Object.assign({}, $scope.page);
        // Update scope variables
        if($scope.page.inFlightSelectedPaymentPlan != null && $scope.page.submission.bindData.selectedPaymentPlan.value != null) {
            $scope.page.inFlightSelectedPaymentPlan = $scope.page.submission.bindData.selectedPaymentPlan.value;
        }
        $scope.page.offeredQuotes = qdd.quoteData.offeredQuotes;
        $scope.page.baseOffering = getBaseOffering($scope.page.offeredQuotes);
        $scope.page.voluntaryExcess = qdd.lobData.personalMotor.coverables.pmVehicles[0].voluntaryExcess;
        $scope.page.ncdProtection = qdd.lobData.personalMotor.coverables.pmVehicles[0].ncdProtection;
        // Don't change the object reference as it's not being picked up correctly downstream.
        $scope.page.ncdCosts = Object.assign($scope.page.ncdCosts ? $scope.page.ncdCosts : {}, qdd.lobData.personalMotor.coverables.pmVehicles[0].ncdCosts);
        $scope.page.submission.value.bindData = qdd.bindData;
        $scope.page.submission.value.quoteData = qdd.quoteData;
        $scope.page.submission.value.lobData.personalMotor.offerings = qdd.lobData.personalMotor.offerings;
        // Update
        setHiddenVersion($scope.page.offeredQuotes);
        $scope.page.covAddedWithoutUpdate = false;
        if($scope.page.inFlightSelectedPaymentPlan == null) {
            $scope.page.inFlightSelectedPaymentPlan = $scope.page.submission.bindData.selectedPaymentPlan.value;
        }
        else {
            if($scope.page.inFlightSelectedPaymentPlan.billingId != $scope.page.submission.bindData.selectedPaymentPlan.value.billingId) {
                $scope.page.submission.bindData.selectedPaymentPlan.value = qdd.bindData.paymentPlans.find(paymentPlan => paymentPlan.billingId === $scope.page.inFlightSelectedPaymentPlan.billingId)
            }
        }
        return $scope.page;
    };

    const updateOfferedQuotes = (submission) => {
        // Update scope variables
        $scope.page.offeredQuotes = submission.quoteData.offeredQuotes;
        $scope.page.baseOffering = getBaseOffering($scope.page.offeredQuotes);
        $scope.page.covAddedWithoutUpdate = true;
        return $scope.page;
    };

    const getCoverage = (patternCode, offering = $scope.page.baseOffering) => offering.lobs.personalMotor
        .pmVehicleCoverages[0].coverages.find((cov) => cov.codeIdentifier === patternCode);

    const getCoverageTermFromCoverage = (coverage, covTermPatternCode) =>
        coverage.terms.find((term) => term.patternCode === covTermPatternCode);

    const getCoverageTerm = (covPatternCode, covTermPatternCode, offering = $scope.page.baseOffering) => {
        const coverage = getCoverage(covPatternCode, offering);
        if (coverage) {
            return getCoverageTermFromCoverage(coverage, covTermPatternCode);
        }
    };

    const updateQuote = function () {
        $scope.page.updateInFlight = true;
        return handleQuotingError(()=> CustomQuoteService.updateQuote({
            sessionUUID: $scope.page.sessionUUID,
            quoteID: $scope.page.quoteID,
            coverages: $scope.page.baseOffering.lobs,
            quote: $scope.page.baseOffering,
            voluntaryExcess: $scope.page.voluntaryExcess, // Set in the PmQuoteExcess directive
            periodStartDate: $scope.page.periodStartDate, // Set in the PmQuoteReviewCover directive
            ncdProtection: $scope.page.ncdProtection,
            ncdCosts: $scope.page.ncdCosts
        })
            .then((qdd) => {
                qdd.quoteData.offeredQuotes = qdd.quoteData.offeredQuotes.map(quote => {
                    // from Submission model - include coverages in quote object
                    quote.lobs = _.mapObject(qdd.lobData, lobdata => {
                        if (lobdata.coverages) {
                            return lobdata.coverages;
                        }
                        if (lobdata.offerings) {
                            const offering = lobdata.offerings.find(offer => offer.branchName === quote.branchName);
                            return offering ? offering.coverages : null;
                        }
                    });
                    return quote;
                });
                return updateModel(qdd);
            })
            .finally(() => {
                $scope.page.updateInFlight = false;
            }));
    };

    const updateCoverageSelectionPromise = function (selected, patternCode, submission, quotePriceUpdate, prevSelected) {
        return $q.resolve(CoverageUtil.updateCoverageSelection(selected, patternCode, submission, quotePriceUpdate, prevSelected));
    };

    const updateNcdSelectionPromise = function (selected, submission, ncdCostAmount, quotePriceUpdate, prevSelected) {
        return $q.resolve(CoverageUtil.updatePncdSelection(selected, submission, ncdCostAmount, quotePriceUpdate, prevSelected));
    };

    const updateCoverageTermSelectionPromise = function (chosenTerm, covPatternCode, covTermPatternCode, submission, quotePriceUpdate, prevSelectedTerm) {
        return $q.resolve(CoverageUtil.updateCoverageTermSelection(chosenTerm, covPatternCode, covTermPatternCode, submission, quotePriceUpdate, prevSelectedTerm));
    };

    const updateCoverageSelection = function (selected, patternCode) {
        let prevSelected = false;
        $scope.page.offeredQuotes.forEach((quote) => {
            const coverage = getCoverage(patternCode, quote);
            prevSelected = coverage.selected;
            coverage.selected = selected;
        });
        
        $scope.page.updateInFlight = true;
        return updateCoverageSelectionPromise(selected, patternCode, $scope.page.submission, true, prevSelected)
            .then((qdd) => {
                return updateOfferedQuotes(qdd.value);
            })
            .finally(() => {
                $scope.page.updateInFlight = false;
            });
    };

    const updateNcdProtectionSelection = function(selected, prevSelected) {
        $scope.page.updateInFlight = true;
        return updateNcdSelectionPromise(selected, $scope.page.submission, $scope.page.ncdCosts.amount, true, prevSelected)
            .then((qdd) => {
                return updateOfferedQuotes(qdd.value);
            })
            .finally(() => {
                $scope.page.updateInFlight = false;
            });
    }

    const updateCoverageTermSelection = function (chosenTerm, covPatternCode, covTermPatternCode) {
        let prevSelectedTerm = null;
        $scope.page.offeredQuotes.forEach((quote) => {
            const coverage = getCoverage(covPatternCode, quote);
            coverage.selected = chosenTerm !== null;
            const covTerm = getCoverageTermFromCoverage(coverage, covTermPatternCode);
            prevSelectedTerm = covTerm.chosenTerm;
            covTerm.chosenTerm = chosenTerm;
            covTerm.updated = true; // Required to trigger update in PC
        });
        
        $scope.page.updateInFlight = true;        
        return updateCoverageTermSelectionPromise(chosenTerm, covPatternCode, covTermPatternCode, $scope.page.submission, true, prevSelectedTerm)
            .then((qdd) => {
                return updateOfferedQuotes(qdd.value);
            })
            .finally(() => {
                $scope.page.updateInFlight = false;
            });
    };

    const handleQuotingError = function (promiseFn) {
        return promiseFn()
            .catch((error) => {
                error = error[0];
                if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                    switch (error.baseError.error.data.appErrorCode) {
                        case 602: // GW_UNDERWRITING_ERROR
                        case 631: // AND_HIGHRISK_VALIDATION_ERROR
                            flowModel.jumpToExit('uwError_AND')
                            break;
                        case 608: // GW_ENTITY_VALIDATION_ERROR
                        case 611: // GW_BLOCK_QUOTE_UNDERWRITING_ERROR
                            flowModel.jumpToExit('quoteDeclined_AND', null, {appErrorCode_AND: error.baseError.error.data.appErrorCode});
                            break;
                        case 624: // AND_CUE_ENTITY_VALIDATION_ERROR
                            flowModel.jumpToExit('quoteDeclinedCue_AND');
                            break;
                        default: // Technical error
                            flowModel.jumpToExit('error');
                    }
                } else { // Technical error
                    flowModel.jumpToExit('error');
                }
            });
    };

    const goToQuotePage = () => { // The actual quote page
        $scope.pageView = 'quotePage';
        flowModel.isAllStepsDisabled_AND = false;
        ScrollService_AND.scrollToTop();
    };
    const goToQuotingPage = () => { // Quoting loader animation
        $scope.pageView = 'quoting';
        flowModel.isAllStepsDisabled_AND = true;
    };
    const goToCoverReviewPage = function () { // Review page for cover section
        $scope.page.minPeriodStartDate = setMinDate;
        $scope.page.maxPeriodStartDate = setMaxDate;
        $scope.pageView = 'coverReviewPage';
        flowModel.isAllStepsDisabled_AND = true;
        ScrollService_AND.scrollToTop();
    };

    const setPeriodStartDates = function (minDate, maxDate) {
        setMinDate = minDate;
        setMaxDate = maxDate;
    }

    const retrieveBrandDates = function (draftSubmissionVM) {
        if (brand === 'tbb' || brand === 'itb') {
            PolicyService.getRetiredBrandRedirectDatesITB_AND()
                .then((scriptDate) => {
                    const brandDateObject = scriptDate.find(obj => obj.hasOwnProperty(brand));
                    if (brandDateObject) {
                        const brandDate = moment(brandDateObject[brand]).startOf('day');
                        const brandDateOneDayEarlier = moment(brandDateObject[brand]).startOf('day').subtract(1, 'days');
                        const dropdownMaxDate = draftSubmissionVM.value.getMaxPeriodStartDate_AND() ? moment(draftSubmissionVM.value.getMaxPeriodStartDate_AND()).startOf('day') : moment().startOf('day');

                        if (dropdownMaxDate.isSameOrAfter(brandDate)) {
                            setPeriodStartDates(draftSubmissionVM.value.getMinPeriodStartDate_AND(), { year: brandDateOneDayEarlier.year(), month: brandDateOneDayEarlier.month(), day: brandDateOneDayEarlier.date() });
                        } else {
                            setPeriodStartDates(draftSubmissionVM.value.getMinPeriodStartDate_AND(), draftSubmissionVM.value.getMaxPeriodStartDate_AND());
                        }
                    } else {
                        setPeriodStartDates(draftSubmissionVM.value.getMinPeriodStartDate_AND(), draftSubmissionVM.value.getMaxPeriodStartDate_AND());
                    }
                });
        } else {
            setPeriodStartDates(draftSubmissionVM.value.getMinPeriodStartDate_AND(), draftSubmissionVM.value.getMaxPeriodStartDate_AND());
        }
    }

    const getIsVolExcessChanged = function() {
        return isVolExcessChanged;
    }

    const setIsVolExcessChanged = function (value) {
        isVolExcessChanged = value;
    }

    const initQuotePage = function (quotedSubmissionVM) {
        retrieveBrandDates(quotedSubmissionVM);
        $scope.page = {
            submission: quotedSubmissionVM,
            retrieveDocsPromise,
            updateCoverageSelection, // When selection has to be reflected on all quotes
            updateCoverageTermSelection,
            updateNcdProtectionSelection,
            getIsVolExcessChanged,
            setIsVolExcessChanged,
            updateQuote, // When it doesn't
            getCoverage,
            getCoverageTerm,
            getMonthlyAmount,
            updateInFlight: false,
            quoteID: quotedSubmissionVM.quoteID.value,
            sessionUUID: quotedSubmissionVM.sessionUUID.value,
            goToCoverReviewPage,
            goToQuotePage,
            goToQuotingPage,
            maxPeriodStartDate : setMaxDate,
            minPeriodStartDate : setMinDate
        }; // Known as quoteData downstream
        updateModel(quotedSubmissionVM.value);
    };

    ctrl.init = () => {
        SessionService_AND.activateFunctionTimer();
        if (draftSubmissionVM.baseData.periodStatus.value.code === 'Quoted') {
            StateUtils.activeDataForState(true).isNcdProtectionSelected = draftSubmissionVM.lobData.personalMotor.coverables.value.pmVehicles[0].ncdProtection;
            goToQuotePage();
            initQuotePage(draftSubmissionVM);
        } else {
            goToQuotingPage();
            StateUtils.activeDataForState(true).isNcdProtectionSelected = false;
            handleQuotingError(() => LoadSaveService.saveAndQuoteSubmission(draftSubmissionVM.value)
                .then(submissionDTO => {
                    initQuotePage(ViewModelService.create(submissionDTO, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', ViewModelContext));
                })
                .finally(() => {
                    goToQuotePage();
                }));
        }
    };

    ctrl.error = (data) => {
        flowModel.jumpToExit('error', data);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', 'StateUtils', 'submissionViewModel', 'qnb.CustomQuoteService', 'qnb.LoadSaveService',
    'ViewModelService', 'qnb.ViewModelCtx', 'ScrollService_AND', '$rootScope', '$q', 'StaticDocumentsService_AND', 'SessionService_AND', 'brandingData', 'qnb.PolicyService'];

export default fn;