import paModelExtension from 'conditional?if=quoteandbind_lob&equals=pa!edge/quoteandbind/pa/directives/PaOfferingSelection/defaultQuoteView/modelExtension/MultipleOfferingViewModelExtension';
import hoModelExtension from 'conditional?if=quoteandbind_lob&equals=ho!edge/quoteandbind/ho/directives/HoOfferingSelection/defaultQuoteView/modelExtension/MultipleOfferingViewModelExtension';
import pmModelExtension from 'conditional?if=quoteandbind_lob&equals=pm!edge/quoteandbind/pm/directives/PmOfferingSelection/defaultQuoteView/modelExtension/MultipleOfferingViewModelExtension';
import paCoverageHelper from 'conditional?if=quoteandbind_lob&equals=pa!edge/quoteandbind/pa/directives/PaOfferingSelection/defaultQuoteView/util/PaCoveragesTransformationHelper';
import hoCoverageHelper from 'conditional?if=quoteandbind_lob&equals=ho!edge/quoteandbind/ho/directives/HoOfferingSelection/util/HoCoveragesTransformationHelper';
import pmCoverageHelper from 'conditional?if=quoteandbind_lob&equals=pm!edge/quoteandbind/pm/directives/PmOfferingSelection/defaultQuoteView/util/PmCoveragesTransformationHelper';

function getLobExtension(lobCode) {
    if (lobCode === 'personalAuto') {
        return paModelExtension;
    } else if (lobCode === 'homeowners') {
        return hoModelExtension;
    } else if (lobCode === 'personalMotor') { // ANDIE
        return pmModelExtension;
    }
}

function getCoverageHelperConstructor(lobCode) {
    if (lobCode === 'personalAuto') {
        return paCoverageHelper;
    } else if (lobCode === 'homeowners') {
        return hoCoverageHelper;
    } else if (lobCode === 'personalMotor') { // ANDIE
        return pmCoverageHelper;
    }
}

export default {
    getLobExtension: getLobExtension,
    getCoverageHelperConstructor: getCoverageHelperConstructor
};
