import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./spreadsheetProcessor.html';
import controller from './spreadsheetProcessorCtrl';
import styles from './spreadsheetProcessor-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

/**
 * @ngdoc component
 * @name gw-spreadsheet-processor
 *
 * @description  A component to process the spreadsheet upload/download for CP and BOP buildings during policy transaction
 *
 */
export default {
    controller,
    template: hashedTemplate,
    transclude: true,
    bindings: {
        showManualEntryLink: '<',
        updating: '<'
    }
};
