import ComponentUtil from 'gw-portals-util-js/ComponentUtil';
import appFeatures from 'effective-features!';
import moment from 'moment';

class FooterCtrl {

    constructor($scope, PresentationMetadataHeaderAndFooter, $state, $stateParams, StateUtils) {
        this.setCtrlParam = ComponentUtil.setCtrlParam(this, $scope);
        this.PresentationMetadataHeaderAndFooter = PresentationMetadataHeaderAndFooter;
        this.isMetadataEnabled = appFeatures.footerEnabled;
        this.state = $state;
        this.stateUtils = StateUtils;
        this.setFooterItemsUsingMetadata();
        this.stateParams = $stateParams;
    }

    setFooterItemsUsingMetadata() {
        if (this.isMetadataEnabled) {
            this.PresentationMetadataHeaderAndFooter.getFooterMetadata().then(footerMetadata => {
                if (footerMetadata) {
                    this.setCtrlParam('leftItems', footerMetadata.leftItems);
                    this.setCtrlParam('rightItems', footerMetadata.rightItems);
                    this.setCtrlParam('containsFooterMetadata', true);
                }
            });
        }
    }

    concatFooterItems() {
        if (this.containsFooterMetadata && this.leftItems && this.rightItems) {
            return this.leftItems.concat(this.rightItems);
        }
    }

    ITBLContactDetailsFooter() {
        if (this.state) {
            return this.state.current.name === 'pmContactDetails';
        }
    }

    ITBLQNBConfirmationFooters() {
        if (this.state) {
            return this.state.current.name === 'qnbconfirmation';
        }
    }

    ITBLQNBConfirmationEffDateBefore() {
        if (this.stateParams.quoteSummary) {
            this.startDate = this.stateParams.quoteSummary.baseData.periodStartDate;
            return moment(this.startDate).isBefore(moment('20201201').startOf('day'));
        }
    }

    PolicyEffDateBeforeAndUk() {
        if (this.state.current.name !== 'pmContactDetails' && this.state.current.name !== 'quoteentry' &&
            !!this.stateUtils.activeDataForState(true).model) {
            if (this.stateUtils.activeDataForState(true).model) {
                return this.stateUtils.activeDataForState(true)
                    .model
                    .value
                    .policyEffDateBeforeAndUk();
            }
        }
    }
}

FooterCtrl.$inject = ['$scope', 'PresentationMetadataHeaderAndFooter', '$state', '$stateParams', 'StateUtils'];
export default FooterCtrl;