import templateStr from 'text!./templates/back-button-navigation.html';

export default ['$state', '$filter', 'NavigationService', ($state, $filter, NavigationService) => {
    return {
        restrict: 'EA',
        scope: {},
        template: templateStr,
        link: (scope) => {
            const navHistoryObj = NavigationService.recentHistory();
            scope.previous = (navHistoryObj) ? navHistoryObj.from : null;

            scope.goToPrevious = () => {
                if (scope.previous) {
                    if (scope.previous.params) {
                        $state.transitionTo(scope.previous.state.name, scope.previous.params);
                    } else {
                        $state.transitionTo(scope.previous.state.name);
                    }
                }
            };

            scope.getPreviousLabel = () => {
                let label;
                const prevState = (scope.previous) ? scope.previous.state : null;
                if (prevState) {
                    if (prevState.data) {
                        label = (prevState.data.label) ? prevState.data.label : prevState.name;
                    } else {
                        label = prevState.name;
                    }
                }
                return (label) ? `${$filter('translate')('platform.navigation.NavigationDirective.Back to')} ${label}` : null;
            };
        }
    };
}];
