import _ from 'lodash';
import assert from 'assert';

import DataItem from './DataItem';
import PropertyInfo from './PropertyInfo';

export default class ScheduledItem {
    constructor(scheduleDescriptor, propertyInfos, onChange, itemOnChange) {
        assert(Array.isArray(propertyInfos) && propertyInfos.every(pi => pi instanceof PropertyInfo));

        this._descriptor = scheduleDescriptor;
        this._properties = propertyInfos;
        this._onChange = typeof onChange === 'function' ? onChange : _.noop;
        this._itemOnChange = typeof itemOnChange === 'function' ? itemOnChange : _.noop;
        this._hasScheduleChanged = false;

        this._id = typeof scheduleDescriptor.itemNumber === 'number' ? scheduleDescriptor.itemNumber : null;
        this._items = _(scheduleDescriptor.itemData)
            .map((item, name) => {
                const matchingProp = _.find(this._properties, prop => prop.getName() === name);

                assert(matchingProp, `No matching property found for ${name}`);
                return new DataItem(item, matchingProp, this._itemOnChange);
            })
            .sortBy(item => item.getPropertyInfo().getOrder())
            .valueOf();

        assert(this._items.length > 0, 'Expected scheduled item to have data items');
    }

    getId() {
        return this._id;
    }

    getDataItems() {
        return this._items;
    }

    _findIn(array) {
        assert(Array.isArray(array));
        return array.indexOf(this._descriptor);
    }

    findProperty(dataItem) {
        assert(dataItem instanceof DataItem);
        return _.find(this._properties, prop => dataItem.getName() === prop.getName());
    }

    findItemByProperty(dataItem) {
        assert(dataItem instanceof DataItem);
        return _.find(this._items, item => dataItem.getName() === item.getName());
    }

    describe() {
        return this._descriptor;
    }

    isValid() {
        return this.getDataItems().every(item => item.isValid());
    }

    isDrafted() {
        return this.getDataItems()[0].isDrafted();
    }

    draft() {
        this.getDataItems().forEach(item => item.draft());
    }

    commit() {
        this.getDataItems().forEach(item => item.commit());
        if (_.some(this.getDataItems(), (item) => item.getChangedStatus())) {
            this.getDataItems().forEach(item => item.resetChangedStatus());
            this._hasScheduleChanged = true;
            this._onChange();
        }
    }

    undo() {
        this.getDataItems().forEach(item => item.undo());
    }

    asCommited() {
        if (this._asCommited) {
            return this._asCommited;
        }

        const CurrentScheduledItem = this.constructor;
        this._asCommited = new CurrentScheduledItem(this.describe(), this._properties, this._onChange, this._itemOnChange);
        return this.asCommited();
    }

    getChangedStatus() {
        return this._hasScheduleChanged;
    }

    resetChangedStatus() {
        this._hasScheduleChanged = false;
    }
}