import _ from 'underscore';

function Vehicle(data) {
    if (data) {
        _.extend(this, data);
    }

    if (!angular.isDefined(this.costNew)) {
        this.costNew = {};
    }
}

Vehicle.prototype.getDisplayName = function (defaultName, $filter) {
    let name = this.make || '';
    if (this.model) {
        if (name) {
            name += ' ';
        }
        name += this.model;
    }
    if (this.year) {
        if (name) {
            name += ' ';
        }
        name += this.year;
    }
    if (name.length < 1) {
        name = $filter('translate')('quoteandbind.controllers.this vehicle');
    } else if (this.license !== '0streamlinedquote0') {
        if (this.license) {
            name += ' (';
            name += this.license;
            if (this.licenseState) {
                name += '/';
                name += this.licenseState;
            }
            name += ')';
        }
    }
    return name;
};

Vehicle.prototype.getVehicleStatesForCountry = function (states, countryCode) {
    return _.filter(states, (state) => {
        const countryConstraint = _.find(state.categories, (constraint) => {
            return _.keys(constraint).indexOf('Country') !== -1;
        });
        if (countryConstraint) {
            return countryConstraint.Country === countryCode;
        }
        return false;
    });
};

export default Vehicle;