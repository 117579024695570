export default ['$q', 'JsonRPCService', 'qnb.endpointConfig', 'qnb.xCenterRouteConfig',
    ($q, JsonRPCService, endpointConfig, xCenterRouteConfig) => {

        const xCenterRoute = xCenterRouteConfig;
        const endpoint = 'coverage/coverage';

        return {
            getDependentCoveragesForLob: (jobNumber) => {
                return JsonRPCService.send(xCenterRoute, endpoint, 'getDependentCoveragesForLob', [jobNumber]);
            }
        };
    }
];
