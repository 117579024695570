import styles from './typeahead-hashed.scss';

export default [() => {
    function escapeRegexp(queryToEscape) {
        // Regex: capture the whole query string and replace it with the string that will be used to match
        // the results, for example if the capture is "a" the result will be \a
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    return function (matchItem, query) {
        if (!query) {
            return matchItem;
        }

        // Replaces the capture string with a the same string inside of a "strong" tag;
        return String(matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), `<span class="${styles.gwTypeahead__highlight}">$&</span>`);
    };
}];