import faqConfig from 'portal/config/faq.json';
import facebookConfig from 'portal/config/facebook.json';
import campaignUrlParamsConfig from 'portal/config/campaignUrlParams.json';
import rootTemplate from 'text!portal/views/root_AND.html'; // ANDIE Replaced root.html
import 'angularRouter';
import 'gw-portals-tracking-angular';
import 'gw-portals-tracking-angular/adapters/module';
import AuthConfigUtil from 'gw-portals-auth-js/utils/AuthConfigUtil';
import {getConfigValue} from 'gw-portals-config';
import mediaSidebarConfig from 'portal/config/media-sidebar-config';

// Flag to separate /app (Dev) and distribution builds
const IS_DISTRIBUTION_BUILD = /* DISTRIBUTION_BUILD_START*/false/* DISTRIBUTION_BUILD_END*/;

const eventAdapterModules = ['gw-portals-tracking-angular.adapters'];


/**
 * Module used to define application level configuration parameters that should be
 * passed to capability modules
 */

export default angular.module('portal.config', ['ui.router', 'gw-portals-tracking-angular', ...eventAdapterModules])
    .config(['adapters.googleAnalyticsProvider', 'CredentialsProvider', (googleAnalyticsProvider, credentialsProvider) => {
        googleAnalyticsProvider
            .enablePlugin('ecommerce')
            .configure(credentialsProvider.getCredentials('googleAnalytics'));
    }])
    .config(['adapters.segmentIOProvider', 'CredentialsProvider', (segmentIOProvider, credentialsProvider) => {
        segmentIOProvider.configure(credentialsProvider.getCredentials('segmentIO'));
    }])
    .config(['EventHubProvider', EventAdapterProvider => {
        EventAdapterProvider
        /* Sends every journey event to the console. For debugging purposes only
         .register({
         serviceName: 'console',
         handler: 'log'
         }) */
            .register({
                serviceName: 'googleAnalytics',
                handler: 'trackPageView',
                category: 'tracking',
                eventName: 'pageView'
            })
            .register({
                serviceName: 'googleAnalytics',
                handler: 'trackServerCall',
                category: 'tracking',
                eventName: 'serverCall'
            })
            /* Stores every journey event in the browser local storage. For debugging purposes only
             .register({
             serviceName: 'localstorage',
             handler: 'onEvent'
             }) */
            .register({
                serviceName: 'googleAnalytics',
                handler: 'trackServerCallError',
                category: 'tracking',
                eventName: 'serverCallError'
            })
            .register({
                serviceName: 'googleAnalytics',
                handler: 'trackServerCallError',
                category: 'tracking',
                eventName: 'serverCallError'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackJourney',
                category: 'tracking',
                eventName: 'journey'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackPageView',
                category: 'tracking',
                eventName: 'pageView'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackServerCall',
                category: 'tracking',
                eventName: 'serverCall'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackServerCallError',
                category: 'tracking',
                eventName: 'serverCallError'
            });
    }])

    /**
     * Defines the prefix url where the set of states for a sub module should
     * plug into the portal application
     */
    .constant('state_plugin_config', getConfigValue('appSettings.statePathConfig'))
    .constant('httpSettings', getConfigValue('httpSettings'))
    .constant('defaultLocaleDetails', getConfigValue('userSettings.defaultLocaleDetails'))
    // Used for date of birth dropdowns.
    .constant('AgeAllowedToDrive', getConfigValue('appSettings.AgeAllowedToDrive', 16))
    .constant('MaximumAge', getConfigValue('appSettings.MaximumAge', 100))
    // 50MB. Used to specify the maximum file size for document upload.
    .constant('maxFileUploadSize', getConfigValue('appSettings.maxFileUploadSize', 50000000))
    .config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
        const states = [{
            name: 'root',
            url: '',
            template: rootTemplate,
            abstract: true
        }];

        for (let i = 0; i < states.length; i++) {
            $stateProvider.state(states[i]);
        }

        $urlRouterProvider.when('home', 'quoteentry'); // ANDIE
        $urlRouterProvider.otherwise('quoteentry'); // ANDIE
    }])

    .constant('ASYNC_TRANSLATIONS', true) // Constant that allows us to load translations synchronously (tests/Components Examples)
    .constant('ANGULARI18N_PRESET', '') // Constant that allows us to load Angular Locale from different path (Components Examples)
    .constant('TRANSLATE_PROVIDER_PREFIX', '') // Constant that allows us to load translations from different path (Components Examples)
    .constant('FAQ_CONFIG', faqConfig)
    .constant('FACEBOOK_CONFIG', facebookConfig)
    .constant('CAMPAIGN_PARAMS_CONFIG', campaignUrlParamsConfig)
    .constant('OLDEST_CAR_YEAR', 1908)
    .constant('MEDIA_SIDEBAR_CONFIGURATION', mediaSidebarConfig)
    .constant('oAuth', (() => {
        const clientId = AuthConfigUtil.getPortalClientId('CustomerEngage-Quote_and_Buy', IS_DISTRIBUTION_BUILD);

        return {
            clientId
        };
    })())
    .constant('sessionConfig', getConfigValue('userSettings.sessionConfig', {
        inactivityIntervalMins: 5,
        logoutConfirmationIntervalMins: 1
    }))
    // ANDIE
    .constant('GENDERED_PREFIXES', ['mr', 'mrs', 'ms', 'miss'])
    .constant('MALE_PREFIXES', ['mr'])
    .constant('FEMALE_PREFIXES', ['mrs', 'ms', 'miss']);
