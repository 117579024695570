module.exports = {
	"endpoints": {
		"hostRunTime": "{{host-run-time || ''}}"
	},
	"httpSettings": {
		"timeout": 15000,
		"reloadQueryParamName": "goto",
		"reloadURL": "/index.html",
		"serviceBaseURLs": {
			"pc": "{{endpoints.hostRunTime}}/pc/service/edge/",
			"quote": "{{endpoints.hostRunTime}}/quoteandbuy/quote/",
			"vinlookup": "{{endpoints.hostRunTime}}/quoteandbuy/vehicleinfo/",
			"availability": "{{endpoints.hostRunTime}}/quoteandbuy/quote/",
			"addressLookup": "{{endpoints.hostRunTime}}/quoteandbuy/address/",
			"guidance": "{{endpoints.hostRunTime}}/quoteandbuy/guidance/",
			"propertycode": "{{endpoints.hostRunTime}}/quoteandbuy/propertycode/"
		}
	},
	"userSettings": {
		"sessionConfig": {
			"inactivityIntervalMins": 5,
			"logoutConfirmationIntervalMins": 1
		},
		"defaultLocaleDetails": {
			"countryCode": "GB",
			"mapCenter": {
				"lat": 37.923933,
				"lon": -122.5203
			}
		}
	},
	"appSettings": {
		"statePathConfig": {
			"quote_bind": "root",
			"home": "quoteentry",
			"quickQuote": "quickQuote",
			"faq": "root"
		},
		"AgeAllowedToDrive": 17,
		"MaximumAge": 81,
		"maxFileUploadSize": 50000000
	}
}