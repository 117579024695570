import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'underscore';
/*
 The information being collected on this page is covered by PCI-DSS.
 It is the customer's responsibility to ensure that any security/data protection measures that are required are implemented.
 Data from this page should only ever be transmitted over HTTPS.
 */
const fn = ($filter, $scope, StateUtils, ModalService, submissionViewModel, paymentType) => {
    const ctrl = Object.create(BaseCtrl);
    const submissionVM = submissionViewModel();
    const numberOfPayments = StateUtils.activeDataForState(true).numberOfPayments;
    const chosenQuoteId = StateUtils.activeDataForState(true).chosenQuote;

    ctrl.init = () => {
        submissionVM.bindData.chosenQuote.value = chosenQuoteId;
        $scope.view = submissionVM.bindData;
        $scope.view.paymentDetails.value = $scope.view.paymentDetails.value || {};
        $scope.view.paymentDetails.paymentMethod.value = $scope.view.paymentDetails.paymentMethod.value || paymentType.WIRE;
        $scope.numberOfPayments = numberOfPayments;
        $scope.paymentType = paymentType;

        $scope.paymentPlans = ctrl.getPaymentPlans($scope.numberOfPayments);
        if ($scope.paymentPlans && $scope.paymentPlans.length === 1) {
            // ANDIE - selectedPaymentPlan has been changed to type PaymentPlanType
            $scope.view.selectedPaymentPlan.value = $scope.paymentPlans[0];
        }

        $scope.chosenQuoteData = _.findWhere(submissionVM.quoteData.offeredQuotes.value, {
            publicID: chosenQuoteId
        });
    };

    ctrl.getPaymentPlans = (numPayments) => {
        const allPlans = $scope.view.value.paymentPlans;
        if (numPayments > 1) {
            return allPlans;
        }

        return [
            _.find(allPlans, plan => plan.installment.amount === 0)
        ];
    };

    ctrl.getAmount = (quoteAmount) => {
        return $filter('gwCurrency')(quoteAmount);
    };

    ctrl.next = (nextFn) => {
        if (!$scope.view.selectedPaymentPlan.value) {
            ModalService.showError(
                'quoteandbind.controllers.PaymentDetailsCtrl.Please choose a payment plan',
                'quoteandbind.controllers.PaymentDetailsCtrl.You have not chosen a payment plan'
            );

            nextFn();
            return;
        }

        if ($scope.view.paymentDetails.paymentMethod.value === paymentType.WIRE) { // Remove unused payment data to avoid validation exception
            $scope.view.paymentDetails.creditCardData.value = null;
        } else {
            $scope.view.paymentDetails.bankAccountData.value = null;
        }
        nextFn(submissionVM);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', 'StateUtils', 'ModalService', 'submissionViewModel', 'paymentType'];

export default fn;
