class PropertiesPaginationCtrl {
    constructor($q) {
        this.q = $q;
        this.displayPagination = true;
        this.loaderDefer = this.q.defer();
    }

    getPage(offsetStart, offsetEnd) {
        return (this.allItems ? this.q.when() : this.loaderDefer.promise).then(() => {
            return {
                items: this.allItems.slice(offsetStart, offsetEnd + 1),
                maxNumberOfResults: this.allItems.length
            };
        });
    }

    onNavigation(riskUnits, pageNum, totalPages) {
        this.displayPagination = this.childPagination ? totalPages > 1 : true;
        this.onPaginationNavigation({riskUnits: riskUnits, pageNum: pageNum});
    }

    $onChanges({allItems}) {
        if (allItems
            && allItems.currentValue
            && allItems.currentValue !== allItems.previousValue) {
            this.loaderDefer.resolve();
        }
    }
}

PropertiesPaginationCtrl.$inject = ['$q'];
export default PropertiesPaginationCtrl;
