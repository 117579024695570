import templateStr from 'text!./templates/wizard-content.html';

export default ['StateUtils', function (StateUtil) {
    return {
        replace: true,
        transclude: true,
        template: templateStr,
        require: '^?gwPortalNavBar',
        link(scope, element, attrs, navBarCtrl) {
            function moveToTopOfElement() {
                const alignElementToTopOfBrowserWindow = true;
                element[0].scrollIntoView(alignElementToTopOfBrowserWindow);
            }

            StateUtil.subscribeToStateTransitions(moveToTopOfElement);

            scope.$on('$destroy', () => {
                StateUtil.unsubscribeFromStateTransitions(moveToTopOfElement);
            });
            /* scope.sidebarMenuVisible = () => navBarCtrl.isSideBarMenuVisible();
            if (navBarCtrl) {
                scope.hideSidebarMenu = () => {
                    navBarCtrl.hideSideBarMenu();
                };
            }*/
        }
    };
}];
