import WizardContentDirective from './WizardContentDirective';
import WizardSidebarRibbonDirective from './WizardSidebarRibbonDirective';
import WizardStepProgressDirective from './WizardStepProgressDirective';
import WizardPageActionsDirective from './WizardPageActionsDirective';
import NavBarWizardBaseDirective from './NavBarWizardBaseDirective';
import 'angularRouter';

export default angular.module('edge.platform.widgets.wizard', ['edge.platform.base', 'ui.router'])
    .directive('gwWizardContent', WizardContentDirective)
    .directive('gwWizardSidebarRibbon', WizardSidebarRibbonDirective)
    .directive('gwWizardStepProgress', WizardStepProgressDirective)
    .directive('gwWizardPageActions', WizardPageActionsDirective)
    .directive('gwNavBarWizardBase', NavBarWizardBaseDirective);
