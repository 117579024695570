import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./TableFilter.html';
import styles from './TableFilter-hashed.scss';
import controller from './TableFilterCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        data: '<',
        filterableProperties: '<',
        onQuery: '<',
        placeholderText: '@'
    },
    controller
};
