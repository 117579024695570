export default ['$q', 'JsonRPCService', 'policyjob.endpointConfig', 'policyjob.xCenterRouteConfig', ($q, JsonRPCService, endpointConfig, xCenterRouteConfig) => {

    const xCenterRoute = xCenterRouteConfig;
    const endpoint = endpointConfig.availability;

    return {
        checkAvailabilityBasedOnPostalCode: (availabilityRequestDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'isProductAvailableBasedOnPostalCode', [availabilityRequestDTO]).then(
                (availabilityResponseDTO) => {
                    return deferred.resolve(availabilityResponseDTO);
                },
                (error, status, headers) => {
                    return deferred.reject([error, status, headers]);
                }
            );
            return deferred.promise;
        },

        checkAvailabilityBasedOnAddress: (availabilityRequestDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'isProductAvailableBasedOnAddress', [availabilityRequestDTO]).then(
                (response) => {
                    const availabilityResponseDTO = response;
                    return deferred.resolve(availabilityResponseDTO);
                },
                (error, status, headers) => {
                    return deferred.reject([error, status, headers]);
                }
            );
            return deferred.promise;
        }
    };
}];