import RouteConfig from './config/RouteConfig';
import TransactionWizardTitle from './transactionWizardTitle';

import 'angularRouter';
import 'edge/platform/map/module';
import 'edge/platform/base/module';
import 'edge/platform/widgets/modal/module';
import 'edge/platform/widgets/inputs/module';
import 'edge/platform/widgets/loading/module';
import 'edge/platform/contacts/module';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';
import 'edge/platform/flow/module';
import 'edge/platform/widgets/wizard/module';
import 'edge/platform/widgets/datetile/module';
import 'portalConfig';
import 'edge/policyjob/app';

import Quote from './components/quote';
import Payment from './components/payment';
import SpreadsheetProcessor from './components/spreadsheetProcessor';
import SpreadsheetProcessorResult from './components/spreadsheetProcessorResult';
import PaymentWithPaymentPlan from './components/paymentWithPaymentPlan';

export default angular.module('edge.transaction.common.app', [
    'portal.config',
    'edge.platform.base',
    'edge.platform.widgets.inputs',
    'ui.router',
    'edge.platform.map',
    'edge.platform.widgets.loadingMsg',
    'edge.platform.widgets.modal',
    'edge.platform.contacts',
    'gw-portals-viewmodel-angular.aspects',
    'edge.platform.widgets.basicinputs',
    'edge.platform.flow',
    'edge.platform.widgets.wizard',
    'edge.platform.widgets.datetile',
    'edge.policyjob.app'
])
    .component('gwTransactionWizardTitle', TransactionWizardTitle)
    .component('gwQuote', Quote)
    .component('gwPayment', Payment)
    .component('gwSpreadsheetProcessor', SpreadsheetProcessor)
    .component('gwSpreadsheetProcessorResult', SpreadsheetProcessorResult)
    .component('gwPaymentWithPaymentPlan', PaymentWithPaymentPlan)

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new RouteConfig(RootStateConfig);
        for (const stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, states[stateName]);
            }
        }
    }]);