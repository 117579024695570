export default (directiveName, direction) => {
    return ['$parse', function ($parse) {
        return {
            restrict: 'EA',
            template: '',
            link(scope, element, attr) {
                /*
                 *   Due to AP-953 we cannot use the ng-touch library for swipe, this directive tries to mimic the
                 *   behaviour of the angular swipe directives as closely as possible. If angular-touch ever get rid
                 *   of their override of the ng-click directive, as proposed here https://github.com/angular/angular.js/pull/12317
                 *   this directive could be replaced by the angular-touch library.
                 */
                const MAX_VERTICAL_DISTANCE = 75;
                const MIN_HORIZONTAL_DISTANCE = 30;
                const MAX_VERTICAL_RATIO = 0.3;

                /*
                 *   Parse the swipe action using the directive name attribute. We do not pass the swipe action here
                 *   using a scope variable as an element cannot be bound to multiple isolated scopes.
                 *   Eg. gw-swipe-left="closePanel()" would mean that directiveName would equal gwSwipeLeft and the
                 *   result of attr['gwSwipeLeft'] would be the string "closePanel()". We pass this string to the
                 *   $parse function which parses the Angular expression and returns a function which will execute
                 *   that expression when called. This the code that the user expects to be run when a valid swipe
                 *   occurs.
                 */
                const swipeAction = $parse(attr[directiveName]);
                let startPosition;
                let isSwipeActive = false;

                $(element).on('touchstart', startSwipe)
                    .on('touchcancel', cancelSwipe)
                    .on('touchend', endSwipe);

                function startSwipe(event) {
                    startPosition = getCoordinates(event);
                    isSwipeActive = true;
                }

                function cancelSwipe() {
                    // Notifications, calls, etc can cancel a swipe
                    isSwipeActive = false;
                }

                function endSwipe(event) {
                    const endPosition = getCoordinates(event);
                    if (isSwipeActive && isSwipeValid(startPosition, endPosition)) {
                        /*
                         *   If a valid swipe occurs we call the swipeAction function. This function is the result of
                         *   parsing an angular expression which was passed into the directive as an attribute. We
                         *   use an $apply here as this code will be executed outside the scope of angular. Using
                         *   $apply will execute this function and then call $digest for us. This is approach used
                         *   by the angular.
                         */
                        scope.$apply(() => swipeAction(scope, {
                            $event: event
                        }));
                    }
                    isSwipeActive = false;
                }

                function isSwipeValid(start, end) {
                    // Identical to ng-touch getCoordinates function.
                    if (!start || !end) {
                        return false;
                    }
                    const deltaY = Math.abs(end.y - start.y);
                    const deltaX = (end.x - start.x) * direction;
                    /*
                     *   DeltaY is the length of the vertical swipe
                     *   DeltaX is the length of the horizontal swipe (which can be negative if it is a left swipe)
                     *   we multiply this by a direction above as valid swipes will only have a positive deltaX.
                     *   For a swipe to be valid its vertical distance must less than our vertical threshold, the
                     *   deltaX after being multiplied by a direction must be greater than 0, the horizontal length
                     *   must be greater than our minimum horizontal threshold and the vertical ratio must be less
                     *   than our max vertical ratio.
                     */
                    return deltaY < MAX_VERTICAL_DISTANCE && deltaX > 0 && deltaX > MIN_HORIZONTAL_DISTANCE &&
                        deltaY / deltaX < MAX_VERTICAL_RATIO;
                }

                function getCoordinates(event) {
                    // Identical to ng-touch getCoordinates function.
                    const originalEvent = event.originalEvent || event;
                    const touches = originalEvent.touches && originalEvent.touches.length ? originalEvent.touches : [originalEvent];
                    const eventTouch = (originalEvent.changedTouches && originalEvent.changedTouches[0]) || touches[0];
                    return {
                        x: eventTouch.clientX,
                        y: eventTouch.clientY
                    };
                }
            }
        };
    }];
};
