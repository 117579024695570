import checkSegmentationOption from './CheckSegmentationOption';

export default ($element, experimentResultPromise) => {
    const optionsEls = $element.find('> gw-segmentation-option'); // child directives in html on init
    const optionsModels = []; // children directives models store

    function showDefaultOptionIfNothingMatchesVariant() {
        const anyOptionShown = optionsModels.some(option => !option.isHidden);
        const defaultOption = optionsModels.find(option => option.isDefault);

        if (!anyOptionShown && defaultOption) {
            defaultOption.show();
        }
    }

    return {
        registerSegmentOption: (option) => {
            experimentResultPromise.then(variantValue => {
                if (checkSegmentationOption(option.optionValues, variantValue)) {
                    option.show();
                }
            }).finally(() => {
                optionsModels.push(option);
                if (optionsModels.length === optionsEls.length) {
                    // all child elements have registered check if we need to show the default option
                    showDefaultOptionIfNothingMatchesVariant();
                }
            });
        }
    };
};