import BaseCtrl from 'edge/platform/base/BaseCtrl';
import removeDriverConfirmation from 'text!../views/pm-remove-driver-confirmation.html';
import moment from 'moment';

const fn = ($scope, StateUtils, submissionViewModel, brandingData, ModalService) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submissionVM = submissionViewModel();
    const pmVM = submissionVM.lobData.personalMotor;
    const periodStartDate = moment(submissionVM.baseData.periodStartDate.value);
    let modalInstance = undefined;

    $scope.contactPhone = submissionVM.bindData.contactPhone;

    if ($scope.contactPhone !== undefined) {
        if ($scope.contactPhone.value !== null && !$scope.contactPhone.aspects.valid) {
            $scope.contactPhone.value = '07000000000';
        }
    }

    $scope.boxDeclaration = submissionVM.bindData.boxDeclaration_itb;

    if ($scope.boxDeclaration !== undefined) {
        if ($scope.boxDeclaration.value !== null && !$scope.boxDeclaration.aspects.valid) {
            $scope.boxDeclaration.value = true;
        }
    }

    ctrl.init = () => {
        $scope.page = {
            driversVM: pmVM.coverables.pmDrivers,
            policyholderVM: pmVM.coverables.pmDrivers.findFirstElement(pmVM.coverables.pmDrivers.value.find((driver) => driver.isPolicyHolder))
        };
    };

    ctrl.canAddDriver = () => $scope.page.driversVM.length < 5; // Maximum is PH + 4 drivers

    ctrl.addDriver = () => {
        const data = StateUtils.activeDataForState(true);
        flowModel.snapshot = data.model;
        flowModel.jumpToStep('pmDriverDetails', data.model, {addNamedDriver: true});
    };

    ctrl.removeDriver = (scope) => {
        pmVM.coverables.value.removeDriver(scope.driver);
        if (scope.driver.mainDriver === true) { // If we're removing the main driver, set PH to main driver
            pmVM.coverables.pmDrivers.value.forEach((d) => {
                d.mainDriver = d.isPolicyHolder === true;
            });
        }
        modalInstance.close();
    };

    ctrl.setMainDriver = (newMainDriver) => {
        $scope.page.driversVM.forEach(({value: driver}) => {
            driver.mainDriver = driver === newMainDriver;
        });
    };

    ctrl.isMainDriverValid = () => {
        if (brandingData !== 'tbb') {
            return true;
        }
        const mainDriverVM = pmVM.coverables.pmDrivers.findFirstElement(pmVM.coverables.pmDrivers.value.find((driver) => driver.mainDriver));        
        const ageOfMainDriver = periodStartDate.diff(moment(mainDriverVM.dateOfBirth.value), 'years');
        return (ageOfMainDriver >= 17 && ageOfMainDriver <= 35);
    };

    ctrl.showRemoveDriverConfirmation = (driver) => {
       modalInstance = ModalService.showSimpleDialog(removeDriverConfirmation, {
             driver: driver
           }, {
             removeDriver: ctrl.removeDriver,
             close: $scope.closeModal
           }, null, {
              fullscreen: true,
              backdrop: true,
              windowClass: 'and-modal-confirmation'
           });
    };

    $scope.next = (callback) => {
        $scope.form.submitted = true;
        if (ctrl.isMainDriverValid() !== true) {
            return callback();
        }
        callback(submissionVM);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'StateUtils', 'submissionViewModel', 'brandingData', 'ModalService'];

export default fn;
