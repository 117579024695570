import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './GuidanceQuestions-hashed.scss';
import templateStr from 'text!./GuidanceQuestions.html';
import _ from 'lodash';

export default [
    () => {
        return {
            restrict: 'E',
            scope: {
                questions: '=',
                isPanelValid: '&',
                continueToNextPanel: '&',
                isActive: '&',
                displayInfo: '&',
                transitionFn: '&'
            },
            template: cssUtil.hashTemplate(templateStr, styles),
            controller: ['$scope', 'guidance.DynamicContent', ($scope, dynamicContent) => {
                $scope.toggleState = (question) => {
                    question.answer.value = !question.answer.value;
                };

                $scope.getIcon = dynamicContent.getQuestionIcon;

                $scope.getState = (question) => {
                    return question.answer.value ? 'completed' : 'selectable';
                };

                $scope.transformQuestions = _.memoize(transformQuestions);

                function transformQuestions(questionList) {
                    return questionList.map(question => {
                        return {
                            model: question.answer,
                            icon: $scope.getIcon(question.code.value),
                            selectText: question.text.value,
                            helpText: question.helpText.value
                        };
                    });

                }
            }]
        };
    }
];