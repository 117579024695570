// The gw-pl-accordion-group directive indicates a block of html that will expand and collapse in an accordion

import templateStr from 'text!edge/platform/widgets/accordion/directives/templates/accordion-group.html';

export default [function () {
    return {
        require: ['^gwPlAccordion', 'gwPlAccordionGroup'], // We need this directive to be inside an accordion
        restrict: 'EA',
        transclude: true, // It transcludes the contents of the directive into the template
        replace: true, // The element containing the directive will be replaced with the template
        template: templateStr,
        scope: {
            gwPlHeading: '@', // Interpolate the heading attribute onto this scope
            isOpen: '=?',
            initialIsOpen: '&',
            initialScrollToOpen: '&',
            isDisabled: '=?',
            validationForm: '=',
            flavor: '@'
        },
        controller: ['$scope', '$element', '$rootElement', function ($scope, $element, $rootElement) {
            this.initialScroll = () => {
                if ($scope.initialScrollToOpen() && $scope.isOpen) {
                    $scope.$applyAsync(() => {
                        $rootElement.scrollTop($element.offset().top);
                    });
                }
            };

            this.setHeading = (element) => {
                this.gwPlHeading = element;
            };
        }],
        link: (scope, element, attrs, ctrls, transcludeFn) => {
            const [accordionCtrl, accordionGroupCtrl] = ctrls;

            if (typeof scope.initialIsOpen() !== 'undefined') {
                scope.isOpen = scope.initialIsOpen();
            }
            accordionGroupCtrl.initialScroll();

            transcludeFn((clonedMarkup, childScope) => {
                element.find('[transclude-here]').append(clonedMarkup);
                childScope.isAccordionOpen = () => scope.isOpen;
            });

            scope.flavor = scope.flavor || 'default';
            accordionCtrl.addGroup(scope);

            scope.$watch('isOpen', (value) => {
                if (value) {
                    accordionCtrl.closeOthers(scope);
                }
            });

            scope.isSubmittedAndValid = (aValidationForm) => {
                return aValidationForm.invalid && aValidationForm.submitted;
            };

            scope.toggleOpen = () => {
                if (!scope.isDisabled) {
                    scope.isOpen = !scope.isOpen;
                }
            };
        }
    };
}];