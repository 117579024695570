import _ from 'underscore';

function createChoiceTerm(model, onChange) {
    const res = {
        'hasOptions': true,
        'options': model.options
    };

    Object.defineProperty(res, 'value', {
        'get': () => {
            return model.chosenTerm;
        },
        'set': (term) => {
            model.chosenTerm = term;
            model.updated = true;
            onChange(model);
        }
    });

    return res;
}

function setChoosenTermForBoolean(model, value) {
    model.chosenTerm = parseBooleanToString(value);
    model.directBooleanValue = value;
    model.updated = true;
}

function createBooleanTerm(model, prop, onChange) {
    let value = model[prop] ? model[prop] : false;
    setChoosenTermForBoolean(model, value);

    const res = {
        'hasOptions': true,
        'options': model.options,
        'valueType': model.valueType
    };

    Object.defineProperty(res, 'value', {
        'get': () => {
            return isTrue(value);
        },
        'set': (term) => {
            value = term;
            setChoosenTermForBoolean(model, value);
            onChange(model);
        }
    });

    return res;
}

function isTrue(val) {
    return (val === 1 || val === 'true' || val === 'Yes' || val === true);
}

function parseBooleanToString(val) {
    return isTrue(val) ? 'true' : 'false';
}

function createTermInput(model, prop, onChange) {
    let newValue = model[prop];

    const res = {
        'hasOptions': false,
        'applyNewValue': () => {
            if (newValue === model[prop]) {
                return;
            }

            if (newValue === '') {
                model[prop] = null;
            } else {
                model[prop] = newValue;
            }

            model.updated = true;
            onChange(model);
        },
        'valueType': model.valueType
    };

    Object.defineProperty(res, 'newValue', {
        'get': () => {
            return newValue;
        },
        'set': (nv) => {
            newValue = nv;
        }
    });

    Object.defineProperty(res, 'value', {
        'get': () => {
            return model[prop];
        },
        'set': (nv) => {
            newValue = nv;
            model[prop] = nv;
            model.updated = true;
            onChange(model);
        }
    });

    return res;
}

/** Model of the coverage term. */
export default {
    /**  Creates a new coverage term with the underlying coverage model. Return a new term with the following
     * functions and properties:
     *
     * <dl>
     *     <dt>name : String</dt><dd>Name of the coverage term</dd>
     *     <dt>options : Array</dt><dd>Optional array of the term options.</dd>
     *     <dt>hasOptions : Boolean</dt>
     *          <dd>Flag indicating that options are present and should be used for the term.</dd>
     *     <dt>value : String</dt><dd>Read-write property of the coverage's value.</dd>
     *     <dt>updating : () => Boolean</dt><dd>Flag set when this coverage is updating.</dd>
     * </dl>
     *
     * @param {Object} $translateFilter
     * @param {Object} model coverage model.
     * @param {Function} loading to retrieve current loading status.
     * @param {Function} [onChange] function to invoke on the term change.
     * @param {Function} [disable] function to check if the term input should be disabled.
     * @param {Map} dependentCoverages Map of dependent coverages that may require a sync on cov change.
     * @returns {Object}
     */
    'create': ($translateFilter, model, loading, onChange, disable, dependentCoverages) => {
        onChange = onChange || _.noop;
        disable = disable || _.constant(false);
        dependentCoverages = dependentCoverages || new Map();

        let base;
        switch (model.valueType) {
            case undefined:
            case 'TYPEKEY':
                base = createChoiceTerm(model, onChange);
                break;
            case 'bit':
                if (model.options.length > 2) {
                    base = createChoiceTerm(model, onChange);
                } else {
                    base = createBooleanTerm(model, 'directBooleanValue', onChange);
                }
                break;
            case 'shorttext':
                base = createTermInput(model, 'directStringValue', onChange);
                break;
            case 'datetime':
                base = createTermInput(model, 'directDateValue', onChange);
                break;
            default:
                base = createTermInput(model, 'directValue', onChange);
                break;
        }

        base.publicID = model.publicID;
        base.name = model.name;
        base.coveragePublicID = model.coveragePublicID;
        base.type = model.type;
        base.disable = disable;
        base.hasDependencies = (dependentCoverages.has(model.type) || dependentCoverages.has(model.coveragePublicID));

        Object.defineProperty(base, 'updating', {
            'get': loading || _.constant(false)
        });

        base.aspects = { // Configure aspects, so that term is usable directly with ctrl-group
            required: model.required,
            minVal: model.directValueMin,
            maxVal: model.directValueMax,
            get valid() {
                if (!this.required && !this.minVal && !this.maxVal) {
                    return true;
                }

                let invalid = false;
                if (this.required) {
                    // Boolean(val) won't work because 0 is valid
                    invalid = this.validationRequiredness;
                }

                // if not invalid after required check, test min max values
                if (!this.validationRequiredness && (this.minVal || this.maxVal)) {
                    invalid = this.validationMinMaxValue;
                }

                return !invalid;
            },
            get validationMessages() {
                if (this.valid) {
                    return [];
                }

                return this.validationRequiredness ? [$translateFilter('policycommon.This is a required field')] : [this.validationMessageMinMaxValue];
            },
            get validationRequiredness() {
                return _.isUndefined(base.value) || _.isNull(base.value) || base.value === '';
            },
            get validationMinMaxValue() {
                return (base.value < this.minVal || base.value > this.maxVal);
            },
            get validationMessageMinMaxValue() {
                if (this.minVal && this.maxVal) {
                    return $translateFilter('policycommon.The value must be between', {minValue: this.minVal, maxValue: this.maxVal});
                } else if (this.minVal && base.value < this.minVal) {
                    return $translateFilter('policycommon.The minimum allowed value is', {value: this.minVal});
                }

                return $translateFilter('policycommon.The maximum allowed value is', {value: this.maxVal});
            }
        };

        return Object.freeze(base);
    }
};