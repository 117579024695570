import CreditCardUtil from './CreditCardUtil';
import templateStr from 'text!./templates/credit-card-details.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,

        scope: {
            creditCardDetails: '=gwPlCreditCardDetails'
        },

        controller: ['$scope', (scope) => {
            scope.creditCardDetails.value = scope.creditCardDetails.value || {};
            scope.issuers = scope.creditCardDetails.creditCardIssuer.aspects.availableValues;
            scope.creditCardDetails.creditCardIssuer.value = scope.creditCardDetails.creditCardIssuer.value || scope.issuers[0];

            scope.cardMask = {};

            // watcher to update the input mask on the card number field depending on issuer.
            scope.$watch('creditCardDetails.creditCardIssuer.value', () => {
                const newValue = scope.creditCardDetails.creditCardIssuer.value;
                if (!newValue) {
                    return;
                }

                const newMask = CreditCardUtil.getInputValidatorForIssuerCode(newValue.code);
                scope.cardMask = newMask;

                const maskLength = newMask.replace(/-/g, '').length; // Get the length of the string of digits unformatted
                const cardNumberValue = scope.creditCardDetails.creditCardNumber.value;
                if (cardNumberValue && cardNumberValue.length > maskLength) {
                    scope.creditCardDetails.creditCardNumber.value = cardNumberValue.substring(0, maskLength); // Truncate if the previous value is too big
                }
            });
        }]
    };
}];
