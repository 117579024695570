import templateStr from 'text!./pm-quote.html';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '=',
            buy: '<',
            error: '<'
        },
        template: templateStr,
        controller: ['$scope', '$q', 'brandingData', 'StaticDocumentsService_AND', '$state', 'ModalService', 'qnb.PolicyService', '$rootScope', '$window', 'EventHub', ($scope, $q, brandingData, StaticDocumentsService_AND, $state, ModalService, PolicyService, $rootScope, $window, EventHub) => {
            const submission = $scope.quoteData.submission.value;
            $scope.COVERAGES = $scope.quoteData.submission.lobData.personalMotor.value.BRAND_COVERAGES[brandingData];
            $scope.brand = brandingData.toLowerCase();
            $scope.productCode = $rootScope.productCode;
            $scope.isAggQuoted = $scope.quoteData.submission.value.isAggsQuoted_AND();
            $scope.DefactoDateEvaluation = PolicyService.getDefactoDate_AND()
                .then((scriptDate) => {
                    $scope.isPolicyEffDateBeforeItbDefactoDate = submission.isPolEffDateBeforeDefactoDate(scriptDate) && $scope.brand === 'itb';
                });
            
            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.CORE_IPID, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY, submission.baseData.periodStartDate),
            ]).then((result) => {
                $scope.privacyPolicyDoc = result[0];
                $scope.privcyNoticeDoc = result[1];
                $scope.coreIpidDoc = result[2];
                $scope.cookiePolicyDoc = result[3];
            });

            $scope.vehicle = $scope.quoteData.submission.lobData.personalMotor.coverables.pmVehicles.children[0].value;

            $scope.showMarketingPref = $scope.quoteData.submission.value.isAggsQuoted_AND();

            $scope.next = (callback) => {
                $scope.form.submitted = true;
                if ($scope.form.valid === false || $scope.quoteData.updateInFlight) {
                    callback();
                } else if ($scope.quoteData.covAddedWithoutUpdate === true) {
                    $scope.quoteData.updateQuote()
                        .then(() => {
                            callback($scope.quoteData.submission);
                        });
                } else if ($scope.quoteData.submission.upFrontPaymentAttempted_itb.value === true) {
                    $state.go('contactUs', {
                        hideNewQuoteBtn_AND: true
                    });
                } else {
                    callback($scope.quoteData.submission);
                }
            };

            $scope.emailQuote = () => {
                $state.go('quoteEmail', {
                    submission: $scope.quoteData.submission
                });
            };

            $scope.printQuote = () => {
                EventHub.publish('wizard', 'print');
                $window.print();
            };

            $scope.refreshQuote = () => {
                $scope.quoteData.updateQuote();
            };

            $scope.isBeforeNewKeyDocDatTBB = () => {
                return moment($scope.quoteData.periodStartDate).isBefore(moment('20231201').startOf('day'));
            }
            
            if ($rootScope.productCode === 'PersonalMotor'){
                $scope.quoteData.submission.bindData.boxDeclaration_itb = true;
            }

            //Handle bindData.autoRenew_itb not being set as bindData has been set at QuotePage
            if ($scope.quoteData.submission.bindData.accountNumber && $scope.quoteData.submission.bindData.autoRenew_itb.value === undefined) {
                $scope.quoteData.submission.bindData.autoRenew_itb.value = true; 
            }

            //Handle invalid baseData.accountHolder.cellNumber from Aggs
            if ($scope.quoteData.submission.baseData.accountHolder.cellNumber.value !== null && ! $scope.quoteData.submission.baseData.accountHolder.cellNumber.aspects.valid) {
                $scope.quoteData.submission.baseData.accountHolder.cellNumber.value = '07000000000';
            }
            //Handle invalid bindData.contactPhone from Aggs
            if ($scope.quoteData.submission.bindData.contactPhone.value !== null && ! $scope.quoteData.submission.bindData.contactPhone.aspects.valid) {
                $scope.quoteData.submission.bindData.contactPhone.value = '07000000000';
            }

        }]
    };
}];