/* The step Covers Direct Debit Bank Details Legal Payments*/
class LegalPaymentStep3Ctrl {
    constructor($scope, StateUtils) {
        const submissionVm = StateUtils.activeDataForState(true).model;
        $scope.bindData = submissionVm.bindData;
    }

}

LegalPaymentStep3Ctrl.inject = ['$scope', 'StateUtils'];

export default LegalPaymentStep3Ctrl;