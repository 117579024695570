import _ from 'lodash';
import ComponentizerCtrl from '../common/ComponentizerCtrl';

const INPUT_TYPES = {
    BOOLEAN: 'boolean'
};

class FieldComponentizerCtrl extends ComponentizerCtrl {

    $onInit() {
        if (this._arePropertiesValid()) {
            this._replaceComponent();
            this._setDefaultValue();
        }
    }

    _setDefaultValue() {
        if (this.viewModel.value === undefined && this.field.dataProps) {
            this.viewModel.value = this.field.dataProps.defaultValue;
        }
    }

    /**
     * Transforms the HTML into an element with the right scope. Adds required form controllers for gw-ctrl-group.
     * @private
     */
    _replaceComponent() {
        const componentName = this._defineComponent();
        const compHtml = `<${componentName} ${this._componentProps()} />`;
        const [component] = this.$compile(compHtml)(this.$scope.$parent, null, {
            transcludeControllers: {
                form: {
                    instance: this.$scope.$ctrl.formCtrl
                }
            }
        });

        this.$element.replaceWith(component);
    }

    _defineComponent() {
        const component = this.field.component;
        if (component) {
            return component;
        }
        const componentType = this.field.dataType || this.viewModel.aspects.inputCtrlType;
        switch (componentType) {
            case INPUT_TYPES.BOOLEAN:
                return 'gw-pl-yes-no-group';
            default:
                return 'gw-pl-input-ctrl';
        }
    }

    /**
     * returns attrs used by component
     * @returns {string}
     * @private
     */
    _componentProps() {
        const properties = this.field.dataProps;
        const componentProps = _.map(properties, (value, key) => {
            return this.formatProperty(value, key);
        });

        const {presentationModel} = this.field;

        if (!_.has(properties, 'model')) { // If model was not defined as a property (used for some flavours)
            componentProps.push(`model="${this.$attrs.viewModel}"`);
        }
        componentProps.push(`ng-show="${this.field.isVisible}"`);
        componentProps.push(`label="${this._translateProperty(this.field.label)}"`);
        componentProps.push(`inline="${this.field.layout === 'inline'}"`);
        componentProps.push(this.generateAutomationProperty());

        if (this.field.tooltip) {
            componentProps.push(this.formatProperty(this.field.tooltip, 'tooltip'));
        }

        if (presentationModel.disabled) {
            componentProps.push(`disabled="${presentationModel.disabled}"`);
        }
        if (presentationModel.readOnly) {
            componentProps.push(`readonly="${presentationModel.readOnly}"`);
        }

        if (this.presentationModelPath) {
            componentProps.push(this.formatProperty(this.presentationModelPath, 'presentation-model'));
        }

        return componentProps.join(' ');
    }

    /**
     * Check iviewModel property is defined
     * @returns {boolean}
     * @private
     */
    _arePropertiesValid() {
        if (!this.viewModel) {
            console.warn(`The given component requires a view-model property. Expected view-model: ${this.field.viewModelName}`);
            return false;
        }
        return true;
    }
}

FieldComponentizerCtrl.$inject = ['$scope', '$element', '$attrs', '$compile', '$filter'];

export default FieldComponentizerCtrl;