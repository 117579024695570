import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./tile-label.html';
import styles from './tile-label-hashed.scss';

export default [() => ({
    restrict: 'E',
    scope: {
        state: '&',
        flavor: '='
    },
    transclude: true,
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', ($scope) => {
        $scope.currentFlavor = $scope.flavor || 'Default';
    }]
})];
