import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './quoteViewCell-hashed.scss';
import templateStr from 'text!./quoteViewCell.html';

function controller() {
}

export default {
    restrict: 'E',
    bindings: {
        cellData: '<',
        coverageTypeId: '<',
        coverageGroupId: '<',
        coverageId: '<'
    },
    template: cssUtil.hashTemplate(templateStr, styles),
    controller: controller
};