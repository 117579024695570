/**
 * $gwPlTransition service provides a consistent interface to trigger CSS 3 transitions and to be informed when they complete.
 * @param  {{DOMElement}} element  The DOMElement that will be animated.
 * @param  {string|object|function} trigger  The thing that will cause the transition to start:
 *   - As a string, it represents the css class to be added to the element.
 *   - As an object, it represents a hash of style attributes to be applied to the element.
 *   - As a function, it represents a function to be called that will cause the transition to occur.
 * @returns {Promise}  A promise that is resolved when the transition finishes.
 */

export default ['$q', '$timeout', '$rootScope', ($q, $timeout, $rootScope) => {
    const $gwPlTransition = (element, trigger, options) => {
        options = options || {};
        const deferred = $q.defer();
        const endEventName = $gwPlTransition[options.animation ? 'animationEndEventName' : 'transitionEndEventName'];

        const transitionEndHandler = () => {
            $rootScope.$apply(() => {
                element.unbind(endEventName, transitionEndHandler);
                deferred.resolve(element);
            });
        };

        if (endEventName) {
            element.bind(endEventName, transitionEndHandler);
        }

        // Wrap in a timeout to allow the browser time to update the DOM before the transition is to occur
        $timeout(() => {
            if (angular.isString(trigger)) {
                element.addClass(trigger);
            } else if (angular.isFunction(trigger)) {
                trigger(element);
            } else if (angular.isObject(trigger)) {
                element.css(trigger);
            }
            // If browser does not support transitions, instantly resolve
            if (!endEventName) {
                deferred.resolve(element);
            }
        });

        // Add our custom cancel function to the promise that is returned
        // We can call this if we are about to run a new transition, which we know will prevent this transition from ending,
        // i.e. it will therefore never raise a transitionEnd event for that transition
        deferred.promise.cancel = () => {
            if (endEventName) {
                element.unbind(endEventName, transitionEndHandler);
            }
            deferred.reject('Transition cancelled');
        };

        return deferred.promise;
    };

    // Work out the name of the transitionEnd event
    const transElement = document.createElement('trans');
    const transitionEndEventNames = {
        'WebkitTransition': 'webkitTransitionEnd',
        'MozTransition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'transition': 'transitionend'
    };
    const animationEndEventNames = {
        'WebkitTransition': 'webkitAnimationEnd',
        'MozTransition': 'animationend',
        'OTransition': 'oAnimationEnd',
        'transition': 'animationend'
    };

    function findEndEventName(endEventNames) {
        for (const name in endEventNames) {
            if (transElement.style[name] !== undefined) {
                return endEventNames[name];
            }
        }
    }

    $gwPlTransition.transitionEndEventName = findEndEventName(transitionEndEventNames);
    $gwPlTransition.animationEndEventName = findEndEventName(animationEndEventNames);
    return $gwPlTransition;
}];