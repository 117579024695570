import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./FindAddress.html';
import styles from './FindAddress-hashed.scss';
import _ from 'lodash';

const hashedTemplate = cssUtil.hashTemplate(template, styles);
const THROTTLE_TIME = 400;

export default {
    template: hashedTemplate,
    bindings: {
        model: '=',
        minimumCharactersBeforeSearching: '@',
        label: '@',
        placeholder: '@',
        tooltip: '=?',
        limitResultsTo: '@'
    },
    controller: ['addresslookup.AddressLookupService', function controller(AddressLookupService) {
        const $ctrl = this;
        $ctrl.showLongFormat = shouldShowLongAddress();
        $ctrl.search = {searchInput: ''};

        function shouldShowLongAddress() {
            return !!($ctrl.model && $ctrl.model.aspects.valid && $ctrl.model.aspects.subtreeValid);
        }

        $ctrl.clearSearch = () => {
            $ctrl.search.searchInput = '';
            $ctrl.noAddressFound = false;
            $ctrl.addressSearchResults = undefined;
            $ctrl.resetValues();
        };

        $ctrl.resetValues = () => {
            $ctrl.model.addressLine1.value = undefined;
            $ctrl.model.addressLine2.value = undefined;
            $ctrl.model.addressLine3.value = undefined;
        };

        function sortSearchResults(addressSearchResults) {
            if (addressSearchResults.errorCode) {
                $ctrl.addressSearchResults = undefined;
                $ctrl.noAddressFound = true;
                $ctrl.errorMessage = addressSearchResults.errorDescription;
            } else {
                $ctrl.noAddressFound = false;
                let sortedMatches = addressSearchResults.matches.sort((foundAddressA, foundAddressB) => foundAddressA.matchAccuracy < foundAddressB.matchAccuracy);
                sortedMatches = sortedMatches.slice(0, $ctrl.limitResultsTo);
                $ctrl.addressSearchResults = sortedMatches.map(searchObject => searchObject.address);
                _.each($ctrl.addressSearchResults, (result) => {
                    result.selected = false;
                });
            }
        }

        $ctrl.searchAddressText = _.throttle(() => {
            AddressLookupService.lookupAddressUsingStringAndFilterByPostalCode($ctrl.search.searchInput, $ctrl.model.postalCode.value)
                .then(searchResults => {
                    sortSearchResults(searchResults);
                });
        }, THROTTLE_TIME);

        $ctrl.selectResult = (selectedResult) => {
            $ctrl.addressSearchResults.forEach((result) => {
                result.selected = false;
            });
            selectedResult.selected = true;
            $ctrl.model.value = selectedResult;
        };

        $ctrl.toggleAddressMode = () => {
            $ctrl.showLongFormat = $ctrl.showLongFormat !== true;
        };

        $ctrl.getDisplayName = (addressObject) => {
            return `${addressObject.addressLine1}, ${addressObject.city}, ${addressObject.state}, ${addressObject.postalCode}`;
        };

        $ctrl.shouldDisableSearch = () => {
            const minCharacterValidationMet = $ctrl.search.searchInput.length < $ctrl.minimumCharactersBeforeSearching;
            const searchResultsValidationMet = $ctrl.addressSearchResults && $ctrl.addressSearchResults.length > 0;
            return minCharacterValidationMet || searchResultsValidationMet;
        };

    }]
};
