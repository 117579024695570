import MobileSubdirectivesInjectors from './MobileViewGenerator/SubdirectiveInjecotrs';

import injectorDirectiveFactory from 'gw-portals-util-angular/injectorDirectiveFactory';

const loadContext = context => context.keys().map(key => context(key).default);

const isCurrency = prop => ['value', 'limit'].some(match => prop.getName().toLowerCase().includes(match)) && prop.getType() === 'INTEGER';
const isDescription = () => false;
const hasLocation = schedule => 'existingLocations' in schedule.describe();

export default angular.module('edge.policycommon.schedules', MobileSubdirectivesInjectors.concat([

    // Schedules
    injectorDirectiveFactory(
        'gwSchedule', {schedule: '&', loading: '=', readonly: '<'},
        loadContext(require.context('./scheduleTypes', true, /\.js$/)),
        ['$scope', 'MediaQueriesUtil', ($scope, MediaQueriesUtil) => {
            const type = $scope.readonly ? 'readonly' : 'editable';
            const isMobile = MediaQueriesUtil.getTabletPortraitDownQuery().matches;

            // Select a schedule type based on the scope
            if (hasLocation($scope.schedule())) {
                if (isMobile) {
                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./scheduleTypes/' + type + '/MobileLocation/MobileLocation').default;
                    /* eslint-enable */
                }

                /*eslint-disable */
                // Webpack doesn't work' well with template strings inside of require()
                return require('./scheduleTypes/' + type + '/LocationTable/LocationTable').default;
                /* eslint-enable */
            }


            if (isMobile) {
                /*eslint-disable */
                // Webpack doesn't work' well with template strings inside of require()
                return require('./scheduleTypes/' + type + '/Mobile/Mobile').default;
                /* eslint-enable */
            }

            /*eslint-disable */
            // Webpack doesn't work' well with template strings inside of require()
            return require('./scheduleTypes/' + type + '/Table/Table').default;
            /* eslint-enable */
        }],
        ['render', '$scope', 'MediaQueriesUtil', (render, $scope, MediaQueriesUtil) => {
            // Trigger digest when phone/tablet window size border is crossed
            const query = MediaQueriesUtil.getTabletPortraitDownQuery();
            query.addListener(render);
            $scope.$on('$destroy', () => query.removeListener(render));
        }]
    ),

    // Schedule data items
    injectorDirectiveFactory(
        'gwScheduleDataItem',
        {
            item: '&',
            type: '@'
        },
        loadContext(require.context('./dataItems', true, /\.js$/)),
        ['$scope', scope => {
            const item = scope.item();
            if (scope.type.includes('readonly') || item.isEditable() === false) {
                const relevantReadonlyType = scope.type.includes('readonly') ? scope.type : `readonly-${scope.type}`;
                if (item.getType() === 'TYPEKEY') {
                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + relevantReadonlyType + '/Typekey.js').default;
                    /* eslint-enable */
                }

                if (isCurrency(item.getPropertyInfo())) {
                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + relevantReadonlyType + '/Currency.js').default;
                    /* eslint-enable */
                }

                if (item.getType() === 'DATE') {
                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + relevantReadonlyType + '/Date.js').default;
                    /* eslint-enable */
                }

                /*eslint-disable */
                // Webpack doesn't work' well with template strings inside of require()
                return require('./dataItems/' + relevantReadonlyType + '/Default.js').default;
                /* eslint-enable */
            }

            switch (item.getType()) {
                case 'BOOLEAN':
                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + scope.type + '/Boolean.js').default;
                /* eslint-enable */
                case 'TYPEKEY':
                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + scope.type + '/Select.js').default;
                /* eslint-enable */
                case 'STRING':
                    if (isDescription(item.getPropertyInfo())) {
                        /*eslint-disable */
                        // Webpack doesn't work' well with template strings inside of require()
                        return require('./dataItems/' + scope.type + '/Textarea.js').default;
                        /* eslint-enable */
                    }

                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + scope.type + '/Input.js').default;
                /* eslint-enable */

                case 'INTEGER':
                    if (isCurrency(item.getPropertyInfo())) {
                        /*eslint-disable */
                        // Webpack doesn't work' well with template strings inside of require()
                        return require('./dataItems/' + scope.type + '/Currency.js').default;
                        /* eslint-enable */
                    }

                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + scope.type + '/Input.js').default;
                /* eslint-enable */

                case 'DATE':
                    /*eslint-disable */
                    // Webpack doesn't work' well with template strings inside of require()
                    return require('./dataItems/' + scope.type + '/Date.js').default;
                /* eslint-enable */

                default:
                    throw new Error('Unknown data item type');
            }
        }]
    ),

    // Property info headers
    injectorDirectiveFactory(
        'gwSchedulePropertyHeader',
        {
            property: '&propertyInfo',
            type: '@',
            hideRequired: '<'
        },
        loadContext(require.context('./propertyHeaders', true, /\.js$/)),
        ['$scope', scope => {
            if (isCurrency(scope.property())) {
                /*eslint-disable */
                // Webpack doesn't work' well with template strings inside of require()
                return require('./propertyHeaders/' + scope.type + '/RightAligned.js').default;
                /* eslint-enable */
            }

            /*eslint-disable */
            // Webpack doesn't work' well with template strings inside of require()
            return require('./propertyHeaders/' + scope.type + '/Default.js').default;
            /* eslint-enable */
        }]
    )
]))
    .directive('gwScheduledReadonlyLocation', require('./LocationReadonlyView/Location').default)
    .directive('gwScheduledEditLocationForm', require('./LocationEditForm/Location').default)
    .directive('gwScheduledMobileNotification', require('./MobileNotification/Notification').default);
