import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import _ from 'lodash';

class ViewBuildingCtrl {
    constructor($scope, $state, StateUtils, ModalService, $filter, cpDeleteBuildingService) {
        this.$state = $state;
        this.model = StateUtils.activeDataForState(true).model;
        this.sessionId = SessionUtil.getSessionUUID(this.model);
        this.$filter = $filter;
        this.$scope = $scope;
        $scope.isBuildingUpdated = $state.params.isBuildingUpdated;
        $scope.isCoverageOpen = $state.params.isCoverageOpen;
        $scope.areCoveragesUpdated = $state.params.areCoveragesUpdated;
        $scope.isLocationUpdated = $state.params.isLocationUpdated;

        getBuildingData(this.model, $state.params.publicID);

        function getBuildingData(model, buildingId) {
            $scope.location = _.find(model.lobData.commercialProperty.coverables.locations.value, (location) => {
                return _.find(location.buildings, (currentBuilding) => {
                    if (currentBuilding.publicID === buildingId) {
                        $scope.building = currentBuilding;
                        return location;
                    }
                });
            });
        }

        this.deleteBuilding = () => {
            const locations = this.model.lobData.commercialProperty.coverables.locations.value;

            cpDeleteBuildingService.deleteBuilding(this.model, $scope.location, $scope.building)
                .then(() => {
                    if (locations.every(loc => loc.buildings.length === 0)) {
                        $state.go('cpBuildingWizardInitialScreen');
                    } else {
                        $state.go('cpBuildingWizardBuildingsList');
                    }
                })
                .catch((error) => {
                    if (error) {
                        ModalService.showError(
                            'quoteandbind.cp.directives.cp-building-view.Building not removed',
                            'quoteandbind.cp.views.cp-building-view.Please check for errors and try again'
                        );
                    }
                });
        };
    }

    removeBuilding() {
        this.deleteBuilding();
    }

    editLocation() {
        this.$state.go('cpBuildingWizardEditLocation', {publicID: this.$scope.location.publicID, buildingPublicID: this.$scope.building.publicID});
    }

}

ViewBuildingCtrl.$inject = ['$scope', '$state', 'StateUtils', 'ModalService', '$filter', 'qnb.cpDeleteBuildingService'];

export default ViewBuildingCtrl;
