import _WizardStepsCtrlAND from 'edge/platform/widgets/wizard/_WizardStepsCtrl_AND';
import templateStr from 'text!./navigation-steps-mobile.html';
import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './navigationStepsMobile-hashed.scss';

export default [
    () => {
        return {
            restrict: 'A',
            replace: true,
            template: cssUtil.hashTemplate(templateStr, styles),
            require: '^gwNavBarWizardBase',
            controller: _WizardStepsCtrlAND,
            link(scope) {
                scope.accordionDisplay = false;
                scope.FAQDisplay = false;
                scope.faqSearchQuery = '';
                scope.toggleAccordionDisplay = () => {
                    scope.accordionDisplay = !scope.accordionDisplay;
                    $('#andFaqMobile .gw-accordion-chevron').removeClass('gw-accordion-chevron_open');
                    scope.hideFAQ();
                };
                scope.toggleFAQDisplay = () => {
                    scope.FAQDisplay = !scope.FAQDisplay;
                    $('#andImportantInfoMobile .gw-accordion-chevron').removeClass('gw-accordion-chevron_open');
                    scope.hideAccordion();
                };
                scope.hideAccordion = () => {
                    scope.accordionDisplay = false;
                };
                scope.hideFAQ = () => {
                    scope.FAQDisplay = false;
                };
                scope.goToFAQ = function () {
                    const faqURL = `https://yourcommunity.tescobank.com/t5/forums/searchpage?q=${this.faqSearchQuery}&search_type=thread&filter=labels,includeTkbs&include_tkbs=true`;
                    window.open(faqURL);
                };
            }
        };
    }
];