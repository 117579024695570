import ModalService from './ModalService';
import ModalController from './ModalCtrl';
import ModalProvider from './ModalProvider';
import StackedMap from './dataStructures/StackedMap';
import ModalStack from './dataStructures/ModalStack';
import ModalBackdropDirective from './directives/ModalBackdrop';
import ModalWindowDirective from './directives/ModalWindow';

export default angular.module('edge.platform.widgets.modal', [])
    .factory('$$stackedMap', StackedMap) // Internal data structure
    .factory('$modalStack', ModalStack) // Internal data structure
    .provider('$modal', ModalProvider)
    .factory('ModalService', ModalService)
    .controller('ModalController', ModalController)
    .directive('modalBackdrop', ModalBackdropDirective) // A helper directive for the $modal service. It creates a backdrop element.
    .directive('modalWindow', ModalWindowDirective);


/*
 to create animation for the modal, provide a key like below in options.

 const options = {
 animationType: 'left-right'
 };

 const full = ModalService.showFullScreenPopup('hey', {}, options);
 setTimeout(() => {
 full.close();
 }, 5000);
 */
