import FlowFactory from 'edge/platform/flow/FlowFactory';
import flowActionStatus from 'edge/platform/flow/angularhelpers/ActionStatusMessages';
import _ from 'lodash';

import {stateNamePrefix, wizardParentState as addBuildingWizardParentState} from './RouteConfig';

const wizardFactory = ['$filter', '$q', '$state', 'EventHub', 'TranslateService', 'ModalService', 'StateUtils', 'qnb.cpCoverablesService', ($filter, $q, $state, EventHub, TranslateService, ModalService, StateUtils, CPCoverablesService) => {
    const _exitingWizard = wizardParentState => (fromState, toState) => {
        const fromStateInWizard = StateUtils.hasState(wizardParentState, fromState);
        const toStateInWizard = StateUtils.hasState(wizardParentState, toState);
        return fromStateInWizard && !toStateInWizard;
    };

    const exitingSubwizard = _exitingWizard(addBuildingWizardParentState);
    const exitingMainWizard = _exitingWizard('baseTransactionWizard');

    const routerExitListenerRemoveFn = StateUtils.beforeStateChangeStart((event, toState, toParams, fromState) => {
        if (fromState.name === `${stateNamePrefix}Confirmation`) {
            // When leaving the confirmation — don't ask anything, just unbind.
            routerExitListenerRemoveFn();
            return;
        }

        if (toState.name === 'cpLocationsAndBuildings') {
            // This is tricky. When we roll back flow step transition (see below),
            // we don't want to get a router state update also.
            // Preventing that event for cpLocationsAndBuildings state
            // makes sure we stay in the subwizard when user doesn't cancel after prompted.
            event.preventDefault();
            return;
        }

        const isExitingSubwizard = exitingSubwizard(fromState, toState);
        const isExitingMainWizard = exitingMainWizard(fromState, toState);

        if (!isExitingSubwizard) {
            return; // Don't care, still in the wizard
        }

        const onError = (err) => {
            if (err) { // Cancellation may be rejected without error. No need to show a modal in that case.
                ModalService.showError('gateway.views.modal.Error', 'quoteandbind.cp.views.Error cleaning up building and location that were being updated, double-check saved data.');
            }
        };

        const flowModel = $state.get(addBuildingWizardParentState).data.flow;
        if (!isExitingMainWizard) {
            // initially prevent transition until we get confirmation
            event.preventDefault();

            flowModel.cancel()
                .then(routerExitListenerRemoveFn) // Make sure this thing isn't triggered twice
                .then(() => $state.go(toState, toParams))
                .catch((err) => {
                    if (err) {
                        onError(err);
                        return;
                    }

                    // Router transition is fired because flow model step change happens. This rolls back step change on the outer model.
                    StateUtils.dataForState('baseTransactionWizard').flowModel.next(StateUtils.dataForState('baseTransactionWizard').model, {});
                });
        } else if (!event.defaultPrevented) {
            flowModel.cancel({skipDialog: true})
                .catch(onError)
                .then(routerExitListenerRemoveFn); // Make sure this thing isn't triggered twice
        }
    });

    const stepNavigationListener = () => (model, fromStep, nextStep) => {
        $state.go(stateNamePrefix + nextStep.name, {
            model
        });
    };
    const exitListener = flowDefn => (model, fromStep, nextStep) => {
        if (flowDefn.exits[nextStep.name]) { // Normal finish of the wizard
            routerExitListenerRemoveFn();
        }
    };

    const flowFactory = FlowFactory.get($q, EventHub, [stepNavigationListener, exitListener], {
        actionStatusInfo: flowActionStatus(ModalService),
        snapshotResetConfirmation: (model) => {
            if (model.selectedBuildingSnapshot &&
                !_.isEqual(model.selectedBuildingSnapshot, model.selectedBuilding)) {
                return ModalService.showWarning(
                    'platform.flow.angularhelpers.SnapshotResetConfirmation.Are you sure you want to leave this page?',
                    'quoteandbind.cp.Changes made to the building will be lost'
                )
                    .result.then(() => $q.reject('confirmed'), () => $q.reject('cancelled'));
            }
            return $q.resolve(model);
        }, // Relevant to the Building Info step, only building data changes need to be checked
        cancelConfirmation: (model, {title, message, cancelLabel}) => {
            if (_.get(model, 'skipDialog')) {
                return $q.resolve({});
            }
            title = title || 'platform.flow.angularhelpers.CancelConfirmation.Are you sure you want to cancel?';
            return ModalService.showWarning(title, message, undefined, undefined, undefined, cancelLabel).result.then(() => model);
        }
    });

    const cancelMessage = {
        error: {
            title: TranslateService.instant('quoteandbind.cp.directives.cp-building-directive.Unable to cancel add building'),
            message: TranslateService.instant('quoteandbind.cp.directives.cp-building-directive.An error occurred while attempting to cancel add building.')
        },
        progress: {
            message: TranslateService.instant('quoteandbind.cp.directives.cp-building-directive.Cancelling add building...')
        }
    };

    const cancel = () => {
        // Retrieve current VM, model created during initialization of wizard is out of sync
        const model = StateUtils.dataForState($state.current.name).model.value;
        const jobID = model.quoteID ? model.quoteID : model.jobID;
        const selectedLocation = $state.params.model.selectedLocation;
        const selectedBuilding = $state.params.model.selectedBuilding;
        if (selectedLocation) {
            // If location has more than one building, we want to only remove the building added via the wizard
            if ((selectedLocation.isPrimary || selectedLocation.buildings.length > 1) && selectedBuilding) {
                return CPCoverablesService.removeCPBuilding(
                    jobID,
                    selectedLocation.publicID,
                    selectedBuilding.publicID,
                    model.sessionUUID
                ).then(() => {
                    const locations = model.lobData.commercialProperty.coverables.locations;
                    const location = _.find(locations, (aLocation) => {
                        return aLocation.publicID === selectedLocation.publicID;
                    });
                    location.buildings.splice(location.buildings.indexOf(selectedBuilding), 1);
                });
            } else if (!selectedLocation.isPrimary && (selectedLocation.buildings.length === 0 ||
                (selectedLocation.buildings.length === 1 && selectedBuilding))) {
                // If location has no buildings, or 1 building, and the selected building is defined
                // we know that the location was added via the wizard and can be removed
                return CPCoverablesService.removeCPLocation(
                    jobID,
                    selectedLocation,
                    model.sessionUUID
                ).then(() => {
                    const locations = model.lobData.commercialProperty.coverables.locations;
                    locations.splice(locations.indexOf(selectedLocation), 1);
                });
            }
        }

        return $q.resolve();
    };

    // Return a flow model for Add Bulilding wizard
    return flowFactory((step) => {
        step('SelectLocation', true)
            .label(() => TranslateService.instant('quoteandbind.cp.directives.cp-building-directive.Location'))
            .onNext.goTo('BuildingInfo');

        step('BuildingInfo')
            .label(() => TranslateService.instant('quoteandbind.cp.directives.cp-location-directive.Building'))
            .onNext.goTo('BuildingCoverages');

        step('BuildingCoverages')
            .label(() => TranslateService.instant('quoteandbind.cp.directives.cp-building-directive.tabs.Coverages'))
            .onNext.goTo('Confirmation');

        step('Confirmation')
            .onNext.goTo('Fin');

        step('cancel')
            .data({
                confirmationModalArgs() {
                    return {
                        title: 'quoteandbind.cp.Cancel Adding Building?',
                        message: 'quoteandbind.cp.The information you have already provided about this building will be lost. Are you sure you want to cancel?',
                        cancelLabel: $filter('translate')('quoteandbind.cp.directives.cp-building-directive.No')
                    };
                }
            })
            .onNext.doAction(cancel, cancelMessage).thenGoTo('Fin');

        step('Fin');
    });
}];

const startWizard = ($injector, params) => {
    const flowModel = $injector.invoke(wizardFactory).start({}); // Fires up the add building wizard.
    const wizardState = $injector.get('$state').get(addBuildingWizardParentState);
    wizardState.data.flow = flowModel; // Publish the flow on the wizard parent state
    wizardState.data.params = params; // Set the params passed to the wizard
};

export {
    startWizard as default,
    wizardFactory
};
