const fn = ($rootScope, $compile) => ({
    getNodeFromTemplate: (template, scopeValues) => {
        let isolateScope = $rootScope.$new(true);
        isolateScope = angular.extend(isolateScope, scopeValues);
        const node = $compile(template)(isolateScope);
        isolateScope.$apply();
        return node;
    }
});
fn.$inject = ['$rootScope', '$compile'];

export default fn;