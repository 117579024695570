import has from 'has';
import featureCategories from 'effective-features!';
import _ from 'lodash';

const hasFeatureFn = (featureCategory) => {
    return featureCategory && featureCategory !== 'false';
};

for (const featureCategory in featureCategories) {
    if (featureCategories.hasOwnProperty(featureCategory)) {
        if (Array.isArray(featureCategories[featureCategory])) {
            for (let i = 0; i < featureCategories[featureCategory].length; i++) {
                if (!has.add(`${featureCategory}.${featureCategories[featureCategory][i]}`)) {
                    has.add(
                        `${featureCategory}.${featureCategories[featureCategory][i]}`,
                        hasFeatureFn.bind(undefined, featureCategories[featureCategory][i])
                    );
                }
            }
        } else if (!has.add(featureCategory)) {
            has.add(featureCategory, hasFeatureFn.bind(undefined, featureCategories[featureCategory]));
        }
    }
}

function getAllFeatures() {
    return featureCategories;
}

function hasFeature(featureName) {
    return has(featureName);
}

function getFeatureEnabledStates(routesConfig) {
    return _.pick(routesConfig, route => {
        const {
            featureToggler
        } = route;
        return !featureToggler || hasFeature(featureToggler);
    });
}

/**
 * Service for setting up has feature check functions and returning feature categories
 */
export default {
    getAllFeatures,
    hasFeature,
    getFeatureEnabledStates
};