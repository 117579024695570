import _ from 'underscore';
import FlowUtil from './../FlowUtil';

export default function (step, Q) {
    let _doActionOp;
    let _doActionMsgs;
    let _doActionCallbacks;
    let _goToOp;
    let _branchOp;
    let transitionSet = false;

    function transition(stepNameOrBranchFn) {
        return function (model, extraArgs) {
            if (_.isString(stepNameOrBranchFn) || FlowUtil.isStepObject(stepNameOrBranchFn) || FlowUtil.isJunctionObject(stepNameOrBranchFn)) {
                // step name
                return stepNameOrBranchFn;
            }
            // branch function
            return stepNameOrBranchFn(model, extraArgs);
        };
    }


    function action(actionFn, callbacks, options = { forceUpdate: false }) {
        return function (model, extraArgs = {}, isPristine) {
            const shouldSendReq = options.forceUpdate || extraArgs.forceUpdate || !isPristine;
            // if the view model hasn't changed or we don't explcitily want to, don't send a request
            const updateModelPromise = shouldSendReq ? actionFn(model, extraArgs) : Promise.resolve(model);

            if (!updateModelPromise || !updateModelPromise.then) {
                throw new Error(`Action function should return a promise: ${actionFn}`);
            }
            return updateModelPromise.then(
                (modelAfterPromise) => {
                    const actionSuccessTransition = callbacks.success(modelAfterPromise, extraArgs);
                    if (!actionSuccessTransition) {
                        throw new Error('Undefined result returned from action success transition');
                    }
                    return {
                        model: modelAfterPromise,
                        step: actionSuccessTransition,
                        extraArgs: extraArgs,
                        serverReqSent: shouldSendReq // Indicates if a request was sent. Used in FlowModel.js to create a serversnapshot of the view model.
                    };
                },
                errorFromAction => {
                    const actionErrorTransition = callbacks.error(errorFromAction, extraArgs);
                    if (!actionErrorTransition) {
                        throw new Error('Undefined result returned from action error transition');
                    }
                    extraArgs.error = errorFromAction;
                    return Q.reject({
                        model: model,
                        step: actionErrorTransition,
                        extraArgs: extraArgs
                    });
                }
            );
        };
    }


    return {
        /**
         * Defines an action to call before going to a new step
         *
         * @param {Function}  doActionFn - Function that takes in the model and returns a promise that resolves to {model, extraArgs}
         * @param {Object}  actionMsgs - Progress, Success and Error messages associated with action. Typically used for
         *                               with dialog messages.
         * @param {Object} options - Optional parameter, used for additional options for the action i.e. forcing an update request.
         *
         * @returns {*} If parameters are supplied then returns an object with thenGoTo and thenBranch properties otherwise
         *              if no parameters are supplied then an object representing the action is returned.
         *              Any extraParams will be bound to the action function.
         */
        doAction(doActionFn, actionMsgs, options = { forceUpdate: false }) {
            if (arguments.length === 0) {
                return _doActionOp ? {
                    action: _doActionOp,
                    msgs: _doActionMsgs,
                    callbacks: _doActionCallbacks
                } : undefined;
            }
            if (transitionSet) {
                throw new Error('A step definition can only have one doAction, goTo or branch property set');
            }
            transitionSet = true;
            // object used to store what function to call when action promise is resolved
            // functions are set on object in thenGoTo and thenBranch
            const callbacks = {
                success: undefined,
                error: undefined
            };
            _doActionOp = action(doActionFn, callbacks, options);
            _doActionMsgs = actionMsgs;
            _doActionCallbacks = callbacks;
            return {
                thenGoTo(successStep, errorStep) {
                    callbacks.success = transition(successStep);
                    callbacks.error = transition(errorStep);
                    return step;
                },
                thenBranch(successFn, errorFn) {
                    callbacks.success = transition(successFn);
                    callbacks.error = transition(errorFn);
                    return step;
                }
            };
        },

        /**
         * Defines a transition to a new step
         *
         * @param {String|Object}  stepNameOrStepObject - String identifying the next step or a step object
         *
         * @returns {*} If parameters are supplied then undefined is returned
         *              if no parameters are supplied then a string or object representing the next step returned
         */
        goTo(stepNameOrStepObject) {
            if (arguments.length === 0) {
                return _goToOp;
            }
            if (transitionSet) {
                throw new Error('A step definition can only have one doAction, goTo or branch property set');
            }
            transitionSet = true;
            _goToOp = transition(stepNameOrStepObject);
            return step;
        },

        /**
         * Defines a branch function that returns the next step in the flow
         *
         * @param {Function}  branchFn - Function that returns the next step
         *
         * @returns {*} If parameters are supplied then undefined is returned
         *              if no parameters are supplied then the branch function. Any extraParams will be bound to the
         *              branch function.
         */
        branch(branchFn) {
            if (arguments.length === 0) {
                return _branchOp;
            }
            if (transitionSet) {
                throw new Error('A step definition can only have one doAction, goTo or branch property set');
            }
            transitionSet = true;
            _branchOp = transition(branchFn);
            return step;
        }
    };
}