import documentUpload from './DocumentUploadDirective';
import driverLicenseUpload from './DriverLicenseUploadDirective';
import fileUploadUtil from './FileUploadUtil';
import uploadTokenService from './UploadTokenService';
import 'blueImpFileUpload/js/jquery.fileupload-angular';
import 'blueImpFileUpload/js/jquery.iframe-transport';
import 'edge/platform/widgets/modal/module';

export default angular.module('edge.platform.widgets.documentupload', ['blueimp.fileupload', 'edge.platform.widgets.modal'])
    .factory('UploadTokenService', uploadTokenService)
    .factory('FileUploadUtil', fileUploadUtil)
    .directive('gwPlDocumentUpload', documentUpload)
    .directive('gwDriverLicenseUpload', driverLicenseUpload);
