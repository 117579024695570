import _ from 'lodash';
import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./ReadonlyCoverages.html';
import styles from './ReadonlyCoverages-hashed.scss';

export default [() => {
    return {
        restrict: 'E',
        template: cssUtil.hashTemplate(template, styles),
        scope: {
            coverages: '=',
            filterSelected: '<'
        },
        controller: ['$scope', (scope) => scope.$watchCollection('coverages', coverages => {
            if (!Array.isArray(coverages)) {
                return;
            }

            scope._coverages = coverages
                .filter(coverage => !scope.filterSelected || coverage.selected)
                .map((coverage, idx) => _.assign({}, coverage, {
                    id: coverage.publicID || idx
                }));
        })]
    };
}];
