import BaseCtrl from 'edge/platform/base/BaseCtrl';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import _ from 'lodash';

const fn = ($scope, $state, $stateParams, $q, ViewModelService, PolicyService, UserAccountService, DefaultProductProvider) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {

        $scope.supportedProductsAndFeatures = [];
        const applicationFeatures = ApplicationFeatures.getAllFeatures();

        const bop = {
            name: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Businessowners',
            productCode: 'BusinessOwners',
            isPersonal: false,
            isProduct: true,
            isPanelActive: false,
            helpText: 'quoteandbind.controllers.ZipCodeCtrl.Products.HelpText.Business Owners'
        };

        const ho = {
            name: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Homeowners',
            productCode: 'Homeowners',
            isPersonal: true,
            isProduct: true,
            isPanelActive: false,
            helpText: 'quoteandbind.controllers.ZipCodeCtrl.Products.HelpText.HomeOwners'
        };

        const pa = {
            name: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Personal Auto',
            productCode: 'PersonalAuto',
            isPersonal: true,
            isProduct: true,
            isPanelActive: false,
            helpText: 'quoteandbind.controllers.ZipCodeCtrl.Products.HelpText.Personal Auto'
        };

        const slq = {
            name: 'quoteandbind.controllers.ZipCodeCtrl.Features.Name.Quick Quote',
            productCode: 'QuickQuote',
            isPersonal: true,
            isProduct: false,
            isPanelActive: false,
            helpText: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.QuickQuote'
        };

        const gtp = {
            name: 'quoteandbind.controllers.ZipCodeCtrl.Features.Name.Guidance To Product',
            productCode: 'Guidance',
            isPersonal: false,
            isProduct: false,
            isPanelActive: false,
            helpText: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.Guidance To Product'
        };

        const lobsAndFeatures = applicationFeatures.quoteandbind_lob.concat(applicationFeatures.quoteandbind_feature);

        _.each(lobsAndFeatures, (enabledLobOrFeature) => {
            if (enabledLobOrFeature === 'pa') {
                $scope.supportedProductsAndFeatures.push(pa);
                $scope.supportedProductsAndFeatures.push(slq);
            } else if (enabledLobOrFeature === 'ho') {
                $scope.supportedProductsAndFeatures.push(ho);
            } else if (enabledLobOrFeature === 'bop') {
                $scope.supportedProductsAndFeatures.push(bop);
            } else if (enabledLobOrFeature === 'gtp') {
                $scope.supportedProductsAndFeatures.push(gtp);
            }
        });

        const applicantDetails = {
            effectiveDate: new Date(),
            postalCode: '',
            account: undefined,
            productCode: undefined
        };

        // This model is created to facilitate the cross fill functionality from AMP. A model is created and populated with
        // data from AMP if the account number is found by the $stateParams
        $scope.crossFillModel = ViewModelService.create(
            applicantDetails, 'pc',
            'edge.capabilities.policycommon.availability.dto.PostalCodeAvailabilityRequestDTO'
        );

        // Initially giving the binary selection the value of true to default the user to the personal products page on entry
        $scope.isPersonalProduct = {value: true};
        $scope.isRetrieveStateActive = false;

        $scope.activatePanel = (activePanel) => {
            $scope.supportedProductsAndFeatures.forEach((product) => {
                product.isPanelActive = product.productCode === activePanel;
            });
        };

        $scope.activateRetrieveState = () => {
            $scope.isRetrieveStateActive = true;
        };

        $scope.deActivateRetrieveState = () => {
            $scope.isRetrieveStateActive = false;
        };

        if ($stateParams.postalCode && $stateParams.quoteID) {
            $scope.view.postalCode.value = $stateParams.postalCode;
            $scope.quoteID = $stateParams.quoteID;
        }

        if ($stateParams.account) {
            const policySummariesPromise = PolicyService.getAccountPolicySummaries();
            const accountSummaryPromise = UserAccountService.getAccountContactSummary();

            $q.when(accountSummaryPromise, (accountSummary) => {
                $scope.crossFillModel.value.account = accountSummary.accountContact;
                $scope.crossFillModel.value.account.subtype = 'Person';
                $scope.crossFillModel.value.account.accountNumber = accountSummary.accountNumber;
                $scope.crossFillModel.postalCode.value = accountSummary.accountContact.primaryAddress.postalCode;
            });

            $q.when(policySummariesPromise, (policySummaries) => {
                $scope.crossFillModel.productCode.value = DefaultProductProvider.getDefaultProduct(policySummaries);
            });
        }
    };

    ctrl.filterProductsAndFeaturesByBinarySelection = (productOrFeature) => {
        const personalCondition = productOrFeature.isPersonal === $scope.isPersonalProduct.value;
        if ($scope.isRetrieveStateActive) {
            return personalCondition && productOrFeature.isPanelActive;
        }
        return personalCondition;
    };

    return ctrl.create($scope);
};

fn.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$q',
    'ViewModelService',
    'qnb.PolicyService',
    'qnb.UserAccountService',
    'qnb.DefaultProductProvider'
];

export default fn;