/**
 * Utilities for the OffsetDateTimeDTO.
 */
import Instant from './Instant';

export default {
    /**
     * Creates a new instance of offsetDateTime DTO capturing a local UTC time offset from the date (based on current
     * client TZ rules.
     *
     * @param {Date|undefined} date
     *
     * @returns {Object|date}
     */
    fromJSDate(date) {
        return date ? {
            instant: Instant.fromJSDate(date),
            offset: -date.getTimezoneOffset()
        } : date;
    },


    /**
     * Converts a DTO into a local date. The returned date represents correct instant but originating time offest
     * is lost.
     *
     * @param {Object|undefined} dto
     * @returns {Date|dto}
     */
    toLocalDate(dto) {
        return dto ? Instant.toJSDate(dto.instant) : dto;
    }
};