export default {
    /**
     * Get SessionUUID from a Submission View Model
     *
     * @param {Object} [submissionViewModel]
     * @returns {*} null if the view model does not have a sessionUUID, sessionUUID otherwise
     */
    getSessionUUID: (submissionViewModel) => {
        if (submissionViewModel && submissionViewModel.sessionUUID) {
            return submissionViewModel.sessionUUID.value;
        }

        return null;
    }
};
