import templateStr from 'text!./templates/qnb-info-bar.html';
import MockUpUtil from 'gw-portals-util-js/MockUpUtil';

const mockUpUtil = new MockUpUtil();


export default ['$filter', ($filter) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            'submission': '=gwQnbInfoBar'
        },
        controller: ['$scope', (scope) => {
            scope.$filter = $filter;
            scope.getAddress = () => {
                if (!scope.submission || !scope.submission.baseData || !scope.submission.baseData.accountHolder || !scope.submission.baseData.policyAddress) {
                    return [];
                }
                const address = [];
                const policyAddress = scope.submission.baseData.policyAddress;
                const policyAddressMockData = mockUpUtil.getMockDataObject('baseData.policyAddress');

                if (policyAddress.addressLine1 && policyAddressMockData.addressLine1 !== policyAddress.addressLine1) {
                    address.push(policyAddress.addressLine1);
                }

                if (policyAddress.city && policyAddress.state && policyAddressMockData.city !== policyAddress.city) {
                    address.push(`${policyAddress.city}, ${policyAddress.state} ${policyAddress.postalCode}`);
                } else if (policyAddress.state) {
                    address.push(`${policyAddress.state} ${policyAddress.postalCode}`);
                } else {
                    address.push(policyAddress.postalCode);
                }

                return address;
            };

            scope.getAccountName = () => {
                if (scope.submission.baseData.accountHolder.contactName === mockUpUtil.getMockData('baseData.accountHolder.contactName')) {
                    return undefined;
                }
                return scope.submission.baseData.accountHolder.displayName;
            };
        }]
    };
}];
