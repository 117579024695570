import $ from 'jquery';

export default ['BaseUtils', 'LocaleService', '$q', (BaseUtils, LocaleService, $q) => {
    const ytLoadedDeferred = $q.defer();

    /**
     * Method gets called when youtube iframe api is loaded
     * @see https://developers.google.com/youtube/iframe_api_reference
     */
    window.onYouTubeIframeAPIReady = () => {
        /* globals YT */
        ytLoadedDeferred.resolve(YT);
    };

    $.getScript('https://www.youtube.com/iframe_api')
        .fail((err) => {
            ytLoadedDeferred.reject(err);
        });

    return {
        restrict: 'E',

        link: (scope, element) => {
            BaseUtils.assert.stringKey(scope, 'id', 'Video id should be specified');

            const id = scope.id;
            const playerConfig = {
                width: '100%',
                height: 'auto',
                playerVars: {
                    rel: 0,
                    hl: LocaleService.getCurrentLanguage(),
                    controls: scope.controls ? 1 : 0,
                    autoplay: scope.autoplay ? 1 : 0
                }
            };

            /**
             * @see https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
             */
            switch (scope.type) {
                case 'video':
                    playerConfig.videoId = id;
                    break;

                case 'user':
                    playerConfig.playerVars.listType = 'user_uploads';
                    playerConfig.playerVars.list = id;
                    break;

                case 'playlist':
                    playerConfig.playerVars.listType = 'playlist';
                    playerConfig.playerVars.list = id;
                    break;

                default:
                    throw new Error('Video id type should be one of "video", "playlist" or "user"');
            }

            ytLoadedDeferred.promise
                .then((youtube) => {
                    /*eslint-disable */
                    new youtube.Player(element[0], playerConfig);
                    /* eslint-enable */
                });
        }
    };
}];
