import skipOnResume from './SkipOnResume';
import next from './Next';
import previous from './Previous';
import valid from './Valid';
import label from './Label';
import data from './Data';
import event from './Event';
import FlowUtil from './../FlowUtil';
import _ from 'underscore';

/**
 * Returns a flow object from a flow definition
 *
 * @param {Function} flowDefn
 * @param {Object} $q - ES6 Promise API implementation
 * @param {Array} extraStepDefnPropFactories - factory functions to add new properties to the step definition
 *
 * @returns {Object} Flow object with an entry, exits, steps.
 */
export default function (flowDefn, $q, extraStepDefnPropFactories) {
    function checkAndSetFlowEntry(stepOrJctToTest, flow) {
        if (stepOrJctToTest) {
            if (flow.entry) {
                throw new Error(`Cannot define ${stepOrJctToTest} as an entry as the flow already has an entry point defined: ${flow.entry.name}`);
            }
            flow.entry = stepOrJctToTest;
        }
    }

    function stepBuilder(stepNameOrObject, isFlowEntry, flow) {
        function addStepToCurrentAndParentFlows(step, flowObj) {
            if (!flowObj) {
                return;
            }
            flowObj._steps[step.name] = step;
            return addStepToCurrentAndParentFlows(step, flowObj._parent);
        }

        if (FlowUtil.isStepObject(stepNameOrObject)) {
            if (isFlowEntry) {
                checkAndSetFlowEntry(stepNameOrObject, flow);
            }
            return stepNameOrObject;
        }
        if (!_.isString(stepNameOrObject)) {
            throw new Error(`Step function takes a string: ${stepNameOrObject}`);
        }
        if (flow._steps[stepNameOrObject]) {
            throw new Error(`Step with name ${stepNameOrObject} already exists in flow`);
        }

        const step = {
            _type: 'STEP',
            name: stepNameOrObject
        };
        step.onNext = next(step, $q);
        step.onPrevious = previous(step);
        step.onEvent = event(step, $q);
        step.isValid = valid(step);
        step.skipOnResumeIf = skipOnResume(step);
        step.label = label(step);
        step.data = data(step);
        // ANDIE Determine if the steps are not only the same, but if similar.
        // Similar steps are steps that should be displayed under the same wizard step.
        step.isSimilarTo_AND = function (that) {
            if (this.name === that.name) { // Short circuit if name matches
                return true;
            }
            let thisSiblings = [this.name];
            let thatSiblings = [that.name];
            if (this.data().asWizardNavigationSteps) {
                thisSiblings = thisSiblings.concat(this.data().asWizardNavigationSteps);
            }
            if (that.data().asWizardNavigationSteps) {
                thatSiblings = thatSiblings.concat(that.data().asWizardNavigationSteps);
            }
            return thisSiblings.filter((stepName) => thatSiblings.includes(stepName)).length > 0;
        };
        if (extraStepDefnPropFactories && _.isArray(extraStepDefnPropFactories)) {
            extraStepDefnPropFactories.forEach(propFactory => propFactory(step));
        }
        if (isFlowEntry) {
            checkAndSetFlowEntry(step, flow);
        }
        addStepToCurrentAndParentFlows(step, flow);
        return step;
    }

    function junctionBuilder(junctionNameOrObject, isFlowEntry, flow) {
        function addJunctionToCurrentAndParentFlows(junction, flowObj) {
            if (!flowObj) {
                return;
            }
            flowObj._junctions[junction.name] = junction;
            return addJunctionToCurrentAndParentFlows(junction, flowObj._parent);
        }

        if (FlowUtil.isJunctionObject(junctionNameOrObject)) {
            if (isFlowEntry) {
                checkAndSetFlowEntry(junctionNameOrObject, flow);
            }
            return junctionNameOrObject;
        }
        if (!_.isString(junctionNameOrObject)) {
            throw new Error(`Junction function takes a string: ${junctionNameOrObject}`);
        }
        if (flow._junctions[junctionNameOrObject]) {
            throw new Error(`Junction with name ${junctionNameOrObject} already exists in flow`);
        }

        const junction = {
            _type: 'JUNCTION',
            name: junctionNameOrObject
        };
        junction.onNext = next(junction, $q);
        junction.data = data(junction);

        if (isFlowEntry) {
            checkAndSetFlowEntry(junction, flow);
        }
        addJunctionToCurrentAndParentFlows(junction, flow);
        return junction;
    }


    function flowBuilder(stepFn, junctionFn, parentFlow) {
        const cachedFlows = [];

        return function (flowFn) {
            // cache the result of a flow creation if the same flow definition is encountered
            const cacheHit = _.find(cachedFlows, cacheItem => cacheItem.defn === flowFn);
            if (cacheHit) {
                return cacheHit.flow;
            }
            const currentFlow = {
                entry: undefined,
                exits: undefined,
                _steps: {},
                _junctions: {},
                _parent: parentFlow
            };

            const boundStepFn = _.partial(stepFn, _, _, currentFlow);
            const boundJunctionFn = _.partial(junctionFn, _, _, currentFlow);


            flowFn(boundStepFn, boundJunctionFn, flowBuilder(stepFn, junctionFn, currentFlow));

            // A flow exit is a step or junction that does not have a doAction, branch, or goTo handler defined
            currentFlow.exits = _.pick(currentFlow._steps, step => {
                return _.every(_.values(step.onNext), prop => prop() === undefined);
            });
            // add unbound junctions also
            _.extend(currentFlow.exits, _.pick(currentFlow._junctions, junction => {
                return _.every(_.values(junction.onNext), prop => prop() === undefined);
            }));

            cachedFlows.push({
                defn: flowFn,
                flow: currentFlow
            });
            return currentFlow;
        };
    }


    const flowFn = flowBuilder(stepBuilder, junctionBuilder);
    return flowFn(flowDefn);
}