// Use gw-pl-accordion-chevron to provide a chevron containing HTML
//   <gw-pl-accordion-chevron is-open="isOpen"></gw-pl-accordion-chevron>

import templateStr from 'text!edge/platform/widgets/accordion/directives/templates/accordion-chevron_AND.html';

export default [() => {
    return {
        restrict: 'EA',
        replace: true,
        template: templateStr,
        scope: {
            isOpen: '=',
            flavor: '@'
        },
        link: $scope => $scope.$watch('flavor', f => {
            $scope._flavor = f || 'default';
        })
    };
}];