import _ from 'lodash';

export default {
    toString(code) {
        if (!code || ['code', 'classification'].some(prop => !code[prop])) {
            return '';
        }

        // Handles both raw value and viewmodel.
        return `${_.get(code, 'code.value', code.code)}: ${_.get(code, 'classification.value', code.classification)}`;
    }
};