export default ['$q', 'addresslookup.AddressLookupService', ($q, AddressLookupService) => ({
    lookupAddress: (addressString, addressSettings) => {
        const containsTooFewCharacters = addressString && addressString.length < addressSettings.minimumCharactersBeforeSearching;

        if (!addressString || containsTooFewCharacters) {
            return [];
        }
        const deferred = $q.defer();

        AddressLookupService.autofillBasedOnAdddress(addressString).then(
            addressInfo => {
                if (addressInfo && addressInfo.matches && addressInfo.matches.length > 0) {
                    const sortedMatches = addressInfo.matches.sort((foundAddressA, foundAddressB) => foundAddressA.matchAccuracy < foundAddressB.matchAccuracy);
                    const sortedAddresses = sortedMatches.map(elem => elem.address);

                    deferred.resolve(sortedAddresses);
                } else {
                    deferred.reject();
                }
            },
            () => {
                deferred.reject();
            }
        );
        return deferred.promise;
    }
})];