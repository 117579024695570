import templateStr from 'text!./templates/phone-details.html';
import _ from 'underscore';

export default ['LocaleService', (LocaleService) => {
    return {
        restrict: 'A',
        scope: {
            phone: '=',
            readonly: '=',
            flavor: '@'
        },
        require: '^form',
        template: templateStr,

        link: (scope, element, attrs, Ctrl) => {
            scope.phoneNumberForm = Ctrl;
            scope.phoneTypes = {
                home: _.find(scope.phone.primaryPhoneType.aspects.availableValues, {
                    code: 'home'
                }),
                work: _.find(scope.phone.primaryPhoneType.aspects.availableValues, {
                    code: 'work'
                }),
                mobile: _.find(scope.phone.primaryPhoneType.aspects.availableValues, {
                    code: 'mobile'
                })
            };
            // Depending on the UserDefaultPhoneCountry configured in the backend, the mask
            // required by the input fields can vary. For example, the Australian mask should
            // look like this '(99) 9999 9999' whilst the German mask should look like this
            // '999/999999'. Here we take a sample phone number for the server configured
            // UserDefaultPhoneCountry. We then replace all digits with '9' so that the
            // masking logic in UiMaskDirective can be applied.
            scope.phoneMask = LocaleService.getRegionSamplePhoneNumber().replace(/[0-8]/g, '9');
            scope.flavor = scope.flavor || 'default';

            // In the event that there are multiple uses of this directive on the page we append a
            // hash to the for attribute to prevent undesirable behaviour
            scope.directiveHash = Math.floor(Math.random() * 9999);
        }
    };
}];