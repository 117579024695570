import datepickerDecorator from 'edge/platform/widgets/datetimepicker/datepickerDecorator';
import datetimepicker from 'edge/platform/widgets/datetimepicker/directives/datetimepicker';
import 'edge/platform/widgets/dropdown/module';
import 'edge/platform/widgets/datetimepicker/bootsrapDropDown';
import 'moment';
import 'imports?require=>function(arg){return window[arg];}!datetimepicker';

export default angular.module('edge.platform.widgets.ui.bootstrap.datetimepicker', [
    'edge.platform.widgets.dropdown',
    'ui.bootstrap.datetimepicker'
])
    .config(datepickerDecorator)
    .directive('ngDatepicker', datetimepicker);