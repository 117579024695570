import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './MultipleOfferingView-hashed.scss';
import templateStr from 'text!./MultipleOfferingView.html';
import controller from './MultipleOfferingViewCtrl';

export default {
    restrict: 'E',
    bindings: {
        quoteData: '<',
        isPaymentPeriodMonthly: '=',
        lobCode: '&',
        offeringUtilFunctions: '<',
        shouldDisableBuyOrRecalculateButton: '<'
    },
    template: cssUtil.hashTemplate(templateStr, styles),
    controller: ['$filter', 'CoveragesUtil', controller]
};