export default ['$q',
    'JsonRPCService',
    'qnb.ErrorHandlingService',
    'addressSettings',
    'addresslookup.AddressSearchUtil',
    function ($q, JsonRPCService, ErrorHandlingService, addressSettings, AddressSearchUtil) {
        return {
            getOccupations() {
                const deferred = $q.defer();
                JsonRPCService.send('guidance', 'guidance', 'getOccupations', []).then(
                    (response) => {
                        ErrorHandlingService.checkSessionUUID(response, deferred);
                        deferred.resolve(response);
                    },
                    (error, status, headers) => {
                        deferred.reject([error, status, headers]);
                    }
                );
                return deferred.promise;
            },
            getQuestions(model) {
                const deferred = $q.defer();
                JsonRPCService.send('guidance', 'guidance', 'populateQuestions', [model.value]).then(
                    (response) => {
                        ErrorHandlingService.checkSessionUUID(response, deferred);
                        model.value = response;
                        deferred.resolve(model);
                    },
                    (error, status, headers) => {
                        deferred.reject([error, status, headers]);
                    }
                );
                return deferred.promise;
            },
            getRecommendations(model) {
                const deferred = $q.defer();
                JsonRPCService.send('guidance', 'guidance', 'populateRecommendedProducts', [model.value]).then(
                    (response) => {
                        ErrorHandlingService.checkSessionUUID(response, deferred);
                        model.value = response;
                        deferred.resolve(model);
                    },
                    (error, status, headers) => {
                        deferred.reject([error, status, headers]);
                    }
                );
                return deferred.promise;
            },
            createAccountAndSubmission(model, productCode) {
                const deferred = $q.defer();
                JsonRPCService.send('guidance', 'guidance', 'createAccountAndSubmission', [model.value, productCode.code.value]).then(
                    (response) => {
                        ErrorHandlingService.checkSessionUUID(response, deferred);
                        deferred.resolve(response);
                    },
                    (error, status, headers) => {
                        deferred.reject([error, status, headers]);
                    }
                );
                return deferred.promise;
            },
            getAccountContactSummary(accountNumber) {
                const deferred = $q.defer();
                JsonRPCService.send('guidance', 'guidance', 'getAccountContactSummary', [accountNumber]).then(
                    (response) => {
                        ErrorHandlingService.checkSessionUUID(response, deferred);
                        return deferred.resolve(response);
                    },
                    (error, status, headers) => {
                        return deferred.reject([error, status, headers]);
                    }
                );
                return deferred.promise;
            },

            findAddress(addressString) {
                return AddressSearchUtil.lookupAddress(addressString, addressSettings);
            },
        };
    }
];
