import _ from 'underscore';
import templateStr from 'text!./stepper-select.html';
/* Creates an element accessor. Returns undefined if fnOrProp do not define a valid accessor. */
function accessor(fnOrProp) {
    if (_.isFunction(fnOrProp)) {
        return fnOrProp;
    }
    if (_.isString(fnOrProp)) {
        return _.property(fnOrProp);
    }

    return undefined;
}

function toString(obj) {
    return _.isNull(obj) || _.isUndefined(obj) ? '' : obj.toString();
}

/**
 * Stepper-based selection for the predefined list of values.
 * This one is strictly MVC-based. It ultimately respects a model value and have hooks for renderers, setters and
 * option value accessor.
 */

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,

        scope: {
            'model': '=gwPlStepperSelect',
            'options': '=',

            'selectBy': '=',
            'optionValue': '=',
            'renderBy': '='
        },

        controller: ['$scope', ($scope) => {
            let valueAccessor = accessor($scope.optionValue) || _.identity;
            let renderer = accessor($scope.renderBy) || toString;

            $scope.$watch('optionValue', () => {
                valueAccessor = accessor($scope.optionValue) || _.identity;
            });
            $scope.$watch('renderBy', () => {
                renderer = accessor($scope.renderBy) || toString;
            });

            function selectedIndex() {
                return _.findIndex($scope.options, (mv) => {
                    return valueAccessor(mv) === $scope.model;
                });
            }

            function couldGoPrev() {
                return $scope.options.length > 0 && $scope.model !== valueAccessor($scope.options[0]);
            }

            function couldGoNext() {
                const l = $scope.options.length;
                return l > 0 && $scope.model !== valueAccessor($scope.options[l - 1]);
            }

            function selectAt(idx) {
                if (idx < 0 || idx >= $scope.options.length) {
                    return;
                }
                const modelValue = valueAccessor($scope.options[idx]);
                if ($scope.selectBy) {
                    $scope.selectBy(modelValue);
                } else {
                    $scope.model = modelValue;
                }
            }

            $scope.couldGoPrev = couldGoPrev;
            $scope.couldGoNext = couldGoNext;
            $scope.displayValue = () => {
                const selIdx = selectedIndex();
                return selIdx < 0 ? '' : renderer($scope.options[selIdx]);
            };
            $scope.goBack = () => {
                let idx = selectedIndex();
                if (idx < 0) {
                    idx = $scope.options.length - 1;
                } else {
                    idx -= 1;
                }
                selectAt(idx);
            };
            $scope.goForward = () => {
                let idx = selectedIndex();
                if (idx < 0) {
                    idx = 0;
                } else {
                    idx += 1;
                }
                selectAt(idx);
            };
        }]
    };
}];