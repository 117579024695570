import _ from 'lodash';
import $ from 'jquery';
import FormUtil from 'edge/platform/modelvalidation/FormUtil';

let dependentCoverages = [];
let opsInSequence;

function getDependentCoverages() {
    return dependentCoverages;
}

function getDependentCoveragesForLOB(CoverageService, jobNumber) {
    return CoverageService.getDependentCoveragesForLob(jobNumber).then((depCovs) => {
        dependentCoverages = depCovs;

        return depCovs;
    });
}

function getSequencer() {
    opsInSequence = [$.Deferred().resolve()];
    return resolveRequestInSequence;
}

function updateDependentCoverages(coverages, covsToUpdate) {
    const depLOBCoverages = _.filter(coverages, (coverage) => {
        return _.find(dependentCoverages, (depCov) => {
            return depCov.name === coverage.publicID || depCov.dependencies.includes(coverage.publicID);
        });
    });

    _.each(covsToUpdate, (coverage) => {
        coverage = _.find(depLOBCoverages, {publicID: coverage.publicID}) || coverage;

        return coverage;
    });
}

function updateCoverages(updatedCoverage, coverages, covsToUpdate) {
    let updatedCoverages = coverages;
    if (updatedCoverage) {
        const updatedDependentCov = _.find(dependentCoverages, (depCov) => {
            return depCov.name === updatedCoverage.publicID || _.find(updatedCoverage.terms, {type: depCov.name});
        });
        if (updatedDependentCov) {
            updatedCoverages = _.filter(coverages, (coverage) => {
                return updatedDependentCov.name === coverage.publicID || updatedDependentCov.dependencies.includes(coverage.publicID);
            });
        } else {
            const cov = _.find(updatedCoverages, {publicID: updatedCoverage.publicID});

            if (cov) {
                updatedCoverages = [cov];
            }
        }
    }

    // Remove any coverage that the server has made unavailable
    _.remove(covsToUpdate, (cov) => {
        return !_.find(coverages, {publicID: cov.publicID});
    });

    // Add any coverages that the server has made available
    _.each(coverages, (cov, i) => {
        if (!_.find(covsToUpdate, {publicID: cov.publicID})) {
            covsToUpdate.splice(i, 0, cov);
        }
    });

    return _.each(covsToUpdate, (coverage, i) => {
        coverage = _.find(updatedCoverages, {publicID: coverage.publicID}) || coverage;

        covsToUpdate.splice(i, 1, coverage);
        return coverage;
    });
}

function resolveRequestInSequence(operation) {
    const lastOpInSequence = _.last(opsInSequence);
    const sequencedOp = operation.then(res => lastOpInSequence.then(() => res)); // Force resolve in sequence

    opsInSequence.push(sequencedOp);

    return sequencedOp;
}

function validateForm(form) {
    if (form) {
        FormUtil.markFormsSubmitted(form);

        if (!form.valid) {
            if (form.invalidInputs[0]) {
                $('html, body').animate({
                    scrollTop: $(`#${form.invalidInputs[0]}`).offset().top - 10 // offset by 10px
                }, 300);
            }

            return false;
        }
    }

    return true;
}

export default () => {

    return {
        getDependentCoverages,
        getDependentCoveragesForLOB,
        updateCoverages,
        updateDependentCoverages,
        getSequencer,
        validateForm
    };
};