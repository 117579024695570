export default ['LocaleService', (LocaleService) => ({
    format(address) {
        switch (LocaleService.getAddressConfig().pcfMode) {
            case 'BigToSmall':
                return [
                    address.postalCode,
                    address.state,
                    address.cityKanji ? address.cityKanji : address.city,
                    address.addressLine1Kanji ? address.addressLine1Kanji : address.addressLine1,
                    address.addressLine2Kanji ? address.addressLine2Kanji : address.addressLine2,
                    address.addressLine3Kanji ? address.addressLine3Kanji : address.addressLine3
                ].filter(x => x).join(', ');
            case 'PostCodeBeforeCity':
                return [
                    address.addressLine1,
                    address.addressLine2,
                    address.addressLine3,
                    address.postalCode,
                    address.city,
                    address.state
                ].filter(x => x).join(', ');
            default:
                return [
                    address.addressLine1,
                    address.addressLine2,
                    address.addressLine3,
                    address.city,
                    address.state,
                    address.postalCode
                ].filter(x => x).join(', ');
        }
    }
})];