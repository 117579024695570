import cssUtil from 'gw-portals-util-js/CssUtil';
import templateStr from 'text!./ContactDirective.html';
import mediaSidebarContactExtenderService from 'plugin?media-sidebar-contact-extender!edge/platform/widgets/mediasidebar/ContactExtenderDefaultService';

import styles from './ContactDirective-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(templateStr, styles);

export default [
    '$q',
    '$injector',
    'TranslateService', ($q, $injector, TranslateService) => {
        return {
            restrict: 'E',
            template: hashedTemplate,
            link: ($scope, $element) => {
                $scope.getContactError = () => {
                    return TranslateService.instant($scope.errorMsg);
                };

                // show a placeholder
                // in case of image loading error
                $element.find('.gw-photo img').on('error', () => {
                    $scope.image = '';
                });

                $scope.loading = $q.resolve(mediaSidebarContactExtenderService($injector, $scope));
            }
        };
    }
];