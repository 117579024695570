import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './navigation-tile-hashed.scss';
import template from 'text!../templates/generic-tile.html';

export default [() => ({
    restrict: 'E',
    replace: true,
    scope: {
        state: '&',
        callback: '&',
        flavor: '@'
    },
    transclude: {
        'header': '?gwTileHeader',
        'footer': '?gwTileFooter',
        'content': 'gwTileContent'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'tiles.tilesUtilFunctions', ($scope, tilesUtilFunctions) => {
        $scope.tileType = 'navigation';
        $scope.customFlavor = $scope.flavor || 'default';
        $scope.allow = {
            header: true,
            content: true,
            actions: false,
            footer: true
        };

        $scope.clickCallback = () => {
            const currentState = $scope.state();
            // Can invoke callback when not in the active state
            if (currentState !== 'active') {
                $scope.callback();
            }
        };

        $scope.getActiveState = tilesUtilFunctions.getActiveStateFunction($scope.state);
        $scope.getStyle = tilesUtilFunctions.getStyleFunction(styles);
    }]
})];
