import _ from 'underscore';
import defaultTemplate from 'text!./templates/address-details-default.html';
import bigToSmallTemplate from 'text!./templates/address-details-BigToSmall.html';
import postCodeBeforeCityTemplate from 'text!./templates/address-details-PostCodeBeforeCity.html';

export default ['LocaleService', (LocaleService) => {
    const addressConfig = LocaleService.getAddressConfig();
    let template;

    switch (addressConfig.pcfMode) {
        case 'BigToSmall':
            template = bigToSmallTemplate;
            break;
        case 'PostCodeBeforeCity':
            template = postCodeBeforeCityTemplate;
            break;
        default:
            template = defaultTemplate;
            break;
    }

    return {
        restrict: 'AE',
        template,
        scope: {
            address: '=',
            allContacts: '=',
            readonly: '@',
            addressType: '@',
            displayCountry: '@',
            defaultCountryCode: '@',
            disableZipCode: '=',
            disableCity: '=',
            disableState: '=',
            showLoading: '=',
            hideFields: '='
        },

        link: (scope, element, attrs) => {
            attrs.$observe('readonly', (value) => {
                scope.readonly = value === 'true';
            });

            const defaultCountryCode = scope.defaultCountryCode || LocaleService.getCountry().code;

            scope.isVisible = (fieldName) => {
                let visible = addressConfig.fieldNames.indexOf(fieldName) >= 0;
                if (scope.hideFields) {
                    visible = visible &&
                        !(_.contains(scope.hideFields, fieldName) || _.contains(_.map(scope.hideFields, (idx) => {
                            return `${idx}Kanji`;
                        }), fieldName));
                }
                return visible;
            };

            scope.shouldShow = (value) => {
                return angular.isDefined(value.value);
            };

            function syncAddressData() {
                if (scope.address.value) {
                    scope.address.country.value = scope.address.country.value || defaultCountryCode;
                } else {
                    scope.address.value = {
                        country: defaultCountryCode
                    };
                }
            }

            scope.$watch('address.value', syncAddressData);
        }
    };
}];