let inactivityTimerId;
let inactivityTimerNewId;

/**
 * ANDIE
 * This service handles the frontend session. If the user does not interact with the Portal for an x amount of time,
 * the journey is interrupted and the user must start a new one.
 * The length of the session is defined by the QNBSessionLengthInMin_itb script parameter in PC
 */

export default ['StateUtils', '$state', 'ModalService', (StateUtils, $state, ModalService) => {
    let inactivityInterval;
    let modalOpen = false;
    let modalInstance;
    let isValidState = true;


    function init(inactivityIntervalMins) {
        if (angular.isDefined(inactivityInterval)) { // Only initialise once
            return;
        }
        inactivityInterval = inactivityIntervalMins * 1000 * 60;
        initializeInActivityTimer();
    }

    function initializeInActivityTimer() {
        inactivityTimerId = window.setTimeout(() => {
            if (modalOpen && modalInstance) {
                modalInstance.close();
            }
            deactivateFunctionTimer();
            const flowModel = StateUtils.activeDataForState(true).flowModel;
            if (flowModel) { // Attempt a graceful wizard exit if possible
                flowModel.jumpToExit('sessionTimedOut_AND');
            } else { // Go direct otherwise
                $state.go('sessionTimedOut_AND');
            }
        }, inactivityInterval);
    }

    const inactivityIntervalTimer = 5 * 60 * 1000;

    function initializeInActivityTimerNew() {
        inactivityTimerNewId = window.setInterval(() => {
            if (!modalOpen && isValidState) {
                const messageKeys = {
                    title: 'platform.flow.angularhelpers.timeout.Are you still there?',
                    message: 'platform.flow.angularhelpers.timeout.we.are...'
                };
                modalInstance = ModalService.showTimeoutWarning(messageKeys.title, messageKeys.message);
                modalOpen = true;
                modalInstance.result.then(() => {
                }, () => {
                    modalOpen = false;
                });               
            }
        }, inactivityIntervalTimer);
    } 
    document.addEventListener('mousemove', () => {
        if (!modalOpen && isValidState) {
            if (inactivityTimerNewId) {
                window.clearInterval(inactivityTimerNewId); 
            }
            initializeInActivityTimerNew(); 
        }
    });
    document.addEventListener('keydown', () => {
        if (!modalOpen && isValidState) {
            if (inactivityTimerNewId) {
                window.clearInterval(inactivityTimerNewId); 
            }
            initializeInActivityTimerNew();     
        }
    });

    function deactivateFunctionTimer() {
        if (inactivityTimerNewId) {
            window.clearInterval(inactivityTimerNewId); 
        }
        isValidState = false;
        inactivityTimerNewId = undefined;
    }
    function activateFunctionTimer() {
        if (!inactivityTimerNewId && !isValidState) {
            isValidState = true;
            initializeInActivityTimerNew();
        }
    }

    function resetInactivityTimer() {
        if (angular.isDefined(inactivityInterval)) { // Reset only if initialised
            deactivateTimers();
            initializeInActivityTimer();
        }
    }

    function deactivateTimers() {
        if (inactivityTimerId) {
            window.clearTimeout(inactivityTimerId);
            inactivityTimerId = null;
        }
    }

    return {
        init,
        // This method should be called anytime the frontend session has to be refreshed.
        // Currently on every XHR call and state change
        resetInactivityTimer,
        deactivateTimers,
        deactivateFunctionTimer,
        activateFunctionTimer
    };
}];