import cssUtil from 'gw-portals-util-js/CssUtil';

import styles from './PropertyCode-hashed.scss';
import template from 'text!./PropertyCode.html';

export default [() => ({
    restrict: 'E',
    scope: {
        match: '=',
        query: '='
    },
    template: cssUtil.hashTemplate(template, styles)
})];