import BaseCtrl from 'edge/platform/base/BaseCtrl';
import {wizardParentState} from '../RouteConfig';
import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import './BuildingCoverages.scss';

const fn = ($scope, $state, $rootElement, StateUtils, $q, ModalService, $stateParams, CPCoverablesService, CoveragesUtil) => {
    $scope.flowModel = $state.get(wizardParentState).data.flow;

    const ctrl = Object.create(BaseCtrl);

    const model = StateUtils.activeDataForState(true).model;
    $scope.jobID = model.quoteID ? model.quoteID.value : model.jobID.value;
    $scope.sessionID = SessionUtil.getSessionUUID(model);

    ctrl.init = () => {
        $rootElement.scrollTop(0);

        $scope.dependentCoverages = CoveragesUtil.getDependentCoverages();
        const sequencer = CoveragesUtil.getSequencer();
        $scope.location = $stateParams.model.selectedLocation;
        $scope.building = $stateParams.model.selectedBuilding;

        $scope.onCovChange = (updatedCoverage) => {
            return updateBuildingCoverages(updatedCoverage);
        };

        $scope.editLocation = () => {
            $scope.flowModel.jumpToStep('SelectLocation');
        };

        $scope.editBuilding = () => {
            $scope.flowModel.jumpToStep('BuildingInfo');
        };

        $scope.next = (doNext) => {
            if (CoveragesUtil.validateForm($scope.form.newBuildingCoverages)) {
                $scope.onCovChange()
                    .then(() => doNext($stateParams.model), () => {
                        doNext();
                    })
                    .catch(() => {
                        ModalService.showError('quoteandbind.common.flow.error.Error', 'quoteandbind.common.flow.error.An error has occurred...');
                    });
            } else {
                doNext();
            }
        };

        const updateBuildingCoverages = (updatedCoverage) => {
            $scope.coveragesLoading = true;
            $scope.updateBuildingCoverages = sequencer(CPCoverablesService.updateCPBuildingCoverages(
                $scope.jobID,
                $scope.location.publicID,
                $scope.building,
                SessionUtil.getSessionUUID(model)
            ));

            $scope.updateBuildingCoverages
                .then((result) => {
                    CoveragesUtil.updateCoverages(updatedCoverage, result.coverages, $scope.building.coverages);
                })
                .catch(() => {
                    // CPUI: the message to be displayed on this modal needs to be reviewed. Sanja will have input on it.
                    ModalService.showError('quoteandbind.cp.directives.cp-building-directive.Building not saved', 'quoteandbind.cp.Please check for errors and try again');
                })
                .then(() => {
                    executeValidations();
                })
                .finally(() => {
                    $scope.coveragesLoading = false;
                });

            return $scope.updateBuildingCoverages;
        };

        const executeValidations = () => {
            $scope.errorMessage = undefined;
            if ($scope.building.coverages.every(c => !c.selected)) {
                $scope.errorMessage = 'quoteandbind.cp.Please select at least one coverage to proceed.';
            }
        };

    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$state', '$rootElement', 'StateUtils', '$q', 'ModalService', '$stateParams', 'qnb.cpCoverablesService', 'CoveragesUtil'];

export default fn;
