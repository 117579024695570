class SpreadsheetProcessorResultCtrl {

    constructor($scope) {

        this.$scope = $scope;
        this.errorDataPageSize = 20;

        if (this.resultData && this.resultData.data && this.resultData.data.errors) {
            this.$scope.spreadsheetUploadErrors = this.resultData.data.errors;

        }

        this.$scope.$watch('spreadsheetUploadErrors', (errorData) => {
            if (errorData) {
                if (errorData.length >= this.errorDataPageSize) {
                    $scope.errorLimit = this.errorDataPageSize;
                    $scope.showLessErrorsLink = false;
                    $scope.showErrorPageLinks = true;
                } else {
                    $scope.showErrorPageLinks = false;
                    $scope.showLessErrorsLink = true;
                }
            }
        });
    }

    showLessSpreadSheetUploadErros() {
        this.$scope.errorLimit = this.errorDataPageSize;
        this.$scope.showLessErrorsLink = false;
    }

    showMoreSpreadSheetUploadError() {
        this.$scope.errorLimit = this.$scope.spreadsheetUploadErrors.length;
        this.$scope.showLessErrorsLink = true;
    }

    spreadsheetProcessor(isUpdateSpreadsheetScenario) {
        this.processor(isUpdateSpreadsheetScenario);
    }
}

SpreadsheetProcessorResultCtrl.$inject = ['$scope', '$state', '$injector', '$http', 'ModalService', '$document', '$timeout', 'StateUtils'];

export default SpreadsheetProcessorResultCtrl;