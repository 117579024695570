import _ from 'lodash';
import assert from 'assert';

import PropertyInfo from './PropertyInfo';

export default class DataItem {
    constructor(dataItemDescriptorObj, propertyInfo, onChange) {
        assert(propertyInfo instanceof PropertyInfo);

        this._descriptor = dataItemDescriptorObj;
        this._propInfo = propertyInfo;
        this._onChange = typeof onChange === 'function' ? onChange : _.noop;
        this._hasItemChanged = false;
    }

    asCommited() {
        if (this._asCommited) {
            return this._asCommited;
        }

        const CurrentDataItem = this.constructor;
        this._asCommited = new CurrentDataItem(this.describe(), this.getPropertyInfo(), null);
        return this.asCommited();
    }

    getVM() {
        if (this._vm) {
            return this._vm;
        }

        const item = this;
        this._vm = {
            aspects: {
                get valid() {
                    return item.isValid();
                },
                get required() {
                    return item.getPropertyInfo().isRequired();
                }
            },

            get value() {
                return item.getValue();
            },
            set value(val) {
                item.setValue(val);
            }
        };

        return this.getVM();
    }

    _getValue() {
        return this._descriptor[DataItem.typeToPropertyName(this.getType())];
    }

    getValue() {
        if (this.isDrafted()) {
            return this._draft;
        }

        return this._getValue();
    }

    _setValue(val) {
        this._descriptor[DataItem.typeToPropertyName(this.getType())] = val;
        this._onChange();
        this._hasItemChanged = true;
    }

    setValue(val) {
        if (this.isDrafted()) {
            this._draft = val;
        } else {
            this._setValue(val);
        }

        return this;
    }

    getChangedStatus() {
        return this._hasItemChanged;
    }

    resetChangedStatus() {
        this._hasItemChanged = false;
    }

    draft() {
        this._drafted = true;
        this._draft = _.cloneDeep(this._getValue());
    }

    isDrafted() {
        return this._drafted;
    }

    _undraft() {
        this._drafted = false;
    }

    commit() {
        assert(this.isDrafted(), 'Can not commit item that is not drafted');
        this._setValue(this._draft);
        this._undraft();
    }

    undo() {
        assert(this.isDrafted(), 'Can not undo on an item that is not drafted');
        this._undraft();
    }

    getPropertyInfo() {
        return this._propInfo;
    }

    getType() {
        return this._propInfo.getType();
    }

    getName() {
        return this._propInfo.getName();
    }

    getAvailableValues() {
        return this._propInfo.getAvailableValues();
    }

    isEditable() {
        return this._propInfo.isEditable();
    }


    getErrors() {
        // Can only check requiredness at this point
        if (!this.getPropertyInfo().isRequired()) {
            return [];
        }

        // uniqueness check
        if (this.getPropertyInfo().isUnique() && this.getValue()) {
            return (this.getPropertyInfo().checkDataItemIsUniqueInSchedule(this)) ? [] : ['policycommon.directives.schedules.dataItem.This field must be unique'];
        }

        if (this.getValue()) {
            return []; // Required and has value
        }

        return ['policycommon.directives.schedules.dataItem.This is a required field'];
    }

    isValid() {
        return this.getErrors().length === 0;
    }

    describe() {
        return this._descriptor;
    }

    static typeToPropertyName(type) {
        switch (type) {
            case 'INTEGER':
                return 'integerValue';
            case 'TYPEKEY':
                return 'typeCodeValue';
            case 'STRING':
                return 'stringValue';
            case 'DATE':
                return 'dateValue';
            default:
                throw new Error(`No mapping for type: ${type}`);
        }
    }
}