import templateStr from 'text!./pm-important-info-ulr.html';

const TRANSLATION_KEY = 'and.quoteandbind.pm.directives.templates.pm-important-info';
const BRAND_SECTIONS = {tbb: 9, dgt: 10, itb: 10};

export default ['brandingData', (brandingData) => {
    return {
        restrict: 'E',
        scope: {},
        template: templateStr,
        controller: ['$scope', ($scope) => {
            if (!BRAND_SECTIONS[brandingData]) {
                return;
            }

            $scope.accordion = [];
            for (let i = 0; i < BRAND_SECTIONS[brandingData]; i++) {
                $scope.accordion.push({
                    title: `${TRANSLATION_KEY}.${i}.Title.ULR`,
                    content: `${TRANSLATION_KEY}.${i}.Content.ULR`
                });
            }
        }]
    };
}];