import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./DefinitionList.html';
import styles from './DefinitionList-hashed.scss';

export default {
    transclude: true,
    replace: true,
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        title: '@'
    }
}; 
