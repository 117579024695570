import cssUtil from 'gw-portals-util-js/CssUtil';

import templateStr from 'text!./input-inline-button.html';
import styles from './input-inline-button-hashed.scss';
import _ from 'lodash';

const hashedTemplate = cssUtil.hashTemplate(templateStr, styles);

export default [() => {
    return {
        restrict: 'EA',
        template: hashedTemplate,
        transclude: true,
        scope: {
            'showNestedButton': '=',
            'buttonLabel': '@',
            'onClick': '='
        },
        controller: ['$scope', ($scope) => {
            $scope.onClick = $scope.onClick || _.noop();
        }]
    };
}];