const defaultStepLabel = (step) => {
    console.error(`No label defined for step ${step.name}`);
    return 'Dummy label';
};

export default function (step) {
    let _labelFn;

    /**
     * Provide a function to associate a label with a step
     *
     * @param {Function} labelFn - Function to return a translated label for a step,
     *                              The arguments to this function are model along with extraArgs map
     *                              The function should return a string.
     *                              If no function is provided the default behavior is to return 'Dummy label'.
     * @returns {Function}
     *
     */
    const labelBuilder = function (labelFn) {
        if (arguments.length === 0) {
            return _labelFn ? _labelFn() : defaultStepLabel(step);
        }
        _labelFn = labelFn;

        return step;
    };

    labelBuilder.hasLabel = function () {
        return Boolean(_labelFn);
    };

    return labelBuilder;
}
