import ValidationUtil_AND from 'gw-portals-util-js/ValidationUtil_AND'; // ANDIE

/**
 * When the user navigates forwards or backwards in the flow we want to know if we should
 * set the model back to the last snapshot.
 *
 * This module checks if the model invalid and if so alerts the user that model will be reset
 * and data will be lost.
 *
 * @param {Object} ModalMessageComponent
 * @param {Object} [Q]
 * @param {Object} [snapshotResetWarningMessages]
 *
 * @returns {Function}
 */
export default function (ModalMessageComponent, Q, snapshotResetWarningMessages) {
    const defaultSnapshotResetWarningMessages = {
        title: 'platform.flow.angularhelpers.SnapshotResetConfirmation.Are you sure you want to leave this page?',
        message: 'platform.flow.angularhelpers.SnapshotResetConfirmation.Changes entered on this page may be lost'
    };

    /**
     * @param {Object} [model]
     * @returns {Promise} if promise is rejected then the model should be reset with the last snapshot otherwise the
     *                        snapshot should be set to the model
     */
    return function (model) {
        if (model && model.aspects && model.aspects.valid && model.aspects.subtreeValid) {
            return Q.resolve(model);
        }
        console.log(ValidationUtil_AND.getInvalidFields(model)); // ANDIE
        const messageKeys = snapshotResetWarningMessages || defaultSnapshotResetWarningMessages;
        const confirmPromise = ModalMessageComponent.showWarningWithSaveButton(messageKeys.title, messageKeys.message).result;

        return confirmPromise.then(() => Q.reject('confirmed'), () => Q.reject('cancelled'));
    };
}
