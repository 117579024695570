import template from 'text!./pm-quote-key-cover.html';
import controller from '../PmQuoteAddOnCtrl';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '=',
            coverageCode: '<',
            isBeforeNewDocDate: '<'
        },
        template,
        controller
    };
}];