import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import locales from 'app-locale';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';

export default ['$translate', 'LOCALE_INFO', '$rootScope', 'tmhDynamicLocale', '$q', 'LOCALE_CHANGE_EVENT_NAME', ($translate, LOCALE_INFO, $rootScope, tmhDynamicLocale, $q, LOCALE_CHANGE_EVENT_NAME) => {
    /* --- VARS --- */
    const localesObj = LOCALE_INFO.locales;
    const missingLocales = {};


    // get locales and locales display names
    const _LOCALES = Object.keys(localesObj);
    if (!_LOCALES || _LOCALES.length === 0) {
        console.error('There are no _LOCALES provided');
    }
    const _LOCALES_DISPLAY_NAMES = [];
    _LOCALES.forEach((locale) => {
        _LOCALES_DISPLAY_NAMES.push(localesObj[locale]);
    });

    /**
     * A promise for when angular-translate is loaded and translations are provided
     * @type {{Q.Promise}}
     */
    const translateReadyDeferred = $q.defer();

    const localeInfo = locales;
    const currencySymbols = {
        'aud': '$',
        'cad': '$',
        'eur': '\u20ac',
        'gbp': '\u00a3',
        'jpy': '\u00a5',
        'rub': '\u20BD',
        'usd': '$',
        'chf': 'Fr.',
        'cny': '\u00a5',
        'nzd': '$'
    };

    const currencySymbolList = _.values(currencySymbols);

    let currentLocale;
    let currentLanguageVariant = '';

    if (ApplicationFeatures.hasFeature('languageVariant')) {
        currentLanguageVariant = ApplicationFeatures.getAllFeatures().languageVariant;
    }

    /* --- METHODS --- */
    const checkLocaleIsValid = (locale) => {
        return _LOCALES.indexOf(locale) !== -1;
    };

    const onSettingLocale = (locale, languageVariant) => {
        document.documentElement.setAttribute('lang', locale.replace('_', '-')); // set "lang" attribute to html
        $rootScope.$broadcast(LOCALE_CHANGE_EVENT_NAME, locale, languageVariant);
    };

    const setLocale = (locale) => {
        if (!checkLocaleIsValid(locale)) {
            console.error(`Locale name "${locale}" is invalid`);
            return;
        }
        startLoadingAnimation();

        currentLocale = locale;
        $translate.use(locale);

        whenTranslateReady().then(stopLoadingAnimation);
    };

    function whenTranslateReady() {
        return translateReadyDeferred.promise;
    }

    /**
     * Stop application loading animation when translations are loaded
     */
    const $html = angular.element('html');
    const LOADING_CLASS = 'app-loading';

    // remove the app main loader when app is starting
    // LOADING_CLASS would be removed when it's started and l10n is loaded
    $('.gw-app-main-loader').remove();

    function startLoadingAnimation() {
        $html.addClass(LOADING_CLASS);
    }

    function stopLoadingAnimation() {
        $html.removeClass(LOADING_CLASS);
    }

    function getTargetLocaleCode(languageCode, countryCode) {
        const possibleLocaleCode = `${languageCode}-${countryCode}`;

        if (missingLocales[possibleLocaleCode]) {
            return missingLocales[possibleLocaleCode];
        }

        return possibleLocaleCode;
    }

    /**
     * Set locale for moment.js (is used in datepicker component)
     * moment.js is loaded with locale included => synchronous change
     * @param {string} targetLocaleCode - locale code you wish to change moments locale to.
     * @param {string} languageCode - language code for target locale
     */
    function setLocaleForMomentJs(targetLocaleCode, languageCode) {
        if (moment.locale(targetLocaleCode) !== targetLocaleCode) { // if locale isn't provided by moment.js
            if (languageCode === 'zh') { // no moment zh.js default to zh-cn.js
                moment.locale('zh-cn');
            } else {
                moment.locale(languageCode); // language
            }
        }
    }

    /* --- EVENTS --- */
    $rootScope.$on('$translateChangeSuccess', (event, data) => {
        onSettingLocale(data.language, currentLanguageVariant);

        const languageComponents = data.language.split('_');
        const languageCode = languageComponents[0];
        const countryCode = localeInfo.country.code.toLowerCase();

        const targetLocaleCode = getTargetLocaleCode(languageCode, countryCode);

        setLocaleForMomentJs(targetLocaleCode, languageCode);

        if (tmhDynamicLocale.get() !== targetLocaleCode) {
            tmhDynamicLocale.set(targetLocaleCode); // load Angular locale
        }
        translateReadyDeferred.resolve();
    });

    $rootScope.$on('$localeChangeSuccess', () => {
        stopLoadingAnimation();
    });

    $rootScope.$on('$localeChangeError', (event, data) => {
        const comp = data.split('-');
        if (comp.length > 1) {
            missingLocales[data] = comp[0];
            tmhDynamicLocale.set(comp[0]);
        } else {
            stopLoadingAnimation();
        }
    });

    /* --- INIT --- */
    function onInit() {
        // store current locale
        // currentLocale = $translate.proposedLanguage() || $translate.use(); // because of async loading
        currentLocale = 'en_GB'; // ANDIE force locale to en_GB
        setLocale(currentLocale); // ANDIE force locale to en_GB
        onSettingLocale(currentLocale, currentLanguageVariant);
    }

    function hideMaskOnJPLocale() {
        return (localeInfo.phone.UserDefaultPhoneCountry === 'JP' ? '' : localeInfo.phone.sample);
    }

    onInit();

    /* --- RETURN --- */
    return {
        getCountry: () => {
            return localeInfo.country;
        },
        getLocaleDisplayName: () => {
            return localesObj[currentLocale];
        },
        setLocaleByDisplayName: (localeDisplayName) => {
            setLocale(_LOCALES[
                _LOCALES_DISPLAY_NAMES.indexOf(localeDisplayName) // get locale index
            ]);
        },
        getCurrentLocale: () => {
            return currentLocale;
        },

        getCurrentLanguage: () => {
            if (currentLocale) {
                if (currentLocale.indexOf('_') > -1) {
                    return currentLocale.split('_').shift();
                }

                return currentLocale;
            }

            throw new Error('Unknown language');
        },

        getLocalesDisplayNames: () => {
            return _LOCALES_DISPLAY_NAMES;
        },
        getCurrencySymbol: (currency) => {
            return currencySymbolList.includes(currency)
                ? currency
                : currencySymbols[currency] || '\u00A4';
        },
        getNameConfig: () => {
            return localeInfo.name;
        },
        getAddressConfig: () => {
            return localeInfo.address;
        },
        getLocaleInfo: () => {
            return localeInfo;
        },
        getRegionSamplePhoneNumber: () => {
            return hideMaskOnJPLocale();
        },
        getConfiguredPhoneRegion: () => {
            return localeInfo.phone.UserDefaultPhoneCountry;
        },

        whenTranslateReady: whenTranslateReady
    };
}];
