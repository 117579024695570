/*
 * Default value constants for common configuration variables.
 */

/**
 * The default timeout for AJAX service requests. This value
 * can vary between different servers, so it should be
 * overridden using the configuration property `httpSettings.timeout`.
 * @type {number}
 */
const DEFAULT_HTTP_TIMEOUT = 120000; // 2 minutes

export { DEFAULT_HTTP_TIMEOUT };