/**
 * Self-containing directive to make linking to FAQ much quicker. It may be used in two ways:
 * 1) To point to a FAQ page itself - just use it like typical attribute-based directive e.g. <span gw-faq-link></span>
 * 2) To point to a specific section/topic - use it as an additional attribute and specify section's/topic's
 *    id as the 'fragment' attribute, e.g. <span gw-faq-link fragment="id-of-some-specific-topic"></span>.
 *    You may find the id in FAQ's structure config file.
 *
 * What's more, when you want to point to a specific section/question, you may decide to provide the content of the link
 * itself in one of the two ways:
 * 1) Default - just use it as mentioned above and link's content would be populated as section's title or topic's
 *    inline-question. Actual label's content would be taken from the FAQ's structure config file.
 * 2) Custom - nest whatever you want, e.g. <span gw-faq-link fragment="id-of-some-specific-topic">any custom content</span>
 *
 * When FAQ's feature is turned off in the config file, all the links will just disappear.
 */
import templateStr from 'text!./templates/faq-link.html';
import BaseCtrl from 'edge/platform/base/BaseCtrl';

export default () => {
    const controller = ($scope, FaqService) => {
        const ctrl = Object.create(BaseCtrl);

        ctrl.defineWatches = () => {
            const contentId = $scope.fragment;
            if (!contentId) {
                return;
            }

            $scope.$watch('defaultContent', defaultContentIndicator => {
                if (defaultContentIndicator) {
                    $scope.inlineContent = FaqService.getLinkContent(contentId);
                }
            });
        };

        return ctrl.create($scope, FaqService);
    };
    controller.$inject = ['$scope', 'FaqService'];

    return {
        replace: false,
        transclude: true,
        scope: {
            fragment: '@',
            newpage: '<',
        },
        restrict: 'A',
        template: templateStr,
        link: (scope, element) => {
            scope.defaultContent = element.find('[ng-transclude] *').length === 0;
        },
        controller
    };
};
