/**
 * Defines a breadcrumbs nav
 *
 * <gw-row>
 *   <gw-row-item>Account: Ray Newton</gw-row-item>
 *   <gw-row-item>Policy: Personal Auto 0726849902</gw-row-item>
 * </gw-row>
 */
import template from 'text!./templates/Row.html';
import layout from './LayoutFactory';

export default layout('Row', template, (partial) => {
    partial('Item', '<div class="gw-row-layout__item" gw-partial-target></div>');
});
