export default ['$q', 'JsonRPCService', function ($q, JsonRPCService) {
    return {
        autofillBasedOnAdddress(address) {
            const deferred = $q.defer();
            JsonRPCService.send('addressLookup', 'lookup', 'lookupAddressUsingString', [address]).then((addressResult) => deferred.resolve(addressResult), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },

        autofillBasedOnPostalCode(address) {
            const deferred = $q.defer();
            JsonRPCService.send('addressLookup', 'lookup', 'lookupAddressUsingObject', [address]).then((addressResult) => deferred.resolve(addressResult), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        lookupAddressUsingStringAndFilterByPostalCode(addressStr, postalCode) {
            const deferred = $q.defer();
            JsonRPCService.send('addressLookup', 'lookup', 'lookupAddressUsingStringAndFilterByPostalCode', [addressStr, postalCode]).then(
                (addressResult) => {
                    deferred.resolve(addressResult);
                },
                (error, status, headers) => {
                    deferred.reject([error, status, headers]);
                }
            );
            return deferred.promise;
        },
        lookupAddressByPostCode_AND(postCode) {
            return JsonRPCService.send('addressLookup', 'lookup', 'lookupAddressByPostCode', [postCode]);
        },
        getAddressDetails_AND(id) {
            return JsonRPCService.send('addressLookup', 'lookup', 'getAddressDetails', [id]);
        }
    };
}];