import BaseCtrl from 'edge/platform/base/BaseCtrl';
import Submission from 'edge/quoteandbind/common/models/Submission';
import moment from 'moment';
import ProductCodeUtil from '../util/ProductCodeUtil';

const fn = function (
    $scope, $state, $stateParams, LoadSaveService, wizardFlowService,
    qnbCompositeFlowDefn, ViewModelService, viewModelContext, returnStateFn,
    errorStateFn, effectiveDateStateFn, $rootScope, brandingData
) {
    const ctrl = Object.create(BaseCtrl);
    const parentWizardState = 'baseTransactionWizard';
    ctrl.init = function () {
        const stepToStateMapping = {
            returnHome: model => returnStateFn(model),
            confirmation: (model) => ['qnbconfirmation', {
                quoteSummary: model.value,
                returnStateInfo: returnStateFn(model.value)
            }],
            error: model => errorStateFn(model),
            effectiveDate: model => effectiveDateStateFn(model)
        };
        const flowfactory = wizardFlowService(qnbCompositeFlowDefn, parentWizardState, stepToStateMapping);
        if ($stateParams.quoteID && $stateParams.postalCode && $stateParams.dateOfBirth) { // Resume existing submission
            const submissionLoadPromise = LoadSaveService.retrieveSubmission($stateParams);
            $scope.submissionLoadPromise = submissionLoadPromise;
            submissionLoadPromise.then(submissionData => {
                if (moment(submissionData.baseData.periodStartDate).isBefore(moment(), 'day')) {
                    if ($rootScope.fromAMP_AND) { // clear startdate when coming from amp with a passed date
                        submissionData.baseData.periodStartDate = undefined;
                    } else {
                        const now = new Date();
                        submissionData.baseData.periodStartDate = {
                            year: now.getFullYear(),
                            month: now.getMonth(),
                            day: now.getDate()
                        };
                    }
                }
                $rootScope.productCode = submissionData.baseData.productCode;
                const submissionVM = ViewModelService.create(submissionData, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', viewModelContext);
                flowfactory.resume(submissionVM, $stateParams);
            }).catch(() => {
                $state.go('contactUs'); // Display technical error screen if retrieval fails
            });
        } else {
            var productCode = ProductCodeUtil.getProductCode(brandingData)
            const submission = new Submission(productCode);
            $rootScope.productCode = submission.baseData.productCode;
            if ($stateParams.account) {
                submission.baseData.accountHolder = $stateParams.account;
                submission.baseData.accountNumber = $stateParams.account.accountNumber;
                submission.baseData.policyAddress = $stateParams.account.primaryAddress;
            }

            const submissionModel = ViewModelService.create(submission, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', viewModelContext);
            flowfactory.start(submissionModel, $stateParams);
        }
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams', 'qnb.LoadSaveService', 'WizardFlowService',
    'qnb.CompositeFlowDefn', 'ViewModelService', 'qnb.ViewModelCtx', 'qnb.returnStateFn',
    'qnb.errorStateFn', 'qnb.effectiveDateStateFn', '$rootScope', 'brandingData'
];

export default fn;
