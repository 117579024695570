import ModalWindowTemplate from 'text!../templates/modal-window-template.html';

export default ['$timeout', ($timeout) => {
    return {
        restrict: 'EA',
        scope: {
            index: '@',
            fullScreen: '<',
            wide: '<',
            parentScope: '='
        },
        replace: true,
        transclude: true,
        template: ModalWindowTemplate,
        link(scope, element, attrs) {
            scope.windowClass = attrs.windowClass || '';
            scope.animationType = attrs.animationType || '';

            // focus a freshly-opened modal
            element[0].focus();

            $timeout(() => {
                // trigger CSS transitions
                scope.animate = true;
            });
        }
    };
}];