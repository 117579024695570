import templateStr from 'text!./templates/scheduled-coverages.html';
import Schedule from '../models/schedules/Schedule';
import _ from 'underscore';


export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            model: '=gwPcScheduledCoverages',
            onChange: '=',
            loading: '=',
            readonly: '<',
            flavor: '@'
        },
        link: (scope) => {
            scope.$watch('flavor', f => {
                scope._flavor = f || 'default';
            });
            scope.$watch('model', () => {
                scope.schedules = _.map(scope.model, (aSchedule) => {
                    return new Schedule(aSchedule, scope.onChange);
                });
            });
        }
    };
}];
