import template from 'text!./templates/Default.html';

const definition = {
    restrict: 'E',
    scope: {
        property: '&propertyInfo',
        hideRequired: '<'
    },
    template: template
};


const directive = [() => definition];
directive.definition = definition;

export default directive;