import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = function ($scope, $element) {

    //ANDIE
    /* function setElementVisibility(isVisible) {
        $scope.sideBarMenuVisible = isVisible;
        $element.toggleClass('gw-slidebar-menu-open', isVisible);
    }*/
    const ctrl = Object.create(BaseCtrl);
    /* $element.toggleClass('gw-slidebar-menu-open', true);*/
    /* ctrl.init = () => {
        $scope.sideBarMenuVisible = false;
    };

    ctrl.toggleSideBarMenu = () => {
        setElementVisibility(!$scope.sideBarMenuVisible);
    };

    ctrl.hideSideBarMenu = () => {
        setElementVisibility(false);
    };

    ctrl.showSideBarMenu = () => {
        setElementVisibility(true);
    };

    ctrl.isSideBarMenuVisible = () => {
        return $scope.sideBarMenuVisible;
    };*/

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$element'];

export default fn;
