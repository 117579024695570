/**
 * Capitalise first letter and any letter following a space, a hyphen or an apostrophe.
 * @returns {{restrict: string, require: string, link: link}}
 */
export default () => {
    const CAPITALISE_PATTERN = /(^[a-z])|([-'][a-z])|(\s[a-z])/g;

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            // Remove - and ' at both ends of string
            const trim = function () {
                parse(modelCtrl.$modelValue, true);
            };
            // Parse and set model
            const parse = function (inputValue, trimInvalidChars) {
                if (inputValue === undefined) return;
                let result = inputValue;
                // Enforce only chars, - and '
                result = result.replace(/[^a-z\s'-]/ig, '');
                // Remove illegal chars at both ends
                if (trimInvalidChars === true) {
                    result = result.replace(/(^[-'\s]+)|([-'\s]+$)/g, '');
                }
                // Enforce single spacing
                result = result.replace(/\s\s+/g, ' ');
                // Trim to 25 chars
                result = result.substring(0, 25);
                // Capitalise
                result = result.replace(CAPITALISE_PATTERN, (match)=> match.toUpperCase());
                if (result !== inputValue) {
                    modelCtrl.$setViewValue(result);
                    modelCtrl.$render();
                }
                return result;
            };
            // To remove illegal chars at both ends. Has to be onBlur because these chars are valid, just not at
            // the beginning nor at the end.
            element.on('blur', trim);
            modelCtrl.$parsers.push(parse); // Set parser
            parse(scope[attrs.ngModel]); // capitalize initial value
        }
    };
};