import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./FindAddress.html';
import styles from './FindAddress-hashed.scss';
import _ from 'lodash';

const hashedTemplate = cssUtil.hashTemplate(template, styles);
const THROTTLE_TIME = 400;

export default {
    template: hashedTemplate,
    bindings: {
        model: '=',
        readonly: '<'
    },
    controller: ['addresslookup.AddressLookupService', 'ViewModelService', 'qnb.ViewModelCtx', '$filter',
        function controller(AddressLookupService, ViewModelService, viewModelContext, $filter) {
            const $ctrl = this;
            $ctrl.minimumCharactersBeforeSearching = 3;
            // A new VM for postcode to reuse dto validation rules in the search input
            $ctrl.search = ViewModelService.create({}, 'pc', 'edge.capabilities.address.dto.AddressDTO', viewModelContext).postalCode;
            $ctrl.search.value = $ctrl.model.postalCode.value || '';
            let previousLookupUpPostCode = '';
            let previousChosenAddress = null;
            $ctrl.chosenAddressVM = {
                value: null,
                aspects: {
                    required: true,
                    get valid() {
                        return !!$ctrl.chosenAddressVM.value;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };

            function getAddressLine1DisplayName(address = $ctrl.model.value) {
                let addressLine1DisplayName = address.addressLine1;
                if (address.organisationName_itb) {
                    addressLine1DisplayName = `${address.organisationName_itb} ${addressLine1DisplayName}`;
                }
                if (address.departmentName_itb) {
                    addressLine1DisplayName = `${address.departmentName_itb} ${addressLine1DisplayName}`;
                }
                return addressLine1DisplayName;
            }

            function selectAddress(address) {
                $ctrl.model.value = address;
                $ctrl.search.value = address.postalCode;
                $ctrl.model.value.addressLine1 = getAddressLine1DisplayName();
            }

            //  Called on each turn of the digest cycle.
            $ctrl.$doCheck = () => {
                // Chosen address
                if ($ctrl.chosenAddressVM.value && previousChosenAddress !== $ctrl.chosenAddressVM.value) {
                    previousChosenAddress = $ctrl.chosenAddressVM.value;
                    $ctrl.inFlight = true;
                    AddressLookupService.getAddressDetails_AND($ctrl.chosenAddressVM.value.id_itb)
                        .then(address => selectAddress(address))
                        .catch(()=> {
                            $ctrl.noAddressFoundErrorMessage = $filter('translate')('and.platform.widgets.findaddress.components.FindAddress.SystemError');
                        })
                        .finally(() => { $ctrl.inFlight = false; });
                }
            };

            $ctrl.clearSearch = () => {
                $ctrl.addressSearchResults = null;
                $ctrl.clearModel();
            };

            $ctrl.clearModel = () => {
                $ctrl.model.value = {};
                $ctrl.chosenAddressVM.value = null;
            };

            $ctrl.searchAddressText = _.throttle(() => {
                if (!$ctrl.isLookupEnabled() || $ctrl.readonly) {
                    return;
                }
                $ctrl.inFlight = true;
                AddressLookupService.lookupAddressByPostCode_AND($ctrl.search.value)
                    .then(searchResults => {
                        if (searchResults.errorDescription) {
                            $ctrl.noAddressFoundErrorMessage = searchResults.errorDescription;
                            $ctrl.clearSearch();
                        } else {
                            $ctrl.noAddressFoundErrorMessage = null;
                            $ctrl.addressSearchResults = searchResults.matches.map(searchObject => searchObject.address);
                        }
                        $ctrl.clearModel();
                        previousLookupUpPostCode = $ctrl.search.value;
                        // Select address automatically if there's only one result
                        if ($ctrl.addressSearchResults && $ctrl.addressSearchResults.length === 1) {
                            $ctrl.chosenAddressVM.value = $ctrl.addressSearchResults[0];
                        }
                    })
                    .catch(()=> {
                        $ctrl.noAddressFoundErrorMessage = $filter('translate')('and.platform.widgets.findaddress.components.FindAddress.SystemError');
                    })
                    .finally(() => { $ctrl.inFlight = false; });
            }, THROTTLE_TIME);

            $ctrl.isPostCodeValid = () => ($ctrl.search.value.length >= $ctrl.minimumCharactersBeforeSearching) &&
                    ($ctrl.search.aspects.valid && $ctrl.search.aspects.subtreeValid);

            $ctrl.isLookupEnabled = () => ($ctrl.inFlight !== true) && (previousLookupUpPostCode !== $ctrl.search.value)
                && $ctrl.isPostCodeValid();
        }]
};
