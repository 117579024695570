import templateStr from 'text!./pm-quote-review-car.html';

export default ['brandingData', '$translate', 'StateUtils', '$anchorScroll', (brandingData, $translate, StateUtils, $anchorScroll) => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '<'
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            const flowModel = StateUtils.activeDataForState(true).flowModel;


            // Vehicle
            $scope.vehicleVM = $scope.quoteData.submission.lobData.personalMotor.coverables.pmVehicles.children[0];
            $scope.vehicle = $scope.vehicleVM.value;
            if ($scope.vehicle.registrationNumber) {
                $scope.vehicleDisplayName = `${$scope.vehicle.registrationNumber} ${$scope.vehicle.make} ${$scope.vehicle.model}`;
            } else {
                $scope.vehicleDisplayName = `${$scope.vehicle.make} ${$scope.vehicle.model}`;
            }
            $scope.vehicleDateOfPurchase = `${$scope.vehicleVM.purchasedMonth.value.name} ${$scope.vehicle.purchasedYear}`;

            // Boolean values
            $scope.getBooleanDisplayName = (value) => {
                return value === true ?
                    $translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review-card.Yes') :
                    $translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review-card.No');
            };

            $scope.generalMods = false
            $scope.bodyWorkMods = false
            $scope.engineMods = false
            $scope.wheelsMods = false
            $scope.showGeneralMods = () => {
                if ($scope.generalMods){
                    $scope.generalMods = false
                } else {
                    $scope.generalMods = true
                }
            }

            $scope.showBodyWorkMods = () => {
                if ($scope.bodyWorkMods){
                    $scope.bodyWorkMods = false
                } else {
                    $scope.bodyWorkMods = true
                }
            }

            $scope.showEngineMods = () => {
                if ($scope.engineMods){
                    $scope.engineMods = false
                } else {
                    $scope.engineMods = true
                }
            }

            $scope.showWheelsMods = () => {
                if ($scope.wheelsMods){
                    $scope.wheelsMods = false
                } else {
                    $scope.wheelsMods = true
                }
            }

            $scope.generalModificatonStrings = $scope.vehicle.generalAfterMarketMods.map(mod => {
                return mod
            })
            $scope.bodyWorkModificatonStrings = $scope.vehicle.bodyWorkMods.map(mod => {
                return mod
            })
            $scope.engineModificatonStrings = $scope.vehicle.engineMods.map(mod => {
                return mod
            })
            $scope.wheelsModificatonStrings = $scope.vehicle.wheelsOrTyresMods.map(mod => {
                return mod
            })

            $scope.jumpToStep = (stepName) => flowModel.jumpToStep(stepName);
            $scope.jumpToSelection = (id) => $anchorScroll(id);
        }]
    };
}];