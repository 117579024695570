import controller from './FieldComponentizerCtrl';

export default {
    controller,
    bindings: {
        field: '<',
        viewModel: '=',
        presentationModelPath: '@?'
    },
    require: {
        formCtrl: '?^form'
    }
};