import _ from 'lodash';

import addBuildingWizardRoutes from './AddBuildingWizard/RouteConfig';

import InitialScreenCtrl from './InitialScreen/InitialScreen';
import InitialScreenTemplate from 'text!./InitialScreen/InitialScreen.html';

import BuildingsListCtrl from './BuildingsList/BuildingsListCtrl';
import BuildingsListTemplate from 'text!./BuildingsList/BuildingsList.html';

import ViewBuildingCtrl from './ViewBuilding/ViewBuildingCtrl';
import ViewBuildingTemplate from 'text!./ViewBuilding/ViewBuilding.html';

import EditBuildingCtrl from './EditBuilding/EditBuildingCtrl';
import EditBuildingTemplate from 'text!./EditBuilding/EditBuilding.html';

import ViewLocationCtrl from './ViewLocation/ViewLocationCtrl';
import ViewLocationTemplate from 'text!./ViewLocation/ViewLocation.html';

import EditLocationCtrl from './EditLocation/EditLocationCtrl';
import EditLocationTemplate from 'text!./EditLocation/EditLocation.html';

import EditCoveragesCtrl from './EditCoverages/EditCoveragesCtrl';
import EditCoveragesTemplate from 'text!./EditCoverages/EditCoverages.html';

import CPSpreadsheetProcessorCtrl from './CPSpreadsheetProcessor/CPSpreadsheetProcessorCtrl';
import CPSpreadsheetProcessorTemplate from 'text!./CPSpreadsheetProcessor/CPSpreadsheetProcessor.html';

export default _.assign({
    'cpBuildingWizardInitialScreen': {
        parent: 'cpLocationsAndBuildings',
        template: InitialScreenTemplate,
        controller: InitialScreenCtrl,
        controllerAs: '$ctrl',
        params: {
            spreadsheetProcessorResult: null
        }
    },
    'cpBuildingWizardBuildingsList': {
        parent: 'cpLocationsAndBuildings',
        template: BuildingsListTemplate,
        controller: BuildingsListCtrl,
        controllerAs: '$ctrl',
        params: {
            spreadsheetProcessorResult: null
        }
    },
    'cpBuildingWizardViewBuilding': {
        parent: 'cpLocationsAndBuildings',
        template: ViewBuildingTemplate,
        controller: ViewBuildingCtrl,
        controllerAs: '$ctrl',
        params: {
            publicID: null, isBuildingUpdated: false, isCoverageOpen: true, areCoveragesUpdated: false, isLocationUpdated: false
        }
    },
    'cpBuildingWizardEditBuilding': {
        parent: 'cpLocationsAndBuildings',
        template: EditBuildingTemplate,
        controller: EditBuildingCtrl,
        controllerAs: '$ctrl',
        params: {building: null}
    },
    'cpBuildingWizardViewLocation': {
        parent: 'cpLocationsAndBuildings',
        template: ViewLocationTemplate,
        controller: ViewLocationCtrl,
        controllerAs: '$ctrl',
        params: {publicID: null}
    },
    'cpBuildingWizardEditLocation': {
        parent: 'cpLocationsAndBuildings',
        template: EditLocationTemplate,
        controller: EditLocationCtrl,
        controllerAs: '$ctrl',
        params: {publicID: null, buildingPublicID: null},
        resolve: {
            originState: ['$state', ($state) => {
                // remember the state we came from so we can return when cancelling
                return {
                    state: $state.current,
                    params: $state.params
                };
            }]
        }
    },
    'cpBuildingWizardEditCoverages': {
        parent: 'cpLocationsAndBuildings',
        template: EditCoveragesTemplate,
        controller: EditCoveragesCtrl,
        controllerAs: '$ctrl',
        params: {buildingID: null}
    },
    'cpBuildingWizardSpreadsheetScreen': {
        parent: 'cpLocationsAndBuildings',
        template: CPSpreadsheetProcessorTemplate,
        controller: CPSpreadsheetProcessorCtrl,
        controllerAs: '$ctrl',
        params: {
            manualAddBuildingDelegator: null,
            callbackState: null
        }
    }

}, addBuildingWizardRoutes);
