import Submission from 'edge/quoteandbind/common/models/Submission';
import VinExtractionUtil from 'edge/quoteandbind/quickquote/pa/util/VinExtractionUtil';

export default ['$q', 'JsonRPCService', 'qnb.ErrorHandlingService', 'qnb.endpointConfig', 'qnb.xCenterRouteConfig', 'addressSettings', 'addresslookup.AddressSearchUtil', 'VinLookupService', 'ModalService',
    function ($q, JsonRPCService, ErrorHandlingService, endpointConfig, xCenterRouteConfig, addressSettings, AddressSearchUtil, VinLookupService, ModalService) {

        const xCenterRoute = xCenterRouteConfig;
        const endpoint = endpointConfig.slquote;

        return {
            findAddress(addressString) {
                return AddressSearchUtil.lookupAddress(addressString, addressSettings);
            },

            createSubmission(submissionDTO) {
                const deferred = $q.defer();
                JsonRPCService.send(xCenterRoute, endpoint, 'createBaseOffering', [submissionDTO]).then(
                    (response) => {
                        submissionDTO = new Submission(response);
                        ErrorHandlingService.checkSessionUUID(submissionDTO, deferred);
                        return deferred.resolve(submissionDTO);
                    },
                    (error, status, headers) => {
                        return deferred.reject([error, status, headers]);
                    }
                );
                return deferred.promise;
            },

            /**
             * Extracts the text from an image which contains a VIN Number
             * The text is then sent to the VinLookupService which looks up the
             * vehicle and returns to us a promise which when resolved contains a Vehicle object
             * @param {File} vinImage
             * @returns {q.Promise}
             */
            extractTextAndLookupVin(vinImage) {
                try {
                    const vinToLookup = VinExtractionUtil.extractTextFromVinImage(vinImage);
                    return VinLookupService.autofillBasedOnVIN(vinToLookup);
                } catch (err) {
                    ModalService.showError(
                        'quoteandbind.quickquote.common.services.QuickQuoteService.Error',
                        'quoteandbind.quickquote.common.services.QuickQuoteService.Vehicle ID Number not found. Please try again.'
                    );
                }
            }
        };
    }
];
