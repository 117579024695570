import TabTemplate from 'text!../templates/tab-template.html';
/**
 * @ngdoc directive
 * @name ui.bootstrap.tabs.directive:tab
 * @restrict EA
 *
 * @param {string=} heading The visible heading, or title, of the tab. Set HTML headings with {@link ui.bootstrap.tabs.directive:tabHeading tabHeading}.
 * @param {string=} select An expression to evaluate when the tab is selected.
 * @param {boolean=} active A binding, telling whether or not this tab is selected.
 * @param {boolean=} disabled A binding, telling whether or not this tab is disabled.
 *
 * @description
 * Creates a tab with a heading and content. Must be placed within a {@link ui.bootstrap.tabs.directive:tabset tabset}.
 *
 * @example
 <example module="ui.bootstrap">
 <file name="index.html">
 <div ng-controller="TabsDemoCtrl">
 <button class="gw-btn" ng-click="items[0].active = true">
 Select item 1, using active binding
 </button>
 <button class="gw-btn" ng-click="items[1].disabled = !items[1].disabled">
 Enable/disable item 2, using disabled binding
 </button>
 <br />
 <tabset>
 <tab heading="Tab 1">First Tab</tab>
 <tab select="alertMe()">
 <tab-heading><i class="icon-bell"></i> Alert me!</tab-heading>
 Second Tab, with alert callback and html heading!
 </tab>
 <tab ng-repeat="item in items"
 heading="{{item.title}}"
 disabled="item.disabled"
 active="item.active">
 {{item.content}}
 </tab>
 </tabset>
 </div>
 </file>
 <file name="script.js">
 function TabsDemoCtrl($scope) {
      $scope.items = [
        { title:"Dynamic Title 1", content:"Dynamic Item 0" },
        { title:"Dynamic Title 2", content:"Dynamic Item 1", disabled: true }
      ];

      $scope.alertMe = function() {
        setTimeout(function() {
          alert("You've selected the alert tab!");
        });
      };
    };
 </file>
 </example>
 */

/**
 * @ngdoc directive
 * @name ui.bootstrap.tabs.directive:tabHeading
 * @restrict EA
 *
 * @description
 * Creates an HTML heading for a {@link ui.bootstrap.tabs.directive:tab tab}. Must be placed as a child of a tab element.
 *
 * @example
 <example module="ui.bootstrap">
 <file name="index.html">
 <tabset>
 <tab>
 <tab-heading><b>HTML</b> in my titles?!</tab-heading>
 And some content, too!
 </tab>
 <tab>
 <tab-heading><i class="icon-heart"></i> Icon heading?!?</tab-heading>
 That's right.
 </tab>
 </tabset>
 </file>
 </example>
 */

export default ['$parse', function ($parse) {
    return {
        require: '^tabset',
        restrict: 'EA',
        replace: true,
        template: TabTemplate,
        transclude: true,
        scope: {
            heading: '@',
            onSelect: '&select', // This callback is called in contentHeadingTransclude
            // once it inserts the tab's content into the dom
            onDeselect: '&deselect'
        },
        controller() {
            // Empty controller so other directives can require being 'under' a tab
        },
        compile(elm, attrs, transclude) {
            return function postLink(scope, _elm, _attrs, tabsetCtrl) {
                let getActive;
                let setActive;
                if (_attrs.active) {
                    getActive = $parse(_attrs.active);
                    setActive = getActive.assign;
                    scope.$parent.$watch(getActive, (value, oldVal) => {
                        // Avoid re-initializing scope.active as it is already initialized
                        // below. (watcher is called async during init with value ===
                        // oldVal)
                        if (value !== oldVal) {
                            scope.active = !!value;
                        }
                    });
                    scope.active = getActive(scope.$parent);
                } else {
                    getActive = angular.noop;
                    setActive = getActive;
                }

                scope.$watch('active', (active) => {
                    // Note this watcher also initializes and assigns scope.active to the
                    // _attrs.active expression.
                    setActive(scope.$parent, active);
                    if (active) {
                        tabsetCtrl.select(scope);
                        scope.onSelect();
                    } else {
                        scope.onDeselect();
                    }
                });

                scope.disabled = false;
                if (_attrs.disabled) {
                    scope.$parent.$watch($parse(_attrs.disabled), (value) => {
                        scope.disabled = !!value;
                    });
                }

                scope.select = function () {
                    if (!scope.disabled) {
                        scope.active = true;
                    }
                };

                tabsetCtrl.addTab(scope);
                scope.$on('$destroy', () => {
                    tabsetCtrl.removeTab(scope);
                });


                // We need to transclude later, once the content container is ready.
                // when this link happens, we're inside a tab heading.
                scope.$transcludeFn = transclude;
            };
        }
    };
}];