import templateStr from 'text!./pm-quote-contact.html';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '='
        },
        template: templateStr
    };
}];