import $ from 'jquery';
import _ from 'underscore';
import tempStr from 'text!./RssDirective.html';
import DateUtil from 'gw-portals-util-js/DateUtil';

import './RssDirective.scss';
/*
 Specifications:
 Rss 2: http://cyber.law.harvard.edu/rss/rss.html
 Atom: https://tools.ietf.org/html/rfc4287
 */

const FEED_NODES = [
    'feed', // Atom specification
    'channel' // Rss specification
];

const ENTRY_NODES = [
    'entry', // Atom specification
    'item' // Rss specification
];

const TITLE_NODES = ['title'];
const LINK_NODES = ['link', 'atom:link'];

const SUMMARY_NODES = [
    'summary', // Atom specification
    'description' // Rss specification
];

const TIMESTAMP_NODES = [
    'published', // Atom specification
    'pubDate' // Rss specification
];

const getNodeByTypeList = (root, nodeTypes) => {
    return _.chain(nodeTypes)
        .map((type) => {
            return root.getElementsByTagName(type);
        })
        .find((elements) => {
            return elements.length > 0;
        })
        .first()
        .valueOf();
};

const getChildNodesByTypeList = (root, nodeTypes) => {
    return _.chain(nodeTypes)
        .map((type) => {
            return _.toArray(root.childNodes).filter((node) => {
                return node && node.tagName && node.tagName.toLowerCase() === type;
            });
        })
        .flatten()
        .valueOf();
};

const getHost = (url) => {
    const a = document.createElement('a');
    a.href = url;
    return a.hostname;
};

export default ['BaseUtils', '$filter', function (BaseUtils, $filter) {
    const $translate = $filter('translate');
    const FeedEntry = function (title, summary, link, timestamp) {
        _.forEach(arguments, (arg) => {
            BaseUtils.assert(!arg || typeof arg === 'string', 'Entry arguments should be strings');
        });

        this._title = _.unescape(title);
        this._summary = _.unescape(summary);
        this._link = _.unescape(link);

        this._ts = new Date(timestamp);
    };
    FeedEntry.prototype = {
        getTitle() {
            return this._title;
        },
        getSummary() {
            return this._summary;
        },
        getLink() {
            return this._link;
        },
        getPublishedDate() {
            const today = DateUtil.currentDate();
            if (this._ts.toDateString() === today.toDateString()) {
                return $translate('platform.date.today');
            }

            const yesterday = DateUtil.currentDate();
            // the return value of setDate is seconds since Unix time start. setDate also mutates the object.
            yesterday.setDate(today.getDate() - 1);
            if (this._ts.toDateString() === yesterday.toDateString()) {
                return $translate('platform.date.yesterday');
            }

            return this._ts;
        }
    };

    return {
        restrict: 'E',
        template: tempStr,

        controller: ['$scope', function ($scope) {
            BaseUtils.assert.stringKey($scope, 'feed', 'Feed url should be specified');

            $scope.loaded = false;
            $.get($scope.feed).then((document) => {
                BaseUtils.assert(document instanceof Document, 'Specified url is not an XML'); // Xml would be parsed into a document by jquery

                const feed = getNodeByTypeList(document, FEED_NODES);
                BaseUtils.assert(feed, 'Invalid feed');

                const siteLinks = getChildNodesByTypeList(feed, LINK_NODES);
                const selfLink = _.find(
                    siteLinks,
                    (link) => {
                        const rel = link.getAttribute('rel');
                        return rel && rel.toLowerCase() === 'self';
                    }
                ) || siteLinks.shift(); // rel="self" or first link

                const titleNode = getChildNodesByTypeList(feed, TITLE_NODES).shift();
                $scope.fav = selfLink ? `https://${getHost(selfLink.getAttribute('href') || selfLink.textContent)}/favicon.ico` : null;
                $scope.title = titleNode ? titleNode.textContent : null;
                $scope.entries = _.chain(ENTRY_NODES)
                    .map((nodeType) => {
                        return _.toArray(feed.getElementsByTagName(nodeType));
                    })
                    .flatten()
                    .first($scope.limit || Infinity)
                    .map((entry) => {
                        const title = getNodeByTypeList(entry, TITLE_NODES);
                        const summary = getNodeByTypeList(entry, SUMMARY_NODES);
                        const link = getNodeByTypeList(entry, LINK_NODES);
                        const timestamp = getNodeByTypeList(entry, TIMESTAMP_NODES);

                        return new FeedEntry(
                            title ? title.textContent : null,
                            summary ? summary.textContent : null,
                            link ? link.getAttribute('href') || link.textContent : null,
                            timestamp ? timestamp.textContent : null
                        );
                    })
                    .valueOf();

                $scope.loaded = true;
                $scope.$digest();
            });
        }]
    };
}];