import 'gw-portals-policytransaction-common-angular';
import 'angularRouter';
import states from './config/RouteConfig';
import BuildingsWizard from './buildingsWizard';

import CPConfirmation from './buildingsWizard/directives/cp-confirmation-directive/CPConfirmationDirective';
import CPWizardStepCircles from './buildingsWizard/directives/wizardStepCircles/WizardStepCircles';

import CPBuildingInputDirective from './buildingsWizard/directives/cp-building-input-directive/CPBuildingInputDirective';

import CPPremisesSummary from './buildingsWizard/directives/cp-premises-summary-directive/CPPremisesSummaryDirective';
import CPLocationSummary from './buildingsWizard/directives/cp-premises-summary-directive/cp-location-summary-directive/CPLocationSummaryDirective';
import CPBuildingSummary from './buildingsWizard/directives/cp-premises-summary-directive/cp-building-summary-directive/CPBuildingSummaryDirective';
import CPBuildingCoveragesSummary from './buildingsWizard/directives/cp-premises-summary-directive/cp-building-coverages-summary-directive/CPBuildingCoveragesSummaryDirective';

import CPLocationViewBuildingList from './buildingsWizard/directives/cp-view-location-building-list-directive/ViewLocationBuildingListDirective';

export default angular.module('gw-portals-cp-components-angular', ['edge.transaction.common.app'])

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider) => {
        for (const stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, states[stateName]);
            }
        }
    }])
    .directive('gwQnbCpConfirmation', CPConfirmation)
    .directive('gwQnbCpWizardStepCircles', CPWizardStepCircles)
    .directive('gwQnbCpBuildingInputDirective', CPBuildingInputDirective)
    .directive('gwCpPremisesSummary', CPPremisesSummary)
    .directive('gwCpLocationSummary', CPLocationSummary)
    .directive('gwCpBuildingSummary', CPBuildingSummary)
    .directive('gwCpBuildingCoveragesSummary', CPBuildingCoveragesSummary)
    .directive('gwCpLocationViewBuildingList', CPLocationViewBuildingList)
    .component('gwBuildingsWizard', BuildingsWizard);
