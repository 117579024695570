import BasicTile from './Generic/GenericTile';
import ContentTile from './Content/ContentTile';
import NavigationTile from './Navigation/NavigationTile';
import WizardTileWrapper from './Wizard/wrapper/_WizardTile';
import WizardTile from './Wizard/WizardTile';
import TileHeaderIcon from './tileLayout/HeaderIcon/tileHeaderIcon';
import TileHeaderText from './tileLayout/HeaderText/tileHeaderText';
import TileLabel from './tileLayout/Label/tileLabel';
import TileSummaryRow from './tileLayout/SummaryRow/tileSummaryRow';
import WizardActionTile from './WizardAction/WizardActionTile';
import TileContent from './tileLayout/Content/TileContent';
import TileAction from './tileLayout/Actions/TileAction';
import tilesUtilFunctions from './common/tilesUtilFunctions';

export default angular.module('edge.platform.widgets.tiles', [TileContent, TileAction])
    .directive('gwBasicTile', BasicTile)
    .directive('gwContentTile', ContentTile)
    .directive('gwNavigationTile', NavigationTile)
    .directive('gwWizardTileWrapper', WizardTileWrapper)
    .directive('gwWizardTile', WizardTile)
    .directive('gwWizardActionTile', WizardActionTile)
    .directive('gwTileHeaderIcon', TileHeaderIcon)
    .directive('gwTileHeaderText', TileHeaderText)
    .directive('gwTileLabel', TileLabel)
    .directive('gwTileSummaryRow', TileSummaryRow)
    .factory('tiles.tilesUtilFunctions', [() => tilesUtilFunctions]);
