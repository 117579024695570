import ActionMessageModuleName from './ActionMessage';
import BulletListModuleName from './BulletList';
import BreadcrumbsModuleName from './Breadcrumbs';
import RowModuleName from './Row';
import Menu from './Menu/Menu';

// Layouts definitions return a string module name
// Apply those as dependencies
export default angular.module('edge.platform.widgets.layouts', [ActionMessageModuleName, BulletListModuleName,
    BreadcrumbsModuleName, RowModuleName, Menu]);
