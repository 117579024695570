import {PhoneNumberUtil} from 'libphonenumber';

export default (LocaleService) => {

    return {
        /**
         * Will pretty-print a phone number according
         * to the provided region
         *
         * If number is not valid or libphonenumber is absent
         * will return an original string
         *
         * @param {String} phoneNumber
         * @param {String} countryCode
         * @returns {String}
         */
        prettyPrint(phoneNumber, countryCode) {
            const phoneUtils = PhoneNumberUtil.getInstance();
            const phoneRegion = countryCode || LocaleService.getConfiguredPhoneRegion();
            try {
                return phoneUtils.format(phoneUtils.parse(phoneNumber, phoneRegion));
            } catch (e) {
                // if there is some problem in libphonenumber e.g. the number is not valid
                // returns the original number
                return phoneNumber;
            }
        },
        /**
         * Will call libphonenumber's isPossibleNumber function to check if
         * the phone number provided is a valid phone number
         * for the country in question
         *
         * @param {String} propertyValue
         * @param {String} countryCode
         * @returns {String}
         */
        isPossibleNumber(propertyValue, countryCode) {
            const phoneUtils = PhoneNumberUtil.getInstance();
            let returnVal = true;
            let extPos;
            let number;
            if (angular.isDefined(propertyValue) && propertyValue !== null && propertyValue !== '') {
                extPos = propertyValue.indexOf(' x');
                number = extPos > 0 ? propertyValue.substring(0, extPos) : propertyValue;
                number = number.replace(/[-_]/g, '');
                try {
                    returnVal = phoneUtils.isPossibleNumber(phoneUtils.parse(number, countryCode || LocaleService.getConfiguredPhoneRegion()));
                } catch (e) {
                    return false; // Suppress log error message from libphonenumber when number is not valid
                }
            }
            return returnVal;
        }
    };
};
