import flowConfigUtil from 'edge/quoteandbind/common/util/FlowConfigUtil';

export default (function (AvailabilityService, LoadSaveService, $q, TranslateService, ViewModelService, ViewModelContext, ActionMessageUtil, EventHub, $rootScope) {
    var translateFn = TranslateService.instant;
    var wrappedLoadSave = flowConfigUtil.wrappedLoadSave(ViewModelService, ViewModelContext);

    var ACTION_MSG = {
        saveSubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to save submission',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to save quote submission.'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Saving quote...'
            }
        }
    };

    var ACTIONS = {
        saveDraftSubmission: function saveDraftSubmission(_ref) {
            var submission = _ref.value;
            return wrappedLoadSave(LoadSaveService.saveSubmission, submission);
        }
    };

    return {
        contactDetails: function contactDetails(step, junction) {
            step('pmContactDetails', true).onNext.goTo('pmContactDetailsComplete').skipOnResumeIf(function (submissionVM) {
                // Higher precedence - if bound, skip.
                if (submissionVM.baseData.periodStatus.value.code === 'Bound') {
                    return true;
                }
                // Higher precedence - if not bound and period start date is invalid, don't skip.
                if (submissionVM.baseData.periodStartDate.aspects.valid !== true) {
                    return false;
                }
                // Lower precedence, check for aggs and AMP quotes.
                if (submissionVM.value.isAggsQuoted_AND() === true) {
                    return true;
                }
                // Resume button on a "Draft" submission - returns to 'About you' Page
                if ($rootScope.fromAMP_AND && submissionVM.baseData.periodStatus.value.code === 'Draft') {
                    return false;
                }
                // buy now or amend button on "Quoted" submission -
                if ($rootScope.fromAMP_AND && submissionVM.baseData.periodStatus.value.code === 'Quoted') {
                    if (angular.isDefined($rootScope.fromAMP_AND.amendJourney)) {
                        // amendJourney == true (amend button), returns to "About you" page
                        if ($rootScope.fromAMP_AND.amendJourney === true) {
                            return false;
                        }
                        // otherwise goes to "Quote" page
                        return true;
                    }
                    // otherwise goes to "Quote" page
                    return true;
                }
                return true;
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Your Info');
            });

            junction('pmContactDetailsComplete');
        },
        coverables: function coverables(step, junction) {
            step('pmDriverDetails', true).onNext.doAction(ACTIONS.saveDraftSubmission, ACTION_MSG.saveSubmission).thenGoTo('pmCoverablesComplete', 'error').skipOnResumeIf(function (_ref2) {
                var submission = _ref2.value;

                if (submission.isAggsQuoted_AND() === true) {
                    return true;
                }
                var driver = submission.lobData.personalMotor.coverables.pmDrivers[0];
                return driver && driver.licenseType;
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Drivers');
            }).data({
                asWizardNavigationSteps: ['pmDriverSummary'] // Display in navigation as...
            });

            junction('pmCoverablesComplete');
        },
        driversSummary: function driversSummary(step, junction) {
            step('pmDriverSummary', true).onNext.doAction(ACTIONS.saveDraftSubmission, ACTION_MSG.saveSubmission).thenGoTo('pmDriversSummaryComplete', 'error').skipOnResumeIf(function (_ref3) {
                var submission = _ref3.value;

                if (submission.isAggsQuoted_AND() === true) {
                    return true;
                }
                return submission.lobData.personalMotor.coverables.pmDrivers.filter(function (driver) {
                    return driver.mainDriver === true;
                }).length === 1;
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Drivers');
            }).data({
                asWizardNavigationSteps: ['pmDriverDetails'], // Display in navigation as...
                regularNavigation: true
            });
            junction('pmDriversSummaryComplete');
        },
        editDrivers: function editDrivers(step, junction) {
            // Review steps
            step('pmEditContactDetails').onNext.doAction(ACTIONS.saveDraftSubmission, ACTION_MSG.saveSubmission).thenGoTo('pmEditPHComplete', 'error').isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).data({
                asWizardNavigationSteps: ['pmContactDetails'] // Display in navigation as...
            });

            step('pmEditDriverDetails').onNext.doAction(ACTIONS.saveDraftSubmission, ACTION_MSG.saveSubmission).thenGoTo('pmEditDriverComplete', 'error').isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).data({
                asWizardNavigationSteps: ['pmDriverDetails', 'pmDriverSummary'] // Display in navigation as...
            });

            junction('pmEditDriverComplete');
            junction('pmEditPHComplete');
        },
        legalPaymentDetails: function legalPaymentDetails(step, junction) {
            step('legalPaymentStep1', true).onNext.goTo('legalPaymentStep2').skipOnResumeIf(function (_ref4) {
                var submission = _ref4.value;

                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            step('legalPaymentStep2')
            // Direct Debit Step is not for annual payment type
            .onNext.branch(function (model) {
                return model.value.isAnnualPaymentPlan_AND() === true ? 'legalPaymentStep4' : 'legalPaymentStep3';
            }).skipOnResumeIf(function (_ref5) {
                var submission = _ref5.value;


                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            step('legalPaymentStep3').onNext.goTo('legalPaymentStep4').skipOnResumeIf(function (_ref6) {
                var submission = _ref6.value;

                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            step('legalPaymentStep4').onNext.goTo('legalPaymentDetailsComplete').skipOnResumeIf(function (_ref7) {
                var submission = _ref7.value;

                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            junction('legalPaymentDetailsComplete');
        },
        vehicleDetails: function vehicleDetails(step, junction) {
            step('pmVehicleDetails', true).onNext.doAction(ACTIONS.saveDraftSubmission, ACTION_MSG.saveSubmission).thenGoTo('pmVehicleDetailsComplete', 'error').skipOnResumeIf(function (_ref8) {
                var submission = _ref8.value;

                if (submission.baseData.periodStatus === 'Bound') {
                    return true;
                }
                var vehicle = submission.lobData.personalMotor.coverables.pmVehicles[0];
                var driver = submission.lobData.personalMotor.coverables.pmDrivers[0];
                return vehicle && driver && submission.baseData.periodStatus === 'Quoted';
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Vehicles.' + $rootScope.productCode);
            });

            junction('pmVehicleDetailsComplete');
        }
    };
});