// Use in the gw-pl-accordion-group template to indicate where you want the heading to be transcluded
// You must provide the property on the gw-pl-accordion-group controller that will hold the transcluded element
// <div class="gw-pl-accordion-group">
//   <div class="gw-pl-accordion-heading" ><a ... gw-pl-accordion-transclude="gwPlHeading">...</a></div>
//   ...
// </div>

export default [() => {
    return {
        require: '^gwPlAccordionGroup',
        link: (scope, element, attr, controller) => {
            scope.$watch(() => {
                return controller[attr.gwPlAccordionTransclude];
            }, (heading) => {
                if (heading) {
                    element.html('');
                    element.append(heading);
                }
            });
        }
    };
}];