import _ from 'lodash';

import buildingsWizardRoutes from '../buildingsWizard/RouteConfig';

export default _.assign({
    cpLocationsAndBuildings: {
        parent: 'baseTransactionWizard',
        template: '<div ui-view></div>'
    },
}, buildingsWizardRoutes);
