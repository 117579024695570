import templateStr from 'text!./pm-billing-address.html';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            submissionVM: '=submissionVm'
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            $scope.isAnnual = $scope.submissionVM.value.isAnnualPaymentPlan_AND();
            $scope.firstNameTooltip = {
                content: 'and.quoteandbind.pm.views.paymentstep.2.monthly.tooltip.First Name',
                trigger: 'click'
            };
            $scope.surnameTooltip = {
                content: 'and.quoteandbind.pm.views.paymentstep.2.monthly.tooltip.surname',
                trigger: 'click'
            };
            $scope.storeCardForFutureTooltip = {
                content: 'and.quoteandbind.pm.views.paymentstep.2.annual.StoreCardForFuture.tooltip',
                trigger: 'click'
            };
        }]
    };
}];