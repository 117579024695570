import _ from 'underscore';
import MockUpUtil from 'gw-portals-util-js/MockUpUtil';

const mockUpUtil = new MockUpUtil();

function Driver(data) {
    if (data) {
        _.extend(this, data);
        if (!data.dateOfBirth) {
            data.dateOfBirth = null;
        }
        if (data.person) {
            if (!areDriverValuesUndefined(data.person)) {
                this.licenseState = data.person.licenseState || (data.person.primaryAddress.state);
                this.licenseNumber = data.person.licenseNumber;
                this.gender = data.person.gender;
                this.dateOfBirth = data.person.dateOfBirth;
            }
        }
    }
}

function areDriverValuesUndefined(personObj) {
    const {
        licenseState, licenseNumber, gender, dateOfBirth, primaryAddress
    } = personObj;
    const isStatesStatusDefined = licenseState === undefined || primaryAddress.state === undefined;
    return (!isStatesStatusDefined || licenseNumber === undefined || gender === undefined || dateOfBirth === undefined);
}

Driver.prototype.getDisplayName = function (defaultName, $filter) {
    this.person.firstName = mockUpUtil.cleanUpMockedProperty('baseData.accountHolder.firstName', this.person.firstName);
    this.person.lastName = mockUpUtil.cleanUpMockedProperty('baseData.accountHolder.lastName', this.person.lastName);
    return this.person.getDisplayName(defaultName, $filter);
};

Driver.prototype.getDriverStatesForCountry = (states, countryCode) => {
    return _.filter(states, (state) => {
        const countryConstraint = _.find(state.categories, (constraint) => {
            return _.keys(constraint).indexOf('Country') !== -1;
        });
        if (countryConstraint) {
            return countryConstraint.Country === countryCode;
        }
        return false;
    });
};


export default Driver;