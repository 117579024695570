/**
 * Defines a breadcrumbs nav
 *
 * <gw-breadcrumbs>
 *   <gw-breadcrumbs-item>Account: Ray Newton</gw-breadcrumbs-item>
 *   <gw-breadcrumbs-item>Policy: Personal Auto 0726849902</gw-breadcrumbs-item>
 * </gw-breadcrumbs>
 */
import template from 'text!./templates/Breadcrumbs.html';
import layout from './LayoutFactory';

export default layout('Breadcrumbs', template, (partial) => {
    partial('Item', '<div class="gw-breadcrumbs__item" gw-partial-target></div>');
});
