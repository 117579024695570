import _ from 'lodash';
import Submission from 'edge/quoteandbind/common/models/Submission';
import conditionalProductFlow from 'integration/quoteandbind/config/ConditionalProductFlow';

export default (
    $q, GuidanceService, wizardFlowService,
    LoadSaveService, ViewModelService, AvailabilityService, TranslateService, ViewModelCtx, $stateParams, StateUtils, ActionMessageUtil
) => {

    const questionCache = createQuestionCache();

    const ACTIONS = {
        getQuestions: (model) => {
            return questionCache(model);
        },
        getRecommendations: (model) => {
            return GuidanceService.getRecommendations(model);
        },
        createAccountSubmissionAndProceedToFullQuote: (model, productCode) => {
            return GuidanceService.createAccountAndSubmission(model, productCode).then(submissionDTO => {
                const submission = new Submission(submissionDTO);
                const submissionViewModel = ViewModelService.create(submission, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', ViewModelCtx);
                conditionalProductFlow.fullQuoteFlowDefn(
                    submissionViewModel, productCode.code.value, $q, wizardFlowService, LoadSaveService, ViewModelService, AvailabilityService, TranslateService,
                    ViewModelCtx, $stateParams, ActionMessageUtil
                );
            });
        },
    };

    function atLeastOneQuestionAnsweredYes(questionsArray) {
        return _.any(questionsArray, question => question.answer === true);
    }

    /**
     * Function to generate the data for the questions. Caches the result to prevent an infinite digest loop
     * in Angular
     * @returns {*} - returns function that caches the data for the address and the industry codes
     */
    function createQuestionCache() {
        return _.memoize((model) => {
            return GuidanceService.getQuestions(model);
        }, createHashFunction());
    }

    function createHashFunction() {
        return (model) => addressIndustryCodeHashFunction(model.userAddress.value, model.industryCode.value);

        function addressIndustryCodeHashFunction(userAddress, industryCode) {
            return JSON.stringify(userAddress) + JSON.stringify(industryCode);
        }
    }

    return function (step, junction) {
        step('occupation', true)
            .onNext.goTo('address')
            .isValid(model => model.industryCode.value && model.industryCode.aspects.subtreeValid);

        step('address')
            .onNext.doAction(ACTIONS.getQuestions, undefined, { forceUpdate: true }).thenGoTo('questions', 'guidanceError')
            .isValid(model => model.userAddress.value && model.userAddress.aspects.subtreeValid);

        step('questions')
            .onNext.doAction(ACTIONS.getRecommendations, undefined, { forceUpdate: true }).thenGoTo('recommendation', 'guidanceError')
            .isValid(model => model.questions.value && model.questions.aspects.subtreeValid && atLeastOneQuestionAnsweredYes(model.questions.value));

        step('recommendation')
            .onNext.doAction(ACTIONS.createAccountSubmissionAndProceedToFullQuote, undefined, { forceUpdate: true }).thenGoTo('exit', 'guidanceError')
            .isValid(model => model.recommendedProducts.value);

        junction('exit');

        step('guidanceError');

    };
};
