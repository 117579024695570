import BaseCtrl from 'edge/platform/base/BaseCtrl';

/*
 The information being collected on this page is covered by PCI-DSS.
 It is the customer's responsibility to ensure that any security/data protection measures that are required are implemented.
 Data from this page should only ever be transmitted over HTTPS.
 */
const fn = ($scope, StateUtils, RootStates, $state, CoveragesUtil, CoverageService, submissionViewModel) => {
    const ctrl = Object.create(BaseCtrl);
    const POLICY_CHANGE = 'PolicyChange';
    const SUBMISSION = 'Submission';
    const RENEWAL = 'Renewal';

    ctrl.init = () => {
        const model = submissionViewModel();

        if (!model) {
            $state.go(RootStates.home);
            throw new Error('Model not found! Redirecting to home page.');
        }

        const jobType = model.value.baseData.jobType || SUBMISSION;

        if (jobType === POLICY_CHANGE) {
            $scope.isPolicyChange = true;
        } else if (jobType === RENEWAL) {
            $scope.isRenewal = true;
        } else if (jobType === SUBMISSION) {
            $scope.isQuote = true;
        }

        // ANDIE LOB dependent coverages aren't needed
        // const jobID = model.jobID ? model.jobID.value : model.quoteID.value;
        // CoveragesUtil.getDependentCoveragesForLOB(CoverageService, jobID);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'StateUtils', 'state_plugin_config', '$state', 'CoveragesUtil', 'CoverageService', 'submissionViewModel'];

export default fn;
