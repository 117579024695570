// The accordion directive simply sets up the directive controller
// and adds an accordion CSS class to itself element.
import templateStr from 'text!edge/platform/widgets/accordion/directives/templates/accordion.html';

export default [() => {
    return {
        restrict: 'EA',
        controller: 'GwPlAccordionController',
        transclude: true,
        replace: false,
        template: templateStr,
        scope: {
            'flavor': '@'
        }
    };
}];