import _ from 'lodash';
import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './GuidanceAddress-hashed.scss';
import templateStr from 'text!./GuidanceAddress.html';

export default [
    () => {
        function isIncompleteButNotEmpty(address) {
            if (address !== '' && _.isEmpty(address.value)) {
                return false;
            }
            return !address.aspects.subtreeValid;
        }

        return {
            restrict: 'E',
            scope: {
                address: '=',
                continueToNextPanel: '&',
                isActive: '&',
                isPanelValid: '&',
                transitionFn: '&'
            },
            template: cssUtil.hashTemplate(templateStr, styles),
            controller: ['$scope', 'TranslateService', 'guidance.GuidanceService', 'LocaleService', 'addressSettings', 'AddressUtil', ($scope, TranslateService, GuidanceService, LocaleService, addressSettings, AddressUtil) => {
                $scope.isInManualEntryMode = false;
                $scope.msOfInactivityBeforeSearching = addressSettings.msOfInactivityBeforeSearching;

                $scope.switchToManualEntryMode = () => {
                    const address = $scope.address;

                    if (!address.value || _.isEmpty(address.value)) {
                        const localeCountryCode = LocaleService.getCountry().code;
                        address.value = {
                            country: localeCountryCode
                        };
                    }
                    $scope.isInManualEntryMode = true;
                };

                $scope.switchToSearch = () => {
                    $scope.isInManualEntryMode = false;
                };

                if (isIncompleteButNotEmpty($scope.address)) {
                    $scope.switchToManualEntryMode();
                }

                $scope.addressLookup = {
                    getMatches: function (userInput) {
                        return GuidanceService.findAddress(userInput);
                    },
                    toString: address => {
                        if (_.isEmpty(address)) {
                            return '';
                        }
                        return AddressUtil.format(address);
                    }
                };
            }]
        };
    }
];
