import templateStr from 'text!./pm-veh-manual-lookup.html';

export default ['$q', 'VehicleLookupService_AND', '$timeout', '$rootScope', ($q, VehicleLookupService_AND, $timeout, $rootScope) => {
    const MANUAL_LOOKUP_FIELDS = ['make', 'model', 'firstRegisteredYear', 'fuelType', 'transmission', 'abiCode'];
    const TRANSMISSION_DISPLAY_NAME = {'MAN': 'Manual', 'AUTO': 'Automatic'};
    let productCode = $rootScope.productCode

    return {
        restrict: 'A',
        scope: {
            vehicleVM: '=vehicleVm'
        },
        template: templateStr,
        link: ($scope) => {

            /**
             * Util
             */

            const clearManualLookupFields = (offset = 0) => {
                MANUAL_LOOKUP_FIELDS.slice(offset)
                    .forEach((key) => {
                        $scope.vehicleVM.value[key] = undefined;
                    });
            };

            const getAbiFilter = () => {
                const dto = {};
                MANUAL_LOOKUP_FIELDS.forEach((key) => {
                    dto[key] = $scope.vehicleVM.value[key];
                });
                return dto;
            };

            const pluckAbiCars = (property) => {
                return $q.when(abiCarsPromise)
                    .then(() => {
                        const result = [];
                        for (const abi of $scope.abiCars) {
                            if (result.indexOf(abi[property]) === -1) {
                                result.push(abi[property]);
                            }
                        }
                        return result;
                    });
            };
            const filterAbiCars = (properties) => {
                const filterKeys = Object.keys(properties);
                return $q.when(abiCarsPromise)
                    .then((abiCars) => {
                        $scope.abiCars = abiCars.filter((abi) => filterKeys.every((key) => abi[key] === properties[key]));

                        if ($scope.abiCars.length === 1) {
                            $scope.vehicleVM.abiCode.value = $scope.abiCars[0].abiCode;
                        }
                    });
            };

            /**
             * Lookups
             */

            const makeChange = () => {
                if (productCode === 'CommercialVehicle'){
                    VehicleLookupService_AND.findLCVModels($scope.vehicleVM.make.value)
                    .then((data) => {
                        $scope.models = data.sort();
                    });
                } else {
                    VehicleLookupService_AND.findModels($scope.vehicleVM.make.value)
                    .then((data) => {
                        $scope.models = data.sort();
                    });
                }
            };

            const modelChange = () => {
                if (productCode === 'CommercialVehicle'){
                    VehicleLookupService_AND.findLCVYears($scope.vehicleVM.make.value, $scope.vehicleVM.model.value)
                    .then((data) => {
                        $scope.years = data.sort((first, second) => second - first);
                    });
                } else {
                    VehicleLookupService_AND.findYears($scope.vehicleVM.make.value, $scope.vehicleVM.model.value)
                    .then((data) => {
                        $scope.years = data.sort((first, second) => second - first);
                    });
                }
                
            };

            const yearChange = () => {
                if (productCode === 'CommercialVehicle'){
                    abiCarsPromise = VehicleLookupService_AND.findAbiLCVs(getAbiFilter())
                    .then((data) => {
                        $scope.abiCars = data.map((abi) => {
                            if(abi.firstRegisteredYear === abi.endYearForModel){
                                abi.displayName = `${abi.firstRegisteredYear} ${abi.make} ${abi.enhancedVehicleDescription} ${abi.engineCapacity} CC`;
                            } else{
                                abi.displayName = `[${abi.firstRegisteredYear}-${abi.endYearForModel}] ${abi.make} ${abi.enhancedVehicleDescription} ${abi.engineCapacity} CC`;
                            }
                            return abi;
                        });
                        return $scope.abiCars;
                    });
                pluckAbiCars('fuelType')
                    .then((result) => {
                        $scope.fuelTypes = result;
                        if ($scope.fuelTypes.length === 1) {
                            $scope.vehicleVM.fuelType.value = $scope.fuelTypes[0];
                        }
                    });
                } else {
                    abiCarsPromise = VehicleLookupService_AND.findAbiCars(getAbiFilter())
                    .then((data) => {
                        $scope.abiCars = data.map((abi) => {
                            if(abi.firstRegisteredYear === abi.endYearForModel){
                                abi.displayName = `${abi.firstRegisteredYear} ${abi.make} ${abi.enhancedVehicleDescription} ${abi.engineCapacity} CC`;
                            } else{
                                abi.displayName = `[${abi.firstRegisteredYear}-${abi.endYearForModel}] ${abi.make} ${abi.enhancedVehicleDescription} ${abi.engineCapacity} CC`;
                            }
                            return abi;
                        });
                        return $scope.abiCars;
                    });
                pluckAbiCars('fuelType')
                    .then((result) => {
                        $scope.fuelTypes = result;
                        if ($scope.fuelTypes.length === 1) {
                            $scope.vehicleVM.fuelType.value = $scope.fuelTypes[0];
                        }
                    });
                }

            };

            const fuelChange = () => {
                filterAbiCars({'fuelType': $scope.vehicleVM.fuelType.value})
                    .then(() => pluckAbiCars('transmission')
                        .then((result) => {
                            // Translate MAN and AUTO to Manual and Automatic
                            $scope.transmissions = result.map((trans) => {
                                if (TRANSMISSION_DISPLAY_NAME[trans]) {
                                    return {name: TRANSMISSION_DISPLAY_NAME[trans], value: trans};
                                } else { // Fallback
                                    return {name: trans, value: trans};
                                }
                            });
                            if ($scope.transmissions.length === 1) {
                                $scope.vehicleVM.transmission.value = $scope.transmissions[0].value;
                            }
                        }));
            };

            const transmissionChange = () => {
                filterAbiCars({'fuelType': $scope.vehicleVM.fuelType.value, 'transmission': $scope.vehicleVM.transmission.value});
            };

            /**
             * Watches
             */

            const watchCheck = (newValue, oldValue, changeFn, offset) => {
                if (newValue) {
                    // When not initializing or selecting an ABI Car directly, clear fields
                    if (selectingAbi !== true && angular.lowercase(newValue) !== angular.lowercase(oldValue)) {
                        clearManualLookupFields(offset);
                    }
                    changeFn();
                }
            };

            $scope.$watch(() => $scope.vehicleVM.make.value, (newValue, oldValue) => watchCheck(newValue, oldValue, makeChange, 1));
            $scope.$watch(() => $scope.vehicleVM.model.value, (newValue, oldValue) => watchCheck(newValue, oldValue, modelChange, 2));
            $scope.$watch(() => $scope.vehicleVM.firstRegisteredYear.value, (newValue, oldValue) => watchCheck(newValue, oldValue, yearChange, 3));
            $scope.$watch(() => $scope.vehicleVM.fuelType.value, (newValue, oldValue) => watchCheck(newValue, oldValue, fuelChange, 4));
            $scope.$watch(() => $scope.vehicleVM.transmission.value, (newValue, oldValue) => watchCheck(newValue, oldValue, transmissionChange, 5));
            $scope.$watch(() => $scope.vehicleVM.abiCode.value, (newValue, oldValue) => {
                if (!!newValue && newValue !== oldValue) {
                    $q.when(abiCarsPromise)
                        .then((abiCars) => {
                            let returnedAbiCar = abiCars.find((abi) => abi.abiCode === newValue);
                            if (returnedAbiCar !== null){
                                delete returnedAbiCar.firstRegisteredYear;
                                Object.assign($scope.vehicleVM.value, returnedAbiCar);
                                selectingAbi = true;
                            }
                            $timeout(()=> {
                                selectingAbi = false;
                            });
                        });
                }
            });

            /**
             * Init
             */
            let abiCarsPromise;
            let selectingAbi = false;
            $scope.makes = [];
            if (productCode === 'CommercialVehicle'){
                VehicleLookupService_AND.findLCVMakes()
                .then((data) => {
                    $scope.makes = data.sort();
                });
            } else {
                VehicleLookupService_AND.findMakes()
                .then((data) => {
                    $scope.makes = data.sort();
                });
            }
            
        }
    };
}];