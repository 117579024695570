const isQueryApplicable = (queryString) => {
    if (!queryString) {
        return false;
    }
    // ensure query contains more than 2 characters ONLY when it represents non-numeric value
    return Number.isNaN(queryString) ? queryString.length > 2 : true;
};

class TableFilterCtrl {
    constructor($filter) {
        this.queryString = null;
        this.$filter = $filter;
    }

    predicate(value, queryString) {
        const normalizePhrase = (phrase) => {
            return phrase ? phrase.toLowerCase() : '';
        };

        const normalizedQuery = normalizePhrase(queryString);
        return this.filterableProperties.some((property) => normalizePhrase(value[property]).includes(normalizedQuery));
    }

    makeQuery() {
        const dataSource = this.data && Array.isArray(this.data) ? this.data : [];
        const isApplicable = isQueryApplicable(this.queryString);
        const queriedData = isApplicable
            ? this.$filter('filter')(dataSource, (value) => this.predicate(value, this.queryString))
            : dataSource;

        this.onQuery(queriedData, isApplicable, this.queryString);
    }

    $onChanges({data}) {
        if (data && data.currentValue) {
            this.makeQuery();
        }
    }
}
TableFilterCtrl.$inject = ['$filter'];
export default TableFilterCtrl;
