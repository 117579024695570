import subtitle from 'text!./templates/Subtitle.html';

export default [() => ({
    restrict: 'E',
    replace: true,
    transclude: true,
    template: subtitle,
    scope: {},
    link: ($scope, $element, $attrs) => {
        if ('offset' in $attrs) {
            $scope.hasOffset = true;
        }
    }
})];