import queryString from 'query-string';

// ANDIE
const BRANDS = [
    {host: 'insurethebox', brand: 'itb'},
    {host: 'tescobank', brand: 'tbb'},
    {host: 'tbboxins', brand: 'tbb'},
    {host: 'drivelikeagirl', brand: 'dgt'},
    {host: 'toyotamotorinsurance', brand: 'toy'},
    {host: 'lexusmotorinsurance', brand: 'lex'},
    {host: 'autosaninsurance', brand: 'aut'},
    {host: 'kinto-lcv-insurance', brand: 'kin'},
    {host: 'kwik-fit', brand: 'kfi'}
  ];

const hostNameFromURLsegments = window.location.hostname.split('.');

let hostName = BRANDS.find(function(hostBrands){
    return hostNameFromURLsegments.includes(hostBrands.host);
 });

 let hostNameBrand;
 if(hostName) {
    hostNameBrand = hostName.brand;
 }

let brand = queryString.parse(window.location.search).branding || // e.g. XXX?branding=custom gives "custom"
            hostNameBrand || // e.g. custom-domain.guidewire.com gives  "custom-domain"
            window.brandingFromRequest; // gets branding param value from request

export default {
    BRANDING: brand // ANDIE
};