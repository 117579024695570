/**
 * @ngdoc component
 * @name gw-transaction-wizard-title
 *
 * @description  This is the default transaction wizard title, this component is empty and can be overridden by a specify project component.
 *
 */
export default {
    bindings: {
        isQuote: '<',
        isPolicyChange: '<',
        isRenewal: '<'
    }
};