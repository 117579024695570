import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './generic-tile-hashed.scss';
import template from 'text!../templates/generic-tile.html';

export default [() => ({
    restrict: 'E',
    replace: true,
    scope: {
        state: '&',
        callback: '&',
        flavor: '@'
    },
    transclude: {
        'header': '?gwTileHeader',
        'footer': '?gwTileFooter',
        'content': '?gwTileContent',
        'actions': '?gwTileAction'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'tiles.tilesUtilFunctions', ($scope, tilesUtilFunctions) => {
        $scope.tileType = 'generic';
        $scope.customFlavor = $scope.flavor || '';
        $scope.allow = {
            header: true,
            content: true,
            actions: true,
            footer: true
        };
        $scope.clickCallback = clickCallback;
        $scope.getActiveState = () => '';

        $scope.getActiveState = tilesUtilFunctions.getActiveStateFunction($scope.state);
        $scope.getStyle = tilesUtilFunctions.getStyleFunction(styles);

        function clickCallback() {
            $scope.callback();
        }
    }]
})];
