const defaultConfig = {
    appId: null,
    debugMode: false,
    currentScriptTag: null,
    shareDialog: null
};
const config = Object.assign({}, defaultConfig);

/**
 * If locale is given as language only (e.g. 'es'), try to normalize it to full locale -> es_ES
 * Facebook will default to English if the guess is wrong.
 * With just 'es' facebook would default to English anyways.
 *
 * @param {String} locale
 * @returns {String}
 */
function normalizeLocaleInfo(locale) {
    if (locale.indexOf('_') === -1) {
        locale = `${locale}_${locale.toUpperCase()}`;
    }
    return locale;
}

function cleanupPreviousInstance() {
    delete window.FB;

    const fbRoot = document.querySelector('#fb-root');
    if (fbRoot) {
        document.body.removeChild(fbRoot);
    }

    document.head.removeChild(config.currentScriptTag);
}

const provider = {
    $get: ($q, LocaleService) => ({
        getClient: () => {
            const deferred = $q.defer();
            const locale = normalizeLocaleInfo(LocaleService.getCurrentLocale());

            window.fbAsyncInit = () => {
                const client = window.FB;
                client.init({
                    appId: config.appId,
                    version: 'v2.5',
                    xfbml: false
                });
                deferred.resolve(client);
            };

            // Access control prevents loading facebook sdk with ajax
            const scriptTag = document.createElement('script');
            scriptTag.async = 'async';
            scriptTag.onerror = () => {
                deferred.reject();
            };

            const scriptSrc = '/sdk.js';
            scriptTag.src = `https://connect.facebook.net/${locale}${scriptSrc}`;

            if (config.currentScriptTag) {
                cleanupPreviousInstance();
            }
            document.head.appendChild(scriptTag);
            config.currentScriptTag = scriptTag;

            return deferred.promise;
        },

        getShareDialogData: () => {
            return config.shareDialog;
        }
    }),

    setAppId: appId => {
        config.appId = appId;
    },

    setDebugMode: debugMode => {
        config.debugMode = debugMode;
    },

    setShareDialogData: data => {
        config.shareDialog = data;
    },

    reset: () => {
        if (config.currentScriptTag) {
            cleanupPreviousInstance();
        }
        Object.assign(config, defaultConfig);
    }
};
provider.$get.$inject = ['$q', 'LocaleService'];

export default provider;
