import _ from 'lodash';
import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./WizardStepCircles.html';
import styles from './WizardStepCircles-hashed.scss';

export default [() => ({
    restrict: 'E',
    template: cssUtil.hashTemplate(template, styles),
    scope: {
        flowModel: '<'
    },
    controller: ['$scope', ($scope) => {
        $scope.steps = [];
        let currentStepIdx = 0;
        const updateSteps = () => {
            $scope.steps.length = 0;
            Array.prototype.push.apply($scope.steps, $scope.flowModel.steps.filter(s => s.label.hasLabel()));

            currentStepIdx = _.findIndex($scope.steps, step => step.name === $scope.flowModel.currentStep.name);
            if (currentStepIdx === -1) {
                // Consider any unlabeled steps to be at the end of the wizard
                currentStepIdx = $scope.steps.length;
            }
        };
        updateSteps();

        $scope.isStepVisited = step => $scope.steps.indexOf(step) <= currentStepIdx;
        $scope.isStepActive = step => $scope.steps.indexOf(step) === currentStepIdx;
        $scope.isStepAccessible = step => !$scope.isStepActive(step) && $scope.flowModel.isStepAccessibleFromCurrent(step);
        $scope.jumpToStep = step => {
            if ($scope.steps.indexOf(step) !== currentStepIdx && $scope.isStepAccessible(step)) {
                $scope.flowModel.jumpToStep(step);
            }
        };

        $scope.flowModel.addStepChangeListener(updateSteps);
        $scope.$on('$destroy', () => $scope.flowModel.removeStepChangeListener(updateSteps));

        $scope.hovered = -1;
        $scope.hover = (i) => {
            if ($scope.isStepAccessible($scope.steps[i])) {
                $scope.hovered = i;
            }
        };
        $scope.dropHover = () => {
            $scope.hovered = -1;
        };
    }]
})];
