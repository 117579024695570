import templateStr from 'text!./stepper-select-group.html';

class StepperSelectGroup {
    constructor() {
        this.restrict = 'AE';
        this.template = templateStr;
        this.scope = {
            model: '=',
            renderByValue: '=',
            label: '@',
            tooltip: '=?'
        };
    }
}
export default StepperSelectGroup;