import templateStr from 'text!./pm-clubcard.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            data: '='
        },
        template: templateStr,
        controller: [
            '$scope', ($scope) => {
            
                $scope.$watch(() => $scope.data.kficlubCardNumber_itb.value, (newValue) => {
                    if (newValue !== undefined || null){
                        $scope.data.kficlubCardNumber_itb = newValue.toUpperCase()
                    }
                })

                $scope.$watch(() => $scope.data.kwikFitClubMember.value, (newValue) => {
                    if(newValue != true) {
                        $scope.data.kficlubCardNumber_itb = ""
                    }
                })

                $scope.clubCardNumberTooltip = {
                    content: 'and.quoteandbind.pm.views.pm-contact-details.tooltip.ClubCard Number',
                    trigger: 'click'
                };
               
            }

            
        ]
    };
}];