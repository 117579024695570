import cssUtil from 'gw-portals-util-js/CssUtil';
import templateString from 'text!./GooglePlacesLookup.html';
import styles from './GooglePlacesLookup-hashed.scss';
import GooglePlacesLookupCtrl from './GooglePlacesLookupCtrl';

const template = cssUtil.hashTemplate(templateString, styles);

export default {
    controller: GooglePlacesLookupCtrl,
    controllerAs: '$ctrl',
    template,
    restrict: 'E',
    bindings: {
        policyAddress: '=',
        label: '@',
        placeholder: '@'
    }
};
