/*
 * Utilities for loading and retrieving values from the
 * environment-based configuration.
 */
import _ from 'lodash';
import loadConfig from './load-config';

const BUILD_PARAMS = __BUILD_PARAMS__ || {};

/**
 * Loads the configuration.
 * @returns {Object} - The configuration
 */
function loadEnvironmentConfig() {
    // load the alias to the current env config
    let config = require('envConfig');
    if (config['@extends']) {
        config = _.merge(require('envBaseConfig'), config, (target, src) => {
            // override (don't merge) arrays
            if (_.isArray(target) && _.isArray(src)) {
                return src;
            }
        });
    }
    return loadConfig(config, BUILD_PARAMS);
}

// memoize loader so that it is called only once
const loadOnce = _.memoize(loadEnvironmentConfig);

/**
 * Returns the environment-based configuration.
 * @returns {Object} - The configuration
 */
export default function getConfiguration() {
    return loadOnce();
}

/**
 * Returns the value of the configuration corresponding to
 * the object path given.
 * @param {string} path - The object path
 * @param {*} [defaultValue] - The default value if no value found at given path
 * @returns {*} - The configuration value
 * @throws {Error} - If `required` is true and value if undefined
 */
export function getConfigValue(path, defaultValue) {
    const config = getConfiguration();
    const value = _.get(config, path);
    if (defaultValue === undefined && value === undefined) {
        throw new Error(`No configuration defined for '${path}', or default value given`);
    }
    return (value !== undefined) ? value : defaultValue;
}

/**
 * Returns the extended value of the configuration corresponding to
 * the object path given.
 * @param {string} path - The object path
 * @param {object} extension - The object to be merged into the configuration value
 * @returns {object} - The extended configuration value
 */
export function getAndExtendConfigValue(path, extension) {
    const configValue = getConfigValue(path);
    return _.merge(configValue, extension);
}