import _ from 'underscore';
import cssUtil from 'gw-portals-util-js/CssUtil';

import Coverage from '../../models/Coverage';

import styles from './EditableCoverages-hashed.scss';
import template from 'text!./EditableCoverages.html';

export default [() => {
    return {
        restrict: 'E',
        template: cssUtil.hashTemplate(template, styles),
        scope: {
            coverages: '=',
            loading: '=',
            onCovChange: '=',
            allowSelectCoverage: '=',
            allCoveragesLoaded: '=?',
            dependentCoverages: '=',
            currentDependentCoverages: '='
            // no-loaders attribute if specified removes the loader
        },
        compile: (tElement, tAttrs) => {
            if ('noLoaders' in tAttrs) {
                $(tElement).find('[gw-loader]').removeAttr('gw-loader');
            }
        },
        controller: ['$scope', '$filter', ($scope, $filter) => {
            const dependentCoveragesMap = mapDependantCoverages($scope.dependentCoverages);

            $scope.select = function ($event, coverage) {
                if (!coverage.selected) {
                    // This handles the case when user clicks on a checkbox so that this callback and toggle don't counteract each other
                    $event.stopPropagation();
                    $event.preventDefault();

                    coverage.selected = true;
                }
            };

            $scope.toggle = function ($event, coverage) {
                $event.stopPropagation();
                coverage.selected = !coverage.selected;
            };

            $scope.ignoreClick = function ($event) {
                $event.stopPropagation();
            };

            $scope.shouldShowCheckbox = function (coverage) {
                return coverage.required || !$scope.allowSelectCoverage;
            };

            function isLoading() {
                return $scope.loading;
            }

            $scope.loadingSelectedCoverages = [];

            function updateSelectedCoverage(coverage) {
                $scope.loadingSelectedCoverages.push(coverage);
                $scope.onCovChange(coverage).finally(() => {
                    const covIndex = $scope.loadingSelectedCoverages.indexOf(coverage);
                    if (covIndex > -1) {
                        $scope.loadingSelectedCoverages.splice(covIndex, 1);
                    }
                });
            }

            function updateDependentCoverage(coverage, dependentCoverage) {
                $scope.currentDependentCoverages.push(dependentCoverage);
                $scope.onCovChange(coverage).finally(() => {
                    const covIndex = $scope.currentDependentCoverages.indexOf(dependentCoverage);
                    if (covIndex > -1) {
                        $scope.currentDependentCoverages.splice(covIndex, 1);
                    }
                });
            }

            function onChange(coverage, term) {
                // If the coverage has been selected, or all its terms are valid, perform a sync
                if (coverage.selectedChanged ||
                    (coverage.terms && coverage.terms.length > 0 && _.every(coverage.terms, aTerm => aTerm.aspects.valid))) {
                    performOnChange(coverage, term);
                }
            }

            function mapDependantCoverages(dependantCoverages) {
                const coveragesMap = new Map();

                if (dependantCoverages) {
                    Object.keys(dependantCoverages).forEach((key) => {
                        coveragesMap.set(dependantCoverages[key].name, true);
                    });
                }

                return coveragesMap;
            }

            function performOnChange(coverage, term) {
                let dependentCoverage;

                if ($scope.dependentCoverages) {
                    dependentCoverage = _.findWhere($scope.dependentCoverages, {name: coverage.publicID});

                    // If no dependent coverage is found, check dependent terms
                    if (!dependentCoverage && term) {
                        // Shallow clone the dependentCoverage term so we can change the name property without updating original object reference
                        dependentCoverage = _.clone(_.findWhere($scope.dependentCoverages, {name: term.type}));

                        if (dependentCoverage) {
                            dependentCoverage.name = term.coveragePublicID;
                        }
                    }
                }

                if ($scope.dependentCoverages && !dependentCoverage &&
                    coverage && coverage.hasTermPatterns && coverage.selected && coverage.selectedChanged) {
                    updateSelectedCoverage(coverage);
                } else if (dependentCoverage) {
                    updateDependentCoverage(coverage, dependentCoverage);
                } else if (!$scope.dependentCoverages) {
                    $scope.allCoveragesLoaded = false;
                    const onCovChange = typeof $scope.onCovChange === 'function' ? $scope.onCovChange() : null;
                    if (onCovChange) {
                        onCovChange.finally(() => {
                            $scope.allCoveragesLoaded = true;
                        });
                    }
                }

                // reset coverage selection check
                coverage.selectedChanged = false;
            }

            $scope.disableDependentCoverage = (publicID) => {
                if (!$scope.currentDependentCoverages || $scope.currentDependentCoverages.length === 0) {
                    return false;
                }

                return _.some($scope.currentDependentCoverages, (cov) => {
                    return _.some(cov.dependencies, (dependent) => {
                        return dependent === publicID;
                    });
                });
            };

            $scope.loadingCov = (coverage) => {
                return anyDependentCovsWithoutDeps() ||
                    (!_.findWhere($scope.currentDependentCoverages, {name: coverage.publicID}) &&
                    !_.findWhere($scope.loadingSelectedCoverages, {publicID: coverage.publicID}));
            };

            $scope.anyCoverageUpdating = function () {
                // If any dependent coverage does not have dependencies listed, show full loader
                return anyDependentCovsWithoutDeps() ||
                    (!$scope.dependentCoverages &&
                    $scope.model.some(coverage => coverage.updating || coverage.terms.some(term => term.updating)));
            };

            function anyDependentCovsWithoutDeps() {
                return _.find($scope.currentDependentCoverages, (depCov) => {
                    return depCov.dependencies.length === 0;
                });
            }

            // Set up the model
            $scope.$watch('coverages', cov => {
                if (!cov) {
                    return;
                }

                $scope.model = _(cov)
                    .sortBy(coverage => (coverage.required ? -1 : 1))
                    .map(_.partial(Coverage.create, $filter('translate'), _, isLoading, onChange, $scope.disableDependentCoverage, dependentCoveragesMap))
                    .valueOf();
            });
        }]
    };
}];