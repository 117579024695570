import StringUtil from 'gw-portals-util-js/StringUtil';

export default {
    getStyleFunction: (styles) => {
        return (prefix, type, flavour, state) => {
            const key = `${prefix}${type}${flavour}${state}`;
            return styles[key];
        };
    },
    getActiveStateFunction: (stateFunc) => {
        return () => {
            let activeState = stateFunc() || '';
            // Capitalise the states first char
            activeState = StringUtil.capitalizeFirstLetter(activeState);
            return activeState;
        };
    }
};