import _ from 'lodash';
import assert from 'assert';

/**
 * This function defines a new layout:
 * * Registers a wrapper directive for the layout
 * * Registers directives for any partials defined for the layout
 *
 * @param {string}      layoutName  Layout name string. Layout will be automatically prefixed with 'gw-'
 * @param {string}      template    Layout template string.
 * @param {function}    definition  Definition function. 'createPartial' method is passed in to define partials.
 *
 * @returns {String}
 */
export default (layoutName, template, definition) => {
    const layoutDirectiveName = `gw${layoutName}`; // gwSomeLayout
    const moduleName = `edge.platform.layout.${layoutName}`;

    const getPartialDirectiveName = partial => _.kebabCase(`${layoutDirectiveName}${partial}`);

    const knownPartials = (() => {
        // lElement is created in a closure so that it gets garbage-collected after known partials are selected
        const lElement = document.createElement('div');
        lElement.innerHTML = template;

        return Array.from(lElement.querySelectorAll('[gw-partial]'))
            .map(el => el.attributes['gw-partial'].value);
    })();

    // Create a separate module for the layout and the outer directive
    const module = angular.module(moduleName, [])
        .directive(layoutDirectiveName, [() => ({
            restrict: 'E',

            // Make sure this doesn't creates a new state —
            // current state should be used so that layouts are transparent
            // for any inner directives with two-way binding
            scope: false,
            compile: tElement => {
                const partials = {};
                knownPartials.forEach(partial => {
                    // .html() call later will clean up angular info stored under .data() unless element is detached first
                    partials[partial] = tElement.find(getPartialDirectiveName(partial)).detach();
                });

                tElement.html(template);
                knownPartials.forEach(partial => {
                    const partialContainer = angular.element(tElement[0].querySelector(`[gw-partial="${partial}"]`));
                    partialContainer.append(partials[partial]);
                });

                return () => {
                };
            }
        })]);

    const createPartial = (partialName, partialTemplate, controller) => {
        assert(knownPartials.includes(partialName), `Partial ${partialName} does not exists in layout template`);

        // Register a partial directive to capture types of content inside the layout
        module.directive(`${layoutDirectiveName}${partialName}`, () => ({ // gwSomeLayoutSomePartial
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: false,
            controller: controller || [() => {
            }],
            // If we use $ctrl here, it will overwrite any parent controller that this directive is used in, resulting in unforseen behaviour
            controllerAs: 'layoutController',
            template: partialTemplate || '<div gw-partial-target></div>',
            link: ($scope, $element, attrs, ctrl, transclude) => {
                transclude($scope, (transcludedContent) => {
                    const targetSelector = '[gw-partial-target]';
                    if ($element.is(targetSelector)) {
                        $element.append(transcludedContent);
                    } else {
                        $element.children(targetSelector).append(transcludedContent);
                    }
                });
            }
        }));
    };

    definition(createPartial);

    return moduleName;
};
