import templateStr from 'text!./pm-quote-mileage.html';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '='
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            // To get the chosen mileage
            $scope.vehicle = $scope.quoteData.submission.lobData.personalMotor.value.coverables.pmVehicles[0];
            // Accordion open flag. Accordion is supposed to go away once opened.
            $scope.page = { hiddenOptionTriggered: false };
            $scope.mileageOptions = []; // 6k, 8k, 10k miles options
            $scope.selectionLabels = []; // Mileage numbers for the text
            $scope.quoteData.offeredQuotes.forEach((quote, index) => {
                if (quote.isHidden !== true) { // Two options in text - the ones that are displayed initially
                    $scope.selectionLabels.push(quote.branchCode);
                }
                $scope.mileageOptions.push({
                    id: index,
                    value: quote.branchCode,
                    label: 'and.quoteandbind.pm.views.quote.Mileage.Options',
                    description: `and.quoteandbind.pm.views.quote.Mileage.Options.${quote.branchCode}`,
                    isHidden: quote.isHidden,
                    price: quote.topUpMilesCost_itb
                });
            });
            // Mileage VMNode
            $scope.selectedMileage = {
                value: $scope.quoteData.baseOffering.branchCode,
                aspects: {
                    visible: true,
                    required: true,
                    get valid() {
                        return !!$scope.selectedMileage.value;
                    }
                }
            };
            // Change base offering/selected period on mileage change
            $scope.$watch('selectedMileage.value', (newValue) => {
                $scope.quoteData.baseOffering = $scope.quoteData.offeredQuotes.find((quote) => {
                    quote.isCustom = quote.branchCode === newValue;
                    return quote.isCustom;
                });

            });
            // Sort quotes by mileage ASC
            // If there is a hidden option, it must be last
            $scope.optionComparator = function (o1, o2) {
                if ($scope.page.hiddenOptionTriggered === false) {
                    if (o1.value.isHidden === false) {
                        return Number.MAX_SAFE_INTEGER;
                    } else if (o2.value.isHidden === true) {
                        return -Number.MAX_SAFE_INTEGER;
                    }
                }
                return o1.value.value - o2.value.value;
            };
        }]
    };
}];