
/**
  * @ngdoc directive
  * @name SvgIcon_AND.directive:[ 
  * @restrict 'E'
  * @element ANY
  * @scope
  * priority: 0
  * @description
  * Renders inline svg icons  
  * @example
  * <and-svg-icon p="chat" class="and-uw-warning-icon"></and-svg-icon>
**/

export default [() =>{

    const icons = {	
        chat: "M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z",
        phone: "M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
    }

    return {
        link: (scope, element, attrs) => {
            function path(icon) {
                return `<svg class="${attrs.class}" viewBox="0 0 24 24">' + '<path d="${icons[icon]}"/>' + '</svg>`;
            }
      
            function renderSVG() {
                element.html(path(attrs.p));
            }
            
            renderSVG();
        },
        restrict: 'E'
    };
    
}];

