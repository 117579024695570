import _ from 'underscore';
import 'edge/quoteandbind/common/models/Submission';
import Driver from 'edge/quoteandbind/pa/models/Driver';
import 'edge/quoteandbind/common/models/Person';
import Vehicle from 'edge/quoteandbind/pa/models/Vehicle';

let nextId;

const PACoverables = function (data, submission) {
    if (data && _.isObject(data)) {
        this.drivers = _.map(data.drivers, (d) => {
            const driver = new Driver(d);
            driver.person = submission.findPerson(submission, d.person);
            return driver;
        }, this);

        this.vehicles = _.map(data.vehicles, (v) => {
            return new Vehicle(v);
        }, this);


    } else {
        this.drivers = [];
        this.vehicles = [];
    }
};

PACoverables.prototype.createDriver = function (person) {
    const driver = new Driver({
        person,
        dateOfBirth: person.dateOfBirth
    });
    driver.tempID = nextId();

    this.drivers.push(driver);
    return driver;
};

PACoverables.prototype.removeDriver = function (driver) {
    const idx = _.indexOf(this.drivers, driver);
    if (idx >= 0) {
        this.drivers.splice(idx, 1);
    }
};

PACoverables.prototype.createVehicle = function (person) {
    const v = new Vehicle({
        tempId: nextId()
    });
    if (person && person.primaryAddress) {
        v.licenseState = person.primaryAddress.state;
    }
    this.vehicles.push(v);
    return v;
};

PACoverables.prototype.removeVehicle = function (vehicle) {
    const idx = _.indexOf(this.vehicles, vehicle);
    if (idx >= 0) {
        this.vehicles.splice(idx, 1);
    }
};

PACoverables.prototype.getDriverForPerson = function (person) {
    const driver = _.findWhere(this.drivers, {
        person
    });

    if (driver.dateOfBirth !== person.dateOfBirth) {
        driver.dateOfBirth = person.dateOfBirth;
    }
    return driver;
};

const PASubmissionDataExtension = function (submission) {
    nextId = submission.nextId;

    const paData = submission.lobData.personalAuto;
    if (paData) {
        this.preQualQuestionSets = paData.preQualQuestionSets || [];
        this.offerings = paData.offerings || [];
        this.coverables = new PACoverables(paData.coverables, submission);
    }
};

export default PASubmissionDataExtension;
