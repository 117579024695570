/**
 * A helper, internal data structure that acts as a map but also allows getting / removing
 * elements in the LIFO order
 */

export default function () {
    return {
        createNew() {
            const stack = [];

            return {
                add(key, value) {
                    stack.push({
                        key: key,
                        value: value
                    });
                },
                get(key) {
                    for (let i = 0; i < stack.length; i++) {
                        if (key === stack[i].key) {
                            return stack[i];
                        }
                    }
                },
                keys() {
                    const keys = [];
                    for (let i = 0; i < stack.length; i++) {
                        keys.push(stack[i].key);
                    }
                    return keys;
                },
                top() {
                    return stack[stack.length - 1];
                },
                remove(key) {
                    let idx = -1;
                    for (let i = 0; i < stack.length; i++) {
                        if (key === stack[i].key) {
                            idx = i;
                            break;
                        }
                    }
                    return stack.splice(idx, 1)[0];
                },
                removeTop() {
                    return stack.splice(stack.length - 1, 1)[0];
                },
                length() {
                    return stack.length;
                }
            };
        }
    };
}