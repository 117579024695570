export default function controller() {
    const $ctrl = this;
    let isOpen = false;
    $ctrl.toggleOpenState = toggleOpenState;
    $ctrl.showOpenState = showOpenState;
    $ctrl.isSelected = isSelected;
    $ctrl.toggleSelectedState = toggleSelectedState;


    function toggleOpenState($event) {
        // Nested ng-click, prevent propagation up to parent
        $event.stopPropagation();
        isOpen = !isOpen;
    }

    function showOpenState() {
        return isOpen;
    }

    function toggleSelectedState() {
        $ctrl.model.value = !$ctrl.model.value;
    }

    function isSelected() {
        return $ctrl.model.value;
    }
}