import OAuthUtil from 'gw-portals-auth-js/utils/OAuthUtil';

/**
 * Create drop zone element for file upload
 * @param {String} dropZoneSelector
 * @param {Object} $document
 * @returns {*}
 */
function createDropZoneElement(dropZoneSelector, $document) {
    let dropZoneElement;
    if (dropZoneSelector) {
        // Disable default behaviour if allowing specific drop zone
        $document.bind('drop dragover', (e) => e.preventDefault());
        dropZoneElement = $(dropZoneSelector) || dropZoneElement;
    }
    return dropZoneElement;
}

/**
 * Creates object with upload parameters
 * @param {String} url
 * @param {Boolean} autoUpload
 * @param {Number} maxFileUploadSize
 * @param {String} dropZoneElement
 * @returns {{url: *, autoUpload: *, async: boolean, maxFileSize: (*|undefined), dropZone: *}}
 */
function createOptions(url, autoUpload, maxFileUploadSize, dropZoneElement) {
    return {
        url: url,
        autoUpload: autoUpload,
        async: true,
        maxFileSize: maxFileUploadSize || undefined,
        dropZone: dropZoneElement
    };
}

/**
 * Adds authorization token header to upload parameters
 * @param {Object} options
 * @param {Object} oAuth
 * @returns {Promise} A promise that resolves to the updated options
 */
function addAuthTokenHeader(options, oAuth) {
    const oAuthUtil = OAuthUtil(oAuth);
    return oAuthUtil.waitTokensSet()
        .then(tokens => {
            const optionsCopy = angular.copy(options);
            optionsCopy.headers = {
                'Authorization': `Bearer ${tokens.accessToken}`
            };
            return optionsCopy;
        });
}

/**
 * Handler called when file upload stop event occurs
 * @param {Array} documents
 * @param {Array} newDocuments
 * @param {Boolean} errorUploading
 * @param {Boolean} isDriverLicenseUpload
 * @returns {{documents: *, newDocuments: Array, errorUploading: *}}
 */
function handleFileUploadStop(documents, newDocuments, errorUploading, isDriverLicenseUpload) {
    if (errorUploading) {
        if (isDriverLicenseUpload) {
            documents.push({});
        }
        errorUploading = false;
    } else if (documents) {
        angular.forEach(newDocuments, (d) => {
            documents.push(d);
        });
    } else {
        documents = newDocuments;
    }
    newDocuments = [];
    return {
        documents: documents,
        newDocuments: newDocuments,
        errorUploading: errorUploading
    };
}

/**
 * Creates doc metadata based on provided metadata template and assigns values from data to metadata.
 * @param {Object} metadataTemplate
 * @param {Object} data
 * @param {String} sessionToken
 * @returns {Object}
 */
function createDocMetadata(metadataTemplate, data, sessionToken) {
    const docMetadata = angular.copy(metadataTemplate);
    docMetadata.name = data.files[0].name;
    docMetadata.mimeType = data.files[0].type;
    docMetadata.sessionID = sessionToken;
    return docMetadata;
}

export default () => {
    return {
        createDropZoneElement,
        createOptions,
        addAuthTokenHeader,
        handleFileUploadStop,
        createDocMetadata
    };
};