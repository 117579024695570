/**
 * Extracted from angular-bootstrap-ui 0.8.0
 */
import TabsetCtrl from './TabsetCtrl';
import TabsetDirective from './directives/Tabset';
import TabDirective from './directives/Tab';
import TabHeadingDirective from './directives/TabHeading';
import TabContentDirective from './directives/TabContent';

angular.module('edge.platform.widgets.tabs', [])
    .controller('TabsetController', TabsetCtrl)
    .directive('tabset', TabsetDirective)
    .directive('tab', TabDirective)
    .directive('tabHeadingTransclude', TabHeadingDirective)
    .directive('tabContentTransclude', TabContentDirective);