export default ['$compile', ($compile) => ({
    restrict: 'A',
    require: '^^?gwTypeahead',
    link: ($scope, $element, $attrs, $ctrl) => {
        if (!$ctrl) {
            // Do not setup typeahead if not within gw-typeahead.
            // Since hook may appear in other related but standalone directives, it shouldn't break stuff.
            return;
        }

        $scope._getMatches = $ctrl._getMatches;
        $scope._toString = $ctrl._toString;
        $scope._selectDirective = $ctrl._selectDirective;
        $scope._appendTo = $ctrl._getElement();

        $scope._setLoading = function (loading) { // Loading hook
            $ctrl._setLoading(loading);
        };

        $scope._setOpen = function (open) { // Open hook
            $ctrl._setOpen(open);
        };

        // This adds a directive to an already existing element.
        // I choose to do this, because:
        // 1) typeahead-internals expect $element to be an actual input element and
        // 2) this way underlaying input remains a separate component, other components may be used in place and no hard link exists.
        $element.removeAttr('gw-typeahead-hook'); // Remove the hook so that this directive is not reinitialized
        $element.attr('gw-typeahead-internal', ''); // Initialize the typeahead internals
        $element.attr('gw-typeahead-editable', $ctrl.isEditable() ? 'true' : 'false');
        $element.attr('gw-typeahead-append-to', '_appendTo');

        $compile($element)($scope); // Recompile the input
    }
})];