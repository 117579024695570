import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./tile-header-icon.html';
import styles from './tile-header-icon-hashed.scss';

export default [() => ({
    restrict: 'E',
    replace: true,
    scope: {
        icon: '@'
    },
    template: cssUtil.hashTemplate(template, styles)
})];
