import landingPage1View from 'text!./../views/landingpage1.html';
import landingPage2View from 'text!./../views/landingpage2.html';
import loadingLandingPage from 'text!./../views/loadinglandingpage.html';
import LoadingLandingPageCtrl from '../controllers/LoadingLandingPageCtrl';
import LandingPageCtrl from '../controllers/LandingPageCtrl';
import landingPage1Content from '../config/landingPage1Content.json';

export default {
    landingPage1: {
        url: '/lp1',
        template: landingPage1View,
        controller: LandingPageCtrl,
        resolve: {
            landingPageContent: () => landingPage1Content
        }
    },

    landingPage2: {
        url: '/lp2',
        template: landingPage2View,
        controller: LandingPageCtrl,
        resolve: {
            landingPageContent: () => {
            }
        }
    },

    loadingLandingPage: {
        controller: LoadingLandingPageCtrl,
        url: '/loading',
        template: loadingLandingPage,
        params: {
            params: null
        }
    }
};
