/**
 * Uses CtrlGroupDirective.js changing the template and transclude options.
 * The new template adds the input directly based on the inputCtrlType aspect instead of having
 * it transcluded.
 */
import templateStr from 'text!./templates/input-ctrl.html';
import CtrlGroupDirective from './CtrlGroupDirective';

export default function () {
    const controlGroupDirectiveObj = new CtrlGroupDirective();
    controlGroupDirectiveObj.template = templateStr;
    controlGroupDirectiveObj.transclude = false;
    controlGroupDirectiveObj.scope.placeholder = '@?';

    const origLink = controlGroupDirectiveObj.link;
    controlGroupDirectiveObj.link = function (scope) {
        origLink.apply(this, arguments);
        // The inputCtrlType aspect is created from the property metadata
        scope.metatype = scope.model.aspects.inputCtrlType;
    };
    return controlGroupDirectiveObj;
}
