const TMI_BRANDS = ['toy', 'lex', 'aut', 'kfi'];
const COMMERCIAL_BRANDS = ['kin'];

export default {
    /**
     * Works out the product code based on brand
     *
     * @param {Object} [brand]
     * @returns {String} null if the view model does not have a sessionUUID, sessionUUID otherwise
     */


    getProductCode: (brand) => {
        if (TMI_BRANDS.includes(brand)) {
            return 'TraditionalMotor'
        }
        if (COMMERCIAL_BRANDS.includes(brand)){
                return 'CommercialVehicle'
        } else {
                return 'PersonalMotor'
        }
    }
}