import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./RadioSelect.html';
import styles from './RadioSelect-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default {
    template: hashedTemplate,
    bindings: {
        model: '=',
        options: '=',
        explicitErrorMessage: '@?'
    },
    controller: function () {
        const ctrl = this;
        ctrl.select = (choice) => {
            ctrl.model.value = choice;
        };
    }
};

