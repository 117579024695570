import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './GuidanceMediaArea-hashed.scss';
import templateStr from 'text!./GuidanceMediaArea.html';

export default {
    restrict: 'E',
    bindings: {
        selectedOccupation: '<'
    },
    template: cssUtil.hashTemplate(templateStr, styles),
    controller: ['$scope', 'guidance.DynamicContent', ($scope, dynamicContent) => {
        $scope.getCustomImage = dynamicContent.getOccupationImage;
    }]
};
