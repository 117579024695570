import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

const trackingConfig = ApplicationFeatures.getAllFeatures().tracking;

/**
 * Check if service is enabled in a portal's configuration
 *
 * @param {String} serviceName
 * @returns {Boolean}
 */
function isEnabled(serviceName) {
    return !!(trackingConfig || {})[serviceName];
}

function getCredentials(serviceName) {
    if (!isEnabled(serviceName)) return {};
    try {
        /**
         INLINE DYNAMIC REQUIRE
         */
        /*eslint-disable */
        // Webpack doesn't work well with template strings inside of require()
        return require('credentials/' + serviceName + '.credentials.json');
        /* eslint-enable */
    } catch (error) {
        throw Error(`Please include ${serviceName}.credentials.json in the credentials directory.`);
    }
}

export default {
    getCredentials,
    $get: () => getCredentials
};