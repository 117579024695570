export default {
    onEvent: (event) => {
        const {
            category, eventName
        } = event;
        const timestamp = new Date().toString();
        const key = `gw-events-${timestamp}-${category}-${eventName}`;
        try {
            localStorage.setItem(key, JSON.stringify(event));
        } catch (error) {
            console.error('Local storage event store error');
        }
    }
};