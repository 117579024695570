import next from './Next';

export default function (step, Q) {
    return function (eventName) {
        if (step[eventName]) {
            return step[eventName];
        }
        step[eventName] = next(step, Q);
        return step[eventName];
    };
}