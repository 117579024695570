import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $sce, $state, StateUtils, LoadSaveService, submissionViewModel, ScrollService_AND) => {
    const ctrl = Object.create(BaseCtrl);

    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submissionVM = submissionViewModel();

    /**
     * Escapes <code>url</code>.
     * https://docs.angularjs.org/api/ng/service/$sce
     * @param {String} url URL to escape
     * @returns {*} Wrapped URL
     */
    function trustSrc(url) {
        return $sce.trustAsResourceUrl(url);
    }
    window.addEventListener('message', (event) => {
        if (event.data === 'scrollToTop') {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 5000);
        }
    });

    window.ackPaymentSuccess_AND = function () {
        flowModel.next(submissionVM);
    };

    window.ackPaymentFailure_AND = function () {
        $state.go('purchaseFailed_AND');
    };

    ctrl.init = () => {
        flowModel.isAllStepsDisabled_AND = false;
        $scope.stepNumber = (submissionVM.value.isAnnualPaymentPlan_AND() === true) ? 3 : 4;
        $scope.isAnnualPayment = submissionVM.value.isAnnualPaymentPlan_AND()
        $scope.amountToPay = (submissionVM.value.isAnnualPaymentPlan_AND() === true) ? submissionVM.value.bindData.paymentPlans[0].total.amount.toFixed(2) : submissionVM.value.bindData.paymentPlans[1].downPayment.amount.toFixed(2)
        $scope.scrollToTop = () => ScrollService_AND.scrollToTop();
        LoadSaveService.preBindSubmission(submissionVM.value)
            .then((submission) => {
                $scope.gatewayURL = trustSrc(submission.bindData.sagePayURL_itb);
            })
            // Display technical error screen if preBind failed
            .catch((error) => {
                error = error[0];
                if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                    switch (error.baseError.error.data.appErrorCode) {
                        case 602: // GW_UNDERWRITING_ERROR
                        case 631: // AND_HIGHRISK_VALIDATION_ERROR
                            flowModel.jumpToExit('uwError_AND')
                            break;
                        case 608: // GW_ENTITY_VALIDATION_ERROR
                        case 611: // GW_BLOCK_QUOTE_UNDERWRITING_ERROR
                            flowModel.jumpToExit('quoteDeclined_AND', null, {appErrorCode_AND: error.baseError.error.data.appErrorCode});
                            break;
                        case 624: // AND_CUE_ENTITY_VALIDATION_ERROR
                            flowModel.jumpToExit('quoteDeclinedCue_AND');
                            break;
                        case 630: // AND_VRN_MISSING
                            flowModel.jumpToExit('contactUsMissingVRNView_AND');
                            break;
                        default: // Technical error
                            $state.go('contactUs');
                    }
                } else { // Technical error
                    $state.go('contactUs');
                }
            });
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$sce', '$state', 'StateUtils', 'qnb.LoadSaveService', 'submissionViewModel', 'ScrollService_AND'];
export default fn;