import fullQuoteTransition from '../FullQuoteTransition';
import paFlowDefnFactory from 'edge/quoteandbind/pa/config/FlowConfig';
import bopFlowDefnFactory from 'edge/quoteandbind/bop/config/FlowConfig';

const paFlowStepsRequiredForTransition = {
    contactDetailsComplete: 'paContactDetailsComplete',
    coverablesComplete: 'paCoverablesComplete',
    offeringSelected: 'offeringSelected',
    moreInfoComplete: 'paMoreInfoComplete'
};

const bopFlowStepsRequiredForTransition = {
    contactDetailsComplete: 'bopContactDetailsComplete',
    coverablesComplete: 'bopCoverablesComplete',
    offeringSelected: 'offeringSelected',
    moreInfoComplete: 'bopMoreInfoComplete'
};

function transitionToBOPFromGuidanceFlow(...params) {
    fullQuoteTransition.transition(bopFlowDefnFactory, bopFlowStepsRequiredForTransition, ...params);
}

function transitionToPAFromSLQFlow(...params) {
    fullQuoteTransition.transition(paFlowDefnFactory, paFlowStepsRequiredForTransition, ...params);
}

export default {
    fullQuoteFlowDefn: function (submission, productCode, $q, ...params) {
        if (productCode === 'PersonalAuto') {
            transitionToPAFromSLQFlow(submission, $q, ...params);
        } else if (productCode === 'BusinessOwners') {
            transitionToBOPFromGuidanceFlow(submission, $q, ...params);
        }
    }
};