import templateStr from 'text!./pm-edit-person.html';
import DateUtil from 'gw-portals-util-js/DateUtil';
import moment from 'moment';

export default ['AgeAllowedToDrive', 'MaximumAge', '$rootScope',
    (AgeAllowedToDrive, MaximumAge, $rootScope) => {
        return {
            restrict: 'A',
            scope: {
                // Either use person or driver, not both!
                personVM: '=?personVm', // When all properties are flat on the same dto
                driverVM: '=?driverVm', // When person fields are nested inside a person property
                readonly: '<?'
            },
            template: templateStr,
            controller: ['$scope', ($scope) => {
                if ($scope.personVM) {
                    $scope.driverVM = $scope.personVM;
                } else if ($scope.driverVM) {
                    $scope.personVM = $scope.driverVM.person;
                }
                // Gender radio options
                $scope.maleGender = $scope.driverVM.gender_itb.aspects.availableValues.find((gender) => gender.code === 'M');
                $scope.femaleGender = $scope.driverVM.gender_itb.aspects.availableValues.find((gender) => gender.code === 'F');
                $scope.genderTooltip = {
                    content: 'and.quoteandbind.pm.directives.templates.pm-edit-person.tooltip.gender',
                    trigger: 'click'
                };
                // DOB limits
                const today = DateUtil.currentDateAtMidnight();
                $scope.maxDateOfBirth = {
                    year: today.getFullYear() - AgeAllowedToDrive,
                    month: today.getMonth(),
                    day: today.getDate()
                };
                $scope.minDateOfBirth = {
                    year: today.getFullYear() - MaximumAge,
                    month: today.getMonth(),
                    day: today.getDate()
                };
                // UK residence limits
                $scope.ukResidenceMinDate = moment(today)
                    .subtract(5, 'years');

                // Occupation
                if ($rootScope.productCode === 'PersonalMotor'){
                    $scope.primaryOccupationTooltip = {
                        content: 'and.quoteandbind.pm.directives.templates.pm-edit-person.tooltip.primary occupation',
                        trigger: 'click'
                    };
                } else {
                    $scope.primaryOccupationTooltip = {
                        content: 'and.quoteandbind.pm.directives.templates.pm-edit-person.tooltip.primaryoccupation.tm',
                        trigger: 'click'
                    };
                }
                
                const occupations = $scope.driverVM.occupation_itb.aspects.availableValues;
                $scope.occupationTypeaheadDef = {
                    getMatches: (input) => {
                        if (!input || input.length < 2) return [];
                        const results = occupations.filter((key) =>
                            key.name.toLowerCase().startsWith(input.toLowerCase()));
                        return results.slice(0, 8);
                    },
                    toString: (typekey) => typekey.name
                };

                // Employers Business
                $scope.employersBusinessTooltip = {
                    content: 'and.quoteandbind.pm.directives.templates.pm-edit-person.tooltip.employers business',
                    trigger: 'click'
                };
                const employersBusiness = $scope.driverVM.employersBusiness_itb.aspects.availableValues;
                $scope.employersbusinesstypeaheadDef = {
                    getMatches: (input) => {
                        if (!input || input.length < 2) return [];
                        const results = employersBusiness.filter((key) =>
                            key.name.toLowerCase().startsWith(input.toLowerCase()));
                        return results.slice(0, 8);
                    },
                    toString: (typekey) => typekey.name
                };
            }]
        };
    }];
