import templateStr from 'text!./return-to-amp.html';

export default ['brandingData', '$location', '$window', 'qnb.PolicyService', (brandingData, $location, $window, PolicyService) => {
    return {
        restrict: 'A',
        scope: {
            label: '@?',
            ampUrl: '@?',
            btnClass: '@?'
        },
        template: templateStr,
        controller: ['$scope', ($scope)=>{
            $scope.brand = brandingData.toLowerCase();
            $scope.label = $scope.label || 'and.directives.SaveAndReturnToAMP.btn';
            $scope.goToPortal = function () {
                PolicyService.getPortalDomain_AND()
                    .then((url)=>{
                        $window.location = `${url}`;
                    });

            };
        }]
    };
}];