export default function (stepOrJunction) {
    let _data;

    /**
     * Associate a object for holding various properties with a step or junction
     *
     * @param {Object|Function} data If no object is provided the default behavior is to return an empty object.
     * @returns {*}
     *
     */
    return function (data) {
        if (arguments.length === 0) {
            return _data || {};
        }
        _data = data;

        return stepOrJunction;
    };
}