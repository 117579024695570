/** Creates a new display key translator. Display key translator have to functions. <code>translate</code> is used
 * to provide an immediate version of the translation. It would produce an empty string if translations are not
 * ready yet. <code>refresh</code> is used to notify this key translator that translations changed.
 * @param {Function} imm function for the immediate translation.
 * @param {String} key key of this translator.
 * @param {promise} promise for the key translation. This key is marked as "ready" after the promise is resolved.
 * @returns {Object}
 */
function create(imm, key, promise) {
    let ready = false;
    let staticText;
    promise.then((value) => {
        staticText = value;
    });
    promise.finally(() => {
        ready = true;
    });

    return {
        'translate': (params) => {
            if (!ready) {
                return '';
            }
            if (!params) {
                return staticText;
            }
            return imm(key, params);
        },
        'refresh': () => {
            staticText = imm(key);
        }
    };
}

/** Implementation of the translator for the single display key (and do this very good).
 * Main responsibility of this class is to provide an immediate translation even if it is not loaded yet. It could
 * also update translation after parent notifies it about the locale change.
 */
export default {
    create
};