const creditCardMasks = {
    'amex': {
        'input': '9999-999999-99999',
        'mask': '####-######-99999'
    },
    'dinersclub': {
        'input': '9999-999999-9999',
        'mask': '####-######-9999'
    },
    'discover': {
        'input': '9999-9999-9999-9999',
        'mask': '####-####-####-9999'
    },
    'mastercard': {
        'input': '9999-9999-9999-9999',
        'mask': '####-####-####-9999'
    },
    'visa': {
        'input': '9999-9999-9999-9999',
        'mask': '####-####-####-9999'
    }
};

export default {
    getInputValidatorForIssuerCode: (code) => {
        return creditCardMasks[code].input;
    },

    getInputMaskForIssuerCode: (code) => {
        return creditCardMasks[code].mask;
    },

    getInputLength: (code) => {
        return creditCardMasks[code].input.replace(/-/g, '').length;
    },

    getMaskedValue: (number, issuer) => {
        const mask = creditCardMasks[issuer].mask;

        let valueMasked = '';

        if (number.length && number.length === mask.replace(/-/g, '').length) {
            let numberPosition = 0;
            angular.forEach(mask.split(''), (chr, i) => {
                if (mask[i] === '#') {
                    valueMasked += '#';
                    numberPosition++;
                } else if (mask[i] === '-') {
                    valueMasked += '-';
                } else {
                    valueMasked += number[numberPosition];
                    numberPosition++;
                }
            });
        }

        return valueMasked;
    }
};