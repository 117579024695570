import BaseCtrl from 'edge/platform/base/BaseCtrl';
import FormUtil from 'edge/platform/modelvalidation/FormUtil';

const fn = function (
    $scope, $stateParams, ModalService, QuoteEmailService, $q,
    qnbCompositeFlowDefn, returnStateFn, errorStateFn, effectiveDateStateFn,
    wizardFlowService, StateUtils, $filter, $state, ViewModelService, $rootScope
) {
    const ctrl = Object.create(BaseCtrl);

    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submission = $stateParams.submission ? $stateParams.submission : StateUtils.activeDataForState(true).model;
    flowModel.isAllStepsDisabled_AND = true;
    ctrl.submission = submission;

    ctrl.emailAddress = {
        get value() {
            return ctrl.submission.baseData.accountHolder.emailAddress1.value ? ctrl.submission.baseData.accountHolder.emailAddress1.value : ctrl.submission.baseData.accountHolder.emailAddress1;
        },
        set value(newValue) {
            if(ctrl.submission.baseData.accountHolder.emailAddress1.value != null) {
                ctrl.submission.baseData.accountHolder.emailAddress1.value = newValue;
            }
            else {
                ctrl.submission.baseData.accountHolder.emailAddress1 = newValue;
            }
        },
        aspects: {
            inputCtrlType: 'text',
            required: true,
            get valid() {
                const email = ctrl.submission.baseData.accountHolder.emailAddress1.value ? ctrl.submission.baseData.accountHolder.emailAddress1.value : ctrl.submission.baseData.accountHolder.emailAddress1;
                return !!email;
            },
            get validationMessages() {
                const email = ctrl.submission.baseData.accountHolder.emailAddress1.value ? ctrl.submission.baseData.accountHolder.emailAddress1.value : ctrl.submission.baseData.accountHolder.emailAddress1;
                if (!!email === "") {
                    return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                }
                return [];
            }
        }
    };

    if ($rootScope.productCode === 'PersonalMotor'){
        ctrl.isEmailed = false;
    } else {
        ctrl.isEmailed = true;
    }
    
    ctrl.quoteReference = $filter('translate')('and.quoteandbind.pm.views.QuoteSaved.Your quote reference is', { quoteID: submission.quoteID.value ? submission.quoteID.value : submission.quoteID});

    const handleResponse = (promise) => {
        $q.when(promise, () => {
            ctrl.isEmailed = true;
        }, () => {
            ModalService.showError(
                'quoteandbind.directives.QnbWizardButtons.Error',
                'quoteandbind.directives.QnbWizardButtons.Sorry, we were not able to email the quote details at this time.'
            );
        });
    };

    const emailQuote = () => {
        const submissionValue = submission.value ? submission.value : submission;
        const quoteID = submissionValue.quoteID.value ? submissionValue.quoteID.value : submissionValue.quoteID;
        const sessionID = submissionValue.sessionUUID.value ? submissionValue.sessionUUID.value : submissionValue.sessionUUID;
        const emailAddress1 = submissionValue.baseData.accountHolder.emailAddress1.value ? submissionValue.baseData.accountHolder.emailAddress1.value : submissionValue.baseData.accountHolder.emailAddress1;
        const emailInfo = {
            quoteID,
            sessionID,
            emailAddress1
        };
        return QuoteEmailService.emailQuote(emailInfo);
    };

    const saveAndEmailQuote = () => {
        const submissionValue = submission.value ? submission.value : submission;
        const model = ViewModelService.create(angular.copy(submissionValue), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', 'EmailQuote_itb');

        if (!model.bindData.chosenQuote.value) {
            const selectedQuote = model.quoteData.offeredQuotes.value.find((quote) => quote.isCustom === true);
            model.bindData.chosenQuote.value = selectedQuote.publicID;
        }
        model.bindData.communicationPreference_itb = model.baseData.accountHolder.communicationPreference_itb;
        return QuoteEmailService.updateQuotedSubmissionAndEmailQuote_AND(model.value);
    };

    ctrl.emailQuote = function () {
        FormUtil.markFormsSubmitted(ctrl.form);
        if (ctrl.emailAddress.aspects.valid) {
            // If the status is quoted update binding data and email the quote
            if (submission.baseData.periodStatus === 'Quoted') {
                handleResponse(saveAndEmailQuote());
            } else { // in all other cases just send the mail data
                handleResponse(emailQuote());
            }
        }
    };

    if ($rootScope.productCode === 'TraditionalMotor' || $rootScope.productCode === 'CommercialVehicle'){
        emailQuote();
    }

    ctrl.return = () => {
        flowModel.isAllStepsDisabled_AND = false;
        $state.go(flowModel.currentStep.name);
    };

    ctrl.cancel = () => {
        const model = StateUtils.activeDataForState(true).model;
        flowModel.exitEmailSession_AND(model);
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$stateParams', 'ModalService', 'qnb.EmailService', '$q', 'qnb.CompositeFlowDefn', 'qnb.returnStateFn',
    'qnb.errorStateFn', 'qnb.effectiveDateStateFn', 'WizardFlowService', 'StateUtils', '$filter', '$state', 'ViewModelService', '$rootScope'];

export default fn;