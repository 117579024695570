import templateStr from 'text!./pm-edit-motor-conviction.html';
import moment from 'moment';


// Edge doesn't support Map<Integer, String>, and since we might want to control the labels on the frontend anyway,
const CONVICTION_POINTS_OPTIONS_LABEL = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11
};

export default [() => {
    return {
        restrict: 'AE',
        scope: {
            pmConvictionCodes: '<',
            pmConvictions: '=?',
            addConviction: '=?',
            removeConviction: '=?',
            driver: '<',
            submission: '='
        },
        controller: ['$scope', function ($scope) {
            $scope.minConvictionDate = moment().subtract(5, 'years');
            $scope.maxConvictionDate = moment();

            // Build typelist structure
            $scope.convictionPointsAvailableValues = [];
            angular.forEach(CONVICTION_POINTS_OPTIONS_LABEL, (key, value) => {
                $scope.convictionPointsAvailableValues.push({code: key, name: value});
            });
        }],
        template: templateStr
    };
}];