import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./Notification.html';
import styles from './Notification-hashed.scss';

export default ['$timeout', $timeout => ({
    restrict: 'E',
    transclude: true,
    replace: true,
    scope: {
        text: '@',
        trigger: '='
    },
    template: cssUtil.hashTemplate(template, styles),
    link: $scope => {
        $scope.trigger = () => {
            $timeout(() => {
                $scope.open = true;
                $timeout(() => {
                    $scope.open = false;
                }, 3000);
            }, 0);
        };
    }
})];