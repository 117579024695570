import BrowserDetectData from 'gw-portals-featuredetection-js/browserdetect/BrowserDetectData';

export default (window, campaignData) => {
    function trackPageView(event) {
        window.analytics.page(event.payload.path, {
            path: event.payload.path,
            context: {
                'campaign': {
                    'source': campaignData.utm_source,
                    'name': campaignData.utm_campaign,
                    'medium': campaignData.utm_medium,
                    'term': campaignData.utm_term,
                    'content': campaignData.utm_content
                }
            }
        });
    }

    function trackServerCall(event) {
        window.analytics.track('serverCall', {
            path: event.payload.path,
            method: event.payload.method,
            elapsedTime: event.payload.elapsedTime,
            requestId: event.payload.requestId,
            channel: BrowserDetectData.browserType
        });
    }

    function trackServerCallError(event) {
        window.analytics.track('serverCallError', {
            path: event.payload.path,
            method: event.payload.method,
            elapsedTime: event.payload.elapsedTime,
            errorMessage: event.payload.errorMessage,
            requestId: event.payload.requestId,
        });
    }

    function trackEvent(event) {
        window.analytics.track(event.eventName, event);
    }

    function trackJourney(event) {
        window.analytics.track('journey', event.payload);
    }

    function identify(event) {
        window.analytics.identify(event.payload.userId);
    }

    return {
        trackPageView,
        trackServerCall,
        trackServerCallError,
        trackEvent,
        trackJourney,
        identify
    };
};