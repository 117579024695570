import RouteConfig from './config/RouteConfig';
import GuidanceService from './services/GuidanceService';
import GuidanceDynamicContentUtil from './util/DynamicContentUtil';
import GuidanceAddress from './directives/Address/GuidanceAddress';
import GuidanceOccupation from './directives/Occupation/GuidanceOccupation';
import GuidanceQuestions from './directives/Questions/GuidanceQuestions';
import GuidanceRecommendations from './directives/Recommendations/GuidanceRecommendations';
import GuidanceMediaArea from './directives/MediaArea/GuidanceMediaArea';
import 'angularRouter';
import 'edge/quoteandbind/util/app';

export default angular.module('edge.guidance.common.app', ['edge.quoteandbind.util.app'])
    .factory('guidance.GuidanceService', GuidanceService)
    .factory('guidance.DynamicContent', [() => GuidanceDynamicContentUtil])
    .directive('gwGuidanceAddressPanel', GuidanceAddress)
    .directive('gwGuidanceOccupationPanel', GuidanceOccupation)
    .directive('gwGuidanceQuestionsPanel', GuidanceQuestions)
    .directive('gwGuidanceRecommendationsPanel', GuidanceRecommendations)
    .component('gwGuidanceMediaArea', GuidanceMediaArea)
    .constant('addressSettings', {
        minimumCharactersBeforeSearching: 3,
        msOfInactivityBeforeSearching: 500
    })
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new RouteConfig(RootStateConfig);
        for (const stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, states[stateName]);
            }
        }
    }]);
