import GuidanceProductView from 'text!../views/guidance-product.html';
import GuidanceProductCtrl from '../controllers/GuidanceProductCtrl';

export default (RootStates) => {
    const parentState = RootStates.quote_bind;

    return {
        guidanceProduct: {
            parent: parentState,
            url: '/guidanceproduct',
            controller: GuidanceProductCtrl,
            template: GuidanceProductView,
            data: {
                trackingUrl: '/guidance/common'
            }
        }
    };
};