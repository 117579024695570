/**
 * Service for providing a set of helper functions across the application
 *
 * @returns {Object}
 */
export default [() => {
    let counter = 0;
    return {
        debounce: debounce,

        getUniqueID: () => {
            return counter++;
        },

        assert: (() => {
            const assert = (value, message) => {
                if (!value) {
                    throw new Error(message || 'Failed assertion');
                }
            };

            assert.string = (value, message) => {
                assert(value && typeof value === 'string', message || 'String expected');
            };

            assert.stringKey = (object, key, message) => {
                assert.string(object && object[key], message || `Expected ${key} to be a string`);
            };

            return assert;
        })()
    };
}];


/**
 * The debounce function limits the rate at which a function can run
 * @param {string} func The function to be called
 * @param {number} wait The function will be called after it stops being called for N milliseconds.
 * @param {boolean} immediate If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
 * @returns {function} Returns a function, that, as long as it continues to be invoked, will not be triggered.
*/
function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}