import templateStr from 'text!./templates/ctrl-group.html';
import _ from 'lodash';

let inputId = 0;

export default () => {
    return {
        require: ['?^form', '?^ngForm'],
        transclude: true,
        scope: {
            label: '@?',
            readonly: '=?',
            disabled: '=?',
            model: '=',
            currency: '@',
            flavor: '@',
            hideInputLabel: '<?',
            explicitErrorMessage: '@?',
            inline: '=?',
            tooltip: '=?',
            customID: '@?customId', // ANDIE
            subLabel: '@?', // ANDIE
            errorType: '@?'
        },
        template: templateStr,

        controller: ['$scope', '$timeout', ($scope, $timeout) => {
            $scope.errorMsg = '';
            if (!$scope.model) {
                throw new Error(`Input with label "${$scope.label}" has an undefined model scope value`);
            }


            $scope.isVisible = () => {
                return !('visible' in _.get($scope.model, 'aspects', {})) || _.get($scope.model, 'aspects.visible');
            };

            $scope.pristine = true;
            // once a different value is set on the model then we mark the field as not pristine
            $scope.$watch('model.value', (newValue, oldValue) => {
                $scope.pristine = (!$scope.pristine) ? false : newValue === oldValue;
            });
            $scope.$watch($scope.isVisible, (newValue, oldValue) => {
                if (!newValue && oldValue) {
                    const question = $scope.model.question;
                    const answers = $scope.model.modelValue.answers;

                    question.filters.forEach(filter => {
                        if (answers[filter.questionCode] !== filter.answer) {
                            $scope.model.value = null;
                        }
                    });
                }
            });

            $scope.$timeout = $timeout;
            // ANDIE - To show Tooltip below the gw-control labels on tooltip icon clicks
            $scope.showTooltip = false;
            $scope.toggleTooltipDisplay = () => {
                $scope.showTooltip = !$scope.showTooltip;
            };
        }],

        link: (scope, element, attrs, ctrls) => {
            if (element[0].attributes[4] !== undefined || element[0].attributes[6] !== undefined){
                if (element[0].attributes[0].value === 'radio'){
                    scope.errorMsg = 'This field is required'
                }
                if (element[0].attributes[0].value === 'text'){
                    scope.errorMsg = 'This field is required'
                }
                if (element[0].attributes[0].value === 'dropdown'){
                    scope.errorMsg = 'Please fill out this field'
                }
            }
            const parentForm = ctrls[0];

            if (parentForm && !parentForm.invalidInputs) {
                parentForm.invalidInputs = []; // The IDs of invalid inputs, used to $anchorScroll to correct element
            }
            const validityFn = () => {
                // If the model is a coverage term, use the coveragePublicID property
                const publicID = scope.model.coveragePublicID ? scope.model.coveragePublicID : scope.model.publicID;
                if (!scope.model.aspects.valid && parentForm && parentForm.invalidInputs && !parentForm.invalidInputs.includes(publicID)) {
                    parentForm.invalidInputs.push(publicID);
                } else if (scope.model.aspects.valid) {
                    removeInvalidInput(publicID);
                }

                return scope.model.aspects.valid;
            };

            function checkIfElementIsNotNull(elementId) {
                return document.getElementById(elementId) !== null;
            }

            function removeInvalidInput(publicID) {
                if (parentForm && parentForm.invalidInputs.includes(publicID)) {
                    const i = parentForm.invalidInputs.indexOf(publicID);
                    parentForm.invalidInputs.splice(i, 1);
                }
            }

            const setPristineFn = () => {
                // we need to use this postDigest function here because the
                // watch on 'model.value' in the controller gets called after
                // this function is called even though the model was changed first
                scope.$$postDigest(() => {
                    scope.pristine = true;
                    scope.$apply();
                });
            };
            if (parentForm) {
                const removeListenerFn = parentForm.addModelControl(validityFn, setPristineFn);
                scope.$on('$destroy', () => {
                    removeInvalidInput();
                    return removeListenerFn();
                });
            }


            if (!('readonly' in scope) && attrs.readonly === '') {
                // if readonly attr exists with no value then set it to true
                scope.readonly = true;
            }

            scope.inputId = scope.customID || inputId++;

            scope.showError = () => {
                const formHasBeenSubmitted = (parentForm) ? parentForm.submitted : true;
                if ((!_.get(scope.model, 'aspects.valid') && !scope.pristine) || (!_.get(scope.model, 'aspects.valid') && formHasBeenSubmitted)) {
                    if (scope.focused === true && scope.model._accessorCode === 'emailAddress1') {
                        return false;
                    }
                    return true;
                }
            };

            element.on('focusin', () => {
                scope.focused = true;
                scope.$apply();
            });
            element.on('focusout', () => {
                scope.focused = false;
                // Wrap apply in timeout function so that it can safely run in the next digest cycle
                scope.$timeout(() => scope.$apply());
            });
        }
    };
};
