import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!../../templates/generic-tile.html';
import styles from './_wizard-tile-hashed.scss';

export default [() => ({
    restrict: 'E',
    scope: {
        state: '&',
        callback: '&',
        flavor: '@',
        icon: '@'
    },
    transclude: {
        'footer': '?gwTileFooter',
        'actions': '?gwTileAction',
        'content': 'gwTileContent'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'tiles.tilesUtilFunctions', ($scope, tilesUtilFunctions) => {
        $scope.tileType = 'wizard';
        $scope.customFlavor = $scope.flavor || 'Default';
        $scope.allow = {
            header: false,
            content: true,
            actions: true,
            footer: true
        };

        $scope.clickCallback = () => {
            const currentState = $scope.state();
            // Can invoke callback when not in the active state and editable
            if (currentState !== 'active' && currentState !== 'disabled') {
                $scope.callback();
            }
        };

        $scope.getActiveState = tilesUtilFunctions.getActiveStateFunction($scope.state);
        $scope.getStyle = tilesUtilFunctions.getStyleFunction(styles);
    }]
})];
