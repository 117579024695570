import './InitialScreen.scss';
import startAddBuildingWizardFlow from '../AddBuildingWizard/flow';
import appFeatures from 'effective-features!';

class BuildingsListCtrl {
    constructor($injector, $state, StateUtils, httpSettings, spreadsheetProcessorConfig, ModalService, $filter) {
        this.$injector = $injector;
        this.$state = $state;
        this.model = StateUtils.activeDataForState(true).model;
        this.jobID = this.model.quoteID ? this.model.quoteID.value : this.model.jobID.value;
        this.ModalService = ModalService;
        this.$filter = $filter;

        const xCenterDownloadURI = httpSettings.serviceBaseURLs.pc;
        this.templateDownloadEndpointURL = xCenterDownloadURI + spreadsheetProcessorConfig.downloadTemplateURL;
        this.templateUploadEndpointURL = spreadsheetProcessorConfig.uploadTemplateURL;
        this.spreadsheetProcessorResult = this.$state.params.spreadsheetProcessorResult;
        this.spreadsheetProcessorEnabled = appFeatures.spreadsheetprocessor && appFeatures.spreadsheetprocessor.enabled;

        this.locations = this.model.lobData.commercialProperty.coverables.locations.value;
        const hasBuildings = this.locations.some(loc => loc.buildings && loc.buildings.length > 0);

        const isSpreadsheetStatusFail = () => (['fail-processing', 'fail-empty', 'fail-unchanged'].includes(this.spreadsheetProcessorResult.status));

        if (hasBuildings || (this.spreadsheetProcessorResult != null && !isSpreadsheetStatusFail)) {
            $state.go('cpBuildingWizardBuildingsList', {spreadsheetProcessorResult: this.spreadsheetProcessorResult});
        }

        if (this.spreadsheetProcessorResult) {
            this.$state.params.spreadsheetProcessorResult = undefined;
        }

        this.manualAddBuilding = () => {
            startAddBuildingWizardFlow(this.$injector);
        };

        this.spreadsheetProcessor = () => {
            this.$state.go('cpBuildingWizardSpreadsheetScreen', {
                manualAddBuildingDelegator: this.manualAddBuilding,
                callbackState: 'cpBuildingWizardInitialScreen'
            });
        };
    }
}

BuildingsListCtrl.$inject = ['$injector', '$state', 'StateUtils', 'httpSettings', 'qnb.spreadsheetProcessorConfig', 'ModalService', '$filter'];

export default BuildingsListCtrl;
