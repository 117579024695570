import _ from 'lodash';
import MapUtil from 'gw-portals-util-js/MapUtil';
import PresentationPages from './_PresentationPages';

/* Parses capability metadata. */
function parseFlavor(meta, name) {
    const res = {
        name
    };
    const pages = PresentationPages.createPages(meta, res);
    res.pages = pages;

    res.get = (page) => {
        const resPage = pages.get(page);
        if (!resPage) {
            throw new Error(`No presentation metadata for page ${page} is defined for flavor ${name}`);
        }
        return resPage;
    };

    return Object.freeze(res);
}

export default {
    /**
     * Creates a representation of the flavors belonging to capabilities in presentation metadata.
     *
     * Following properties and functions are defined on the object:
     * <dl>
     *     <dt>allFlavors</dt><dd>List of all flavor defined for the capability</dd>
     *     <dt>has(flavorName)</dt><dd>Checks if flavorName is defined for the flavor definitions.</dd>
     *     <dt>get(flavorName)</dt><dd>Retrieves a flavor by its name. Throws Error if flavor was not found.
     * </dl>
     *
     * @param {Object} metadata information about the capability.
     *
     * @returns {Object}
     */
    createFlavors: (metadata) => {
        const flavorMap = _.mapValues(metadata, parseFlavor);

        return {
            allFlavors: _.values(flavorMap),
            has() {
                return _.has(flavorMap, arguments[0]);
            },
            get() {
                const flavor = MapUtil.get(flavorMap, arguments[0]);
                if (!flavor) {
                    throw new Error(`No flavor with name ${arguments[0]} is defined`);
                }
                return flavor;
            }
        };
    }
};