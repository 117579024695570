import VinLookupService from './services/VinLookupService';
import VehicleLookupService_AND from './services/VehicleLookupService_AND'; // ANDIE
import VehicleImageLookupService from './services/VehicleImageLookupService';
import AvailabilityService from './services/AvailabilityService';
import CoverageService from './services/CoverageService';
import ScrollService_AND from './services/ScrollService_AND'; // ANDIE
import 'portalConfig';
import 'edge/platform/base/module';

export default angular.module('edge.policyjob.app', ['portal.config', 'edge.platform.base'])
    .config(['EventHubProvider', (EventHubProvider) => {
        EventHubProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'journey',
                eventName: 'policyChange'
            },
            transformer(payload, send) {
                if (payload && payload.success && payload.path && payload.method === 'bind' && payload.path === 'policychange/policychange') {
                    const policyChange = payload.response.policyChange;
                    if (policyChange.status === 'Bound') {
                        send({
                            accountNumber: policyChange.accountNumber,
                            policyNumber: policyChange.policyNumber,
                            productCode: policyChange.productCode,
                            effectiveDate: policyChange.effectiveDate,
                            jobID: policyChange.jobID,
                            history: policyChange.history
                        });
                    }
                }
            }
        });
        EventHubProvider.registerTransformer({
            source: {
                category: 'wizard',
                eventName: '*'
            },
            target: {
                category: 'tracking',
                eventName: 'pageView'
            },
            transformer(payload, send) {
                if (payload && ((payload.url === '/endorsement') || (payload.url === '/endorsement')) && payload.model && payload.model.policyChange && payload.model.policyChange.productCode && payload.toState) {
                    const url = payload.url;
                    const wizardStepName = payload.toState.name;
                    const productCode = payload.model.policyChange.productCode;
                    send({
                        path: `${url}/${productCode}/${wizardStepName}`
                    });
                }
            }
        });
    }])

    .value('policyjob.endpointConfig', {
        'vinlookup': 'vehicleinfo/lookup',
        'availability': 'availability'
    })

    .value('policyjob.xCenterRouteConfig', 'quote')

    .factory('VinLookupService', VinLookupService)
    .factory('VehicleLookupService_AND', VehicleLookupService_AND) // ANDIE
    .factory('VehicleImageLookupService', VehicleImageLookupService)
    .factory('AvailabilityService', AvailabilityService)
    .factory('CoverageService', CoverageService)
    .factory('ScrollService_AND', ScrollService_AND); // ANDIE
