import BaseCtrl from 'edge/platform/base/BaseCtrl';
import {wizardParentState} from '../RouteConfig';
import startAddBuildingWizardFlow from '../flow';
import _ from 'lodash';

const fn = ($scope, $state, $stateParams, $injector, StateUtils) => {
    const model = StateUtils.activeDataForState(true).model;
    $scope.flowModel = $state.get(wizardParentState).data.flow;
    $scope.location = _.find(model.lobData.commercialProperty.coverables.locations.value, (location) => {
        return location.publicID === $stateParams.model.selectedLocation.publicID;
    });
    $scope.building = _.find($scope.location.buildings, (building) => {
        return building.publicID === $stateParams.model.selectedBuilding.publicID;
    });
    $scope.coverages = $scope.building.coverages;

    const ctrl = Object.create(BaseCtrl);
    ctrl.init = () => {
    };

    if ($scope.location.isNew) {
        $scope.location.isNew = false;
    }

    ctrl.addAnotherBuilding = (callback) => {
        callback();
        startAddBuildingWizardFlow($injector);
    };

    $scope.editLocation = () => {
        $state.go('cpBuildingWizardEditLocation', {publicID: $scope.location.publicID, buildingPublicID: $scope.building.publicID});
    };

    $scope.next = next => next($stateParams.model);

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams', '$injector', 'StateUtils'];

export default fn;
