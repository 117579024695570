import _ from 'underscore';
import Driver from 'edge/quoteandbind/pm/models/PmDriver';
import Vehicle from 'edge/quoteandbind/pm/models/PmVehicle';

const BRAND_COVERAGES = {
    itb: {
        COURTESY_CAR: 'PMCourtesyCarPlusCov',
        KEY_COVER: 'PMKeyProtect',
        BREAKDOWN_COV: 'PMRoadRescueCov',
        BREAKDOWN_COV_TERM: 'PMRoadRescueLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Level1', 'Level2', 'Level3'],
        LEGAL_EXPENSES: 'PMLegalExpensesCov',
        ENHANCED_COURTESY_CAR: 'TMEnhancedCourtesyCarCov',
    },
    dgt: {
        COURTESY_CAR: 'PMCourtesyCarPlusCov',
        KEY_COVER: 'PMKeyProtect',
        BREAKDOWN_COV: 'PMRoadRescueCov',
        BREAKDOWN_COV_TERM: 'PMRoadRescueLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Level1', 'Level2', 'Level3'],
        LEGAL_EXPENSES: 'PMLegalExpensesCov',
        ENHANCED_COURTESY_CAR: 'TMEnhancedCourtesyCarCov',
    },
    tbb: {
        KEY_COVER: 'PMKeyCov',
        BREAKDOWN_COV: 'PMBreakdownCov',
        BREAKDOWN_COV_TERM: 'PMBreakdownLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Value', 'Finest', 'EuroPlus'],
    },
    toy: {
        BREAKDOWN_COV: 'TMBreakdownCov',
        BREAKDOWN_COV_TERM: 'TMBreakdownLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Level1', 'Level2', 'Level3'],
        LEGAL_EXPENSES: 'PMLegalExpensesCov',
        ENHANCED_COURTESY_CAR: 'TMEnhancedCourtesyCarCov',
        DRIVING_ABROAD_COV: 'PMDrivingAbroadCov',
        DRIVING_ABROAD_COV_TERM: 'TMDrivingAbroadDays',
        DRIVING_ABROAD_COV_OPTIONS: ['3', '90'],
        TRAILER_COV: 'PMTrailerCov',
        MOTOR_EXCESS_PROTECTION_COV: 'TMExcessProtectionCov',
        INCREASED_PERSONAL_ACCIDENT_COV: 'TMPersonalAccidentCov',
    },
    lex: {
        BREAKDOWN_COV: 'TMBreakdownCov',
        BREAKDOWN_COV_TERM: 'TMBreakdownLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Level1', 'Level2', 'Level3'],
        LEGAL_EXPENSES: 'PMLegalExpensesCov',
        ENHANCED_COURTESY_CAR: 'TMEnhancedCourtesyCarCov',
        DRIVING_ABROAD_COV: 'PMDrivingAbroadCov',
        DRIVING_ABROAD_COV_TERM: 'TMDrivingAbroadDays',
        DRIVING_ABROAD_COV_OPTIONS: ['3', '180'],
        TRAILER_COV: 'PMTrailerCov',
        MOTOR_EXCESS_PROTECTION_COV: 'TMExcessProtectionCov',
        INCREASED_PERSONAL_ACCIDENT_COV: 'TMPersonalAccidentCov',
    },
    aut: {
        BREAKDOWN_COV: 'TMBreakdownCov',
        BREAKDOWN_COV_TERM: 'TMBreakdownLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Level1', 'Level2', 'Level3'],
        LEGAL_EXPENSES: 'PMLegalExpensesCov',
        ENHANCED_COURTESY_CAR: 'TMEnhancedCourtesyCarCov',
        DRIVING_ABROAD_COV: 'PMDrivingAbroadCov',
        DRIVING_ABROAD_COV_TERM: 'TMDrivingAbroadDays',
        DRIVING_ABROAD_COV_OPTIONS: ['3', '90'],
        TRAILER_COV: 'PMTrailerCov',
        MOTOR_EXCESS_PROTECTION_COV: 'TMExcessProtectionCov',
        INCREASED_PERSONAL_ACCIDENT_COV: 'TMPersonalAccidentCov',
    },
    kin: {
        BREAKDOWN_COV: 'TMBreakdownCov',
        BREAKDOWN_COV_TERM: 'TMBreakdownLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Level1', 'Level2', 'Level3'],
        LEGAL_EXPENSES: 'PMLegalExpensesCov',
        ENHANCED_COURTESY_CAR: 'TMEnhancedCourtesyCarCov',
        DRIVING_ABROAD_COV: 'PMDrivingAbroadCov',
        DRIVING_ABROAD_COV_TERM: 'TMDrivingAbroadDays',
        DRIVING_ABROAD_COV_OPTIONS: ['3', '90'],
        TRAILER_COV: 'PMTrailerCov',
        MOTOR_EXCESS_PROTECTION_COV: 'TMExcessProtectionCov',
        INCREASED_PERSONAL_ACCIDENT_COV: 'TMPersonalAccidentCov',
        TOOLS_IN_TRANSIT_COV: 'PMToolsInTransit',
    },
    kfi: {
        BREAKDOWN_COV: 'TMBreakdownCov',
        BREAKDOWN_COV_TERM: 'TMBreakdownLevel',
        BREAKDOWN_COV_TERM_OPTIONS: ['Level1', 'Level2', 'Level3'],
        LEGAL_EXPENSES: 'PMLegalExpensesCov',
        ENHANCED_COURTESY_CAR: 'TMEnhancedCourtesyCarCov',
        DRIVING_ABROAD_COV: 'PMDrivingAbroadCov',
        DRIVING_ABROAD_COV_TERM: 'TMDrivingAbroadDays',
        DRIVING_ABROAD_COV_OPTIONS: ['3', '90'],
        TRAILER_COV: 'PMTrailerCov',
    }
};

let nextId;

const PmCoverables = function (data, submission) {
    if (data && _.isObject(data)) {
        this.pmDrivers = _.map(data.pmDrivers, (d) => {
            const driver = new Driver(d);
            if (driver.isAccountHolder === true) {
                // Copy data from PH Driver to AccountHolder contact
                driver.copyToAccountHolder(submission.baseData.accountHolder);
            }
            return driver;
        }, this);

        this.pmVehicles = _.map(data.pmVehicles, (v) => {
            return new Vehicle(v);
        }, this);

        this.ncdYearsOptions = data.ncdYearsOptions;
        this.convictionCodes = data.convictionCodes;
    } else {
        this.pmDrivers = [];
        this.pmVehicles = [];
    }
};

PmCoverables.prototype.createDriver = function (person) {
    if (person) {
        person = {
            title_itb: person.title_itb,
            firstName: person.firstName,
            lastName: person.lastName
        };
    }
    const driver = new Driver(person);
    driver.tempID = nextId();

    this.pmDrivers.push(driver);
    return driver;
};

PmCoverables.prototype.removeDriver = function (driver) {
    const idx = _.indexOf(this.pmDrivers, driver);
    if (idx >= 0) {
        this.pmDrivers.splice(idx, 1);
    }
};

PmCoverables.prototype.createVehicle = function () {
    const v = new Vehicle({
        tempId: nextId(),
        marketValue: {},
        voluntaryExcess: 0
    });
    this.pmVehicles.push(v);
    return v;
};

PmCoverables.prototype.removeVehicle = function (vehicle) {
    const idx = _.indexOf(this.pmVehicles, vehicle);
    if (idx >= 0) {
        this.pmVehicles.splice(idx, 1);
    }
};

PmCoverables.prototype.getDriverForPerson = function (person) {
    return _.findWhere(this.pmDrivers, {
        person
    });
};

PmCoverables.prototype.getPHDriver = function () {
    return this.pmDrivers.find((driver) => driver.isPolicyHolder === true);
};

const PmSubmissionDataExtension = function (submission) {
    nextId = submission.nextId;

    const pmData = submission.lobData.personalMotor;
    if (pmData) {
        this.offerings = pmData.offerings || [];
        this.coverables = new PmCoverables(pmData.coverables, submission);
        this.BRAND_COVERAGES = BRAND_COVERAGES;
    }
};

export default PmSubmissionDataExtension;
