import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import './BuildingsList.scss';
import appFeatures from 'effective-features!';

import _ from 'lodash';
import startAddBuildingWizardFlow from '../AddBuildingWizard/flow';

class BuildingsListCtrl {
    constructor($inject, $state, $stateParams, $scope, gatewayParamConfig, ModalService, CPCoverablesService, LoadSaveService, $filter, StateUtils, $q) {
        this.$scope = $scope;
        this.$injector = $inject;
        this.$state = $state;
        this.sharedPageNum = 0;// Initial page num
        this.pageSize = gatewayParamConfig.cpPropertiesPerPage;
        this.ModalService = ModalService;
        this.CPCoverablesService = CPCoverablesService;
        this.LoadSaveService = LoadSaveService;
        this.$filter = $filter;
        this.model = StateUtils.activeDataForState(true).model;
        this.$q = $q;
        this.jobID = this.model.quoteID ? this.model.quoteID.value : this.model.jobID.value;

        this.isUpdateSpreadsheetScenario = false;
        this.spreadsheetProcessorEnabled = appFeatures.spreadsheetprocessor && appFeatures.spreadsheetprocessor.enabled;
        this.spreadsheetProcessorResult = $stateParams.spreadsheetProcessorResult;
        this.showSpreadsheetProcessorWizard = false;
        this.showSpreadsheetWizardStartButton = this.spreadsheetProcessorEnabled && !this.spreadsheetProcessorResult && !this.showSpreadsheetProcessorWizard;

        if (this.spreadsheetProcessorResult) {
            $stateParams.spreadsheetProcessorResult = undefined;
        }

        this.existingLocations = this.model.lobData.commercialProperty.coverables.locations;

        this.allBuildings = _(this.existingLocations.value)
            .map(loc => {
                const address = loc.displayName;
                return loc.buildings.map(building => ({
                    publicID: building.publicID,
                    classCode: building.classCode.code,
                    description: building.description || building.name, // Name is always present at least as autogenerated 'Bulding #N'
                    location: loc,
                    address: address,
                    isNewlyAdded: this.buildingIsNewAddition(building.publicID) || false,

                    // Two params necessary to show menu when open no matter if item is hovered
                    hovered: false,
                    extraMenuOpen: false
                }));
            })
            .flatten()
            .sortBy('publicID') // Assumption is that public id grows
            .sortBy((location)=>{
                return location.isNewlyAdded;
            })
            .reverse()
            .valueOf();

        // Set up initial views
        this.filteredBuildings = this.allBuildings;
        this.shownBuildings = this.filteredBuildings;

        this.updateFilterState = this.updateFilterState.bind(this); // Without bind context is lost when function is passed via '<'
        this.next = this.next.bind(this);
        this.spreadsheetProcessor = this.spreadsheetProcessor.bind(this);
        this.manualAddBuilding = this.manualAddBuilding.bind(this);
    }

    buildingIsNewAddition(buildingPID) {
        if (this.model.newlyAddedBuildingList) {
            return _.contains(this.model.newlyAddedBuildingList, buildingPID);
        }
        return false;
    }

    next(nextTo) {
        const {baseData, quoteID, sessionUUID} = this.model;

        this._removeLocationsWithoutBuildings()
            .then(() => {
                if (_.get(baseData, 'periodStatus.value.code') === 'Quoted') {
                    return this.LoadSaveService.setQuoteToDraft(quoteID.value, sessionUUID.value);
                }
                return Promise.resolve(this.model.value);
            }).then((draftSubmission) => {
                this.model.value = draftSubmission;
            }).then(() => {
                nextTo(this.model);
            });
    }

    _removeLocationsWithoutBuildings() {
        const REMOVE = true;
        const UPDATE = false;

        const getLocations = () => {
            return this.model.lobData.commercialProperty.coverables.locations.value;
        };

        const findLocationsWithoutBuildings = () => {
            return getLocations().filter((loc) => loc.buildings.length === 0);
        };

        const changePrimaryLocation = (location, sessionID) => {
            if (location.isPrimary) { // set any other location as location as primary
                const newPrimaryLocation = getLocations().find((loc) => loc.publicID !== location.publicID);
                newPrimaryLocation.isPrimary = true;

                return this.CPCoverablesService.updateCPLocation(this.jobID, newPrimaryLocation, sessionID).then((updatedLocation) => {
                    updateOrRemoveLocationFromSubmission(updatedLocation, UPDATE);
                });
            }
            return this.$q.resolve();
        };

        const updateOrRemoveLocationFromSubmission = (location, remove) => {
            const locations = this.model.lobData.commercialProperty.coverables.locations.value;
            const locationIndex = locations.findIndex((loc) => {
                return loc.publicID === location.publicID;
            });
            if (remove) {
                locations.splice(locationIndex, 1);
            } else {
                locations.splice(locationIndex, 1, location);
            }
        };

        const removeLocation = (promises, location) => {
            const deferred = this.$q.defer();
            const sessionID = SessionUtil.getSessionUUID(this.model);

            changePrimaryLocation(location, sessionID).then(() => {
                this.CPCoverablesService.removeCPLocation(this.jobID, location, sessionID).then(() => {
                    updateOrRemoveLocationFromSubmission(location, REMOVE);
                    deferred.resolve();
                });
            });

            promises.push(deferred.promise);
            return promises;
        };

        const locationsRemoved = [];
        findLocationsWithoutBuildings().reduce(removeLocation, locationsRemoved);
        return this.$q.all(locationsRemoved);
    }

    manualAddBuilding() {
        startAddBuildingWizardFlow(this.$injector);
    }

    spreadsheetProcessor(isUpdateSpreadsheetScenario) {
        this.isUpdateSpreadsheetScenario = isUpdateSpreadsheetScenario;
        this.showSpreadsheetProcessorWizard = !this.showSpreadsheetProcessorWizard;
        this.showSpreadsheetWizardStartButton = false;
    }

    updateFilterState(queriedData, filterApplicable, query) {
        this.filteredBuildings = queriedData;
        this.query = filterApplicable ? query : '';
        this.reset = !this.reset; // CPUI: This forces pagination to trigger. Weeeeeird. Shouldn't it watch incoming data?
    }

    onPagination(pageData, pageNum) {
        this.shownBuildings = pageData.items;
        this.sharedPageNum = pageNum;
    }

    addBuilding(location) {
        startAddBuildingWizardFlow(this.$injector, {selectedLocation: location});
    }

    showBuilding(buildingId) {
        this.$state.go('cpBuildingWizardViewBuilding', {
            publicID: buildingId
        });
    }

    showLocation(locationId) {
        this.$state.go('cpBuildingWizardViewLocation', {
            publicID: locationId
        });
    }

    _removeBuilding(buildingID, location) {
        _.remove(location.buildings, building => building.publicID === buildingID);
        _.remove(this.allBuildings, building => building.publicID === buildingID);
    }

    deleteBuilding(locationID, buildingID) {
        const locations = this.model.lobData.commercialProperty.coverables.locations.value;
        const sessionID = SessionUtil.getSessionUUID(this.model);

        this.ModalService.showWarning(
            'quoteandbind.cp.views.cp-building-view.Delete Building',
            'quoteandbind.cp.views.cp-building-view.Are you sure you want to delete this building?',
            null,
            this.$filter('translate')('quoteandbind.cp.views.cp-building-view.Yes, please delete'),
            null,
            this.$filter('translate')('quoteandbind.cp.views.cp-building-view.No, Ill keep it')
        ).result
            .then(() => {
                const loc = _.find(locations, location => location.publicID === locationID);
                let promise;
                const removeLocation = loc.buildings.length === 1 && !loc.isPrimary;
                if (removeLocation) {
                    promise = this.CPCoverablesService.removeCPLocation(this.jobID, loc, sessionID);
                } else {
                    promise = this.CPCoverablesService.removeCPBuilding(this.jobID, locationID, buildingID, sessionID);
                }
                this._handleDeleteBuildingState(promise, buildingID, removeLocation, loc, locations);
            });
    }

    _handleDeleteBuildingState(deletePromise, buildingID, removeLocation, location, locations) {
        deletePromise
            .then(() => {
                if (removeLocation) {
                    _.remove(locations, currLocation => currLocation.publicID === location.publicID);
                }
                this._removeBuilding(buildingID, location);
            })
            .then(() => {
                this.reset = !this.reset;
            })
            .then(() => {
                if (locations.every(loc => loc.buildings.length === 0)) {
                    this.$state.go('cpBuildingWizardInitialScreen', {spreadsheetProcessorResult: null});
                }
            })
            .catch(() => this.ModalService.showError('quoteandbind.cp.directives.cp-building-view.Building not removed', 'quoteandbind.cp.views.cp-building-view.Please check for errors and try again'));
    }

    mousein(item) {
        /* eslint class-methods-use-this: 0 */
        item.hovered = true;
    }

    mouseout(item) {
        /* eslint class-methods-use-this: 0 */
        item.hovered = false;
    }

    ellipsisClosed(item) {
        item.extraMenuOpen = false;
        this.$scope.$applyAsync();
    }

    ellipsisOpen(item) {
        item.extraMenuOpen = true;
        this.$scope.$applyAsync();
    }


}
BuildingsListCtrl.$inject = ['$injector', '$state', '$stateParams', '$scope', 'gatewayParamConfig', 'ModalService', 'qnb.cpCoverablesService', 'qnb.LoadSaveService', '$filter', 'StateUtils', '$q'];

export default BuildingsListCtrl;
