import './Wrapper.scss';

import BaseCtrl from 'edge/platform/base/BaseCtrl';
import {wizardParentState} from '../RouteConfig';

const fn = ($scope, $state) => {
    $scope.flowModel = $state.get(wizardParentState).data.flow;

    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
    };

    ctrl.create($scope);

    $scope.$watch('flowModel.currentStep', () => {
        $scope.isConfirmationStep = $scope.flowModel.currentStep.name === 'Confirmation';
    });
};
fn.$inject = ['$scope', '$state'];

export default fn;
