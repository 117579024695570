import _ from 'underscore';
import sidebarTemplate from 'text!./module.html';
import 'edge/platform/facebook/module';
import 'portalConfig';
import appFeatures from 'app-features';

import './module.scss';

const mediaSidebarConfig = appFeatures.mediasidebar;

/**
 * Selector specifiying where inside sidebarTemplate should the media modules be appended
 * @type {string}
 */
const CONTAINER_SELECTOR = '.gw-sidebar-content';

/**
 * Provider name for the configuration
 * @type {string}
 */
const CONFIGURATION_PROVIDER = 'MEDIA_SIDEBAR_CONFIGURATION';


const socialMediaModule = angular.module('edge.platform.widgets.mediasidebar', [
    'portal.config',
    'edge.platform.facebook'
]);

/**
 * Known media modules. Should match names under ./directives
 * @type {string[]}
 */
const knownModules = ['Twitter', 'Youtube', 'Contact', 'Facebook', 'Rss', 'Text'];
knownModules.forEach((module) => {
    if (mediaSidebarConfig[module] && mediaSidebarConfig[module].enabled === 'true') {
        socialMediaModule.directive(
            `gwMedia${module}`,
            /**
             INLINE DYNAMIC REQUIRE
             */
            /*eslint-disable */
            // Webpack doesn't work well with template strings inside of require()
            require('./directives/' + module + 'Directive/' + module + 'Directive.js').default
            /* eslint-enable */
        );
    }
});

socialMediaModule.directive('gwMediaSidebar', ['$injector', '$compile', 'BaseUtils', ($injector, $compile, BaseUtils) => {
    let configuration = [];
    if ($injector.has(CONFIGURATION_PROVIDER)) {
        // Only load the configuration in portals that provide it to avoid undefined dependency for gwMediaSidebar.
        configuration = $injector.get(CONFIGURATION_PROVIDER);
    }

    // Legit configuration should be an array of {module: <string>, configuration: <object>}
    BaseUtils.assert(Array.isArray(configuration), 'Invalid marketing sidebar configuration, expecting an array of modules');
    configuration.forEach((module) => {
        BaseUtils.assert(typeof module.configuration === 'object', `Configuration required for module ${module}`);
        BaseUtils.assert(
            knownModules.indexOf(module.module) > -1,
            `Unknown module ${module.module}, available: ${knownModules.join(', ')}`
        );
    });

    const baseDirectiveDefinition = {
        restrict: 'E',
        scope: {}
    };

    if (!configuration.length || mediaSidebarConfig.enabled === 'false') {
        // No configuration, no sidebar
        return _.extend(baseDirectiveDefinition, {
            link: ($scope, $element) => {
                const node = $element[0];
                node.parentNode.removeChild(node); // Remove the whole node
            }
        });
    }

    // Actual directive
    return _.extend(baseDirectiveDefinition, {
        link: ($scope, $element) => {
            const sidebarMarkup = $compile(sidebarTemplate)($scope);
            $element.append(sidebarMarkup);

            const container = angular.element(sidebarMarkup).find(CONTAINER_SELECTOR);
            configuration.forEach((config) => {
                if (!_.isEmpty(config.configuration) && mediaSidebarConfig[config.module] && mediaSidebarConfig[config.module].enabled === 'true') {
                    const directive = $compile(`<gw-media-${config.module} />`);
                    const markup = directive(angular.extend($scope.$new(true), config.configuration));
                    container.append(markup);
                }
            });
        }
    });
}]);

export default socialMediaModule;