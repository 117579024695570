import templateStr from 'text!./cp-building-input-directive.html';
import _ from 'lodash';
import PropertyCodeUtil from 'edge/quoteandbind/common/util/PropertyCodeUtil';
import PropertyCodeViewDirective from 'edge/platform/widgets/typeahead/matchContentDirectives/PropertyCode/PropertyCode';
import ViewModelUtil from 'gw-portals-viewmodel-js/ViewModelUtil';

export default [function () {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            buildingView: '=',
            classCode: '=',
            address: '=',
            jobId: '@',
            sessionId: '@',
            readonly: '='
        },
        controller: ['$scope', 'qnb.PropertyCodeLookupService', 'ModalService', ($scope, PropertyCodeLookupService, ModalService) => {

            const lookupCodesPromise = PropertyCodeLookupService.getPropertyCodes(
                'CommercialProperty',
                $scope.jobId,
                $scope.classCode || null,
                $scope.address,
                $scope.sessionId
            );

            $scope.classcodeLookup = {
                getMatches: function (userinput) {
                    return lookupCodesPromise
                        .then(propertyCodes => _(propertyCodes)
                            .filter(code => PropertyCodeUtil.toString(code).toLowerCase()
                                .includes((userinput || '').toLowerCase()))
                            .take(10)
                            .valueOf()).catch(() => ModalService.showError('quoteandbind.cp.controllers.CPBuildingCtrl.There was an error retrieving property class code', 'quoteandbind.cp.controllers.CPBuildingCtrl.Please check for errors and try again'));
                },
                toString: code => {
                    if (ViewModelUtil.isViewModelNode(code)) {
                        return code.value;
                    }

                    return PropertyCodeUtil.toString(code);
                },
                directive: PropertyCodeViewDirective
            };

            const anAlarmTypeIsDefined = !!$scope.buildingView.alarmType;

            $scope.hasAlarm = { // A fake viewmodel node to render a yes-no group
                value: anAlarmTypeIsDefined,
                aspects: {
                    required: false,
                    inputCtrlType: 'boolean',
                    get valid() {
                        return true;
                    }
                }
            };
        }]
    };
}];
