import cssUtil from 'gw-portals-util-js/CssUtil';
import templateStr from 'text!./pm-vehicle-lookup.html';
import styles from './PmVehicleLookup-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(templateStr, styles);
const THROTTLE_TIME = 400;

export default [function () {
    return {
        restrict: 'E',
        template: hashedTemplate,
        scope: {
            vehicle: '='
        },
        controller: ['$scope', '$filter', '$rootScope', 'VehicleLookupService_AND', 'ViewModelService', 'brandingData', function ($scope, $filter, $rootScope, VehicleLookupService_AND, ViewModelService, brandingData) {
            // Keep both VMs in sync to trigger accurate validation
            $scope.manualVehicleToolTip = false
            $scope.syncRegSearchVM = () => {
                regSearchVM.value = $scope.regSearchVM.value;
            };

            $scope.isLookupEnabled = () => ($scope.inFlight !== true) && previousReg !== $scope.regSearchVM.value &&
                $scope.regSearchVM.aspects.valid;

            $scope.goToManualMode = () => {
                $scope.manualMode = true;
                $scope.vehicle.registrationNumber = null;
                $scope.regSearchVM.value = null;
                $scope.syncRegSearchVM();
            };

            $scope.goToAutoMode = () => {
                $scope.manualMode = false;
                previousReg = null;
                resetValuesOnVehicle();
            };
            $scope.showVehicleLookUpTooltip = () => {
                if ($scope.manualVehicleToolTip === null || $scope.manualVehicleToolTip === false){
                    $scope.manualVehicleToolTip = true
                } else {
                    $scope.manualVehicleToolTip = false
                }
                
            }
            $scope.searchVehicle = _.throttle(() => {
                $scope.errorMsg = null;
                previousReg = null;
                $scope.inFlight = true;
                resetValuesOnVehicle();
                if ($scope.regSearchVM.aspects.valid) {
                    if($rootScope.productCode === 'CommercialVehicle') {
                        VehicleLookupService_AND.lookupLCVVehicleByRegNumber($scope.regSearchVM.value)
                            .then((vehicleInfo) => {
                                if (vehicleInfo && vehicleInfo.make && vehicleInfo.model && vehicleInfo.firstRegisteredYear) {
                                    previousReg = $scope.regSearchVM.value;
                                    autofillVehicleFromLookup(vehicleInfo);
                                } else {
                                    throw new Error('Reg Lookup data not valid');
                                }
                            })
                            .catch(() => {
                                $scope.errorMsg = $filter('translate')('and.quoteandbind.pm.directives.templates.pm-vehicle.lookup.We could not find this car. You must either provide your registration number or enter your car details manually.' + brandingData);
                            })
                            .finally(() => {
                                $scope.inFlight = false;
                            });
                    } else {
                        VehicleLookupService_AND.lookupVehicleByRegNumber($scope.regSearchVM.value)
                            .then((vehicleInfo) => {
                                if (vehicleInfo && vehicleInfo.make && vehicleInfo.model && vehicleInfo.firstRegisteredYear) {
                                    previousReg = $scope.regSearchVM.value;
                                    autofillVehicleFromLookup(vehicleInfo);
                                } else {
                                    throw new Error('Reg Lookup data not valid');
                                }
                            })
                            .catch(() => {
                                $scope.errorMsg = $filter('translate')('and.quoteandbind.pm.directives.templates.pm-vehicle.lookup.We could not find this car. You must either provide your registration number or enter your car details manually.' + brandingData);
                            })
                            .finally(() => {
                                $scope.inFlight = false;
                            });
                    }
                }

            }, THROTTLE_TIME);

            let previousReg = $scope.vehicle.registrationNumber.value;
            // Keep track of what data reg lookup sets to clear later if needed.
            let regLookupKeys = ['description', 'engineCapacity', 'firstRegisteredMonth',
                'firstRegisteredYear', 'model', 'colour', 'fuelType', 'transmission', 'imported', 'abiCode',
                'numberOfDoors', 'make', 'bodyType', 'vin'];

            const resetValuesOnVehicle = () => {
                regLookupKeys.forEach((key) => {
                    $scope.vehicle[key] = undefined;
                });
            };
            const autofillVehicleFromLookup = (dto) => {
                regLookupKeys = Object.keys(dto); // Cache what data the lookup set
                Object.assign($scope.vehicle.value, dto); // Copy all values to the PM Vehicle
                $scope.vehicle.registrationNumber.value = $scope.regSearchVM.value;
            };

            // We need the normal reg validation and additionally check if it's not null.
            const regSearchVM = ViewModelService.create({
                registrationNumber: $scope.vehicle.registrationNumber.value
            }, 'pc', 'itb.edge.capabilities.policyjob.lob.personalmotor.coverables.dto.PmVehicleDTO').registrationNumber;
            $scope.regSearchVM = {
                value: $scope.vehicle.registrationNumber.value,
                aspects: {
                    inputCtrlType: 'text',
                    required: true,
                    get valid() {
                        return !!$scope.regSearchVM.value && regSearchVM.aspects.valid;
                    },
                    get validationMessages() {
                        if (!!$scope.regSearchVM.value !== true) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        } else if (regSearchVM.aspects.valid !== true) {
                            return regSearchVM.aspects.validationMessages;
                        }
                        return [];
                    }
                }
            };
            if ($scope.vehicle.regNumUnknown.value === true) {
                $scope.goToManualMode();
            }
        }]
    };
}];