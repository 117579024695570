import cssUtil from 'gw-portals-util-js/CssUtil';

import templateStr from 'text!./stepper.html';
import styles from 'edge/platform/widgets/inputs/stepper/Stepper-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(templateStr, styles);

/** Base stepper directive. This one is responsible for displaying a current value and for displaying
 * "previous" and "next" buttons.
 */

export default [() => {
    return {
        restrict: 'A',
        template: hashedTemplate,

        scope: {
            'displayValue': '=',
            'havePrevValue': '=',
            'haveNextValue': '=',
            'selectPrevValue': '&',
            'selectNextValue': '&'
        }
    };
}];