import CovTerm from './CovTerm';
import _ from 'underscore';
/** Coverage model. */

export default {
    /**
     * Creates a new coverage with the underlying coverage model. Return a new coverage with the following
     * functions and properties:
     *
     * <dl>
     *     <dt>name : String</dt><dd>Name of the coverage term</dd>
     *     <dt>amount : Currency</dt><dd>Coverage amount</dd>
     *     <dt>required : Boolean</dt><dd>Flag indicating that coverage is mandatory.</dd>
     *     <dt>updating : () => Boolean</dt><dd>Flag set when this coverage is updating.</dd>
     *     <dt>terms : CovTerm[]</dt><dd>Array of terms for this coverage</dd>
     *     <dt>selected : Boolean</dt><dd>Flag indicating that property is selected.</dd>
     *     <dt>publicId : String</dt><dd>Identifier of the coverage</dd>
     * </dl>
     *
     * @param {Object} $translateFilter
     * @param {Object} model coverage model.
     * @param {Function} loading to retrieve current loading status.
     * @param {Function} [onChange] function to invoke on the term change.
     * @param {Function} [disableDependentCoverage] function check if the term input should be disabled.
     * @param {Map} dependentCoverages Map of dependent coverages that may require a sync on cov change.
     * @returns {Object}
     */
    'create': ($translateFilter, model, loading, onChange, disableDependentCoverage, dependentCoverages) => {
        onChange = onChange || _.noop;

        function onCovChange(term) {
            onChange(res, term);
        }

        const res = {
            'name': model.name,
            'description': model.description,
            'amount': model.amount,
            'required': model.required,
            'offering': model.branch,
            'publicID': model.publicID,
            'terms': model.terms.map(_.partial(CovTerm.create, $translateFilter, _, loading, onCovChange, disableDependentCoverage, dependentCoverages)),
            'hasTermPatterns': model.hasTerms
        };

        Object.defineProperty(res, 'updating', {
            'get': loading || _.constant(false)
        });

        if (res.required) {
            // Configuring a fake setter for angular (avoids nonassign error)
            Object.defineProperty(res, 'selected', {
                'get': () => true, // Always true
                'set': () => {
                } // Ignore
            });
        } else {
            Object.defineProperty(res, 'selected', {
                'get': () => {
                    return model.selected;
                },
                'set': (nv) => {
                    model.selected = nv;
                    model.updated = true;
                    model.terms.forEach((term) => {
                        term.updated = true;
                    });
                    res.selectedChanged = true;
                    onCovChange();
                }
            });
        }

        let selectedChanged = false;
        // Configuring a property to track the status of the selected value
        Object.defineProperty(res, 'selectedChanged', {
            'get': () => selectedChanged,
            'set': (newVal) => {
                selectedChanged = newVal;
            }
        });

        return Object.freeze(res);
    }
};