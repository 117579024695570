export default function () {
    return {
        restrict: 'A',
        require: '^tab',
        link(scope, elm) {
            scope.$watch('headingElement', (heading) => {
                if (heading) {
                    elm.html('');
                    elm.append(heading);
                }
            });
        }
    };
}