import template from 'text!./templates/Date.html';

export default [() => ({
    restrict: 'E',
    scope: {
        item: '&'
    },
    template: template,
    link: $scope => $scope.$watch('item()', item => {
        $scope.fakeVM = {
            set value(val) {
                item.setValue(val);
            },

            get value() {
                return item.getValue();
            }
        };
    })
})];