import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import 'conditional?if=quoteandbind_lob&equals=pa!edge/quoteandbind/pa/app';
import 'conditional?if=quoteandbind_lob&equals=ho!edge/quoteandbind/ho/app';
import 'conditional?if=quoteandbind_lob&equals=cp!edge/quoteandbind/cp/app';
import 'conditional?if=quoteandbind_lob&equals=bop!edge/quoteandbind/bop/app';
import 'conditional?if=quoteandbind_lob&equals=pm!edge/quoteandbind/pm/app'; // ANDIE Add Personal Motor LOB

const lobModules = [];

if (!ApplicationFeatures.hasFeature('role.vendor')) {
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.pa')) {
        lobModules.push('edge.quoteandbind.pa.app');
    }
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.ho')) {
        lobModules.push('edge.quoteandbind.ho.app');
    }
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.cp')) {
        lobModules.push('edge.quoteandbind.cp.app');
    }
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.bop')) {
        lobModules.push('edge.quoteandbind.bop.app');
    }
    // ANDIE Add Personal Motor LOB
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.pm')) {
        lobModules.push('edge.quoteandbind.pm.app');
    }
}

export default lobModules;