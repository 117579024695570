import templateStr from 'text!../templates/youtube.html';

export default ['$sce', $sce => {
    return {
        restrict: 'AE',
        scope: {
            videoId: '@'
        },
        replace: true,
        template: templateStr,
        link: scope => {
            scope.$watch('videoId', videoId => {
                if (videoId) {
                    scope.url = $sce.trustAsResourceUrl(`https://www.youtube.com/embed/${videoId}`);
                }
            });
        }
    };
}];
