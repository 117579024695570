export default function controller() {
    const $ctrl = this;
    $ctrl.isHelpVisible = isHelpVisible;
    $ctrl.toggleHelp = toggleHelp;
    $ctrl.showInfoIcon = showInfoIcon;
    $ctrl.isRightEdgeCell = isRightEdgeCell;
    $ctrl.isBottomEdgeCell = isBottomEdgeCell;

    /**
     * Toggles the help text for a particular row.
     * @param {Number} index - Index of the row associated with the toggle action
     * @param {boolean} descriptionRow - Boolean to determine whether the change came from description or a data row
     */
    function toggleHelp(index, descriptionRow) {
        if (descriptionRow) {
            $ctrl.coverageTable[index].isVisible = !$ctrl.coverageTable[index].isVisible;
        } else {
            $ctrl.coverageTable[index - 1].isVisible = !$ctrl.coverageTable[index - 1].isVisible;
        }
    }

    /**
     * Determine whether the help text for a row is visible
     * @param {Number} index - index of the current row
     * @param {boolean} descriptionRow - Boolean to determine whether the current row is a description or a data row
     * @returns {boolean}
     */
    function isHelpVisible(index, descriptionRow) {
        if (descriptionRow) {
            return !!$ctrl.coverageTable[index].isVisible;
        }
        return !!$ctrl.coverageTable[index - 1].isVisible;
    }

    function isRightEdgeCell(row, col) {
        return col === $ctrl.coverageTable[row].length - 1;
    }

    function isBottomEdgeCell(row) {
        return row === $ctrl.coverageTable.length - 1;
    }

    /**
     * Determine whether the info icon for a row should be visible
     * @param {Number} index - index of the current row
     * @param {boolean} descriptionRow - Boolean to determine whether the current row is a description or a data row
     * @returns {boolean}
     */
    function showInfoIcon(index, descriptionRow) {
        const isDescriptionRowAndHelpActive = descriptionRow && $ctrl.isHelpVisible(index, descriptionRow);
        const notDescriptionRowAndHelpNotActive = !descriptionRow && !$ctrl.isHelpVisible(index, descriptionRow);
        return isDescriptionRowAndHelpActive || notDescriptionRowAndHelpNotActive;
    }
}