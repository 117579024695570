import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./view-location-building-list-directive.html';
import styles from './ViewLocationBuildingList-hashed.scss';
import startAddBuildingWizardFlow from '../../AddBuildingWizard/flow';

export default [() => ({
    restrict: 'E',
    scope: {
        location: '='
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', '$injector', ($scope, $injector) => {
        $scope.sortType = 'description';
        $scope.sortReverse = false;

        $scope.$watch('location', (value) => {
            $scope.buildingList = value.buildings;
        });

        $scope.addBuilding = () => {
            startAddBuildingWizardFlow($injector, {selectedLocation: $scope.location});
        };
    }]
})];
