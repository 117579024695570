export default [() => {

    const ACTION_MSG = {
        saveAndQuoteSubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to generate a quote',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to calculate a quote.'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Calculating quote...'
            }
        }
    };

    function quoteFailMessage() {
        return ACTION_MSG.saveAndQuoteSubmission;
    }

    return {
        quoteFailMessage: (resp) => {
            return quoteFailMessage(resp);
        }
    };
}];