import templateStr from 'text!./StepperInput.html';
import styles from 'edge/platform/widgets/inputs/stepper/Stepper-hashed.scss';
import cssUtil from 'gw-portals-util-js/CssUtil';

export default [() => {
    return {
        restrict: 'E',
        template: cssUtil.hashTemplate(templateStr, styles),

        scope: {
            'model': '=',
            'minimumValue': '=',
            'maximumValue': '=',
        },
        controller: ['$scope', ($scope) => {

            $scope.canSelectPrevious = () => {
                return $scope.model.value > $scope.minimumValue;
            };
            $scope.canSelectNext = () => {
                return $scope.model.value < $scope.maximumValue;
            };

            $scope.selectPrevValue = () => {
                if ($scope.canSelectPrevious()) {
                    $scope.model.value--;
                }
            };
            $scope.selectNextValue = () => {
                if ($scope.canSelectNext()) {
                    $scope.model.value++;
                }
            };

        }]
    };
}];