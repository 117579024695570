/* The step Covers Contact Details and Terms and conditions for Legal Payments*/
class LegalPaymentStep1Ctrl {
    constructor($scope, $filter, StateUtils) {
        const submissionVm = StateUtils.activeDataForState(true).model;
        $scope.bindData = submissionVm.bindData;
        $scope.baseData = submissionVm.baseData;
        $scope.submission = submissionVm;
        // Trigger validation of fields post-quote
        const selectedQuote = submissionVm.quoteData.offeredQuotes.value.find((quote) => quote.isCustom === true);
        StateUtils.activeDataForState(true).chosenQuote = selectedQuote.publicID;
        $scope.bindData.chosenQuote = selectedQuote.publicID;
        $scope.bindData.communicationPreference_itb = submissionVm.baseData.accountHolder.communicationPreference_itb;

        $scope.totalSteps = (submissionVm.value.isAnnualPaymentPlan_AND() === true) ? 3 : 4;
        $scope.vehicleRegData = {
            vehicleVM: submissionVm.lobData.personalMotor.coverables.pmVehicles.children[0],
            changeConfirmationCallback: undefined
        };
        $scope.vehicleRegData.hasReg = !!$scope.vehicleRegData.vehicleVM.registrationNumber.value;
        $scope.vehicleRegData.regMatched = $scope.vehicleRegData.hasReg;
        if (!$scope.vehicleRegData.vehicleVM.registrationNumber.value) {
            $scope.vehicleRegData.changeConfirmationVM = {
                value: undefined,
                aspects: {
                    required: true,
                    get valid() {
                        return $scope.vehicleRegData.changeConfirmationVM.value === true;
                    },
                    get validationMessages() {
                        if ($scope.vehicleRegData.changeConfirmationVM.value !== true) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };
        }

        /**
         * Invalid if:
         * #1. Vehicle does not have a reg coming in; and
         * #2. New registration number hasn't matched existing Vehicle details; and
         * #3. Vehicle change hasn't been confirmed by the customer.
         *
         * Any other scenarios mean the vehicle has a reg, went through a lookup and accepted any necessary changes.
         *
         * @returns {boolean}
         */
        const isRegContextInvalid = () => {
            return ($scope.vehicleRegData.hasReg !== true && $scope.vehicleRegData.regMatched !== true &&
                $scope.vehicleRegData.changeConfirmationVM.value !== true);
        };

        $scope.next = (callback) => {
            $scope.form.submitted = true;
            if ($scope.form.valid === false || isRegContextInvalid()) {
                callback();
                return;
            } else if (!!$scope.vehicleRegData.changeConfirmationVM && $scope.vehicleRegData.changeConfirmationVM.value === true) {
                if ($scope.vehicleRegData.changeConfirmationCallback) {
                    $scope.vehicleRegData.changeConfirmationCallback();
                }
                const data = StateUtils.activeDataForState(true);
                data.flowModel.jumpToStep('pmVehicleDetails', data.flowModel.snapshot, {changeConfirmationCallback: $scope.vehicleRegData.changeConfirmationCallback});
                return;
            }

            callback(submissionVm);
        };
    }
}

LegalPaymentStep1Ctrl.inject = ['$scope', '$filter', 'StateUtils'];

export default LegalPaymentStep1Ctrl;