import _SegmentationUtil from './../_SegmentationUtil';

export default [() => {

    return {
        restrict: 'EA',
        scope: {
            params: '=?', // {Object} optional parmeters that can be passed to experiment service
            experiment: '@' // if the value is provided the option is triggered to be shown
        },
        controller: ['$element', '$scope', 'gwAbExperimentService', function ($element, $scope, AbExperimentService) {
            const experimentResult = AbExperimentService.getExperimentValue($scope.experiment, $scope.params);
            return _SegmentationUtil($element, experimentResult);
        }]
    };
}];