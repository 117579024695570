import template from 'text!./templates/date-dropdown_AND.html';
import moment from 'moment';

const DEFAULT_MIN_DATE = moment().year(moment().year() - 100); // 100 years ago
const DEFAULT_MAX_DATE = moment(); // today

/**
 * @param {int} min year
 * @param {int} max year
 * @returns {Array} year values >= min and <= max
 */
const getYears = (min, max) => {
    const years = [];
    for (let i = max; i >= min; i--) {
        years.push(i);
    }
    return years;
};

/**
 * @param {moment} date
 * @param {moment} min
 * @param {moment} max
 * @returns {boolean} True is <code>date</code> is between <code>min</code> and <code>max</code>.
 */
const isValidDate = (date, min, max) => {
    return date.isBetween(min, max, 'month', '[]');
};

export default [() => ({
    restrict: 'AE',
    template: template,
    scope: {
        monthVM: '=monthVm',
        yearVM: '=yearVm',
        label: '@',
        minDate: '<?',
        maxDate: '<?',
        customID: '@?customId',
        readonly: '<?'
    },
    controller: ['$scope', ($scope) => {
        let minDate;
        let maxDate;

        const init = () => {
            minDate = $scope.minDate ? moment($scope.minDate) : DEFAULT_MIN_DATE;
            maxDate = $scope.maxDate ? moment($scope.maxDate) : DEFAULT_MAX_DATE;

            // Split models
            if ($scope.monthVM) {
                $scope.listOfMonths = $scope.monthVM.aspects.availableValues;
                $scope.months = $scope.listOfMonths;
            }
            if ($scope.yearVM) {
                $scope.listOfYears = getYears(minDate.year(), maxDate.year());
                $scope.years = $scope.listOfYears;
            }
        };
        init();

        const reValidate = () => { // When valid date ranges change
            init();
            const previousValue = $scope.dateVM.displayName;
            $scope.validateMonth($scope.monthVM.value);
            $scope.validateYear($scope.yearVM.value);
            // If one of the values had to be cleared, clear them all
            if (previousValue !== $scope.dateVM.displayName) {
                $scope.monthVM.value = undefined;
                $scope.yearVM.value = undefined;
            }
        };

        $scope.validateYear = (newValue) => {
            if (newValue) { // Filter list of valid months on year selection
                $scope.months = $scope.listOfMonths.filter((month, index) =>
                    isValidDate(moment({
                        year: $scope.yearVM.value,
                        month: index
                    }), minDate, maxDate));
                if ($scope.months.includes($scope.monthVM.value) === false) { // Clear month selection if now invalid
                    $scope.monthVM.value = undefined;
                }
            } else { // If undefined, reset to full list
                $scope.months = $scope.listOfMonths;
            }
        };

        $scope.validateMonth = (newValue) => {
            if (newValue) { // Filter list of valid years on month selection
                $scope.years = $scope.listOfYears.filter((year) =>
                    isValidDate(moment({
                        year,
                        month: $scope.listOfMonths.indexOf($scope.monthVM.value)
                    }), minDate, maxDate));
                if ($scope.years.includes($scope.yearVM.value) === false) { // Clear year selection if now invalid
                    $scope.yearVM.value = undefined;
                }
            } else { // If undefined, reset to full list
                $scope.years = $scope.listOfYears;
            }
        };

        $scope.$watch(() => $scope.monthVM.value, $scope.validateMonth);
        $scope.$watch(() => $scope.yearVM.value, $scope.validateYear);
        $scope.$watch(() => $scope.minDate, reValidate);
        $scope.$watch(() => $scope.maxDate, reValidate);

        // Fake view model to represent a single data field (since there are multiple date fields for one question)
        $scope.dateVM = {
            value: undefined,
            aspects: $scope.yearVM.aspects,
            get displayName() {
                if ($scope.monthVM.value) {
                    return $scope.yearVM.value + $scope.monthVM.value.code;
                }
                return $scope.yearVM.value;
            }
        };
        Object.defineProperty($scope.dateVM, 'valid', {
            get: function () {
                return $scope.yearVM.aspects.valid && $scope.monthVM.aspects.valid;
            }
        });
        Object.defineProperty($scope.dateVM, 'validationMessages', {
            get: function () {
                return $scope.yearVM.aspects.validationMessages.concat($scope.monthVM.aspects.validationMessages);
            }
        });
    }]
})];