import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

export default () => ({
    restrict: 'A',
    scope: {
        category: '@'
    },
    transclude: true,
    link: (scope, element, attrs, ctrl, transcludeFn) => {
        const enabled = ApplicationFeatures.hasFeature(scope.category);
        if (enabled) {
            const content = transcludeFn();
            element.append(content);
        }
    }
});
