import Coverage from 'edge/policycommon/common/models/Coverage';
import _ from 'lodash';

function LobCoveragesTransformationHelper($filter, offeringUtilFunctions) {
    const model = this;
    this.transformCoverageArray = _transformCoverageArray;
    this.onChange = _onChange;
    this.isLoading = _isLoading;
    this.createCoverage = createCoverage;
    this.offeringUtilFunctions = offeringUtilFunctions;

    /**
     * Transforms an Object (where each key-value pair is an array of coverages) into a 2D array of Coverage models
     * where each odd row is description of the next coverage.
     * @param {Array} coverageArray - Object where the keys are a coverage ID and the values are an array of the coverages
     * @returns {Array}
     * @private
     */
    function _transformCoverageArray(coverageArray) {
        return _(coverageArray).map(_createCoverageRow).flatten().value();
    }

    /**
     * Converts a coverage object into a Coverage Model
     * @param {Object} cell
     * @returns {*}
     * @private
     */
    function _createCoverageCell(cell) {
        if (!cell.terms) {
            return cell;
        }
        return createCoverage(cell);
    }

    /**
     * Transforms an array of coverages into an an array of arrays where the first element is the description and the
     * second is an array of Coverage models
     * @param {Array} row - row of coverage objects
     * @returns {[{description: String},[Coverage]]}
     * @private
     */
    function _createCoverageRow(row) {
        return [{description: row[0].description}, row.map(_createCoverageCell)];
    }


    /**
     * Gets a cached loading function from the parent directive
     * @param {string} branchCode
     * @returns {*|(function())}
     * @private
     */
    function _isLoading(branchCode) {
        return offeringUtilFunctions.getIsLoadingFunc(branchCode);
    }

    /**
     * Creates a callback function for when an offerings coverage updates
     * @param {string} offeringCode - the name of the offering
     * @returns {function()} - callback function to be invoked on a coverage change
     * @private
     */
    function _onChange(offeringCode) {
        return () => {
            const customQuote = offeringUtilFunctions.buildCustomQuote(offeringCode);
            offeringUtilFunctions.syncCoverages(customQuote, () => model.updateModels());
        };
    }

    function createCoverage(cell) {
        return Coverage.create($filter('translate'), cell, _isLoading(cell.branch), _onChange(cell.branch));
    }

}

/**
 * Implemented by LOB specific implementation
 */
LobCoveragesTransformationHelper.prototype.updateModels = function () {
};

/**
 * Implemented by LOB specific implementation
 */
LobCoveragesTransformationHelper.prototype.getBaseCoverageData = function () {
};

/**
 * Implemented by LOB specific implementation
 */
LobCoveragesTransformationHelper.prototype.getLobCoverageData = function () {
};

/**
 * Implemented by LOB specific implementation
 */
LobCoveragesTransformationHelper.prototype.getSchedulesModel = function () {
};

export default LobCoveragesTransformationHelper;