export default function (step) {
    let _isValidFn;
    const defaultValidationFn = () => true;

    /**
     * Provide a function to validate a step
     *
     * @param {Function} isValidFn - Function to validate the data entered in a step,
     *                              The arguments to this function are model along with extraArgs.
     *                              The function should return a boolean.
     *                              If no function is provided the default behavior is to return true.
     *
     * @returns {step}
     */
    return function (isValidFn) {
        if (arguments.length === 0) {
            // isValid function takes model and extraArgs parameters
            return _isValidFn || defaultValidationFn;
        }
        _isValidFn = isValidFn;
        return step;
    };
}