import _ from 'underscore';
import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./CoveragesList.html';
import styles from './CoveragesList-hashed.scss';

export default [() => ({
    restrict: 'E',
    template: cssUtil.hashTemplate(template, styles),

    scope: {
        coverages: '=',
        onCovChange: '=',
        coveragesLoading: '=',
        dependentCoverages: '='
    },
    controller: ['$scope', ($scope) => {
        $scope.allCoveragesLoaded = true;
        $scope.currentDependentCoverages = [];
        $scope.$watchCollection('coverages', () => {
            const cov = $scope.coverages || [];
            $scope._coverages = _.groupBy(cov, 'coverageCategoryDisplayName');
        });
    }]
})];