/**
 * Decorates the ngFormDirective so that the control group directives
 * get passed a reference to the ngModelCtrl that is used by the input. This is
 * needed to determine if the input is pristine.
 */
import _ from 'underscore';

export default ['$delegate', function ($delegate) {
    const directive = $delegate[0];
    const origControllerCtr = directive.controller;

    directive.controller = ['$scope', '$element', '$attrs', '$injector', function ($scope, $element, $attrs, $injector) {
        const self = this;
        this._inputModelValidationFns = [];
        this._setPristineFns = [];

        function removeListeners(validationListener, setPristineListener) {
            let idx = self._inputModelValidationFns.indexOf(validationListener);
            if (idx >= 0) {
                self._inputModelValidationFns.splice(idx, 1);
            }
            idx = self._setPristineFns.indexOf(setPristineListener);
            if (idx >= 0) {
                self._setPristineFns.splice(idx, 1);
            }
        }

        /**
         * Register a control with a form so that we can mark a form
         * as valid or pristine based on the pristine and validity values
         * of each control.
         *
         * @param {Function} validtyFn : Function to get the validity value of a control
         * @param {Function} setPristineFn : Function to mark a control as pristine
         *
         * @returns {Function}
         */
        this.addModelControl = (validtyFn, setPristineFn) => {
            self._inputModelValidationFns.push(validtyFn);
            self._setPristineFns.push(setPristineFn);
            return _.partial(removeListeners, validtyFn, setPristineFn);
        };

        /**
         * Set all controls registered with a form to pristine
         */
        this.setPristine = () => {
            self._setPristineFns.forEach((setCtrlToPristine) => {
                setCtrlToPristine();
            });
        };


        Object.defineProperty(self, 'valid', {
            get: () => {
                return _.every(self._inputModelValidationFns, (validFn) => {
                    return validFn();
                });
            }
        });
        Object.defineProperty(self, 'invalid', {
            get: () => {
                return !self.valid;
            }
        });
        // call the original controller with the correct DI services passed in
        $injector.invoke(origControllerCtr, this, {
            $scope,
            $element,
            $attrs
        });
    }];
    return $delegate;
}];