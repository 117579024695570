export default {
    $get: ['ModalService', '$filter', '$state', 'state_plugin_config', (ModalService, $filter, $state, statePluginConfig) => {
        const quoteTimeout = () => {
            ModalService.showError(
                $filter('translate')('platform.base.DefaultErrorHandlerService.Session Timeout'),
                $filter('translate')('platform.base.DefaultErrorHandlerService.Sorry, your session has timed out. Please restart your quote.')
            ).result.then(() => {
                $state.transitionTo(statePluginConfig.home);
            });
        };

        return {
            getErrorHandlerForCode: (errorCode) => {
                switch (errorCode) {
                    case -33004:
                        return quoteTimeout;
                    default:
                        return null;
                }
            }
        };
    }]
};