import templateStr from 'text!./templates/local-date-chooser-group.html';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';

class LocalDateChooserWithLabel {
    constructor() {
        this.restrict = 'E';
        this.template = templateStr;
        this.scope = {
            model: '=',
            minDate: '=',
            maxDate: '=',
            initialDate: '=',
            label: '@',
            readonly: '=',
            disabled: '=?',
            tooltip: '=?'
        };
        this.controller = ['$scope', function ($scope) {
            $scope.fixedDateOfBirth = LocalDateUtil.toMidnightDate($scope.model.value);
        }];
    }
}
export default LocalDateChooserWithLabel;
