import _ from 'lodash';
import assert from 'assert';

const escapeQuotes = str => str.replace(/"/g, '\\"');

angular.module('edge.platform.util.injector-transclude-helper', [])
    .directive('gwInjectorTranscludeHelper', () => ({
        restrict: 'E',
        scope: {
            getTranscludedContent: '='
        },
        link: ($scope, $el) => {
            $el.replaceWith($scope.getTranscludedContent());
        }
    }));

/**
 * Creates a directive that shows one other directive based on current scope.
 *
 * All the directives should be created at module-initialization time.
 * So this factory takes in an array of all the availableDirectives that may be used to initialize.
 *
 * directiveSelector should return one of the availableDirectives and it gets rendered.
 * Note directiveSelector should return an object contained in availableDirectives by reference.
 *
 * This factory creates a separate module per injector and returns its name,
 * so that the module may be specified as a dependency for whatever module uses it.
 *
 * There are limitations:
 *  * Every subdirective must have an isolate scope and be restricted to E
 *  * Injector directive scope must contain the scopes of all the subdirectives
 *  * Transcluded content is injected using a helper, processing it is hard
 *
 * @param {string}              name                    Injector directive name
 * @param {object}              [scopeDefinition={}]    Injector directive scope
 * @param {array[]|function[]}  availableDirectives     Definitions of all the directives available under this injector
 * @param {function}            directiveSelector       Method to select one of the subdirectives
 *                                                      out of availableDirectives based on the current scope.
 *                                                      Should be an angular annotated function (either fn.$inject or [deps..., fn]).
 *                                                      $scope is accessible through locals.
 *
 * @param {function}            [initTriggers]          Function to set up triggers that will re-render the injector, other than scope changes.
 *                                                      Should be an angular annotated function (either fn.$inject or [deps..., fn]).
 *                                                      $scope and 'render' callback are accessible through locals.
 *
 * @returns {string}                                    Module name to use as a dependency for your module
 */
export default function (name, scopeDefinition, availableDirectives, directiveSelector, initTriggers) {
    assert(name && typeof name === 'string', 'Directive name should be a string');
    assert(Array.isArray(availableDirectives) && availableDirectives.length > 0, 'Non empty array of directives expected');
    assert(!scopeDefinition || _.isPlainObject(scopeDefinition), 'Scope definition should be a plain object');
    assert(
        typeof directiveSelector === 'function' ||
        (Array.isArray(directiveSelector) && typeof directiveSelector[directiveSelector.length - 1] === 'function'),
        'Directive selector should be a method returning a directive module based on current scope'
    );
    assert(
        !initTriggers || typeof initTriggers === 'function' ||
        (Array.isArray(directiveSelector) && typeof directiveSelector[directiveSelector.length - 1] === 'function'),
        'Triggers initializer should be a function'
    );

    const uniqID = (() => {
        let id = 0;
        return () => String(id++);
    })();

    // Get a name for directive definition that would be persistent between calls
    const names = new Map();

    function getName(definition) {
        if (!availableDirectives.includes(definition)) {
            console.error('Requested following definition is not in availableDirectives for %s:', name, definition);
            throw new Error('Unknown definition');
        }

        assert(availableDirectives.includes(definition), 'Unknown definition');
        if (names.has(definition)) {
            return names.get(definition);
        }

        const directiveName = `${_.kebabCase(name)}${uniqID()}`;
        names.set(definition, directiveName);
        return directiveName;
    }

    const moduleName = `edge.platform.util.injector.${name}`;
    const module = angular.module(moduleName, ['edge.platform.util.injector-transclude-helper']);

    // Define subdirectives
    availableDirectives.forEach(directiveDefinition => {
        const directiveName = _.camelCase(getName(directiveDefinition));
        module.directive(directiveName, directiveDefinition);
    });

    // Create the injector directive
    module.directive(_.camelCase(name), ['$compile', '$injector', ($compile, $injector) => {
        const outerScope = scopeDefinition || {};

        const expectedDirectiveScopes = new Map();
        availableDirectives.forEach(directiveDefinition => {
            // Find out what's the expected scope for the given directive
            const directiveDefinitionObject = $injector.invoke(directiveDefinition);
            if (directiveDefinitionObject.restrict && !directiveDefinitionObject.restrict.includes('E')) {
                // No restriction is OK — default restriction is AE
                console.error('All subdirectives of %s must be restricted to E, following directive does not:', name, directiveDefinition);
                throw new Error('E restriction expected');
            }

            const expectedScope = directiveDefinitionObject.scope;
            if (!_.isPlainObject(expectedScope)) {
                console.error('All subdirectives of %s must have isolate scopes, following directive does not:', name, directiveDefinition);
                throw new Error('Isolate scope expected');
            }
            if (!Object.keys(expectedScope).every(key => outerScope[key] === expectedScope[key])) {
                console.error('%s injector scope definition must contain scope definitions of every subdirective. It does not for the following directive:', name, directiveDefinition);
                throw new Error('Expected injector scope definition to include subdirective scope definition');
            }

            expectedDirectiveScopes.set(directiveDefinition, expectedScope);
        });

        const scopeMapper = key => {
            switch (outerScope[key].charAt(0)) {
                case '&':
                    return `${key}()`;
                default:
                    return key;
            }
        };
        const watchGroup = Object.keys(outerScope).map(scopeMapper); // Watch changes to the outer scope

        return {
            restrict: 'E',
            scope: outerScope,
            transclude: true,
            link: ($scope, $element, $attrs, $ctrl, $transclude) => {
                let currentSubdirectiveScope = $scope.$new();
                let currentSubdirective = null;
                const render = () => {
                    // Call the directive selector to get a definition of a directive to render
                    const directiveDefinition = $injector.invoke(directiveSelector, this, {$scope: $scope});

                    // Get a name for the directive to render
                    const directiveName = getName(directiveDefinition);

                    if (!$injector.has(`${_.camelCase(directiveName)}Directive`)) {
                        console.error('Requested subdirective with following definition has not been loaded for %s:', name, directiveDefinition);
                        throw new Error('Unknown directive given, expecting one out of availableDirectives');
                    }

                    if (currentSubdirective === directiveName) {
                        return; // Don't rerender. Instead, scope will be updated by angular.
                    }

                    // Track what is rendered
                    currentSubdirective = directiveName;

                    // Destroy previous scope
                    currentSubdirectiveScope.$destroy();
                    currentSubdirectiveScope = $scope.$new();

                    // Prepare to hack around the transclusion
                    $transclude((transcludedContent, transcludedScope) => {
                        transcludedScope.__$$originalInjectorScope = currentSubdirectiveScope;
                        currentSubdirectiveScope.__$$getTranscludedContent = () => {
                            let transclusionContent;
                            $transclude(tc => {
                                transclusionContent = tc;
                            });
                            return transclusionContent;
                        };

                        // This directive receives the arguments intended for a subdirective, so those should be passed on properly
                        const expectedDirectiveScope = expectedDirectiveScopes.get(directiveDefinition);
                        const mapKey = key => {
                            switch (expectedDirectiveScope[key].charAt(0)) {
                                case '&':
                                    return `__$$originalInjectorScope.${key}()`;
                                case '@':
                                    const content = outerScope[key].charAt(0) === '&' ? $scope[key]() : $scope[key];
                                    return String(content).replace(/\{/g, '\\{').replace(/}/g, '\\}');
                                default:
                                    return `__$$originalInjectorScope.${key}`;
                            }
                        };
                        const passScopeStr = Object.keys(expectedDirectiveScope).map(key => {
                            const expectedPropName = expectedDirectiveScope[key].length > 1 ? expectedDirectiveScope[key].substr(1) : key;
                            return `${_.kebabCase(expectedPropName)}="${escapeQuotes(mapKey(key))}"`;
                        }).join(' ');

                        // Create the subdirective
                        const directive = $compile(`
                            <${directiveName} ${passScopeStr}>
                                <gw-injector-transclude-helper get-transcluded-content="__$$originalInjectorScope.__$$getTranscludedContent"></gw-injector-transclude-helper>
                            </${directiveName}>
                        `);
                        directive(transcludedScope, markup => $element.empty().append(markup));
                    });
                };

                $scope.$watchGroup(watchGroup, render);
                if (initTriggers) {
                    $injector.invoke(initTriggers, this, {render: render, $scope: $scope});
                }
            }
        };
    }]);

    return moduleName;
}
