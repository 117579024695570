import _ from 'lodash';
import mockUpDefaultData from 'portal/config/mockUpData.json5';

/**
 * Generate skeleton object and apply value to the given path
 * @example
 * const example = stringToObject('baseData.policyAddress.city', 'Dublin');
 * console.log(example) // { baseData: { policyAddress: { city: 'Dublin' } }
 *
 * @param {string} stringPath
 * @param {*} value
 * @returns {object}
 */
function stringToObject(stringPath, value = {}) {
    return stringPath.split('.').reverse().reduce((accumulatedNode, nodePath)=> {
        const node = {};
        if (typeof accumulatedNode === 'string') {
            node[nodePath] = {};
            node[nodePath][accumulatedNode] = value;
        } else {
            node[nodePath] = accumulatedNode;
        }
        return node;
    });
}

/**
 * MockupUtil was designed to control all mock-up information used through Digital applications
 */
class MockUpUtil {

    /**
     * creates a new instance providing the mockup data to be used
     * or uses the default mockup data defined by portal level
     * @param {object} mockUpData
     * @param {string} customMockDataSet
     */
    constructor({mockUpData, customMockDataSet} = {}) {
        this.combineMockData(mockUpData, customMockDataSet);
    }

    /**
     * Combine default mock data with custom mock up data set
     * @param {object} mockUpData
     * @param {string} customMockDataSet
     */
    combineMockData(mockUpData, customMockDataSet) {
        const baseMockData = mockUpData || mockUpDefaultData;
        const customMockData = baseMockData[customMockDataSet] || {};
        this.mockUpData = _.merge({}, baseMockData.default, customMockData);
    }

    /**
     * Compares the mock data against the account holders data to determine if the user has been mocked.
     * @param {object} accountHolder
     * @returns {Boolean}
     */
    isAccountHolderMocked(accountHolder) {
        return accountHolder.firstName === this.getMockData('baseData.accountHolder.firstName') &&
            accountHolder.lastName === this.getMockData('baseData.accountHolder.lastName');
    }

    /**
     * Returns the mocked data existing on mockup data
     * @param {string} mockDataKey
     * @returns {string}
     */
    getMockData(mockDataKey) {
        const mockData = this.mockUpData[mockDataKey];
        return mockData || '';
    }

    /**
     * Returns the mocked object based on mockDataFilter
     * @param {string} mockDataFilter
     * @returns {object}
     */
    getMockDataObject(mockDataFilter) {
        let mockDataObject = stringToObject(mockDataFilter);

        const mockUpItems = Object.entries(this.mockUpData).filter(([key]) => key.includes(mockDataFilter));
        if (!mockUpItems.length) {
            return {};
        }
        _.each(mockUpItems, ([key, value]) => {
            const mockDataItem = stringToObject(key, value);
            mockDataObject = _.merge({}, mockDataObject, mockDataItem);
        });

        return _.get(mockDataObject, mockDataFilter);
    }

    /**
     * Check if given property has mocked value assigned and clean up
     * @param {string} mockDataKey
     * @param {object} property
     * @returns {*}
     */
    cleanUpMockedProperty(mockDataKey, property) {
        const mockData = this.getMockData(mockDataKey);
        if (property === mockData) {
            property = undefined;
        }
        return property;
    }

    /**
     * Check if baseObject properties have mocked values assigned and clean up
     * @param {string} mockDataFilter
     * @param {object} baseObject
     * @param {string[]} properties
     * @returns {*}
     */
    cleanUpMockedProperties(mockDataFilter, baseObject, ...properties) {
        const mockDataItems = this.getMockDataObject(mockDataFilter);
        properties.forEach((property)=>{
            if (!baseObject[property]) {
                return false;
            }

            if (_.isEqual(baseObject[property], mockDataItems[property])) {
                baseObject[property] = undefined;
            }
        });
        return baseObject;
    }

    /**
     * Cleans up the mocked properties which are being displayed in a page with metadata.
     * If no metadata is provided, it will cleanup the properties provided.
     * @param {object} objectScope the scope from which the properties will be removed (usually a controller scope, or its subtree)
     * @param {object} [options]
     * @param {object} [options.uiMetadata] the metadata for the page configuration (optional)
     * @param {string[]} [options.propertiesToCleanUp] a list of paths as described in the UI Metadata
     *                                                 that should be cleaned up **if displayed** on the page
     */
    cleanUpMockedPropertiesUsingMetadata(objectScope, {uiMetadata, propertiesToCleanUp = []} = {}) {
        // if we don't have UI metadata we clean up all the cleanable properties
        const metadataPaths = !uiMetadata ? propertiesToCleanUp : _.values(uiMetadata.fields).map(field => field.path);
        const effectivePropertiesToCleanUp = _.intersection(propertiesToCleanUp, metadataPaths);

        effectivePropertiesToCleanUp.map(path => {
            const [pathPrefix, ...rest] = path.split('.');
            let viewModel = `${pathPrefix}ViewModel`; // e.g 'baseDataViewModel'
            if (!objectScope[viewModel]) {
                viewModel = pathPrefix;
            }

            const pathCore = rest.slice(0, -1);
            const baseObjectRef = [viewModel, ...pathCore, 'value'];
            const baseObject = baseObjectRef.reduce((obj, prop) => obj[prop], objectScope); // e.g. this.baseDataViewModel.policyAddress.value (returns the object)

            const mockDataFilter = [pathPrefix, ...pathCore].join('.');
            const propertyName = rest.slice(-1); // e.g. 'addressLine1'

            return [mockDataFilter, baseObject, propertyName];
        }).forEach(([...args]) => {
            this.cleanUpMockedProperties(...args);
        });
    }
}

export default MockUpUtil;