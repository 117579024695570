import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./SimpleEdit.html';
import styles from './SimpleEdit-hashed.scss';

export default () => ({
    restrict: 'E',
    scope: {
        scheduledItem: '<',
        shouldShowErrors: '<'
    },
    template: cssUtil.hashTemplate(template, styles)
});
