import BaseUtils from 'gw-portals-util-js/BaseUtil';
import StateUtils from './StateUtil';
import AddressUtil from './AddressUtil';
import MediaQueriesUtil from './MediaQueriesUtil';
import TemplateUtil from './TemplateUtil';

export default angular.module('edge.platform.util', [])
// breakpoints should match values defined in values.scss
// all values below are defined in px
    .constant('phonePortraitMediaBreakpoint', 480)
    .constant('phoneLandscapeMediaBreakpoint', 640)
    .constant('tabletPortraitMediaBreakpoint', 768)
    .constant('tabletLandscapeMediaBreakpoint', 1024)
    .constant('desktopSmallMediaBreakpoint', 1200)

    .constant('paymentType', {
        WIRE: 'wire',
        CREDIT_CARD: 'creditcard',
        REDISTRIBUTE: 'redistribute'
    })

    .factory('BaseUtils', BaseUtils)
    .factory('StateUtils', StateUtils)
    .factory('AddressUtil', AddressUtil)

    .service('MediaQueriesUtil', MediaQueriesUtil)
    .service('TemplateUtil', TemplateUtil);
