import BaseCtrl from 'edge/platform/base/BaseCtrl';
import moment from 'moment';
import Submission from 'edge/quoteandbind/common/models/Submission';
import FormUtil from 'edge/platform/modelvalidation/FormUtil';
import WizardUtil from '../util/WizardUtil_AND';

const fn = function (
    $scope, $state, $stateParams, LoadSaveService, ModalService, wizardFlowService,
    qnbCompositeFlowDefn, ViewModelService, viewModelContext, returnStateFn,
    errorStateFn, effectiveDateStateFn, brandingData, $rootScope
) {
    const ctrl = Object.create(BaseCtrl);
    const quoteID = $stateParams.quoteID;
    const parentWizardState = 'baseTransactionWizard';
    let shownWarning = false;
    let submission = null;

    ctrl.init = function () {

        /* Flag used to show/hide Warning dialog*/
        ctrl.showWarning = false;

        const stepToStateMapping = {
            returnHome: model => returnStateFn(model),
            confirmation: (model) => ['qnbconfirmation', {
                quoteSummary: model.value,
                returnStateInfo: returnStateFn(model.value)
            }],
            error: model => errorStateFn(model),
            effectiveDate: model => effectiveDateStateFn(model)
        };
        const flowfactory = wizardFlowService(qnbCompositeFlowDefn, parentWizardState, stepToStateMapping);

        ctrl.wizardSteps = WizardUtil.getWizardStepsReadonly();
        $scope.wizardSteps = ctrl.wizardSteps;

        ctrl.quoteRetrieval = ViewModelService.create({}, 'pc', 'edge.capabilities.quote.submission.dto.QuoteRetrievalDTO', viewModelContext);
        ctrl.quoteRetrieval.quoteID.value = quoteID;
        ctrl.quoteRetrieval.brand_itb.value = brandingData.toUpperCase();

        /* Populates SubmissionDTO and resumes quote flow*/
        ctrl.populateSubmissionData = function (submissionData) {
            const subData = new Submission(submissionData);
            const submissionVM = ViewModelService.create(subData, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', viewModelContext);
            flowfactory.resume(submissionVM, $stateParams);
        };

        /* Retrieves Quote on click of Continue from UI. If Warning is already is shown, it will redirects to quote steps
        * If visiting for the first time, retrieves the quote and checks for past effective date
        * If effective date is in past show Warning Message
        * If effective date is today or in future it redirects to quote step */
        ctrl.retrieveQuote = function () {
            FormUtil.markFormsSubmitted(ctrl.form);
            if (ctrl.quoteRetrieval.aspects.valid && ctrl.quoteRetrieval.aspects.subtreeValid) {
                if (shownWarning && submission) {
                    ctrl.populateSubmissionData(submission);
                } else {
                    const submissionLoadPromise = LoadSaveService.retrieveSubmission(ctrl.quoteRetrieval.value);
                    $scope.submissionLoadPromise = submissionLoadPromise;
                    submissionLoadPromise.then(submissionData => {
                        $rootScope.productCode = submissionData.baseData.productCode;
                        if (submissionData.baseData.periodStatus !== 'Bound' &&
                            moment(submissionData.baseData.periodStartDate).isBefore(moment(), 'day')) {
                            submission = submissionData;
                            ctrl.showWarning = true;
                            shownWarning = true;
                        } else {
                            ctrl.populateSubmissionData(submissionData);
                        }
                    }).catch(() => {
                        ModalService.showError(
                            'quoteandbind.controllers.QuoteEntryCtrl.Error retrieving submission',
                            'and.quoteandbind.controllers.QuoteEntryCtrl.Sorry, but we cant seem to locate a quote with given Quote Reference, Postal code and Date Of Birth. Please try again.'
                        );
                    }).finally(()=> {
                        $scope.submissionLoadPromise = null;
                    });
                }
            }
        };
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams', 'qnb.LoadSaveService', 'ModalService', 'WizardFlowService',
    'qnb.CompositeFlowDefn', 'ViewModelService', 'qnb.ViewModelCtx', 'qnb.returnStateFn',
    'qnb.errorStateFn', 'qnb.effectiveDateStateFn', 'brandingData', '$rootScope'];

export default fn;
