import BaseCtrl from 'edge/platform/base/BaseCtrl';
import slideContent from 'edge/platform/widgets/carousel/config/demoslides.json';

const fn = ($scope, landingPageContent) => {
    const ctrl = Object.create(BaseCtrl);


    ctrl.init = () => {
        $scope.content = landingPageContent;
        $scope.slideContent = slideContent;
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', 'landingPageContent'];

export default fn;