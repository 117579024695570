import './Table.scss';
import directiveTemplate from 'text!./Table.html';
import overlayTemplate from 'text!./Overlay.html';

const getDefaultItemState = () => ({
    removing: false
});

export default [() => ({
    restrict: 'E',
    scope: {
        schedule: '&'
    },
    template: directiveTemplate,
    controller: ['$scope', 'ModalService', ($scope, ModalService) => {
        $scope.$watch('schedule()', schedule => {
            const itemStates = new Map();
            const dropRemoves = () => itemStates.forEach(item => {
                item.removing = false;
            });
            const createDefaultState = item => itemStates.set(item, getDefaultItemState(item));
            schedule.getScheduledItems().forEach(createDefaultState);

            $scope.initRemove = function (item) {
                dropRemoves();
                itemStates.get(item).removing = true;
            };
            $scope.cancelRemoving = function (item) {
                itemStates.get(item).removing = false;
            };
            $scope.isBeingRemoved = function (item) {
                return itemStates.get(item).removing;
            };
            $scope.remove = function (item) {
                schedule.remove(item);
                itemStates.delete(item);
            };

            const _showOverlay = function (item, isNewLocation) {
                dropRemoves();

                item.draft();

                let onBeforeCommit;
                ModalService.showSimpleDialog(overlayTemplate, {
                    schedule: schedule,
                    scheduledItem: item,
                    isNewLocation: isNewLocation,
                    onBeforeCommit: (cb) => {
                        onBeforeCommit = cb;
                    }
                }, {
                    undo: dialogScope => {
                        item.undo();
                        dialogScope.$dismiss();
                    },
                    commit: (dialogScope) => {
                        const beforeCommitValid = typeof onBeforeCommit === 'function' ? onBeforeCommit() : true;
                        if (!item.isValid() || !beforeCommitValid) {
                            dialogScope.shouldShowErrors = true;
                        } else {
                            item.commit();
                            if (isNewLocation) {
                                createDefaultState(item);
                                schedule.add(item);
                            }

                            dialogScope.$dismiss();
                        }
                    }
                }, null, {
                    wide: true,
                    keyboard: false
                });
            };

            $scope.edit = function (item) {
                _showOverlay(item, false);
            };
            $scope.createNew = function () {
                _showOverlay(schedule.createNewItem(), true);
            };
        });
    }]
})];
