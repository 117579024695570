import templateStr from 'text!./templates/yes-no.html';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            model: '=',
            disabled: '=?',
            tooltip: '=?'
        }
    };
}];
