/**
 * Provides campaign data based on the params passed by query string.
 *
 * @returns {Object}
 *
 * {Object}.campaignData {Object} campaing data from URL.
 *      Fields are filled from URL using the corresponding params
 *      taken from A SEPARATE CONFIG
 * [campaignData.utm_source] {String} Required if the campaign is provided
 * [campaignData.utm_medium] {String} Required if the campaign is provided
 * [campaignData.utm_term] {String}
 * [campaignData.utm_content] {String}
 * [campaignData.utm_campaign] {String} Required if the campaign is provided
 *
 *      E.g. %URL%?utm_source=1 gives {utm_source: "1"}
 *      (depending on A SEPARATE CONFIG for param names)
 */

import window from 'edge/platform/base/Window';
import queryString from 'query-string';
import campaignUrlParams from './config/campaignUrlParams.json';

const defaultConfig = {
    urlParams: campaignUrlParams.campaign
};
const config = Object.assign({}, defaultConfig);

export default {
    $get: () => {
        // ---VARS---
        const CAMPAIGN_KEYS = Object.keys(config.urlParams);
        const campaignData = {};

        // ---LOGIC---
        // parse URL search params
        const searchParams = queryString.parse(window.location.search);
        // fill campaign params keys from URL search params
        CAMPAIGN_KEYS.forEach((CAMPAIGN_KEY) => {
            // param names are taken from A SEPARATE CONFIG
            const paramFromUrl = searchParams[config.urlParams[CAMPAIGN_KEY]];

            if (paramFromUrl) {
                campaignData[CAMPAIGN_KEY] = paramFromUrl;
            }
        });

        // ---RETURN---
        return campaignData;
    },

    setUrlParamsConfig: urlParamsConfig => {
        config.urlParams = urlParamsConfig;
    }
};