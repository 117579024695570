import templateStr from 'text!./mobileCoveragesSummary.html';

export default {
    template: templateStr,
    bindings: {
        offering: '<',
        branchCode: '<'
    },
    controller: function controller() {
        const $ctrl = this;
        $ctrl.selectedOffering = $ctrl.offering;
        $ctrl.vehicleCoveragesExist = doVehicleCoveragesExistOnOffering();
        function doVehicleCoveragesExistOnOffering() {
            let result = false;
            $ctrl.selectedOffering.vehicleCoverages.forEach((vehicle) => {
                vehicle.coverages.forEach((coverage) => {
                    if (coverage.selected) {
                        result = true;
                    }
                });
            });
            return result;
        }
    }
};
