import TabsetTemplate from 'text!../templates/tabset-template.html';
/*
 * @ngdoc directive
 * @name ui.bootstrap.tabs.directive:tabset
 * @restrict EA
 *
 * @description
 * Tabset is the outer container for the tabs directive
 *
 * @param {boolean=} vertical Whether or not to use vertical styling for the tabs.
 *
 * @example
 <example module="ui.bootstrap">
 <file name="index.html">
 <tabset>
 <tab heading="Vertical Tab 1"><b>First</b> Content!</tab>
 <tab heading="Vertical Tab 2"><i>Second</i> Content!</tab>
 </tabset>
 <hr />
 <tabset vertical="true">
 <tab heading="Vertical Tab 1"><b>First</b> Vertical Content!</tab>
 <tab heading="Vertical Tab 2"><i>Second</i> Vertical Content!</tab>
 </tabset>
 </file>
 </example>
 */
export default function () {
    return {
        restrict: 'EA',
        transclude: true,
        replace: true,
        scope: {flavor: '@'},
        controller: 'TabsetController',
        template: TabsetTemplate,
        link(scope, element, attrs) {
            scope._flavor = scope.flavor || 'default';
            scope.vertical = angular.isDefined(attrs.vertical) ? scope.$parent.$eval(attrs.vertical) : false;
        }
    };
}