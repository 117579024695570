import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./cp-location-summary-directive.html';
import styles from './LocationSummary-hashed.scss';

export default [() => ({
    restrict: 'E',
    replace: true,
    require: 'gwCpPremisesSummary',
    scope: {
        location: '=',
        edit: '&?',
        change: '&',
        showChooseDifferentLocation: '=',
        flavor: '@',
        buildingId: '@?'
    },
    transclude: true,
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'InsuranceSuiteMetadata', '$state', ($scope, insuranceSuiteMetadata, $state) => {

        const typelist = insuranceSuiteMetadata.get('pc').types.getTypelist('FireProtectClass');
        $scope.location.displayAddress = $scope.location.displayName.split(',');
        $scope.fireProtection = ($scope.location.fireProtection) ? typelist.getCode($scope.location.fireProtection).name : null;

        if (!$scope.edit) {
            $scope.edit = () => {
                $state.go('cpBuildingWizardEditLocation', {publicID: $scope.location.publicID, buildingPublicID: $scope.buildingId});
            };
        }
    }]
})];
