import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./BadgeDirective.html';
import styles from './BadgeDirective-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default [() => ({
    restrict: 'E',
    transclude: true,
    replace: true,
    template: hashedTemplate,
    scope: {
        type: '@',
        flavor: '@'
    },
    controller: ['$scope', ($scope) => {
        $scope.styles = styles;
    }]
})];