/**
 * Base implementation of low-level transport. Each low-level transport should implement following methods:
 * <ul>
 *     <li>send(endpoint : String, headers: {}, method : String, params : *[]) : Promise<Object> - performs a network query. </li>
 * </ul>
 */
import generateGuid from 'uuid/v4';
import q from 'q';
import cookie from 'js-cookie';


export const JSON_CONTENT_TYPE = 'application/json';

export default {

    send: (endpoint, headers, method, params) => {

        const manageStickySessionCookie = () => {
            const STICKY_SESSION_ID = 'JSESSIONID';
            if (!cookie.get(STICKY_SESSION_ID)) {
                cookie.set(STICKY_SESSION_ID, generateGuid());
            }
        };
        const requestCode = generateGuid();
        const deferred = q.defer();
        const baseHeaders = {
            'Accept': JSON_CONTENT_TYPE,
            'Content-Type': JSON_CONTENT_TYPE
        };
        manageStickySessionCookie();
        headers = Object.assign(baseHeaders, headers);
        return fetch(endpoint, {
            method: 'POST',
            headers,
            credentials: 'include',
            body: JSON.stringify({
                'id': `${requestCode}`,
                'jsonrpc': '2.0',
                method,
                params
            })
        })
            .then((res) => {
                const contentType = res.headers.get('content-type');
                if (!res.ok) {
                    // status NOT in the range 200-299
                    if (res.status === 401) {
                        deferred.reject({
                            status: 401
                        });
                        return deferred.promise;
                    }
                    if (contentType && contentType.includes(JSON_CONTENT_TYPE)) {
                        return res.json().then(data => {
                            deferred.reject({
                                id: data.id,
                                error: data.error
                            });
                            return deferred.promise;
                        });
                    }
                    // reject with Error if response does not contain parsable JSON content
                    deferred.reject(new Error(`${res.status} (${res.statusText})`));
                    return deferred.promise;
                }
                return res.json().then(data => {
                    deferred.resolve({
                        id: data.id,
                        result: data.result
                    });
                    return deferred.promise;
                });
            });
    }
};