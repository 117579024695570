import createSubflowsFromDefns from './_createSubflowsFromDefns';
import flowBegining from './_flowBegining';
import wireLOBSection from './_wireLOBSection';

export default ['AvailabilityService', 'qnb.LoadSaveService', '$q', 'TranslateService', 'ViewModelService', 'qnb.ViewModelCtx', 'qnb.ActionMessageUtil', 'EventHub', '$rootScope',
    function (AvailabilityService, LoadSaveService, $q, TranslateService, ViewModelService, ViewModelCtx, ActionMessageUtil, EventHub, $rootScope) {

        function branchOnProductCode(paFlows, hoFlows, cpFlows, bopFlows, pmFlows, flowPropertyKey) {
            // ANDIE These aren't null safe but won't be needed anyway.
            // const paTransition = paFlows[flowPropertyKey] ? paFlows[flowPropertyKey].entry : null;
            // const hoTransition = hoFlows[flowPropertyKey] ? hoFlows[flowPropertyKey].entry : null;
            // const cpTransition = (cpFlows && cpFlows[flowPropertyKey]) ? cpFlows[flowPropertyKey].entry : null;
            // const bopTransition = (bopFlows && bopFlows[flowPropertyKey]) ? bopFlows[flowPropertyKey].entry : null;
            const pmTransition = (pmFlows && pmFlows[flowPropertyKey]) ? pmFlows[flowPropertyKey].entry : null;

            return ({
                value: submission
            }) => {
                // if (submission.baseData.productCode === 'PersonalAuto') {
                //     return paTransition;
                // }
                // if (submission.baseData.productCode === 'Homeowners' || submission.baseData.productCode === 'HOPHomeowners') {
                //     return hoTransition;
                // }
                // if (submission.baseData.productCode === 'CommercialProperty') {
                //     return cpTransition;
                // }
                // if (submission.baseData.productCode === 'BusinessOwners') {
                //     return bopTransition;
                // }
                if (submission.baseData.productCode === 'PersonalMotor') {
                    return pmTransition;
                }
                if (submission.baseData.productCode === 'TraditionalMotor') {
                    return pmTransition;
                }
                if (submission.baseData.productCode === 'CommercialVehicle') {
                    return pmTransition;
                }
                throw new Error('Invalid Product code specified.');
            };
        }

        return function (step, junction, flow) {
            // ANDIE Added rootScope
            const subFlows = createSubflowsFromDefns(flow, AvailabilityService, LoadSaveService, $q, TranslateService, ViewModelService, ViewModelCtx, ActionMessageUtil, EventHub, $rootScope);
            // create complete flow by wiring up sub flows
            flowBegining(subFlows, branchOnProductCode, TranslateService)(step, junction, flow);
            wireLOBSection(subFlows, branchOnProductCode, TranslateService)(step, junction, flow);
        };
    }
];
