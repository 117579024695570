module.exports = {
	"@extends": "../config.json5",
	"endpoints": {
		"build": {
			"pc": {
				"host": "https://pc.prod.gw10.cbp-uk.co.uk",
				"port": "443"
			}
		}
	}
}