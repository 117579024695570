import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './multiselect-hashed.scss';
import templateStr from 'text!edge/platform/widgets/multiselect/components/multiselect/multiselect.html';

export default {
    replace: true,
    template: cssUtil.hashTemplate(templateStr, styles),
    bindings: {
        selectItems: '<',
    }
};