import _ from 'underscore';
/* WARNING: Angular do not have a good propagation control. So this code is somewhat UGLY. Most of it should go
 * into utilities or propagation/execution model. Until then we have what we have.
 */

function toString(obj) {
    return _.isNull(obj) || _.isUndefined(obj) ? '' : obj.toString();
}

function toBoolean(obj) {
    return _.isNull(obj) || _.isUndefined(obj) || obj === 'false';
}

/**
 * Creates an element accessor. Returns undefined if fnOrProp do not define a valid accessor.
 *
 * @param {Function|String|*} fnOrProp
 *
 * @returns {Function|undefined}
 */
function accessor(fnOrProp) {
    if (_.isFunction(fnOrProp)) {
        return fnOrProp;
    }
    if (_.isString(fnOrProp)) {
        return _.property(fnOrProp);
    }

    return undefined;
}


/**
 * Creates a new text component.
 * @param {String} value initial text value.
 * @returns {Object}
 */
function textComponent(value) {
    const res = document.createTextNode(value);
    return {
        'ui': res,
        'set': (newValue) => {
            if (newValue === value) {
                return;
            }
            value = newValue;
            res.textContent = newValue;
        }
    };
}

/**
 * Creates a new placeholder controller.
 *
 * @param {Object} scope
 * @returns {Object}
 */
function placeholderComponent(scope) {
    const res = document.createElement('option');
    res.setAttribute('value', '0');
    res.setAttribute('class', toBoolean(scope.hidePlaceholderOption) ? 'hide-placeholder' : 'show-placeholder');

    const text = textComponent(scope.placeholder || '');
    res.appendChild(text.ui);

    return {
        'ui': res,
        'getValue': () => {
            return undefined;
        },
        'update': () => {
            return text.set(scope.placeholder || '');
        }
    };
}


/**
 * Creates a new option component.
 *
 * @param {Object} scope
 * @param {String} idx
 * @param {Object} ctx
 * @returns {Object}
 */
function optionComponent(scope, idx, ctx) {
    let option = scope.options[idx];
    let value = ctx.optionValue(option);

    const res = document.createElement('option');
    res.setAttribute('value', (idx + 1).toString());

    const text = textComponent(ctx.optionName(option));
    res.appendChild(text.ui);

    return {
        'ui': res,
        'getValue': () => {
            return value;
        },
        'update': (_ctx) => {
            option = scope.options[idx];
            value = _ctx.optionValue(option);
            text.set(_ctx.optionName(option));
        }
    };
}


function createContext(scope) {
    return {
        'optionValue': accessor(scope.optionValue) || _.identity,
        'optionName': accessor(scope.renderBy) || toString
    };
}


function selectComponent(elt, scope) {
    let ctx = createContext(scope);

    const placeholder = placeholderComponent(scope);
    const options = (scope.options || []).map((option, idx) => {
        return optionComponent(scope, idx, ctx);
    });
    options.unshift(placeholder);

    /**
     * Returns an index of the item.
     *
     * @param {*} item
     * @returns {Number}
     */
    function indexOf(item) {
        /*
         * Additional defensive measure of "findIndex", if item is no longer present in the list of options
         * then we return the placeholder.
         * Note the model is the source of truth so the component will not reset the model back to undefined,
         * this is the responsibility of the application
         */
        return Math.max(0, _.findIndex(options, (opt) => {
            return opt.getValue() === item;
        }));
    }

    function syncSelection() {
        const selIndex = indexOf(scope.value);
        const changed = selIndex !== elt.selectedIndex;
        if (changed) {
            elt.selectedIndex = selIndex;
        }
        return changed;
    }

    options.forEach((optComp) => {
        elt.appendChild(optComp.ui);
    });

    return {
        'ui': elt,
        'valueAt': (idx) => {
            return options[idx].getValue();
        },
        syncSelection,
        'update': () => {
            ctx = createContext(scope);
            // model options plus placeholder
            const optionsLength = (scope.options || []).length + 1;

            while (options.length > optionsLength) {
                elt.removeChild(options.pop().ui);
            }
            options.forEach((opt) => {
                opt.update(ctx);
            });
            while (options.length < optionsLength) {
                const newOpt = optionComponent(scope, options.length - 1, ctx);
                options.push(newOpt);
                elt.appendChild(newOpt.ui);
            }

            syncSelection();
        }
    };
}


export default [() => {
    return {
        restrict: 'A',
        scope: {
            /** Model (value) to select. */
            'value': '=gwPlSelect',
            /** List of options to select from. */
            'options': '=',
            /** Function to call to select an element. */
            'selectBy': '=?',
            /** Function or attribute name used to extract value from the option. That value would be set on the
             * model and would be matched with the model value.
             */
            'optionValue': '=?',
            /** Function or attribute name used for the attribute rendering. */
            'renderBy': '=?',
            /** Value to be displayed if nothing was selected by the user. */
            'placeholder': '@',
            /** Flag to hide placeholder from list of available options, may be preferred behavior for required fields */
            'hidePlaceholderOption': '=?',
        },
        link: (scope, elt) => {
            const domElement = elt[0];

            domElement.classList.add('gw-pl-select');
            const ctrl = selectComponent(domElement, scope);

            /** Update ctrl on each digest cycle. Second function is used to fulfill watch signature. */
            scope.$watch(ctrl.update, () => {
            });

            domElement.addEventListener('change', () => {
                const newValue = ctrl.valueAt(domElement.selectedIndex);
                if (scope.selectBy) {
                    scope.selectBy(newValue);
                } else {
                    scope.value = newValue;
                }
                scope.$apply();

                ctrl.syncSelection();
            });
        }
    };
}];