/**
 * Holds the information about the user
 */

// is populated when used is logged in
let _USER_INFO = null;
let _USER_PUBLIC_ID = null;

function setUserInfo(value) {
    _USER_INFO = value;
}

function setUserPublicId(value) {
    _USER_PUBLIC_ID = value;
}


angular.module('edge.platform.userinfo', [])
/*
 constant method is used to get the user info at the any phase of the app
 */
    .constant('USER_INFO', () => {
        if (!_USER_INFO) { // shouldn't be called before the user info is assigned
            throw new Error('User info is not provided');
        }
        return _USER_INFO;
    })

    .constant('USER_PUBLIC_ID', () => {
        if (!_USER_PUBLIC_ID) { // shouldn't be called before the user info is assigned
            throw new Error('User PublicId is not provided');
        }
        return _USER_PUBLIC_ID;
    })

    /*
     Helper service for the easier access to the data
     */
    .factory('UserInfoService', () => {
        if (!_USER_INFO) {
            return {
                setUserPublicId,
                setUserInfo,
                publicId: _USER_PUBLIC_ID,
                isAgent: () => false
            };
        }

        return {
            setUserPublicId,
            setUserInfo,
            publicId: _USER_PUBLIC_ID,
            isAgent: () => _USER_INFO.userType === 'producer'
        };
    });