import template from 'text!./pm-quote-courtesy-car.html';
import controller from '../PmQuoteAddOnCtrl';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '=',
            coverageCode: '<'
        },
        template,
        controller
    };
}];