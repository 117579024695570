import templateStr from 'text!./monthly-plan-representative-ex.html';

export default [() =>{
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            installmentPlanDetails: '<?',
        }
    };
}];