
/**
 * The onRepeatDone directive should be used within ng-repeat and trigger 
 * the method when the ng-repeat is completed
 * 
 * @example
 * <li ng-repeat="item in menuItems" gw-on-repeat-done="$ctrl.callback()">
 *   <a>{{item}}</a>
 * </li>
 */
class OnRepeatDone {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            gwOnRepeatDone: '&gwOnRepeatDone'
        };
        this.link = (scope) => {
            // wait until $parent scope in the ng-repeat be completed
            if (scope.$parent.$last) {
                scope.gwOnRepeatDone();
            }
        };
    } 
    

}

export default OnRepeatDone;