import BaseCtrl from 'edge/platform/base/BaseCtrl';
import flowFactory from 'edge/platform/flow/FlowFactory';
import flowConfig from '../config/FlowConfig';
import _ from 'underscore';

export default [
    '$scope',
    '$filter',
    'guidance.GuidanceService',
    '$q',
    'WizardFlowService',
    'qnb.LoadSaveService',
    'ViewModelService',
    'AvailabilityService',
    'TranslateService',
    'qnb.ViewModelCtx',
    '$stateParams',
    'StateUtils',
    'LocaleService',
    'qnb.ActionMessageUtil',
    'EventHub',

    (
        $scope, $filter, GuidanceService, $q, wizardFlowService, LoadSaveService, ViewModelService, AvailabilityService, TranslateService,
        ViewModelCtx, $stateParams, StateUtils, LocaleService, ActionMessageUtil, EventHub
    ) => {
        const ctrl = Object.create(BaseCtrl);
        const stepIndices = {
            OCCUPATION_PANEL: 0,
            ADDRESS_PANEL: 1,
            QUESTIONS_PANEL: 2,
            RECOMMENDATIONS_PANEL: 3
        };
        const flowDefn = flowConfig($q, GuidanceService, wizardFlowService, LoadSaveService, ViewModelService, AvailabilityService, TranslateService, ViewModelCtx, $stateParams, StateUtils, ActionMessageUtil);
        const flowModelFactory = flowFactory.get($q, EventHub)(flowDefn);
        const flowModel = flowModelFactory.start();
        const stepValidFuncs = flowModel.steps.map((step) => {
            // step - Defined in the FlowConfig
            return {
                isValid: () => {
                    // The isValid() below is the isValid() function from the flowModel
                    const isValidFnOfStep = step.isValid();
                    // Since we want to reuse the isValid() of the step we need to call it with the model.
                    return $scope.model && isValidFnOfStep($scope.model);
                }
            };
        });

        ctrl.init = function () {
            const modelData = {
                userAddress: {}
            };

            $scope.model = ViewModelService.create(modelData, 'pc', 'edge.capabilities.guidance.dto.GuidanceDTO');
            $scope.flow = flowModel;
            $scope.stepValidFuncs = stepValidFuncs;

            Object.defineProperty($scope, 'currentStepIndex', {
                get: () => flowModel.steps.indexOf(flowModel.currentStep)
            });
            $scope.selectedProduct = null;
            ctrl.setDefaultHelpAndLearningText();
        };

        ctrl.jumpFn = function (targetStep) {
            // Block jump to future step unless all the panels are valid.
            const direction = targetStep > $scope.currentStepIndex ? 'forward' : 'back';
            if (direction === 'forward') {
                const previousPanels = $scope.stepValidFuncs.slice(0, targetStep - 1);
                if (_.every(previousPanels, (panel) => panel.isValid && panel.isValid())) {
                    // Recurse through the flow to get to the target step
                    _recurseToState(targetStep, direction);
                }
            } else {
                _recurseToState(targetStep, direction);
            }
        };

        ctrl.getSectionClass = function (stepIdx) {
            if (stepIdx === $scope.currentStepIndex) {
                return 'gw-active-section';
            } else if (stepIdx < $scope.currentStepIndex) {
                return 'gw-inactive-section-left';
            }
            return 'gw-inactive-section-right';
        };

        ctrl.nextPanel = function () {
            const deferred = $q.defer();
            if ($scope.currentStepIndex <= $scope.flow.steps.length - 1) {
                flowModel.next($scope.model, $scope.selectedProduct).then(newModel => {
                    $scope.model = newModel.model;
                    ctrl.setDefaultHelpAndLearningText();
                    deferred.resolve();
                });
            } else {
                deferred.reject();
            }
            return deferred.promise;
        };

        ctrl.previousPanel = function () {
            const thereIsAPanelToSwitchTo = $scope.currentStepIndex > stepIndices.OCCUPATION_PANEL;

            if (thereIsAPanelToSwitchTo) {
                $scope.setDefaultHelpAndLearningText();
                flowModel.previous($scope.model);
            }
        };

        ctrl.isPanelValid = function () {
            return $scope.stepValidFuncs[$scope.currentStepIndex].isValid();
        };

        ctrl.setDefaultHelpAndLearningText = function () {
            $scope.activeHelpText = $filter('translate')('guidance.controllers.guidance-product-controller.Default Help and Learning Text');
        };

        ctrl.displayInfo = function (text) {
            $scope.activeHelpText = text;
        };

        ctrl.getVisibilityClass = function (index) {
            let activeClass = '';
            if ($scope.currentStepIndex < index) {
                activeClass = 'gw-gtp-hidden-section';
            }
            return activeClass;
        };

        ctrl.isSelectedProduct = function (product) {
            return $scope.selectedProduct && $scope.selectedProduct.code.value === product.code.value;
        };

        ctrl.updateSelectedProduct = function (product) {
            $scope.selectedProduct = product;
        };

        ctrl.isProductAvailable = function (selectedProduct) {
            return selectedProduct && selectedProduct.availableForQuote.value;
        };

        ctrl.isProductSelected = function () {
            return $scope.selectedProduct !== null;
        };

        return ctrl.create($scope);

        /**
         * Recurse forward or backward in the flowmodel to get to a step
         * @param {Number} destinationStep - index of the destination step
         * @param {String} direction - the recursion direction - values ('back'|'forward')
         * @private
         */
        function _recurseToState(destinationStep, direction) {
            // Recurse through flow until we get to the state
            if ($scope.currentStepIndex !== destinationStep) {
                if (direction === 'back') {
                    flowModel.previous($scope.model).then(() => {
                        $scope.setDefaultHelpAndLearningText();
                        _recurseToState(destinationStep, direction);
                    });
                } else if (direction === 'forward') {
                    // Only allow the user to move the next state if the current state is valid
                    if ($scope.stepValidFuncs[$scope.currentStepIndex].isValid()) {
                        flowModel.next($scope.model).then(() => {
                            $scope.setDefaultHelpAndLearningText();
                            _recurseToState(destinationStep, direction);
                        });
                    }
                }
            }
        }
    }
];
