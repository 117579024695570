/*

 This is a heavily-modified typeahead implementation borrowed from angular-bootstrap.
 Angular-bootstrap implementation expects the working scope to be the same scope where input element is defined.
 This is not the case for this implementation for the typeahead, so stuff like _setLoading and _setOpen are hardcoded.
 They are passed down from typeahead hook directive.

 TypeaheadDefinition (source fn, mappers) is also hardcoded instead of being parsed from the attribute.

 Other changes are cosmetic to comply with codestyle,
 and wiped code for functionality that is not used.

 */
export default angular.module('edge.platform.widgets.typeahead.internal', [])
    .controller('gwTypeaheadController', ['$scope', '$element', '$attrs', '$compile', '$parse', '$q', '$timeout', '$document', '$window', '$rootScope',
        function (originalScope, element, attrs, $compile, $parse, $q, $timeout, $document, $window, $rootScope) {
            const HOT_KEYS = [9, 13, 27, 38, 40];
            let modelCtrl;
            let ngModelOptions;


            // should it restrict model values to the ones selected from the popup only?
            let isEditable = originalScope.$eval(attrs.gwTypeaheadEditable) !== false;
            originalScope.$watch(attrs.gwTypeaheadEditable, newVal => {
                isEditable = newVal !== false;
            });

            // binding to a variable that indicates if matches are being retrieved asynchronously
            const isLoadingSetter = originalScope._setLoading || angular.noop;

            // a function to determine if an event should cause selection
            const isSelectEvent = attrs.typeaheadShouldSelect ? $parse(attrs.typeaheadShouldSelect) : (scope, vals) => {
                const evt = vals.$event;
                return evt.which === 13 || evt.which === 9;
            };

            // a callback executed when a match is selected
            const onSelectCallback = $parse(attrs.typeaheadOnSelect);

            // should it select highlighted popup value when losing focus?
            const isSelectOnBlur = angular.isDefined(attrs.typeaheadSelectOnBlur) ? originalScope.$eval(attrs.typeaheadSelectOnBlur) : false;

            // binding to a variable that indicates if there were no results after the query is completed
            const isNoResultsSetter = $parse(attrs.typeaheadNoResults).assign || angular.noop;

            const appendTo = originalScope.$eval(attrs.gwTypeaheadAppendTo);

            const focusFirst = originalScope.$eval(attrs.typeaheadFocusFirst) !== false;

            // binding to a variable that indicates if dropdown is open
            const isOpenSetter = originalScope._setOpen || angular.noop;

            // INTERNAL VARIABLES

            // model setter executed upon match selection
            const parsedModel = $parse(attrs.ngModel);
            const invokeModelSetter = $parse(`${attrs.ngModel}($$$p)`);
            const $setModelValue = (scope, newValue) => {
                if (angular.isFunction(parsedModel(originalScope)) &&
                    ngModelOptions && ngModelOptions.$options && ngModelOptions.$options.getterSetter) {
                    return invokeModelSetter(scope, {$$$p: newValue});
                }

                return parsedModel.assign(scope, newValue);
            };

            const typeaheadDefinition = {
                itemName: 'item',
                source: $parse('_getMatches($viewValue)'),
                viewMapper: $parse('_toString(item)'),
                modelMapper: $parse('item')
            };

            let hasFocus;

            // Used to avoid bug in iOS webview where iOS keyboard does not fire
            // mousedown & mouseup events
            // Issue #3699
            let selected;

            // create a child scope for the typeahead directive so we are not polluting original scope
            // with typeahead-specific data (matches, query etc.)
            const scope = originalScope.$new();
            const offDestroy = originalScope.$on('$destroy', () => scope.$destroy());
            scope.$on('$destroy', offDestroy);

            // WAI-ARIA
            const popupId = `gw-typeahead-${scope.$id}-${Math.floor(Math.random() * 10000)}`;
            element.attr({
                'aria-autocomplete': 'list',
                'aria-expanded': false,
                'aria-owns': popupId
            });

            // pop-up element used to display matches
            const popUpEl = angular.element('<div gw-typeahead-dropdown></div>');
            popUpEl.attr({
                id: popupId,
                matches: 'matches',
                active: 'activeIdx',
                select: 'select(activeIdx, evt)',
                'move-in-progress': 'moveInProgress',
                query: 'query',
                position: 'position',
                'assign-is-open': 'assignIsOpen(isOpen)',
                'select-directive': '_selectDirective' // asmoogly@: This method is passed to match content directive injector
            });

            const resetMatches = function () {
                scope.matches = [];
                scope.activeIdx = -1;
                element.attr('aria-expanded', false);
            };

            const getMatchId = function (index) {
                return `${popupId}-option-${index}`;
            };

            // Indicate that the specified match is the active (pre-selected) item in the list owned by this typeahead.
            // This attribute is added or removed automatically when the `activeIdx` changes.
            scope.$watch('activeIdx', index => {
                if (index < 0) {
                    element.removeAttr('aria-activedescendant');
                } else {
                    element.attr('aria-activedescendant', getMatchId(index));
                }
            });

            const inputIsExactMatch = (inputValue, index) => {
                if (scope.matches.length > index && inputValue) {
                    return inputValue.toUpperCase() === scope.matches[index].label.toUpperCase();
                }

                return false;
            };

            const getMatchesAsync = (inputValue, evt) => {
                const locals = {$viewValue: inputValue};
                isLoadingSetter(true);
                isNoResultsSetter(originalScope, false);
                $q.when(typeaheadDefinition.source(originalScope, locals))
                    .then(
                        matches => {
                            // it might happen that several async queries were in progress if a user were typing fast
                            // but we are interested only in responses that correspond to the current view value
                            const onCurrentRequest = inputValue === modelCtrl.$viewValue;
                            if (onCurrentRequest && hasFocus) {
                                if (matches && matches.length > 0) {
                                    scope.activeIdx = focusFirst ? 0 : -1;
                                    isNoResultsSetter(originalScope, false);
                                    scope.matches = [];

                                    // transform labels
                                    for (let i = 0; i < matches.length; i++) {
                                        locals[typeaheadDefinition.itemName] = matches[i];
                                        scope.matches.push({
                                            id: getMatchId(i),
                                            label: typeaheadDefinition.viewMapper(scope, locals),
                                            model: matches[i]
                                        });
                                    }

                                    scope.query = inputValue;
                                    element.attr('aria-expanded', true);

                                    // Select the single remaining option if user input matches
                                    if (scope.matches.length === 1 && inputIsExactMatch(inputValue, 0)) {
                                        scope.select(0, evt);
                                    }
                                } else {
                                    resetMatches();
                                    isNoResultsSetter(originalScope, true);
                                }
                            }
                            if (onCurrentRequest) {
                                isLoadingSetter(false);
                            }
                        },
                        () => {
                            resetMatches();
                            isLoadingSetter(false);
                            isNoResultsSetter(originalScope, true);
                        }
                    );
            };

            // we need to propagate user's query so we can higlight matches
            scope.query = undefined;
            resetMatches();

            scope.assignIsOpen = function (isOpen) {
                isOpenSetter(isOpen);
            };

            scope.select = function (activeIdx, evt) {
                // called from within the $digest() cycle
                const locals = {};

                selected = true;

                const item = scope.matches[activeIdx].model;
                locals[typeaheadDefinition.itemName] = item;

                const model = typeaheadDefinition.modelMapper(originalScope, locals);
                $setModelValue(originalScope, model);
                modelCtrl.$setValidity('editable', true);
                modelCtrl.$setValidity('parse', true);

                onSelectCallback(originalScope, {
                    $item: item,
                    $model: model,
                    $label: typeaheadDefinition.viewMapper(originalScope, locals),
                    $event: evt
                });

                resetMatches();

                // return focus to the input element if a match was selected via a mouse click event
                // use timeout to avoid $rootScope:inprog error
                if (scope.$eval(attrs.typeaheadFocusOnSelect) !== false) {
                    $timeout(() => {
                        element[0].focus();
                    }, 0, false);
                }
            };

            // bind keyboard events: arrows up(38) / down(40), enter(13) and tab(9), esc(27)
            element.on('keydown', evt => {
                // typeahead is open and an "interesting" key was pressed
                if (scope.matches.length === 0 || HOT_KEYS.indexOf(evt.which) === -1) {
                    return;
                }

                const shouldSelect = isSelectEvent(originalScope, {$event: evt});

                /**
                 * if there's nothing selected (i.e. focusFirst) and enter or tab is hit
                 * or
                 * shift + tab is pressed to bring focus to the previous element
                 * then clear the results
                 */
                if (((scope.activeIdx === -1 && shouldSelect) || evt.which === 9) && Boolean(evt.shiftKey)) {
                    resetMatches();
                    scope.$digest();
                    return;
                }

                evt.preventDefault();
                const onArrow = function () {
                    scope.$digest();
                    const target = popUpEl[0].querySelectorAll('.js-gw-typeahead-match')[scope.activeIdx];
                    target.parentNode.scrollTop = target.offsetTop;
                };

                switch (evt.which) {
                    case 27: // escape
                        evt.stopPropagation();

                        resetMatches();
                        originalScope.$digest();
                        break;
                    case 38: // up arrow
                        scope.activeIdx = (scope.activeIdx > 0 ? scope.activeIdx : scope.matches.length) - 1;
                        onArrow();
                        break;
                    case 40: // down arrow
                        scope.activeIdx = (scope.activeIdx + 1) % scope.matches.length;
                        onArrow();
                        break;
                    default:
                        if (shouldSelect) {
                            scope.$apply(() => scope.select(scope.activeIdx, evt));
                        }
                }
            });

            element.bind('focus', evt => {
                hasFocus = true;
                $timeout(() => getMatchesAsync(modelCtrl.$viewValue, evt), 0);
            });

            element.bind('blur', evt => {
                if (isSelectOnBlur && scope.matches.length && scope.activeIdx !== -1 && !selected) {
                    selected = true;
                    scope.$apply(() => scope.select(scope.activeIdx, evt));
                }
                if (!isEditable && modelCtrl.$error.editable) {
                    modelCtrl.$setViewValue();
                    scope.$apply(() => {
                        // Reset validity as we are clearing
                        modelCtrl.$setValidity('editable', true);
                        modelCtrl.$setValidity('parse', true);
                    });
                    element.val('');
                }
                hasFocus = false;
                selected = false;
            });

            // Keep reference to click handler to unbind it.
            const dismissClickHandler = evt => {
                // Issue #3973
                // Firefox treats right click as a click on document
                if (element[0] !== evt.target && evt.which !== 3 && scope.matches.length !== 0) {
                    resetMatches();
                    if (!$rootScope.$$phase) {
                        originalScope.$digest();
                    }
                }
            };

            $document.on('click', dismissClickHandler);

            originalScope.$on('$destroy', () => {
                $document.off('click', dismissClickHandler);
                $popup.remove();

                // Prevent jQuery cache memory leak
                popUpEl.remove();
            });

            const $popup = $compile(popUpEl)(scope);
            angular.element(appendTo).eq(0).append($popup);

            this.init = function (_modelCtrl, _ngModelOptions) {
                modelCtrl = _modelCtrl;
                ngModelOptions = _ngModelOptions;

                // plug into $parsers pipeline to open a typeahead on view changes initiated from DOM
                // $parsers kick-in on all the changes coming from the view as well as manually triggered by $setViewValue
                modelCtrl.$parsers.unshift(inputValue => {
                    hasFocus = true;
                    getMatchesAsync(inputValue);

                    if (isEditable) {
                        return inputValue;
                    }

                    if (!inputValue) {
                        // Reset in case user had typed something previously.
                        modelCtrl.$setValidity('editable', true);
                        return null;
                    }

                    modelCtrl.$setValidity('editable', false);
                    return undefined;
                });

                modelCtrl.$formatters.push(modelValue => {
                    const locals = {};

                    // The validity may be set to false via $parsers (see above) if
                    // the model is restricted to selected values. If the model
                    // is set manually it is considered to be valid.
                    if (!isEditable) {
                        modelCtrl.$setValidity('editable', true);
                    }

                    // it might happen that we don't have enough info to properly render input value
                    // we need to check for this situation and simply return model value if we can't apply custom formatting
                    locals[typeaheadDefinition.itemName] = modelValue;
                    const candidateViewValue = typeaheadDefinition.viewMapper(originalScope, locals);

                    locals[typeaheadDefinition.itemName] = undefined;
                    const emptyViewValue = typeaheadDefinition.viewMapper(originalScope, locals);

                    return candidateViewValue !== emptyViewValue ? candidateViewValue : modelValue;
                });
            };
        }])
    .directive('gwTypeaheadInternal', () => ({
        restrict: 'A',
        controller: 'gwTypeaheadController',
        require: ['ngModel', '^?ngModelOptions', 'gwTypeaheadInternal'],
        link: (originalScope, element, attrs, ctrls) => {
            ctrls[2].init(ctrls[0], ctrls[1]);
        }
    }));