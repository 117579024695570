import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './multiselect-row-hashed.scss';
import controller from './multiselectRowCtrl';
import templateStr from 'text!edge/platform/widgets/multiselect/components/multiselectrow/multiselect-row.html';

export default {
    template: cssUtil.hashTemplate(templateStr, styles),
    bindings: {
        model: '<',
        icon: '&',
        selectText: '&',
        helpText: '&',
        id: '&'
    },
    controller: controller
};