export default {

    /*
    * @returns array of steps with step and title
    * */
    getWizardStepsReadonly: () => {
        const step = function (name, label) {
            const stepVM = {
                name: name,
                label: label
            };
            return stepVM;
        };
        return [
            step('pmContactDetails', () => 'About you'),
            step('pmDriverSummary', () => 'About the driver'),
            step('pmVehicleDetails', () =>'About your car'),
            step('quoteDetails', () => 'Your quote'),
            step('legalPaymentStep4', () => 'Legal and payment'),
            step('paymentDetails', () => 'Payment Details')];
    },
};