import BaseCtrl from 'edge/platform/base/BaseCtrl';

export default () => {
    const ctrlConstructor = function ($scope, MediaQueriesUtil, $element) {
        // indicator to prevent a slide panel closing on opener element click
        // when opener is in a content section
        let drawerIsOpening = false;
        const drawerIsOpenPostfix = 'DrawerIsOpen';
        const mediaQuery = MediaQueriesUtil.getPhoneLandscapeDownQuery();

        const ctrl = Object.create(BaseCtrl);

        ctrl.init = function () {
            $scope[`left${drawerIsOpenPostfix}`] = false; // leftDrawerIsOpen
            $scope[`right${drawerIsOpenPostfix}`] = false; // rightDrawerIsOpen

            ctrl._breakpointReached(mediaQuery);

            // EVENTS
            mediaQuery.addListener(ctrl._breakpointReached);

            $element.on('click', () => {
                if (drawerIsOpening) {
                    drawerIsOpening = false;
                    return;
                }
                ctrl.closeDrawers();
            });

            // remove listeners on $scope destroying
            $scope.$on('$destroy', () => {
                mediaQuery.removeListener(ctrl._breakpointReached);
                ctrl._retrieveContentFocus();
                $element.off('click');
            });
        };

        /**
         * @param {String} drawerSide 'left' || 'right'
         */
        ctrl.openDrawer = (drawerSide) => {
            // close other
            const otherDrawerSide = ctrl._getOtherDrawerSide(drawerSide);
            if ($scope[otherDrawerSide + drawerIsOpenPostfix]) {
                ctrl.closeDrawer(otherDrawerSide);
            }
            // open current
            $element.addClass(`gw-snap-drawer-${drawerSide}-active`);
            ctrl._preventContentFocus();

            drawerIsOpening = true;
            $scope[drawerSide + drawerIsOpenPostfix] = true; // set drawer state to $scope
        };

        /**
         * @param {String} drawerSide 'left' || 'right'
         */
        ctrl.closeDrawer = (drawerSide) => {
            $element.removeClass(`gw-snap-drawer-${drawerSide}-active`);
            ctrl._retrieveContentFocus();

            $scope[drawerSide + drawerIsOpenPostfix] = false; // set drawer state to $scope
        };

        ctrl.closeDrawers = () => {
            if ($scope[`left${drawerIsOpenPostfix}`]) {
                ctrl.closeDrawer('left');
                ctrl._angularApply();
            }

            if ($scope[`right${drawerIsOpenPostfix}`]) {
                ctrl.closeDrawer('right');
                ctrl._angularApply();
            }
        };

        // UTILS
        ctrl._getOtherDrawerSide = function (drawerSide) {
            switch (drawerSide) {
                case 'left':
                    return 'right';
                case 'right':
                    return 'left';
                default:
                    break;
            }
        };

        // Required when some functionality is called outside of AngularJs
        ctrl._angularApply = function () {
            if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                $scope.$apply();
            }
        };

        /* EVENT */
        // PREVENT SCROLLING AND FOCUS INSIDE ELEMENTS WHEN DRAWER IS OPEN
        ctrl._preventContentFocus = function () {
            $element.on('focusin.snap', e => $(e.target).blur());
        };

        ctrl._retrieveContentFocus = function () {
            $element.off('focusin.snap');
        };

        // media query change
        ctrl._breakpointReached = function () {
            if (!mediaQuery.matches) { // >= break
                ctrl.closeDrawers(); // close drawers on switching from a mobile to a bigger screen
            }
        };

        return ctrl.create($scope);
    };
    ctrlConstructor.$inject = ['$scope', 'MediaQueriesUtil', '$element'];

    return {
        restrict: 'A',
        replace: false,
        controller: ctrlConstructor
    };
};
