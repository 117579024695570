/**
 * Taken from https://github.com/angular-ui/ui-mask with changes to ignore validation and remove any
 * trailing placeholder characters
 */

export default ['uiMaskConfig', (maskConfig) => {
    return {
        priority: 100,
        require: 'ngModel',
        restrict: 'A',
        compile: function uiMaskCompilingFunction() {
            const options = maskConfig;

            return function uiMaskLinkingFunction(scope, iElement, iAttrs, controller) {
                let maskProcessed = false;
                let eventsBound = false;
                let maskCaretMap;
                let maskPatterns;
                let maskPlaceholder;
                let maskComponents;
                // Minimum required length of the value to be considered valid
                let value;
                let valueMasked;
                let isValid;
                // Vars for initializing/uninitializing
                const originalPlaceholder = iAttrs.placeholder;
                const originalMaxlength = iAttrs.maxlength;
                // Vars used exclusively in eventHandler()
                let oldValue;
                let oldValueUnmasked;
                let oldCaretPosition;
                let oldSelectionLength;
                // Used for communicating if a backspace operation should be allowed between
                // keydownHandler and eventHandler
                let preventBackspace;

                const originalIsEmpty = controller.$isEmpty;
                controller.$isEmpty = function (_value) {
                    if (maskProcessed) {
                        return originalIsEmpty(unmaskValue(_value || ''));
                    }
                    return originalIsEmpty(_value);
                };

                function initialize(maskAttr) {
                    if (!angular.isDefined(maskAttr)) {
                        return uninitialize();
                    }
                    processRawMask(maskAttr);
                    if (!maskProcessed) {
                        return uninitialize();
                    }
                    initializeElement();
                    bindEventListeners();
                    return true;
                }

                function initPlaceholder(placeholderAttr) {
                    if (!placeholderAttr) {
                        return;
                    }

                    maskPlaceholder = placeholderAttr;

                    // If the mask is processed, then we need to update the value
                    // but don't set the value if there is nothing entered into the element
                    // and there is a placeholder attribute on the element because that
                    // will only set the value as the blank maskPlaceholder
                    // and override the placeholder on the element
                    if (maskProcessed && !(iElement.val().length === 0 && angular.isDefined(iAttrs.placeholder))) {
                        iElement.val(maskValue(unmaskValue(iElement.val())));
                    }
                }

                function initPlaceholderChar() {
                    return initialize(iAttrs.uiMask);
                }

                function formatter(fromModelValue) {
                    if (!maskProcessed) {
                        return fromModelValue;
                    }
                    value = unmaskValue(fromModelValue || '');
                    isValid = validateValue(value);
                    controller.$setValidity('mask', isValid);
                    return isValid && value.length ? maskValue(value) : undefined;
                }

                function parser(fromViewValue) {
                    let returnedValue;
                    if (!maskProcessed) {
                        return fromViewValue;
                    }
                    value = unmaskValue(fromViewValue || '');
                    isValid = validateValue(value);
                    // We have to set viewValue manually as the reformatting of the input
                    // value performed by eventHandler() doesn't happen until after
                    // this parser is called, which causes what the user sees in the input
                    // to be out-of-sync with what the controller's $viewValue is set to.
                    controller.$viewValue = value.length ? maskValue(value) : '';
                    controller.$setValidity('mask', isValid);
                    // JC: change from original  implementation- remove trailing placeholder characters
                    if (value.length && angular.isDefined(iAttrs.leaveValueMasked)) {
                        returnedValue = fromViewValue;
                        if (controller.$viewValue.length > maskCaretMap.length) {
                            // remove any trailing characters when we have entered the required number of characters
                            returnedValue = returnedValue.substr(0, maskCaretMap.length + 1);
                        }
                    } else {
                        returnedValue = value;
                    }
                    return returnedValue;
                }

                let linkOptions = {};

                if (iAttrs.uiOptions) {
                    linkOptions = scope.$eval(`[${iAttrs.uiOptions}]`);
                    if (angular.isObject(linkOptions[0])) {
                        // we can't use angular.copy nor angular.extend, they lack the power to do a deep merge
                        linkOptions = ((original, current) => {
                            for (const i in original) {
                                if (Object.prototype.hasOwnProperty.call(original, i)) {
                                    if (current[i] === undefined) {
                                        current[i] = angular.copy(original[i]);
                                    } else if (angular.isObject(current[i]) && !angular.isArray(current[i])) {
                                        current[i] = angular.extend({}, original[i], current[i]);
                                    }
                                }
                            }
                            return current;
                        })(options, linkOptions[0]);
                    } else {
                        linkOptions = options; // gotta be a better way to do this..
                    }
                } else {
                    linkOptions = options;
                }

                iAttrs.$observe('gwPlUiMask', initialize);
                if (angular.isDefined(iAttrs.uiMaskPlaceholder)) {
                    iAttrs.$observe('uiMaskPlaceholder', initPlaceholder);
                } else {
                    iAttrs.$observe('placeholder', initPlaceholder);
                }
                if (angular.isDefined(iAttrs.uiMaskPlaceholderChar)) {
                    iAttrs.$observe('uiMaskPlaceholderChar', initPlaceholderChar);
                }

                controller.$formatters.unshift(formatter);
                controller.$parsers.unshift(parser);

                function uninitialize() {
                    maskProcessed = false;
                    unbindEventListeners();

                    if (angular.isDefined(originalPlaceholder)) {
                        iElement.attr('placeholder', originalPlaceholder);
                    } else {
                        iElement.removeAttr('placeholder');
                    }

                    if (angular.isDefined(originalMaxlength)) {
                        iElement.attr('maxlength', originalMaxlength);
                    } else {
                        iElement.removeAttr('maxlength');
                    }

                    iElement.val(controller.$modelValue);
                    controller.$viewValue = controller.$modelValue;
                    return false;
                }

                function initializeElement() {
                    oldValueUnmasked = unmaskValue(controller.$modelValue || '');
                    value = oldValueUnmasked;

                    oldValue = maskValue(value);
                    valueMasked = oldValue;

                    isValid = validateValue(value);
                    if (iAttrs.maxlength) { // Double maxlength to allow pasting new val at end of mask
                        iElement.attr('maxlength', maskCaretMap[maskCaretMap.length - 1] * 2);
                    }
                    if (!originalPlaceholder && linkOptions.addDefaultPlaceholder) {
                        iElement.attr('placeholder', maskPlaceholder);
                    }
                    let viewValue = controller.$modelValue;
                    let idx = controller.$formatters.length;
                    while (idx--) {
                        viewValue = controller.$formatters[idx](viewValue);
                    }
                    controller.$viewValue = viewValue || '';
                    controller.$render();
                    // Not using $setViewValue so we don't clobber the model value and dirty the form
                    // without any kind of user interaction.
                }

                function bindEventListeners() {
                    if (eventsBound) {
                        return;
                    }
                    iElement.bind('blur', blurHandler);
                    iElement.bind('mousedown mouseup', mouseDownUpHandler);
                    iElement.bind('keydown', keydownHandler);
                    iElement.bind(linkOptions.eventsToHandle.join(' '), eventHandler);
                    eventsBound = true;
                }

                function unbindEventListeners() {
                    if (!eventsBound) {
                        return;
                    }
                    iElement.unbind('blur', blurHandler);
                    iElement.unbind('mousedown', mouseDownUpHandler);
                    iElement.unbind('mouseup', mouseDownUpHandler);
                    iElement.unbind('keydown', keydownHandler);
                    iElement.unbind('input', eventHandler);
                    iElement.unbind('keyup', eventHandler);
                    iElement.unbind('click', eventHandler);
                    iElement.unbind('focus', eventHandler);
                    eventsBound = false;
                }

                function validateValue() {
                    // JC: change from original  implementation- leave view model to handle validation - just mark it valid
                    return true;
                }

                function unmaskValue(_value) {
                    let valueUnmasked = '';
                    const input = iElement[0];
                    const maskPatternsCopy = maskPatterns.slice();
                    const selectionStart = oldCaretPosition;
                    const selectionEnd = selectionStart + getSelectionLength(input);
                    let valueOffset;
                    let tempValue = '';
                    // Preprocess by stripping mask components from _value
                    _value = _value.toString();
                    valueOffset = 0;
                    const valueDelta = _value.length - maskPlaceholder.length;
                    angular.forEach(maskComponents, (component) => {
                        let position = component.position;
                        // Only try and replace the component if the component position is not within the selected range
                        // If component was in selected range then it was removed with the user input so no need to try and remove that component
                        if (!(position >= selectionStart && position < selectionEnd)) {
                            if (position >= selectionStart) {
                                position += valueDelta;
                            }
                            if (_value.substring(position, position + component.value.length) === component.value) {
                                tempValue += _value.slice(valueOffset, position); // + _value.slice(position + component.value.length);
                                valueOffset = position + component.value.length;
                            }
                        }
                    });
                    _value = tempValue + _value.slice(valueOffset);
                    angular.forEach(_value.split(''), (chr) => {
                        if (maskPatternsCopy.length && maskPatternsCopy[0].test(chr)) {
                            valueUnmasked += chr;
                            maskPatternsCopy.shift();
                        }
                    });

                    return valueUnmasked;
                }

                function maskValue(unmaskedValue) {
                    valueMasked = '';
                    const _maskCaretMapCopy = maskCaretMap.slice();

                    angular.forEach(maskPlaceholder.split(''), (chr, i) => {
                        if (unmaskedValue.length && i === _maskCaretMapCopy[0]) {
                            valueMasked += unmaskedValue.charAt(0) || '_';
                            unmaskedValue = unmaskedValue.substr(1);
                            _maskCaretMapCopy.shift();
                        } else {
                            valueMasked += chr;
                        }
                    });
                    return valueMasked;
                }

                function getPlaceholderChar(i) {
                    const placeholder = angular.isDefined(iAttrs.uiMaskPlaceholder) ? iAttrs.uiMaskPlaceholder : iAttrs.placeholder;

                    if (angular.isDefined(placeholder) && placeholder[i]) {
                        return placeholder[i];
                    }
                    const defaultPlaceholderChar = angular.isDefined(iAttrs.uiMaskPlaceholderChar) && iAttrs.uiMaskPlaceholderChar ? iAttrs.uiMaskPlaceholderChar : '_';
                    return (defaultPlaceholderChar.toLowerCase() === 'space') ? ' ' : defaultPlaceholderChar[0];
                }

                // Generate array of mask components that will be stripped from a masked value
                // before processing to prevent mask components from being added to the unmasked value.
                // E.g., a mask pattern of '+7 9999' won't have the 7 bleed into the unmasked value.
                function getMaskComponents() {
                    const maskPlaceholderChars = maskPlaceholder.split('');
                    let components;

                    // maskCaretMap can have bad values if the input has the ui-mask attribute implemented as an obversable property, e.g. the demo page
                    if (maskCaretMap && !Number.isNaN(maskCaretMap[0])) {
                        // Instead of trying to manipulate the RegEx based on the placeholder characters
                        // we can simply replace the placeholder characters based on the already built
                        // maskCaretMap to underscores and leave the original working RegEx to get the proper
                        // mask components
                        angular.forEach(maskCaretMap, (_value) => {
                            maskPlaceholderChars[_value] = '_';
                        });
                    }
                    const maskPlaceholderCopy = maskPlaceholderChars.join('');
                    components = maskPlaceholderCopy.replace(/[_]+/g, '_').split('_');
                    components = components.filter((s) => {
                        return s !== '';
                    });

                    // need a string search offset in cases where the mask contains multiple identical components
                    // E.g., a mask of 99.99.99-999.99
                    let offset = 0;
                    return components.map((c) => {
                        const componentPosition = maskPlaceholderCopy.indexOf(c, offset);
                        offset = componentPosition + 1;
                        return {
                            value: c,
                            position: componentPosition
                        };
                    });
                }

                function processRawMask(mask) {
                    let characterCount = 0;

                    maskCaretMap = [];
                    maskPatterns = [];
                    maskPlaceholder = '';

                    if (angular.isString(mask)) {
                        let numberOfOptionalCharacters = 0;
                        const splitMask = mask.split('');

                        angular.forEach(splitMask, (chr, i) => {
                            if (linkOptions.maskDefinitions[chr]) {

                                maskCaretMap.push(characterCount);

                                maskPlaceholder += getPlaceholderChar(i - numberOfOptionalCharacters);
                                maskPatterns.push(linkOptions.maskDefinitions[chr]);

                                characterCount++;
                            } else if (chr === '?') {
                                numberOfOptionalCharacters++;
                            } else {
                                maskPlaceholder += chr;
                                characterCount++;
                            }
                        });
                    }
                    // Caret position immediately following last position is valid.
                    maskCaretMap.push(maskCaretMap.slice().pop() + 1);

                    maskComponents = getMaskComponents();
                    maskProcessed = maskCaretMap.length > 1;
                }

                let prevValue = iElement.val();

                function blurHandler() {
                    if (linkOptions.clearOnBlur || ((linkOptions.clearOnBlurPlaceholder) && (value.length === 0) && iAttrs.placeholder)) {
                        oldCaretPosition = 0;
                        oldSelectionLength = 0;
                        if (!isValid || value.length === 0) {
                            valueMasked = '';
                            iElement.val('');
                            scope.$apply(() => {
                                // only $setViewValue when not $pristine to avoid changing $pristine state.
                                if (!controller.$pristine) {
                                    controller.$setViewValue('');
                                }
                            });
                        }
                    }
                    // Check for different value and trigger change.
                    if (value !== prevValue) {
                        triggerChangeEvent(iElement[0]);
                    }
                    prevValue = value;
                }

                function triggerChangeEvent(element) {
                    let change;
                    if (angular.isFunction(window.Event) && !element.fireEvent) {
                        // modern browsers and Edge
                        change = new Event('change', {
                            view: window,
                            bubbles: true,
                            cancelable: false
                        });
                        element.dispatchEvent(change);
                    } else if ('createEvent' in document) {
                        // older browsers
                        change = document.createEvent('HTMLEvents');
                        change.initEvent('change', false, true);
                        element.dispatchEvent(change);
                    } else if (element.fireEvent) {
                        // IE <= 11
                        element.fireEvent('onchange');
                    }
                }

                function mouseDownUpHandler(e) {
                    if (e.type === 'mousedown') {
                        iElement.bind('mouseout', mouseoutHandler);
                    } else {
                        iElement.unbind('mouseout', mouseoutHandler);
                    }
                }

                iElement.bind('mousedown mouseup', mouseDownUpHandler);

                function mouseoutHandler() {
                    /* jshint validthis: true */
                    oldSelectionLength = getSelectionLength(this);
                    iElement.unbind('mouseout', mouseoutHandler);
                }

                function keydownHandler(e) {
                    /* jshint validthis: true */
                    const isKeyBackspace = e.which === 8;
                    let caretPos = getCaretPosition(this) - 1 || 0; // value in keydown is pre change so bump caret position back to simulate post change

                    if (isKeyBackspace) {
                        while (caretPos >= 0) {
                            if (isValidCaretPosition(caretPos)) {
                                // re-adjust the caret position.
                                // Increment to account for the initial decrement to simulate post change caret position
                                setCaretPosition(this, caretPos + 1);
                                break;
                            }
                            caretPos--;
                        }
                        preventBackspace = caretPos === -1;
                    }
                }

                function eventHandler(e) {
                    /* jshint validthis: true */
                    e = e || {};
                    // Allows more efficient minification
                    const eventWhich = e.which;
                    const eventType = e.type;

                    // Prevent shift and ctrl from mucking with old values
                    if (eventWhich === 16 || eventWhich === 91) {
                        return;
                    }

                    const val = iElement.val();
                    const valOld = oldValue;
                    let valAltered = false;
                    let valUnmasked = unmaskValue(val);
                    const valUnmaskedOld = oldValueUnmasked;
                    let caretPos = getCaretPosition(this) || 0;
                    const caretPosOld = oldCaretPosition || 0;
                    const caretPosDelta = caretPos - caretPosOld;
                    const caretPosMin = maskCaretMap[0];
                    const caretPosMax = maskCaretMap[valUnmasked.length] || maskCaretMap.slice().shift();
                    const selectionLenOld = oldSelectionLength || 0;
                    const isSelected = getSelectionLength(this) > 0;
                    const wasSelected = selectionLenOld > 0;
                    // Case: Typing a character to overwrite a selection
                    const isAddition = (val.length > valOld.length) || (selectionLenOld && val.length > valOld.length - selectionLenOld);
                    // Case: Delete and backspace behave identically on a selection
                    const isDeletion = (val.length < valOld.length) || (selectionLenOld && val.length === valOld.length - selectionLenOld);
                    const isSelection = (eventWhich >= 37 && eventWhich <= 40) && e.shiftKey; // Arrow key codes

                    const isKeyLeftArrow = eventWhich === 37;
                    // Necessary due to "input" event not providing a key code
                    const isKeyBackspace = eventWhich === 8 || (eventType !== 'keyup' && isDeletion && (caretPosDelta === -1));
                    const isKeyDelete = eventWhich === 46 || (eventType !== 'keyup' && isDeletion && (caretPosDelta === 0) && !wasSelected);
                    // Handles cases where caret is moved and placed in front of invalid maskCaretMap position. Logic below
                    // ensures that, on click or leftward caret placement, caret is moved leftward until directly right of
                    // non-mask character. Also applied to click since users are (arguably) more likely to backspace
                    // a character when clicking within a filled input.
                    const caretBumpBack = (isKeyLeftArrow || isKeyBackspace || eventType === 'click') && caretPos > caretPosMin;

                    oldSelectionLength = getSelectionLength(this);

                    // These events don't require any action
                    if (isSelection || (isSelected && (eventType === 'click' || eventType === 'keyup' || eventType === 'focus'))) {
                        return;
                    }

                    if (isKeyBackspace && preventBackspace) {
                        iElement.val(maskPlaceholder);
                        // This shouldn't be needed but for some reason after aggressive backspacing the controller $viewValue is incorrect.
                        // This keeps the $viewValue updated and correct.
                        scope.$apply(() => {
                            controller.$setViewValue(''); // $setViewValue should be run in angular context, otherwise the changes will be invisible to angular and user code.
                        });
                        setCaretPosition(this, caretPosOld);
                        return;
                    }

                    // Value Handling
                    // ==============

                    // User attempted to delete but raw value was unaffected--correct this grievous offense
                    if ((eventType === 'input') && isDeletion && !wasSelected && valUnmasked === valUnmaskedOld) {
                        while (isKeyBackspace && caretPos > caretPosMin && !isValidCaretPosition(caretPos)) {
                            caretPos--;
                        }
                        while (isKeyDelete && caretPos < caretPosMax && maskCaretMap.indexOf(caretPos) === -1) {
                            caretPos++;
                        }
                        const charIndex = maskCaretMap.indexOf(caretPos);
                        // Strip out non-mask character that user would have deleted if mask hadn't been in the way.
                        valUnmasked = valUnmasked.substring(0, charIndex) + valUnmasked.substring(charIndex + 1);

                        // If value has not changed, don't want to call $setViewValue, may be caused by IE raising input event due to placeholder
                        if (valUnmasked !== valUnmaskedOld) {
                            valAltered = true;
                        }
                    }

                    // Update values
                    const valMasked = maskValue(valUnmasked);

                    oldValue = valMasked;
                    oldValueUnmasked = valUnmasked;

                    // additional check to fix the problem where the viewValue is out of sync with the value of the element.
                    // better fix for commit 2a83b5fb8312e71d220a497545f999fc82503bd9 (I think)
                    if (!valAltered && val.length > valMasked.length) {
                        valAltered = true;
                    }

                    iElement.val(valMasked);

                    // we need this check.  What could happen if you don't have it is that you'll set the model value without the user
                    // actually doing anything.  Meaning, things like pristine and touched will be set.
                    if (valAltered) {
                        scope.$apply(() => {
                            controller.$setViewValue(valMasked); // $setViewValue should be run in angular context, otherwise the changes will be invisible to angular and user code.
                        });
                    }

                    // Caret Repositioning
                    // ===================

                    // Ensure that typing always places caret ahead of typed character in cases where the first char of
                    // the input is a mask char and the caret is placed at the 0 position.
                    if (isAddition && (caretPos <= caretPosMin)) {
                        caretPos = caretPosMin + 1;
                    }

                    if (caretBumpBack) {
                        caretPos--;
                    }

                    // Make sure caret is within min and max position limits
                    caretPos = caretPos > caretPosMax ? caretPosMax : caretPos;
                    caretPos = caretPos < caretPosMin ? caretPosMin : caretPos;

                    // Scoot the caret back or forth until it's in a non-mask position and within min/max position limits
                    while (!isValidCaretPosition(caretPos) && caretPos > caretPosMin && caretPos < caretPosMax) {
                        caretPos += caretBumpBack ? -1 : 1;
                    }

                    if ((caretBumpBack && caretPos < caretPosMax) || (isAddition && !isValidCaretPosition(caretPosOld))) {
                        caretPos++;
                    }
                    oldCaretPosition = caretPos;
                    setCaretPosition(this, caretPos);
                }

                function isValidCaretPosition(pos) {
                    return maskCaretMap.indexOf(pos) > -1;
                }

                function isFocused(elem) {
                    return elem === document.activeElement && (!document.hasFocus || document.hasFocus()) && !!(elem.type || elem.href || !(elem.tabIndex === -1));
                }

                function getCaretPosition(input) {
                    if (!input) {
                        return 0;
                    }
                    if (input.selectionStart !== undefined) {
                        return input.selectionStart;
                    } else if (document.selection) {
                        if (isFocused(iElement[0])) {
                            // Curse you IE
                            input.focus();
                            const selection = document.selection.createRange();
                            selection.moveStart('character', input.value ? -input.value.length : 0);
                            return selection.text.length;
                        }
                    }
                    return 0;
                }

                function setCaretPosition(input, pos) {
                    if (!input) {
                        return 0;
                    }
                    if (input.offsetWidth === 0 || input.offsetHeight === 0) {
                        return; // Input's hidden
                    }
                    if (input.setSelectionRange) {
                        if (isFocused(iElement[0])) {
                            input.focus();
                            input.setSelectionRange(pos, pos);
                        }
                    } else if (input.createTextRange) {
                        // Curse you IE
                        const range = input.createTextRange();
                        range.collapse(true);
                        range.moveEnd('character', pos);
                        range.moveStart('character', pos);
                        range.select();
                    }
                }

                function getSelectionLength(input) {
                    if (!input) {
                        return 0;
                    }
                    if (input.selectionStart !== undefined) {
                        return (input.selectionEnd - input.selectionStart);
                    }
                    if (document.selection) {
                        return (document.selection.createRange().text.length);
                    }
                    return 0;
                }
            };
        }
    };
}];
