import templateStr from 'text!./templates/wizard-page-actions_AND.html';
import FormUtil from 'edge/platform/modelvalidation/FormUtil';

export default ['StateUtils', '$q', '$state', '$rootScope', function (StateUtils, $q, $state, $rootScope) {
    return {
        template: templateStr,
        scope: {
            'showCancel': '=?',
            'showPrevious': '=?',
            'undo': '&?',
            'showUndo': '=?',
            'showNext': '=?',
            'disableCancel': '=?',
            'disablePrevious': '=?',
            'disableNext': '=?',
            'next': '=?',
            'nextLabel': '@?',
            'nextTooltip': '@?',
            'tooltipPosition': '@?',
            'previous': '=?',
            'previousLabel': '@?',
            'cancel': '=?',
            'cancelLabel': '@?',
            'gwWizardPageActions': '=',
            'flowModel': '=?',
            'hideTopBorder': '=?'
        },
        require: ['?^form', '?^ngForm'],
        link(scope, element, attrs, formCtrls) {
            const formCtrl = formCtrls[0] || {};
            const flowModel = scope.flowModel || StateUtils.activeDataForState(true).flowModel;
            const model = scope.gwWizardPageActions || StateUtils.activeDataForState(true).model;
            if (!flowModel) {
                throw new Error('Page action directive under a wizard state that has a flowModel data property');
            }

            scope.showNext = (scope.showNext !== undefined) ? scope.showNext : true;
            if (flowModel.steps.length > 0) {
                const isFirstStep = flowModel.steps[0].name === flowModel.currentStep.name;
                scope.showPrevious = (scope.showPrevious !== undefined) ? scope.showPrevious : !isFirstStep;
            }
            scope.showCancel = (scope.showCancel !== undefined) ? scope.showCancel : true;

            scope.disableCancel = scope.disableCancel || false;
            scope.disablePrevious = scope.disablePrevious || false;
            
            scope.tooltipPosition = scope.tooltipPosition || 'bottom';
            
            scope.showTBBContinueButtonArrow = () => {
                return $state.current.name !== 'legalPaymentStep2';
            };

            scope.goToNext = () => {
                FormUtil.markFormsSubmitted(formCtrl);
                if (scope.next) {
                    const actionDeferred = $q.defer();
                    scope.actionPromise = actionDeferred.promise;
                    scope.next((_model, args) => {
                        if (!_model) {
                            $rootScope.$broadcast('andEnableValidationSummary'); // ANDIE Trigger display of validation summary and scroll to it
                            actionDeferred.reject();
                        } else {
                            $rootScope.$broadcast('andDisableValidationSummary'); // ANDIE Disable validation summary
                            actionDeferred.resolve();
                            flowModel.next(_model, args);
                        }
                        scope.actionPromise = undefined;
                    }, formCtrl);
                } else {
                    flowModel.next(model);
                }
            };
            scope.goToPrevious = () => {
                if (scope.previous) {
                    const actionDeferred = $q.defer();
                    scope.actionPromise = actionDeferred.promise;
                    scope.previous((_model, args) => {
                        actionDeferred.resolve();
                        flowModel.previous(_model, args);
                    }, formCtrl);
                } else {
                    flowModel.previous(model);
                }
            };
            scope.goToCancel = () => {
                if (scope.cancel) {
                    const actionDeferred = $q.defer();
                    scope.actionPromise = actionDeferred.promise;
                    scope.cancel((_model, args) => {
                        if (!_model) {
                            actionDeferred.reject();
                        } else {
                            actionDeferred.resolve();
                            args = args || {};
                            args.visitedSteps = flowModel.visitedSteps.map(step => step.name);
                            flowModel.cancel(_model, args);
                        }
                        scope.actionPromise = undefined;
                    }, formCtrl);
                } else {
                    const args = {};
                    args.visitedSteps = flowModel.visitedSteps.map(step => step.name);
                    flowModel.cancel(model, args);
                }
            };
            scope.undo = () => {
               flowModel.jumpToStep('pmDriverSummary', null, null, true);
            };
        }
    };
}];
