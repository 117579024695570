import ComponentUtil from 'gw-portals-util-js/ComponentUtil';
import maxAgeLimitModal from 'text!../views/max-age-limit-popup-modal.html';
import moment from 'moment';

let submissionVm;

class PAContactDetailsCtrl {

    constructor($scope, submissionViewModel, brandingData, PolicyService, SessionService_AND, ModalService) {
        submissionVm = submissionViewModel();
        $scope.dealerRecords = null;
        this.$scope = $scope;
        this._setCtrlParam = ComponentUtil.setCtrlParam(this, $scope);
        $scope.brand = brandingData.toLowerCase();
        this.SessionService_AND = SessionService_AND;
        const maxAge = 80;
        const minAgeToDrive = 17;
        $scope.isWithinAgeRange = true;
        var ageDifference = null;
        $scope.dealerVM = {
            value: null,
            aspects: null,
        };

        const urlParams = new URLSearchParams(window.location.search); 
        if (urlParams.has('referrer') && urlParams.get('referrer') === 'drivehome') {
            submissionVm.baseData.driveHomeEligible_itb.value = true;
        }        
        
        this._init();
        $scope.clubCardNumberTooltip = {
            content: 'and.quoteandbind.pm.views.pm-contact-details.tooltip.ClubCard Number',
            trigger: 'click'
        };

        $scope.isBeforeANDUKStartDate = () => {
            return moment().isBefore(moment('20201201').startOf('day'));
        };

        if (submissionVm.bindData.accountNumber && submissionVm.bindData.autoRenew_itb.value === undefined) {
            $scope.autoRenew_itb = submissionVm.bindData.autoRenew_itb;
            $scope.autoRenew_itb.value = false; 
        } 

        $scope.contactPhone = submissionVm.bindData.contactPhone;
        $scope.boxDeclaration = submissionVm.bindData.boxDeclaration_itb; 
        if ($scope.contactPhone !== undefined) {
            if ($scope.contactPhone.value !== null && !$scope.contactPhone.aspects.valid) {
                $scope.contactPhone.value = '07000000000';
            }
        }

        if ($scope.boxDeclaration !== undefined) {
            if ($scope.boxDeclaration.value !== null && !$scope.boxDeclaration.aspects.valid) {
                $scope.boxDeclaration.value = true;
            }
        }
       
        $scope.$watch(() => submissionVm.baseData.activeDealerCode.value, (newValue) => {
            if (newValue && submissionVm.baseData.driveHomeEligible_itb.value) {
                $scope.showDealerRenewalDate = true; 
            } else {
                $scope.showDealerRenewalDate = false; 
            }
        });

        $scope.$watch(() => $scope.dealerVM.value, (newValue) => {
            if (newValue != undefined) {
                submissionVm.baseData.activeDealerCode.value = newValue.centerNumber;
            }
        });
        
        $scope.$watch(() => submissionVm.baseData.kwikFitStaffMember.value, (newValue) => {
            if (newValue != true) {
                submissionVm.baseData.kfistaffNumber_itb.value = "";
            }
        });
        
        $scope.$watch(() => submissionVm.baseData.kwikFitDiscount.value, (newValue) => {
            if (newValue != true) {
                submissionVm.baseData.kfidiscountCode_itb.value = "";
            }
        });

        $scope.$watch(() => submissionVm.baseData.periodStartDate.value, (newValue) => {
            if(newValue && ($scope.brand === 'itb' || $scope.brand === 'kfi')) {
                var periodStart = moment([newValue.year, newValue.month, newValue.day]);
                var dob = moment([submissionVm.baseData.accountHolder.dateOfBirth.year.value, submissionVm.baseData.accountHolder.dateOfBirth.month.value, submissionVm.baseData.accountHolder.dateOfBirth.day.value]);
                showMaxAgeModal(periodStart, dob);
            }
        });

        $scope.$watch(() => submissionVm.baseData.accountHolder.dateOfBirth.value, (newValue) => {
            if(newValue && ($scope.brand === 'itb' || $scope.brand === 'kfi')) {
                var periodStart = moment([submissionVm.baseData.periodStartDate.year.value, submissionVm.baseData.periodStartDate.month.value, submissionVm.baseData.periodStartDate.day.value]);
                var dob = moment([newValue.year, newValue.month, newValue.day]);
                showMaxAgeModal(periodStart, dob);
            }
        });

        const showMaxAgeModal = (periodStart, dateOfBirth) => {
            ageDifference = periodStart.diff(dateOfBirth, 'years');
            $scope.isWithinAgeRange = (ageDifference >= minAgeToDrive && ageDifference <= maxAge);
            if(!$scope.isWithinAgeRange) {
                ModalService.showSimpleDialog(maxAgeLimitModal, {
                  maxAge: maxAge,
                  isPH: true
                }, {}, null, {
                  fullscreen: true,
                  backdrop: true,
                  windowClass: 'and-modal-max-age'
                });
            }
        };

        /*  Where Did You Head About Us options */
        $scope.WhereDidYouHearAboutUsOptions = () => {
            if ($scope.productCode === 'TraditionalMotor' || $scope.productCode === 'CommercialVehicle') {
                this.$scope.wdyhauTypeLists = submissionVm.baseData.marketingAnswer_itb.aspects.availableValues[0].typelist.filters;
                if (brandingData === 'toy') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'toyOptions';
                    });
                }
                if (brandingData === 'lex') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'lexOptions';
                    });
                }
                if (brandingData === 'aut') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'autOptions';
                    });
                }
                if (brandingData === 'kin') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'kinOptions';
                    });
                }
                if (brandingData === 'kfi') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'kfiOptions';
                    });
                }
                else {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'all';
                    });
                }
            }
        };

        $scope.showCenterCode = true;
        const dealerList = [];
        PolicyService.getActiveDealerRecords(brandingData)
            .then((records) => {
                records.forEach(record => {
                    dealerList.push(record);
                });  
                $scope.dealerRecords = dealerList.sort((a, b) => {
                    // convert to lowercase so we dont get caught out with capitals
                    return a.dealerName.toLowerCase().localeCompare(b.dealerName.toLowerCase());
                });
                $scope.dealerVM.aspects = $scope.dealerRecords;
                if (submissionVm.baseData.activeDealerCode.value != null) {
                    if ($scope.dealerRecords != null) {
                        const dealerSelected = $scope.dealerRecords.find(dealer => dealer.centerNumber === submissionVm.baseData.activeDealerCode.value);
                        if (dealerSelected) {
                            $scope.activeDealerCodeSelected(dealerSelected);
                        }
                    }
                }
            });

        if ($scope.brand === 'tbb' || $scope.brand === 'itb') {
            PolicyService.getRetiredBrandRedirectDatesITB_AND()
                .then((scriptDate) => {
                    const brandDateObject = scriptDate.find(obj => obj.hasOwnProperty(brandingData));
                    if (brandDateObject) {
                        const brandDate = moment(brandDateObject[brandingData]).startOf('day');
                        const brandDateOneDayEarlier = moment(brandDateObject[brandingData]).startOf('day').subtract(1, 'days');
                        const dropdownMaxDate = submissionVm.value.getMaxPeriodStartDate_AND() ? moment(submissionVm.value.getMaxPeriodStartDate_AND()).startOf('day') : moment().startOf('day');
                        if (dropdownMaxDate.isSameOrAfter(brandDate)) {
                            this.maxPeriodStartDate = { year: brandDateOneDayEarlier.year(), month: brandDateOneDayEarlier.month(), day: brandDateOneDayEarlier.date() };
                        } else {
                            this.maxPeriodStartDate = submissionVm.value.getMaxPeriodStartDate_AND();
                        }
                    }
                });
        }

        $scope.activeDealerCodeSelected = function (code) {
            $scope.dealerVM.value = code;
        };

    }

    _init() {
        this.SessionService_AND.deactivateFunctionTimer();
        this.baseDataViewModel = submissionVm.baseData;
        if (!this.baseDataViewModel.policyAddress.value) {
            this.baseDataViewModel.policyAddress.value = {};
        }

        this.minPeriodStartDate = submissionVm.value.getMinPeriodStartDate_AND();
        this.maxPeriodStartDate = submissionVm.value.getMaxPeriodStartDate_AND();
        this.maxRenewalPeriodStartDate = submissionVm.value.getMaxPeriodRenewalStartDate_AND();

        const accountHolder = this.baseDataViewModel.accountHolder;
        this.next = function (callback) {
            // If there are drivers, copy account holder data from this page onto the PH Driver
            if (submissionVm.lobData.personalMotor.coverables.pmDrivers && submissionVm.lobData.personalMotor.coverables.pmDrivers.length > 0) {
                const accountHolderDriver = submissionVm.lobData.personalMotor.coverables.pmDrivers.value.filter((driver) => driver.isAccountHolder === true)[0];
                accountHolderDriver.copyFromAccountHolder(accountHolder.value);
            }
            callback(submissionVm);
        };
    }

}

PAContactDetailsCtrl.$inject = ['$scope', 'submissionViewModel', 'brandingData', 'qnb.PolicyService', 'SessionService_AND', 'ModalService'];

export default PAContactDetailsCtrl;