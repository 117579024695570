import _ from 'lodash';

import DataItem from './DataItem';

export default class PropertyInfo {
    constructor(properetyInfoDescriptorObj, schedule) {
        this._descriptor = properetyInfoDescriptorObj;
        this._schedule = schedule;
    }

    isRequired() {
        return this._descriptor.required;
    }

    isUnique() {
        return this._descriptor.isUnique;
    }

    isEditable() {
        return this._descriptor.editable;
    }

    getName() {
        return this._descriptor.id;
    }

    getLabel() {
        return this._descriptor.label;
    }

    getType() {
        return this._descriptor.valueType;
    }

    getOrder() {
        return this._descriptor.order;
    }

    getSchedule() {
        return this._schedule;
    }

    getCurrency() {
        return this._descriptor.currency;
    }

    getAvailableValues() {
        return this._descriptor.availableTypeListValues;
    }

    getDisplayKeyFromAvailableValuesByCode(code) {
        if (!code) {
            return '';
        }

        return _.find(this.getAvailableValues(), val => val.code === code).displayKey;
    }

    checkDataItemIsUniqueInSchedule(dataItem) {
        return this.getSchedule().dataItemIsUniqueInSchedule(dataItem);
    }

    getDefaultValue(onItemChange) {
        const defaultDescriptor = this._descriptor.defaultValue ? _.cloneDeep(this._descriptor.defaultValue) : {[DataItem.typeToPropertyName(this.getType())]: undefined};
        return new DataItem(defaultDescriptor, this, onItemChange);
    }
}