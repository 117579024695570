/**
 * Directive to provide segmentation based on the geolocation data
 */

import _SegmentationUtil from './../_SegmentationUtil';
import assert from 'assert';

export default [() => {
    // INIT
    return {
        restrict: 'E',
        scope: {
            compareBy: '@'
        },
        controller: ['$element', '$scope', 'gwLocationSegmentationService', function ($element, $scope, LocationSegmentationService) {
            // check attributes
            assert(
                ['country', 'countryCode', 'state', 'city'].includes($scope.compareBy),
                [`Unexpected 'compareBy' attribute value: "${$scope.compareBy}". It must be: "country|countryCode|state|city"`]
            );

            const locationDefer = LocationSegmentationService.getSegmentationData($scope.compareBy);

            // return the standard segmentation directive and apply the location data to it
            return _SegmentationUtil($element, locationDefer);
        }]
    };
}];