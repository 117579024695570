import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import 'conditional?if=policycommon_lob&equals=pa!edge/policycommon/pa/app';
import 'conditional?if=policycommon_lob&equals=ho!edge/policycommon/ho/app';

const lobModules = [];

if (!ApplicationFeatures.hasFeature('role.vendor')) {
    if (ApplicationFeatures.hasFeature('policycommon_lob.pa')) {
        lobModules.push('edge.policycommon.pa.app');
    }
    if (ApplicationFeatures.hasFeature('policycommon_lob.ho')) {
        lobModules.push('edge.policycommon.ho.app');
    }
}

export default lobModules;