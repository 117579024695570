import SupportedLobModules from 'integration/quoteandbind/SupportedLobModules';
import 'edge/quoteandbind/quickquote/app';
import 'edge/policyjob/app';
import 'edge/policycommon/app';
import 'edge/platform/navigation/module';
import 'gw-portals-cp-components-angular';

const qnbDependencies = SupportedLobModules;

qnbDependencies.push('edge.quoteandbind.quickquote.app');
qnbDependencies.push('edge.policyjob.app');
qnbDependencies.push('edge.policycommon.app');
qnbDependencies.push('edge.platform.navigation');
qnbDependencies.push('edge.quoteandbind.quickquote.app');
qnbDependencies.push('gw-portals-cp-components-angular');

export default angular.module('edge.quoteandbind.app', qnbDependencies)
    .config(['EventHubProvider', (EventHubProvider) => {
        const quoteAndBindMethods = [
            'create',
            'retrieve',
            'saveAndQuote',
            'bind'
        ];

        EventHubProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'journey',
                eventName: 'submission'
            },
            transformer(payload, send) {
                if (payload && payload.success && (payload.path === 'quote') && quoteAndBindMethods.includes(payload.method)) {
                    const response = payload.response;
                    const baseData = response.baseData;
                    const newPayload = {
                        method: payload.method,
                        accountNumber: baseData.accountNumber,
                        quoteID: response.quoteID,
                        productCode: baseData.productCode

                    };
                    if (payload.method === 'bind') {
                        send(Object.assign(newPayload, {
                            policyNumber: response.bindData.policyNumber
                        }));
                    } else {
                        send(newPayload);
                    }
                }
            }
        });

        EventHubProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'tracking',
                eventName: 'journey'
            },
            transformer(payload, send) {
                if (payload.path === 'quote' && payload.success) {
                    if (payload.method === 'create') {
                        const response = payload.response;
                        const baseData = payload.response.baseData;
                        send({
                            eventName: 'Submission Created',
                            accountNumber: baseData.accountNumber,
                            jobID: response.quoteID,
                            productCode: baseData.productCode,
                            accountHolder: {
                                name: baseData.accountHolder.displayName,
                                address: baseData.accountHolder.primaryAddress.displayName,
                                email: baseData.accountHolder.emailAddress1
                            }
                        });
                    } else if (payload.method === 'retrieve') {
                        const response = payload.response;
                        const baseData = payload.response.baseData;
                        send({
                            eventName: 'Submission Retrieved',
                            accountNumber: baseData.accountNumber,
                            jobID: response.quoteID,
                            productCode: baseData.productCode
                        });
                    } else if (payload.method === 'saveAndQuote') {
                        const response = payload.response;
                        const baseData = response.baseData;
                        const quoteData = response.quoteData;
                        quoteData.offeredQuotes.forEach(q => {
                            if (!q.isCustom) {
                                send({
                                    eventName: 'Quote Showed',
                                    accountNumber: baseData.accountNumber,
                                    jobID: response.quoteID,
                                    productCode: baseData.productCode,
                                    branchCode: q.branchCode,
                                    value: q.total
                                });
                            }
                        });
                    } else if (payload.method === 'bind') {
                        const response = payload.response;
                        const baseData = response.baseData;
                        const bindData = response.bindData;
                        const quoteData = response.quoteData;
                        send({
                            eventName: 'Policy Bound',
                            accountNumber: baseData.accountNumber,
                            policyNumber: bindData.policyNumber,
                            jobID: response.quoteID,
                            paymentPlan: bindData.paymentPlans[0].name,
                            value: quoteData.offeredQuotes[0].premium.total
                        });
                    }
                } else if (payload.path === 'customquote' && payload.method === 'updateCustomQuote' && payload.success) {
                    const response = payload.response;
                    send({
                        eventName: 'Quote Showed',
                        jobID: response.quoteID,
                        branchCode: response.branchCode,
                        value: response.total
                    });
                }
            }
        });
    }]);
