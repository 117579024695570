import injectorDirectiveFactory from 'gw-portals-util-angular/injectorDirectiveFactory';

const loadContext = context => context.keys().map(key => context(key).default);
export default [
    injectorDirectiveFactory(
        'gwMobileScheduleListItem', {scheduledItem: '<', directive: '<'},
        loadContext(require.context('./subdirectives/list', true, /\.js$/)),
        ['$scope', $scope => $scope.directive]
    ),

    injectorDirectiveFactory(
        'gwMobileScheduleEditItem',
        {
            schedule: '<',
            scheduledItem: '<',
            directive: '<',
            shouldShowErrors: '<',
            onBeforeCommit: '<'
        },
        loadContext(require.context('./subdirectives/edit', true, /\.js$/)),
        ['$scope', $scope => $scope.directive]
    )
];
