import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./spreadsheetProcessorResult.html';
import controller from './spreadsheetProcessorResultCtrl';
import styles from './spreadsheetProcessorResult-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

/**
 * @ngdoc component
 * @name gw-spreadsheet-processor-result
 *
 * @description  A component to process the result of spreadsheet upload for CP and BOP buildings during policy transaction
 *
 */
export default {
    controller,
    template: hashedTemplate,
    transclude: true,
    bindings: {
        resultData: '<',
        processor: '<'
    }
};
