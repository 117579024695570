import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./MobileHeader.html';
import styles from './MobileHeader-hashed.scss';

export default () => ({
    restrict: 'E',
    transclude: true,
    replace: true,
    scope: {
        onBack: '&'
    },
    template: cssUtil.hashTemplate(template, styles)
});