import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './MobileCoverageSummary-hashed.scss';
import templateStr from 'text!./mobile-coverage-summary.html';

export default [
    () => {
        return {
            restrict: 'E',
            scope: {
                offering: '&',
                coverageType: '@',
                lobCode: '&'
            },
            template: cssUtil.hashTemplate(templateStr, styles),
            controller: ['$scope', ($scope) => {
                $scope.getCoverages = () => $scope.offering()[$scope.coverageType];
            }]
        };
    }
];