import _ from 'lodash';
import MapUtil from 'gw-portals-util-js/MapUtil';
import PresentationFlavors from './_PresentationFlavors';
import appFeatures from 'effective-features!';

/* Parses a presentation metadata capability object. */
function parseCapability(capabilityMetadata, name) {
    const capability = {
        name
    };
    const flavors = PresentationFlavors.createFlavors(capabilityMetadata);
    capability.flavors = flavors;

    capability.get = (flavor) => {
        const resFlavor = flavors.get(flavor);
        if (!resFlavor) {
            throw new Error(`No presentation metadata for flavor ${flavor} is defined in capability ${name}`);
        }
        return resFlavor;
    };

    return Object.freeze(capability);
}

function fetchPresentationMetadata() {
    const presentationMetadataURI = appFeatures.presentationMetadataURI;
    if (!presentationMetadataURI) {
        throw Error('presentationMetadataURI is not defined');
    }

    return fetch(presentationMetadataURI)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Unable to retrieve presentation Metadata.');
            }
            return response.json();
        })
        .then(json => json)
        .catch(console.error);
}

export default {
    /**
     * Parses presentation metadata information.
     * The returned object has following properties and functions:
     * <dl>
     *     <dt>capabilities</dt><dd>List of all capability presentation metadata defined in the configuration.<dd>
     *     <dt>get(path)</dt><dd>Returns capability metadata. Throws error if presentation metadata is not defined.</dd>
     * </dl>
     *
     * @returns {Object}
     */
    createPresentationMetadata: () => {

        const fetchedPresentationMetadata = fetchPresentationMetadata();

        return {
            'capabilities': () => {
                return fetchedPresentationMetadata.then(presentationMetadata => {
                    const map = _.mapValues(presentationMetadata, parseCapability);
                    return _.values(map);
                });
            },
            'get': (capability) => {
                return fetchedPresentationMetadata.then(presentationMetadata => {
                    const map = _.mapValues(presentationMetadata, parseCapability);
                    const res = MapUtil.get(map, capability);
                    if (!res) {
                        throw new Error(`No presentation metadata for capability ${capability} is defined`);
                    }
                    return res;
                });
            }
        };
    }
};