class QuoteCtrl {

    static get $inject() {
        return ['StateUtils', 'ViewModelService', '$q', 'ModalService', '$state', 'paymentType', 'UserInfoService'];
    }

    constructor(StateUtils, ViewModelService, $q, ModalService, $state, paymentType, UserInfoService) {
        this.ModalService = ModalService;
        this.$q = $q;
        this.$state = $state;
        this.paymentType = paymentType;
        this.next = this.next.bind(this);
        this.cancel = this.cancel.bind(this);
        this.StateUtils = StateUtils;
        this.UserInfoService = UserInfoService;
    }

    $onInit() {
        this.model = this.StateUtils.activeDataForState(true).model;
        this.flowModel = this.StateUtils.activeDataForState(true).flowModel;
        const quoteData = this.model.value.quoteData.offeredQuotes[0].premium;

        this.effectiveDate = this.model.value.baseData.effectiveDate;
        this.startDate = this.model.value.baseData.periodStartDate;
        this.endDate = this.model.value.baseData.periodEndDate;
        this.newPremium = quoteData.total;
        this.paymentPlans = this.model.value.bindData.paymentPlans;
        this.policyNumber = this.model.value.policyNumber;
        this.multiplePaymentPlans = this.paymentPlans.length !== 1;
        this.paymentPlanDescription = this.getPaymentPlanDescription();
        this.isAgent = this.UserInfoService.isAgent();
        this.jobType = this.model.value.baseData.jobType;
        this.productName = this.model.value.baseData.productName;
    }

    getPaymentPlanDescription() {
        if (!this.model.bindData.selectedPaymentPlan) {
            return undefined;
        }
        // ANDIE Changed selectedPaymentPlan from String to PaymentPlanDto
        const paymentPlan = this.paymentPlans.find(p => p.billingId === this.model.bindData.selectedPaymentPlan.billingId.value);
        return paymentPlan ? paymentPlan.name : undefined;
    }

    editPaymentPlans() {
        this.editingPaymentPlans = true;
    }

    isWire() {
        return this.model.bindData.paymentDetails.paymentMethod.value === this.paymentType.WIRE;
    }

    isCreditCard() {
        return this.model.bindData.paymentDetails.paymentMethod.value === this.paymentType.CREDIT_CARD;
    }

    updatePaymentDetails() {
        switch (this.model.value.bindData.paymentDetails.paymentMethod) {
            case this.paymentType.WIRE:
                delete this.model.value.bindData.paymentDetails.creditCardData;
                break;
            case this.paymentType.CREDIT_CARD:
                delete this.model.value.bindData.paymentDetails.bankAccountData;
                break;
            case this.paymentType.REDISTRIBUTE:
                delete this.model.value.bindData.paymentDetails.creditCardData;
                delete this.model.value.bindData.paymentDetails.bankAccountData;
                break;
            default:
                delete this.model.value.bindData.paymentDetails.creditCardData;
                delete this.model.value.bindData.paymentDetails.bankAccountData;
        }
    }

    next(nextFn, form) {
        if (!this.model.bindData.selectedPaymentPlan.value) {
            this.ModalService.showError(
                'quoteandbind.controllers.PaymentDetailsCtrl.Please choose a payment plan',
                'quoteandbind.controllers.PaymentDetailsCtrl.You have not chosen a payment plan'
            );

            return nextFn();
        }
        if (form.invalid) {
            return nextFn();
        }
        this.updatePaymentDetails();
        nextFn(this.model);
    }

    openReferToUnderwriterForm(form) {
        form.submitted = true;
        if (form.invalid) {
            return;
        }
        this.referingToUnderwriter = true;
    }

    closeReferToUnderwriterForm() {
        this.note = '';
        this.referingToUnderwriter = false;
    }

    referToUnderwriter() {
        this.updatePaymentDetails();
        const note = {value: this.note};
        this.flowModel.next(this.model, [note]);
    }

    cancel(cancelFn) {
        const modelEdited = angular.copy(this.model.value);
        delete modelEdited.bindData.paymentDetails;
        cancelFn(this.model, {modelEdited});
    }
}

export default QuoteCtrl;