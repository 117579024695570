export default function (subFlows) {

    return function (step, junction) {

        // start with the availability check if not in agent quote
        junction(subFlows.availability.entry, true);

        subFlows.availability.exits.productAvailable.onNext.goTo(subFlows.submissionExists.entry);
    };
}
