import _ from 'lodash';

/**
 * Function will invalidate and update the cached transformed coverages
 * @public
 */
function updateModel() {
    if (this.offerings.length > 0) {
        this.additionalCoverageArray = _transformVehicleCoveragesToArray(this.offerings);
    }
}

/**
 * Transforms the submission offerings from a complex object to another object for simpler
 * @param {Array} offerings - array of submissions LOB offerings
 * @returns {Object} - Object where each value is a vehicle. Each vehicle is an object of coverages types. Where each
 *                  coverage type is array of coverages (of the same type) sorted by offering
 * @private
 */
function _transformVehicleCoveragesToArray(offerings) {
    /*
     For each vehicle the branch name and vehicle fixedID is added to each coverage and deep flattened to an array.
     Sort the resulting array by branch and then group by vehicles and then then by coverage.
     The end result is an object with each key as a vehicle ID. The value for each key is another object where each key
     is a coverage ID. Each value of this is an array of coverages where the elements are sorted by their branch.
     Eg. {
     1001: {
     PAVehicleCov1: [ VehicleCov1Branch1, VehicleCov1Branch2, VehicleCov1Branch3],
     PAVehicleCov2: [ VehicleCov2Branch1, VehicleCov2Branch2, VehicleCov2Branch3],
     }
     }
     */
    var transformedOfferings = _(offerings).map(function (offering) {
        return offering.coverages.pmVehicleCoverages.map(function (vehicle) {
            vehicle.coverages.forEach(function (coverage) {
                coverage.branch = offering.branchCode;
                coverage.vehicleFixedId = vehicle.fixedId;
            });
            return vehicle.coverages;
        });
    }).flattenDeep();
    return transformedOfferings.groupBy(function (item) {
        return item.vehicleFixedId;
    }).mapValues(function (vehicle) {
        return _(vehicle).groupBy(function (coverage) {
            return coverage.publicID;
        }).value();
    }).value();
}

export default {
    updateModel: updateModel
};