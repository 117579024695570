import _ from 'underscore';
import flowConfigUtil from 'edge/quoteandbind/common/util/FlowConfigUtil';

export default (AvailabilityService, LoadSaveService, $q, TranslateService, ViewModelService, ViewModelCtx) => {
    const translateFn = TranslateService.instant;
    const wrappedLoadSave = flowConfigUtil.wrappedLoadSave(ViewModelService, ViewModelCtx);

    const ACTION_MSG = {
        saveSubmission: {
            error: {
                title: 'quoteandbind.common.flow.error.Error',
                message: 'quoteandbind.common.flow.error.An error has occurred...'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Saving quote...'
            }
        }
    };

    const ACTIONS = {
        saveDraftSubmission: ({value: submission}) => {
            return wrappedLoadSave(LoadSaveService.updateDraftSubmissionAndPrimaryLocation, submission);
        }
    };

    return {

        contactDetails: (step, junction) => {
            step('bopContactDetails', true)
                .onNext.branch((model) => {
                    return model.quoteID.value ? 'bopPostContactDetails' : 'bopContactDetailsComplete';
                })
                .skipOnResumeIf(({
                    value: submission
                }) => submission.persons[0] && submission.persons[0].emailAddress1)
                .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.wizard.step.Your Info'));

            junction('bopPostContactDetails')
                .onNext.doAction(ACTIONS.saveDraftSubmission, ACTION_MSG.saveSubmission, {forceUpdate: true})
                .thenGoTo('bopContactDetailsComplete', 'error');

            junction('bopContactDetailsComplete');

        },
        coverables: (step, junction) => {
            step('bopPrequalQuestions', true)
                .onNext.doAction(ACTIONS.saveDraftSubmission, ACTION_MSG.saveSubmission).thenGoTo('bopGeneralCoverages', 'error')
                .skipOnResumeIf(({value: submission}, {gatewayportalnewsubmission}) => {
                    if (gatewayportalnewsubmission) {
                        return false;
                    }

                    if (submission.lobData.businessOwners && submission.lobData.businessOwners.preQualQuestionSets) {
                        const answerValues = _.values(submission.lobData.businessOwners.preQualQuestionSets[0].answers);
                        return answerValues.some(value => value !== null);
                    }

                    return false;
                })
                .isValid((model, formValid) => model.aspects.valid && model.aspects.subtreeValid && formValid)
                .label(() => translateFn('quoteandbind.wizard.step.Qualification'));

            step('bopGeneralCoverages')
                .onNext.goTo('bopAdditionalCoverages', 'error')
                .skipOnResumeIf(({
                    value: submission
                }) => {
                    const coverages = submission.lobData.businessOwners.coverages.lobCoverages;
                    return submission.baseData.periodStatus === 'Quoted' ||
                        (coverages && !_.findWhere(coverages, {isValid: false}));
                })
                .isValid((model) => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.bop.wizard.step.General Coverages'));

            step('bopAdditionalCoverages')
                .onNext.goTo('bopLocationsAndBuildings', 'error')
                .skipOnResumeIf(({
                    value: submission
                }) => {
                    const coverages = submission.lobData.businessOwners.coverages.additionalCoverages;
                    return submission.baseData.periodStatus === 'Quoted' ||
                        (coverages && !_.findWhere(coverages, {isValid: false}));
                })
                .isValid((model) => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.bop.wizard.step.Additional Coverages'));

            step('bopLocationsAndBuildings')
                .onNext.goTo('bopCoverablesComplete')
                .skipOnResumeIf(({value: submission}) => submission.baseData.periodStatus === 'Quoted')
                .label(() => translateFn('quoteandbind.bop.wizard.step.Locations and Buildings'));

            junction('bopCoverablesComplete');
        },
        moreInfo: (step, junction) => {
            step('bopPolicyInfo', true)
                .skipOnResumeIf(({
                    value: submission
                }) => {
                    return submission.baseData &&
                        submission.baseData.accountHolder &&
                        submission.baseData.accountHolder.emailAddress1 && (
                        submission.baseData.accountHolder.homeNumber ||
                            submission.baseData.accountHolder.workNumber ||
                            submission.baseData.accountHolder.cellNumber
                    );
                })
                .onNext.goTo('bopMoreInfoComplete')
                .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.wizard.step.Policy Information'));

            junction('bopMoreInfoComplete');
        }
    };
};
