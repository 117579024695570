import templateStr from 'text!./pm-quote-review.html';

export default ['brandingData', '$translate', 'StateUtils', '$anchorScroll','qnb.PolicyService', (brandingData, $translate, StateUtils, $anchorScroll, PolicyService) => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '<'
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            const flowModel = StateUtils.activeDataForState(true).flowModel;
            $scope.brand = brandingData.toLowerCase();
            const BRAND_COVERAGES = $scope.quoteData.submission.lobData.personalMotor.value.BRAND_COVERAGES[brandingData];
            $scope.BRAND_COVERAGES = BRAND_COVERAGES;
            const BRAND_COVERAGES_ORDER = {
                [BRAND_COVERAGES.COURTESY_CAR]: 10,
                [BRAND_COVERAGES.KEY_COVER]: 20,
                [BRAND_COVERAGES.BREAKDOWN_COV]: 30
            };
            const COVERAGE_CODES = Object.keys(BRAND_COVERAGES)
                .map(e => BRAND_COVERAGES[e]); // IE doesn't support Object.values()
            $scope.phDriver = $scope.quoteData.submission.lobData.personalMotor.coverables.value.getPHDriver();
            $scope.phDriverVM = $scope.quoteData.submission.lobData.personalMotor.coverables.pmDrivers
                .findFirstElement($scope.phDriver);
            $scope.accountHolder = $scope.quoteData.submission.baseData.accountHolder;
            $scope.periodStartDate = $scope.quoteData.submission.value.getPSDLongDisplayName_AND();
            $scope.periodEndDate = $scope.quoteData.submission.value.getPEDLongDisplayName_AND();
            $scope.DefactoDateEvaluation = PolicyService.getDefactoDate_AND()
              .then((scriptDate) => {
                  $scope.isPolicyEffDateBeforeItbDefactoDate = $scope.quoteData.submission.value.isPolEffDateBeforeDefactoDate(scriptDate) && $scope.brand === 'itb';
            });

            // Vehicle
            $scope.vehicleVM = $scope.quoteData.submission.lobData.personalMotor.coverables.pmVehicles.children[0];
            $scope.vehicle = $scope.vehicleVM.value;
            if ($scope.vehicle.registrationNumber) {
                $scope.vehicleDisplayName = `${$scope.vehicle.registrationNumber} ${$scope.vehicle.make} ${$scope.vehicle.model}`;
            } else {
                $scope.vehicleDisplayName = `${$scope.vehicle.make} ${$scope.vehicle.model}`;
            }
            $scope.vehicleDateOfPurchase = `${$scope.vehicleVM.purchasedMonth.value.name} ${$scope.vehicle.purchasedYear}`;

            // Drivers
            $scope.driversVM = $scope.quoteData.submission.lobData.personalMotor.coverables.pmDrivers;
            $scope.driverSort = (driverVM) => {
                if (driverVM.mainDriver.value === true) {
                    return -1;
                }
                return driverVM.person.firstName.value;
            };

            // Address
            $scope.policyAddress = $scope.quoteData.submission.baseData.policyAddress.value;
            $scope.policyAddressLine1 = $scope.policyAddress.addressLine1;
            if ($scope.policyAddress.organisationName_itb) {
                $scope.policyAddressLine1 = `${$scope.policyAddress.organisationName_itb} ${$scope.policyAddressLine1}`;
            }
            if ($scope.policyAddress.departmentName_itb) {
                $scope.policyAddressLine1 = `${$scope.policyAddress.departmentName_itb} ${$scope.policyAddressLine1}`;
            }

            // Boolean values
            $scope.getBooleanDisplayName = (value) => {
                return value === true ?
                    $translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review-card.Yes') :
                    $translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review-card.No');
            };

            // Coverages, need to be functions to cope with changes in selection. Either that or implement a
            // watch in case performance takes a hit.
            $scope.isCourtesyCarSelected = () => !!$scope.quoteData.baseOffering.lobs.personalMotor.pmVehicleCoverages[0]
                .coverages.find(cov => cov.selected === true && cov.codeIdentifier === BRAND_COVERAGES.COURTESY_CAR);
            $scope.isKeysCoverSelected = () => !!$scope.quoteData.baseOffering.lobs.personalMotor.pmVehicleCoverages[0]
                .coverages.find(cov => cov.selected === true && cov.codeIdentifier === BRAND_COVERAGES.KEY_COVER);
            $scope.isBreakdownSelected = () => !!$scope.quoteData.baseOffering.lobs.personalMotor.pmVehicleCoverages[0]
                .coverages.find(cov => cov.selected === true && cov.codeIdentifier === BRAND_COVERAGES.BREAKDOWN_COV);

            $scope.getBreakdownChosenTerm = () => $scope.quoteData
                .getCoverageTerm(BRAND_COVERAGES.BREAKDOWN_COV, BRAND_COVERAGES.BREAKDOWN_COV_TERM, $scope.quoteData.baseOffering)
                .chosenTerm;

            $scope.getChosenMileage = () => $scope.quoteData.baseOffering.branchCode;
            $scope.getMilesPrice = () => $scope.quoteData.baseOffering.topUpMilesCost_itb;
            $scope.getChosenAddOns = () => $scope.quoteData.baseOffering.lobs.personalMotor.pmVehicleCoverages[0]
                .coverages.filter(cov => cov.selected === true && COVERAGE_CODES.includes(cov.codeIdentifier));

            $scope.getAddOnsSubtitle = () => {
                const chosenAddOns = $scope.getChosenAddOns();
                if (chosenAddOns.length === 0) {
                    return $translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review.AddOnsEmptySubtitle');
                }

                let translationKey = 'and.quoteandbind.pm.directives.templates.pm-quote-review.AddOns.Subtitle1Selected';
                if (chosenAddOns.length === 2) {
                    translationKey = 'and.quoteandbind.pm.directives.templates.pm-quote-review.AddOns.Subtitle2Selected';
                } else if (chosenAddOns.length === 3) {
                    translationKey = 'and.quoteandbind.pm.directives.templates.pm-quote-review.AddOns.Subtitle3Selected';
                }

                const params = {
                    array: [],
                    param1: '',
                    param2: '',
                    param3: ''
                };
                chosenAddOns.sort((a, b) => BRAND_COVERAGES_ORDER[a.codeIdentifier] - BRAND_COVERAGES_ORDER[b.codeIdentifier]);
                chosenAddOns.forEach((addOn) => {
                    switch (addOn.codeIdentifier) {
                        case BRAND_COVERAGES.COURTESY_CAR:
                            params.array.push($translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review.AddOns.CCC'));
                            break;
                        case BRAND_COVERAGES.KEY_COVER:
                            params.array.push($translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review.AddOns.Keys'));
                            break;
                        case BRAND_COVERAGES.BREAKDOWN_COV:
                            params.array.push($translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review.AddOns.Breakdown'));
                            break;
                        default: // Empty because the review only knows how to handle the above 3 coverages.
                    }
                });
                params.array.forEach((param, index) => {
                    params[`param${index + 1}`] = param;
                });

                return $translate.instant(translationKey, {
                    param1: params.param1,
                    param2: params.param2,
                    param3: params.param3
                });
            };

            $scope.getDriverSubtitle = () => {
                let translationKey;
                $scope.driversVM.length > 1 ?
                    translationKey = 'and.quoteandbind.pm.directives.templates.pm-quote-review.Drivers' :
                    translationKey = 'and.quoteandbind.pm.directives.templates.pm-quote-review.Driver';
                return `${$scope.driversVM.length} ${$translate.instant(translationKey)}`;
            };

            $scope.generalMods = false
            $scope.bodyWorkMods = false
            $scope.engineMods = false
            $scope.wheelsMods = false
            $scope.showGeneralMods = () => {
                if ($scope.generalMods){
                    $scope.generalMods = false
                } else {
                    $scope.generalMods = true
                }
            }

            $scope.showBodyWorkMods = () => {
                if ($scope.bodyWorkMods){
                    $scope.bodyWorkMods = false
                } else {
                    $scope.bodyWorkMods = true
                }
            }

            $scope.showEngineMods = () => {
                if ($scope.engineMods){
                    $scope.engineMods = false
                } else {
                    $scope.engineMods = true
                }
            }

            $scope.showWheelsMods = () => {
                if ($scope.wheelsMods){
                    $scope.wheelsMods = false
                } else {
                    $scope.wheelsMods = true
                }
            }

            $scope.generalModificatonStrings = $scope.vehicle.generalAfterMarketMods.map(mod => {
                return mod
            })
            $scope.bodyWorkModificatonStrings = $scope.vehicle.bodyWorkMods.map(mod => {
                return mod
            })
            $scope.engineModificatonStrings = $scope.vehicle.engineMods.map(mod => {
                return mod
            })
            $scope.wheelsModificatonStrings = $scope.vehicle.wheelsOrTyresMods.map(mod => {
                return mod
            })

            $scope.jumpToStep = (stepName) => flowModel.jumpToStep(stepName);
            $scope.jumpToSelection = (id) => $anchorScroll(id);
        }]
    };
}];