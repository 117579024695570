import templateStr from 'text!./templates/yes-no-group.html';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            label: '@',
            model: '=',
            inline: '=?',
            tooltip: '=?',
            readonly: '=?',
            disabled: '&',
            flavor: '@?'
        }
    };
}];
