const faq = {
    sections: []
};

function parseID(data) {
    const result = [];

    data.forEach(unit => {
        result.push(unit.id);

        if (unit.topics && unit.topics.length > 0) {
            unit.topics.forEach(topic => {
                result.push(topic.id);
            });
        }
    });

    return result;
}

function validateSections(sections) {
    const ids = parseID(sections);
    const idMap = {};
    const duplicates = [];

    ids.forEach(id => {
        if (idMap[id]) {
            duplicates.push(id);
        } else {
            idMap[id] = true;
        }
    });

    if (duplicates.length) {
        console.error(`FAQ section data contains duplicate id : ${duplicates}`);
        return false;
    }

    return true;
}

export default {
    $get: () => {
        return {
            getAllSections: () => {
                // Return a copy of the data to avoid modifications to the original
                const sections = JSON.parse(JSON.stringify(faq.sections));
                validateSections(sections);
                return sections;
            },
            getLinkContent: id => {
                const sectionContent = faq.sections
                    .filter(section => section.id === id)
                    .map(section => section.title);

                const topicContent = faq.sections.map(section => section.topics)
                    .reduce((flat, item) => flat.concat(item), [])
                    .filter(topic => topic.id === id)
                    .map(topic => topic.inlineQuestion);

                return sectionContent.concat(topicContent)[0];
            }
        };
    },

    setSections: sections => {
        faq.sections = sections;
    },

    validateSections
};
