import BaseCtrl from 'edge/platform/base/BaseCtrl';
import Submission from 'edge/quoteandbind/common/models/Submission';
import WizardUtil from '../util/WizardUtil_AND';
import queryString from 'query-string';
import CoverageUtil from '../util/CoverageUtil.js';
import moment from 'moment';

/**
 *
 * POST https://quotes.insurethebox.com/sites/rq/web/en_GB/itb/?providerName=confused
 * providerName=confused
 * calcRefId=<respective calcrefid>
 * postcode=<respective postcode>
 */

const fn = function (
    $scope, $state, $location, LoadSaveService, wizardFlowService, qnbCompositeFlowDefn, ViewModelService,
    viewModelContext, returnStateFn, errorStateFn, effectiveDateStateFn, brandingData, CustomQuoteService, PolicyService
) {
    const AGG_CODE = {
        MSM: 'MSM',
        CTM: 'CTM',
        GC: 'GC',
        US: 'US',
        CONFUSED: 'CONFUSED',
        QZ: 'QZ'
    };

    /**
     * Parses URI Encoded ui-router query string params (i.e. #/state-name?param1=value1)
     * @returns {any} Object with ui-router query string params
     */
    const getParams = () => {
        // 1. Take the location hash and clip it up to ui-router params
        //  e.g. location.hash => '#/agg-retrieval?guid=123&agg=ABC' => 'guid=123&agg=ABC'
        const params = window.location.hash.substring($location.path().length + 2); // +2 takes care of # and ?
        // 2. Decode URI chars and parse into an object
        return queryString.parse(decodeURIComponent(params));
    };

    const ctrl = Object.create(BaseCtrl);
    const parentWizardState = 'baseTransactionWizard';
    let brandDateObject = {};

    ctrl.init = function () {
        // Redirect retired brands based on end of sale date and brand
        $scope.brand = brandingData.toLowerCase();

        function processAggRetrieval() {
            // Temp logging to support with Agg Deeplinks issues
            console.log(`Agg Deeplink data:
                URL: ${$location.absUrl()} 
                calcRefId: ${window.aggQuoteID_AND}
                postcode: ${window.aggPostCode_AND}
                Breakdown: ${window.aggBreakdown_AND}
                Legal: ${window.aggLegal_AND}`);
            
            const quoteID = window.aggQuoteID_AND;
            const postCode = window.aggPostCode_AND;
            const Breakdown = window.aggBreakdown_AND;
            const Legal = window.aggLegal_AND;
            window.aggQuoteID_AND = undefined;
            window.aggPostCode_AND = undefined;
            window.aggBreakdown_AND = undefined;
            window.aggLegal_AND = undefined;
            const params = getParams();
            if (!quoteID || !postCode || !params.agg || !AGG_CODE[params.agg.toUpperCase()]) {
                return $state.go('contactUs'); // Display technical error screen if invalid params are passed
            }

            if ((Breakdown != null && Breakdown.trim() != '' ) || (Legal != null && Legal.trim() != '' ) ) {
                let paramNames = Object.keys(params);
                if(!paramNames.includes(Breakdown) && Breakdown != null) {
                    let BreakdownOption = Breakdown;
                    if(Breakdown.toLowerCase() === 'breakdown') {
                        BreakdownOption = 'Level2';
                    }
                    Object.assign(params, { Breakdown: BreakdownOption });
                }
                if(!paramNames.includes(Legal) && Legal != null) {
                    Object.assign(params, { Legal: Legal });
                }
            }
            
            $scope.AggCoverages = CoverageUtil.mapAggsCoverages(params, brandingData);

            $scope.aggCode = params.agg.toLowerCase();
            const stepToStateMapping = {
                returnHome: model => returnStateFn(model),
                confirmation: (model) => ['qnbconfirmation', {
                    quoteSummary: model.value,
                    returnStateInfo: returnStateFn(model.value)
                }],
                error: model => errorStateFn(model),
                effectiveDate: model => effectiveDateStateFn(model)
            };
            const flowfactory = wizardFlowService(qnbCompositeFlowDefn, parentWizardState, stepToStateMapping);

            ctrl.wizardSteps = WizardUtil.getWizardStepsReadonly();
            $scope.wizardSteps = ctrl.wizardSteps;

            const updateSubmission = (submission, submissionResponse) => {
                submission.quoteData.offeredQuotes = submissionResponse.quoteData.offeredQuotes;
                submission.quoteData = submissionResponse.quoteData;
                submission.lobData.personalMotor.offerings = submissionResponse.lobData.personalMotor.offerings;
                return submission;
            };

            /* Populates SubmissionDTO and resumes quote flow*/
            ctrl.populateSubmissionData = function (submissionData) {
                let submission = submissionData;

                function handleSubmissionUpdate() {
                    if ($scope.AggCoverages.length > 0) {
                        for (var cov in $scope.AggCoverages) {
                            if ($scope.AggCoverages[cov].SelectedTerm == null) {
                                submissionData = CoverageUtil.updateCoverageSelection(true, $scope.AggCoverages[cov].CoverageCode, submissionData, false);
                            } else {
                                submissionData = CoverageUtil.updateCoverageTermSelection($scope.AggCoverages[cov].SelectedTerm, $scope.AggCoverages[cov].CoverageCode, $scope.AggCoverages[cov].TermCode, submissionData, false);
                            }
                        }
                        CoverageUtil.updateQuote(submissionData, CustomQuoteService)
                            .then((submissionResponse) => { 
                                submission = submissionResponse; 
                                const submissionVM = ViewModelService.create(new Submission(submission), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', viewModelContext);
                                flowfactory.resume(submissionVM);
                            })
                            .catch(() => $state.go('contactUs'));
                    } else {
                        const submissionVM = ViewModelService.create(new Submission(submission), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', viewModelContext);
                        flowfactory.resume(submissionVM);
                    }
                }

                if ($scope.brand === 'itb' || $scope.brand === 'tbb') {
                    if (brandDateObject && Object.keys(brandDateObject).length > 0) {
                        const brandRetireWindowEnd = moment(brandDateObject[brandingData]).startOf('day');
                        const policyStartDate = moment(submission.baseData.periodStartDate).startOf('day');
                        if (policyStartDate.isBefore(brandRetireWindowEnd)) {
                            sessionStorage.setItem('brandAllowed', true);
                            handleSubmissionUpdate();
                        } else {
                            window.location.replace(`/quoteandbuy/app/html/${brandingData}-thankyou.html`);
                        } 
                    }
                } else {
                    handleSubmissionUpdate();
                }
            };

            LoadSaveService.retrieveAggsSubmission_AND({quoteID: quoteID, postCode: postCode, aggregator: params.agg.toUpperCase()})
                .then(ctrl.populateSubmissionData)
                .catch((error) => {
                    error = error[0];
                    if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                        switch (error.baseError.error.data.appErrorCode) {
                            case 602: // GW_UNDERWRITING_ERROR
                            case 631: // AND_HIGHRISK_VALIDATION_ERROR
                                $state.go('uwError_AND');
                                break;
                            case 608: // GW_ENTITY_VALIDATION_ERROR
                            case 611: // GW_BLOCK_QUOTE_UNDERWRITING_ERROR
                                $state.go('quoteDeclined_AND', {appErrorCode_AND: error.baseError.error.data.appErrorCode});
                                break;
                            case 624: // AND_CUE_ENTITY_VALIDATION_ERROR
                                $state.go('quoteDeclinedCue_AND');
                                break;
                            default: // Technical error
                                $state.go('contactUs');
                        }
                    } else if (error && error.baseError && error.baseError.error && !error.baseError.error.data && error.baseError.error.message) {
                        switch (error.baseError.error.code) {
                            case -32603: // "ErrorCode: -32603-INTERNAL_ERROR ErrorMessage: This product is no longer available."
                                window.location.replace(`/quoteandbuy/app/html/${brandingData}-thankyou.html`);
                                break;
                            default: // Technical error
                                $state.go('contactUs');
                        }
                    } else { // Technical error
                        $state.go('contactUs');
                    }
                });

        }

        if ($scope.brand === 'itb' || $scope.brand === 'tbb') {
            PolicyService.getRetiredBrandRedirectDatesITB_AND()
                .then((scriptDate) => {
                    brandDateObject = scriptDate.find(obj => obj.hasOwnProperty(brandingData));
                    if (brandDateObject) {
                        const brandRetireWindowEnd = moment(brandDateObject[brandingData]).startOf('day');
                        const currentDate = moment().startOf('day');
                        if (currentDate.isSameOrAfter(brandRetireWindowEnd)) {
                            window.location.replace(`/quoteandbuy/app/html/${brandingData}-thankyou.html`);
                        } else {
                            sessionStorage.setItem('brandAllowed', true);
                        }
                    }
                }).then(processAggRetrieval);
        } else {
            processAggRetrieval();
        }
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$location', 'qnb.LoadSaveService', 'WizardFlowService',
    'qnb.CompositeFlowDefn', 'ViewModelService', 'qnb.ViewModelCtx', 'qnb.returnStateFn',
    'qnb.errorStateFn', 'qnb.effectiveDateStateFn', 'brandingData', 'qnb.CustomQuoteService',
    'qnb.PolicyService'];

export default fn;