import _ from 'lodash';
import cssUtil from 'gw-portals-util-js/CssUtil';

import styles from './typeahead-hashed.scss';
import typeaheadTemplate from 'text!./templates/typeahead.html';
import {DefaultMatchDirective} from './matchContentDirectives/Injector';

const DEFAULT_DEBOUNCE_MS = 50;
export default ['$q', '$timeout', ($q, $timeout) => {
    const debounce = function (fn, debounceTime) {
        let prevDeferred;
        let timeoutPromise;
        return function (...args) {
            if (timeoutPromise) {
                $timeout.cancel(timeoutPromise);
            }

            if (prevDeferred) {
                prevDeferred.resolve([]); // Don't leave memory leaks
            }

            const deferred = $q.defer();
            prevDeferred = deferred;

            timeoutPromise = $timeout(() => {
                $q.resolve(fn.apply(this, args)).then(
                    result => deferred.resolve(result),
                    err => deferred.reject(err)
                );
            }, debounceTime);

            return deferred.promise;
        };
    };

    return {
        restrict: 'E',
        template: cssUtil.hashTemplate(typeaheadTemplate, styles),
        controller: ['$scope', '$element', function ($scope, $element) {
            // Controller exists to pass stuff to gw-typeahead-hook.
            // gw-typeahead-hook requires gw-typeahead, so the controller becomes available.
            this._getMatches = function (...args) {
                return $scope._getMatches(...args);
            };

            this._toString = function (...args) {
                return $scope._toString(...args);
            };

            this._selectDirective = function () {
                return $scope._selectDirective();
            };

            this._getElement = function () {
                return $element;
            };

            this._setLoading = function (loading) {
                $scope.$applyAsync(`_loading = ${String(Boolean(loading))}`);
            };

            this._setOpen = function (open) {
                $scope.$applyAsync(`_open = ${String(Boolean(open))}`);
            };

            this.isEditable = function () {
                return $scope.editable;
            };
        }],
        scope: {
            result: '=',
            editable: '<',
            definition: '<',
            placeholder: '@',
            debounceMs: '@'
        },
        link: ($scope) => {
            $scope.wipe = () => {
                $scope.selectedItem = '';
            };

            $scope.$watch('definition', definition => {
                if (!definition) {
                    // Do not set anything up if there's no definition
                    return;
                }

                $scope._selectDirective = () => definition.directive || DefaultMatchDirective;

                const knownResults = new Map();
                const updateResults = results => results.forEach(result => knownResults.set($scope._toString(result), result));

                const debounceMs = parseFloat($scope.debounceMs) || DEFAULT_DEBOUNCE_MS;

                $scope._getMatches = debounce(_.memoize(userinput => { // Memoize is there to not make extra requests for same userinput
                    if (knownResults.has(userinput)) {
                        return [knownResults.get(userinput)]; // Userinput matches one of the previously received values, no need to make any extra requests;
                    }

                    const results = definition.getMatches(userinput);
                    $q.resolve(results).then(updateResults);

                    return results;
                }), debounceMs);

                $scope._toString = item => {
                    if (!item) {
                        return '';
                    }

                    if (typeof item === 'string') {
                        return item;
                    }

                    return definition.toString(item);
                };

                $scope.selectedItem = $scope._toString($scope.result);
                $scope.$watch('selectedItem', selectedItem => {
                    if (selectedItem !== $scope._toString($scope.result)) {
                        // In some cases (e.g. initial render), we might receive a value matching the current item.
                        // Watch might be triggered, because toString(item) may be different from item depending on the typeahead definition.
                        // So this check safeguards against backpropagating string value where we have a perfectly suitable model.
                        $scope.result = selectedItem;
                    }
                });
            });

            $scope.$watch('result', result => {
                if (result !== undefined) {
                    $scope.selectedItem = $scope._toString(result);
                }
            });
        }
    };
}];
