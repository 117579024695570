import WizardFlowService from './WizardFlowService';
import 'edge/platform/widgets/modal/module';
import 'angularRouter';
import 'gw-portals-util-angular';
import 'l10nModule';

export default angular.module('edge.platform.flow', [
    'edge.platform.widgets.modal',
    'ui.router',
    'edge.platform.util',
    'edge.platform.l10n'
])
    .factory('WizardFlowService', WizardFlowService);
