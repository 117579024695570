import template from 'text!./templates/Textarea.html';

export default [() => ({
    restrict: 'E',
    scope: {
        item: '&'
    },
    template: template,
    link: $scope => $scope.$watch('item()', item => {
        $scope.applyNewValue = () => {
            item.setValue($scope.fakeVM.value);
        };

        $scope.fakeVM = {value: item.getValue()};
    })
})];