const EMAIL_REGEXP = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;

/* Garog : The aim of this decorator is the override the default angular email validation which returns undefined when the view value
 fails validation. This has the knock on effect of not setting the model value, which messes with our own DTO validation.
 */
export default function ($provide) {
    $provide.decorator('inputDirective', ['$delegate', function ($delegate) {
        const directive = $delegate[0];
        const link = directive.link;

        directive.compile = function () {
            return function (scope, element, attrs, ctrl) {
                link.pre.apply(this, arguments); // Otherwise the formatters and parsers aren't there yet
                if (attrs.type === 'email') {
                    const emailValidator = (value) => {
                        if (ctrl.$isEmpty(value) || EMAIL_REGEXP.test(value)) {
                            ctrl.$setValidity('email', true);
                        } else {
                            ctrl.$setValidity('email', false);
                        }
                        return value;
                    };
                    ctrl.$formatters = [emailValidator];
                    ctrl.$parsers = [emailValidator];
                }
            };
        };

        return $delegate;
    }]);
}
