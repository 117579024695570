/**
 * Dynamic html attributes
 *
 * @example 
 *  const attributes = {
 *      bar: foo
 *  };
 * 
 *  <div gw-dynamic-attr="{{attributes}}" />
 *  // result
 *  <div gw-dynamic-attr="{{attributes}}" bar="foo" />
 */
class DynamicAttr {

    constructor() {
        this.restrict = 'A';

        this.link = (scope, element, $attrs) => {
            try {
                const parsedAttrs = JSON.parse($attrs.gwDynamicAttr);
                Reflect
                    .ownKeys(parsedAttrs)
                    .forEach((attrName) => {
                        if (attrName) {
                            $attrs.$set(attrName, parsedAttrs[attrName]);
                        }
                    });
            } catch (error) {
                console.error(error);
            }
        };
    }
}

export default () => new DynamicAttr();