import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./TextDirective.html';
import styles from './TextDirective-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default [
    () => {
        return {
            restrict: 'E',
            template: hashedTemplate,
            link: ($scope) => {
                $scope.loading = true;
            }
        };
    }
];