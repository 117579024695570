import appFeatures from 'effective-features!';
import _ from 'lodash';

class MetadataDrivenUI {

    constructor() {
        this.segment = appFeatures.segment || 'default';
        this.presentationMetadataURI = appFeatures.presentationMetadataURI;
    }

    getPresentationMetadata(pageDefinition) {
        pageDefinition = pageDefinition.replace('{SEGMENT}', this.segment);
        return this._fetchMetadata().then((presentationMetadata => {
            if (!presentationMetadata.hasOwnProperty(pageDefinition)) {
                throw new Error(`Unable to reach page: ${pageDefinition}`);
            }
            let requestedMetadata = presentationMetadata[pageDefinition];
            if (requestedMetadata['@extends'] !== undefined) {
                requestedMetadata = MetadataDrivenUI.extendMetadata(presentationMetadata, requestedMetadata);
            }
            return requestedMetadata;
        }));
    }

    static extendMetadata(presentationMetadata, requestedMetadata) {
        const extendedPage = requestedMetadata['@extends'];
        if (!presentationMetadata.hasOwnProperty(extendedPage)) {
            throw new Error(`Unable to extend undefined page: ${extendedPage}`);
        }
        const metadataToExtend = presentationMetadata[extendedPage];
        return _.merge({}, metadataToExtend, requestedMetadata);
    }

    async _fetchMetadata() {
        if (!this.presentationMetadataURI) {
            throw Error('presentationMetadataURI is not defined');
        }
        let response;
        try {
            response = await fetch(this.presentationMetadataURI);
        } catch (error) {
            console.error(error);
        }
        if (!response.ok) {
            throw new Error('Unable to retrieve presentation Metadata.');
        }
        const data = await response.json();
        return data;
    }
}

export default MetadataDrivenUI;