import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

import ViewModel from 'gw-portals-viewmodel-js';
import 'gw-portals-metadata-angular/InsuranceSuiteMetadataService';

export default ['InsuranceSuiteMetadata', (InsuranceSuiteMetaDataService) => {
    // return the service instance
    const VM = ViewModel.get(InsuranceSuiteMetaDataService);

    const config = ApplicationFeatures.getAllFeatures().viewmodel;
    if (config) {
        VM.configure(config);
    }

    return VM;
}];
