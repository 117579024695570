export default ['$q', 'JsonRPCService', 'qnb.endpointConfig', 'qnb.xCenterRouteConfig', ($q, JsonRPCService, endpointConfig, xCenterRouteConfig) => {

    const xCenterRoute = xCenterRouteConfig;
    const endpoint = endpointConfig.emailEndpoint;

    return {
        emailQuote: (quoteEmailDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'emailQuote', [quoteEmailDTO]).then(() => {
                return deferred.resolve();
            }, (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        updateQuotedSubmissionAndEmailQuote_AND: (submissionDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'updateQuotedSubmissionAndEmailQuote', [submissionDTO]).then(() => {
                return deferred.resolve();
            }, (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        }
    };
}];