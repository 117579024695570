/**
 * Helper for stateful data and state manipulation.
 * This helper provides functions to serve following purposes:
 * <ul>
 *     <li>Introspect current application state and access it's internal state.
 *     <li>Provide access to parent state for child states.
 *     <li>Bind object lifecycle to state lifecycle and not to a "forever" cycle (as in the case of services).
 * </ul>
 *
 * In general, it should simplify "context-related" display (like top menus depending on the internal state) and
 * help to remove most of the "specific scenario" bugs. UI must be bound to the state and better to do that
 * implicitly (so <em>transition history</em> would not affect UI).
 */

export default ['$state', '$rootScope', '$timeout', '$q', ($state, $rootScope, $timeout, $q) => {
    const stateTransitionCallbacks = new Map();

    function getStateData(state) {
        /* Data field name. */
        const DATA_NAME = 'GW.PL.LiveData';

        if (!state.data) {
            state.data = {};
        }

        if (!state.data[DATA_NAME]) {
            state.data[DATA_NAME] = {};
        }
        return state.data[DATA_NAME];
    }

    function getParentStatesArray(state, collectedParentStates = []) {
        collectedParentStates.unshift(state);
        if (state.parent === undefined) {
            return collectedParentStates;
        }
        const parentState = (typeof state.parent === 'string') ? $state.get(state.parent) : state.parent;
        return getParentStatesArray(parentState, collectedParentStates);
    }


    function retrieveDataForStateName(stateName, includeInherited) {
        const state = $state.get(stateName);
        if (!state) {
            return undefined;
        }
        const states = (includeInherited) ? getParentStatesArray(state) : [state];
        const statesData = states.map(_state => getStateData(_state));

        // copying own enumerable properties from rightmost to leftmost parameters
        return angular.extend(...statesData);
    }


    function deleteDataForStateName(stateName, propertiesToDelete, includeInherited) {
        const state = $state.get(stateName);
        if (!state) {
            return undefined;
        }
        const states = (includeInherited) ? getParentStatesArray(state) : [state];
        const statesData = states.map(_state => getStateData(_state));

        statesData.forEach(data => {
            let propsToDel = Object.getOwnPropertyNames(data);
            if (propertiesToDelete) {
                propsToDel = propsToDel.filter(prop => propertiesToDelete.includes(prop));
            }
            // set any matching properties to undefined
            propsToDel.forEach(prop => {
                data[prop] = undefined;
            });
        });
    }

    function stateHasParent(testState, parentState) {
        testState = $state.get(testState);
        parentState = $state.get(parentState);
        if (testState.name === parentState.name) {
            return true;
        }
        if (testState.parent === undefined) {
            return false;
        }
        return stateHasParent(testState.parent, parentState);
    }

    function isDefined(v) {
        return v !== null && v !== undefined;
    }

    function getTrackingUrlFromState(state) {
        let url = null;
        if (!state.data || !isDefined(state.data.trackingUrl)) return null;
        if (state.parent) {
            // Detects and discards trackingUrl's inherited from a parent state
            const parentState = $state.get(state.parent);
            url = parentState.data && state.data.trackingUrl === parentState.data.trackingUrl ? null : state.data.trackingUrl;
        } else {
            url = state.data.trackingUrl;
        }
        return url;
    }

    function buildTrackingPathSegment(state, params) {
        let pathSegment;
        const trackingUrl = getTrackingUrlFromState(state);
        if (trackingUrl !== null) {
            if (typeof trackingUrl === 'function') {
                const activeData = retrieveDataForStateName(state.name, true);
                let model = null;
                if (activeData.flowModel && activeData.model && activeData.model.value) {
                    model = activeData.model.value;
                }
                pathSegment = trackingUrl(state, params, model);
            } else {
                pathSegment = trackingUrl.toString();
            }
        } else if (isDefined(state.url)) {
            pathSegment = state.url;
        } else {
            pathSegment = `/${state.name}`;
        }
        return pathSegment;
    }

    return {

        /**
         * Provides a location to store and access to data that relevant to the current state.
         * Will create a live data structure for the object if that structure do not already exists.
         *
         * @param {Boolean} [includeInherited=false] - Flag to indicate that data from parents should be included (defaults to false).
         *                                         Child properties will take precedence over parent properties of the same name
         *
         * @returns {Object|undefined} '<code>undefined</code>' if <code>stateName</code> was provided but current state is not compatible
         * with the requested state.
         */
        'activeDataForState'(includeInherited = false) {
            return retrieveDataForStateName($state.current.name, includeInherited);
        },

        /**
         * Deletes data being held in the current state object based on the <code>propertiesToDelete</code> parameter.
         *
         * @param {Array} [propertiesToDelete]       - Array of properties to be deleted from the active state object.
         *                                              If undefined then all active state data is deleted
         *
         * @param {Boolean} [includeInherited=false] -Flag to indicate that data from parent states also be deleted
         *
         * @returns {*}
         *
         */
        'removeActiveDataForState'(propertiesToDelete, includeInherited = false) {
            return deleteDataForStateName($state.current.name, propertiesToDelete, includeInherited);
        },

        /**
         * Provides a location to store and access to data that relevant to a state specified by fnol.
         * Will create a data structure for the object if that structure does not already exist.
         *
         *  @param {String} stateName - name of the state to fetch data for.
         *  @param {Boolean} [includeInherited] - Flag to indicate that data from parents should be included (defaults to true).
         *                                         Child properties will take precedence over parent properties of the same name
         *
         * @throws Error if stateName parameter not provided
         * @throws Error if a state cannot be found for the stateName
         *
         * @returns {Object|undefined} new or existing data associated with the state given by stateName
         */
        'dataForState'(stateName, includeInherited = true) {
            if (!stateName) {
                throw new Error('A valid state name must be provided.');
            }
            const stateData = retrieveDataForStateName(stateName, includeInherited);

            if (!stateData) {
                throw new Error(`Unable to find state with name ${stateName}`);
            }
            return stateData;
        },

        /**
         *Deletes data being held in the state object identified by <code>stateName</code> based on the
         * <code>propertiesToDelete</code> parameter.
         *
         * @param {String} stateName - name of the state to delete data on.
         *
         * @param {Array} [propertiesToDelete]       - Array of properties to be deleted from the active state object.
         *                                              If undefined then all active state data is deleted
         *
         * @param {Boolean} [includeInherited=false] -Flag to indicate that data from parent states also be deleted
         *
         * @returns {*}
         *
         */
        'removeDataForState'(stateName, propertiesToDelete, includeInherited = false) {
            return deleteDataForStateName(stateName, propertiesToDelete, includeInherited);
        },

        /**
         * Checks if <code>equalOrDescendantState</code> is equal to <code>equalOrParentState</code> or is a descendant of <code>equalOrParentState</code>.
         * If equalOrDecendantState is undefined then tests against the current state
         *
         * @param {String} equalOrParentState parent state to check if equal or a parent to equalOrDescendantState
         * @param {String} [equalOrDescendantState]  Defaults to the current state
         *
         * @returns {boolean}
         *
         */
        'hasState'(equalOrParentState, equalOrDescendantState) {
            if (!equalOrDescendantState) {
                // use current state
                return $state.includes(equalOrParentState);
            }
            const state = $state.get(equalOrDescendantState);
            const parentState = $state.get(equalOrParentState);
            return equalOrParentState ? stateHasParent(state, parentState) : false;
        },


        /**
         * Calls a function on each state change but before each controller is initialized.
         * @param {Function} callback function to call.
         *
         * @returns {Function} function to remove the listener
         */
        'beforeStateInit'(callback) {
            return $rootScope.$on('$stateChangeSuccess', callback);
        },

        /**
         * Calls a function on each state change after state controller is initalized.
         * @param {Function} callback zero-arg function to call.
         * @returns {Function} function to remove the listener
         */
        'afterStateInit'(callback) {
            return $rootScope.$on('$stateChangeSuccess', () => {
                $timeout(callback);
            });
        },

        /**
         * Calls a function before state change
         * @param {Function} callback function to call.
         *
         * @returns {Function} function to remove the listener
         */
        'beforeStateChangeStart'(callback) {
            return $rootScope.$on('$stateChangeStart', callback);
        },

        /**
         * Returns the tracking URL for a given state and state parameters.
         *
         * @returns {string} - the tracking URL, as a concatenation of the tracking URLs for the state chain.
         */
        'getTrackingUrl'() {
            const state = $state.current;
            const stateParams = $state.params;
            let currentState = state;
            let path = null;
            while (currentState) {
                let pathSegment = buildTrackingPathSegment(currentState, stateParams);
                if (pathSegment.startsWith('^')) {
                    pathSegment = pathSegment.substr(1);
                    currentState = null;
                }
                path = path ? `${pathSegment}${path}` : pathSegment;

                currentState = currentState && currentState.parent ? $state.get(currentState.parent) : null;
            }
            return path;
        },

        subscribeToStateTransitions(callback) {
            let lastStateTransitionDeferred;

            function trackStateTransitionInitiated(actionHandlerObject) {
                lastStateTransitionDeferred = $q.defer();
                callback(actionHandlerObject, lastStateTransitionDeferred.promise);
            }

            function trackStateTransitionFinished(transitionDescription) {
                if (lastStateTransitionDeferred) {
                    lastStateTransitionDeferred.resolve(transitionDescription);
                }
            }

            const flowModel = this.activeDataForState(true).flowModel;
            if (!flowModel) {
                throw new Error('Cannot subscribe to state transitions as no flowModel is available.');
            }

            flowModel.addTransitionStartListener(trackStateTransitionInitiated);
            flowModel.addTransitionFinishListener(trackStateTransitionFinished);

            stateTransitionCallbacks.set(callback, () => {
                flowModel.removeTransitionStartListener(trackStateTransitionInitiated);
                flowModel.removeTransitionFinishListener(trackStateTransitionFinished);
            });
        },
        unsubscribeFromStateTransitions(callback) {
            const unbind = stateTransitionCallbacks.get(callback);
            stateTransitionCallbacks.delete(callback);

            unbind();
        },
        jumpToTheStep(step) {
            const flowModel = this.activeDataForState(true).flowModel;
            if (flowModel.isStepAccessibleFromCurrent(step)) {
                flowModel.jumpToStep(step, this.activeDataForState(true).model);
            }
        }
    };
}];
