import $ from 'jquery';
/**
 * Part of Dropdown functionality from Angular Bootstrap Framework
 * which is needed for datepicker plugin
 */

/* ========================================================================
 * Bootstrap: dropdown.js v3.3.4
 * http://getbootstrap.com/javascript/#dropdowns
 * ========================================================================
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */


// DROPDOWN CLASS DEFINITION
// =========================

const backdrop = '.dropdown-backdrop';
const toggle = '[data-toggle="dropdown"]';
const Dropdown = function (element) {
    $(element).on('click.bs.dropdown', this.toggle);
};

Dropdown.VERSION = '3.3.4';
Dropdown.prototype.toggle = function (e) {
    const $this = $(this);

    if (!e) {
        // FOR OLD BOOTSTRAP VERSION ON CALLING TOGGLE DIRECTLY-
        // JUST TRIGGER PARENT CLICK
        $this.parent().trigger('click');
    }
};

function clearMenus(e) {
    if (e && e.which === 3) {
        return;
    }
    $(backdrop).remove();
    $(toggle).each(function () {
        const $this = $(this);
        const $parent = getParent($this);
        const relatedTarget = {
            relatedTarget: this
        };

        if (!$parent.hasClass('open')) {
            return;
        }

        $parent.trigger(e = new $.Event('hide.bs.dropdown', relatedTarget));

        if (e.isDefaultPrevented()) {
            return;
        }

        $this.attr('aria-expanded', 'false');
        $parent.removeClass('open').trigger('hidden.bs.dropdown', relatedTarget);
    });
}

function getParent($this) {
    let selector = $this.attr('data-target');

    if (!selector) {
        selector = $this.attr('href');
        selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, ''); // strip for ie7
    }

    const $parent = selector && $(selector);

    return $parent && $parent.length ? $parent : $this.parent();
}


// DROPDOWN PLUGIN DEFINITION
// ==========================

function Plugin(option) {
    return this.each(function () {
        const $this = $(this);
        let data = $this.data('bs.dropdown');

        if (!data) {
            $this.data('bs.dropdown', (data = new Dropdown(this)));
        }
        if (typeof option === 'string') {
            data[option].call($this);
        }
    });
}

const old = $.fn.dropdown;

$.fn.dropdown = Plugin;
$.fn.dropdown.Constructor = Dropdown;


// DROPDOWN NO CONFLICT
// ====================

$.fn.dropdown.noConflict = function () {
    $.fn.dropdown = old;
    return this;
};


// APPLY TO STANDARD DROPDOWN ELEMENTS
// ===================================

$(document)
    .on('click.bs.dropdown.data-api', clearMenus)
    .on('click.bs.dropdown.data-api', '.dropdown form', (e) => {
        e.stopPropagation();
    })
    .on('click.bs.dropdown.data-api', toggle, Dropdown.prototype.toggle);