const fn = (
    $window,
    phonePortraitMediaBreakpoint,
    phoneLandscapeMediaBreakpoint,
    tabletPortraitMediaBreakpoint,
    tabletLandscapeMediaBreakpoint,
    desktopSmallMediaBreakpoint
) => (
    {
        // check if meets specific size
        getPhonePortraitOnlyQuery: () => {
            return $window.matchMedia(`(max-width: ${phonePortraitMediaBreakpoint}px)`);
        },
        getPhoneLandscapeOnlyQuery: () => {
            return $window.matchMedia(`(min-width: ${phonePortraitMediaBreakpoint + 1}px) and (max-width: ${phoneLandscapeMediaBreakpoint}px)`);
        },
        getTabletPortraitOnlyQuery: () => {
            return $window.matchMedia(`(min-width: ${phoneLandscapeMediaBreakpoint + 1}px) and (max-width: ${tabletPortraitMediaBreakpoint}px)`);
        },
        getTabletLandscapeOnlyQuery: () => {
            return $window.matchMedia(`(min-width: ${tabletPortraitMediaBreakpoint + 1}px) and (max-width: ${tabletLandscapeMediaBreakpoint}px)`);
        },
        getDesktopSmallOnlyQuery: () => {
            return $window.matchMedia(`(min-width: ${tabletLandscapeMediaBreakpoint + 1}px) and (max-width: ${desktopSmallMediaBreakpoint}px)`);
        },
        getDesktopLargeOnlyQuery: () => {
            return $window.matchMedia(`(min-width: ${desktopSmallMediaBreakpoint + 1}px)`);
        },
        // check if size is smaller or equal to the specified one
        getPhonePortraitDownQuery: () => {
            return $window.matchMedia(`(max-width: ${phonePortraitMediaBreakpoint}px)`);
        },
        getPhoneLandscapeDownQuery: () => {
            return $window.matchMedia(`(max-width: ${phoneLandscapeMediaBreakpoint}px)`);
        },
        getTabletPortraitDownQuery: () => {
            return $window.matchMedia(`(max-width: ${tabletPortraitMediaBreakpoint}px)`);
        },
        getTabletLandscapeDownQuery: () => {
            return $window.matchMedia(`(max-width: ${tabletLandscapeMediaBreakpoint}px)`);
        },
        getDesktopSmallDownQuery: () => {
            return $window.matchMedia(`(max-width: ${desktopSmallMediaBreakpoint}px)`);
        },
        // check if size is bigger than the specified one
        getPhonePortraitUpQuery: () => {
            return $window.matchMedia(`(min-width: ${phonePortraitMediaBreakpoint + 1}px)`);
        },
        getPhoneLandscapeUpQuery: () => {
            return $window.matchMedia(`(min-width: ${phoneLandscapeMediaBreakpoint + 1}px)`);
        },
        getTabletPortraitUpQuery: () => {
            return $window.matchMedia(`(min-width: ${tabletPortraitMediaBreakpoint + 1}px)`);
        },
        getTabletLandscapeUpQuery: () => {
            return $window.matchMedia(`(min-width: ${tabletLandscapeMediaBreakpoint + 1}px)`);
        },
        getDesktopSmallUpQuery: () => {
            return $window.matchMedia(`(min-width: ${desktopSmallMediaBreakpoint + 1}px)`);
        }
    });
fn.$inject = [
    '$window',
    'phonePortraitMediaBreakpoint',
    'phoneLandscapeMediaBreakpoint',
    'tabletPortraitMediaBreakpoint',
    'tabletLandscapeMediaBreakpoint',
    'desktopSmallMediaBreakpoint'
];

export default fn;