export default ['$window', 'TransportService', ($window, TransportService) => {
    return {
        send: (xCenterType, serviceEndpoint, method, params) => {
            return (
                TransportService.send(xCenterType, serviceEndpoint, method, params)
                    .catch((error) => {
                        if (TransportService.isSessionExpiredReason(error) || TransportService.isUserUnauthenticatedReason(error)) {
                            // refresh the page to get to login page
                            /* Set reload to true. Some "autentication solutions" does not set proper
                             * caching headers.
                             */
                            $window.location.reload(true);
                        }
                        throw error;
                    }));
        }
    };
}];