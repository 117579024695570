import _ from 'lodash';
import LobCoveragesTransformationHelper from 'edge/quoteandbind/common/directives/CommonOfferingSelection/util/LobCoveragesTransformationHelper';

function PmCoveragesTransformationHelper($filter, offeringUtilFunctions) {
    LobCoveragesTransformationHelper.call(this, $filter, offeringUtilFunctions);
}

function buildMobileCoverageData(offering) {
    var _this = this;

    var offeringCov = {};
    offeringCov.branchName = offering.branchName;
    offeringCov.lineCoverages = [];
    offeringCov.vehicleCoverages = [];
    offering.coverages.pmLineCoverages.forEach(function (lineCoverage) {
        var lineCoverageState = {};
        lineCoverageState.infoVisible = false;
        if (!lineCoverage.terms) {
            lineCoverageState.coverage = lineCoverage;
        } else {
            var cov = _this.createCoverage(lineCoverage);
            lineCoverageState.coverage = cov;
        }
        offeringCov.lineCoverages.push(lineCoverageState);
    });
    offering.coverages.pmVehicleCoverages.forEach(function (vehicle) {
        vehicle.displayCoverages = [];
        vehicle.coverages.forEach(function (vehicleCoverage) {
            var vehicleCoverageState = {};
            vehicleCoverageState.infoVisible = false;
            if (!vehicleCoverage.terms) {
                vehicleCoverageState.coverage = vehicleCoverage;
            } else {
                var vehicleCov = _this.createCoverage(vehicleCoverage);
                vehicleCoverageState.coverage = vehicleCov;
            }
            vehicle.displayCoverages.push(vehicleCoverageState);
        });
        offeringCov.vehicleCoverages.push(vehicle);
    });
    return offeringCov;
}

/**
 * Updates the coverage array models on the util
 * @public
 */
PmCoveragesTransformationHelper.prototype.updateModels = function () {
    var _this2 = this;

    this.offeringUtilFunctions.getLobData().updateModel();

    var lineCoverages = this.offeringUtilFunctions.getLobData().lineCoverageArray;
    var vehicleCoverages = this.offeringUtilFunctions.getLobData().additionalCoverageArray;
    this.coverageArray = this.transformCoverageArray(lineCoverages);
    // Transform Coverage Array of each vehicle and find the name of the vehicle to render
    this.vehicleCoverageArray = _(vehicleCoverages).map(function (coverages, vehicleId) {
        return {
            coverages: _this2.transformCoverageArray(coverages),
            vehicleName: _this2.offeringUtilFunctions.getLobOfferings()[0].coverages.pmVehicleCoverages.find(function (vehicle) {
                return '' + vehicle.fixedId === vehicleId;
            }).vehicleName,
            publicID: _this2.offeringUtilFunctions.getLobOfferings()[0].coverages.pmVehicleCoverages.find(function (vehicle) {
                return '' + vehicle.fixedId === vehicleId;
            }).publicID
        };
    }).value();
    var nonCustomOfferings = this.offeringUtilFunctions.getLobOfferings().filter(function (offering) {
        return offering.branchCode !== 'CUSTOM';
    });
    this.offeringHeaders = this.offeringUtilFunctions.getOfferedQuotes();
    this.mobileCoverageData = nonCustomOfferings.map(buildMobileCoverageData.bind(this));
};

/**
 * Gets the base (line) coverage array table
 * @returns {*|Array}
 */
PmCoveragesTransformationHelper.prototype.getBaseCoverageData = function () {
    return this.coverageArray;
};

/**
 * Gets the Lob specific coverables -> coverages tables. For PA this will be an array of tables where each table contains
 * the coverages for a specific vehicle
 * @returns {*|Array}
 */
PmCoveragesTransformationHelper.prototype.getLobCoverageData = function () {
    return this.vehicleCoverageArray;
};

PmCoveragesTransformationHelper.prototype.getOfferingHeaders = function () {
    return this.offeringHeaders;
};

PmCoveragesTransformationHelper.prototype.getLobCoverageName = function () {
    return 'lineCoverages';
};

export default PmCoveragesTransformationHelper;