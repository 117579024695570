import ExpressionLanguage from 'gw-portals-exp-lang-js';
import 'gw-portals-metadata-angular';

export default ['TranslateService', 'InsuranceSuiteMetadata', function (TranslateService, InsuranceSuiteMetadata) {
    return ExpressionLanguage.createExpressionLanguage(function () {
        const displayKey = arguments[0];
        const transKeys = {};
        for (let i = 1; i < arguments.length; i++) {
            transKeys[`_${i - 1}`] = arguments[i];
        }
        return TranslateService.instant(displayKey, transKeys);
    }, (xCenter, typekey, typecode) => {
        return InsuranceSuiteMetadata.get(xCenter).types.get('typelist', `typekey.${typekey}`).getCode(typecode);
    });
}];