import 'edge/quoteandbind/common/models/Submission';

export default ['$q',
    'JsonRPCService',
    'qnb.endpointConfig',
    'qnb.xCenterRouteConfig',
    ($q, JsonRPCService, endpointConfig, xCenterRouteConfig) => {

        const xCenterRoute = xCenterRouteConfig;
        const endpoint = endpointConfig.customQuoteEndpoint;

        return {
            setSelectedVersionOnSubmission: (quoteID, branchName, sessionUUID) => {
                return JsonRPCService.send(xCenterRoute, endpoint, 'setSelectedVersionOnSubmission', [quoteID, branchName, sessionUUID]);
            },

            updateCustomQuote: (customQuoteDTO) => {
                return JsonRPCService.send(xCenterRoute, endpoint, 'updateCustomQuote', [customQuoteDTO]);
            },

            updateCustomQuoteCoverages: (customQuoteDTO) => {
                return JsonRPCService.send(xCenterRoute, endpoint, 'updateCustomQuoteCoverages', [customQuoteDTO]);
            },

            forceUpdateCustomQuoteCoverages: (customQuoteDTO) => {
                return JsonRPCService.send(xCenterRoute, endpoint, 'forceUpdateCustomQuoteCoverages', [customQuoteDTO]);
            },

            updateQuote: (customQuoteDTO) => { // ANDIE
                return JsonRPCService.send(xCenterRoute, endpoint, 'updateQuote', [customQuoteDTO]);
            },
        };
    }
];
