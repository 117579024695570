export default [
    () => {
        // this service is intended to return a vehicle picture given year, make, and model
        // this implementation is hard coded to two vehicles for demo purpose only
        // consult documentation for a sample implementation using third party photo lookup
        return {
            lookupPhoto(dto) {
                const {year, make, model} = dto;
                const mo = model.toUpperCase();
                const mk = make.toUpperCase();
                let imageURL = '';
                if (mk === 'TOYOTA' && mo === 'AVENSIS' && year) {
                    imageURL = '../styles/images/common/stockphotos/toyota_avensis.jpg';
                } else if (mk === 'AUDI' && mo === 'A4' && year) {
                    imageURL = '../styles/images/common/stockphotos/audi_a4.jpg';
                }
                return Promise.resolve(imageURL);
            }
        };
    }
];
