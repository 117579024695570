class ComponentizerCtrl {

    constructor($scope, $element, $attrs, $compile, $filter) {
        this.$scope = $scope;
        this.$element = $element;
        this.$attrs = $attrs;
        this.$compile = $compile;
        this.$filter = $filter;
    }

    formatProperty(value, key) {
        let property;

        if (key.startsWith('display-')) { // check if property starts with `display-` to translate
            const formattedKey = key.replace('display-', ''); // Remove prefix
            property = `${formattedKey}="${this._translateProperty(value)}"`;
        } else if (Array.isArray(value) || typeof value === 'object') {
            property = `${key}='${JSON.stringify(value)}'`;
        } else {
            property = `${key}="${value}"`;
        }
        return property;
    }

    /**
     *
     * @param {String} label
     * @returns {String}
     */
    _translateProperty(label) {
        return this.$filter('translate')(label);
    }

    /**
     * Generates the automation property either for field or element components
     * @param {string} automationProperty
     * @returns {string} the automation HTML property
     */
    generateAutomationProperty() {
        const {automationProperty} = this.field || this.element;
        if (automationProperty) {
            return `data-gw-test-${automationProperty}=""`;
        }
        return '';
    }
}

ComponentizerCtrl.$inject = ['$scope', '$element', '$attrs', '$compile', '$filter'];

export default ComponentizerCtrl;