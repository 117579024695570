class QuoteCtrl {

    constructor(EndorsementService, StateUtils, ModelService, PolicyDiffService) {
        this.EndorsementService = EndorsementService;
        this.StateUtils = StateUtils;
        this.ModelService = ModelService;
        this.PolicyDiffService = PolicyDiffService;
    }

    $onInit() {
        const model = this.StateUtils.activeDataForState(true).model;
        const calculatePercentage = (premium, changeInCost) => Math.abs(Math.floor((changeInCost * 100) / premium));
        const removeSign = (field) =>{
            const copy = Object.assign({}, field);
            copy.amount = Math.abs(field.amount);
            return copy;
        };

        this.policyDiffTree = [];
        this.PolicyDiffService.getPolicyDiffWithPrevious(model.jobID.value)
            .then((result) => {
                this.policyDiffTree = result;
            }).catch(() => {
                this.ModelService.showError(
                    'gw-portals-policytransaction-common-angular.components.quote.quote.There was an error retrieving the changes to your policy',
                    'gw-portals-policytransaction-common-angular.components.quote.quote.Please try your changes again.'
                );
            });

        this.finish = this.finish.bind(this);
        this.increase = this.changeInCost.amount > 0;
        this.decrease = this.changeInCost.amount < 0;
        this.noChange = this.changeInCost.amount === 0;
        this.changeInCostNoSign = removeSign(this.changeInCost);
        this.percentage = calculatePercentage(this.previousPremium.amount, this.changeInCost.amount);
        this.showPaymentStepButton = this.alwaysShowPaymentStep || this.increase;

    }

    finish(nextFn) {
        const model = this.StateUtils.activeDataForState(true).model;
        const paymentDetails = {
            paymentMethod: 'redistribute'
        };
        this.EndorsementService.bind(model.jobID.value, paymentDetails).then(() => nextFn(model));
    }
}

QuoteCtrl.$inject = ['endorsement.EndorsementService', 'StateUtils', 'ModalService', 'PolicyDiffService'];

export default QuoteCtrl;