import defaultModeTemplate from 'text!./templates/companyname-details-default.html';
import japanModeTemplate from 'text!./templates/companyname-details-JP.html';

export default ['LocaleService', (LocaleService) => {
    const defaultHiddenFields = [];
    const nameConfig = LocaleService.getNameConfig();

    return {
        restrict: 'A',
        template: nameConfig.mode === 'Japan' ? japanModeTemplate : defaultModeTemplate,
        scope: {
            contact: '=', // if this val is changed in view then it changes in the model
            readonly: '=' // if this val is changed in view it DOES NOT change in the model
        },
        controller: ['$scope', ($scope) => {
            $scope.hiddenFields = defaultHiddenFields;
            $scope.isFieldVisible = (fieldName) => {
                const visible = nameConfig.visibleFields.indexOf(fieldName) >= 0;
                const hidden = $scope.hiddenFields.indexOf(fieldName) >= 0;
                return visible && !hidden;
            };
        }]

    };
}];
