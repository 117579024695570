/**
 * Defines a bullet list
 *
 * <gw-bullet-list>
 *   <gw-bullet-list-item>Na-na</gw-bullet-list-item>
 *   <gw-bullet-list-item>Na-na</gw-bullet-list-item>
 *   <gw-bullet-list-item>Batman!</gw-bullet-list-item>
 * </gw-bullet-list>
 */
import template from 'text!./templates/BulletList.html';
import layout from './LayoutFactory';

export default layout('BulletList', template, (partial) => {
    partial('Item', '<li class="gw-bullet-list__item" gw-partial-target></li>');
});
