export default ['$gwPlTransition', ($gwPlTransition) => {
    return {
        link: (scope, element, attrs) => {
            let initialAnimSkip = true;
            let currentTransition;

            function doTransition(change) {
                const newTransition = $gwPlTransition(element, change);
                if (currentTransition) {
                    currentTransition.cancel();
                }
                currentTransition = newTransition;
                newTransition.then(newTransitionDone, newTransitionDone);
                return newTransition;

                function newTransitionDone() {
                    // Make sure it's this transition, otherwise, leave it alone.
                    if (currentTransition === newTransition) {
                        currentTransition = undefined;
                    }
                }
            }

            function expand() {
                if (initialAnimSkip) {
                    initialAnimSkip = false;
                    expandDone();
                } else {
                    element.removeClass('gw-collapse').addClass('gw-collapsing');
                    doTransition({
                        height: `${element[0].scrollHeight}px`
                    }).then(expandDone);
                }
            }

            function expandDone() {
                element.removeClass('gw-collapsing');
                element.addClass('gw-collapse gw-in');
                element.css({
                    height: 'auto'
                });
            }

            function collapse() {
                if (initialAnimSkip) {
                    initialAnimSkip = false;
                    collapseDone();
                    element.css({
                        height: 0
                    });
                } else {
                    // CSS transitions don't work with height: auto, so we have to manually change the height to a specific value
                    element.css({
                        height: `${element[0].scrollHeight}px`
                    });
                    // trigger reflow so a browser realizes that height was updated from auto to a specific value
                    // noinspection BadExpressionStatementJS
                    element[0].offsetWidth;

                    element.removeClass('gw-collapse gw-in').addClass('gw-collapsing');

                    doTransition({
                        height: 0
                    }).then(collapseDone);
                }
            }

            function collapseDone() {
                element.removeClass('gw-collapsing');
                element.addClass('gw-collapse');
            }

            scope.$watch(attrs.gwPlCollapse, (shouldCollapse) => {
                if (shouldCollapse) {
                    collapse();
                } else {
                    expand();
                }
            });
        }
    };
}];