const TMI_BRANDS = ['toy', 'lex', 'aut', 'kin', 'kfi', 'itb', 'tbb'];

export default {
    /**
     * Works out the brand name based on brand code
     *
     * @param {Object} [brand]
     * @returns {String} returns the full brand name
     */

    getBrandName: (brand) => {
        if (brand === 'toy'){
            return 'Toyota Motor Insurance'
        }
        if (brand === 'lex'){
            return 'Lexus Motor Insurance'
        }
        if (brand === 'aut'){
            return 'Autosan Motor Insurance'
        }
        if (brand === 'kin'){
            return 'Kinto LCV'
        }
        if (brand === 'kfi'){
            return 'Kwik Fit Motor Insurance'
        }
        if (brand === 'itb'){
            return 'insurethebox'
        }
        if (brand === 'tbb'){
            return 'Tesco bank Box Insurance'
        }
    }
}