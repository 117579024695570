/**
 * Extracted from angular-ui-bootstrap 0.8.0
 */

export default ['$document', '$location', ($document) => {
    let openElement = null;
    let closeMenu = angular.noop;
    return {
        restrict: 'CA',
        link: (scope, element) => {
            scope.$watch('$location.path', () => {
                closeMenu();
            });
            element.parent().bind('click', () => {
                closeMenu();
            });
            element.bind('click', (event) => {
                const elementWasOpen = (element === openElement);

                event.preventDefault();
                event.stopPropagation();

                if (openElement) {
                    closeMenu();
                }

                if (!elementWasOpen && !element.hasClass('gw-disabled') && !element.prop('disabled')) {
                    element.parent().addClass('gw-open');
                    openElement = element;
                    closeMenu = function (_event) {
                        if (_event) {
                            _event.preventDefault();
                            _event.stopPropagation();
                        }
                        $document.unbind('click', closeMenu);
                        element.parent().removeClass('gw-open');
                        closeMenu = angular.noop;
                        openElement = null;
                    };
                    $document.bind('click', closeMenu);
                }
            });
        }
    };
}];