import template from 'text!./templates/input-mask-group.html';

class UiMaskGroup {
    constructor() {
        this.restrict = 'E';
        this.scope = {
            label: '@',
            model: '=',
            mask: '@',
            tooltip: '=?'
        };
        this.template = template;
    }
}

export default UiMaskGroup;
