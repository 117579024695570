import _ from 'lodash';
import mustache from 'mustache';

const START_TOKEN = '{{';
const END_TOKEN = '}}';
const variableExpr = new RegExp(`${START_TOKEN}.+${END_TOKEN}`);

/**
 * Does the given string value have a variable expression?
 * @param {string} str - The string to test
 * @returns {boolean} - True if it has a variable expression
 */
function hasVariable(str) {
    return variableExpr.test(str);
}

/**
 * Splits the variable expression into a key and a default value (if present).
 * @param {string} keyMaybeValue - The variable expression
 * @returns {{key: string, defaultValue: *}}
 */
function splitKey(keyMaybeValue) {
    const r = /([\w-.]+\s+)\|\|\s+(.*)/.exec(keyMaybeValue);
    return {
        key: r ? r[1].trim() : keyMaybeValue,
        defaultValue: r ? r[2].trim().replace(/(^['"]|['"]$)/g, '') : undefined
    };
}

/**
 * Gets the value of the key from the given params.
 * @param {string} keyMaybeValue - The key to lookup (optionally default value, i.e. `key || defaultValue`)
 * @param {Object} params - The params
 * @param {boolean} [allowMissing=false] - True if missing params allowed
 * @returns {string} - The value string
 */
function getValue(keyMaybeValue, params, allowMissing = false) {
    const {key, defaultValue} = splitKey(keyMaybeValue);
    let value = _.get(params, key, defaultValue);
    if (value === undefined || value === null) {
        if (!allowMissing) {
            console.warn('Error, missing key ', key, ' from: ', params);
        }
        // put back variable expression
        value = `${START_TOKEN}${keyMaybeValue}${END_TOKEN}`;
    }
    return value;
}

/**
 * Replace variable references in string values.
 * @param {string} str - The template value to replace
 * @param {Object} params - The parameters
 * @param {boolean} [allowMissing=false] - True if missing params are allowed
 * @returns {string} - The value, with variables replaced
 */
function substitute(str, params, allowMissing = false) {
    const tokens = mustache.parse(str, [START_TOKEN, END_TOKEN]);
    let buffer = '';
    tokens.forEach(token => {
        const symbol = token[0];
        const textOrKey = token[1];
        switch (symbol) {
            // replace variable if value found
            case 'name':
                buffer += getValue(textOrKey, params, allowMissing);
                break;
            case 'text': buffer += textOrKey; break;
            default: throw new Error(`Parse error, unexpected symbol: ${symbol}`);
        }
    });
    return buffer;
}

function concatPath(path, addPath) {
    const str = typeof addPath === 'number' ? `[${addPath}]` : addPath;
    if (path.length === 0) {
        return str;
    } else if (typeof addPath === 'number') {
        return `${path}${str}`;
    }
    return `${path}.${str}`;
}

/**
 * Traverses a data structure of arbitrarily deep objects and arrays to
 * find 'atomic' values. Invokes the `callback` on these values.
 * @param {*} val - The value to traverse
 * @param {function(val:*, path:string)} callback - The function to invoke
 * @param {string} [path=''] - The current traversal path
 */
function traverse(val, callback, path = '') {
    if (_.isArray(val)) {
        val.forEach((elem, i) => traverse(elem, callback, concatPath(path, i)));
    } else if (_.isObject(val)) {
        Object.keys(val).forEach(key => traverse(val[key], callback, concatPath(path, key)));
    } else {
        callback(val, path);
    }
}

/**
 * Loads the configuration object, substituting parameter values.
 * @param {Object} obj - The unsubstituted object
 * @param {Object} params - The key-value map of parameters
 * @returns {Object} - The object with parameter substitutions
 */
export default function loadConfig(obj, params) {
    // first pass, substitute params
    traverse(obj, (val, path) => {
        if (_.isString(val) && hasVariable(val)) {
            _.set(obj, path, substitute(val, params, true));
        }
    });
    // second pass, resolve references
    traverse(obj, (val, path) => {
        if (typeof val === 'string' && hasVariable(val)) {
            _.set(obj, path, substitute(val, obj));
        }
    });
    return obj;
}
