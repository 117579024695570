import defaultTemplate from 'text!./templates/address-summary-default.html';
import bigToSmallTemplate from 'text!./templates/address-summary-BigToSmall.html';
import postCodeBeforeCityTemplate from 'text!./templates/address-summary-PostCodeBeforeCity.html';

export default ['LocaleService', (LocaleService) => {
    const addressConfig = LocaleService.getAddressConfig();
    let template;

    switch (addressConfig.pcfMode) {
        case 'BigToSmall':
            template = bigToSmallTemplate;
            break;
        case 'PostCodeBeforeCity':
            template = postCodeBeforeCityTemplate;
            break;
        default:
            template = defaultTemplate;
            break;
    }

    return {
        restrict: 'AE',
        template,
        scope: {
            address: '='
        }
    };
}];