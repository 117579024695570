function isPromise(value) {
    return value && typeof value.then === 'function';
}

function isMessagePlusInterpolation(value) {
    return value && typeof value.key === 'string';
}

export default ['$scope', '$filter', '$translate', '$q', 'model', 'stateProps', ($scope, $filter, $translate, $q, model, stateProps) => {
    function translate(val) {
        if (isPromise(val)) {
            return val;
        }
        if (isMessagePlusInterpolation(val)) {
            return $translate(val.key, val.args || {});
        }
        return $q.when(typeof val === 'string' && val.length ?
            $translate(val) :
            val);
    }

    function writeToScope(prop) {
        return (msg) => {
            $scope[prop] = msg;
        };
    }

    $scope.iconClass = () => {
        switch (model.iconClass) {
            case 'gw-messageErrorIcon':
                return 'fa fa-minus-circle';
            case 'gw-messageWarningIcon':
                return 'fa fa-exclamation-triangle';
            case 'gw-messageInformationIcon':
                return 'fa fa-info-circle';
            case 'gw-messageEmailIcon':
                return 'fa fa-envelope-o';
            default:
                return null;
        }
    };

    $scope.iconClassType = () => {
        return model.iconClass;
    };

    $scope.stateProps = stateProps;
    $scope.showCancelBtn = model.showCancelBtn;
    $scope.cancelBtnLabel = model.cancelBtnLabel;
    $scope.showSaveBtn = model.showSaveBtn;
    $scope.showTimeoutBtn = model.showTimeoutBtn;

    if (model.radioOptions && model.radioOptions.length > 0) {
        $scope.radioOptions = model.radioOptions;
        $scope.optionsModel = {
            set value(code) {
                this.code = {code: code};
            },
            get value() {
                return this.code;
            },
            aspects: {
                visible: true,
                get valid() {
                    return $scope.optionsModel.value && $scope.optionsModel.value.code;
                }
            }
        };
        $scope.optionsModel.value = model.radioOptions[0].value;
    }

    if (model.checkboxOptions) {
        $scope.checkboxOptions = model.checkboxOptions;
        $scope.optionsModel = {
            'value': model.checkboxOptions.value
        };
    }

    $scope.toggle = function ($event) {
        $event.stopPropagation();
        $scope.optionsModel.value = !$scope.checkboxOptionsModel.value;
    };

    translate(model.title).then(writeToScope('title'));
    translate(model.message).then(writeToScope('message'));
    translate(model.description).then(writeToScope('description'));

    if (model.action) {
        $scope.action = {
            btnClass: model.action.btnClass || 'gw-btn-primary',
            label: model.action.label || $filter('translate')('platform.modal.ModalCtrl.OK')
        };
    }
}];
