/**
 * Use instead of the "./module" to avoid authentication in the app
 */

export default angular.module('edge.platform.auth', [])
    .provider('gwAuthTokenService', {// do nothing
        $get: () => {
            return {};
        }
    })
    .provider('gwInactivityTimerService', {// do nothing
        $get: () => {
            return {
                resetInactivityTimer: () => true
            };
        }
    });