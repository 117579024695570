/**
 * Default behavior for displaying status information about an action
 *  Progress: while the action is in progress then a progress dialog will be shown with a progress message if provided
 *  Success: if there is a success message provided then a success message will be displayed, otherwise no dialog is displayed
 *  Error: if the action results in an error then an error dialog will be show with the error message if provided
 *
 *  @param {Object} ModalComponent
 *  @returns {function}
 */
export default function (ModalComponent) {
    const defaultActionMsgs = {
        error: {
            title: 'platform.flow.angularhelpers.ActionStatusMessages.Unable to perform operation',
            message: 'platform.flow.angularhelpers.ActionStatusMessages.An error occurred, please contact your agent'
        },
        progress: {
            message: 'platform.flow.angularhelpers.ActionStatusMessages.Processing operation...'
        }
    };

    function getActionMsg(actionMsgs, resp) {
        if (angular.isFunction(actionMsgs)) {
            return actionMsgs(resp);
        }

        return actionMsgs;
    }

    /**
     * @param {Promise} actionPromise
     * @param {Object} actionMsgs - Can be an object with progress, success, and error message, or a function that returns an object with the required properties
     * @returns {Promise}
     */
    return function (actionPromise, actionMsgs = defaultActionMsgs) {
        return actionPromise.then(resp => {
            const actionMsgResult = getActionMsg(actionMsgs, resp);
            if (actionMsgResult.success) {
                const successMsg = () => actionMsgResult.success.message;
                // when the dialog is closed return the response from the action
                return ModalComponent.showSuccess(successMsg, successMsg).result.then(() => resp);
            }
            return resp;
        });
        /**
         * ANDIE Do not show an error modal, go straight to the next handler in the chain.
         *
         *
            .catch(e => {
            const actionMsgResult = getActionMsg(actionMsgs, e);
            // when the dialog is closed return throw an error to trigger the next error handler in the chain
            return ModalComponent.showError(
                actionMsgResult.error.title, actionMsgResult.error.message,
                actionMsgResult.error.description, actionMsgResult.error.actionLabel
            ).result.then(() => {
                throw e;
            });
        });*/
    };
}