export default ['LocaleService', '$filter', (LocaleService, $filter) => {
    return (amount) => {
        let formatted;
        if (amount) {
            const fractionSize = amount.fractionSize || LocaleService.getLocaleInfo().currency.fractionSize;
            if (!amount.hasOwnProperty('amount')) {
                console.error('Currency without amount was passed into currency filter!');
            }
            formatted = $filter('currency')(
                amount.amount,
                angular.isDefined(amount.currency) ? LocaleService.getCurrencySymbol(amount.currency) : LocaleService.getLocaleInfo().currency.symbol,
                fractionSize
            );
        }
        return formatted;
    };
}];