import _ from 'underscore';
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import landingpagecriteria from '../config/landingpagecriteria.json';
import checkSegmentationOption from 'edge/platform/segmentation/CheckSegmentationOption';

const fn = ($scope, $state, $stateParams, $q, brandingData, CampaignDataService, queryParamsData, abExperimentService, appConfig) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        const matchCriteria = landingpagecriteria;
        const prioritisedMatchCriteria = [matchCriteria.branding, matchCriteria.queryParams, matchCriteria.campaign, matchCriteria.abExperiment];
        const defaultLandingPage = appConfig.home;
        $q.all([
            $q.resolve(brandingData),
            $q.resolve(queryParamsData),
            $q.resolve(CampaignDataService)
            // abExperimentService.getExperimentValue('experimentA') //we don't consider ab testing for landing page segmentation
        ]).then(values => {
            let landingPage;
            values.find((variantValue, idx) => {
                const segmentationCriteria = prioritisedMatchCriteria[idx];
                return _.pairs(segmentationCriteria).find(([lpName, criteria]) => {
                    const foundMatchingOption = checkSegmentationOption(criteria, variantValue);
                    landingPage = foundMatchingOption ? lpName : undefined;
                    return landingPage;
                });
            });
            landingPage = landingPage || defaultLandingPage;
            $state.go(landingPage, $stateParams.params);
        }, () => {
            $state.go(defaultLandingPage, $stateParams.params);
        });
    };

    return ctrl.create($scope);
};

fn.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$q',
    'brandingData',
    'CampaignDataService',
    'queryParamsData',
    'gwAbExperimentService',
    'state_plugin_config'
];

export default fn;
