import LobModelExtenstion from 'integration/quoteandbind/directives/LobOfferingSelection/util/LobOfferingHelper';
import _ from 'lodash';

export default function controller($filter, CoveragesUtil) {
    const $ctrl = this;
    const emptySchedules = [];

    const lobCode = $ctrl.lobCode();
    Object.assign($ctrl.offeringUtilFunctions.getLobData(), LobModelExtenstion.getLobExtension(lobCode));
    const coverageHelper = buildCoverageHelper(lobCode);
    coverageHelper.updateModels();

    $ctrl.updateModels = () => coverageHelper.updateModels();
    $ctrl.getBaseCoverageData = () => coverageHelper.getBaseCoverageData();
    $ctrl.getLobCoverageData = () => coverageHelper.getLobCoverageData();
    $ctrl.getOfferingHeaders = () => coverageHelper.getOfferingHeaders();
    $ctrl.getSchedulesModel = () => {
        if (coverageHelper.getSchedulesModel) {
            return coverageHelper.getSchedulesModel();
        }
        return emptySchedules;
    };
    $ctrl.doesAnyOfferingHaveBlockingUWIssues = doesAnyOfferingHaveBlockingUWIssues;
    $ctrl.getOfferingsWithUWIssues = getOfferingsWithUWIssues;
    $ctrl.uwIssueText = setUWIssueText();
    $ctrl.shouldDisplayPriceDifferenceFor = shouldDisplayPriceDifferenceFor;
    $ctrl.getPriceDiffFor = getPriceDiffFor;

    $ctrl.recalculate = (forms, offering) => {
        // check each form and ensure all are valid
        const formsValid = !_.find(forms, form => !CoveragesUtil.validateForm(form));

        if (formsValid) {
            $ctrl.offeringUtilFunctions.activateOffering(offering.branchCode, $ctrl.updateModels);
        }
    };

    function buildCoverageHelper(lob) {
        const CoverageHelperConstructor = LobModelExtenstion.getCoverageHelperConstructor(lob);
        return new CoverageHelperConstructor($filter, $ctrl.offeringUtilFunctions);
    }

    /**
     * Returns the appropriate display key to the user depending on how many UW Issues are present
     * @returns {String}
     * @private
     */
    function setUWIssueText() {
        const offeringsWithUWIssues = $ctrl.getOfferingsWithUWIssues();

        let uwIssueWarningText;
        if (offeringsWithUWIssues.length === 1) {
            uwIssueWarningText = $filter('translate')('quoteandbind.pa.directives.MultiQuoteView.UW Issue For 1 Offering', {
                offeringName: offeringsWithUWIssues[0].branchName
            });
        } else if (offeringsWithUWIssues.length === 2) {
            uwIssueWarningText = $filter('translate')('quoteandbind.pa.directives.MultiQuoteView.UW Issue For 2 Offerings', {
                offeringName1: offeringsWithUWIssues[0].branchName,
                offeringName2: offeringsWithUWIssues[1].branchName
            });
        } else if (offeringsWithUWIssues.length === 3) {
            uwIssueWarningText = $filter('translate')('quoteandbind.pa.directives.MultiQuoteView.UW Issue For 3 Offerings', {
                offeringName1: offeringsWithUWIssues[0].branchName,
                offeringName2: offeringsWithUWIssues[1].branchName,
                offeringName3: offeringsWithUWIssues[2].branchName
            });
        } else {
            uwIssueWarningText = $filter('translate')('quoteandbind.pa.directives.MultiQuoteView.UW Issue for n Offerings', {
                offeringCount: offeringsWithUWIssues.length
            });
        }
        return uwIssueWarningText;
    }

    /**
     * Determine whether any offerings have blocking Underwriting issues
     * @param {Array} offerings - array of quote offerings
     * @returns {Boolean}
     */
    function doesAnyOfferingHaveBlockingUWIssues(offerings) {
        return _.some(offerings, (offering) => offering.hasBlockingUWIssues);
    }

    /**
     * Returns all non CUSTOM offerings that have underwriting issues
     * @returns {Array} offerings
     * @private
     */
    function getOfferingsWithUWIssues() {
        return $ctrl.offeringUtilFunctions.getOfferedQuotes().filter(offering => offering.hasBlockingUWIssues && offering.branchName !== 'CUSTOM');
    }

    /**
     * Wrapper function to make it more readable
     * @param {string} offeringCode
     * @returns {boolean}
     */
    function shouldDisplayPriceDifferenceFor(offeringCode) {
        return $ctrl.offeringUtilFunctions.displayPriceDifference(offeringCode);
    }

    /**
     * Wrapper function to make it more readable
     * @param {string} offeringCode
     * @returns {string}
     */
    function getPriceDiffFor(offeringCode) {
        return $ctrl.offeringUtilFunctions.getFormattedPriceDifference(offeringCode);
    }
}