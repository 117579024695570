const nodes = [];

const navObj = (state, params) => {
    return {
        state,
        params
    };
};

const nodeObj = (to, toParams, from, fromParams) => {
    return {
        to: navObj(to, toParams),
        from: navObj(from, fromParams)
    };
};

const addNodeToHistory = (node) => {
    const to = node.to.state.name;
    if (nodes.length > 0) {
        if (nodes[0].from.state.name === to) {
            nodes.shift();
        } else {
            nodes.unshift(node);
        }
    } else {
        nodes.unshift(node);
    }
};

export default [() => {
    return {
        updateHistory: (to, toParams, from, fromParams) => {
            const node = nodeObj(to, toParams, from, fromParams);
            if (!from.abstract) {
                addNodeToHistory(node);
            }
        },
        recentHistory: () => {
            return nodes.length > 0 ? (nodes[0]) : null;
        }
    };
}];
