export default ['$q', 'JsonRPCService', 'policyjob.endpointConfig', 'policyjob.xCenterRouteConfig', ($q, JsonRPCService, endpointConfig, xCenterRouteConfig) => {

    const xCenterRoute = xCenterRouteConfig;
    const endpoint = endpointConfig.vinlookup;

    return {
        lookupVehicleByRegNumber: (reg) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupVehicleByRegNumber', [reg])
                .then((vehicleInfo) => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        lookupLCVVehicleByRegNumber: (reg) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupLCVVehicleByRegNumber', [reg])
                .then((vehicleInfo) => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findMakes: () => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findMakes', [])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findLCVMakes: () => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findLCVMakes', [])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findModels: (make) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findModels', [make])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findLCVModels: (make) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findLCVModels', [make])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findYears: (make, model) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findYears', [make, model])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findLCVYears: (make, model) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findLCVYears', [make, model])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findAbiCars: (abiVehicleLookupDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findAbiCars', [abiVehicleLookupDTO])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        findAbiLCVs: (abiVehicleLookupDTO) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'findAbiLCVs', [abiVehicleLookupDTO])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        }
    };
}];