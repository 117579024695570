import googleAnalyticsAdapter from 'gw-portals-tracking-js/adapters/GoogleAnalyticsAdapter';

const configuration = {
    cookieDomain: 'auto'
};

const plugins = [];

export default {
    configure(config) {
        Object.assign(configuration, config);
        return this;
    },

    enablePlugin(pluginName) {
        plugins.push(pluginName);
        return this;
    },

    $get: [
        'googleAnalytics', 'CampaignDataService',
        (ga, CampaignDataService) => {
            return googleAnalyticsAdapter(ga, configuration, plugins, CampaignDataService);
        }
    ]
};