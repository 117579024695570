import template from 'text!./templates/gw-carousel.html';

export default [() => ({
    'restrict': 'AE',
    'template': template,
    'transclude': true,
    'replace': true,
    'scope': {
        'interval': '=?',
        'wrapSlides': '=?'
    },
    'controller': ['$scope', '$element', '$transclude', ($scope) => {
        $scope.interval = $scope.interval || 5000;
        $scope.wrapSlides = $scope.wrapSlides || true;
        $scope.activeFn = () => {
        }; // Used to invoke child active slide function.
    }],
})];