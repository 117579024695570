export default ['TransportService', 'EventHub', '$q', (Transport, EventHub, $q) => {
    Transport.subscribe((promise, requestId, xCenter, serviceEndpoint, method, params) => {
        const t0 = Date.now();
        return promise.then((response) => {
            const elapsed = Date.now() - t0;
            EventHub.publish('tracking', 'serverCall', {
                path: serviceEndpoint,
                method,
                elapsedTime: elapsed,
                requestId: requestId,
                params,
                response
            });
            return response;
        }, (error) => {
            const elapsed = Date.now() - t0;
            let errorMessage = '';
            // Catch exception should any property before 'message' not be defined
            try {
                if (angular.isDefined(error.baseError.data.error.message)) {
                    errorMessage = error.baseError.data.error.message;
                }
            } catch (e) {
                console.error('No error message defined.');
            }

            EventHub.publish('tracking', 'serverCallError', {
                path: serviceEndpoint,
                method,
                elapsedTime: elapsed,
                params,
                errorMessage: errorMessage,
                requestId: requestId
            });
            return $q.reject(error);
        });
    });
}];