import templateStr from 'text!./templates/wizard-sidebar-additional.html';
import MockUpUtil from 'gw-portals-util-js/MockUpUtil';
import _ from 'lodash';
import moment from 'moment';

export default ['ModalService', 'qnb.EmailService', '$q', 'StateUtils', '$state', '$translate', 'EventHub', '$window', 'qnb.PolicyService', '$rootScope', '$stateParams', 'ViewModelService', (ModalService, QuoteEmailService, $q, StateUtils, $state, $translate, EventHub, $window, PolicyService, $rootScope, $stateParams, ViewModelService) => {
    return {
        restrict: 'A',
        template: templateStr,
        link: (scope) => {
            scope.quoteNumber = () => {
                const flowModel = StateUtils.activeDataForState(true).flowModel;
                if (!flowModel) {
                    return null;
                }
                const submission = flowModel.snapshot.value;
                return submission ? submission.quoteID : null;
            };

            scope.printQuote = () => {
                EventHub.publish('wizard', 'print');
                $window.print();
            };

            const mockUpUtil = new MockUpUtil();
            scope.flowModel = StateUtils.activeDataForState(true).flowModel;
            const submission = $stateParams.submission ? $stateParams.submission : StateUtils.activeDataForState(true).model;
            scope.showEmailAddress = () => {
                const emailAddress1 = _.get(StateUtils.activeDataForState(true).flowModel, 'snapshot.value.baseData.accountHolder.emailAddress1');
                const mockEmailAddress1 = mockUpUtil.getMockData('baseData.accountHolder.emailAddress1');
                return emailAddress1 && emailAddress1 !== mockEmailAddress1;
            };

            const saveAndEmailQuote = () => {
                const model = ViewModelService.create(angular.copy(submission.value), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', 'EmailQuote_itb');
        
                if (!model.bindData.chosenQuote.value) {
                    const selectedQuote = model.quoteData.offeredQuotes.value.find((quote) => quote.isCustom === true);
                    model.bindData.chosenQuote.value = selectedQuote.publicID;
                }
                model.bindData.communicationPreference_itb = model.baseData.accountHolder.communicationPreference_itb;
                return QuoteEmailService.updateQuotedSubmissionAndEmailQuote_AND(model.value);
            };

            scope.emailQuote = () => {
                const submission = StateUtils.activeDataForState(true).model;
                    $state.go('quoteEmail', {
                        submission: submission
                    });

            };
        
            
            $rootScope.emailQuoteDirect = () => {
                const submission = StateUtils.activeDataForState(true).flowModel.snapshot.value;
                const quoteID = submission.quoteID;
                const sessionID = submission.sessionUUID;
                const emailAddress = submission.baseData.accountHolder.emailAddress1;
                const emailInfo = {
                        quoteID,
                        sessionID,
                        emailAddress
                    };
                    if (submission.baseData.periodStatus === 'Quoted') {
                        saveAndEmailQuote();
                        $state.go('quoteEmail', {
                            submission: submission
                        });
                        
                    } else { // in all other cases just send the mail data
                        $state.go('quoteEmail', {
                            submission: submission
                        });
                    }
                    
            }
            

            const _getFlowModelSnapshot = () => {
                const flowModel = StateUtils.activeDataForState(true).flowModel;
                if (flowModel && flowModel.snapshot) {
                    return flowModel.snapshot.value;
                }
            };

            scope.submissionCreated = () => {
                const submission = _getFlowModelSnapshot();
                return (!!submission) && (!!submission.quoteID);
            };

            scope.submissionOnQuoteScreen = () => {
                const flowModel = StateUtils.activeDataForState(true).flowModel;
                return flowModel && flowModel.currentStep.name === 'quoteDetails';
            };

            scope.isDraftSubmission = () => {
                const submission = _getFlowModelSnapshot();
                return (!!submission) && (!!submission.quoteID) && submission.baseData.periodStatus === 'Draft';
            };
        },
    };
}];
