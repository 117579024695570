import './SelectLocation.scss';
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import _ from 'lodash';

import {wizardParentState} from '../RouteConfig';

const fn = ($scope, $state, originState, StateUtils, ViewModelService, AddressUtil, CPCoverablesService, ModalService, LocaleService, $stateParams) => {
    const ctrl = Object.create(BaseCtrl);
    const LocationDTOName = 'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.LocationDTO';
    const model = StateUtils.activeDataForState(true).model;
    const jobID = model.quoteID ? model.quoteID.value : model.jobID.value;
    const commercialProperty = model.lobData.commercialProperty;
    const existingLocations = commercialProperty.coverables.locations.value;

    $scope.phoneMask = LocaleService.getRegionSamplePhoneNumber().replace(/[0-8]/g, '9');

    const wizardState = $state.get(wizardParentState);
    wizardState.data.originState = originState; // Save info about where user comes from (used to redirect after cancelling)

    $scope.flowModel = wizardState.data.flow;
    if (wizardState.data.params && wizardState.data.params.selectedLocation) {
        $scope.location = wizardState.data.params.selectedLocation;
        wizardState.data.params.selectedLocation = undefined; // reset selected location in case we enter the location screen from within the wizard
        $scope.isNewlyAddedLocation = false;
    } else {
        $scope.isNewlyAddedLocation = $stateParams.model.selectedLocation ? $stateParams.model.selectedLocation.isNew : false;
        $scope.location = $scope.isNewlyAddedLocation ? $stateParams.model.selectedLocation : {address: {}, buildings: []};
    }

    $scope.locationView = ViewModelService.create($scope.location, 'pc', LocationDTOName);
    $scope.existingLocations = existingLocations.map(loc => {
        return ViewModelService.create(loc, 'pc', LocationDTOName);
    });

    const previouslySelectedLocation = getPreviouslySelectedLocation($scope.location, $scope.existingLocations);
    $stateParams.model = $stateParams.model || {};

    ctrl.init = () => {
        $scope.chosenExistingLocation = {
            value: previouslySelectedLocation || $scope.existingLocations[0],
            aspects: {
                required: false,
                inputCtrlType: 'typelist',
                availableValues: $scope.existingLocations,
                get valid() {
                    return true;
                }
            }
        };
        $scope.useExistingLocation = {
            value: !$scope.isNewlyAddedLocation,
            aspects: {
                required: false,
                inputCtrlType: 'boolean',
                get valid() {
                    return true;
                }
            }
        };

        $scope.locationToString = (loc) => {
            return AddressUtil.format(loc.value.address);
        };

        $scope.next = (callback) => {
            if ($scope.useExistingLocation.value) {
                if (_isThisALocationChange($scope.chosenExistingLocation.value.value)) {
                    _switchLocations($stateParams.model.selectedBuilding, $scope.chosenExistingLocation.value.value, $stateParams.model.selectedLocation, callback);
                } else {
                    _moveToNextStep($scope.chosenExistingLocation.value.value, callback);
                }
            } else {
                $scope.locationForm.submitted = true;
                validateFormAndCreateNewLocation($scope.locationForm, callback);
            }
        };
    };

    function getPreviouslySelectedLocation(selectedLocation, locations) {
        if (selectedLocation) {
            return _.find(locations, loc => {
                return selectedLocation.publicID === loc.value.publicID;
            });
        }
        return null;
    }

    function _isThisALocationChange(location) {
        return $stateParams.model.selectedLocation && $stateParams.model.selectedLocation.publicID !== location.publicID;
    }

    function _buildingsMatch(building, location) {
        return building ? _.find(location.buildings, currBuilding => {
            return currBuilding.publicID === building.publicID;
        }) : false;
    }

    function _switchLocations(building, location, prevLocation, callback, isNewlyAdded) {
        if (prevLocation.buildings.length <= 1 && !prevLocation.isPrimary) {
            if (_buildingsMatch(building, prevLocation) || angular.isDefined(prevLocation.isNew)) {
                _deleteLocation(building, location, prevLocation, callback, isNewlyAdded);
            } else {
                _moveToNextStep(location, callback, isNewlyAdded);
            }
        } else if (building) {
            _deleteBuilding(building, location, prevLocation, callback, isNewlyAdded);
        } else {
            _moveToNextStep(location, callback, isNewlyAdded);
        }
    }

    function validateFormAndCreateNewLocation(locationForm, callback) {
        if (locationForm.invalid) {
            callback();
        } else {
            const newLocation = $scope.locationView.value;

            saveLocation(newLocation)
                .then(location => {
                    _.remove(model.lobData.commercialProperty.coverables.locations.value, (currLocation) => {
                        return currLocation.publicID === location.publicID;
                    });
                    commercialProperty.coverables.locations.value.push(location);
                    if (_isThisALocationChange(location)) {
                        _switchLocations($stateParams.model.selectedBuilding, location, $stateParams.model.selectedLocation, callback, true);
                    } else {
                        _moveToNextStep(location, callback, true);
                    }
                })
                .catch(() => {
                    ModalService.showError(
                        'quoteandbind.cp.Unable to add location',
                        'quoteandbind.cp.Please check for errors and try again'
                    ).result.then(callback());
                });
        }
    }

    function _moveToNextStep(location, callback, locationIsNew) {
        $stateParams.model.selectedLocation = location;
        if (locationIsNew) {
            $stateParams.model.selectedLocation.isNew = true;
        }
        callback($stateParams.model);
    }

    function saveLocation(location) {
        return $scope.isNewlyAddedLocation ?
            CPCoverablesService.updateCPLocation(jobID, location, SessionUtil.getSessionUUID(model)) :
            CPCoverablesService.addCPLocation(jobID, location, SessionUtil.getSessionUUID(model));
    }

    function _deleteLocation(building, location, prevLocation, callback, isBeingReplaced) {
        CPCoverablesService.removeCPLocation(jobID, prevLocation, SessionUtil.getSessionUUID(model))
            .then(() => {
                _.remove(model.lobData.commercialProperty.coverables.locations.value, (currLocation) => {
                    return currLocation.publicID === prevLocation.publicID;
                });
                if (building) {
                    saveBuilding(building, location, callback, isBeingReplaced);
                } else {
                    _moveToNextStep(location, callback, isBeingReplaced);
                }
            })
            .catch(() => ModalService.showError('gateway.views.modal.Error', 'quoteandbind.cp.views.Error cleaning up building and location that were being updated, double-check saved data.').result.then(callback()));
    }

    function _deleteBuilding(building, location, prevLocation, callback, isBeingReplaced) {
        CPCoverablesService.removeCPBuilding(jobID, prevLocation.publicID, building.publicID, SessionUtil.getSessionUUID(model))
            .then(() => {
                const loc = _.find(model.lobData.commercialProperty.coverables.locations.value, (currLocation) => {
                    return currLocation.publicID === prevLocation.publicID;
                });

                _.remove(loc.buildings, (currentBuilding) => {
                    return currentBuilding.publicID === building.publicID;
                });
                saveBuilding(building, location, callback, isBeingReplaced);
            })
            .catch(() => ModalService.showError('gateway.views.modal.Error', 'quoteandbind.cp.views.Error cleaning up building and location that were being updated, double-check saved data.').result.then(callback()));
    }

    function saveBuilding(building, location, callback, isBeingReplaced) {
        CPCoverablesService.addCPBuilding(jobID, location.publicID, building, SessionUtil.getSessionUUID(model))
            .then((result) => {
                $stateParams.model.selectedBuilding = result;
                _moveToNextStep(location, callback, isBeingReplaced);
            })
            .catch(() => ModalService.showError('gateway.views.modal.Error', 'quoteandbind.cp.views.Error cleaning up building and location that were being updated, double-check saved data.').result.then(callback()));
    }

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$state', 'originState', 'StateUtils', 'ViewModelService', 'AddressUtil', 'qnb.cpCoverablesService', 'ModalService', 'LocaleService', '$stateParams'];

export default fn;
