class CPSpreadsheetProcessorCtrl {

    constructor(StateUtils) {
        this.model = StateUtils.activeDataForState(true).model;
        this.hasBuildings = this.model.lobData.commercialProperty.coverables.locations.value.some(loc => loc.buildings && loc.buildings.length > 0);
    }
}

CPSpreadsheetProcessorCtrl.$inject = ['StateUtils'];

export default CPSpreadsheetProcessorCtrl;
