import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./cp-building-summary-directive.html';
import styles from './BuildingSummary-hashed.scss';
import PropertyCodeUtil from 'edge/quoteandbind/common/util/PropertyCodeUtil';

export default [() => ({
    restrict: 'E',
    replace: true,
    require: 'gwCpPremisesSummary',
    scope: {
        building: '=',
        edit: '&?',
    },
    transclude: true,
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', '$state', 'InsuranceSuiteMetadata', ($scope, $state, insuranceSuiteMetadata) => {

        const typelist = insuranceSuiteMetadata.get('pc').types.getTypelist('Sprinklered');
        $scope.sprinklered = ($scope.building.percentageSprinklered) ? typelist.getCode($scope.building.percentageSprinklered).name : null;
        $scope.formatClassCode = PropertyCodeUtil.toString;

        if (!$scope.edit) {
            $scope.edit = () => {
                $state.go('cpBuildingWizardEditBuilding', {building: $scope.building});
            };
        }
    }]
})];
