class QuoteCtrl {

    constructor(StateUtils, ViewModelService, EndorsementService, $q, ModalService, paymentType) {
        this.EndorsementService = EndorsementService;
        this.ModalService = ModalService;
        this.$q = $q;
        this.flowModel = StateUtils.activeDataForState(true).flowModel;

        this.$onInit = () => {
            this.model = StateUtils.activeDataForState(true).model;

            this.payInFullFlag = false;
            this.paymentPlan = this.model.paymentPlan.value;
            this.changeInCost = this.model.transactionCost.value;
            this.effectiveDate = this.model.baseData.effectiveDate.value;
            this.startDate = this.model.baseData.periodStartDate.value;
            this.endDate = this.model.baseData.periodEndDate.value;
            this.payInFull = this.payInFull.bind(this);
            this.hidePayInFull = this.hidePayInFull.bind(this);
            this.paymentType = paymentType;

            this.paymentDetails = {
                paymentMethod: this.paymentType.WIRE,
                creditCardData: {},
                bankAccountData: {}
            };

            this.paymentView = ViewModelService.create(
                this.paymentDetails, 'pc',
                'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO'
            );
        };
    }

    payInFull(nextFn, form) {
        if (form.invalid) {
            return nextFn();
        }
        this.bind().then(() => this.flowModel.next(this.model));
    }

    redistribute() {
        this.paymentDetails.paymentMethod = 'redistribute';
        this.bind().then(() => this.flowModel.next(this.model));
    }

    isWire() {
        return this.paymentDetails.paymentMethod === this.paymentType.WIRE;
    }

    isCreditCard() {
        return this.paymentDetails.paymentMethod === this.paymentType.CREDIT_CARD;
    }

    displayPayInFull() {
        this.payInFullFlag = true;
    }

    hidePayInFull() {
        this.payInFullFlag = false;
    }

    bind() {
        switch (this.paymentDetails.paymentMethod) {
            case this.paymentType.WIRE:
                delete this.paymentDetails.creditCardData;
                break;
            case this.paymentType.CREDIT_CARD:
                delete this.paymentDetails.bankAccountData;
                break;
            case this.paymentType.REDISTRIBUTE:
                delete this.paymentDetails.creditCardData;
                delete this.paymentDetails.bankAccountData;
                break;
            default:
                delete this.paymentDetails.creditCardData;
                delete this.paymentDetails.bankAccountData;
        }

        const deferred = this.$q.defer();
        this.EndorsementService.bind(this.model.jobID.value, this.paymentDetails)
            .then(() => deferred.resolve())
            .catch(() => this.ModalService.showError('gw-portals-policytransaction-common-angular.components.payment.paymentCtrl.Error binding transaction', 'gw-portals-policytransaction-common-angular.components.payment.paymentCtrl.Please check for errors and try again'));
        return deferred.promise;
    }
}

QuoteCtrl.$inject = ['StateUtils', 'ViewModelService', 'endorsement.EndorsementService', '$q', 'ModalService', 'paymentType'];

export default QuoteCtrl;