/**
 * Function that accepts a view model object and returns a function to recreate the same
 *
 * @param {Object} viewModel - view model object
 *
 * @returns {Object} clone of view model object passed in as a parameter
 */
export default function getViewModelBuilder(viewModel) {
    let Constructor;
    let vmBuilder;
    let valueJSON;

    if (viewModel) {
        if (viewModel.value && viewModel.aspects && viewModel.aspects._vmFromData) {
            // view model created created with gw viewmodel API
            Constructor = viewModel.value.constructor;
            vmBuilder = viewModel.aspects._vmFromData;
            valueJSON = JSON.stringify(viewModel.value);
        } else {
            // just a plain JS Object
            Constructor = viewModel.constructor;
            valueJSON = JSON.stringify(viewModel);
        }
    }
    if (!valueJSON) {
        return undefined;
    }
    let value = JSON.parse(valueJSON);
    value = new Constructor(value);
    if (vmBuilder) {
        // build a view model object
        return vmBuilder(value);
    }
    return value;
}
