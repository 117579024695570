import 'edge/quoteandbind/common/models/Submission';
import PASubmissionDataExtension from 'edge/quoteandbind/pa/models/PASubmissionDataExtension';
import BOPSubmissionDataExtender from 'edge/quoteandbind/bop/models/BOPSubmissionDataExtender';
import PmSubmissionDataExtension from 'edge/quoteandbind/pm/models/PmSubmissionDataExtension'; // ANDIE

export default {
    mixinLobDataExtensions: (submission) => {
        if (submission && submission.baseData && submission.baseData.productCode === 'PersonalAuto') {
            submission.lobData = submission.lobData || {};
            submission.lobData.personalAuto = new PASubmissionDataExtension(submission);
        }
        if (submission && submission.baseData && submission.baseData.productCode === 'BusinessOwners') {
            BOPSubmissionDataExtender.extend(submission);
        }
        if (submission && submission.baseData && submission.baseData.productCode === 'PersonalMotor') { // ANDIE
            submission.lobData = submission.lobData || {};
            submission.lobData.personalMotor = new PmSubmissionDataExtension(submission);
        }
        if (submission && submission.baseData && submission.baseData.productCode === 'TraditionalMotor') { // ANDIE
            submission.lobData = submission.lobData || {};
            submission.lobData.personalMotor = new PmSubmissionDataExtension(submission);
        }
        if (submission && submission.baseData && submission.baseData.productCode === 'CommercialVehicle') { // ANDIE
            submission.lobData = submission.lobData || {};
            submission.lobData.personalMotor = new PmSubmissionDataExtension(submission);
        }
    }
};
