import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

const versionName = ApplicationFeatures.getAllFeatures().versionName;

/**
 * Util to provide L10N keys depending on versionName available for the app
 * @param {String} [translationIdBase] - prefix of the l10n ID (if the versionName is available) or the l10n ID to be used
 * @returns {String}
 */
export default (translationIdBase) => {
    let l10nId = `${translationIdBase}`; // l10n ID to be used

    if (versionName) {
        l10nId = `${translationIdBase}.${versionName}`;
    }

    return l10nId;
};