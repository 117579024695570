/**
 * @name TranslateService
 * @description
 * Service for locale change watching and updating translations
 * Provide workaround to avoid many $rootScope.$translateChangeSuccess and $scope.$destroy listeners
 */

import KeyTranslator from './KeyTranslator';
import _ from 'underscore';

export default ['$translate', '$rootScope', ($translate, $rootScope) => {
    // VARS
    const watchers = {}; // contains all translations watchers
    let watcherId = 0; // ID to increment and store for each translation watcher
    const scopes = []; // contains unique scopes to identify $destroy listeners

    const keyTranslators = {}; // Translators for the keys.

    // METHODS

    /**
     * Retrieves a translator for the key. Creates a new translator if it is not present yet.
     *
     * @param {String} key
     * @returns {*}
     */
    function getKeyTranslator(key) {
        if (keyTranslators.hasOwnProperty(key)) {
            return keyTranslators[key];
        }
        const res = KeyTranslator.create($translate.instant, key, $translate(key));
        keyTranslators[key] = res;
        return res;
    }


    /** Narrows down the translator with the specific prefix. Instead of
     * base.immediate('a.b.c.d.Key')
     * base.immediate('a.b.c.d.AnotherKey')
     * you could use
     * const T = base.narrowDown('a.b.c.d')
     * T.immediate('Key')
     * T.immediate('AnotherKey')
     * @param {Object} base base translator.
     * @param {String} prefix prefix to prepend to each translation key.
     *
     * @returns {Object}
     */
    function narrowDown(base, prefix) {
        return {
            'translate': (params) => {
                params = Object.create(params);
                if (typeof (params.displayKey) === 'object') {
                    params.displayKey = {
                        'key': prefix + params.displayKey.key,
                        'args': params.displayKey.args
                    };
                } else {
                    params.displayKey = prefix + params.displayKey;
                }
                return base.translate(params);
            },
            'instant': (key, params) => {
                return base.instant(prefix + key, params);
            },
            'bindTranslation': (key, params) => {
                return base.bindTranslation(prefix + key, params);
            },
            'apply': (key, params) => {
                return base.apply(prefix + key, params);
            },
            'narrowDown': (otherPrefix) => {
                return narrowDown(base, `${prefix}${otherPrefix}.`);
            }
        };
    }

    /**
     * Remove translation watch data on scope.$destroy
     * If there is already listener for current scope- adds current watcher's ID there to be destroyed
     *
     * @param {Scope} scope
     * @param {Number} _watcherId Translation watcher ID
     */
    const addDestroyListener = (scope, _watcherId) => {
        if (scopes.indexOf(scope) === -1) {
            // there isn't listener of the current scope $destroy
            scope.$on('$destroy', () => {
                watchers[_watcherId].removeOnDestroy.forEach((watcherIdToBeRemoved) => {
                    delete watchers[watcherIdToBeRemoved];
                });
                scopes.splice(scopes.indexOf(scope), 1); // remove $scope from "scopes"
            });
            scopes.push(scope); // add $scope to "scopes"
        } else {
            // there is already listener of the current scope $destroy
            watchers[_watcherId].removeOnDestroy.push(_watcherId); // add current watcher to be removed on scope.$destroy
        }
    };

    /**
     * Provides current translation via Promise
     * @param {Object} params
     * @param {Object} params.object object inside which translation has to be provided. Object- to pass-by-reference translation inside code
     * @param {String} params.propName Property name where correct translation has to be provided
     * @param {String|Object} params.displayKey display entity to be translated
     * @param {Object} params.displayKeyArgs arguments for string interpolation during translation
     * @param {Number} params._watcherId Translation watcher ID
     */
    const getCurrentTranslation = (params) => {
        $translate(params.displayKey, params.displayKeyArgs)
            .then((translatedValue) => {
                if (!watchers[params._watcherId]) {
                    return; // e.g. when scope is already destroyed
                }
                params.object[params.propName] = translatedValue; // provide translated value to object
            });
    };

    /**
     * Provides correct translations with update on locale change
     *
     * @param {Object} params
     * @param {Object} params.object object inside which translation has to be provided. Object- to pass-by-reference translation inside code
     * @param {String} params.propName Property name where correct translation has to be provided
     * @param {String|Object} params.displayKey display entity to be translated
     * @param {Scope} [params.scope] scope to remove translation watcher data on $destroy
     *
     * Examples:
     *
     * TranslateService.translate({
     *  object: obj1,
     *  displayKey: 'platform.inputs.address-details.City',
     *  propName: 'name',
     *  scope: $scope
     * });
     *
     * TranslateService.translate({
     *  object: obj2,
     *  displayKey: {
     *      "args": {
     *          "max": 60,
     *          "min": 0
     *      },
     *      "key": "displaykey.Edge.Web.Api.Model.Size"
     *  },
     *  propName: 'name'
     * });
     *
     */
    const translate = (params) => {
        const object = params.object; // required
        const propName = params.propName; // required

        let displayKey;
        let displayKeyArgs = {};

        if (typeof (params.displayKey) === 'object') {
            displayKey = params.displayKey.key;
            displayKeyArgs = params.displayKey.args;
        } else {
            displayKey = params.displayKey;
        }

        const scope = params.scope; // optional
        const _watcherId = ++watcherId;

        watchers[_watcherId] = {
            object,
            propName,
            displayKey,
            displayKeyArgs,
            scope,
            removeOnDestroy: [_watcherId] // watchers objects to be removed on scope.$destroy (might be extended with others to avoid many scope.$destroy listeners)
        };

        getCurrentTranslation({
            object,
            propName,
            displayKey,
            displayKeyArgs,
            _watcherId
        });

        if (scope) {
            addDestroyListener(scope, _watcherId);
        }
    };

    /**
     * Update all translations using resources file for selected locale
     */
    const updateTranslations = () => {
        Object.keys(watchers).forEach((_watcherId) => {
            const watcher = watchers[_watcherId];
            watcher.object[watcher.propName] = $translate.instant(watcher.displayKey, watcher.displayKeyArgs);
        });
        _.values(keyTranslators).forEach((keyTranslator) => {
            keyTranslator.refresh();
        });
    };

    // EVENTS
    $rootScope.$on('$translateChangeSuccess', updateTranslations);

    // RETURN
    const baseTranslator = {
        translate,
        /**
         * Returns an instant translation.
         *
         * @param {String} key
         * @param {Array} args
         *
         * @returns {Function}
         */
        instant: (key, args) => {
            return getKeyTranslator(key).translate(args);
        },

        /** Returns a function used to translate value in current locale.
         * <em>Warning</em>. This is not a monadic bind.
         *
         * @param {String} key
         * @param {Array} args
         *
         * @returns {Function}
         */
        bindTranslation: (key, args) => {
            return _.partial(getKeyTranslator(key).translate, args);
        },

        /** "Applicative" application of the translation. Each argument could be a function used to evaluate an
         * argument or the constant value itself. Functions are called (without arguments) to get actual value
         * arguments during each call.
         *
         * @param {String} key
         * @param {Array} args
         *
         * @returns {Function}
         */
        apply: (key, args) => {
            const translator = getKeyTranslator(key);
            return () => {
                return translator.translate(_.mapObject(args, (argValue) => {
                    return _.isFunction(argValue) ? argValue() : argValue;
                }));
            };
        },
        'narrowDown': (prefix) => {
            return narrowDown(baseTranslator, `${prefix}.`);
        }
    };

    return baseTranslator;
}];
