import routeConfig from './config/RouteConfig';
import 'angularRouter';
import 'edge/platform/widgets/carousel/module';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import 'plugin?auth-type!gw-portals-auth-angular/module';

export default angular.module(
    'edge.platform.segmentation.demolandingpage',
    ['ui.router', 'edge.platform.widgets.carousel', 'edge.platform.auth']
)

    .config(['$stateProvider', ($stateProvider) => {
        for (const stateName in routeConfig) {
            if (routeConfig.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, routeConfig[stateName]);
            }
        }
    }])
    .run(['$state', 'state_plugin_config', '$q', 'oAuth', '$timeout', ($state, appConfig, $q, oAuth, $timeout) => {
        const authenticationService = AuthenticationService(oAuth);
        const handlerFn = () => {
            $timeout(() => {
                // need to put inside $timeout to allow a digest cycle to run and set initial state
                const homePage = appConfig.home;
                if ($state.current.name === homePage) {
                    $state.go('loadingLandingPage', {
                        params: $state.params
                    });
                }
            });
        };

        // when app is loaded go to a loading page while we wait for authentication response and any segmentation decisions to be made
        authenticationService.waitForLoginRequestComplete.then(handlerFn, handlerFn);
    }]);