import cssUtil from '../../../../api/gw-portals-util-js/CssUtil';
import templateStr from 'text!./InfoBox.html';
import infoBoxCtrl from './InfoBoxCtrl';
import styles from './InfoBox-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(templateStr, styles);

export default [function () {
    return {
        restrict: 'E',
        template: hashedTemplate,
        scope: {
            message: '@',  // Message that show in the info box.
            iconPath: '@', // Image file source path. If not specify, by default a info icon will show on the info box
            show: '=?',     // To show or hide the info box. if not specify, by default is set to true.
            readonly: '=?' //To show or hide Close button at right bottom which hides infoBox on click
        },
        controller: infoBoxCtrl,
        controllerAs: '$ctrl'
    };
}];