import templateStr from 'text!./CommonOfferingSelection.html';
import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import _ from 'lodash';

export default [() => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            quoteData: '=',
            buy: '=',
            error: '=',
            lobCode: '@'
        },
        controller: ['$scope', '$filter', '$state', 'qnb.CustomQuoteService', 'ModalService', function ($scope, $filter, $state, CustomQuoteService, ModalService) {

            // Cache the state of each quote on initialization
            const initialSubmissionCoverages = _buildCoverageCache();
            const _isLoadingCallFunctions = {};
            // Initially setting the payment period to monthly
            $scope.isPaymentPeriodMonthly = {value: true};
            $scope.offeringUtilFunctions = {
                getPeriodPrices: getPeriodPrices,
                isStale: isStale,
                isLoading: isLoading,
                activateOffering: activateOffering,
                revertCustomQuote: revertCustomQuote,
                revertCustomQuoteAndStopPropagation: revertCustomQuoteAndStopPropagation,
                hasChangedSinceInitialise: hasChangedSinceInitialise,
                buildCustomQuote: buildCustomQuote,
                syncCoverages: syncCoverages,
                getLobData: getLobData,
                getLobOfferings: getLobOfferings,
                getOfferedQuotes: getOfferedQuotes,
                getIsLoadingFunc: getIsLoadingFunc,
                shouldDisableBuyOrRecalculateButton: shouldDisableBuyOrRecalculateButton,
                displayPriceDifference: displayPriceDifference,
                getFormattedPriceDifference: getFormattedPriceDifference,
                hasQuotedPremium: hasQuotedPremium,
                getPricePlaceholder: getPricePlaceholder
            };
            $scope.quoteStatus = {};

            /**
             * Getting the amount of the payment period. Either the monthly or yearly price.
             * @param {object} offering
             * @param {boolean} isPaymentPeriodMonthly
             * @returns {*|amount}
             */
            function getPeriodPrices(offering, isPaymentPeriodMonthly) {
                return isPaymentPeriodMonthly.value ? offering.premium.monthlyPremium : offering.premium.total;
            }

            /**
             * Determine if an offering has changed since the controller was intialised
             * @param {string} offeringCode
             * @returns {*|boolean}
             */
            function hasChangedSinceInitialise(offeringCode) {
                return $scope.quoteStatus[offeringCode] && $scope.quoteStatus[offeringCode].changedSinceInitialise;
            }

            /**
             * Revert a quote and offering data to the cached state for a particular offering
             * @param {string} offeringCode
             * @param {function()} updateModel - callback to be executed on end of action
             */
            function revertCustomQuote(offeringCode, updateModel) {
                // Get and reset old coverages
                const oldCoverages = _.cloneDeep(initialSubmissionCoverages[offeringCode]);
                const currentCoverages = $scope.quoteData.submission.value.lobData[$scope.lobCode].offerings;
                const currentCoveragesIndex = _.findIndex(currentCoverages, (quote => quote.branchCode === offeringCode));
                currentCoverages[currentCoveragesIndex] = oldCoverages;

                const customQuote = buildCustomQuote(offeringCode);
                syncCoverages(customQuote, updateModel, true).then(() => {
                    $scope.quoteStatus[offeringCode] = {
                        showLoading: false,
                        isStale: true,
                        changedSinceInitialise: false
                    };
                });
            }

            function revertCustomQuoteAndStopPropagation(offeringCode, updateModel, event) {
                $scope.offeringUtilFunctions.revertCustomQuote(offeringCode, updateModel);
                event.stopPropagation();
            }

            /**
             * Builds a cache of all the offerings
             * @returns {Object}
             * @private
             */
            function _buildCoverageCache() {
                const currentOfferings = $scope.quoteData.submission.value.lobData[$scope.lobCode].offerings;
                const cacheCoverages = {};
                currentOfferings.forEach(offering => {
                    cacheCoverages[offering.branchCode] = _.cloneDeep(offering);
                });
                return cacheCoverages;
            }

            /**
             * Determine whether the quote for a particular offering is stale and needs to be recalculated
             * @param {string} offeringCode - String
             * @returns {boolean|isStale|*}
             */
            function isStale(offeringCode) {
                return ($scope.quoteStatus[offeringCode] && $scope.quoteStatus[offeringCode].isStale) || _isOfferingInDraft(offeringCode);
            }

            /**
             * Determine whether a quote is in Draft based on whether the premium exists on the offered quote
             * @param {string} offeringCode - the offering code of the offering
             * @returns {boolean}
             * @private
             */
            function _isOfferingInDraft(offeringCode) {
                const quote = getOfferedQuotes().find(offering => offering.branchCode === offeringCode);

                return quote.status === 'Draft';
            }

            /**
             * Function that initiates an action on an offering based on whether it's data is stale or not.
             * @param {string} offeringCode
             * @param {function()} updateModel - callback to be executed on end of action
             */
            function activateOffering(offeringCode, updateModel) {
                if (isStale(offeringCode)) {
                    _recalculateAction(offeringCode, updateModel);
                } else {
                    _buyAction(offeringCode);
                }
            }

            /**
             * Initiates a recalculate action
             * @param {string} offeringCode
             * @param {function()} updateModel - callback to be executed on end of action
             */
            function _recalculateAction(offeringCode, updateModel) {
                const customQuote = buildCustomQuote(offeringCode);
                _updateQuote(customQuote, updateModel);
            }

            /**
             * Initiates a buy action
             * @param {string} offeringCode
             * @returns {boolean}
             */
            function _buyAction(offeringCode) {
                const selectedQuote = $scope.quoteData.submission.value.quoteData.offeredQuotes.find(offering => offering.branchCode === offeringCode);
                const termInMonths = selectedQuote.premium.termMonths;
                // setting the policy period as the selected version on the current submission
                const sessionUUID = SessionUtil.getSessionUUID($scope.quoteData.submission);
                CustomQuoteService.setSelectedVersionOnSubmission($scope.quoteData.submission.value.quoteID, selectedQuote.branchName, sessionUUID);
                $scope.buy(selectedQuote, termInMonths);
                return offeringCode;
            }

            /**
             * Updates the coverages for a custom quote
             * @param {Object} offeringUpdate - The offering that is to be synced
             * @param {function()} updateModel - callback to be executed on end of sync
             * @param {Boolean} forceUpdateAllCoverages - determine whether to force the sync of all coverages
             * @returns {Promise}
             * @private
             */
            function syncCoverages(offeringUpdate, updateModel, forceUpdateAllCoverages = false) {
                const offeringCode = offeringUpdate.quote.branchCode;
                let operation;
                $scope.quoteStatus[offeringCode] = {
                    showLoading: true,
                    isStale: true,
                    changedSinceInitialise: true
                };
                if (forceUpdateAllCoverages) {
                    operation = CustomQuoteService.forceUpdateCustomQuoteCoverages(offeringUpdate);
                } else {
                    operation = CustomQuoteService.updateCustomQuoteCoverages(offeringUpdate);
                }
                operation.then((offering) => {
                    // Update local offering with new one from xcenter
                    const oldOffering = getLobOfferings().find((quote) => quote.branchCode === offeringCode);
                    oldOffering.coverages = offering.coverages[$scope.lobCode];
                    // Update local quote status with new one from xcenter
                    const oldQuote = getOfferedQuotes().find((quote) => quote.branchCode === offeringCode);
                    oldQuote.status = offering.quote.status;
                    // Invalidate the cached LOB array models and update the model on the scope
                    getLobData().updateModel();
                    if (updateModel) {
                        updateModel();
                    }
                });
                operation.catch(_handleSyncError);
                operation.finally(() => {
                    $scope.quoteStatus[offeringCode].showLoading = false;
                    $scope.CustomQuoteForm.submitted = false;
                });
                return operation;
            }

            /**
             * Updates the calculated value of a quote
             * @param {Object} offeringUpdate - The offering that is to be synced
             * @param {function()} updateModel - callback to be executed on end of update
             * @private
             */
            function _updateQuote(offeringUpdate, updateModel) {
                const offeringCode = offeringUpdate.quote.branchCode;
                const previousChangedSinceInitialise = $scope.quoteStatus[offeringCode] && $scope.quoteStatus[offeringCode].changedSinceInitialise;
                $scope.quoteStatus[offeringCode] = {
                    showLoading: true,
                    isStale: true,
                    changedSinceInitialise: previousChangedSinceInitialise,
                    oldPrice: offeringUpdate.quote.premium.monthlyPremium ? offeringUpdate.quote.premium.monthlyPremium.amount : 0
                };
                const operation = CustomQuoteService.updateCustomQuote(offeringUpdate);
                operation.then((updatedCustomQuote) => {
                    // Update local offering with new one from xcenter
                    const oldOffering = getLobOfferings().find((quote) => quote.branchCode === offeringCode);
                    oldOffering.coverages = updatedCustomQuote.coverages[$scope.lobCode];

                    const oldQuote = getOfferedQuotes().find((quote) => quote.branchCode === offeringCode);
                    oldQuote.premium = updatedCustomQuote.quote.premium;
                    oldQuote.hasBlockingUWIssues = updatedCustomQuote.quote.hasBlockingUWIssues;
                    oldQuote.status = updatedCustomQuote.quote.status;
                    $scope.quoteStatus[offeringCode].newPrice = updatedCustomQuote.quote.premium.monthlyPremium.amount;
                    // price difference should not be displayed if the old price was not set (happens during retrieval of offerings that were in draft) or the difference is 0
                    $scope.quoteStatus[offeringCode].shouldDisplayPriceDifference = $scope.quoteStatus[offeringCode].oldPrice > 0 && _calculateAndSetPriceDifference(offeringCode) !== 0;
                    $scope.quoteStatus[offeringCode].isStale = false;
                    if (updateModel) {
                        updateModel();
                    }
                });
                operation.catch(_handleQuoteError);
                operation.finally(() => {
                    $scope.quoteStatus[offeringCode].showLoading = false;
                    $scope.CustomQuoteForm.submitted = false;
                });
            }

            /**
             * Function to display price difference between old and new quote
             * @param {string} offeringCode
             * @returns {boolean}
             */
            function displayPriceDifference(offeringCode) {
                if ($scope.quoteStatus[offeringCode] != null) {
                    return $scope.quoteStatus[offeringCode].shouldDisplayPriceDifference;
                }
                return false;
            }

            /**
             * Function to round the price difference to 2 decimal places and apply currency filter
             * @param {string} offeringCode
             * @returns {string} formatted amount
             */
            function getFormattedPriceDifference(offeringCode) {
                const roundedPriceDiff = Math.round($scope.quoteStatus[offeringCode].priceDifference * 100) / 100;
                return $filter('gwCurrency')({amount: roundedPriceDiff});
            }

            /**
             * Function to calculate the price difference and set it for the offering code
             * @param {string} offeringCode
             * @returns {number} price difference
             */
            function _calculateAndSetPriceDifference(offeringCode) {
                const priceDiff = $scope.quoteStatus[offeringCode].newPrice - $scope.quoteStatus[offeringCode].oldPrice;
                $scope.quoteStatus[offeringCode].priceDifference = priceDiff;
                return priceDiff;
            }

            /**
             * Function to invoke in the event that a call to _syncCoverages fails
             * @private
             */
            function _handleSyncError() {
                const message = $filter('translate')('quoteandbind.common.directives.CommonOfferingSelection.CommonOfferingSelection.Invalid Coverage Option');
                const description = $filter('translate')('quoteandbind.common.directives.CommonOfferingSelection.CommonOfferingSelection.Sorry, but that coverage option is invalid, please select a different value');
                ModalService.showError(message, description);
            }

            /**
             * Function to invoke in the event that a call to _updateQuote fails
             * @private
             */
            function _handleQuoteError() {
                $scope.error($scope.quoteData.submission);
            }

            /**
             * Determine whether a particular offering is loading
             * @param {string} offeringCode
             * @returns {boolean}
             */
            function isLoading(offeringCode) {
                return !!($scope.quoteStatus[offeringCode] && $scope.quoteStatus[offeringCode].showLoading);
            }

            /**
             * Creates a callback that determines whether a particular offering is being synced with the xcenter
             * @param {string} offeringCode - name of the offering
             * @returns {function()} - Callback to determine whether an offering is updating.
             * @private
             */
            function getIsLoadingFunc(offeringCode) {
                const cachedFunction = _isLoadingCallFunctions[offeringCode];
                if (cachedFunction) {
                    return cachedFunction;
                }
                const newFunction = () => {
                    return $scope.offeringUtilFunctions.isLoading(offeringCode);
                };
                _isLoadingCallFunctions[offeringCode] = newFunction;
                return newFunction;
            }

            /**
             * Getter for the submissions LOB specific data. xcenter Read/Writable
             * @returns {*}
             */
            function getLobData() {
                return $scope.quoteData.submission.lobData[$scope.lobCode].value;
            }

            /**
             * Getter for the submissions LOB specific offering data. Read/Writable
             * @returns {*}
             */
            function getLobOfferings() {
                return getLobData().offerings;
            }

            /**
             * Getter for the submission LOB Agnostic specific data. xcenter Readonly
             * @returns {*}
             * @private
             */
            function getOfferedQuotes() {
                return $scope.quoteData.submission.quoteData.offeredQuotes.value;
            }

            /**
             * Build a custom quote using an offering code
             * @param {string} offeringCode
             * @returns {{quote, coverages: {personalAuto}, quoteID, sessionUUID: (string|number), periodStart, periodEnd}}
             * @private
             */
            function buildCustomQuote(offeringCode) {
                const offering = getOfferedQuotes().find(quote => quote.branchCode === offeringCode);
                const coverages = getLobOfferings().find(quote => quote.branchCode === offeringCode).coverages;
                const customQuote = {
                    quote: offering,
                    coverages: {},
                    quoteID: $scope.quoteData.submission.quoteID.value,
                    sessionUUID: $scope.quoteData.submission.sessionUUID.value,
                    periodStart: $scope.quoteData.submission.baseData.periodStartDate.value,
                    periodEnd: $scope.quoteData.submission.baseData.periodEndDate.value
                };
                customQuote.coverages[$scope.lobCode] = coverages;
                return customQuote;
            }

            /**
             * Determine whether to disable the buy/recalculate button depending on if the offering is stale or has UW Issues
             * @param {Object} offering
             * @returns {Boolean}
             * @private
             */
            function shouldDisableBuyOrRecalculateButton(offering) {
                const offeringCode = offering.branchCode;
                const offeringIsLoading = $scope.offeringUtilFunctions.isLoading(offeringCode);
                const hasUnderwritingIssues = offering.hasBlockingUWIssues;
                const notStale = !$scope.offeringUtilFunctions.isStale(offeringCode);
                return offeringIsLoading || (hasUnderwritingIssues && notStale);
            }

            function hasQuotedPremium(offering) {
                return !!offering.premium.total;
            }

            function getPricePlaceholder() {
                const emptyPrice = $filter('gwCurrency')({amount: 0});
                const pricePlaceholder = emptyPrice.replace(/0/g, '-');
                return pricePlaceholder;
            }
        }]
    };
}];
