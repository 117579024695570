

/**
 * Sets properties on person to indicate whether driver license upload was erroneous
 * and to indicate that an attempt at scanning license has been made
 * @param {Object} person
 * @param {Object} driverLicense
 */
function setLicenseUploadProperties(person, driverLicense) {
    person.errorUploading = Object.keys(driverLicense).length === 0;
    person.isDriverLicenseScanned = true;
}

/**
 * Assign information retrieved from driver license barcode to the driver
 * @param {Object} driver
 * @param {Object} driverLicense
 */
function fillDriverInfo(driver, driverLicense) {
    const person = driver.person;
    Object.keys(driverLicense).forEach(key => {
        if (driver.hasOwnProperty(key)) {
            driver[key].value = driverLicense[key];
        }
        if (person.hasOwnProperty(key)) {
            person[key].value = driverLicense[key];
        }
    });
}

/**
 * Assign information retrieved from driver license barcode to the contact. If scanned license has a different
 * postal code from the product availability postal code, the primary address of the scanned license will be ignored.
 * @param {Object} contact
 * @param {Object} driverLicense
 * @param {Boolean} skipAddress
 */
function fillContactInfo(contact, driverLicense, skipAddress) {
    if (skipAddress) {
        delete driverLicense.primaryAddress;
        delete driverLicense.licenseState;
    }

    for (const key of Object.keys(driverLicense)) {
        if (contact.hasOwnProperty(key)) {
            contact[key].value = driverLicense[key];
        }
    }
}

export default () => {
    return {
        setLicenseUploadProperties,
        fillDriverInfo,
        fillContactInfo
    };
};