import BaseCtrl from 'edge/platform/base/BaseCtrl';
import WizardUtil from '../util/WizardUtil_AND';
import BrandNumbersUtil from '../../common/util/BrandNumbersUtil';

const fn = function ($scope, $location, $stateParams, brandingData) {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = function () {
        $scope.wizardSteps = WizardUtil.getWizardStepsReadonly();
        $scope.hideNewQuoteBtn_AND = $stateParams.hideNewQuoteBtn_AND;
        $scope.appErrorCode = $stateParams.appErrorCode_AND;
        $scope.brandNumber = BrandNumbersUtil.getBrandNumber(brandingData)
        $scope.brand = brandingData.toLowerCase();
    };
    
    ctrl.startQuote = function () {
        const url = $location.absUrl();
        window.location.href = url.substring(0, url.indexOf('#'));
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$location', '$stateParams', 'brandingData'];
export default fn;
