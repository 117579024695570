import HighlightPhraseFilter from './HighlightPhraseFilter';
import prettyPhoneNumber from './prettyPhoneNumber';
import EntityIDFilter_AND from './EntityIDFilter_AND';
import PhoneNumber_AND from './PhoneNumber_AND';

export default angular.module('edge.platform.filters', [
    'edge.platform.l10n'
])
    .filter('highlightPhrase', HighlightPhraseFilter)
    .filter('gwPrettyPhoneNumber', prettyPhoneNumber)
    .filter('andEntityID', EntityIDFilter_AND)
    .filter('andPhoneNumber', PhoneNumber_AND);

