/**
 * Directive implements sticky header behavior
 * with auto-hiding on scrolling a page down
 *
 * <div gw-pl-sticky-header class="header-sticky"></div>
 *
 */
import 'scroll-up-bar';

export default ['MediaQueriesUtil', '$rootScope', (MediaQueriesUtil, $rootScope) => {
    return {
        'restrict': 'A',
        'link': ($scope, element) => {
            /* --- VARS --- */
            const mediaQuery = MediaQueriesUtil.getPhoneLandscapeDownQuery();

            /* --- METHODS --- */

            // APPLY "FIXED" HEADER ONLY FOR MOBILE DEVICES
            // media query change
            function breakpointReached(_mediaQuery) {
                if (_mediaQuery.matches) { // < break
                    element.scrollupbar({
                        exitViewport: () => {
                            $rootScope.$emit('stickyHeaderExitViewport');
                            angularApply();
                        },
                        enterViewport: () => {
                            $rootScope.$emit('stickyHeaderEnterViewport', element.height());
                            angularApply();
                        }
                    });
                } else { // >= break
                    resetToDefault();
                }
            }

            function resetToDefault() {
                $.scrollupbar.destroy();

                element.css({
                    'position': '',
                    'top': ''
                });
            }

            // has to be invoked when some functionality is called outside of AngularJs
            function angularApply() {
                if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest') {
                    $scope.$apply();
                }
            }

            // INIT
            breakpointReached(mediaQuery);

            // EVENTS
            mediaQuery.addListener(breakpointReached);

            // remove listeners on $scope destroying
            $scope.$on('$destroy', () => {
                mediaQuery.removeListener(breakpointReached);
                resetToDefault();
            });
        }
    };
}];
