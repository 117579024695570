import FlowFactory from 'edge/platform/flow/FlowFactory';
import routerStepChangeListener from './angularhelpers/RouterStepChangeListener';
import cancelConfirmation from './angularhelpers/CancelConfirmation';
import actionStatusMessages from './angularhelpers/ActionStatusMessages';
import snapshotResetConfirmation from './angularhelpers/SnapshotResetConfirmation';
import 'gw-portals-tracking-angular';

export default ['$q', '$state', 'StateUtils', 'ModalService', 'EventHub', 'SessionService_AND', '$rootScope', ($q, $state, StateUtils, ModalService, EventHub, SessionService_AND, $rootScope) => {
    /**
     *  Angular service to wrap FlowFactory and set dependencies that are specific to use in a wizard context.
     *  For example provides a stepChange listener that hooks into ui-router and transitions to a new page for each
     *  transition to a step or exit junction.
     *  The flow model object returned from the FlowFactory.get call is stored in the base wizard state object.
     *  Here it can be accessed by components like the NavBar that are not under the wizard component.
     *
     * @param {Function} flowDefn - flow definition function
     *
     * @param {String} parentWizardState - The parent state which all wizard pages are children
     *
     * @param {Object} [stepToStateMapping] - Mapping from a step name to a function that handles a transition to a router state
     *                                       This function overrides the default mapping of what routing behavior to do
     *                                       after moving to a new step in the flow.
     * @param {*} [eventListeners]
     *
     * @returns {Object}
     */
    return function (flowDefn, parentWizardState, stepToStateMapping, eventListeners) {
        const routerListenerFn = routerStepChangeListener($state, StateUtils, parentWizardState, stepToStateMapping, SessionService_AND);

        const modalBehavior = {
            actionStatusInfo: actionStatusMessages(ModalService),
            snapshotResetConfirmation: snapshotResetConfirmation(ModalService, $q),
            cancelConfirmation: cancelConfirmation(ModalService, StateUtils)
        };

        function storeModelAndFlow(_model, _flow) {
            // flowModel is accessible globally from base wizard state object
            const wizardStateData = StateUtils.dataForState(parentWizardState);
            wizardStateData.flowModel = _flow;
            wizardStateData.model = _model;
        }

        const listeners = angular.isDefined(eventListeners) ? [routerListenerFn, ...eventListeners] : [routerListenerFn];
        const factory = FlowFactory.get($q, EventHub, listeners, modalBehavior, StateUtils, $rootScope);

        const flowInstance = factory(flowDefn);
        return {
            start(model, extraArgs) {
                const flowModel = flowInstance.start(model, extraArgs);
                storeModelAndFlow(model, flowModel);
                return flowModel;
            },
            resume(model, extraArgs) {
                const flowModel = flowInstance.resume(model, extraArgs);
                storeModelAndFlow(model, flowModel);
                return flowModel;
            }
        };
    };
}];
