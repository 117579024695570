import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import _ from 'lodash';

class ViewLocationCtrl {
    constructor($scope, $state, $q, StateUtils) {
        this.$state = $state;
        this.model = StateUtils.activeDataForState(true).model;
        this.sessionId = SessionUtil.getSessionUUID(this.model);

        this.location = _.find(this.model.lobData.commercialProperty.coverables.locations.value, (location) => {
            return location.publicID === $state.params.publicID;
        });
    }

    toBuildingsList() {
        this.$state.go('cpBuildingWizardBuildingsList');
    }

    toEditLocation() {
        this.$state.go('cpBuildingWizardEditLocation', {publicID: this.location.publicID});
    }
}
ViewLocationCtrl.$inject = ['$scope', '$state', '$q', 'StateUtils'];

export default ViewLocationCtrl;
