import pageViews from './pageViews';
import transport from './transport';
import eventHubProviderFactory from './EventHubProvider';
import CredentialsProvider from './CredentialsProvider';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

export default angular.module('gw-portals-tracking-angular', ['edge.platform.util'])
    .constant('applicationFeatures', ApplicationFeatures.getAllFeatures())
    .provider('EventHub', eventHubProviderFactory())
    .provider('Credentials', CredentialsProvider)
    .run(pageViews)
    .run(transport);