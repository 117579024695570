module.exports = {
	"default": {
		"baseData.accountHolder.firstName": "0dummyname0",
		"baseData.accountHolder.lastName": "0dummyname0",
		"baseData.accountHolder.emailAddress1": "dummy@email.dummy",
		"baseData.accountHolder.displayName": "0dummyname0 0dummyname0",
		"baseData.accountHolder.contactName": "0dummyname0",
		"baseData.accountHolder.dateOfBirth.year": 1980,
		"baseData.accountHolder.dateOfBirth.month": 1,
		"baseData.accountHolder.dateOfBirth.day": 1,
		"baseData.policyAddress.addressLine1": "0streamlinedquote0",
		"baseData.policyAddress.state": "CA",
		"baseData.policyAddress.city": "0streamlinedquote0",
		"lobData.personalAuto.coverables.vehicles.vehicle.vin": "0streamlinedquote0",
		"lobData.personalAuto.coverables.drivers.driver.license": "0streamlinedquote0",
		"lobData.personalAuto.coverables.drivers.driver.licenseNumber": "0streamlinedquote0",
		"lobData.businessOwners.accountOrgType": "commonownership",
		"lobData.businessOwners.smallBusinessType": "apartment",
		"person.firstName": "0dummyname0",
		"bindData.contactPhone": "650-357-9100"
	},
	"insuranceNow": {
		"baseData.accountHolder.emailAddress1": "john.smith@iscs.com",
		"baseData.policyAddress.addressLine1": "100 Great Oaks Blvd Ste 100",
		"baseData.policyAddress.state": "CA"
	}
}