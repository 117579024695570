/**
 * Interoperability extension to the view model. Allows developers to access edge date/time types as values of a
 * Javascript date.
 */
import LocalDate from './LocalDate';
import Instant from './Instant';
import OffsetDateTime from './OffsetDateTime';
/** An aspect for the non-date times. It does not have any value and could not be set to anything meaningful. */
const noDateDescriptor = {};


/**
 * Compares two dates.
 * @param {*} dt1
 * @param {*} dt2
 * @returns {boolean}
 */
function datesEqual(dt1, dt2) {
    if (dt1 === dt2) {
        return true;
    }

    if (dt1 === null || dt1 === undefined || dt2 === null || dt2 === undefined) {
        return false;
    }

    return dt1.getTime() === dt2.getTime() && dt1.getTimezoneOffset() === dt2.getTimezoneOffset();
}


function createPropertyWrapper(dto2date, date2dto, viewModelNode) {
    /**
     * Creates a "proper" date wrapper with date caching.
     * @param dto2date function used for conversion from DTO to js date.
     * @param date2dto function used for conversion from js date to DTO.
     * @param viewModelNode model node for which a wrapper is created.
     * @returns property descriptor of the "
     */
    let cached = dto2date(viewModelNode.value);
    return {
        'jsDateValue': {
            get() {
                const newValue = dto2date(viewModelNode.value);
                /* Prevent unlimited date digesting by angular. */
                if (!datesEqual(cached, newValue)) {
                    cached = newValue;
                }
                return cached;
            },
            set(newValue) {
                viewModelNode.value = date2dto(newValue);
            }
        }
    };
}


/** Factories for the different date-time classes. */
const factories = {
    'edge.time.LocalDateDTO': createPropertyWrapper.bind(null, LocalDate.toLocalMidnight, LocalDate.fromJSDate),
    'edge.time.InstantDTO': createPropertyWrapper.bind(null, Instant.toJSDate, Instant.fromJSDate),
    'edge.time.OffsetDateTimeDTO': createPropertyWrapper.bind(null, OffsetDateTime.toLocalDate, OffsetDateTime.fromJSDate),
    'edge.time.LocalTimeDTO': createPropertyWrapper.bind(null, LocalDate.toLocalTodayDate, OffsetDateTime.fromJSDate)
};


export default {
    'getAspectProperties': (currentViewModelNode, currentMetadataNode /* , ancestorChain*/) => {
        const type = currentMetadataNode.valueType;
        if (type.kind !== 'class') {
            return noDateDescriptor;
        }

        const factory = factories[type.name];
        return factory ? factory(currentViewModelNode) : noDateDescriptor;
    }
};