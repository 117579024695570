export default {
    /**
     * Marks form and immediate child forms submitted
     *
     * @param {Object} form
     */
    markFormsSubmitted: (form) => {
        form.submitted = true;

        Object.keys(form).forEach((key) => {
            if (
                typeof (form[key]) === 'object' &&
                form[key].$submitted !== undefined // checking if child is a form
            ) {
                form[key].submitted = true;
            }
        });
    }
};
