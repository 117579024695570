import template from 'text!./wizard-tile.html';

export default [() => ({
    restrict: 'E',
    replace: false,
    transclude: true,
    scope: {
        state: '&',
        callback: '&',
        flavor: '@',
        icon: '@',
        text: '@'
    },
    template: template
})];
