export default function (step) {
    let _goBackFn;

    /**
     * Provide a function with logic about how to go backwards in the flow
     *
     * The arguments to this function are model, extraArgs
     *
     * @param {Function} goBackFn
     * @returns {*}
     */
    return function (goBackFn) {
        if (arguments.length === 0) {
            // previous function takes model and extraArgs parameters
            return _goBackFn;
        }
        _goBackFn = goBackFn;
        return step;
    };
}