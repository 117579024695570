const styles = require('./visuallyhidden-hashed.scss');

/**
 * Makes content visually hidden unless condition is met
 * ('visibility: hidden' rather than 'display: none' to avoid jumps)
 */

export default [() => {
    return {
        restrict: 'A',
        link: ($scope, $element, $attr) => {
            $scope.$watch($attr.gwVisuallyhiddenUnless, value => {
                $element.toggleClass(styles['gw-visuallyhidden'], !value);
            });
        }
    };
}];