import $ from 'jquery';

export default ['BaseUtils', 'LocaleService', '$q', (BaseUtils, LocaleService, $q) => {
    const twitterApi = $q.defer();
    const twitterApiPromise = twitterApi.promise;

    $.getScript('https://platform.twitter.com/widgets.js')
        .then(() => {
            /* globals twttr */
            twitterApi.resolve(twttr);
        })
        .fail((error) => {
            console.error('Failed to load twitter due to', error.stack ? error.stack : error);
            twitterApi.reject(error);
        });

    return {
        restrict: 'E',

        link: (scope, element) => {
            // Twitter switched off WidgetId functionality in Jul 2018
            // The Twitter documentation is vague regarding the future support of the widgets.createTimeline functionality
            // The commented snippet below can be used if Twitter turns off support for widgets.createTimeline
            //
            // element.append(`<a class="twitter-timeline" data-lang="${LocaleService.getCurrentLanguage()}" data-tweet-limit="${Number(scope.tweetLimit) || undefined}" data-link-color="${scope.linkColor || undefined}" href="https://twitter.com/${scope.dataSource.screenName}">Tweets by ${scope.dataSource.screenName}</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`);
            // 
            twitterApiPromise.then((t) => {
                t.widgets.createTimeline(
                    scope.dataSource,
                    element[0],
                    {
                        lang: LocaleService.getCurrentLanguage(),
                        tweetLimit: Number(scope.tweetLimit) || undefined,
                        linkColor: scope.linkColor || undefined
                    }
                );
            });
        }
    };
}];
