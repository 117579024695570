import flowConfigUtil from 'edge/quoteandbind/common/util/FlowConfigUtil';
import MockUpUtil from 'gw-portals-util-js/MockUpUtil';


const mockUpUtil = new MockUpUtil();

/**
 * This function prepares some common actions to be used in flow configuration
 *
 * @param {Object} dependencies
 * @param {Object} dependencies.$q a Promise implementation
 * @param {Object} dependencies.LoadSaveService
 * @param {Object} dependencies.ViewModelService
 * @param {Object} dependencies.ViewModelContext
 *
 * @returns {Object} a set of common Flow actions 'ret.ACTIONS' an their respective message 'ret.ACTIONS_MSG' to be used across all the custom flows
 */
function commonFlowActions({
    LoadSaveService, $q, ViewModelService, ViewModelContext
}) {
    const wrappedLoadSave = flowConfigUtil.wrappedLoadSave(ViewModelService, ViewModelContext);

    const ACTIONS = {
        createEmptySubmission: ({value: submission}) => {

            return new $q((resolve, reject) => {
                const isBOPSubmission = submission.lobData.businessOwners !== undefined;
                const isPASubmission = submission.lobData.personalAuto !== undefined;

                const {
                    firstName, lastName, contactName, emailAddress1
                } = mockUpUtil.getMockDataObject('baseData.accountHolder');
                const {addressLine1, state, city} = mockUpUtil.getMockDataObject('baseData.policyAddress');

                if (isPASubmission) {
                    const {dateOfBirth} = mockUpUtil.getMockDataObject('baseData.accountHolder');
                    submission.baseData.accountHolder = Object.assign({
                        firstName, lastName, contactName, emailAddress1, dateOfBirth
                    }, submission.baseData.accountHolder);
                } else {
                    submission.baseData.accountHolder = Object.assign({
                        firstName, lastName, contactName, emailAddress1
                    }, submission.baseData.accountHolder);
                }
                submission.baseData.policyAddress = Object.assign({addressLine1, state, city}, submission.baseData.policyAddress);
                if (isBOPSubmission) {
                    const bopData = mockUpUtil.getMockDataObject('lobData.businessOwners');
                    submission.lobData.businessOwners = bopData;
                }
                const submissionPromise = LoadSaveService.createEmptySubmission(submission);

                submissionPromise
                    .then(submissionDTO => {
                        if (!submissionDTO.quoteID) {
                            reject();
                        }
                        const submissionVm = ViewModelService.create(submissionDTO, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', ViewModelContext);
                        if (isBOPSubmission) {
                            mockUpUtil.cleanUpMockedProperties('baseData.accountHolder', submissionVm.baseData.accountHolder.value, 'firstName', 'lastName', 'contactName', 'emailAddress1');
                            mockUpUtil.cleanUpMockedProperties('baseData.policyAddress', submissionVm.baseData.policyAddress.value, 'addressLine1', 'city');
                            mockUpUtil.cleanUpMockedProperties('lobData.businessOwners', submissionVm.lobData.businessOwners.value, 'accountOrgType', 'smallBusinessType');
                        }
                        resolve(submissionVm);
                    })
                    .catch(console.error);
            });
        },
        createSubmission: ({
            value: submission
        }) => wrappedLoadSave(LoadSaveService.createSubmission, submission),
    };

    const ACTIONS_MSG = {
        createSubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to create submission',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to create the quote submission.'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Saving details...'
            }
        },
        createEmptySubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to request a quote',
                message: 'quoteandbind.common.config.FlowConfig.Sorry we are unable to create submission.'
            },
            progress: {
                title: 'quoteandbind.common.config.FlowConfig.Creating submission',
                message: 'quoteandbind.common.config.FlowConfig.Checking if a new submission can be provided for that location'
            }
        }
    };


    return {
        ACTIONS,
        ACTIONS_MSG
    };
}

export default commonFlowActions;