import ModalBackdropTemplate from 'text!../templates/modal-backdrop-template.html';

export default ['$modalStack', '$timeout', function ($modalStack, $timeout) {
    return {
        restrict: 'EA',
        replace: true,
        template: ModalBackdropTemplate,
        link(scope) {
            scope.animate = false;

            // trigger CSS transitions
            $timeout(() => {
                scope.animate = true;
            });

            scope.close = function (evt) {
                const modal = $modalStack.getTop();
                if (modal && modal.value.backdrop && modal.value.backdrop !== 'static') {
                    evt.preventDefault();
                    evt.stopPropagation();
                    $modalStack.dismiss(modal.key, 'backdrop click');
                }
            };
        }
    };
}];
