import DemoGuidewireExperiment from './providers/DemoGuidewireExperiment';
import GoogleExperiment from './providers/GoogleExperiment';
import AbExperimentSegmentationDirective from './AbExperimentSegmentationDirective';
import AbExperimentService from './AbExperimentService';
import 'edge/platform/base/module';

export default angular.module('edge.platform.segmentation.abexperiment', ['edge.platform.base'])
    .provider('gwAbExperimentService', AbExperimentService)
    .factory('gwDemoGuidewireExperiment', DemoGuidewireExperiment)
    .factory('gwGoogleExperiment', GoogleExperiment)
    .directive('gwAbExperimentSegmentation', AbExperimentSegmentationDirective)
    // default the demo guidewire AB service to be deployed to PC - this can be overridden by a higher level module
    .value('gwAbExperimentxCenter', 'pc')
    .config(['gwAbExperimentServiceProvider', (gwAbExperimentService) => {
        gwAbExperimentService.useGoogleProvider(false);
    }]);