import cssUtil from 'gw-portals-util-js/CssUtil';

import listTemplate from 'text!./MobileLocation.html';
import listStyles from './MobileLocation-hashed.scss';

export default [() => ({
    restrict: 'E',
    template: cssUtil.hashTemplate(listTemplate, listStyles),
    scope: {
        schedule: '&'
    },
    controller: ['$scope', 'AddressUtil', ($scope, AddressUtil) => {
        $scope.spellAddress = (addrVM) => AddressUtil.format(addrVM);
    }]
})];
