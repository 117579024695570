import accordion from 'edge/platform/widgets/accordion/directives/accordion';
import accordionGroup from 'edge/platform/widgets/accordion/directives/accordionGroup';
import accordionHeading from 'edge/platform/widgets/accordion/directives/accordionHeading';
import accordionChevron from 'edge/platform/widgets/accordion/directives/accordionChevron';
import accordionTransclude from 'edge/platform/widgets/accordion/directives/accordionTransclude';
import 'edge/platform/widgets/accordion/transition/module';
import 'edge/platform/widgets/accordion/collapse/module';

export default angular.module('edge.platform.widgets.ui.bootstrap.accordion', [
    'edge.platform.widgets.ui.bootstrap.transition',
    'edge.platform.widgets.ui.bootstrap.collapse'
])
    .constant('gwPlAccordionConfig', {
        closeOthers: true
    })
    .directive('gwPlAccordion', accordion)
    .directive('gwPlAccordionGroup', accordionGroup)
    .directive('gwPlAccordionHeading', accordionHeading)
    .directive('gwPlAccordionChevron', accordionChevron)
    .directive('gwPlAccordionTransclude', accordionTransclude)
    .controller('GwPlAccordionController', ['$scope', '$attrs', 'gwPlAccordionConfig', function ($scope, $attrs, gwPlAccordionConfig) {
        // This array keeps track of the accordion groups
        this.groups = [];

        // Ensure that all the groups in this accordion are closed, unless close-others explicitly says not to
        this.closeOthers = function (openGroup) {
            const closeOthers = angular.isDefined($attrs.closeOthers) ?
                $attrs.closeOthers === 'true' :
                gwPlAccordionConfig.closeOthers;

            if (closeOthers) {
                angular.forEach(this.groups, (group) => {
                    if (group !== openGroup) {
                        group.isOpen = false;
                    }
                });
            }
        };

        // This is called from the gw-pl-accordion-group directive to add itself to the accordion
        this.addGroup = function (groupScope) {
            const that = this;
            this.groups.push(groupScope);

            groupScope.$on('$destroy', () => {
                that.removeGroup(groupScope);
            });
        };

        // This is called from the gw-pl-accordion-group directive when to remove itself
        this.removeGroup = function (group) {
            const index = this.groups.indexOf(group);
            if (index !== -1) {
                this.groups.splice(index, 1);
            }
        };
    }]);