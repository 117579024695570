import template from 'text!./InitialScreen/InitialScreen.html';
import controller from './InitialScreen/InitialScreen';

/**
 * @ngdoc component
 * @name gw-portals-cp-components-angular: gwBuildingsWizard
 *
 * @description  A component to display locations and buildings page.
 *
 */
export default {
    controller,
    template
};