import templateStr from 'text!./pm-edit-driver.html';
import moment from 'moment';

export default ['AgeAllowedToDrive', (AgeAllowedToDrive) => {
    const getMonthCode = (typekeyVM) => (typekeyVM.value.priority / 10) - 1;

    return {
        restrict: 'A',
        scope: {
            driver: '=',
            ageRefDateDTO: '<ageRefDateDto', // Reference date for age calculation (typically, period start date)
            convictionCodes: '<',
            submission: '='
        },
        template: templateStr,
        controller: ['$scope', '$rootScope', 'brandingData', ($scope, $rootScope, brandingData) => {
            let LicenceFilterType = 'TelematicsLicenceType';
            if (brandingData === 'kfi') {
                LicenceFilterType = 'TelematicsLicenceType';
            } else if ($rootScope.productCode === 'TraditionalMotor' || $rootScope.productCode === 'CommercialVehicle') {
                LicenceFilterType = 'TMILicenceType';
            } 
            $scope.pmClaims = $scope.driver.pmClaims;
            $scope.pmMotorConvictions = $scope.driver.pmMotorConvictions;
            $scope.licenseTypeFilters = $scope.driver.licenseType.aspects.availableValues[0].typelist.filters;
            $scope.LicencePrefixes = $scope.licenseTypeFilters.find(obj => {
                return obj.name === LicenceFilterType;
            });
            // Initialize the following fields for PH when coming from AMP
            if ($rootScope.fromAMP_AND && $rootScope.fromAMP_AND.initDriverFields && $scope.driver.value.isPolicyHolder) {
                $scope.driver.accessToOtherVehicles = null;
                $scope.driver.medicalConditions = null;
                $scope.driver.nonMotoringConvictions = null;
                $scope.driver.cancelledPreviousInsurance = null;
                $rootScope.fromAMP_AND.initDriverFields = false; // disable this so values are retained after this step
            }

            $scope.$watch(() => $scope.driver.dateOfBirth.value, (newValue) => {
                if (newValue) {
                    // Driving test pass and drove on provisional licence minimum date
                    $scope.minAllowedToDriveDate = moment([$scope.driver.dateOfBirth.year.value,
                        $scope.driver.dateOfBirth.month.value, $scope.driver.dateOfBirth.day.value]);
                    $scope.minAllowedToDriveDate.add(AgeAllowedToDrive, 'years');
                    $scope.driverCanHave10YearsExp = $scope.driver.value.getAgeAt($scope.ageRefDateDTO) >=
                        (AgeAllowedToDrive + 10);
                    if ($scope.driverCanHave10YearsExp !== true) {
                        $scope.driver.testPassed10Years.value = false;
                    }
                }
            });

            // If provisional, driver hasn't passed the test.
            $scope.$watch(() => $scope.driver.licenseType.value, (newValue, oldValue) => {
                if (newValue !== oldValue && $scope.driver.value.hasFullLicence() === false) {
                    if ($scope.driverCanHave10YearsExp !== true) {
                        $scope.driver.testPassed10Years.value = false;
                    } else {
                        $scope.driver.testPassed10Years.value = null; // Just clear it so it doesn't show up pre populated
                    }
                }
            });

            // Maximum date reacts to driving test passed date, as this has to be before that
            $scope.maxProvisionalLicenceDate = moment();
            $scope.$watch(() => {
                if ($scope.driver.passedTestMonth.value) {
                    return $scope.driver.passedTestYear.value + $scope.driver.passedTestMonth.value.code;
                }
                return $scope.driver.passedTestYear.value;
            }, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (angular.isDefined($scope.driver.passedTestYear.value) &&
                        angular.isDefined($scope.driver.passedTestMonth.value)) {
                        // Immutable, for change detection
                        $scope.maxProvisionalLicenceDate = moment([$scope.driver.passedTestYear.value,
                            getMonthCode($scope.driver.passedTestMonth)]);
                    } else {
                        $scope.maxProvisionalLicenceDate = moment();
                    }
                }
            });
            if ($rootScope.productCode === 'PersonalMotor') {
                $scope.medicalConditionsTooltip = {
                    content: 'and.quoteandbind.pm.directives.templates.pm-edit-driver.MedicalConditionsTooltip',
                    trigger: 'click'
                };
            }
            else {
                $scope.medicalConditionsTooltip = {
                    content: `and.quoteandbind.pm.directives.templates.pm-edit-driver.MedicalConditionsTooltip.${brandingData}`,
                    trigger: 'click'
                };
            }

            $scope.unspentConvictionsTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-driver.UnspentConvictionsTooltip',
                trigger: 'click'
            };
            $scope.claimsTooltip = {
                content: 'and.quoteandbind.pm.views.pm-driver-details.ClaimsTooltip',
                trigger: 'click'
            };

            $scope.$watch(() => $scope.driver.hasMotorConvictions.value, (newValue) => {
                if (newValue && $scope.pmMotorConvictions.length <= 0) {
                    $scope.addMotorConviction();
                } else if (!newValue && $scope.pmMotorConvictions.length > 0) {
                    $scope.pmMotorConvictions.value.splice(0);
                }
            });
            $scope.$watch(() => $scope.driver.hasMotorClaims.value, (newValue) => {
                if (newValue && $scope.pmClaims.length <= 0) {
                    $scope.addMotorClaim();
                } else if (!newValue && $scope.pmClaims.length > 0) {
                    $scope.pmClaims.value.splice(0);
                }
            });

            $scope.addMotorClaim = () => {
                if (!$scope.pmClaims.value) {
                    $scope.pmClaims.value = [];
                }
                $scope.pmClaims.value.push({});

            };
            $scope.removeMotorClaim = (index) => {
                $scope.pmClaims.value.splice(index, 1);
                if ($scope.pmClaims.length <= 0) {
                    $scope.driver.hasMotorClaims.value = false;
                }
            };
            $scope.addMotorConviction = () => {
                if (!$scope.pmMotorConvictions.value) {
                    $scope.pmMotorConvictions.value = [];
                }
                $scope.pmMotorConvictions.value.push({});
            };
            $scope.removeMotorConviction = (index) => {
                $scope.pmMotorConvictions.value.splice(index, 1);
                if ($scope.pmMotorConvictions.length <= 0) {
                    $scope.driver.hasMotorConvictions.value = false;
                }
            };
        }]
    };
}];
