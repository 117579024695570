import templateStr from 'text!./templates/date-drop-down-directive.html';
import DateUtil from 'gw-portals-util-js/DateUtil';

export default ['$filter', 'TranslateService', function factory($filter, TranslateService) {
    const now = DateUtil.currentDate();
    const currentYear = now.getFullYear();

    const populateDropDownValues = (scope) => {
        scope.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

        scope.months = [{
            value: 0,
            _name: 'January'
        }, {
            value: 1,
            _name: 'February'
        }, {
            value: 2,
            _name: 'March'
        }, {
            value: 3,
            _name: 'April'
        }, {
            value: 4,
            _name: 'May'
        }, {
            value: 5,
            _name: 'June'
        }, {
            value: 6,
            _name: 'July'
        }, {
            value: 7,
            _name: 'August'
        }, {
            value: 8,
            _name: 'September'
        }, {
            value: 9,
            _name: 'October'
        }, {
            value: 10,
            _name: 'November'
        }, {
            value: 11,
            _name: 'December'
        }];

        scope.months.forEach((month) => {
            TranslateService.translate({
                object: month,
                propName: 'name',
                displayKey: `platform.inputs.DateDropDownDirective.${month._name}`,
                scope
            });
        });

        const oldestYear = currentYear - (parseInt(scope.minYearsFromNow, 10) || 100);
        const futurestYear = currentYear + (scope.maxYearsFromNow || 50);
        const futureOnly = (angular.isDefined(scope.futureOnly)) ? scope.futureOnly : false;
        let yrIdx = currentYear;

        scope.years = [];
        if (futureOnly) {
            if (angular.isDefined(scope.startFromOffset)) {
                yrIdx += parseInt(scope.startFromOffset, 10);
            }
            for (; yrIdx < futurestYear; yrIdx++) {
                scope.years.push(yrIdx);
            }
        } else {
            if (angular.isDefined(scope.startFromOffset)) {
                yrIdx -= parseInt(scope.startFromOffset, 10);
            }
            for (; yrIdx > oldestYear; yrIdx--) {
                scope.years.push(yrIdx);
            }
        }
    };

    return {
        restrict: 'A',
        replace: true,
        require: 'ngModel',
        scope: true,
        template: templateStr,

        link: (scope, element, attrs, ctrl) => {
            const ngModelCtrl = ctrl;

            scope.startFromOffset = attrs.startFromOffset;
            scope.minYearsFromNow = attrs.minYearsFromNow;
            scope.maxYearsFromNow = attrs.maxYearsFromNow;
            scope.showDays = attrs.showDays === 'true';
            scope.futureOnly = attrs.futureOnly === 'false' ? undefined : true;
            scope.disableFields = attrs.disabled;
            scope.placeholderDate = {};

            populateDropDownValues(scope);

            scope.dateFields = {};
            if (ngModelCtrl.$modelValue) {
                scope.dateFields.day = new Date(ngModelCtrl.$modelValue).getDate();
                scope.dateFields.month = new Date(ngModelCtrl.$modelValue).getMonth();
                scope.dateFields.year = new Date(ngModelCtrl.$modelValue).getFullYear();
                if (scope.futureOnly) {
                    ngModelCtrl.$modelValue.setHours(23, 59, 59, 0);
                }
            } else {
                scope.placeholderDate = angular.copy(now);
                if (scope.futureOnly) {
                    // if future only then set date to be the last day of the month
                    const lastDateOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
                    scope.placeholderDate.setDate(lastDateOfThisMonth);
                    scope.placeholderDate.setFullYear(scope.placeholderDate.getFullYear() + (angular.isDefined(scope.startFromOffset) ? parseInt(scope.startFromOffset, 10) : 0));
                } else {
                    // set to first day of month
                    scope.placeholderDate.setDate(1);
                    scope.placeholderDate.setMonth(0);
                    scope.placeholderDate.setFullYear(scope.placeholderDate.getFullYear() - (angular.isDefined(scope.startFromOffset) ? parseInt(scope.startFromOffset, 10) : 0));
                }
            }

            scope.$watch(attrs.ngModel, (newValue) => {
                if (newValue) {
                    scope.dateFields.day = new Date(newValue).getDate();
                    scope.dateFields.month = new Date(newValue).getMonth();
                    scope.dateFields.year = new Date(newValue).getFullYear();
                } else {
                    scope.dateFields.day = undefined;
                    scope.dateFields.month = undefined;
                    scope.dateFields.year = undefined;
                }
            });

            attrs.$observe('disabled', (isDisabled) => {
                scope.disableFields = isDisabled;
            });

            scope.updateModel = () => {
                if (scope.dateFields.year === null || scope.dateFields.month === null || scope.dateFields.day === null) {
                    ngModelCtrl.$setViewValue(null);
                } else {
                    ngModelCtrl.$setViewValue(new Date(
                        angular.isDefined(scope.dateFields.year) ? scope.dateFields.year : scope.placeholderDate.getFullYear(),
                        angular.isDefined(scope.dateFields.month) ? scope.dateFields.month : scope.placeholderDate.getMonth(),
                        angular.isDefined(scope.dateFields.day) ? scope.dateFields.day : scope.placeholderDate.getDate(),
                        // Set the appropriate time based on future date or not - otherwise the validators will fail to work
                        (scope.futureOnly) ? 23 : 0, (scope.futureOnly) ? 59 : 0, (scope.futureOnly) ? 59 : 0,
                        0
                    ));
                }
            };
        }
    };
}];
