import _ from 'lodash';
import dtoParser from './PresentationDTOParser';

function createProperty(metadata) {
    const copiedMetadata = _.cloneDeep(metadata);
    return Object.freeze(copiedMetadata);
}

export default {
    /**
     * Creates a new page definition from its serialized form. Returned objects have the following
     * properties and functions:
     * <dl>
     *     <dt>pageName</dt><dd>Name of the page represented by the mirror.</dd>
     *     <dt>DTOs</dt><dd>List of all the DTOs defined for the page.</dd>
     *     <dt>flavor</dt><dd>Reference back to the flavor to which this page belongs.</dd>
     * </dl>
     *
     * @param {*} flavor page instance for which this page belongs.
     * @param {Function} flavorLinker function used to get an access to the owning flavor.
     * @param {*} name page name.
     * @param {*} metadata page definition to parse.
     * @returns {Object} page type definition.
     */
    createPageInfo: (flavor, flavorLinker, name, metadata) => {
        const res = {
            pageName: name,
            flavor,
        };

        /* PROPERTIES */
        res.DTOs = _.mapValues(metadata, createProperty);
        res.parse = () => {
            return dtoParser(res.DTOs);
        };
        Object.freeze(res.DTOs);
        Object.freeze(res);
        return res;
    }
};