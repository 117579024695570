import _ from 'lodash';
import template from 'text!./templates/Select.html';

export default [() => ({
    restrict: 'E',
    scope: {
        item: '&'
    },
    template: template,
    link: $scope => {
        $scope.$watch('item()', item => {
            $scope.fakeVM = {
                set value(val) {
                    item.setValue(val.code);
                },

                get value() {
                    return _.find(this.options, opt => opt.code === item.getValue());
                },

                get options() {
                    return item.getAvailableValues() || [];
                }
            };
        });
    }
})];