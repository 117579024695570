import $ from 'jquery';
import _ from 'underscore';

const initializeIfNotReady = _.once(() => {
    // The following is taken from
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/tracking-snippet-reference#alternative-async-tracking-snippet
    /* eslint-disable */
    window.ga = window.ga || function () {
            (ga.q = ga.q || []).push(arguments)
        };
    ga.l = +new Date;
    /* eslint-enable */
    // enable google analytics ecommerce plugin
    window.ga('require', 'ecommerce');

    $.ajax({
        url: 'https://www.google-analytics.com/analytics.js',
        dataType: 'script',
        cache: true
    });
});

export default () => {
    // cannot return window.ga here directly, because although window.ga will be replaced
    // later, the caller would still point to the intermediary object defined above
    return (...args) => {
        initializeIfNotReady();
        window.ga(...args);
    };
};