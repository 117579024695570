export default {
    extend: (submission) => {
        submission.lobData = submission.lobData || {};

        if (!submission.lobData.businessOwners) {
            submission.lobData.businessOwners = {
                accountOrgType: null,
                smallBusinessType: null
            };
        }

        if (submission.baseData.accountHolder && !isPersonalAccount(submission.baseData.accountHolder)) {
            submission.baseData.accountHolder.subtype = 'Company';
        }
    }
};

function isPersonalAccount(accountHolder) {
    // Assume is personal account if has a first and last name, cannot trust subtype as defaults to Person
    return accountHolder.firstName && accountHolder.lastName;
}

