/**
 * Provides ability to track using Google analytics.
 * @param {Object} ga
 * @param {Object} config
 * @param {Array|undefined} plugins
 * @param {Object} campaignData
 * @returns {Object}
 */
export default (ga, config, plugins = [], campaignData) => {
    function trackPageView({
        payload: {
            path
        }
    }) {
        ga('set', {
            page: path,
            campaignSource: campaignData.utm_source,
            campaignMedium: campaignData.utm_medium
        });
        ga('send', {
            hitType: 'pageview'
        });
    }

    function trackServerCall({
        payload: {
            elapsedTime, path, method
        }
    }) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'serverCall',
            eventAction: `${path}:${method}`,
            eventValue: elapsedTime
        });
    }

    function trackServerCallError({
        payload: {
            elapsedTime, path, method
        }
    }) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'serverCallError',
            eventAction: `${path}:${method}`,
            eventValue: elapsedTime
        });
    }


    function trackWizard({
        eventName
    }) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'wizard',
            eventAction: eventName
        });
    }

    function trackPurchase({
        payload: {
            id, offering, taxes, total, productName
        }
    }) {
        ga('ecommerce:addTransaction', {
            id,
            revenue: total.amount,
            tax: taxes.amount,
            currency: total.currency
        });
        ga('ecommerce:addItem', {
            id,
            name: productName,
            category: offering,
            price: total.amount - taxes.amount,
            currency: total.currency
        });
        ga('ecommerce:send');
    }

    // Create a new tracker
    ga('create', config);
    plugins.forEach(pluginName => ga('require', pluginName));
    return {
        trackPageView,
        trackServerCall,
        trackServerCallError,
        trackWizard,
        trackPurchase
    };
};