import _ from 'underscore';
import flowModel from './FlowModel';
import flowBuilder from '../flow/build/FlowBuilder';
import FlowUtil from './FlowUtil';
import ViewModelUtil from 'gw-portals-viewmodel-js/ViewModelUtil';

export default {
    /**
     * A factory function that returns a service used to create wizard flows
     *
     * @param {Object} $q - ES6 Promise API implementation
     * @param {Object} EventHub - Event hub instance used to track flow events
     * @param {Array} stepChangeListenerFactories - Array of functions returning a callback that is called for step and exit transitions
     * @param {Object} modalBehavior - Provides promises resulting from displaying information dialogs
     *                                      actionStatusInfo - (modals for displaying progress & error messages of calls to the server)
     *                                      snapshotResetConfirmation - (confirmation message to prompt that data may be lost when reset to snapshot)
     *                                      cancelConfirmation - (confirmation message to cancel flow)
     * @param {Object} [StateUtils]
     *
     * @returns {Function} - Function to call for storing model and flowModel after creation (like in the state)
     **/
    get($q, EventHub, stepChangeListenerFactories, modalBehavior, StateUtils, $rootScope) {

        const dummyModalBehavior = {
            actionStatusInfo: model => $q.resolve(model),
            snapshotResetConfirmation: model => $q.resolve(model),
            cancelConfirmation: model => $q.resolve(model)
        };

        modalBehavior = modalBehavior || dummyModalBehavior;

        /**
         * Create a new flow object
         *
         * @param {Function} flowDefn - function for defining flow definition
         *
         * @returns {Object} A new flow object which can be started or combined with other flows
         **/
        return function (flowDefn) {
            /**
             * Validate Flow
             *  - there is one flow entry and one or more flow exits
             *  - there is only one event associated with a step
             *
             *  @param {Object} flow - Object with entry, exit and steps property
             *  @throws {Error} error with details of how flow is invalid
             *  @returns {true}
             */
            function validateFlow(flow) {
                const validationErrors = [];
                if (!flow.entry) {
                    validationErrors.push('Flow has no entry step defined');
                }
                if (_.keys(flow.exits).length === 0) {
                    validationErrors.push('Flow has no exit steps defined');
                }
                if (validationErrors.length > 0) {
                    throw new Error(validationErrors.join('\n'));
                }
                return true;
            }

            let isStarted = false; // Flag to prevent generating events during wizard bootstrap
            function publishWizardEvent(eventName, model, prevStep, finalStep) {
                if (isStarted) {
                    const unpackedModel = model ? ViewModelUtil.unpackIfViewModelNode(model) : null;
                    EventHub.publish('wizard', eventName, {
                        model: unpackedModel,
                        fromState: prevStep,
                        toState: finalStep,
                        url: StateUtils ? StateUtils.getTrackingUrl() : undefined
                    });
                }
            }

            function withEventsDisabled(fn) {
                isStarted = false;
                try {
                    fn();
                } finally {
                    isStarted = true;
                }
            }

            const flow = flowBuilder(flowDefn, $q, EventHub);
            validateFlow(flow);
            const stepListeners = stepChangeListenerFactories ? stepChangeListenerFactories.map(fn => fn(flow)) : [];


            return {
                /**
                 * A factory function that returns a flow model initialized to the first step
                 *
                 * @param {Object} model         - Model holding initial data object
                 * @param {Object} [extraArgs]   - Map of arguments passed to branch functions and step listeners
                 *
                 * @returns {Object} FlowModel
                 **/
                start(model, extraArgs) {
                    const flowObj = flowModel(flow, $q, publishWizardEvent, stepListeners, modalBehavior, $rootScope);
                    flowObj.snapshot = model;
                    flowObj.serverSnapshot = model;

                    withEventsDisabled(() => {
                        if (FlowUtil.isStepObject(flow.entry)) {
                            // go to the first step triggering any callbacks.
                            flowObj.jumpToStep(flow.entry, model, extraArgs, true);
                        } else {
                            // if junction proceed until a step is reached
                            flowObj.next(model, extraArgs);
                        }
                    });
                    publishWizardEvent('start', model, null, flowObj.currentStep);
                    return flowObj;
                },

                /**
                 * A factory function that returns a flow model which compares the model with the resume expression for each step
                 *
                 * @param {Object} model - Object that is checked against resume expressions for subsequent steps
                 * @param {Object} [extraArgs]   - Map of arguments passed to branch functions and step listeners
                 *
                 *  @returns {Object} FlowModel
                 **/
                resume(model, extraArgs) {
                    const flowObj = flowModel(flow, $q, publishWizardEvent, stepListeners, modalBehavior, $rootScope);
                    let currentStep = flow.entry;
                    if (FlowUtil.isJunctionObject(currentStep)) {
                        currentStep = FlowUtil.getNextStep(flow, currentStep, model);
                    }
                    const stepHistory = [currentStep];
                    let nextStep = currentStep;
                    do {
                        const stepSkipFn = currentStep.skipOnResumeIf ? currentStep.skipOnResumeIf() : undefined;
                        if (!stepSkipFn || stepSkipFn(model, extraArgs)) {
                            nextStep = FlowUtil.getNextStepOrJunction(flow, nextStep, model);
                            if (nextStep) {
                                currentStep = nextStep;
                                if (FlowUtil.isStepObject(nextStep)) {
                                    stepHistory.push(nextStep);
                                }
                            }

                        } else {
                            nextStep = undefined;
                        }
                    } while (nextStep);

                    if (FlowUtil.isExitPoint(currentStep, flow)) {
                        flowObj.jumpToExit(currentStep, model, extraArgs);
                        return flowObj;
                    }
                    withEventsDisabled(() => {
                        flowObj.resetFlowProgress(currentStep, stepHistory, model);
                        flowObj.jumpToStep(flowObj.currentStep, model, extraArgs, true);
                    });
                    publishWizardEvent('resume', model, null, flowObj.currentStep);
                    return flowObj;
                }
            };
        };
    }
};