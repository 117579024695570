import templateStr from 'text!./templates/language-select.html';
/**
 * Widget for displaying language dropdown
 *
 * @returns {Object}
 */
export default () => {
    return {
        restrict: 'A',
        replace: true,
        template: templateStr,
        controller: ['$scope', 'LocaleService', ($scope, LocaleService) => {
            $scope.currentLocaleDisplayName = LocaleService.getLocaleDisplayName();
            $scope.localesDisplayNames = LocaleService.getLocalesDisplayNames();
            $scope.visible = $scope.localesDisplayNames &&
                $scope.localesDisplayNames.length > 1;

            // METHODS
            $scope.changeLanguage = (locale) => {
                LocaleService.setLocaleByDisplayName(locale);
                $scope.currentLocaleDisplayName = locale;
            };
        }]
    };
};
