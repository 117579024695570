/**
 * Provides variations from the Google Experiment
 *
 * @returns {Object}
 *
 * {Object}.googleExperiment {Number} Selected option for the user from the Google Experiment.
 *      E.g. 0 or 1. If GA is not loaded or experiment is not provided then -1 is returned
 *      The value is persisted in cookies
 */

import $ from 'jquery';

let scriptLoaded;
let cachedExperimentId;

function loadExperimentScript(experimentId) {
    cachedExperimentId = experimentId;
    scriptLoaded = $.ajax({
        url: `https://www.google-analytics.com/cx/api.js?experiment=${experimentId}`,
        dataType: 'script',
        cache: true
    });
}

export default ['$q', '$window', ($q, $window) => {
    return {
        getExperimentValue: (experimentId) => {
            if (cachedExperimentId !== experimentId) {
                // reload the experiment script if there is a different experiment passed in. Set window object
                loadExperimentScript(experimentId);
            }
            return $q((resolve, reject) => {
                scriptLoaded.done(() => {
                    resolve($window.cxApi.chooseVariation());
                });
                scriptLoaded.fail(() => {
                    reject(-1);
                });
            });
        }
    };
}];