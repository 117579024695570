const nonScopeMethods = ['init', 'defineWatches', '$inject'];

export default {
    create($scope) {
        this.defineWatches();
        this.init();
        for (const prop in this) {
            if (this.hasOwnProperty(prop) &&
                prop.charAt(0) !== '_' &&
                typeof this[prop] === 'function' &&
                nonScopeMethods.indexOf(prop) === -1) {
                $scope[prop] = this[prop];
            }
        }
        return this;
    },

    defineWatches: () => {
        // OVERRIDE : put all watches and event listeners here
    },

    init: () => {
        // OVERRIDE : Initialise the scope with service populated data when controller is instantiated
    }
};