/*
 *  For adding a progress bar at top of wizard for responsive view
 *
 */

import _WizardStepsCtrlAND from './_WizardStepsCtrl_AND';
import templateStr from 'text!./templates/wizard-step-progress.html';

export default function () {
    return {
        restrict: 'A',
        replace: true,
        template: templateStr,
        require: '^gwNavBarWizardBase',
        controller: _WizardStepsCtrlAND,
        link(scope, element, attrs, navBarWizardBaseCtrl) {
            scope.toggleWizardStepDisplay = () => {
                navBarWizardBaseCtrl.toggleSideBarMenu();
            };
        }
    };
}
