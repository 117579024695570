import _ from 'lodash';
import $ from 'jquery';
import cssUtil from 'gw-portals-util-js/CssUtil';

import styles from './Table-hashed.scss';
import template from 'text!./Table.html';

const getDefaultState = () => ({
    editable: false,
    showError: false,
    removing: false
});

export default ['$timeout', ($timeout) => ({
    restrict: 'E',
    scope: {
        schedule: '&'
    },
    template: cssUtil.hashTemplate(template, styles),
    link: ($scope, $element) => {
        let scheduleUpdateId = 0;
        $scope.$watch('schedule()', schedule => {
            $scope.scheduleUpdateId = scheduleUpdateId++; // Used for fix width
            $scope.sizingItem = schedule.createNewItem(); // Rendered with visibility:hidden to keep a reference size for the inputs

            const fixWidths = (fixingAttempt) => {
                /*
                 There's a hidden sizing table in the markup.
                 The whole table has layout-fixed so setting explicit widths on the top row also sets the widths for the whole table.

                 All that is necessary to remove any jumps when going between edit and display modes.
                 */

                fixingAttempt = fixingAttempt || 0;
                if (fixingAttempt > 10) {
                    console.error('Could not fix widths in %d attempts inside', fixingAttempt - 1, $element);
                    return; // That's too much attempts, something is obviously wrong.
                }

                setTimeout(() => $scope.$evalAsync(() => {
                    const $referenceItems = angular.element(`.${styles.gwTableSize}`);

                    const $fields = $referenceItems.filter('.gw-schedule-table__sizing-row-field-cell');
                    const fieldsZeroWidth = $fields.length > 0 ? $fields.toArray().every(item => $(item).width() <= 0) : false;

                    if ($referenceItems.length !== $scope.sizingItem.getDataItems().length + 2 || fieldsZeroWidth) { // +2 for the control cells
                        fixWidths(fixingAttempt + 1);
                        return;
                    }

                    const fixableItems = $element.find('.gw-schedule-table__actual-sizing-row').children().toArray();
                    $referenceItems.each(function (i) {
                        const $cell = $(this);
                        const $fixable = $(fixableItems[i]);
                        $fixable.css('width', $cell.outerWidth());
                    });
                }), 1);
            };
            window.addEventListener('resize', _.throttle(fixWidths, 100));
            fixWidths();

            const itemStates = new Map();
            const dropRemoves = () => itemStates.forEach(item => {
                item.removing = false;
            });
            const createDefaultState = item => itemStates.set(item, getDefaultState());
            schedule.getScheduledItems().forEach(createDefaultState);

            $scope.isEditable = function (item) {
                return itemStates.get(item).editable;
            };
            $scope.edit = function (item) {
                dropRemoves();
                itemStates.get(item).editable = true;
                item.draft();
            };
            $scope.doneEditing = function (item) {
                dropRemoves();
                const itemState = itemStates.get(item);
                if (!item.isValid()) {
                    itemState.showError = true;
                    return;
                }

                itemState.editable = false;
                item.commit();
            };
            $scope.cancelEditing = function (item) {
                dropRemoves();
                itemStates.get(item).editable = false;
                itemStates.get(item).showError = false;

                item.undo();
            };
            $scope.handleEnter = function (event, item) {
                if (event.which === 13) {
                    event.preventDefault();
                    event.stopPropagation();

                    // Trigger onblur and let it run before saving the item
                    $(event.target).parents('.gw-schedule-table__row').focus();
                    $timeout(() => $scope.doneEditing(item));
                }
            };

            $scope.shouldShowError = function (item) {
                return itemStates.get(item).showError;
            };

            $scope.initRemove = function (item) {
                dropRemoves();
                itemStates.get(item).removing = true;
            };
            $scope.cancelRemoving = function (item) {
                itemStates.get(item).removing = false;
            };
            $scope.isBeingRemoved = function (item) {
                return itemStates.get(item).removing;
            };
            $scope.remove = function (item) {
                schedule.remove(item);
                itemStates.delete(item);
            };

            $scope.newItem = null;
            $scope.showNewItemError = false;
            $scope.createNew = function () {
                dropRemoves();
                $scope.newItem = schedule.createNewItem();
                $scope.newItem.draft();
            };
            $scope.resetAdd = function () {
                dropRemoves();
                $scope.newItem = null;
                $scope.showNewItemError = false;
            };
            $scope.add = function () {
                dropRemoves();
                if (!$scope.newItem.isValid()) {
                    $scope.showNewItemError = true;
                    return;
                }

                $scope.newItem.commit();
                schedule.add($scope.newItem);
                createDefaultState($scope.newItem);
                $scope.resetAdd();
            };
            $scope.handleEnterOnNew = function (event) {
                if (event.which === 13) {
                    event.preventDefault();
                    event.stopPropagation();

                    // Trigger onblur and let it run before saving the item
                    $(event.target).parents('.gw-schedule-table__row').focus();
                    $timeout(() => $scope.add());
                }
            };
        });
    }
})];