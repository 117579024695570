import cssUtil from 'gw-portals-util-js/CssUtil';

import styles from './typeahead-hashed.scss';
import typeaheadDropdownTemplate from 'text!./templates/typeahead-dropdown.html';

/*
 * This is coming from angular.bootstap with modifications for selectDirective.
 */
export default [() => {
    return {
        scope: {
            matches: '=',
            query: '=',
            active: '=',
            position: '&',
            moveInProgress: '=',
            select: '&',
            assignIsOpen: '&',
            selectDirective: '='
        },
        replace: true,
        template: cssUtil.hashTemplate(typeaheadDropdownTemplate, styles),
        link: (scope) => {
            scope.isOpen = function () {
                const isDropdownOpen = scope.matches.length > 0;
                scope.assignIsOpen({isOpen: isDropdownOpen});
                return isDropdownOpen;
            };

            scope.isActive = function (matchIdx) {
                return scope.active === matchIdx;
            };

            scope.selectActive = function (matchIdx) {
                scope.active = matchIdx;
            };

            scope.selectMatch = function (activeIdx, evt) {
                scope.select({activeIdx: activeIdx, evt: evt});
            };
        }
    };
}];