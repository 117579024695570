import _ from 'lodash';
import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './GuidanceOccupation-hashed.scss';
import templateStr from 'text!./GuidanceOccupation.html';
import IndustryCodeUtil from '../../util/IndustryCodeUtil';
import IndustryCodeDirective from 'edge/platform/widgets/typeahead/matchContentDirectives/IndustryCode/IndustryCode';

export default [
    () => {
        return {
            restrict: 'E',
            scope: {
                industryCode: '=',
                continueToNextPanel: '&',
                isActive: '&',
                isPanelValid: '&',
                transitionFn: '&'
            },
            template: cssUtil.hashTemplate(templateStr, styles),
            controller: ['$scope', 'guidance.GuidanceService', ($scope, GuidanceService) => {

                const AMOUNT_OF_OCCUPATIONS_TO_SHOW_IN_DROPDOWN = 5;
                const occupationsPromise = GuidanceService.getOccupations();

                $scope.occupationLookup = {
                    getMatches: function (userInput) {
                        return occupationsPromise
                            .then(occupations => _(occupations)
                                .filter(code => IndustryCodeUtil.toString(code).toLowerCase()
                                    .includes((userInput || '').toLowerCase()))
                                .take(AMOUNT_OF_OCCUPATIONS_TO_SHOW_IN_DROPDOWN)
                                .valueOf());
                    },
                    /* This toString will return to the user the classification code of the selection */
                    toString: IndustryCodeUtil.toString,
                    directive: IndustryCodeDirective
                };
            }]
        };
    }
];
