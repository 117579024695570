import _ from 'underscore';

export default () => {
    /**
     * Initializes the Contact type hierarchy to match the xCenter configuration.
     * @returns {Array}
     */
    function initializeHierarchy() {
        const personType = {
            name: 'Person'
        };
        const companyType = {
            name: 'Company'
        };
        const placeType = {
            name: 'Place'
        };
        const companyVendorType = {
            name: 'CompanyVendor',
            parent: companyType
        };
        const personVendorType = {
            name: 'PersonVendor',
            parent: personType
        };

        return [
            companyType,
            companyVendorType, {
                name: 'AutoRepairShop',
                parent: companyVendorType
            }, {
                name: 'AutoTowingAgcy',
                parent: companyVendorType
            }, {
                name: 'LawFirm',
                parent: companyVendorType
            }, {
                name: 'MedicalCareOrg',
                parent: companyVendorType
            },
            personType, {
                name: 'Adjudicator',
                parent: personType
            },
            personVendorType, {
                name: 'Attorney',
                parent: personVendorType
            }, {
                name: 'Doctor',
                parent: personVendorType
            }, {
                name: 'UserContact',
                parent: personType
            },
            placeType, {
                name: 'LegalVenue',
                parent: placeType
            }
        ];
    }

    /**
     * The types in the Contact hierarchy.
     * @type {Array}
     */
    const contactTypes = initializeHierarchy();

    /**
     * Helper function to append a field to a address.
     * @param {string} base the current address, may be null
     * @param {string} separator the separator to use before appending the field. It only be added if both
     * the base and the newField parameters are not null.
     * @param {*} newField the new field value to be appended, may be null
     * @returns {string} the resulting address string.
     */
    function append(base, separator, newField) {
        let result = base;
        if (result.length > 0 && newField && newField.length > 0) {
            result += separator;
        }
        if (newField) {
            result += newField;
        }
        return result;
    }

    return {
        /**
         * Returns a string representation for a Contact.
         * @param {Object} contact the contact
         * @param {Object} $filter AngularJS filter
         * @returns {string} the string representation for a contact, or 'Unknown' if the parameter is falsy.
         */
        getDisplayName: (contact, $filter) => {
            return contact ? contact.displayName : $filter('translate')('platform.contacts.Unknown');
        },

        /**
         * Returns a string representation for an addresss
         * @param {Object} [address] the address
         * @returns {string} the string representation for the address, or null if the parameter is falsy.
         */
        getFullAddress: (address) => {
            if (!address) {
                return null;
            }
            let fullAddress = '';
            fullAddress = append(fullAddress, '', address.addressLine1);
            fullAddress = append(fullAddress, ', ', address.addressLine2);
            fullAddress = append(fullAddress, ',\n', address.city);
            fullAddress = append(fullAddress, ', ', address.state);
            fullAddress = append(fullAddress, ' ', address.postalCode);
            return fullAddress;
        },

        /**
         * Returns true if 'contact' is an instance of a given Contact type.
         * @param {Object} contact
         * @param {Object} contactType
         * @returns {boolean}
         */
        isInstanceOf: (contact, contactType) => {
            let foundType = _.find(contactTypes, (_contactType) => {
                return _contactType.name === contact.subtype;
            });
            while (foundType && foundType.name !== contactType) {
                foundType = foundType.parent;
            }
            return !!foundType;
        },

        /** Returns contact's primary phone number
         * @param {Object} contact contact to get its primary phone.
         * @returns {*} contact primary phone.
         */
        getPrimaryPhone: (contact) => {
            switch (contact.primaryPhoneType) {
                case 'home':
                    return contact.homeNumber;
                case 'work':
                    return contact.workNumber;
                case 'mobile':
                    return contact.cellNumber;
                default:
                    return null;
            }
        }
    };
};
