import templateStr from 'text!./templates/alert.html';

export default [() => {
    return {
        restrict: 'EA',
        transclude: true,
        replace: true,
        template: templateStr,
        scope: {
            type: '@',
            close: '&'
        },
        link: (scope, element, attrs) => {
            scope.closeable = 'close' in attrs;

            scope.icon = () => {
                switch (scope.type) {
                    case 'error':
                        return 'fa fa-minus-circle gw-alert__icon_type_error';
                    case 'warning':
                        return 'fa fa-exclamation-triangle gw-alert__icon_type_warning';
                    case 'info':
                        return 'fa fa-info-circle gw-alert__icon_type_info';
                    default:
                        /* don't display an icon if unknown type provided */
                        return null;
                }
            };
        }
    };
}];
