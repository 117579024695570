import templateStr from 'text!./pm-edit-claim.html';
import moment from 'moment';

export default [() => {
    return {
        restrict: 'AE',
        scope: {
            pmClaims: '=?',
            addClaim: '=?',
            removeClaim: '=?',
            driver: '<',
            submission: '='
        },
        controller: ['$scope', function ($scope) {
            $scope.minClaimDate = moment().subtract(5, 'years');
            $scope.maxClaimDate = moment();
        }],
        template: templateStr
    };
}];