/**
 * Allows us to dynamically set ng-form names.
 *
 * It uses the AngularJS interpolator to determine the value in the name attribute
 * and then sets this as the name of the ng-form.
 *
 * The issue, which is been tracked here https://github.com/angular/angular.js/issues/1404, was
 * getting a lot of traffic, it may be the case that this gets fixed in the future, so we should
 * keep an eye on it to see if it does. We can remove this decorator when it does.
 *
 * @param {Object} $provide
 */
export default function ($provide) {
    $provide.decorator('ngModelDirective', ['$delegate', function ($delegate) {
        const ngModel = $delegate[0];
        const controller = ngModel.controller;
        ngModel.controller = ['$scope', '$element', '$attrs', '$injector', function (scope, element, attrs, $injector) {
            const $interpolate = $injector.get('$interpolate');
            attrs.$set('name', $interpolate(attrs.name || '')(scope));
            $injector.invoke(controller, this, {
                '$scope': scope,
                '$element': element,
                '$attrs': attrs
            });
        }];
        return $delegate;
    }]);
    $provide.decorator('ngFormDirective', ['$delegate', function ($delegate) {
        const form = $delegate[0];
        const controller = form.controller;
        form.controller = ['$scope', '$element', '$attrs', '$injector', function (scope, element, attrs, $injector) {
            const $interpolate = $injector.get('$interpolate');
            attrs.$set('name', $interpolate(attrs.name || attrs.ngForm || '')(scope));
            $injector.invoke(controller, this, {
                '$scope': scope,
                '$element': element,
                '$attrs': attrs
            });
        }];
        return $delegate;
    }]);
}
