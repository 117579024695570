import BaseCtrl from 'edge/platform/base/BaseCtrl';

import {wizardParentState} from '../RouteConfig';
import _ from 'lodash';
import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';

const fn = ($scope, $state, $rootElement, StateUtils, $q, ViewModelService, ModalService, $stateParams, CPCoverablesService) => {
    $scope.flowModel = $state.get(wizardParentState).data.flow;

    const ctrl = Object.create(BaseCtrl);
    const model = StateUtils.activeDataForState(true).model;
    $scope.jobID = model.quoteID ? model.quoteID.value : model.jobID.value;
    $scope.sessionID = SessionUtil.getSessionUUID(model);

    ctrl.init = () => {
        $rootElement.scrollTop(0);

        $scope.location = $stateParams.model.selectedLocation;
        $scope.buildings = $scope.location.buildings;

        // Copy the building so that we don't edit it by reference. Necessary to handle back without save.
        $scope.building = _.extend({}, $stateParams.model.selectedBuilding || {classCode: ''});
        // Set the snapshot to reference the building which will be updated in the form
        $stateParams.model.selectedBuildingSnapshot = $scope.building;
        $scope.isExistingBuilding = Boolean($stateParams.model.selectedBuilding);

        $scope.fakeLocationModel = {
            value: $scope.location.displayName,
            aspects: {valid: true}
        };

        $scope.editLocation = () => {
            $scope.flowModel.jumpToStep('SelectLocation');
        };

        $scope.buildingView = ViewModelService.create($scope.building, 'pc', 'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.BuildingDTO');

        $scope.buildingView.coverageForm = 'BPP';
        $scope.buildingView.rateType = 'Class';

        function validateAndSubstituteNumericInput(viewModel) {
            // This is a workaround for the fact that the back-end does not gracefully accept an empty string as a null number.
            // Any new numeric properties would have to be handled in the same way, or a generic approach used on the back-end.
            ['yearBuilt', 'numberOfStories', 'totalAreaExcludingBasement'].forEach(key => {
                if (!viewModel[key]) {
                    viewModel[key] = null;
                }
            });
            return viewModel;
        }

        const getBuildingToSave = () => {
            const newBuilding = validateAndSubstituteNumericInput($scope.buildingView.value);
            newBuilding.displayName = formatDisplayName(newBuilding);
            return newBuilding;
        };

        $scope.saveBuilding = (callback) => {
            $scope.buildingInfo.newBuildingForm.submitted = true;
            if ($scope.buildingInfo.newBuildingForm.invalid) {
                callback();
                return;
            }

            const building = getBuildingToSave();
            buildingOp(callback, building);
        };
    };

    function buildingOp(callback, building) {
        const rpcCall = $scope.isExistingBuilding ? CPCoverablesService.updateCPBuilding : CPCoverablesService.addCPBuilding;
        rpcCall(
            $scope.jobID,
            $scope.location.publicID,
            building,
            SessionUtil.getSessionUUID(model)
        ).then((result) => {
            _.extend(building, result);

            _.remove($scope.buildings, (currentBuilding) => {
                return currentBuilding.publicID === building.publicID;
            });

            // Update building on the state model
            const location = _.find(model.lobData.commercialProperty.coverables.locations.value, (loc) => {
                return loc.publicID === $scope.location.publicID;
            });

            const buildingIndex = _.findIndex(location.buildings, (blding) => {
                return blding.publicID === building.publicID;
            });

            if (buildingIndex >= 0) {
                location.buildings.splice(buildingIndex, 1, building);
            } else {
                location.buildings.push(building);
            }

            $stateParams.model.selectedBuilding = building;
            $stateParams.model.selectedBuildingSnapshot = building;

            if (model.newlyAddedBuildingList) {
                model.newlyAddedBuildingList = model.newlyAddedBuildingList.concat([building.publicID]);
            } else {
                model.newlyAddedBuildingList = [building.publicID];
            }

            callback($stateParams.model);
        }).catch(() => ModalService.showError('quoteandbind.cp.directives.cp-building-directive.Building not saved', 'quoteandbind.cp.Please check for errors and try again'));
    }

    function formatDisplayName(building) {
        return `${building.description}`;
    }

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$rootElement', 'StateUtils', '$q', 'ViewModelService', 'ModalService', '$stateParams', 'qnb.cpCoverablesService'];

export default fn;