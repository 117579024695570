import googleAnalytics from './googleAnalytics';
import googleAnalyticsAdapterProvider from './googleAnalyticsAdapterProvider';
import segmentIOAdapterProvider from './SegmentIOAdapterProvider';
import consoleLogAdapter from 'gw-portals-tracking-js/adapters/ConsoleLogAdapter';
import localStorageAdapter from 'gw-portals-tracking-js/adapters/LocalstorageAdapter';

angular.module('gw-portals-tracking-angular.adapters', [])
    .factory('googleAnalytics', googleAnalytics)
    .provider('adapters.googleAnalytics', googleAnalyticsAdapterProvider)
    .provider('adapters.segmentIO', segmentIOAdapterProvider)
    .value('adapters.console', consoleLogAdapter)
    .value('adapters.localstorage', localStorageAdapter);