import GetVersionL10nId from 'gw-portals-platform-js/versionName/GetVersionL10nId';

// HELPERS
/**
 * Sets the document title
 * @param {Object} $translate - Angular translate service
 * @param {String} l10nId - l10n ID to be used
 */
function setDocTitle({$translate, l10nId}) {
    $translate(l10nId)
        .then((translatedValue) => {
            document.title = translatedValue;
        }).catch(console.error);
}

/**
 * Sets the global document title depending on the versions available for the app
 * @param {Object} $translate - Angular translate service
 * @param {String} [translationIdBase] - prefix of the l10n ID (if the versionName is available) or the l10n ID to be used
 */
export default ({$translate, translationIdBase}) => {
    const l10nId = GetVersionL10nId(translationIdBase);

    setDocTitle({$translate, l10nId});
};