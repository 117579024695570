import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $rootScope, $stateParams, submissionViewModel, PolicyService, LoadSaveService) => {
    const ctrl = Object.create(BaseCtrl);
    const submissionVm = submissionViewModel();
    const pmModel = submissionVm.lobData.personalMotor.value;
    const accountHolder = submissionVm.value.getAccountHolderPerson();
    $scope.contactPhone = submissionVm.bindData.contactPhone;
    $scope.boxDeclaration = submissionVm.bindData.boxDeclaration_itb
    if ($scope.contactPhone !== undefined) {
        if ($scope.contactPhone.value !== null && !$scope.contactPhone.aspects.valid) {
            $scope.contactPhone.value = '07000000000';
        }
    }

    if ($scope.boxDeclaration !== undefined) {
        if ($scope.boxDeclaration.value !== null && !$scope.boxDeclaration.aspects.valid) {
            $scope.boxDeclaration.value = true;
        }
    }

    ctrl.init = () => {
        $scope.page = {
            readonly: false,
            ncdYearsOptions: submissionVm.lobData.personalMotor.coverables.ncdYearsOptions.value,
            submissionVm: submissionVm,
            updateVehicle
        };
        if (submissionVm.bindData && submissionVm.bindData.accountNumber && submissionVm.bindData.autoRenew_itb.value === undefined) {
            submissionVm.bindData.autoRenew_itb.value = false;
        }
        let selectedVehicle;
        /**
         *  If there are no vehicles, initialise an empty vehicle to be populated,
         *  otherwise on first enter, when there are no vehicles, pick the first one from the array.
         */
        if (pmModel.coverables.pmVehicles.length === 0) {
            selectedVehicle = pmModel.coverables.createVehicle(accountHolder);
        } else {
            selectedVehicle = pmModel.coverables.pmVehicles[0];
        }
        $scope.page.selectedVehicle = submissionVm.lobData.personalMotor.coverables.pmVehicles.findFirstElement(selectedVehicle);
        PolicyService.getModificationLists($rootScope.productCode)
            .then((data) => {

                $scope.page.afterMarketOptions = data[0];
                $scope.page.bodyworkModifications = data[1];
                $scope.page.engineModifications = data[2];
                $scope.page.wheelsOrTyresModifications = data[3];
                $scope.page.conversionModifications = data[4];
                $scope.page.otherModifications = data[5];
            });
        $scope.phDriver = submissionVm.lobData.personalMotor.coverables.value.getPHDriver();
        $scope.page.driver = submissionVm.lobData.personalMotor.coverables.pmDrivers.findFirstElement($scope.phDriver);

        if ($stateParams.changeConfirmationCallback) {
            $stateParams.changeConfirmationCallback(
                $scope.page.selectedVehicle,
                $scope.page.driver,
                $scope.page.afterMarketOptions,
                $scope.page.bodyworkModifications,
                $scope.page.engineModifications,
                $scope.page.wheelsOrTyresModifications,
                $scope.page.conversionModifications,
                $scope.page.otherModifications,
                $scope.page.updateVehicle
            );
        }
    };

    const updateModel = (sub) => {
        let selectedVehicle;
        if (sub.lobData.personalMotor.coverables.pmVehicles.length === 0) {
            selectedVehicle = sub.lobData.personalMotor.coverables.createVehicle(accountHolder);
        } else {
            selectedVehicle = sub.lobData.personalMotor.coverables.pmVehicles[0];
        }
        if($scope.page.selectedVehicle.quickSave.value === true) {
            selectedVehicle.quickSave = false;
            Object.assign($scope.page.selectedVehicle, selectedVehicle); // Copy all values to the PM Vehicle
        }
    };

    const updateVehicle = function () {
        $scope.page.updateInFlight = true;
        const draftSubmissionVM = $scope.page.submissionVm;
         LoadSaveService.updateDraftSubmission({
            sessionUUID: draftSubmissionVM.sessionUUID.value,
            quoteID: draftSubmissionVM.quoteID.value,
            lobData: draftSubmissionVM.lobData.value,
            baseData: draftSubmissionVM.baseData.value
        })
            .then((sub) => {
                return updateModel(sub);
            })
            .finally(() => {
                $scope.page.updateInFlight = false;
            });
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$rootScope', '$stateParams', 'submissionViewModel', 'qnb.PolicyService', 'qnb.LoadSaveService'];

export default fn;
