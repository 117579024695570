/** http://dalelotts.github.io/angular-bootstrap-datetimepicker/
 * decorator
 */

import moment from 'moment';
import DateUtil from 'gw-portals-util-js/DateUtil';

function getValidDateOrNull(dateParam) {
    let date;
    if (typeof dateParam === 'undefined') {
        date = null;
    } else {
        date = new Date(dateParam);
        // check if date object is invalid;
        if (Number.isNaN(date.getTime())) {
            date = null;
        }
    }
    return date;
}

export default ['$provide', 'dateTimePickerConfig', function ($provide, dateTimePickerConfig) {
    $provide.decorator('datetimepickerDirective', ['$delegate', '$timeout', function ($delegate, $timeout) {
        const directive = $delegate[0];

        /* ----- INTERNAL DATEPICKER'S METHODS START ----- */
        function DateObject() {
            const tempDate = DateUtil.currentDate();
            const localOffset = tempDate.getTimezoneOffset() * 60000;
            this.utcDateValue = tempDate.getTime();
            this.selectable = true;

            this.localDateValue = function () {
                return this.utcDateValue + localOffset;
            };

            const validProperties = ['utcDateValue', 'localDateValue', 'display', 'active', 'selectable', 'past', 'future'];

            for (const prop in arguments[0]) {
                /* istanbul ignore else */
                // noinspection JSUnfilteredForInLoop
                if (validProperties.indexOf(prop) >= 0) {
                    // noinspection JSUnfilteredForInLoop
                    this[prop] = arguments[0][prop];
                }
            }
        }

        const getUTCTime = function getUTCTime(modelValue) {
            const tempDate = (modelValue ? moment(modelValue).toDate() : DateUtil.currentDate());
            return tempDate.getTime() - (tempDate.getTimezoneOffset() * 60000);
        };
        /* ----- INTERNAL DATEPICKER'S METHODS END ----- */

        directive.compile = function () {
            return function (scope, element, attrs, ngModelController) {
                directive.link.apply(this, arguments);
                const parentScope = scope.$parent;

                // support the default date
                function updateDefaultDate(newDate, oldDate) {
                    if (oldDate !== newDate) {
                        if (angular.isDate(newDate)) {
                            $timeout(() => {
                                scope.changeView(
                                    parentScope.startView,
                                    new DateObject({
                                        utcDateValue: newDate.getTime()
                                    })
                                );
                            });
                        }
                    }
                }

                updateDefaultDate(parentScope.defaultDate);
                parentScope.$watch('defaultDate', updateDefaultDate);

                // add support for max-date and min-date to the picker
                scope.beforeRender = (picker) => {
                    const dates = picker.$dates;
                    const view = picker.$view; // hour, day, month or year
                    let maxDate = getValidDateOrNull(parentScope.maxDate);
                    let minDate = getValidDateOrNull(parentScope.minDate);
                    // extend range for each view
                    if (maxDate) {
                        maxDate = moment(maxDate).endOf(view).valueOf();
                    }
                    if (minDate) {
                        minDate = moment(minDate).startOf(view).valueOf();
                    }
                    // make items outside of the range not selectable
                    dates.forEach((dateObject) => {
                        const date = dateObject.utcDateValue;
                        const isGreaterThanMaxDate = maxDate && date > maxDate;
                        const isLesserThanMinDate = minDate && date < minDate;
                        if (isGreaterThanMaxDate || isLesserThanMinDate) {
                            dateObject.selectable = false;
                        }
                    });
                };
                scope.$root.$on('$translateChangeSuccess', () => {
                    // RESET VIEW TO BE UPDATED WITH THE NEW LOCALE
                    scope.changeView(
                        dateTimePickerConfig.startView,
                        new DateObject({
                            utcDateValue: getUTCTime(ngModelController.$viewValue)
                        })
                    );
                });
            };
        };

        return $delegate;
    }]);
}];
