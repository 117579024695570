import template from 'text!./templates/loader.html';

const noop = () => {
};

const LOADER_INLINE_CLASS = 'gw-loader-wrapper_inline';
const LOADER_LARGE_CLASS = 'gw-loader-wrapper_large';
const LOADER_SMALL_CLASS = 'gw-loader-wrapper_small';
const LOADER_TRANSPARENT_CLASS = 'gw-loader-wrapper_transparent';
const LOADER_HAS_OFFSET_CLASS = 'gw-loader-wrapper_hasOffset';
const LOADER_CENTERED_CLASS = 'gw-loader-wrapper_centered';
const HIDE_OVERFLOW_CLASS = 'gw-loader-wrapper_hide-overflows';
const HAS_TEXT_CLASS = 'gw-loader-wrapper_has-text';
const TRANSITION_START_CLASS = 'gw-loader_running';
const SELENIUM_LOADER_DONE_HOOK = 'gw-loader-done'; // For selenium tests denotes that load condition is met and loader has finished loading

/**
 * A loader directive that covers whatever it is set on with an overlay and shows a loader on top.
 *
 * Accepts a promise, array of promises or a boolean to track whether the thing is loaded.
 * Page loader flag determines if loader is used for the whole page (affects positioning).
 *
 * gw-loader-inline attribute sets loader to be applied for inline elements
 * gw-loader-small attribute applies smaller version of progress indicator
 * gw-loader-transparent attribute makes the loader backdrop transparent
 * gw-loader-no-offset attribute removes offsets from the loader
 * gw-loader-centered attribute forces vertical centering of the loader
 *
 * @example:
 *  <div gw-loader="new Promise()"
 *       page-loader="true"
 *  >...</div>
 *
 * @example:
 *  <div gw-loader="booleanFlagOnScope"
 *       gw-loader-inline
 *  >...</div>
 *
 * @example:
 *  <div gw-loader="[promise, promise, promise]">...</div>
 */

export default ['$compile', '$q', ($compile, $q) => {
    return {
        restrict: 'A',
        scope: {
            loaded: '&gwLoader',
            pageLoader: '&',
            text: '@gwLoaderText'
        },
        link: ($scope, $element, $attrs) => {
            // SET INLINE/BLOCK LOADER VIEW
            const isInline = $attrs.gwLoaderInline !== undefined;
            const classes = [];
            if (isInline) {
                classes.push(LOADER_INLINE_CLASS);
                classes.push(LOADER_CENTERED_CLASS);
            } else if ($attrs.gwLoaderSmall !== undefined) {
                classes.push(LOADER_SMALL_CLASS);
            } else {
                classes.push(LOADER_LARGE_CLASS);
            }

            if ($attrs.gwLoaderTransparent !== undefined) {
                classes.push(LOADER_TRANSPARENT_CLASS);
            }

            if (!isInline && $attrs.gwLoaderNoOffset === undefined) {
                classes.push(LOADER_HAS_OFFSET_CLASS);
            }

            if ($attrs.gwLoaderCentered !== undefined) {
                classes.push(LOADER_CENTERED_CLASS);
            }

            if ($scope.text) {
                classes.push(HAS_TEXT_CLASS);
            }

            $element.addClass(classes.join(' '));

            let isAppended = false;
            let stopLoader = noop;

            const startLoader = function () {
                if (isAppended) {
                    return;
                }

                $element.removeClass(SELENIUM_LOADER_DONE_HOOK);

                const scope = $scope.$new(true);
                scope.doneLoading = false;
                scope.pageLoader = $scope.pageLoader();
                scope.text = $scope.text;

                const loader = $compile(template)(scope);
                $element.append(loader);
                if (!scope.pageLoader) {
                    $element.addClass(HIDE_OVERFLOW_CLASS);
                }

                noop(loader.get(0).offsetHeight); // Trigger reflow so that transition starts
                loader.addClass(TRANSITION_START_CLASS);

                stopLoader = function () {
                    $element.removeClass(HIDE_OVERFLOW_CLASS);
                    $element.addClass(SELENIUM_LOADER_DONE_HOOK);
                    loader.remove();
                    isAppended = false;
                };

                isAppended = true;
            };

            $scope.$watchCollection('loaded()', () => {
                const loaded = $scope.loaded();
                if (loaded && (typeof loaded.then === 'function' || Array.isArray(loaded))) {
                    // Got thenable or an array of what we assume are thenables. Stop whatever was previously there.
                    stopLoader();

                    // Append the new loader
                    startLoader();

                    // And remove once resolved or rejected.
                    const resultingPromise = Array.isArray(loaded) ? $q.all(loaded) : loaded;
                    resultingPromise.then(stopLoader, stopLoader);
                } else if (loaded) {
                    stopLoader();
                } else {
                    startLoader();
                }
            });

            $scope.$on('$destroy', () => {
                stopLoader();
            });
        }
    };
}];
