export default [
    function () {
        return {
            /**
             * Checks the existing policies in the policy summaries provided and returns a default
             * product line that should be sold, based on the existing policy lines
             * @param {Array} policySummaries
             * @returns {*}
             */
            getDefaultProduct: function (policySummaries) {
                const hasPersonalAutoPolicy = lineExistsInPolicySummaries(policySummaries, 'PersonalAutoLine');
                const hasHomeownersPolicy = lineExistsInPolicySummaries(policySummaries, 'HomeownersLine_HOE');

                if (hasHomeownersPolicy && !hasPersonalAutoPolicy) {
                    return 'PersonalAuto';
                } else if (hasPersonalAutoPolicy && !hasHomeownersPolicy) {
                    return 'Homeowners';
                }

                return undefined;
            }
        };

        /**
         * Checks all periods for all policies in the given policy summaries
         * to see if the given line exists in any period
         *
         * @param {Array} policySummaries
         * @param {*} line
         *
         * @returns {Boolean}
         */
        function lineExistsInPolicySummaries(policySummaries, line) {
            return policySummaries.filter((policy) => {
                return policy.periods.filter((policyPeriod) => {
                    return policyPeriod.lines.includes(line);
                }).length > 0;
            }).length > 0;
        }
    }
];
