import _ from 'lodash';
import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./Location.html';
import styles from './Location-hashed.scss';

const LocationDTOName = 'edge.capabilities.policycommon.coverage.schedule.dto.ScheduledLocationDTO';
const getDefaultLocationDTO = () => ({
    address: {},
    isNonSpecificLocation: false
});

export default () => ({
    restrict: 'E',
    scope: {
        schedule: '<',
        scheduledItem: '<',
        shouldShowErrors: '<',
        onBeforeCommit: '<'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'ViewModelService', 'LocaleService', 'AddressUtil', ($scope, ViewModelService, LocaleService, AddressUtil) => {
        $scope.phoneMask = LocaleService.getRegionSamplePhoneNumber().replace(/[0-8]/g, '9');

        $scope.$watch('shouldShowErrors', shouldShowErrors => {
            $scope.locationForm.submitted = shouldShowErrors;
        });

        $scope.$watch('scheduledItem', item => {
            $scope.isNew = !$scope.schedule.getScheduledItems().some(schedItem => schedItem === item);

            const location = item.describe().location || getDefaultLocationDTO();
            const locationBase = _.cloneDeep(location);

            $scope.locationView = ViewModelService.create(locationBase, 'pc', LocationDTOName);
            $scope.existingLocations = $scope.schedule.describe().existingLocations.map(loc => {
                return ViewModelService.create(loc, 'pc', LocationDTOName);
            });

            $scope.useExistingLocation = {
                value: $scope.existingLocations.length > 0,
                aspects: {
                    required: false,
                    inputCtrlType: 'boolean',
                    get valid() {
                        return true;
                    }
                }
            };
            $scope.chosenExistingLocation = {
                value: $scope.existingLocations[0],
                aspects: {
                    required: false,
                    inputCtrlType: 'typelist',
                    availableValues: $scope.existingLocations,
                    get valid() {
                        return true;
                    }
                }
            };
            $scope.locationToString = (loc) => {
                return AddressUtil.format(loc.value.address);
            };

            ($scope.onBeforeCommit || angular.noop)(() => {
                if (item.isValid() && (($scope.isNew && $scope.useExistingLocation.value) || $scope.locationView.aspects.subtreeValid)) {
                    const targetLocation = ($scope.isNew && $scope.useExistingLocation.value) ? _.cloneDeep($scope.chosenExistingLocation.value.value) : locationBase;
                    _.extend(location, targetLocation);

                    if ($scope.isNew) {
                        item.describe().location = location;
                    }

                    return true;
                }

                return false;
            });
        });
    }]
});
