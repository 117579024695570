let inputCounter = 0;
class RadioSelectImageCtrl {

    $onInit() {
        this.inputCounter = inputCounter++;
    }

    /**
     * Returns the image url
     * @param {string} imgName
     * @returns {string}
     */
    imageUrl(imgName) {
        return `${this.imgSrc}${imgName}`;
    }

    /**
     * Show custom option when current selected option is 'other'
     * @returns {boolean}
     */
    showCustomOption() {
        return this.customOptionModel && this.model.value && this.model.value.code === 'other';
    }
}

export default RadioSelectImageCtrl;