import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import _ from 'lodash';
import {stateNamePrefix as AddBuildingSubWizard} from '../AddBuildingWizard/RouteConfig';

class EditBuildingCtrl {
    constructor($rootScope, $scope, $state, ViewModelService, ModalService, CPCoverablesService, $filter, StateUtils, cpDeleteBuildingService) {
        const buildingDTOName = 'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.BuildingDTO';
        this.model = StateUtils.activeDataForState(true).model;
        this.jobID = this.model.quoteID ? this.model.quoteID.value : this.model.jobID.value;
        this.sessionId = SessionUtil.getSessionUUID(this.model);
        this.showChangesWarning = true;

        $scope.location = _.find(this.model.lobData.commercialProperty.coverables.locations.value, (location) => {
            return location.publicID === $state.params.building.locationId;
        });

        $scope.building = _.find($scope.location.buildings, (building) => {
            return building.publicID === $state.params.building.publicID;
        });

        $scope.buildingVM = ViewModelService.create(_.clone($scope.building), 'pc', buildingDTOName);

        const cancelWarning = () => {
            return ModalService.showWarning(
                'quoteandbind.cp.buildingsWizard.EditBuilding.Cancel Changes?',
                'quoteandbind.cp.buildingsWizard.EditBuilding.You will lose all the changes',
                undefined,
                undefined,
                undefined,
                $filter('translate')('quoteandbind.cp.No')
            );
        };

        $scope.back = (buildingUpdated) => {
            if (!buildingUpdated && !_.isEqual($scope.building, $scope.buildingVM.value)) {
                cancelWarning().result.then(() => {
                    handleBack(buildingUpdated);
                });
            } else {
                handleBack(buildingUpdated);
            }
        };

        const handleBack = (buildingUpdated) => {
            this.showChangesWarning = false;
            const params = {
                publicID: $scope.building.publicID,
                isBuildingUpdated: Boolean(buildingUpdated),
                isCoverageOpen: false
            };

            $state.go('cpBuildingWizardViewBuilding', params);
        };

        $scope.deleteBuilding = () => {
            cpDeleteBuildingService.deleteBuilding(this.model, $scope.location, $scope.building)
                .then(() => $state.go('cpBuildingWizardBuildingsList'))
                .catch(() => ModalService.showError('quoteandbind.cp.directives.cp-building-view.Building not removed', 'quoteandbind.cp.views.cp-building-view.Please check for errors and try again'));
        };

        $scope.updateBuilding = () => {
            $scope.buildingInfo.newBuildingForm.submitted = true;
            if ($scope.buildingInfo.newBuildingForm.invalid) {
                return;
            }

            // No need to send the update request is nothing has changed
            if (_.isEqual($scope.building, $scope.buildingVM.value)) {
                $scope.back(true);
            } else {
                updateBuilding().then(() => {
                    $scope.back(true);
                }).catch(() => {
                    ModalService.showError('quoteandbind.cp.directives.cp-building-directive.Building not saved', 'quoteandbind.cp.Please check for errors and try again');
                });
            }
        };

        $scope.cancel = () => {
            if (_.isEqual($scope.building, $scope.buildingVM.value)) {
                handleBack();
            } else {
                cancelWarning().result.then(() => {
                    handleBack();
                });
            }
        };

        $scope.toEditLocation = () => {
            $state.go('cpBuildingWizardEditLocation', {publicID: $scope.location.publicID, buildingPublicID: $scope.building.publicID});
        };

        const updateBuilding = () => {
            this.updateBuildingPromise = CPCoverablesService.updateCPBuilding(this.jobID, $scope.location.publicID, $scope.buildingVM.value, this.sessionId);

            return this.updateBuildingPromise.then((building) => {

                const buildingIndex = _.findIndex($scope.location.buildings, (blding) => {
                    return blding.publicID === building.publicID;
                });

                $scope.location.buildings.splice(buildingIndex, 1, building);
            });

        };

        const stateChangeUnsub = $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
            if (this.showChangesWarning &&
                !_.isEqual($scope.building, $scope.buildingVM.value) &&
                (StateUtils.hasState('baseTransactionWizard', toState) || StateUtils.hasState(AddBuildingSubWizard, toState))) {

                event.preventDefault();

                ModalService.showWarning(
                    'quoteandbind.cp.Unsaved Changes',
                    'quoteandbind.cp.Do you want to save your changes?',
                    undefined,
                    undefined,
                    undefined,
                    $filter('translate')('quoteandbind.cp.No')
                )
                    .result.then(() => {
                        // Update the building - this will then transition
                        updateBuilding().then(() => {
                            $state.go(toState, toParams);
                        });

                    }).catch(() => {
                        // If the user chooses to discard, go to state
                        $state.go(toState, toParams);
                    });
            }
            stateChangeUnsub(); // Unsubscribe $stateChangeStart listener
        });
    }
}

EditBuildingCtrl.$inject = ['$rootScope', '$scope', '$state', 'ViewModelService', 'ModalService', 'qnb.cpCoverablesService', '$filter', 'StateUtils', 'qnb.cpDeleteBuildingService'];

export default EditBuildingCtrl;
