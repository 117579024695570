import 'gw-portals-viewmodel-js';
import Context from 'gw-portals-viewmodel-js/aspects/Context';
import Requiredness from 'gw-portals-viewmodel-js/aspects/Requiredness';
import Validity from 'gw-portals-viewmodel-js/aspects/Validity';
import SubtreeValidity from 'gw-portals-viewmodel-js/aspects/SubtreeValidity';
import InputCtrlType from 'gw-portals-viewmodel-js/aspects/InputCtrlType';
import AvailableValues from 'gw-portals-viewmodel-js/aspects/AvailableValues';
import SerializedForm from 'gw-portals-viewmodel-js/aspects/SerializedForm';
import RebuildVMFromData from 'gw-portals-viewmodel-js/aspects/RebuildVMFromData';
import DebugInvalidChildProperties from 'gw-portals-viewmodel-js/aspects/DebugInvalidChildProperties';
import TypedValue from 'gw-portals-viewmodel-js/aspects/TypedValue';
import _ValidationFunctions from 'gw-portals-viewmodel-js/aspects/_ValidationFunctions';
import DateUtil from 'gw-portals-util-js/DateUtil';
import _PhoneUtil from 'gw-portals-util-angular/PhoneUtil';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import 'gw-portals-exp-lang-angular';
import '../module';
import 'l10nModule';

export default angular.module('gw-portals-viewmodel-angular.aspects', ['edge.platform.el', 'gw-portals-viewmodel-angular'])
    .run(['ViewModelService', 'ExpressionLanguageService', 'TranslateService', 'LocaleService', 'DateFormatter', (ViewModelService, ExpressionLanguageService, TranslateService, LocaleService, DateFormatter) => {
        const typeValidationMessages = {};

        TranslateService.translate({
            'object': typeValidationMessages,
            'displayKey': 'platform.modelvalidation.DTOValidationService.Please enter numbers only',
            'propName': 'notANumber'
        });
        TranslateService.translate({
            'object': typeValidationMessages,
            'displayKey': 'platform.modelvalidation.DTOValidationService.Value entered must not contain decimal values',
            'propName': 'notAnInteger'
        });

        ViewModelService.addAspectFactory(Context.create(ExpressionLanguageService));
        ViewModelService.addAspectFactory(Requiredness.create(ExpressionLanguageService));
        ViewModelService.addAspectFactory(Validity.create(ExpressionLanguageService, typeValidationMessages));
        ViewModelService.addAspectFactory(SubtreeValidity);
        ViewModelService.addAspectFactory(InputCtrlType);
        ViewModelService.addAspectFactory(AvailableValues.create(ExpressionLanguageService));
        ViewModelService.addAspectFactory(SerializedForm);
        ViewModelService.addAspectFactory(RebuildVMFromData);
        ViewModelService.addAspectFactory(DebugInvalidChildProperties);
        ViewModelService.addAspectFactory(TypedValue);

        ExpressionLanguageService.getCompilationContext().registerType('edge.aspects.validation.ValidationFunctions', _ValidationFunctions(LocaleService));
        ExpressionLanguageService.getCompilationContext().registerType('edge.capabilities.locale.util.DateFormatter', DateFormatter);
        ExpressionLanguageService.getCompilationContext().registerType('gw.api.util.DateUtil', DateUtil);
        ExpressionLanguageService.getCompilationContext().registerType('gw.api.util.PhoneUtil', _PhoneUtil(LocaleService));
        ExpressionLanguageService.getCompilationContext().registerType('edge.time.LocalDateUtil', LocalDateUtil);
    }]);
