import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './mobileQuoteInternal-hashed.scss';
import templateStr from 'text!./mobileQuoteInternal.html';

function controller() {
    const $ctrl = this;
    $ctrl.toggleCoverageStateVisibility = toggleCoverageStateVisibility;
}

function toggleCoverageStateVisibility(coverageState) {
    coverageState.infoVisible = !coverageState.infoVisible;
}

export default {
    restrict: 'E',
    transclude: true,
    bindings: {
        coverages: '<',
        branchCode: '&',
        offeringId: '<',
        id: '@'
    },
    template: cssUtil.hashTemplate(templateStr, styles),
    controller: controller
};