import injectorDirectiveFactory from 'gw-portals-util-angular/injectorDirectiveFactory';

import PropertyCodeDirective from './PropertyCode/PropertyCode';
import IndustryCodeDirective from './IndustryCode/IndustryCode';
import DefaultMatchDirective from './Default/Default';

const knownMatchContentDefinitions = [DefaultMatchDirective, PropertyCodeDirective, IndustryCodeDirective];
const moduleName = injectorDirectiveFactory('gwTypeaheadMatchContent', {
    directive: '&',
    index: '=',
    match: '=',
    query: '='
}, knownMatchContentDefinitions, ['$scope', $scope => $scope.directive()]);

export {
    moduleName as default,
    DefaultMatchDirective
};