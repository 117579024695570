import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './defaultVehicleCoverages-hashed.scss';
import templateStr from 'text!./defaultVehicleCoverages.html';

export default {
    restrict: 'E',
    bindings: {
        vehicleCoverageArray: '=',
    },
    template: cssUtil.hashTemplate(templateStr, styles),
    controller: function controller() {
    }
};