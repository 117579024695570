import templateStr from 'text!./templates/binary-choice-group.html';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            label: '@',
            titleLeft: '@',
            titleRight: '@',
            valueLeft: '=',
            valueRight: '=',
            model: '=',
            inline: '=?',
            tooltip: '=?',
            readonly: '=?',
            disabled: '=?',
            flavor: '@?'
        }
    };
}];
