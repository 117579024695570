import _ from 'lodash';

export default {
    /**
     * "Safer" version of accessor which retrieves object's own properties only.
     * @param {Array} map
     * @param {*} prop
     * @returns {*}
     */
    'get': (map, prop) => {
        if (!_.has(map, prop)) {
            return;
        }

        return map[prop];
    },

    /**
     * Converts items list into the unique map.
     * @param {Array} items items to convert into the map.
     * @param {Function|String} keyFunctionOrProperty function used to access a key or name of the key property.
     * @returns {Array} map from key to object.
     * @throws Error if key is not unique.
     */
    'toUniqueMap': (items, keyFunctionOrProperty) => {
        const res = {};
        const keyFunction =
            _.isFunction(keyFunctionOrProperty) ? keyFunctionOrProperty : _.property(keyFunctionOrProperty);
        items.forEach((item) => {
            const key = keyFunction(item);
            if (_.has(res, key)) {
                throw new Error(`Duplicate key ${key} found`);
            }
            res[key] = item;
        });
        return res;
    },

    getApiKey: () => {
        try {
            // Note: Don't change the following lines to use import!
            const googleMapsCredentials = require('credentials/googleMaps.credentials.json');
            // https://developers.google.com/maps/documentation/javascript/get-api-key
            return googleMapsCredentials.apiKey; // this uses a feature of webpack to load
        } catch (error) {
            console.error('Please include a google maps API key to the googleMaps.credentials.json file in the app/js/credentials directory.');
        }
    }
};