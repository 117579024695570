import _ from 'lodash';

const factoryFn = (expressionLanguage) => {
    const CONTEXT_DTO_TYPE = 'edge.aspects.validation.dto.ContextDTO';

    function getContextValuesForNode(currentMetadataNode) {
        const cachedContext = {};
        const compilationCtx = expressionLanguage.getCompilationContext(currentMetadataNode.xCenter);

        // Add external context values as context value expressions
        if (currentMetadataNode.externalContext) {
            _.keys(currentMetadataNode.externalContext).forEach((key) => {
                cachedContext[key] = () => {
                    return currentMetadataNode.externalContext[key];
                };
            });
        }

        _.each(currentMetadataNode.elementMetadata.get(CONTEXT_DTO_TYPE), (contextDefn) => {
            const expFn = compilationCtx.compile(contextDefn.expression);
            cachedContext[contextDefn.name] = expFn;
        });
        return cachedContext;
    }

    const contextAspect = {
        /**
         * Add aspect properties to a view model node
         *
         * @param {Object} currentViewModelNode
         * @param {Object} currentMetadataNode
         * @param {Object} ancestorChain
         * @returns {Object}
         */
        getAspectProperties: (currentViewModelNode, currentMetadataNode, ancestorChain) => {
            const ctx = getContextValuesForNode(currentMetadataNode);


            return {
                context: {
                    value: (name) => {
                        if (ctx[name]) {
                            return ctx[name](currentViewModelNode, ancestorChain.parent);
                        }
                        return (ancestorChain.parent) ? ancestorChain.parent.aspects.context(name) : undefined;
                    }
                }
            };
        }
    };

    return contextAspect;
};

export default {
    'create': factoryFn
};