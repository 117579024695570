export default ['$scope', function ($scope) {
    const ctrl = this;

    $scope.tabs = [];
    ctrl.tabs = $scope.tabs;
    const tabs = ctrl.tabs;


    ctrl.select = function (tab) {
        angular.forEach(tabs, (_tab) => {
            _tab.active = false;
        });
        tab.active = true;
    };

    ctrl.addTab = function addTab(tab) {
        tabs.push(tab);
        if (tabs.length === 1 || tab.active) {
            ctrl.select(tab);
        }
    };

    ctrl.removeTab = function removeTab(tab) {
        const index = tabs.indexOf(tab);
        // Select a new tab if the tab to be removed is selected
        if (tab.active && tabs.length > 1) {
            // If this is the last tab, select the previous tab. else, the next tab.
            const newActiveIndex = index === tabs.length - 1 ? index - 1 : index + 1;
            ctrl.select(tabs[newActiveIndex]);
        }
        tabs.splice(index, 1);
    };
}];