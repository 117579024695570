/**
 * An event hub service
 *
 * To publish do: eventhub.publish('category', 'eventName', payload)
 * It will publish an event with a shape: { catagory, eventName, payload }
 *
 * To subscribe: eventhub.subscribe('category', 'eventName', ({ category,
 *   eventName, payload }) => {...});
 *
 * You can use a wildcard '*' in place of category and event name to subscribe
 * to all events
 *
 */
import EventEmitter from 'eventemitter2';

export default () => {

    const hub = new EventEmitter.EventEmitter2({
        // can subscribe to events e.g. 'transport.*.get' will subscribe to all
        // transport events using get,
        // 'transport.**' will subscribe to all transport events and
        // '*' will subscribe to all events in the hub
        wildcard: true,
        delimiter: '::'
    });

    // unlimited listeners
    hub.setMaxListeners(0);

    function checkString(paramName, value) {
        if (typeof value !== 'string' || value.indexOf('::') > -1) {
            throw new Error(`${paramName} should be a string and should not contain :: separator`);
        }
    }

    return {
        eventTransformers: [],

        /**
         * given category, eventName and payload emits a
         * { category, eventName, payload } frozen object
         *
         * @param {String} category
         * @param {String} eventName
         * @param {Object} payload
         */
        publish(category, eventName, payload) {
            let event = {
                category, eventName
            };
            if (payload) event.payload = payload;
            event = this.eventTransformers.reduce((ev, transformer) => transformer(ev), event);
            checkString('category', event.category);
            checkString('eventName', event.eventName);
            hub.emit([event.category, event.eventName], event);
        },
        /**
         * subscribe to given category and event name, once event
         * is fired, cb will be called with a { category, eventName, cb }
         * object,
         * category and eventName can be '*' which will call all objects.
         * @param {String} category
         * @param {String} eventName
         * @param {Function} callback
         * @returns {Function} unsubscribe function
         */
        subscribe(category, eventName, callback) {
            checkString('category', category);
            checkString('eventName', eventName);
            hub.on([category, eventName], callback);
            return hub.off.bind(hub, [category, eventName], callback);
        },
        /**
         * unsubscribe cb from category and eventName
         * If cb is not provided will remove all subscriptions from
         * the current category, eventName pair
         * @param {String} category
         * @param {String} eventName
         * @param {Function} callback
         * @returns {Function} unsubscribe function
         */
        unsubscribe(category, eventName, callback) {
            checkString('category', category);
            checkString('eventName', eventName);
            return hub.off([category, eventName], callback);
        },

        unsubscribeAll(category, eventName) {
            checkString('category', category);
            if (eventName) checkString('eventName', eventName);
            if (!eventName) eventName = '*';
            hub.removeAllListeners([category, eventName]);
        }
    };
};
