import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './content-tile-hashed.scss';
import template from 'text!../templates/generic-tile.html';

export default [() => ({
    restrict: 'E',
    replace: true,
    scope: {
        flavor: '@'
    },
    transclude: {
        'header': '?gwTileHeader',
        'actions': '?gwTileAction',
        'content': 'gwTileContent'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'tiles.tilesUtilFunctions', ($scope, tilesUtilFunctions) => {
        $scope.tileType = 'content';
        $scope.customFlavor = $scope.flavor || 'default';
        $scope.allow = {
            header: true,
            content: true,
            actions: true,
            footer: false
        };

        // Callback is a nop in the content tile
        $scope.clickCallback = () => (true);
        $scope.getActiveState = () => '';
        $scope.getStyle = tilesUtilFunctions.getStyleFunction(styles);
    }]
})];
