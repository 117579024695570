import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./quote.html';
import controller from './quoteCtrl';
import styles from './quote-hashed.scss';

/**
 * @ngdoc component
 * @name gw-quote
 *
 * @description  A generic component to display a quote page into a wizard flow
 *
 */
export default {
    controller,
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        effectiveDate: '<',
        startDate: '<',
        endDate: '<',
        previousPremium: '<',
        changeInCost: '<',
        newPremium: '<',
        taxes: '<',
        newPremiumBeforeTaxes: '<',
        transactionType: '@',
        alwaysShowPaymentStep: '<'
    }
};