import FaqCtrl from '../controllers/FaqCtrl';
import cssUtil from 'gw-portals-util-js/CssUtil';

import FaqTemplate from 'text!./FaqDirective.html';
import styles from 'edge/faq/directives/FaqDirective-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(FaqTemplate, styles);

export default () => {
    return {
        restrict: 'E',
        template: hashedTemplate,
        controller: FaqCtrl
    };
};