import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RadioSelectImage.html';
import styles from './RadioSelectImage-hashed.scss';
import RadioSelectImageCtrl from './RadioSelectImageCtrl';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default {
    template: hashedTemplate,
    bindings: {
        model: '=',
        label: '@',
        imgSrc: '@',
        customOptionModel: '=?',
        explicitErrorMessage: '@?',
        tooltip: '=?'
    },
    controller: RadioSelectImageCtrl
};