/**
 * Utilities for an "instant" date/time.
 */
export default {
    /**
     * Creates a new instant from JS date.
     * @param {Date|undefined} date
     * @returns {{time: number}}
     */
    fromJSDate(date) {
        return date ? {
            'time': date.getTime()
        } : date;
    },

    /**
     * Creates a new JS date from an instant.
     * @param {Object} instant
     * @returns {*}
     */
    toJSDate(instant) {
        return instant ? new Date(instant.time) : instant;
    }
};