import templateStr from 'text!./pm-important-information.html';

export default ['brandingData', (brandingData) => {
    return {
        restrict: 'A',
        scope: {},
        template: templateStr,
        link: ($scope) => {
            $scope.brand = brandingData.toLowerCase();
            // Init Accordion tabs 'open' status
            $scope.tcAccordion = ((numberOfTabs) => {
                const result = [];
                for (let i = 0; i < numberOfTabs; i++) {
                    result[i] = {open: false};
                }
                return result;
            })(5);
        }
    };
}];