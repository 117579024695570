import cssUtil from 'gw-portals-util-js/CssUtil';

import templateStr from 'text!./FacebookSharingDirective.html';
import styles from './FacebookSharingDirective-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(templateStr, styles);
let fbClient = null;

const fn = (FacebookService, TranslateService) => ({
    restrict: 'AE',
    template: hashedTemplate,
    link: scope => {

        function translate(shareData) {
            Object.keys(shareData)
                .filter(key => shareData[key].startsWith('facebook.share-dialog'))
                .forEach(key => TranslateService.translate({
                    object: shareData,
                    propName: key,
                    displayKey: shareData[key],
                    scope
                }));

            return shareData;
        }

        const shareData = translate(FacebookService.getShareDialogData());

        if (!fbClient) {
            FacebookService.getClient().then(client => {
                fbClient = client; // preload to make popup a direct user action instead of a promise
            });
        }

        scope.share = () => {
            if (fbClient) {
                const share = Object.assign({
                    method: 'share'
                }, shareData);
                fbClient.ui(share);
            }
        };

        scope.shareData = shareData;
    }
});
fn.$inject = ['FacebookService', 'TranslateService'];

export default fn;
