import XCenters from 'gw-portals-metadata-js';

export default ['TranslateService', (TranslateService) => {
    function translate(key) {
        const obj = {};
        TranslateService.translate({
            object: obj,
            propName: 'value',
            displayKey: key
        });
        return () => {
            return obj.value;
        };
    }

    return XCenters.createSuiteMetadata(translate);
}];