import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./PropertiesPagination.html';
import styles from './PropertiesPagination-hashed.scss';
import controller from './PropertiesPaginationCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        pageSize: '<',
        reset: '<',
        translatedLabel: '<',
        allItems: '<',
        onPaginationNavigation: '&',
        childPagination: '<',
        sharedPageNum: '<'
    },
    controller
};
