/**
 * Capitalise everything
 * @returns {{restrict: string, require: string, link: link}}
 */
export default () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            // Parse and set model
            const parse = function (inputValue) {
                if (inputValue === undefined) return;
                const result = inputValue.toUpperCase();
                // Capitalise
                if (result !== inputValue) {
                    modelCtrl.$setViewValue(result);
                    modelCtrl.$render();
                }
                return result;
            };
            modelCtrl.$parsers.push(parse); // Set parser
            parse(scope[attrs.ngModel]); // capitalize initial value
        }
    };
};