// Depends on ./StackedMap.js, but that one should defined as angular module
export default ['$timeout', '$document', '$compile', '$rootScope', '$$stackedMap',
    function ($timeout, $document, $compile, $rootScope, $$stackedMap) {
        const OPENED_MODAL_CLASS = 'gw-modal-opened';
        const MODAL_MOVE_EVENT = 'touchmove.modal';

        // take a look at the modal.scss
        // every new entry of animation should include respective time delay here.
        const ANIMATION_DELAYS = new Map();
        ANIMATION_DELAYS.set('left-right', 200);

        let backdropjqLiteEl;
        let backdropDomEl;
        const backdropScope = $rootScope.$new(true);
        const openedWindows = $$stackedMap.createNew();
        const $modalStack = {};

        function backdropIndex() {
            let topBackdropIndex = -1;
            const opened = openedWindows.keys();
            for (let i = 0; i < opened.length; i++) {
                if (openedWindows.get(opened[i]).value.backdrop) {
                    topBackdropIndex = i;
                }
            }
            return topBackdropIndex;
        }

        $rootScope.$watch(backdropIndex, (newBackdropIndex) => {
            backdropScope.index = newBackdropIndex;
        });

        function removeModalWindow(modalInstance) {
            const body = $document.find('body').eq(0);
            const modalWindow = openedWindows.get(modalInstance).value;

            // clean up the stack
            openedWindows.remove(modalInstance);

            // remove window DOM element
            modalWindow.modalDomEl.remove();
            body.toggleClass(OPENED_MODAL_CLASS, openedWindows.length() > 0);
            body.unbind(MODAL_MOVE_EVENT);

            // remove backdrop if no longer needed
            if (backdropDomEl && backdropIndex() === -1) {
                backdropDomEl.remove();
                backdropDomEl = undefined;
            }

            // destroy scope
            modalWindow.modalScope.$destroy();
        }

        $document.bind('keydown', (evt) => {
            let modal;

            if (evt.which === 27) {
                modal = openedWindows.top();
                if (modal && modal.value.keyboard) {
                    $rootScope.$apply(() => {
                        $modalStack.dismiss(modal.key);
                    });
                }
            }
        });

        function playAnimation(modal) {
            // trigger CSS transitions
            modal.value.modalDomEl[0].classList.remove('in');
            modal.value.modalDomEl[0].classList.add('out');
        }

        function getAnimationDelay(animationType) {
            return ANIMATION_DELAYS.get(animationType);
        }

        $modalStack.open = function (modalInstance, modal) {
            openedWindows.add(modalInstance, {
                deferred: modal.deferred,
                modalScope: modal.scope,
                backdrop: modal.backdrop,
                keyboard: modal.keyboard,
                animationType: modal.animationType
            });

            const body = $document.find('body').eq(0);

            if (backdropIndex() >= 0 && !backdropDomEl) {
                backdropjqLiteEl = angular.element('<div modal-backdrop></div>');
                backdropDomEl = $compile(backdropjqLiteEl)(backdropScope);
                body.append(backdropDomEl);
            }

            const angularDomEl = angular.element('<div modal-window></div>');
            angularDomEl.attr('window-class', modal.windowClass);
            angularDomEl.attr('index', openedWindows.length() - 1);
            angularDomEl.attr('full-screen', !!modal.fullScreen);
            angularDomEl.attr('wide', Boolean(modal.wide));
            angularDomEl.attr('animation-type', modal.animationType);
            angularDomEl.html(modal.content);
            // Append "transcluded" content to the modal
            const ele = angularDomEl.find('#transcludedContent');
            ele.append(modal.transcludedContent);

            const modalDomEl = $compile(angularDomEl)(modal.scope);
            openedWindows.top().value.modalDomEl = modalDomEl;
            body.append(modalDomEl);
            body.addClass(OPENED_MODAL_CLASS);

            if (modal.fixedScroll) {
                body.bind(MODAL_MOVE_EVENT, (e) => e.preventDefault());
            }
        };

        $modalStack.close = function (modalInstance, result) {
            const modal = openedWindows.get(modalInstance);
            if (modal) {
                modal.value.deferred.resolve(result);
                if (modal.value.animationType) {
                    // trigger CSS transitions
                    playAnimation(modal);
                    $timeout(() => {
                        removeModalWindow(modalInstance);
                    }, getAnimationDelay(modal.value.animationType));
                } else {
                    removeModalWindow(modalInstance);
                }
            }
        };

        $modalStack.dismiss = function (modalInstance, reason) {
            const modalWindow = openedWindows.get(modalInstance).value;
            if (modalWindow) {
                modalWindow.deferred.reject(reason);
                removeModalWindow(modalInstance);
            }
        };

        $modalStack.getTop = function () {
            return openedWindows.top();
        };

        return $modalStack;
    }
];