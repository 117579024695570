
export default {
    /**
     * A factory function that returns the service that is used to create presentation models from simple data objects and back again
     *
     * @param {Object} presentationMetadata : Object returned from PresentationMetadata.createPresentationMetadata
     * @returns {Object} : exported presentation model functions
     **/
    get: (presentationMetadata) => {

        /**
         * This service is responsible for creating a presentation model object from a plain data object and presentation metadata.
         */
        return {

            /**
             * Create a new presentation model object based on an object containing actual data and a metadata path
             *
             * @param {String} capability : code identifying the capability containing the presentation metadata. Error thrown if there is no match for capability code.
             * @param {String} flavor : code identifying the flavor containing the presentation metadata in the capability. Error thrown if there is no match for the flavor code.
             * @param {String} page : the name of the context or page in the flavor the data is on. Error thrown if there is no metadata found for the page.
             *
             * @returns {Promise}: A new presentation model object
             **/
            create: (capability, flavor, page) => {
                return presentationMetadata.get(capability).then(capabilityMetadata =>{
                    return capabilityMetadata.get(flavor).get(page);
                });
            }
        };
    }
};
