import template from 'text!./coverage-term.html';

export default [() => ({
    restrict: 'E',
    template: template,
    scope: {
        term: '=',
        updating: '=',
        isMultipleOffering: '&'
    },
    controller: ['$scope', ($scope) => {

        const term = $scope.term;

        $scope.typeIsSelect = term.hasOptions && term.valueType !== 'bit';
        $scope.typeIsBinary = term.hasOptions && term.valueType === 'bit';
        $scope.typeIsDateTime = !term.hasOptions && term.valueType === 'datetime';
        $scope.typeIsTextArea = !term.hasOptions && term.valueType === 'shorttext';
        $scope.typeIsOrdinaryInput = !term.hasOptions && term.valueType !== 'datetime'
            && term.valueType !== 'shorttext'
            && term.valueType !== 'bit';
    }]
})];