import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./cp-premises-summary-directive.html';
import styles from './PremisesSummary-hashed.scss';

export default [() => ({
    restrict: 'E',
    replace: true,
    scope: {
        type: '@',
        heading: '@',
        subHeading: '=',
        isOpen: '='
    },
    transclude: true,
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', ($scope) => {

        $scope.accordianProps = {isOpen: true};
        $scope.$watch('type', (value) => {
            switch (value) {
                case 'location':
                    $scope.icon = 'gw-icon fa fa-map-marker';
                    break;
                case 'building':
                    $scope.icon = 'gw-icon fa fa-building-o';
                    break;
                case 'coverages':
                    $scope.icon = 'gw-icon fa fa-umbrella';
                    break;
                default:
                    $scope.icon = 'gw-icon fa fa-check';
                    break;
            }
        });

        $scope.$watch('isOpen', (value) => {
            $scope.accordianProps.isOpen = value;
        });
    }]
})];
