import template from 'text!./templates/local-date-chooser.html';

function localDate2Date(localDate) {
    return localDate ? new Date(localDate.year, localDate.month, localDate.day) : undefined;
}

function date2localDate(date) {
    return date ? {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
    } : undefined;
}

export default [() => ({
    'restrict': 'AE',
    'template': template,
    'scope': {
        'model': '=',
        'maxDate': '=',
        'minDate': '=',
        'initialDate': '=',
        'startView': '@',
        'tooltip': '=?',
        'disabled': '=?'
    },
    'controller': ['$scope', ($scope) => {
        $scope.value = localDate2Date($scope.model);
        $scope.initialDateV = localDate2Date($scope.initialDate || $scope.model);
        $scope.maxDateV = localDate2Date($scope.maxDate);
        $scope.minDateV = localDate2Date($scope.minDate);
        $scope.startView = $scope.startView || 'year';

        $scope.$watch('model', (nv, ov) => {
            if (nv !== ov) {
                $scope.value = localDate2Date(nv);
                $scope.initialDateV = localDate2Date($scope.initialDate || nv);
            }
        }, true);
        $scope.$watch('value', (nv, ov) => {
            if (nv !== ov) {
                $scope.model = date2localDate(nv);
            }
        });
        $scope.$watch('initialDate', (nv, ov) => {
            if (nv !== ov) {
                $scope.initialDateV = localDate2Date(nv || $scope.model);
            }
        });
        $scope.$watch('initialDateV', console.log, true);
    }]
})];