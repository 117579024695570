import layout from '../LayoutFactory';
import cssUtil from 'gw-portals-util-js/CssUtil';
import menu from 'text!./menu.html';
import menuItem from 'text!./menu-item.html';
import styles from './menu-hashed.scss';

export default layout('Menu', cssUtil.hashTemplate(menu, styles), (partial) => {
    partial('Item', cssUtil.hashTemplate(menuItem, styles), ['$attrs', function ($attrs) {
        this.icon = () => $attrs.icon;
    }]);
});
