export default [
    // Any module may be specified any number of times, order here determines order shown
    {
        module: 'Contact',
        configuration: { // Any of keys may be omitted
            // 'displayName': 'Sam Smith',
            'userType': '',
            // 'primaryAddress': {
            //     'city': 'San Francisco'
            // },
            // 'phoneNumber': '213-555-8164',
            // 'email': 'SSmith@FoundationInsurance.com',

            // Image url
            // Expected to be 64x64px, larger images would be scaled then cropped.
            // Placeholder icon is used if image is not provided
            'image': '../styles/images/common/users/test.png'
        }
    }, {
        module: 'Text',
        configuration: {
            /*
             * Custom Text module.
             * Enter section object into sections array with id, title, content and optional link object.
             * If Link object entered it must contain a url and name.
             * */
            'sections': [
                {
                    'id': 'media_sidebar.text.sections.media_sidebar_example',
                    'title': 'media_sidebar.text.sections.media_sidebar_example.title',
                    'content': 'media_sidebar.text.sections.media_sidebar_example.content',
                    'link': {
                        'url': '#/faq/',
                        'name': 'media_sidebar.text.sections.media_sidebar_example.link_name'
                    }
                }
            ]
        }
    }, {
        module: 'Rss',
        configuration: {
            /*
             It may be impossible to request some feeds directly from client due to CORS configuration.
             If the feed is under your control, specify your domain under Access-Control-Allow-Origin response header.

             Otherwise, configure http server to proxy requests to the feed.
             Make sure to explicitly specify urls you're proxying to avoid creating an open proxy.
             Here's an example for Apache:

             ProxyPassMatch '^/rss/guidewire/.+$' !
             ProxyPass /rss/guidewire/ https://ir.guidewire.com/corporate.rss?c=248177&Rule=Cat=news~subcat=ALL connectiontimeout=5 timeout=30
             */

            'feed': '/rss/guidewire/', // Supports both Rss 2.0 and Atom feeds
            'limit': 5 // Max entries to show
        }
    }, {
        module: 'Twitter',
        configuration: {
            'dataSource': {
                sourceType: 'profile',
                screenName: 'Guidewire_PandC'
            }, // widgetID deprecated https://twittercommunity.com/t/deprecating-widget-settings/102295 
            'tweetLimit': 3 // May be ommited or set to undefined for scrollable timeline
        }
    }, {
        module: 'Youtube',
        configuration: {
            'type': 'user', // one of: user, video or playlist
            'id': 'GuidewireVideo', // Username, video id or playlist id depending on type above
            'autoplay': false,
            'controls': false
        }
    }, {
        module: 'Facebook',
        configuration: {
            'facebookHandle': 'GuidewireSoftware',
            'showSmallHeader': false,
            'hideCoverPhoto': false,
            'showFacepile': true,
            'showPosts': true
        }
    }
];