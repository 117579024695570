import segmentIOAdapter from 'gw-portals-tracking-js/adapters/SegmentIOAdapter';

const trackingConfig = {};
export default {
    configure(config) {
        Object.assign(trackingConfig, config);
    },
    $get: ['$window', 'CampaignDataService', (window, CampaignDataService) => {
        /**
         * Most of the following code comes from a snippet provided
         * from Segment--detailed here:
         * https://segment.com/docs/libraries/analytics.js/quickstart/,
         * replacing script loading with a jQuery ajax call
         */
        /* eslint-disable */
        !function () {
            var analytics = window.analytics = window.analytics || [];
            if (!analytics.initialize)
                if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
                else {
                    analytics.invoked = !0;
                    analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "page", "once", "off", "on"];
                    analytics.factory = function (t) {
                        return function () {
                            var e = Array.prototype.slice.call(arguments);
                            e.unshift(t);
                            analytics.push(e);
                            return analytics
                        }
                    };
                    for (var t = 0; t < analytics.methods.length; t++) {
                        var e = analytics.methods[t];
                        analytics[e] = analytics.factory(e)
                    }
                    analytics.load = function (t) {
                        var src = ("https:" === window.location.protocol ? "https://" : "http://") + "cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
                        $.ajax({
                            url: src,
                            dataType: 'script',
                            cache: true
                        });
                    };
                    analytics.SNIPPET_VERSION = "3.1.0";
                    analytics.load(trackingConfig.writeKey);
                }
        }();
        /* eslint-enable */
        /** End Segment snippet. */

        return segmentIOAdapter(window, CampaignDataService);
    }]
};