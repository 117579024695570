/**
 * Webpack build for Portals starts from this file
 * It loads some 3rd party dependencies and makes them available globally
 * (to prevent need in requiring them in modules)
 * Then loads a proper main.js file in a Portal
 */

// jQuery has to be loaded before AngularJS
// to make AngularJS use it instead of jqLite
const jquery = require('jquery');

window.$ = jquery;
window.jQuery = jquery;

// needed for datetimepicker
const moment = require('moment');

window.moment = moment;


/* --------- */


// Loaded using the full path because "angular" alias
// is set to return just a global "angular" variable
// but here we have to load an AngularJS file
require('angular-lib');
// AngularJS adds itself to a global space automatically
// It has to be loaded before any modules which may use it


/* --------- */


// START THE APP
/**
 * Checks if the the user login is required before loading of the Portal app
 * If "yes"- the authentication sub-app is loaded,
 * and when user is logged in- the sub-app is destroyed and the usual Portal app is loaded
 */
import appFeatures from 'effective-features!';// import is needed for the Webpack loader

// .default to import the ES Modules
const app = require('portal/app').default;// default Portal app

const loadPortal = () => angular.bootstrap(document, [app.name]);// usual Portal app loading trigger

if (appFeatures.auth && appFeatures.auth.initialLoginRequired) { // check if the initial auth is required
    const authInitialApp = require('auth-initial').default;// auth initial app
    authInitialApp(loadPortal);
} else {
    loadPortal();
}