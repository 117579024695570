import _ from 'lodash';

import _VMFactory from './_VMFactory';
import _Step from './build/_Step';

export default {
    /**
     * A factory function that returns a service that is used to create view models from simple data object and back again
     *
     * @param {Object} insuranceSuiteMetaData : Object returned from InsuranceSuiteMetadata.createSuiteMetadata
     *
     * @returns {Object} : exported view model functions
     **/
    get: (insuranceSuiteMetaData) => {
        const aspectFactories = [];
        const config = {};


        /**
         * View model objects hold data and aspect properties for binding to UI components
         * This service is responsible for creating a view model object from a plain data object and converting back to plain
         * data for sending back to the server.
         *
         */
        return {
            /**
             * Add a factory function to the list of functions that are called to add aspect properties to the view model
             * An aspect is the term used for a set of synthetic properties that are added to a view model. OOTB example would be
             * a validation aspect that adds a 'validity' property to view model nodes
             *
             * @param {Function} aspectFactory : Function to create aspect properties on a view model
             *
             **/
            addAspectFactory: (aspectFactory) => {
                aspectFactories.push(aspectFactory);
            },

            configure: newConf => _.extend(config, newConf),

            /**
             * Create a new view model object based on an object containing data and a metadata type
             *
             * @param {Object} modelRoot : initial data object for view model. Error thrown if data is not an object. When creating a new blank object,
             * properties initialized to empty objects will be full fledged VMNodes with VMNode sub-properties (if the properties correspond to DTOs themselves).
             * @param {String} xCenter : code identifying the xCenter containing the type metadata. Error thrown if there is no match for xCenter code.
             * @param {String} type : the name of the metadata type. Error thrown if there is no metadata found for the type
             * @param {Object} initialContext : Optional parameter used to provide initial context values. Any values defined
             * as metadata will take precedence over the values defined in this initial object.
             *
             * @returns {Object}: A new view model object
             **/
            create: (modelRoot, xCenter, type, initialContext) => {
                const metadataNode = insuranceSuiteMetaData.get(xCenter).types.get(type);

                if (metadataNode.metaType.isTypelist) {
                    throw new Error(`View model objects can only be created from DTO types not Typelists ${type}`);
                }

                const rootMetadataInfo = _Step.root(xCenter, metadataNode, initialContext);
                return _VMFactory(aspectFactories, config).create(modelRoot, rootMetadataInfo);
            }
        };
    }
};
