class SpreadsheetProcessorCtrl {

    constructor($scope, $state, $injector, $http, ModalService, $document, $timeout, StateUtils, httpSettings, spreadsheetProcessorConfig) {
        this.$document = $document;
        this.$timeout = $timeout;
        this.ModalService = ModalService;
        this.$injector = $injector;
        this.$state = $state;
        this.$http = $http;
        this.submissionVM = StateUtils.activeDataForState(true).model;
        this.jobID = this.submissionVM.quoteID ? this.submissionVM.quoteID.value : this.submissionVM.jobID.value;

        const xCenterDownloadURI = `${httpSettings.serviceBaseURLs.pc}`;
        this.templateDownloadEndpointURL = xCenterDownloadURI + spreadsheetProcessorConfig.downloadTemplateURL.concat('/').concat(this.jobID);
        this.templateUploadEndpointURL = spreadsheetProcessorConfig.uploadTemplateURL;
        this.existingLocations = this.submissionVM.lobData.commercialProperty.coverables.locations;

        this.showManualEntryLink = this.showManualEntryLink ? this.showManualEntryLink : false;

        this.showDownloadLoader = true;
        this.documentMetaDataTemplate = {
            docUID: '001',
            documentType: 'other',
            securityType: 'unrestricted',
            status: 'approved',
            jobNumber: this.jobID
        };

        this.getFileUploadStatus = (data) => {

            const isUndefinedOrZero = (value) => (value === undefined || value === 0);

            const isFailedUnchanged = (value) => {
                return value.rowsRead > 0 && (
                    isUndefinedOrZero(value.rowsWithErrors) &&
                isUndefinedOrZero(value.rowsNew) &&
                isUndefinedOrZero(value.rowsEdited) &&
                isUndefinedOrZero(value.rowsRemoved));
            };

            if (data.fatalErrors) {
                return 'fail-processing';
            } else if (isUndefinedOrZero(data.rowsRead)) {
                return 'fail-empty';
            } else if (isFailedUnchanged(data)) {
                return 'fail-unchanged';
            } else if (data.rowsWithErrors === data.rowsRead) {
                return 'fail-full';
            } else if (data.rowsWithErrors > 0) {
                return 'partial';
            }
            return 'success';
        };

        this.registerFileUploadEventListeners($scope);
    }


    registerFileUploadEventListeners($scope) {
        $scope.$on('fileuploadadd', () => {
            this.showDownloadLoader = false;
        });

        $scope.$on('fileuploaddone', (e, data) => {
            this.showDownloadLoader = true;
            this.processData(data.response().result.result);

            const status = this.getFileUploadStatus(data.response().result.result);
            const goToInitialScreen = () => {
                this.$state.go(
                    'cpBuildingWizardInitialScreen',
                    {
                        spreadsheetProcessorResult: {
                            'status': status,
                            'data': data.response().result.result
                        }
                    }
                );
            };
            const apply = true;
            const timeout = null;

            this.$timeout(goToInitialScreen, timeout, apply);
        });

        $scope.$on('fileuploadfail', () => {
            this.showDownloadLoader = true;
            this.$state.go(
                'cpBuildingWizardInitialScreen',
                {
                    spreadsheetProcessorResult: {
                        'status': 'fail-processing',
                        'data': null
                    }
                }
            );
        });
    }

    processData(data) {

        if (data.lobData.newlyAddedBuildings) {
            if (this.submissionVM.newlyAddedBuildingList) {
                this.submissionVM.newlyAddedBuildingList = this.submissionVM.newlyAddedBuildingList.concat(data.lobData.newlyAddedBuildings);
            } else {
                this.submissionVM.newlyAddedBuildingList = data.lobData.newlyAddedBuildings;
            }
        }

        this.existingLocations.value = data.lobData.locations;
    }

    downloadAgain() {
        this.templateDownloaded = false;
    }

    downloadTemplate() {
        this.downloadTemplatePromise = this.$http.get(this.templateDownloadEndpointURL, {responseType: 'arraybuffer'});
        this.downloadTemplatePromise
            .then((response)=>{
                try {
                    const filename = response.headers('Content-Disposition').replace('attachment; filename=', '');
                    const blob = new Blob([response.data], {
                        type: response.headers('Content-Type')
                    });

                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        const downloadContainer = angular.element('<div data-tap-disabled="true"><a></a></div>');
                        const downloadLink = angular.element(downloadContainer.children()[0]);
                        downloadLink.attr('href', window.URL.createObjectURL(blob));
                        downloadLink.attr('download', filename);
                        downloadLink.attr('target', '_blank');
                        this.$document.find('body').append(downloadContainer);
                        this.$timeout(() => {
                            downloadLink[0].click();
                            downloadLink.remove();
                        }, null);
                    }
                    this.templateDownloaded = true;
                } catch (e) {
                    this.ModalService.showError('gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Error', 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Something went wrong. Template was not downloaded. Please try again.');
                    this.templateDownloaded = false;
                }
            }, () => {
                this.ModalService.showError('gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Error', 'gw-portals-policytransaction-common-angular.components.spreadsheetProcessor.Something went wrong. Template was not downloaded. Please try again.');
                this.templateDownloaded = false;
            });
    }

    enterInfoManually() {
        this.$state.params.manualAddBuildingDelegator();
    }
}

SpreadsheetProcessorCtrl.$inject = ['$scope', '$state', '$injector', '$http', 'ModalService', '$document', '$timeout', 'StateUtils', 'httpSettings', 'qnb.spreadsheetProcessorConfig'];

export default SpreadsheetProcessorCtrl;