import _ from 'underscore';
/**
 *  Duck typing to check that an object is a step or junction based on a type attribute
 *
 * @param {String} [type]  'STEP' or 'JUNCTION', if undefined then matches for step or junction
 * @param {Object} objectToTest
 * @returns {*|boolean}
 */
function checkStepOrJunction(type, objectToTest) {
    return objectToTest && objectToTest.name && objectToTest.onNext && (objectToTest._type === type || type === null);
}

/**
 * Returns the next step or junction in the flow based on the model and extraArgs
 *
 * @param {Boolean} syncTransitionsOnly  If true then return undefined if a doAction transition is defined
 * @param {*} flow
 * @param {Object} currentStep
 * @param {Object} model
 * @param {Array} [extraArgs]
 * @returns {*} Step or Junction Object
 */
function getNextStepOrJunction(syncTransitionsOnly, flow, currentStep, model, extraArgs = {}) {
    const possibleTransitions = [currentStep.onNext.goTo(), currentStep.onNext.branch()];
    if (!syncTransitionsOnly) {
        possibleTransitions.push(currentStep.onNext.doAction() ? currentStep.onNext.doAction().callbacks.success : undefined);
    }
    const transition = _.find(possibleTransitions, _transition => _transition !== undefined);

    if (!transition) {
        return undefined;
    }
    const stepOrJct = transition(model, extraArgs);
    if (!stepOrJct) {
        // branch function returned falsey
        return undefined;
    }
    if (checkStepOrJunction(null, stepOrJct)) {
        // if a step or junction object no need to lookup in flow
        return stepOrJct;
    }
    const nextStep = flow._steps[stepOrJct] || flow._junctions[stepOrJct];
    if (!nextStep) {
        throw new Error(`Could not find step or junction object: ${stepOrJct}`);
    }
    return nextStep;
}

/**
 * Returns the next step in the flow based on the model and extraArgs. Will follow the next transition on successive
 * junctions until a step is reached or a transition returns undefined;
 *
 * @param {Boolean} syncTransitionsOnly  If true then return undefined if a doAction transition is defined
 * @param {*} flow
 * @param {Object} currentStep
 * @param {Object} model
 * @param {Array} [extraArgs]
 * @returns {*} Step object
 */
function getNextStep(syncTransitionsOnly, flow, currentStep, model, extraArgs = []) {
    const getNextStepFollowingJunctions = currentStepOrJunction => {
        const nextStepOrJunction = getNextStepOrJunction(syncTransitionsOnly, flow, currentStepOrJunction, model, extraArgs);
        if (!nextStepOrJunction) {
            return undefined;
        }
        if (this.isStepObject(nextStepOrJunction)) {
            return nextStepOrJunction;
        }
        return getNextStepFollowingJunctions(nextStepOrJunction);
    };
    return getNextStepFollowingJunctions(currentStep);
}

export default {
    getNextSyncStepOrJunction: _.partial(getNextStepOrJunction, true),

    getNextStepOrJunction: _.partial(getNextStepOrJunction, false),

    getNextSyncStep: _.partial(getNextStep, true),

    getNextStep: _.partial(getNextStep, false),

    isStepObject: _.partial(checkStepOrJunction, 'STEP'),

    isJunctionObject: _.partial(checkStepOrJunction, 'JUNCTION'),

    isExitPoint(stepNameOrObject, flow) {
        stepNameOrObject = this.getStepOrJunctionObject(stepNameOrObject, flow);

        if (!stepNameOrObject || stepNameOrObject !== flow.exits[stepNameOrObject.name]) {
            return false;
        }
        return true;
    },

    getStepObject(stepNameOrObject, flow) {
        if (this.isStepObject(stepNameOrObject) && flow._steps[stepNameOrObject.name]) {
            return stepNameOrObject;
        } else if (typeof stepNameOrObject === 'string' && flow._steps[stepNameOrObject]) {
            return flow._steps[stepNameOrObject];
        }
        throw new Error(`Unable to find step object ${stepNameOrObject} in flow`);
    },

    getJunctionObject(junctionNameOrObject, flow) {
        if (this.isJunctionObject(junctionNameOrObject) && flow._junctions[junctionNameOrObject.name]) {
            return junctionNameOrObject;
        } else if (typeof junctionNameOrObject === 'string' && flow._junctions[junctionNameOrObject]) {
            return flow._junctions[junctionNameOrObject];
        }
        throw new Error(`Unable to find step object ${junctionNameOrObject} in flow`);
    },

    getStepOrJunctionObject(stepOrJunction, flow) {
        if (typeof stepOrJunction === 'string' && flow._junctions[stepOrJunction]) {
            return flow._junctions[stepOrJunction];
        } else if (typeof stepOrJunction === 'string' && flow._steps[stepOrJunction]) {
            return flow._steps[stepOrJunction];
        } else if (checkStepOrJunction(null, stepOrJunction)) {
            return stepOrJunction;
        }
        throw new Error(`Unable to find step or junction object ${stepOrJunction} in flow`);
    }
};
