import CtrlGroupDirective from './CtrlGroupDirective';
import InlineCtrlGroupDirective from './InlineCtrlGroupDirective';
import InputCtrlDirective from './InputCtrlDirective';
import InlineInputCtrlDirective from './InlineInputCtrlDirective';
import NgFormDecorator from './NgFormDecorator';
import 'edge/platform/widgets/datetimepicker/module';
import 'edge/platform/widgets/select/module';

export default angular.module('edge.platform.widgets.basicinputs', [
    'edge.platform.widgets.ui.bootstrap.datetimepicker',
    'edge.platform.widgets.select'
])
    .config(['$provide', ($provide) => {
        $provide.decorator('ngFormDirective', NgFormDecorator);
    }])
    .config(['$provide', ($provide) => {
        $provide.decorator('formDirective', NgFormDecorator);
    }])
    .directive('gwPlCtrlGroup', CtrlGroupDirective)
    .directive('gwPlInlineCtrlGroup', InlineCtrlGroupDirective)
    .directive('gwPlInputCtrl', InputCtrlDirective)
    .directive('gwPlInlineInputCtrl', InlineInputCtrlDirective);