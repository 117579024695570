import template from 'text!./pm-quote-increased-personal-accident-cov.html';
import controller from '../PmQuoteAddOnCtrl';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '=',
            coverageCode: '<'
        },
        template,
        controller
    };
}];