import 'clamp';

export default ['$timeout', ($timeout) => {
    return {
        'restrict': 'A',
        'scope': {
            'clampLines': '@',
            'clampValue': '@'
        },
        'link': (scope, elt) => {
            function update() {
                $timeout(() => {
                    const jqElement = angular.element(elt[0]).empty();
                    jqElement.append(document.createTextNode(scope.clampValue));
                    window.$clamp(elt[0], {
                        clamp: scope.clampLines
                    });
                });
            }

            update();
            scope.$watch('clampValue', update);
        }
    };
}];
