import _ from 'underscore';
import contactTemplate from 'text!./templates/contact-template.html';

export default ['$modal', 'ContactUtils', '$filter', ($modal, ContactUtilsSvc, $filter) => {
    const modalController = ['$scope', '$modalInstance', 'contact', ($scope, $modalInstance, contact) => {
        $scope.$filter = $filter;
        $scope.contact = contact;
        $scope.displayName = ContactUtilsSvc.getDisplayName($scope.contact, $filter);
        $scope.fullAddress = ContactUtilsSvc.getFullAddress($scope.contact.primaryAddress);
        $scope.isA = (contactType) => {
            return ContactUtilsSvc.isInstanceOf($scope.contact, contactType);
        };
    }];


    const controller = ['$scope',
        function controller($scope) {
            $scope.open = () => {
                $modal.open({
                    template: contactTemplate,
                    controller: modalController,
                    resolve: {
                        contact: () => {
                            return $scope.contact;
                        }
                    }
                });
            };

            _.extend($scope, ContactUtilsSvc);
        }
    ];

    return {
        restrict: 'A',
        replace: true,
        template: '<a data-ng-click="open()">{{getDisplayName(contact, $filter)}}</a>',
        link: (scope, element) => {
            if (!angular.isDefined(scope.contact)) {
                element.replaceWith('<span>Private</span>');
            }
        },
        controller,
        scope: {
            contact: '=gwPlContactLink'
        }
    };
}];
