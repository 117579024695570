import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./gw-dropdown-directive.html';
import styles from './gwDropdownDirective-hashed.scss';

export default [() => ({
    restrict: 'E',
    scope: {
        onOpen: '&',
        onClose: '&'
    },
    transclude: {
        'target': 'gwDropdownTarget',
        'content': 'gwDropdownContent'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', ($scope) => {
        // Passes down handlers to underlying dropdown directive
        $scope._dropdownOnOpen = $scope.onOpen;
        $scope._dropdownOnClose = $scope.onClose;
    }]
})];
