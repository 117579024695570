import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import 'conditional?if=quoteandbind_lob&equals=pa!edge/quoteandbind/quickquote/pa/app';

const lobModules = [];

if (!ApplicationFeatures.hasFeature('role.vendor')) {
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.pa')) {
        lobModules.push('edge.quoteandbind.quickquote.pa.app');
    }
}

export default lobModules;