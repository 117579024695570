import typeaheadMatchContentModuleName from './matchContentDirectives/Injector';
import typeaheadInternalsModule from './typeahead-internals';

import hook from './Hook';
import dropdown from './Dropdown';
import match from './Match';
import typeaheadPublic from './TypeaheadPublic';

import highlightFilter from './HighlightFilter';

export default angular.module('edge.platform.widgets.typeahead', [typeaheadMatchContentModuleName, typeaheadInternalsModule.name])

/*
 This is a typeahead wrap. It renders an input and a typeahead itself.

 Restricted strictly to element (E).

 Available attributes:
 result: '='         Variable containing a selection. Either matches an item returned from definition.getMatches or userinput (see editable).

 editable: '<'       A flag whether userinput is an allowed result.
 When editable result may contain user input string.
 When non-editable, result would be always an item from definition.getMatches, but userinput would be wiped on focusout.

 placeholder: '@'    Placeholder text. No placeholder is shown when empty.

 [debounce-ms] (as attribute)    Number os milliseconds to wait between calling getMatches

 definition: '<'     An object describing typeahead behaviour.

 definition.getMatches   A function receiving userinput. Should return an array of items to render or a promise for such array.

 [definition.toString]   A method converting an item from getMatches to string.
 It is important that toString returns different string for every item,
 because getMatches() is optimized for repeat calls based on the result of toString.

 [definition.directive]  A reference to one of matchContentDirectives defined on an injector (./matchContentDirectives/Injector.js).
 Defaults to ./matchContentDirectives/Default/Default

 */
    .directive('gwTypeahead', typeaheadPublic)

    /*
     This is a hook directive for use in other directives, that represent an input for a typeahead.
     Typeahead internals expect an input element as their $element, this hook is a way to establish this.

     Restricted strictly to attribute, no arguments of its own.
     */
    .directive('gwTypeaheadHook', hook)

    // Directive rendering the dropdown of matched items
    .directive('gwTypeaheadDropdown', dropdown)

    // A wrap for typeahead match. Actual contents are rendered using an injector with definition.directive.
    .directive('gwTypeaheadMatch', match)

    // A filter to highlight the match in a string. Assumes substring match.
    .filter('gwTypeaheadHighlight', highlightFilter);
