import _ from 'lodash';
import ComponentizerCtrl from '../common/ComponentizerCtrl';

class ElementComponentizerCtrl extends ComponentizerCtrl {

    $onInit() {
        this._replaceComponent();
    }

    /**
     * Transforms the HTML into an element with the right scope. Adds required form controllers for gw-ctrl-group.
     * @private
     */
    _replaceComponent() {
        const componentName = this.element.component;
        const compHtml = `<${componentName} ${this._componentProps()} />`;
        const [component] = this.$compile(compHtml)(this.$scope.$parent, null, {
            transcludeControllers: {
                form: {
                    instance: this.$scope.$ctrl.formCtrl
                }
            }
        });

        if (this.element.content) {
            const translatedContent = this._translateProperty(this.element.content);
            component.innerHTML = translatedContent;
        }
        this.$element.replaceWith(component);
    }

    /**
     * returns attrs used by component
     * @returns {string}
     * @private
     */
    _componentProps() {
        const properties = this.element.dataProps;
        const componentProps = _.map(properties, (value, key) => {
            return this.formatProperty(value, key);
        });

        componentProps.push(`class="${this.element.className}"`);
        componentProps.push(this.generateAutomationProperty());
        return componentProps.join(' ');
    }
}

export default ElementComponentizerCtrl;