/*
 * Google Maps Api JS
 * https://developers.google.com/maps/documentation/javascript/geocoding
 */
class GeoCodeService {

    constructor($http, $log, $q) {
        this.$http = $http;
        this.$log = $log;
        this.$q = $q;

        if (window.google && window.google.maps) {
            // throw new Error('google map api not found');
            this.mapsApi = window.google.maps;
            this.geocoder = new this.mapsApi.Geocoder();
        } else {
            $log.warn('google map api not found');
        }
    }

    /*
     * Geocode Address model to cords latitude, longitude
     */
    geocodeAddress(address) {
        const defer = this.$q.defer();

        if (!this.mapsApi) {
            throw new Error('google map api not found');
        }

        let search = address.addressLine1;
        if (address.addressLine2) {
            search = `${address.addressLine1} ${address.addressLine2}`;
        }
        search = `${search}, ${address.city}, ${address.state}`;

        this.geocoder.geocode({
            address: search,
            componentRestrictions: {
                country: address.country,
                postalCode: address.postalCode
            }
        }, (results, status) => {
            if (status === this.mapsApi.GeocoderStatus.OK && results.length > 0) {
                defer.resolve({
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng()
                });
            } else {
                console.warn(`Google Geocode API error : ${status}`);
                defer.reject();
            }
        });

        return defer.promise;
    }

    /*
     * Geocode GooglePlace object to cords latitude, longitude
     */
    geocodePlace(placeId) {
        const defer = this.$q.defer();

        if (!this.mapsApi) {
            throw new Error('google map api not found');
        }

        this.geocoder.geocode(
            {placeId: placeId},
            (results, status) => {
                if (status === this.mapsApi.GeocoderStatus.OK && results.length > 0) {
                    defer.resolve({
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    });
                } else {
                    console.warn(`Google Geocode API error : ${status}`);
                    defer.reject();
                }
            }
        );

        return defer.promise;
    }

    /*
     * Legacy Google Geocode Rest Api
     */
    getGeoCodedAddr(lat, lon, sensor = true) {
        const geoCodeURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&sensor=${sensor}`;
        return this.$http({
            method: 'GET',
            url: geoCodeURL,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                const address = {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    country: '',
                    countryCode: ''
                };
                if (response.data.status === 'OK') {
                    const addressComponents = response.data.results[0].address_components;
                    let streetNumber;
                    let streetName;
                    for (let i = 0; i < addressComponents.length; i++) {
                        const addr = addressComponents[i];

                        const types = addr.types.join();
                        switch (types) {
                            case 'country':
                                address.country = addr.short_name;
                                break;
                            case 'street_number':
                                streetNumber = addr.long_name;
                                break;
                            case 'route':
                                streetName = addr.long_name;
                                break;
                            case 'postal_code':
                                address.postalCode = addr.short_name;
                                break;
                            case 'country,political':
                                address.country = addr.long_name;
                                address.countryCode = addr.short_name;
                                break;
                            case 'administrative_area_level_1,political':
                                address.state = addr.short_name;
                                break;
                            case 'locality,political':
                                address.city = addr.long_name;
                                break;
                            default:
                        }
                    }

                    if (streetNumber && streetName) {
                        address.addressLine1 = `${streetNumber} ${streetName}`;
                    } else if (streetName) {
                        address.addressLine1 = streetName;
                    } else if (streetNumber) {
                        address.addressLine1 = streetName;
                    }
                }
                return address;
            }, (error, status, headers) => {
                return this.$q.reject([error, status, headers]);
            });
    }
}

GeoCodeService.$inject = ['$http', '$log', '$q'];

export default GeoCodeService;