import _ from 'lodash';
import $ from 'jquery';

const PLACEHOLDER_BOUNDARY = '%%';
const PLACEHOLDER_RE_BASE = `${PLACEHOLDER_BOUNDARY}.+?${PLACEHOLDER_BOUNDARY}`;

const PLACEHOLDER_RE = new RegExp(PLACEHOLDER_RE_BASE); // Used to find next placeholder to replase
const PLACEHOLDER_RE_GLOBAL = new RegExp(PLACEHOLDER_RE_BASE, 'g'); // Used to find all placeholders in a string
const PLACEHOLDER_STRIP_WRAP_RE = new RegExp(`(^${PLACEHOLDER_BOUNDARY}|${PLACEHOLDER_BOUNDARY}$)`, 'g');

const REPLACES_ATTRIBUTE_NAME = 'gw-displaykey-format-replaces';

const format = (element, _format, values) => {
    element.textContent = _format; // Replace the current node content with the format text

    const placeholders = _format.match(PLACEHOLDER_RE_GLOBAL);
    if (values.length === 0 || !_format || !placeholders || placeholders.length !== values.length) {
        throw new Error(`Can not successfully format: no format given,
                 no placeholders in format or number of contained elements
                 does not matches the number of placeholders`);
    }
    if (placeholders.length !== _.uniq(placeholders).length) {
        throw new Error('Same placeholder can not be used twice within one string');
    }

    let placeholderMatch;
    let textNode = element.childNodes[0]; // Contents changed, now first child is the only text node

    /* That assignment is there on purpose */
    /*eslint-disable */
    while (placeholderMatch = PLACEHOLDER_RE.exec(textNode.textContent)) {
        /* eslint-enable */
        const placeholderValue = placeholderMatch[0];
        const placeholderName = placeholderValue.replace(PLACEHOLDER_STRIP_WRAP_RE, '');


        // Split text at placeholder start, now there are two text nodes:
        // text before placeholder and placeholder with the rest of the text
        const placeholder = textNode.splitText(placeholderMatch.index);


        // Separate the placeholder, now placeholder node contains nothing but placeholder,
        // rest of the text is in two neighbouring nodes with textNode containing the rest of unprocessed text
        textNode = placeholder.splitText(placeholderValue.length);


        // Replace the node containing the placeholder with whatever was intended to be there
        const value = values.find(_value => _value.getAttribute(REPLACES_ATTRIBUTE_NAME) === placeholderName);
        if (!value) {
            throw new Error(`No replacement for placeholder ${placeholderName}`);
        }
        element.replaceChild(value, placeholder);
    }
};

/**
 * Move child elements to respective position inside format string.
 *
 * Each replacement element should be marked with 'gw-displaykey-format-replaces' attribute specifying where
 * in format string should it pe positioned.
 *
 * Any other elements not marked with 'gw-displaykey-format-replaces' or text nodes would be silently discarded.
 *
 * Placeholders may not be repeated within format string, e.g. "%%duplicate%% %%duplicate%%" would throw an exception.
 *
 * @example
 *  Given key "call.Shruthi": "contact %%name%%",
 *  this template:
 *   <gw-displaykey-format format="{{ 'call.Shruthi' | translate }}">
 *       <a gw-displaykey-format-replaces="name" ui-sref="accounts.detail.summary({accountNumber : activity.accountNumber})">Shruthi</a>
 *   </gw-displaykey-format>
 *
 *  Produces:
 *   contact <a ui-sref="accounts.detail.summary({accountNumber : activity.accountNumber})">Shruthi</a>
 *
 *
 * @example:
 *  Given key "my.key": "whaba %%1%% daba %%2%% doo",
 *  this template:
 *   <gw-displaykey-format format="{{ 'my.key' | translate }}">
 *       <a gw-displaykey-format-replaces="1" href="http://guidewire.com">{{myInterpolatedValue}}</a>
 *       <div gw-displaykey-format-replaces="2">I'm going to be a second inserted value</div>
 *   </gw-displaykey-format>
 *
 *  Produces:
 *   whaba
 *   <a href="http://guidewire.com">whatever the value is</a>
 *   daba
 *   <div>I'm going to be a second inserted value</div>
 *   doo
 *
 *
 *
 * @example:
 *  Given two keys:
 *   "gateway.directives.activity-view-summary.Action for Product for Account" : "%%action%% for %%product%% for %%account%%",
 *   "gateway.directives.activity-view-summary.Action for Account" : "%%action%% for %%account%%"
 *
 *  The following template:
 *   <gw-displaykey-format format="{{ (activity.productName ? 'gateway.directives.activity-view-summary.Action for Product for Account' : 'gateway.directives.activity-view-summary.Action for Account') | translate}}">
 *       <span gw-displaykey-format-replaces="action">{{activity.subject}}</span>
 *       <span gw-displaykey-format-replaces="product" ng-if="activity.productName">{{activity.productName}}</span>
 *       <a gw-displaykey-format-replaces="account" ui-sref="accounts.detail.summary({accountNumber : activity.accountNumber})">{{activity.accountHolderName}}</a>
 *   </gw-displaykey-format>
 *
 *  Produces either:
 *      <span gw-displaykey-format-replaces="action">{{activity.subject}}</span>
 *      for
 *      <span gw-displaykey-format-replaces="product">{{activity.productName}}</span>
 *      for
 *      <a gw-displaykey-format-replaces="account" ui-sref="accounts.detail.summary({accountNumber : activity.accountNumber})">{{activity.accountHolderName}}</a>
 *
 *  or:
 *      <span gw-displaykey-format-replaces="action">{{activity.subject}}</span>
 *      for
 *      <a gw-displaykey-format-replaces="account" ui-sref="accounts.detail.summary({accountNumber : activity.accountNumber})">{{activity.accountHolderName}}</a>
 *
 *  depending whether there is an activity.productName
 */

export default ['LocaleService', '$compile', (LocaleService, $compile) => {
    return {
        restrict: 'E',

        compile(tElement) {
            const contents = tElement.html(); // Cache replacements
            tElement.empty(); // Clean up whatever was there.

            return ($scope, $element, attrs) => { // Linking method. Need it to watch scope.
                let childScope;
                const onScopeChange = () => {
                    if (childScope) {
                        // If child scope were to be removed immediately after format, all the bindings would be lost.
                        childScope.$destroy();
                    }

                    // Create child scope to link with contents.
                    // We need a digest cycle to run on the contents after linking.
                    // Child scope helps avoid infinite loop — now digest runs on child scope and parent scope is left intact.
                    childScope = $scope.$new();

                    // Directives with transclude: 'element' or directives relying on adding/removing/replacing nodes
                    // need a parent node to work. Appending resulting elements to a wrapper provides such parent.
                    // Wrapper is then discarded.
                    let wrapper = $(document.createElement('div')).append($compile(contents)(childScope));

                    // When child scope finishes digesting (forced below), run format.
                    // At this point whatever transformations were taking place inside contents should have finished.
                    childScope.$$postDigest(() => {
                        const replacements = Array
                            .from(wrapper.get(0).children)
                            .filter(replacement => replacement.getAttribute(REPLACES_ATTRIBUTE_NAME)); // Only work with elements that have a 'replaces' attribute

                        format($element.get(0), attrs.format, replacements);

                        // Cleanup
                        wrapper.remove();
                        wrapper = null;
                    });

                    const digestPhase = _.get(childScope, '$root.$$phase');
                    if (!['$apply', '$digest'].includes(digestPhase)) {
                        // Force digest on the child scope otherwise contents are not interpolated
                        childScope.$digest();
                    }
                };

                // Call onScopeChange once per frame at 60fps (1000ms / 60)
                LocaleService.whenTranslateReady().then(() => $scope.$watch(_.debounce(onScopeChange, 16.6, {
                    'leading': true,
                    'trailing': true
                })));
            };
        }
    };
}];