import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './MultipleOfferingViewInternal-hashed.scss';
import templateStr from 'text!./MultipleOfferingViewInternal.html';
import Ctrl from './MultipleOfferingViewInternalCtrl';

export default {
    bindings: {
        coverageTable: '<',
        id: '@'
    },
    template: cssUtil.hashTemplate(templateStr, styles),
    controller: Ctrl
};