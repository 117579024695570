/**
 * Invokes the code to set the global class to represent the Portal versionName (if provided)
 * Can be used to customise the Portal UI
 */

import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

const versionName = ApplicationFeatures.getAllFeatures().versionName;

if (versionName) {
    document.documentElement.classList.add(`gw-global-versionName-${versionName}`);
}