import _ from 'lodash';

import './TooltipDirective.scss';

export default [function () {
    return {
        restrict: 'EA',
        // template: template,
        scope: {
            trigger: '@', // hover|click
            side: '@', // top|bottom|right|left
            interactive: '<'
        },
        link: (scope, element) => {
            const tooltipOptions = {
                // we detach the content from the page and give it to the tooltip
                content: element.find('>content').detach(),
                contentCloning: false,
                interactive: typeof scope.interactive === 'undefined' ? true : Boolean(scope.interactive), // True by default
                maxWidth: 480,
                distance: 0,
                animationDuration: 200,
                delay: scope.interactive === false ? 0 : [0, 600], // No delays for non-interactive tooltip
                delayTouch: scope.interactive === false ? 0 : [0, 600]

                // http://iamceege.github.io/tooltipster/
                // minIntersection
                // timer
                // zIndex
                // IEmin: 11
            };

            // TRIGGER OPTION
            tooltipOptions.trigger = scope.trigger || 'hover';
            if (tooltipOptions.trigger === 'hover') {
                if ('ontouchstart' in window) {
                    // hover on mobile devices should be tap
                    _.extend(tooltipOptions, {
                        trigger: 'custom',
                        triggerOpen: {
                            mouseenter: true,
                            touchstart: true
                        },
                        triggerClose: {
                            click: true,
                            scroll: true,
                            tap: true
                        }
                    });
                } else {
                    // on dekstop hover should only react to mouse movements (not clicks), like you'd expect from css hover.
                    _.extend(tooltipOptions, {
                        trigger: 'custom',
                        triggerOpen: {
                            mouseenter: true
                        },
                        triggerClose: {
                            mouseleave: true
                        }
                    });
                }
            }

            // SIDE OPTION
            if (scope.side) {
                tooltipOptions.side = scope.side;
            }

            // INIT
            const $tooltip = element.find('>opener').tooltipster(tooltipOptions);

            // ON DESTROY
            scope.$on('$destroy', () => {
                try {
                    $tooltip.tooltipster('destroy');
                } catch (e) {
                    return null;
                }
            });
        }
    };
}];