import BaseCtrl from 'edge/platform/base/BaseCtrl';

/* The step Covers Billing Address and Account Details */
const fn = ($scope, $state, LoadSaveService, submissionViewModel, StateUtils) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.submissionVm = submissionViewModel();
        $scope.totalSteps = ($scope.submissionVm.value.isAnnualPaymentPlan_AND() === true) ? 3 : 4;
    };

    ctrl.next = (callback) => {
        $scope.form.submitted = true;
        if ($scope.form.valid === false) {
            return callback();
        }

        if ($scope.submissionVm.value.isInstallmentsPaymentPlan_AND() === true) {
            // Cache card data
            const isCardRegisteredToYou = $scope.submissionVm.bindData.paymentDetails.creditCardData.isCardRegisteredToYou.value;
            const cardHolderFirstname = $scope.submissionVm.bindData.paymentDetails.creditCardData.cardHolderFirstname.value;
            const cardHolderSurname = $scope.submissionVm.bindData.paymentDetails.creditCardData.cardHolderSurname.value;
            const cardHolderPhoneNumber = $scope.submissionVm.bindData.paymentDetails.creditCardData.cardHolderPhoneNumber.value;
            const cardHolderEmailAddress = $scope.submissionVm.bindData.paymentDetails.creditCardData.cardHolderEmailAddress.value;
            LoadSaveService.updateQuotedSubmissionAndValidateDDI_AND($scope.submissionVm.value)
                .then((submission) => {
                    $scope.submissionVm.bindData.value = submission.bindData; // Update scope model
                    $scope.submissionVm.bindData.chosenQuote.value = StateUtils.activeDataForState(true).chosenQuote;
                    // Credit Card object must be reconstructed when returned empty because of the input ctrls
                    $scope.submissionVm.bindData.paymentDetails.creditCardData.value =
                        $scope.submissionVm.bindData.paymentDetails.creditCardData.value || {
                            isCardRegisteredToYou, cardHolderFirstname, cardHolderSurname, cardHolderPhoneNumber, cardHolderEmailAddress
                        };
                    if (submission.bindData.paymentDetails.bankAccountData.validationResponse_itb.isCorrect !== true) {
                        callback();
                    } else {
                        callback($scope.submissionVm);
                    }
                })
                .catch(() => {
                    // Flag that something went wrong with the integration
                    $scope.submissionVm.bindData.paymentDetails.bankAccountData.validationResponse_itb.value = {
                        integrationOffline: true
                    };
                    callback();
                });
        } else {
            callback($scope.submissionVm);
        }
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$state', 'qnb.LoadSaveService', 'submissionViewModel', 'StateUtils'];
export default fn;