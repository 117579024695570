import StepSpec from './_StepSpecification';
import NodeMetadataDefinition from 'gw-portals-metadata-js/NodeMetadataDefinition';

/* Derives a metadata from the type reference. */
function getTypeRefMetadata(typeRef) {
    if (typeRef.kind !== 'class') {
        return NodeMetadataDefinition.EMPTY;
    }

    return typeRef.typeInfo.metadata;
}


/* Creates a step descriptor. */
function create(step, inheritedMeta, xCenter, valueType, externalContext, readOnly) {
    const typeMeta = getTypeRefMetadata(valueType);
    return Object.freeze({
        step,
        typeMeta,
        inheritedMeta,
        'elementMetadata': NodeMetadataDefinition.merge(inheritedMeta, typeMeta),
        xCenter,
        valueType,
        externalContext,
        readOnly
    });
}


/** This module defines a "build step" API which could be used by different model builders and aspects. Each
 * step have following properties and functions:
 * <dl>
 *     <dt>step : StepSpec</dt><dd>Defines a type of last step used to descendd into this node.</dd>
 *     <dt>typeMeta : ElementMetadata</dt><dd>Metadata on the value's type.</dd>
 *     <dt>inheritedMeta : ElementMetadata</dt><dd>Metadata inherited from the owner'snnn definition of the step
 *        (annotation on property in DTO).</dd>
 *     <dt>elementMetadata : ElementMetadata</dt><dd>Effective metadata for the "receiving" node. It is a mix of both
 *        inherited and type's metadata.</dd>
 *     <dt>xCenter : String</dt><dd>Name of the xCenter for which object is created.</dd>
 *     <dt>valueType : TypeRef</dt><dd>Type of the node's value. This is a reference to the type (generic type
 *       name), not the type metadata.</dd>
 *     <dt>externalContext : Object</dt><dd>Additional data passed to the model. This property is set to undefined
 *       for most values and have some reasonable value for root nodes only.</dd>
 * </dl>
 */
export default {
    /**
     * Creates a step definition for the root node.
     * @param {String} xCenter xCenter name.
     * @param {Object} type DTO type instance.
     * @param {*} externalContext external data passed to the model.
     *
     * @returns {Object}
     */
    'root': (xCenter, type, externalContext) => {
        const typeSpec = {
            'kind': 'class',
            'name': type.typeName,
            'typeInfo': type
        };
        return create(StepSpec.ROOT, NodeMetadataDefinition.EMPTY, xCenter, typeSpec, externalContext);
    },


    /**
     * Creates a step definition based on the property.
     * @param {String} xCenter xCenter name.
     * @param {Object} prop property descriptor.
     *
     * @returns {Object}
     */
    'property': (xCenter, prop) => {
        return create(StepSpec.property(prop.name), prop.metadata, xCenter, prop.type, null, prop.readOnly);
    },


    /**
     * Creates a step definition based on the collection element's type reference.
     * @param {String} xCenter xCenter name.
     * @param {*} eltType type of the collection element.
     *
     * @returns {Object}
     */
    'collectionElement': (xCenter, eltType) => {
        return create(StepSpec.COLLECTION_ELT, NodeMetadataDefinition.EMPTY, xCenter, eltType);
    }
};