import templateStr from 'text!./templates/bank-account-details.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,

        scope: {
            bankAccountDetails: '=gwPlBankAccountDetails'
        },

        controller: ['$scope', (scope) => {
            scope.bankAccountDetails.value = scope.bankAccountDetails.value || {};
            scope.accTypes = scope.bankAccountDetails.bankAccountType.aspects.availableValues;
            scope.bankAccountDetails.bankAccountType.value = scope.bankAccountDetails.bankAccountType.value || scope.accTypes[0];
        }]
    };
}];
