import template from 'text!./templates/file-upload-viewer.html';

let uploadInProgress = false; // Only one file upload dialog at a time

export default [
    () => ({
        'scope': {
            'uploadConfig': '=',
            'documents': '=',
            'dropZoneSelector': '&',
            'hideDropArea': '&',
            'removeFunction': '&'
        },
        'template': template,
        'controller': ['$scope', '$timeout', '$q', '$filter', 'ModalService', ($scope, $timeout, $q, $filter, ModalService) => {
            const fileIcons = {
                doc: 'fa-file-word-o',
                docx: 'fa-file-word-o',
                docm: 'fa-file-word-o',
                pdf: 'fa-file-pdf-o',
                xlt: 'fa-file-excel-o',
                xlsx: 'fa-file-excel-o',
                xlsm: 'fa-file-excel-o',
                gif: 'fa-file-image-o',
                png: 'fa-file-image-o',
                jpeg: 'fa-file-image-o'
            };

            $scope.documentNotFoundRedirectUrl = `${window.location.origin}${$scope.uploadConfig.documentNotFoundUrl}`;
            $scope.dragDropSelector = $scope.dropZoneSelector() || '.gw-file-upload-drag-area';
            $scope.removeDocument = (document) => {
                if (!document.canDelete) {
                    return;
                }
                const title = 'platform.widgets.fileupload.templates.Remove Document';
                const msg = $filter('translate')('platform.widgets.fileupload.templates.Are you sure you want to remove:', {
                    documentName: document.name
                });

                ModalService.showWarning(title, msg)
                    .result.then(() => {
                        $q.when($scope.removeFunction({documentID: document.publicID}), (deleted) => {
                            if (deleted === true) {
                                // Filter out deleted doc
                                $scope.documents = $scope.documents.filter((doc) => doc.workingPublicID !== document.publicID);
                            } else {
                                ModalService.showError(
                                    'platform.widgets.fileupload.templates.Document Deletion May Have Failed',
                                    'platform.widgets.fileupload.templates.Something may have gone wrong when deleting your document.'
                                );
                            }
                        }, () => {
                            ModalService.showError(
                                'platform.widgets.fileupload.templates.Unable to Delete Document',
                                'platform.widgets.fileupload.templates.Sorry, your document could not be deleted at this time.'
                            );
                        });
                    });
            };

            $scope.getFileIcon = (file) => {
                const extension = _getFileExtension(file.docUID).toLowerCase() || '';
                return fileIcons[extension] || 'fa-file-o';
            };

            $scope.clickUpload = () => {
                if (!uploadInProgress && !$scope.hideDropArea()) {
                    const uploadElement = $(`.${$scope.uploadConfig.uploaderClass} input`);
                    if (uploadElement.length !== 0) {
                        uploadInProgress = true;

                        // Wrap in timeout to call after current digest
                        $timeout(() => {
                            // Clicks the upload area to trigger the upload dialog
                            uploadElement[0].click();
                            uploadInProgress = false;
                        });
                    }
                }
            };

            function _getFileExtension(name) {
                const extension = name.split('.').pop();
                return extension || '';
            }
        }]
    })
];