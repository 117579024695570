import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./SimpleList.html';
import styles from './SimpleList-hashed.scss';

export default () => ({
    restrict: 'E',
    scope: {
        scheduledItem: '<'
    },
    template: cssUtil.hashTemplate(template, styles)
});
