import templateStr from 'text!./mobile-quote-offerings.html';
import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './MobileQuoteOfferings-hashed.scss';
import LobModelExtenstion from 'integration/quoteandbind/directives/LobOfferingSelection/util/LobOfferingHelper';

export default {
    template: cssUtil.hashTemplate(templateStr, styles),
    bindings: {
        submission: '<',
        isPaymentPeriodMonthly: '=',
        offeringUtilFunctions: '<',
        lobCode: '&'
    },
    controller: ['$filter', function controller($filter) {
        const $ctrl = this;
        const lobCode = $ctrl.lobCode();
        const emptySchedules = [];
        const coverageHelper = buildCoverageHelper(lobCode);
        coverageHelper.updateModels();

        $ctrl.updateModels = () => coverageHelper.updateModels();
        $ctrl.coverageType = coverageHelper.getLobCoverageName();
        $ctrl.getOfferingCoverages = getOfferingCoverages;
        $ctrl.accordionStates = createAccordionStates();
        $ctrl.onOfferingSelection = onOfferingSelection;
        $ctrl.getSchedulesModel = getSchedulesModel;
        $ctrl.shouldDisplayPriceDifferenceFor = shouldDisplayPriceDifferenceFor;
        $ctrl.getPriceDiffFor = getPriceDiffFor;

        function onOfferingSelection(event, branchCode, updateModel) {
            event.stopPropagation();
            $ctrl.offeringUtilFunctions.activateOffering(branchCode, updateModel);
        }

        function buildCoverageHelper(lob) {
            const CoverageHelperConstructor = LobModelExtenstion.getCoverageHelperConstructor(lob);
            return new CoverageHelperConstructor($filter, $ctrl.offeringUtilFunctions);
        }

        /**
         * Creating an array of accordion states to store an isOpen state associated with an offering. We are doing this to implement offering summary show/hide logic.
         * We have considered attaching an isOpen property on the offerings array, however felt that modifying the existing model can lead to a potential clash and could cause confusion.
         * getOffering is a function because we need to query the latest version of the submission.offeredQuotes array
         * @returns {*|Array}
         */
        function createAccordionStates() {
            return coverageHelper.offeringHeaders.map(realOffering => {
                const accordionState = {};
                accordionState.isOpen = false;
                accordionState.getOffering = () => findOffering(realOffering.branchName);
                return accordionState;
            });
        }

        function findOffering(offeringName) {
            return $ctrl.offeringUtilFunctions.getOfferedQuotes().find(offering => offering.branchName === offeringName);
        }

        /**
         * Gets the coverages for a particular offering
         * @param {string} currentOffering
         * @returns {Object}
         */
        function getOfferingCoverages(currentOffering) {
            return coverageHelper.mobileCoverageData.find((offeringCov) => offeringCov.branchName === currentOffering.branchName);
        }

        function getSchedulesModel(currentOffering) {
            if (coverageHelper.getSchedulesModel) {
                return coverageHelper.getSchedulesModel().find((offeringCov) => offeringCov.model.branch === currentOffering.branchName);
            }
            return emptySchedules;
        }

        /**
         * Wrapper function to make it more readable
         * @param {string} offeringCode
         * @returns {boolean}
         */
        function shouldDisplayPriceDifferenceFor(offeringCode) {
            return $ctrl.offeringUtilFunctions.displayPriceDifference(offeringCode);
        }

        /**
         * Wrapper function to make it more readable
         * @param {string} offeringCode
         * @returns {string}
         */
        function getPriceDiffFor(offeringCode) {
            return $ctrl.offeringUtilFunctions.getFormattedPriceDifference(offeringCode);
        }
    }]
};
