import templateStr from 'text!./templates/document-upload.html';

export default [
    'UploadTokenService', 'TranslateService', '$document', 'httpSettings', 'ModalService', 'maxFileUploadSize', 'EventHub', 'oAuth', 'FileUploadUtil', 
    (UploadTokenService, TranslateService, $document, httpSettings, ModalService, maxFileUploadSize, EventHub, oAuth, FileUploadUtil) => {
        return {
            restrict: 'A',
            template: templateStr,
            transclude: true,
            scope: {
                metadataTemplate: '&metadata',
                documents: '=',
                toggleDisabled: '=',
                serviceName: '@',
                sessionServiceName: '@',
                disableAutoUpload: '@disableAutoUpload',
                xCenter: '@gwPlDocumentUpload',
                dropZoneSelector: '&',
                customClass: '=',
                widgetIcon: '@',
                widgetText: '@',
                allowedFiles: '@',
                showErrorModal: '@'
            },
            link: (scope) => {
                scope.sessionToken = null;
                scope.serviceName = scope.serviceName || 'upload';
                scope.sessionServiceName = scope.sessionServiceName || 'docs/docsession';
                scope.buttonIcon = scope.widgetIcon || 'fa-plus';
                scope.buttonText = scope.widgetText || 'platform.documentupload.templates.document-upload.Upload Documents';
                scope.allowedFilesTypes = scope.allowedFiles || '.xls,.xlsx,.pdf,.txt,.doc,.docx,.png,.jpg';
                scope.showErrorModal = scope.showErrorModal ? scope.showErrorModal : 'true';
                scope.tooltipText = scope.buttonText;
                const dropZoneElement = FileUploadUtil.createDropZoneElement(scope.dropZoneSelector(), $document);
                scope.options = FileUploadUtil.createOptions(
                    httpSettings.serviceBaseURLs[scope.xCenter] + scope.serviceName,
                    !scope.disableAutoUpload,
                    maxFileUploadSize,
                    dropZoneElement
                );

                FileUploadUtil.addAuthTokenHeader(scope.options, oAuth)
                    .then((options) => {
                        options.dropZone = dropZoneElement;
                        scope.options = options;
                    });

                UploadTokenService.generateUploadToken(scope.xCenter, scope.sessionServiceName)
                    .then((response) => {
                        scope.sessionToken = response;
                    }).catch(()=>{
                        scope.tooltipText = 'platform.documentupload.templates.document-upload.You seem to be offline. Once your connection is restored, click “Previous” and start the process again.';
                    });

                scope.metadata = [];
                let newDocuments = [];
                let errorUploading = false;

                scope._unbind = function (eventname) {
                    if (eventname in scope._unbind.handlers) {
                        scope._unbind.handlers[eventname]();
                    }
                };
                scope._unbind.handlers = {};

                const bind = (eventname, cb) => {
                    scope._unbind.handlers[eventname] = scope.$on(eventname, cb);
                };

                bind('fileuploadadd', (e, data) => {
                    data.files[0].metadata = FileUploadUtil.createDocMetadata(scope.metadataTemplate(), data, scope.sessionToken);
                });

                bind('fileuploadsubmit', (e, data) => {
                    data.formData = {
                        document: JSON.stringify(data.files[0].metadata)
                    };
                });

                bind('fileuploaddone', (e, data) => {
                    const uploadResult = data.result.result;

                    newDocuments.push(uploadResult);
                    e.targetScope.clear(data.files);

                    EventHub.publish('journey', 'document', {
                        action: 'upload',
                        fileName: uploadResult.name,
                        documentType: uploadResult.documentType,
                        mimeType: uploadResult.mimeType
                    });
                });

                /**
                 * Add the temporarily held documents in newDocuments to the documents array.
                 * We reset the newDocuments array back to zero after we have added it to the documents array.
                 * We do this because old references to previously added documents may remain, and
                 * an exception will be thrown if you try to add the same document twice.
                 */
                bind('fileuploadstop', () => {
                    const result = FileUploadUtil.handleFileUploadStop(scope.documents, newDocuments, errorUploading, false);
                    scope.documents = result.documents;
                    newDocuments = result.newDocuments;
                    errorUploading = result.errorUploading;
                });

                bind('fileuploadfail', () => {
                    errorUploading = true;
                    if (scope.showErrorModal === 'true') {
                        ModalService.showError(
                            'platform.documentupload.DocumentUploadDirective.Error',
                            'platform.documentupload.DocumentUploadDirective.Failed to upload file, either you do not have permission or a file exists with the same name.'
                        );
                    }
                });

                bind('fileuploadprocessfail', (e, data) => {
                    errorUploading = true;
                    if (scope.showErrorModal === 'true') {
                        ModalService.showError(
                            'platform.documentupload.DocumentUploadDirective.Processing file failed.',
                            {
                                fileName: data.originalFiles[0].name,
                                error: TranslateService.instant(`platform.documentupload.DocumentUploadDirective.Error.${data.originalFiles[0].error}`)
                            }
                        );
                    }
                });
            }
        };
    }];