import actionIconTemplate from 'text!./templates/ActionIcon.html';
import actionIconInnerTemplate from 'text!./templates/ActionIconInner.html';

export default angular.module('edge.platform.widgets.actionicon', [])

/**
 * Action icon directive.
 *
 * Icon specifies font-awesome icon name.
 * Tooltip specifies tooltip text.
 * OnClick specifies an onclick handler.
 *
 * Noborder, if true, hides the icon border for use in tables.
 * Disable grays out the whole icon.
 */
    .directive('gwActionIcon', [() => ({
        restrict: 'E',
        template: actionIconTemplate,
        replace: true,
        scope: {
            icon: '@',
            rawInput: '<',
            tooltip: '@',
            onClick: '&',
            forcedHover: '<',
            thickborder: '<',
            noHover: '<',
            noborder: '<',
            disable: '<',
            solid: '<',
            shadow: '<',
            small: '<'
        }
    })])

    /**
     * Private-ish. Should not be used on it's own.
     */
    .directive('gwActionIconInner', [() => ({
        restrict: 'E',
        template: actionIconInnerTemplate,
        replace: true,
        scope: {
            icon: '@',
            rawInput: '<',
            onClick: '&',
            forcedHover: '<',
            disable: '<',
            noborder: '<',
            thickborder: '<',
            noHover: '<',
            solid: '<',
            shadow: '<',
            small: '<'
        },
        link: $scope => {
            $scope.$watch('forcedHover', fh => {
                $scope.hasForcedHover = typeof fh !== 'undefined';
            });

            $scope.handleClick = () => {
                if (!$scope.disable) {
                    $scope.onClick();
                }
            };
        }
    })]);
