import templateStr from 'text!./templates/language-expandos.html';
/**
 * Widget for displaying language expandos
 *
 * @returns {Object}
 */
export default () => {
    return {
        restrict: 'A',
        replace: true,
        template: templateStr,
        controller: ['$scope', 'LocaleService', ($scope, LocaleService) => {
            $scope.currentLocaleDisplayName = LocaleService.getLocaleDisplayName();
            $scope.localesDisplayNames = LocaleService.getLocalesDisplayNames();
            $scope.isVisible = $scope.localesDisplayNames && $scope.localesDisplayNames.length > 1;
            $scope.isOpen = false;

            // METHODS
            $scope.changeLanguage = (locale) => {
                LocaleService.setLocaleByDisplayName(locale);
                $scope.currentLocaleDisplayName = locale;
            };
            $scope.toggleExpandos = ($event) => {
                $event.stopPropagation();// we don't want to close the drawer
                $scope.isOpen = !$scope.isOpen;
            };
        }]
    };
};
