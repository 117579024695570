export default {
    getInvalidFields
};

/**
 * Retrieves all the invalid fields from the model.
 * @param {*} model VM Node
 * @param {string} parentKey Absolute path to the current property
 * @returns {Array} List of invalid fields
 */
function getInvalidFields(model, parentKey = '') {
    let invalidFields = [];
    if (model && (model.aspects.valid === false || model.aspects.subtreeValid === false)) {
        if (model.length && model.length > 0) { // If this is an array, need to get its children
            model.children.forEach((child) => {
                invalidFields = invalidFields.concat(getInvalidFields(child, parentKey));
            });
        } else {
            Object.keys(model).forEach((key) => {
                if (key.startsWith('_')) return; // Discard private properties
                const value = model[key];
                if (value && value.aspects) {
                    const fullPath = `${parentKey.length === 0 ? '' : `${parentKey}.`}${key}`;
                    if (value.aspects.valid === false) {
                        invalidFields.push({fullPath, value});
                    } else if (value.aspects.subtreeValid === false) {
                        invalidFields = invalidFields.concat(getInvalidFields(value, fullPath));
                    }
                }
            });
        }
    }
    return invalidFields;
}