import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./Location.html';
import styles from './Location-hashed.scss';

export default () => ({
    restrict: 'E',
    scope: {
        scheduledItem: '<'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'AddressUtil', ($scope, AddressUtil) => {
        $scope.$watch('scheduledItem.describe().location.address', address => {
            $scope.address = AddressUtil.format(address);
        });
    }]
});
