import templateStr from 'text!./pm-introduction.html'; 
import moment from 'moment';


export default ['$q', 'StaticDocumentsService_AND', 'qnb.PolicyService', 'brandingData', ($q, StaticDocumentsService_AND, PolicyService, brandingData) => {
    return {
        restrict: 'A', 
        scope: {
        }, 
        template: templateStr, 
        controller: ['$scope', ($scope) => {
            $scope.brand = brandingData.toLowerCase();
            $scope.pathname = window.location.origin; 
            $scope.privacyNoticeDoc = ''; 
            $scope.cookiePolicyDoc = ''; 
            $scope.privacyPolicyDoc = '';
            if (($scope.brand === 'tbb' || $scope.brand === 'itb') && sessionStorage.getItem('brandAllowed') === null) {
                PolicyService.getEndOfSaleDatesITB_AND()
                    .then((scriptDate) => {
                        const brandDateObject = scriptDate.find(obj => obj.hasOwnProperty($scope.brand));
                        if (brandDateObject) {
                            const brandDate = moment(brandDateObject[$scope.brand]).startOf('day');
                            const currentDate = moment().startOf('day');
                            if (currentDate.isSameOrAfter(brandDate)) {
                                window.location.replace(`/quoteandbuy/app/html/${brandingData}-thankyou.html`);
                            }
                        }
                    });
            }
        }], 
        link: ($scope) => {
            //Docs
            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY),
            ]).then((result) => {
                $scope.privacyPolicyDoc = result[0];
                $scope.privacyNoticeDoc = result[1];
                $scope.cookiePolicyDoc = result[2];
            });
        }, 
    };
}]; 