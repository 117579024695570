import _ from 'underscore';
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const TransactionConfirmation = function ($scope, $state, $stateParams, InsuranceSuiteMetadata, EventHub) {
    const ctrl = Object.create(BaseCtrl);

    const jobType = $stateParams.transactionSummary.baseData.jobType;
    $scope.transaction = initializeTransaction($stateParams.transactionSummary);
    initializeConfirmationMessage();

    $scope.goToPolicy = () => {
        $state.go('policies.detail.summary', {policyNumber: $scope.transaction.policyNumber});
    };

    $scope.goToTransaction = () => {
        // Check typekey.Job Code value
        if (jobType === 'Submission') {
            $state.go('quote.detail.summary', {submissionNumber: $scope.transaction.jobID});
        } else if (jobType === 'PolicyChange') {
            $state.go('endorsements.detail.summary', {policyChangeNumber: $scope.transaction.jobID});
        } else if (jobType === 'Renewal') {
            $state.go('renewal.detail.summary', {renewalNumber: $scope.transaction.jobID});
        }
    };

    function initializeTransaction(transactionSummary) {
        return {
            jobID: transactionSummary.jobID || transactionSummary.quoteID,
            policyNumber: transactionSummary.policyNumber || transactionSummary.bindData.policyNumber,
            productName: transactionSummary.baseData.productName,
            jobType: InsuranceSuiteMetadata.get('pc').types.getTypelist('Job').getCode(transactionSummary.baseData.jobType).name
        };
    }

    function initializeConfirmationMessage() {
        if (jobType === 'Submission') {
            $scope.confirmationMsg = 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmationCtrl.Your policy has been confirmed.';
        } else if (jobType === 'PolicyChange') {
            $scope.confirmationMsg = 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmationCtrl.Your changes have been confirmed.';
        } else {
            $scope.confirmationMsg = 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmationCtrl.Your transaction has been confirmed.';
        }
    }

    // Event Hub Tracking
    if (jobType === 'Submission') {
        publishToEventHub();
    }

    function publishToEventHub() {
        $scope.chosenQuote = _.findWhere($stateParams.transactionSummary.quoteData.offeredQuotes, {
            publicID: $stateParams.transactionSummary.bindData.chosenQuote
        });

        EventHub.publish('journey', 'purchase', createPurchaseEvent());
    }

    function createPurchaseEvent() {
        const bindData = $stateParams.transactionSummary.bindData;
        const chosenQuote = $scope.chosenQuote;
        return {
            id: $stateParams.transactionSummary.quoteID,
            accountNumber: bindData.accountNumber,
            policyNumber: bindData.policyNumber,
            offering: chosenQuote.branchName,
            taxes: chosenQuote.premium.taxes,
            total: chosenQuote.premium.total,
            totalBeforeTaxes: chosenQuote.premium.totalBeforeTaxes,
            productName: $stateParams.transactionSummary.baseData.productName,
            paymentPlans: bindData.paymentPlans
        };
    }

    return ctrl.create($scope);
};

TransactionConfirmation.$inject = ['$scope', '$state', '$stateParams', 'InsuranceSuiteMetadata', 'EventHub'];

export default TransactionConfirmation;
