import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./Definition.html';
import styles from './Definition-hashed.scss';

export default {
    bindings: {
        term: '@'
    },
    transclude: true,
    template: cssUtil.hashTemplate(template, styles)
}; 
