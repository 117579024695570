import templateStr from 'text!./mobileLobCoverages.html';

function controller() {
}


export default {
    template: templateStr,
    bindings: {
        coverages: '<',
        schedules: '<',
        branchCode: '<',
        lobCode: '@',
        offeringId: '<'
    },
    controller: controller
};
