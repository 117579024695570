export default ['LocaleService', (LocaleService) => {
    function link(scope, el, attrs, ngModelCtrl) {
        const symbol = LocaleService.getLocaleInfo().currency.symbol;

        ngModelCtrl.$parsers.push((value) => {
            value = value.trim();
            if (value[0] === symbol) {
                return value.slice(1);
            }
            if (value[value.length - 1] === symbol) {
                return value.substring(0, value.length - 1);
            }
            return value;
        });
    }

    return {
        require: 'ngModel',
        restrict: 'A',
        link
    };
}];
