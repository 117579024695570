import templateStr from 'text!./pm-ncd-protect.html';
import PNCDAppendix from 'text!./pncd-appendix.html';

export default ['$filter', ($filter) => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '='
        },
        template: templateStr,

        controller: ['$scope', 'ModalService', 'brandingData', 'qnb.PolicyService', 'StateUtils', ($scope, ModalService, brandingData, PolicyService, StateUtils) => {
            $scope.vehicle = $scope.quoteData.submission.lobData.personalMotor.value.coverables.pmVehicles[0];
            StateUtils.activeDataForState(true).ncdProtection = true;
            $scope.ncdYears = $scope.vehicle.ncdYears;
            const submission = $scope.quoteData.submission.value;
            const test = StateUtils.activeDataForState(true).ncdProtection;
            $scope.prevNcdProtectionValue = null;
            $scope.ncdProtectionVM = {
                value: StateUtils.activeDataForState(false).numberOfPayments,
                aspects: {
                    required: true,
                    availableValues: [true, false],
                    get valid() {
                        return angular.isDefined($scope.ncdProtectionVM.value) && $scope.ncdProtectionVM.value !== null;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };

            if((submission.isAggsQuoted_AND() === true) && ($scope.vehicle.ncdProtection === true)) {
                $scope.ncdProtectionVM.value = true
            }
            else if (StateUtils.activeDataForState(true).isNcdProtectionSelected) {
                $scope.ncdProtectionVM.value = $scope.vehicle.ncdProtection;
            }
            else {
                $scope.ncdProtectionVM.value = null;
            }

            $scope.prevNcdProtectionValue = $scope.ncdProtectionVM.value;

            $scope.DefactoDateEvaluation = PolicyService.getDefactoDate_AND()
                .then((scriptDate) => {
                    $scope.isPolicyEffDateBeforeItbDefactoDate = submission.isPolEffDateBeforeDefactoDate(scriptDate);
                    if ($scope.isPolicyEffDateBeforeItbDefactoDate === true) {
                        $scope.defactoDocSectionITB = 'Section 10';
                    } else {
                        $scope.defactoDocSectionITB = 'Section 11';
                    }
                });
            $scope.showPNCDAppendix = () => {
                ModalService.showSimpleDialog(PNCDAppendix, {
                    ncdYears: $scope.vehicle.ncdYears,
                    brand: brandingData.toLowerCase(),
                    isDateBeforeDefacto: submission.isPolicyStartDateBeforeDefacto5(),
                    isDateBeforeDefactoITB: $scope.isPolicyEffDateBeforeItbDefactoDate,
                    defactoDocSectionITB: $scope.defactoDocSectionITB,
                    ncdCosts: $scope.vehicle.ncdCosts
                }, {}, null, {
                    fullscreen: true,
                    backdrop: true,
                    windowClass: 'and-modal'
                });
            };
            $scope.$watch(() => $scope.vehicle.voluntaryExcess, (newValue) => {
                if($scope.quoteData.getIsVolExcessChanged()) {
                    $scope.ncdProtectionVM.value = null;
                    $scope.prevNcdProtectionValue = null;
                    $scope.vehicle.ncdProtection = false;
                    StateUtils.activeDataForState(true).isNcdProtectionSelected = false;
                    $scope.quoteData.setIsVolExcessChanged(false);
                }
            });
            $scope.$watch('ncdProtectionVM.value', (newValue) => {
                if (newValue !== null && $scope.vehicle.ncdProtection !== newValue) {
                    $scope.quoteData.ncdProtection = newValue;
                    $scope.quoteData.updateNcdProtectionSelection(newValue, $scope.prevNcdProtectionValue)
                        .then(() => { // Update current scope value
                            $scope.vehicle.ncdProtection = $scope.quoteData.ncdProtection;
                            // Changing the object reference isn't being picked up correctly here, so copying the amount directly.
                            $scope.vehicle.ncdCosts.amount = $scope.quoteData.ncdCosts.amount;
                            $scope.prevNcdProtectionValue = newValue
                            StateUtils.activeDataForState(true).isNcdProtectionSelected = true;
                        });
                }
            });
            $scope.selectOption = (option, $event) => {
                if ($event.target.tagName === 'A') { // Ignore clicks to the document anchor links
                    return;
                }
                if ($scope.quoteData.updateInFlight !== true) {
                    $scope.ncdProtectionVM.value = option;
                }
            };
        }],
    };
}];