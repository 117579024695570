import typeaheadMatchTemplate from 'text!./templates/typeahead-match.html';

export default [() => ({
    scope: {
        index: '=',
        match: '=',
        query: '=',
        selectDirective: '='
    },
    template: typeaheadMatchTemplate
})];