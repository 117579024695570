import _WizardStepsCtrlAND from './_WizardStepsCtrl_AND';
import templateStr from 'text!./templates/wizard-sidebar-ribbon.html';

export default function () {
    return {
        restrict: 'A',
        replace: true,
        template: templateStr,
        controller: _WizardStepsCtrlAND,
        scope: {
            wizardTitle: '@',
            readonlyWizardSteps: '=?' // To create dummy wizard Navigation to match with ANDIE UI designs
        }
    };
}
