import _ from 'lodash';
import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import {stateNamePrefix as AddBuildingSubWizard} from '../AddBuildingWizard/RouteConfig';

const findBuildingAndLocation = (locations, buildingID) => {
    for (let i = 0; i < locations.length; i++) {
        const location = locations[i];

        for (let j = 0; j < location.buildings.length; j++) {
            const building = location.buildings[j];

            if (building.publicID === buildingID) {
                return {location, building};
            }
        }
    }

    throw new Error('Building not found');
};

class EditCoveragesCtrl {
    constructor($rootScope, $q, $rootElement, $filter, $scope, $state, $stateParams, CoverablesService, ModalService, cpDeleteBuildingService, StateUtils, CoveragesUtil) {
        $rootElement.scrollTop(0);

        this.cpDeleteBuildingService = cpDeleteBuildingService;                
        this.CoverablesService = CoverablesService;
        this.ModalService = ModalService;
        this.$filter = $filter;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.StateUtils = StateUtils;

        this.model = StateUtils.activeDataForState(true).model;
        this.jobID = this.model.quoteID ? this.model.quoteID.value : this.model.jobID.value;

        const locations = this.model.lobData.commercialProperty.coverables.locations.value;
        const {location, building} = findBuildingAndLocation(locations, $stateParams.buildingID);

        this.location = location;
        this.building = building;

        this.CoveragesUtil = CoveragesUtil;
        this.dependentCoverages = CoveragesUtil.getDependentCoverages();
        this.sequencer = CoveragesUtil.getSequencer();

        this.initialCoverages = _.cloneDeep(this.building.coverages);

        this.coveragesLoading = false;
        this.lastOperation = $q.resolve();
        this.onCovChange = this.onCovChange.bind(this);
        this.updateCoverages = this.updateCoverages.bind(this);
        this.goBack = this.goBack.bind(this);
        this.showChangesWarning = true;
        this.stateChangeUnsub = this.$rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
            if (this.showChangesWarning &&
                !_.isEqual(this.initialCoverages, this.building.coverages) &&
                (this.StateUtils.hasState('baseTransactionWizard', toState) || this.StateUtils.hasState(AddBuildingSubWizard, toState))) {

                event.preventDefault();

                this.ModalService.showWarning(
                    'quoteandbind.cp.Unsaved Changes',
                    'quoteandbind.cp.Do you want to save your changes?',
                    undefined,
                    undefined,
                    undefined,
                    this.$filter('translate')('quoteandbind.cp.No')
                ).result.then(() => {
                    this.updateCoverages()
                        .then(() => this.$state.go(toState, toParams));
                }).catch(() => {
                    // If the user chooses to discard, go to state
                    this.resetCoverages().then(() => {
                        this.$state.go(toState, toParams);
                    });
                });
            }
            this.stateChangeUnsub(); // Unsubscribe $stateChangeStart listener
        });
    }

    onCovChange(updatedCoverage) {
        return this.updateCoverages(updatedCoverage);
    }

    updateCoverages(updatedCoverage, coverages = null) {
        if (coverages) {
            this.building.coverages = coverages;
        }

        this.coveragesLoading = true;
        this.lastOperation = this.sequencer(this.CoverablesService.updateCPBuildingCoverages(this.jobID, this.location.publicID, this.building, SessionUtil.getSessionUUID(this.model)));

        this.lastOperation.then((result) => {
            this.CoveragesUtil.updateCoverages(updatedCoverage, result.coverages, this.building.coverages);
        })
            .catch(() => this.ModalService.showError('quoteandbind.cp.buildingsWizard.EditCoverages.Coverages not saved', 'quoteandbind.cp.Please check for errors and try again'))
            .then(() => {
                this.coveragesLoading = false;
            });

        return this.lastOperation;
    }

    cancel() {
        const areCoveragesUpdated = false;

        if (_.isEqual(this.initialCoverages, this.building.coverages)) {
            return this.goBack(areCoveragesUpdated);
        }

        return this.ModalService.showWarning(
            'quoteandbind.cp.buildingsWizard.EditBuilding.Cancel Changes?',
            'quoteandbind.cp.buildingsWizard.EditBuilding.You will lose all the changes',
            undefined,
            undefined,
            undefined,
            this.$filter('translate')('quoteandbind.cp.No')
        )
            .result.then(() => {
                return this.resetCoverages();
            })
            .then(() => this.goBack(areCoveragesUpdated));
    }

    save(form) {
        if (this.CoveragesUtil.validateForm(form)) {
            const areCoveragesUpdated = true;
            this.updateCoverages()
                .then(() => this.goBack(areCoveragesUpdated));
        }
    }

    goBack(areCoveragesUpdated) {
        const params = {
            publicID: this.$state.params.buildingID,
            isCoverageOpen: false,
            areCoveragesUpdated
        };
        this.showChangesWarning = false;
        this.$state.go('cpBuildingWizardViewBuilding', params);
    }

    deleteBuilding() {
        this.cpDeleteBuildingService.deleteBuilding(this.model, this.location, this.building);
    }

    resetCoverages() {
        this.initialCoverages.forEach(cov => {
            cov.updated = true;
            cov.terms.forEach(term => {
                term.updated = true;
            });
        });
        return this.updateCoverages(null, this.initialCoverages);
    }
}

EditCoveragesCtrl.$inject = ['$rootScope', '$q', '$rootElement', '$filter', '$scope', '$state', '$stateParams', 'qnb.cpCoverablesService', 'ModalService', 'qnb.cpDeleteBuildingService', 'StateUtils', 'CoveragesUtil'];

export default EditCoveragesCtrl;
