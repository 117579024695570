/**
 * Provides variations from a Guidewire service
 *
 * getExperimentVariant {Promise}
 *      It fulfils with a selected option for the user.
 *      E.g. 0 or 1. In case of an error -1 is returned
 *      The value is persisted in cookies
 */

export default ['JsonRPCService', '$cookies', 'gwAbExperimentxCenter', '$q', (JsonRPCService, $cookies, abExperimentxCenter, $q) => {
    return {
        /**
         * @param {String} [experimentId]
         * @param {Object} [params]
         * @returns {Promise}
         */
        getExperimentValue(experimentId = 'guidewireExperiment', params) {
            // check experiment ID in cookies
            if ($cookies.get(experimentId)) {
                return $q.resolve($cookies.get(experimentId));
            }
            // get experiment variant
            return JsonRPCService
                .send(abExperimentxCenter, 'segmentation/abexperiment', 'getExperimentValue', [experimentId, params])
                .then((data) => {
                    $cookies.put(experimentId, data.experimentResponse); // put experiment variant in cookies
                    return data.experimentResponse;
                }, () => {
                    return -1;
                });
        }
    };
}];