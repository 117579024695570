import templateStr from 'text!./pm-payment-reg-lookup.html';
import PMVehicle from 'edge/quoteandbind/pm/models/PmVehicle';

export default ['$filter', 'ViewModelService', 'VehicleLookupService_AND', 'brandingData', '$rootScope', ($filter, ViewModelService, VehicleLookupService_AND, brandingData, $rootScope) => {
    return {
        restrict: 'A',
        scope: {
            form: '<',
            data: '='
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            let previousReg;
            const isSameVehicle = (other) => {
                const vehicle = $scope.data.vehicleVM.value;
                return vehicle.abiCode === other.abiCode;
            };

            const updateVehicle = function (lookupData, vehicleVM = $scope.data.vehicleVM) {
                Object.assign(vehicleVM.value, lookupData); // Copy all values to the PM Vehicle
                vehicleVM.registrationNumber.value = $scope.regSearchVM.value; // Update Reg
                vehicleVM.regNumUnknown.value = false;
            };

            // We need the normal reg validation and additionally check if it's not null.
            const regSearchVM = ViewModelService.create({
                registrationNumber: $scope.data.vehicleVM.registrationNumber.value
            }, 'pc', 'itb.edge.capabilities.policyjob.lob.personalmotor.coverables.dto.PmVehicleDTO').registrationNumber;
            $scope.regSearchVM = {
                value: $scope.data.vehicleVM.registrationNumber.value,
                aspects: {
                    inputCtrlType: 'text',
                    required: !$scope.data.hasReg,
                    get valid() {
                        return !!$scope.regSearchVM.value && regSearchVM.aspects.valid;
                    },
                    get validationMessages() {
                        if (!!$scope.regSearchVM.value !== true) {
                            return [$filter('translate')('and.quoteandbind.pm.views.paymentstep.1.RegRequiredErrorMsg')];
                        } else if (regSearchVM.aspects.valid !== true) {
                            return regSearchVM.aspects.validationMessages;
                        }
                        return [];
                    }
                }
            };
            // Keep both VMs in sync to trigger accurate validation
            $scope.syncRegSearchVM = () => {
                regSearchVM.value = $scope.regSearchVM.value;
            };

            $scope.lookupRegOnEnter = (event) => {
                if (event && event.which === 13) { // Allow for lookups onEnter
                    $scope.lookupReg();
                }
            };

            $scope.lookupReg = () => {
                if ($scope.inFlight === true || (previousReg === $scope.regSearchVM.value)) {
                    return;
                }
                $scope.errorMsg = null;
                if ($scope.regSearchVM.aspects.valid) {
                    $scope.inFlight = true;
                    $scope.newVehicle = null;
                    if ($rootScope.productCode === 'CommercialVehicle') {
                        VehicleLookupService_AND.lookupLCVVehicleByRegNumber($scope.regSearchVM.value)
                            .then((data) => {
                                if (data && data.make && data.model && data.firstRegisteredYear) {
                                    previousReg = $scope.regSearchVM.value;
                                    if (isSameVehicle(data)) {
                                        updateVehicle(data);
                                        $scope.data.regMatched = true;
                                    } else {
                                        $scope.errorMsg = $filter('translate')('and.quoteandbind.pm.views.paymentstep.1.VehMismatchErrorMsg');
                                        $scope.newVehicle = new PMVehicle(data);
                                        $scope.data.changeConfirmationCallback = function (vehicleVM) {
                                            updateVehicle(data, vehicleVM);
                                        };
                                    }
                                } else {
                                    throw new Error('Reg Lookup data not valid');
                                }
                            })
                            .catch(() => {
                                $scope.errorMsg = $filter('translate')(`and.quoteandbind.pm.directives.templates.pm-vehicle.lookup.We could not find this car. You must either provide your registration number or enter your car details manually.${ brandingData}`);
                            })
                            .finally(() => {
                                $scope.inFlight = false;
                            });
                    } else {
                            
                        VehicleLookupService_AND.lookupVehicleByRegNumber($scope.regSearchVM.value)
                            .then((data) => {
                                if (data && data.make && data.model && data.firstRegisteredYear) {
                                    previousReg = $scope.regSearchVM.value;
                                    if (isSameVehicle(data)) {
                                        updateVehicle(data);
                                        $scope.data.regMatched = true;
                                    } else {
                                        $scope.errorMsg = $filter('translate')('and.quoteandbind.pm.views.paymentstep.1.VehMismatchErrorMsg');
                                        $scope.newVehicle = new PMVehicle(data);
                                        $scope.data.changeConfirmationCallback = function (vehicleVM) {
                                            updateVehicle(data, vehicleVM);
                                        };
                                    }
                                } else {
                                    throw new Error('Reg Lookup data not valid');
                                }
                            })
                            .catch(() => {
                                $scope.errorMsg = $filter('translate')(`and.quoteandbind.pm.directives.templates.pm-vehicle.lookup.We could not find this car. You must either provide your registration number or enter your car details manually.${ brandingData}`);
                            })
                            .finally(() => {
                                $scope.inFlight = false;
                            }); 
                    }
                }
            };
        }]
    };
}];