import BaseCtrl from 'edge/platform/base/BaseCtrl';
import maxAgeLimitModal from 'text!../views/max-age-limit-popup-modal.html';

const fn = ($filter, $scope, $stateParams, $translate, ModalService, StateUtils, submissionViewModel, SessionService_AND, brandingData) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submissionVm = submissionViewModel();
    const pmModel = submissionVm.lobData.personalMotor.value;
    const accountHolder = submissionVm.value.getAccountHolderPerson();
    const maxAge = 80;
    const minAgeToDrive = 17;
    $scope.isWithinAgeRange = true;
    var ageDifference = null;
    $scope.brand = brandingData.toLowerCase();

    $scope.contactPhone = submissionVm.bindData.contactPhone;
    $scope.boxDeclaration = submissionVm.bindData.boxDeclaration_itb
    if ($scope.contactPhone !== undefined) {
        if ($scope.contactPhone.value !== null && !$scope.contactPhone.aspects.valid) {
            $scope.contactPhone.value = '07000000000';
        }
    }

    if ($scope.boxDeclaration !== undefined) {
        if ($scope.boxDeclaration.value !== null && !$scope.boxDeclaration.aspects.valid) {
            $scope.boxDeclaration.value = true;
        }
    }

    $scope.$watch(() => $scope.page.currentDriver.dateOfBirth.value, (newValue) => {
        if(newValue && ($scope.brand === 'itb' || $scope.brand === 'kfi')) {
            var periodStart = moment([submissionVm.baseData.periodStartDate.year.value, submissionVm.baseData.periodStartDate.month.value, submissionVm.baseData.periodStartDate.day.value]);
            var dob = moment([newValue.year, newValue.month, newValue.day]);
            showMaxAgeModal(periodStart, dob);
        }

    });

    const showMaxAgeModal = (periodStart, dateOfBirth) => {
        ageDifference = periodStart.diff(dateOfBirth, 'years');
        $scope.isWithinAgeRange = (ageDifference >= minAgeToDrive && ageDifference <= maxAge);
        if(!$scope.isWithinAgeRange) {
            ModalService.showSimpleDialog(maxAgeLimitModal, {
              maxAge: maxAge,
              isPH: $scope.page.currentDriver.isPolicyHolder.value
            }, {}, null, {
              fullscreen: true,
              backdrop: true,
              windowClass: 'and-modal-max-age'
            });
        }
    };

    ctrl.init = () => {
        if ($stateParams.addNamedDriver === true) {
            $scope.isAddingNewDriver = true;
        } else if ($stateParams.editNamedDriver) {
            $scope.isEditingDriver = true;
        }

        SessionService_AND.activateFunctionTimer();
        
        $scope.page = {
            drivers: submissionVm.lobData.personalMotor.coverables.pmDrivers,
            periodStartDate: submissionVm.baseData.periodStartDate.value,
            convictionCodes: submissionVm.lobData.personalMotor.coverables.convictionCodes.value.sort()
        };

        // Init driver
        let currentDriver;
        if (pmModel.coverables.pmDrivers.length === 0) { // If there are no drivers and this is the first visit, initialise a new driver
            currentDriver = pmModel.coverables.createDriver(accountHolder);
            currentDriver.copyFromAccountHolder(accountHolder); // Copy driver fields from Account Holder object to driver
            currentDriver.isPolicyHolder = true;
            currentDriver.mainDriver = true;
        } else if ($scope.isAddingNewDriver === true) {
            currentDriver = pmModel.coverables.createDriver();
            currentDriver.mainDriver = false;
        } else if ($scope.isEditingDriver === true) {
            currentDriver = pmModel.coverables.pmDrivers.find((driver)=> driver.publicID === $stateParams.editNamedDriver);
        } else {
            currentDriver = pmModel.coverables.pmDrivers.find((driver)=> driver.isPolicyHolder === true);
        }
        $scope.page.currentDriver = submissionVm.lobData.personalMotor.coverables.pmDrivers.findFirstElement(currentDriver);
    };

    ctrl.undo = () => {
        flowModel.jumpToStep('pmDriverSummary', null, null, true);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', '$stateParams', '$translate', 'ModalService', 'StateUtils', 'submissionViewModel', 'SessionService_AND', 'brandingData'];

export default fn;
