export default (RootStates) => {
    const parentState = RootStates.faq;

    return {
        faq: {
            name: 'faq',
            url: '/faq/{faqScrollTo}',
            parent: parentState,
            controller: () => {
            },
            template: '<gw-faq></gw-faq>',
            featureToggler: 'faq'
        }
    };
};
