import _ from 'lodash';
import loadGoogleMapsAPI from 'gw-portals-google-maps-js';

let _ModalService;
let googleMapsApi;

class GooglePlacesLookupCtrl {

    constructor(defaultLocaleDetails, ModalService, $element) {
        loadGoogleMapsAPI()
            .then((response) => {
                googleMapsApi = response;
                this.$element = $element;
                this._defaultLocaleDetails = defaultLocaleDetails;
                _ModalService = ModalService;
                this._init();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    _init() {
        this.showLongAddressFormat = this.shouldShowLongAddress();
        this._createPlacesListener();
    }

    shouldShowLongAddress() {
        return !!(this.policyAddress && this.policyAddress.aspects.valid && this.policyAddress.aspects.subtreeValid);
    }

    _createPlacesListener() {
        const [searchInput] = this.$element.find('.address_search_input');
        const options = {
            componentRestrictions: {
                country: this._defaultLocaleDetails.countryCode
            }
        };
        const autocomplete = new googleMapsApi.places.Autocomplete(searchInput, options);
        googleMapsApi.event.addListener(autocomplete, 'place_changed', () => {
            this._triggerPlaceChangedEvent(searchInput, autocomplete);
        });
    }

    _mapGooglePlacesAddressToDTO(place) {
        place.address_components.forEach(addressComponent => {
            if (addressComponent.types.includes('street_number')) {
                this.policyAddress.addressLine1.value = addressComponent.long_name;
            }
            if (addressComponent.types.includes('route')) {
                const streetNumber = this.policyAddress.addressLine1.value;
                this.policyAddress.addressLine1.value = `${streetNumber} ${addressComponent.long_name}`;
            }
            if (addressComponent.types.includes('neighborhood') && addressComponent.types.includes('political')) {
                this.policyAddress.addressLine2.value = addressComponent.long_name;
            }
            if (addressComponent.types.includes('locality') && addressComponent.types.includes('political')) {
                this.policyAddress.city.value = addressComponent.long_name;
            }
            if (addressComponent.types.includes('sublocality_level_1') && addressComponent.types.includes('sublocality') && addressComponent.types.includes('political')) {
                this.policyAddress.city.value = addressComponent.long_name;
            }
        });
        if (_.isEmpty(this.policyAddress.addressLine1.value) || _.isEmpty(this.policyAddress.city.value)) {
            _ModalService.showError(
                'platform.widgets.googleplaceslookup.components.Unsuccessful Mapping From Google Places',
                'platform.widgets.googleplaceslookup.components.An error occurred when mapping the address data from google places'
            );
        }
    }

    _clearAddressObject() {
        const addressObject = this.policyAddress.value;
        addressObject.addressLine1 = '';
        addressObject.addressLine2 = '';
        addressObject.addressLine3 = '';
        addressObject.city = '';
    }

    _triggerPlaceChangedEvent(searchInputElement, autocomplete) {
        const place = autocomplete.getPlace();
        const postalCodeComponent = _.find(place.address_components, (addressComponent) => {
            return _.includes(addressComponent.types, 'postal_code');
        });
        if (postalCodeComponent !== undefined) {
            if (this.policyAddress.postalCode.value === postalCodeComponent.long_name) {
                this._mapGooglePlacesAddressToDTO(place);
            } else {
                _ModalService.showError(
                    'platform.widgets.googleplaceslookup.components.Availability Lookup Error',
                    'platform.widgets.googleplaceslookup.components.You need to enter an address with the same postal code as you started the quote process with'
                );
                searchInputElement.val('');
                this._clearAddressObject();
            }
        }
    }

    toggleAddressEntry() {
        this.showLongAddressFormat = this.showLongAddressFormat !== true;
    }

}

GooglePlacesLookupCtrl.$inject = ['defaultLocaleDetails', 'ModalService', '$element'];

export default GooglePlacesLookupCtrl;
