import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'lodash';

import styles from 'edge/faq/directives/FaqDirective-hashed.scss';

const fn = ($scope, $location, $anchorScroll, FaqService, TranslateService, $stateParams) => {
    const ctrl = Object.create(BaseCtrl);

    function translateContent(sectionsData) {
        sectionsData.forEach(section => {
            TranslateService.translate({
                object: section,
                propName: 'title',
                displayKey: section.title,
                scope: $scope
            });

            section.topics.forEach(topic => {
                TranslateService.translate({
                    object: topic,
                    propName: 'question',
                    displayKey: topic.question,
                    scope: $scope
                });

                TranslateService.translate({
                    object: topic,
                    propName: 'long',
                    displayKey: topic.long,
                    scope: $scope
                });

                if (topic.link && topic.link.name) {
                    TranslateService.translate({
                        object: topic.link,
                        propName: 'name',
                        displayKey: topic.link.name,
                        scope: $scope
                    });
                }
            });
        });

        return sectionsData;
    }

    function isSectionVisible(section, query) {
        const filter = query || $scope.search.query;
        let result = null;

        if (filter) {
            result = section.topics.some(topic => topicSearch(filter)(topic));
        } else {
            result = section.topics.length > 0;
        }

        return result;
    }

    function sectionSearch(query) {
        return section => isSectionVisible(section, query);
    }

    function topicSearch(query) {
        return topic => {
            if (!query) {
                return true;
            }

            const questionMatching = checkSafeMatching(topic.question, query);
            const answerMatching = checkSafeMatching(topic.long, query);
            const linkMatching = topic.link && topic.link.name ? checkSafeMatching(topic.link.name, query) : false;
            return questionMatching || answerMatching || linkMatching;
        };
    }

    function checkSafeMatching(phrase, query) {
        const htmlStrippedPhrase = _.unescape(phrase.replace(/<[^<>]+>/gi, ''));
        return htmlStrippedPhrase.toLocaleLowerCase().includes(query.toLowerCase());
    }

    function isTopicOpened(topicId) {
        return topicId === $location.hash();
    }

    ctrl.init = () => {
        if ($stateParams.faqScrollTo) {
            $location.hash($stateParams.faqScrollTo);
            $anchorScroll();
        }

        const faqSections = FaqService.getAllSections();
        $scope = Object.assign($scope, {
            search: {},
            sections: translateContent(faqSections),
            sectionSearch,
            topicSearch,
            isTopicOpened,
            isSectionVisible,
            styles
        });

    };

    ctrl.selectSection = sectionId => {
        $location.hash(sectionId);
        $anchorScroll();
    };

    return ctrl.create($scope, $location, $anchorScroll, FaqService, TranslateService);
};

fn.$inject = ['$scope', '$location', '$anchorScroll', 'FaqService', 'TranslateService', '$stateParams'];

export default fn;
