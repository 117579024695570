// Use gw-pl-accordion-heading below an gw-pl-accordion-group to provide a heading containing HTML
// <gw-pl-accordion-group>
//   <gw-pl-accordion-heading>Heading containing HTML - <img src="..."></gw-pl-accordion-heading>
// </gw-pl-accordion-group>

export default [() => {
    return {
        restrict: 'EA',
        transclude: true, // Grab the contents to be used as the heading
        template: '', // In effect remove this element!
        replace: true,
        require: '^gwPlAccordionGroup',
        link: (scope, element, attr, accordionGroupCtrl, transclude) => {
            // Pass the gwPlHeading to the gw-pl-accordion-group controller
            // so that it can be transcluded into the right place in the template
            // [The second parameter to transclude causes the elements to be cloned so that they work in ng-repeat]
            accordionGroupCtrl.setHeading(transclude(scope, () => {
            }));
        }
    };
}];