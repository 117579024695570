import NavigationHistoryService from './NavigationService';
import NavigationDirective from './NavigationDirective';
import NavigationListener from './NavigationListener';
import swipeDirective from './SwipeDirective';
import 'angularRouter';
import 'edge/platform/widgets/definition-list/module';


export default angular.module('edge.platform.navigation', ['ui.router', 'edge.platform.widgets.definition-list'])
    .factory('NavigationService', NavigationHistoryService)
    .directive('gwPlNavigation', NavigationDirective)
    .directive('gwSwipeLeft', swipeDirective('gwSwipeLeft', -1))
    .directive('gwSwipeRight', swipeDirective('gwSwipeRight', 1))
    .run(NavigationListener);
