/**
 * Layout for content with actions
 *
 * Example rendering a title with a list and 3 actions:
 *   <gw-action-Message>
 *       <gw-action-Message-Content>
 *           <h1>Underwriting issues have been raised</h1>
 *           <p>To continue the quote you have to do one of the following:</p>
 *           <ul>
 *               <li>Jump on one foot</li>
 *               <li>Refer the issues to the underwriter</li>
 *               <li>Ask the client to lie just a little bit</li>
 *           </ul>
 *       </gw-action-Message-Content>
 *
 *       <gw-action-Message-Action>
 *           <button class="gw-btn">Jump on left foot</button>
 *       </gw-action-Message-Action>
 *       <gw-action-Message-Action>
 *           <button class="gw-btn">Refer to underwriter</button>
 *       </gw-action-Message-Action>
 *       <gw-action-Message-Action>
 *           <button class="gw-btn">Refer the client to another insurance agent</button>
 *       </gw-action-Message-Action>
 *   </gw-action-Message>
 */
import template from 'text!./templates/ActionMessage.html';
import layout from './LayoutFactory';

export default layout('ActionMessage', template, (partial) => {
    partial('Content');
    partial('Action', '<div class="gw-action-message__action" gw-partial-target></div>');
});
