import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./payment.html';
import controller from './paymentCtrl';
import styles from './payment-hashed.scss';

/**
 * @ngdoc component
 * @name gw-payment
 *
 * @description  A generic component to display a payment detail page into a wizard flow
 *
 */
export default {
    controller,
    template: cssUtil.hashTemplate(template, styles)
};