import ProductMediaMapUtil from 'gw-portals-util-js/ProductMediaMapUtil';

const SECTOR1 = 1711;
const SECTOR2 = 5710;
const SECTOR3 = 8111;
const STOCK_PHOTO_URI = '../styles/images/common/stockphotos/';
const occupationImageMap = {
    'sector1': 'sector1',
    'sector2': 'sector2',
    'sector3': 'sector3',
    'default': 'sector4'
};

const questionMap = {
    '01': 'fa-truck',
    '02': 'fa-building',
    '03': 'fa-desktop',
    '04': 'fa-barcode',
    '05': 'fa-money',
    '06': 'fa-users',
    '07': 'fa-hand-paper-o',
    '08': 'fa-plane',
    '09': 'fa-archive',
    '10': 'fa-keyboard-o',
    '11': 'fa-compass',
    '12': 'fa-phone',
    '13': 'fa-archive',
    'default': ''
};

export default {
    getQuestionIcon(id) {
        return questionMap[id] || questionMap.default;
    },
    getOccupationImage(SIC) {
        const id = _normaliseSIC(SIC);
        const image = occupationImageMap[id] || occupationImageMap.default;
        return `${STOCK_PHOTO_URI}${image}.svg`;
    },
    getProductCodeIcon(productCode) {
        return ProductMediaMapUtil.getProductCodeIcon(productCode);
    },
    getProductCodeImage(productCode) {
        return ProductMediaMapUtil.getProductCodeImage(productCode);
    }
};

function _normaliseSIC(SIC) {
    let normalisedId = 'default';
    const parsedSIC = parseInt(SIC);
    if (!Number.isInteger(parsedSIC)) {
        return normalisedId;
    }
    switch (parsedSIC) {
        case (SECTOR1):
            normalisedId = 'sector1';
            break;
        case (SECTOR2):
            normalisedId = 'sector2';
            break;
        case (SECTOR3):
            normalisedId = 'sector3';
            break;
        default:
            break;
    }
    return normalisedId;
}