import templateStr from 'text!./templates/progress-indicator.html';

/*
 * Directive API
 *  transitionFn - Callback to execute when a progress indicator is touched
 *  flavor - Allows the user to define custom styles on the base component without overwriting its behavior
 *  prevFunc - Callback to execute when back chevron is touched
 *  nextFunc - Callback to execute when next chevron is touched
 *  stepValidityFns - array of objects with isValid() functions
 *  form - Optional form to check for validity
 *  currentStep - the current active step index
 *  customClasses - optional array of classes to apply to each step
 */
export default [
    function () {
        return {
            restrict: 'E',
            scope: {
                transitionFn: '&',
                flavor: '@',
                prevFunc: '&',
                nextFunc: '&',
                stepValidityFns: '=',
                form: '=',
                currentStep: '=',
                customClasses: '=',
                removeLastIndicator: '=?',
                notIndicateCompletedSteps: '=?'
            },
            template: templateStr,
            controller: ['$scope', ($scope) => {
                const stepValidityFns = $scope.stepValidityFns.slice();

                $scope.transition = (targetStep) => {
                    $scope.transitionFn({
                        targetStep: targetStep
                    });
                };
                $scope.stepValidityFnsToDisplay = stepValidityFns;

                if ($scope.removeLastIndicator) {
                    $scope.stepValidityFnsToDisplay.pop();
                }
            }]
        };
    }
];