class MetadataDrivenCtrl {

    constructor($scope) {
        this.$scope = $scope;
        this.$scope.validationForms = {};
        this.content = [];
        this.CONTENT_TYPE = {
            FIELD: 'field',
            GROUP: 'group',
            CONTAINER: 'container',
            ELEMENT: 'element'
        };
    }

    $onInit() {
        this._init();
    }

    $onChanges() {
        this._init();
    }

    /**
     * Setup fieldsets and define view model for each input field
     * @private
     */
    _init() {
        if (this.presentationModel) {
            this._registerActions();
            this._setupFieldsets();

            if (this.viewModel) {
                // initialize VMNode when value is undefined
                this.viewModel.value = this.viewModel.value || {};
            }
        }
        this.forms = {};
        this.formsSet = {};
    }

    /**
     * Setup fieldsets using default group or groups defined in presentationModel or
     * setting specific group informed on component usages
     * @private
     */
    _setupFieldsets() {
        if (this.presentationModel) {
            if (this.group) {
                const fieldsetGroup = this.presentationModel.groups[this.group];
                if (fieldsetGroup) {
                    this.content = fieldsetGroup.map(contentPath => this.presentationModel.items.get(`groups.${this.group}`).items.get(contentPath));
                }
            } else if (this.presentationModel.content) {
                this.content = this.presentationModel.content.map(contentPath => this.presentationModel.items.get(contentPath));
            } else {
                this.content = [this.presentationModel];
            }
        }
    }

    /**
     * Register actions required by fields created on controller
     * @private
     */
    _registerActions() {
        if (this.exposedMethods) {
            for (const action in this.exposedMethods) {
                if (this.exposedMethods.hasOwnProperty(action)) {
                    this[action] = this.exposedMethods[action];
                }
            }
        }
    }

    setFormName(formName) {
        this.$scope.validationForms[formName] = undefined;
    }

    getForm(formName) {
        // Due to issues with dynamic angular form names, this is a hack that we had to
        // implement to allow the presentation metadata to dynamically name and access ng-forms.
        return this.$scope.$parent.$parent.form[`validationForms[${formName}]`];
    }
}

MetadataDrivenCtrl.$inject = ['$scope'];

export default MetadataDrivenCtrl;