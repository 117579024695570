import template from 'text!./templates/date-tile.html';
import moment from 'moment';
import _ from 'underscore';

export default [
    () => ({
        'scope': {
            'model': '=',
            'mode': '=',
            'minDate': '=',
            'maxDate': '=',
            'onNext': '&',
            'wizardState': '@',
            'editableStates': '='
        },
        template,
        'controller': ['$scope', ($scope) => {
            /*  model - Required - UTC String or date object for date
             *   min - Optional - UTC String or date object for min date
             *   max - Optional - UTC String or date object for max date
             *   mode - Optional - String to determine whether to allow user to change date. Controls enabled when equal to 'active'
             */

            const PAST = -1;
            const FUTURE = 1;
            $scope.getDayOfWeek = () => getDateModel().format('dddd');
            $scope.getMonth = () => getDateModel().format('MMMM');
            $scope.getDayOfMonth = () => getDateModel().format('D');
            $scope.getYear = () => getDateModel().format('YYYY');
            $scope.increaseDay = changeDay(FUTURE);
            $scope.decreaseDay = changeDay(PAST);
            $scope.increaseMonth = changeMonth(FUTURE);
            $scope.decreaseMonth = changeMonth(PAST);
            $scope.increaseYear = changeYear(FUTURE);
            $scope.decreaseYear = changeYear(PAST);
            $scope.increaseDayValid = isDayChangeValid(FUTURE);
            $scope.decreaseDayValid = isDayChangeValid(PAST);
            $scope.increaseMonthValid = isMonthChangeValid(FUTURE);
            $scope.decreaseMonthValid = isMonthChangeValid(PAST);
            $scope.increaseYearValid = isYearChangeValid(FUTURE);
            $scope.decreaseYearValid = isYearChangeValid(PAST);
            // Set the model value. This is done as it will ensure the date is within the min/max range
            $scope.model = _prepareNewModelDate($scope.model, $scope.model);

            function changeDay(direction) {
                return _changeTime(direction, 'days');
            }

            function changeMonth(direction) {
                return _changeTime(direction, 'months');
            }

            function changeYear(direction) {
                return _changeTime(direction, 'years');
            }

            function isDayChangeValid(direction) {
                return _isChangeValid(direction, 'days');
            }

            function isMonthChangeValid(direction) {
                return _isChangeValid(direction, 'months');
            }

            function isYearChangeValid(direction) {
                return _isChangeValid(direction, 'years');
            }

            /**
             *
             * @param {Number} direction - The amount to change the date. Negative would be a change to the past, 0 no
             * change and a positive would be a change to a future date.
             * @param {String} changeUnit - The unit of time to add/remove (see valid units for the moment library)
             * @returns {function()} - returns function that increases or decreases a date
             * @private
             */
            function _changeTime(direction, changeUnit) {
                return () => {
                    const newDate = moment($scope.model).add(1 * direction, changeUnit);
                    const tempDate = _normaliseDate(newDate, changeUnit);
                    // Set the model to the new date and save as same type as the current model
                    $scope.model = _prepareNewModelDate($scope.model, tempDate);
                };
            }

            /**
             * Creates a function which will determine if a date change is valid
             * @param {Number} direction The amount to change the date. Negative would be a change to the past, 0 no
             * change and a positive would be a change to a future date.
             * @param {String} changeUnit The unit of time to add/remove (see valid units for the moment library)
             * @returns {function()} - returns function that determines whether a change is valid
             * @private
             */
            function _isChangeValid(direction, changeUnit) {
                return () => {
                    const {
                        minDate, maxDate
                    } = _parseMinMax();
                    let returnResult = true;
                    // Default to true if there is no min/max
                    if (minDate || maxDate) {
                        const modelDay = moment($scope.model);
                        const newDay = modelDay.add(1 * direction, changeUnit);
                        if (minDate) {
                            returnResult = returnResult && newDay.isAfter(minDate);
                        }
                        if (maxDate) {
                            returnResult = returnResult && newDay.isBefore(maxDate);
                        }
                    }
                    return returnResult;
                };
            }

            /**
             * Returns the normalised (between min & max if they exist) model date
             * @returns {moment} date object
             */
            function getDateModel() {
                return _normaliseDate($scope.model);
            }

            /**
             * Parses a date and ensures that it is between the min/max if they exist. Defaults to min max if not
             * @param {date} date - the date to be normalised
             * @param {String} [changeUnit] - optional date granularity to round down to if the date is greater than max
             * @returns {Object} - moment date object
             */
            function _normaliseDate(date, changeUnit = 'days') {
                // Moment will parse a UTC and local date object
                let normalisedDate = moment(date);
                // Parse min/max dates or default to null
                const {
                    minDate, maxDate
                } = _parseMinMax();


                // Default to min or max date if the date is outside the min/max range
                if (minDate) {
                    normalisedDate = normalisedDate.isBefore(minDate) ? minDate : normalisedDate;
                }

                if (maxDate) {
                    // We use startOf here to round down if changeUnit granularity has been set. (UX feedback)
                    normalisedDate = normalisedDate.isAfter(maxDate) ? maxDate.startOf(changeUnit) : normalisedDate;
                }
                return normalisedDate;
            }

            /**
             * Parses the optional min/max scope params into a moment object or null if undefined
             * @returns {{minDate: null, maxDate: null}}
             * @private
             */
            function _parseMinMax() {
                const minDate = $scope.minDate !== undefined ? moment($scope.minDate) : null;
                const maxDate = $scope.maxDate !== undefined ? moment($scope.maxDate) : null;
                return {
                    minDate, maxDate
                };
            }

            /**
             * Determine whether a date is a JS object or a UTC string
             * @param {Date} date - object/primitive that will be tested
             * @returns {boolean} - true if the date is a date object, otherwise false
             * @private
             */
            function _isDateObject(date) {
                const DATE_KEYS = ['day', 'month', 'year'];
                const isObject = typeof date === 'object';
                let hasAllKeys;
                if (isObject) {
                    hasAllKeys = _.every(DATE_KEYS, (key) => key in date);
                }
                return isObject && hasAllKeys;
            }

            /**
             * Convert a date object into a local date json object
             * @param {Date} date - date to convert
             * @returns {{day: int, month: int, year: int}}
             * @private
             */
            function _toLocalDate(date) {
                return {
                    day: date.date(),
                    month: date.month(),
                    year: date.year()
                };
            }

            /**
             * prepares a value to be set. Sets it based on they type of the old date
             * @param {Date} oldDate - the old date
             * @param {Date} newDate - the new date to set
             * @returns {*}
             * @private
             */
            function _prepareNewModelDate(oldDate, newDate) {
                const tempModel = _normaliseDate(newDate);
                return _isDateObject(oldDate) ? _toLocalDate(tempModel) : tempModel.format();
            }

            /**
             * Returns current state used by tiles
             * @returns {String}
             */
            $scope.getState = () => {

                const isEditable = $scope.editableStates.some(state => state === $scope.wizardState);

                if ($scope.mode === 'tile' && isEditable) {
                    return 'completed';
                }

                return 'disabled';
            };
        }]
    })
];