import controller from './MetadataDrivenCtrl';
import template from 'text!./metadataDriven.html';
import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './metadataDriven-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);
export default {
    controller,
    template: hashedTemplate,
    replace: true,
    bindings: {
        presentationModel: '<',
        viewModel: '<',
        group: '@',
        loading: '<',
        exposedMethods: '=?'
    }
};