import cssUtil from 'gw-portals-util-js/CssUtil';

import listTemplate from 'text!./List.html';
import listStyles from './List-hashed.scss';

export default [() => ({
    restrict: 'E',
    template: cssUtil.hashTemplate(listTemplate, listStyles),
    scope: {
        schedule: '&'
    }
})];