import typeaheadMatchContentDefaultTemplate from 'text!./Default.html';

export default [() => ({
    restrict: 'E',
    template: typeaheadMatchContentDefaultTemplate,
    scope: {
        index: '=',
        match: '=',
        query: '='
    }
})];