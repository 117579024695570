import templateStr from 'text!./FacebookDirective.html';

const fn = FacebookService => ({
    restrict: 'E',
    template: templateStr,
    link: ($scope, $element) => {
        FacebookService.getClient()
            .then(fbClient => {
                fbClient.XFBML.parse($element[0]); // Updates the dynamically inserted fb-related markup
            });
    }
});
fn.$inject = ['FacebookService'];

export default fn;