import templateStr from 'text!edge/platform/widgets/datetimepicker/directives/templates/datetimepicker.html';
import moment from 'moment';
import _ from 'lodash';

// @const
const ESC_KEY = 27;

// @const
const ENTER_KEY = 13;

let _uniqueId = 1;

/**
 * Provides filter value for date view
 * DATE | date:dateFormat
 *
 * @param {string} minView
 * @returns {string}
 */
function getDateFormat(minView) {
    const validViews = ['minute', 'hour', 'day', 'month', 'year'];
    if (validViews.indexOf(minView) === -1) {
        throw new Error(`Minimal view has invalid value: ${minView}`);
    }

    switch (minView) {
        case 'day':
            return 'shortDate';
        default:
            return 'short';
    }
}

/**
 * A map for date format normalization.
 * Those format parts that are
 * in the list, the format should not occur in short and shortDate
 * formats
 */
const dateFormatPartsMap = {
    yyyy: 'yyyy',
    yy: 'yyyy',
    y: 'yyyy',
    MMMM: 'MM',
    MMM: 'MM',
    MM: 'MM',
    M: 'MM',
    dd: 'dd',
    d: 'dd',
    HH: 'HH',
    H: 'HH',
    hh: 'hh',
    h: 'hh',
    mm: 'mm',
    m: 'mm',
    a: 'a'
};

const dateFormatLocalizationTokens = {
    Y: 'platform.datepicker.format.token.Year',
    y: 'platform.datepicker.format.token.Year',
    M: 'platform.datepicker.format.token.Month',
    D: 'platform.datepicker.format.token.Day',
    d: 'platform.datepicker.format.token.Day',
    H: 'platform.datepicker.format.token.Hour',
    h: 'platform.datepicker.format.token.Hour',
    m: 'platform.datepicker.format.token.Minute'
};

const formattingTokens = /(yyyy|yy?|M{1,4}|dd?|HH?|hh?|mm?|a|.)/g;
const CHAR_RE = /./g;

/**
 *  @class TransormedFormatInfo
 *
 *  @property {String} format A new format with padded parts
 *  @property {String} placeholder A placeholder for a new format
 *  @property {Array.<String>}  parts An array of matched parts
 *  @property {String} am AM localization stored in placeholder
 */

/**
 *  Takes an Angular.js date format and transforms it to analogous format with
 *  padded values
 *  @param {String} format Angular.js date format
 *  @param {Object} locale
 *  @param {Object} TranslateService
 *  @returns {Object} new format and placehoder
 */
function transformDateFormat(format, locale, TranslateService) {
    const AM = locale.DATETIME_FORMATS.AMPMS[0];
    format = locale.DATETIME_FORMATS[format];
    return format
        .match(formattingTokens)
        .map((part) => {
            const matchedPart = dateFormatPartsMap[part];
            let res;
            if (matchedPart) {
                let placeholder = AM;
                if (matchedPart !== 'a') {
                    placeholder = matchedPart
                        .replace(CHAR_RE, char => TranslateService.instant(dateFormatLocalizationTokens[char]));
                }

                res = {
                    // add the part to the format string
                    part: matchedPart,
                    // will be AM, else, leave the part intact
                    placeholder: placeholder
                };
            } else {
                // leave all other text as is
                res = part;
            }
            return res;
        })
        .reduce((acc, val) => {
            // combine the format with padded parts
            // along with combining created mask and placeholder
            // to strings
            let part;
            let placeholder;
            if (typeof val !== 'string') {
                if (val.part === 'a') {
                    acc.am = val.placeholder;
                }
                part = val.part;
                placeholder = val.placeholder;
                acc.parts.push(part);
            } else {
                // if val was not a part of the pattern,
                // just add it up to all the properties
                part = val;
                placeholder = val;
            }
            acc.format += part;
            acc.placeholder += placeholder;
            return acc;
        }, /* initial object */ {
            format: '',
            placeholder: '',
            parts: [],
            am: null
        });
}

/**
 * Groups some format-related information in one place.
 *
 * @param {String} minView
 * @param {Object} TranslateService
 */
function FormatInfo(minView, TranslateService) {
    this._formatName = getDateFormat(minView);
    this._translateService = TranslateService;
}

/**
 * Calculate format-related information based on the locale
 * and add related info to instance
 * @param {Object} locale
 */
FormatInfo.prototype.updateLocale = function (locale) {
    const transormedFormatInfo = transformDateFormat(this._formatName, locale, this._translateService);
    this.dateFormat = transormedFormatInfo.format;
    this.parts = transormedFormatInfo.parts;
    this.am = transormedFormatInfo.am;
    this.placeholder = transormedFormatInfo.placeholder;
};


/**
 * Parses a date with padded format parts
 *
 * @param {String} format
 * @param {String} date
 * @returns {Date}
 */
function parseDate(format, date) {
    // will reuse moment's robust parser
    // convert angular format to moment's format
    format = format
        .replace(/yyyy/, 'YYYY')
        .replace(/dd/, 'DD');
    const d = moment(date, format, true);
    return d.isValid() ? d.toDate() : null;
}

export default ['$locale', '$filter', 'TranslateService', '$translate', '$q', ($locale, $filter, TranslateService, $translate, $q) => {
    // LocaleService is loaded to set a correct locale even when only Datepicker is only present on a page (e.g. Live Style Guide)
    return {
        restrict: 'EA',
        template: templateStr,
        require: ['ngModel'],
        scope: {
            disabled: '=',
            maxDate: '=',
            minDate: '=',
            defaultDate: '=?',
            open: '=?',
            onSetTime: '&',
            ngModel: '='
        },

        compile: (element, attrs) => {
            // INPUT ATTRIBUTES
            const input = element.find('input:text');


            if (attrs.inputId) {
                input.attr('id', attrs.inputId);
            }
            if (attrs.inputName) {
                input.attr('name', attrs.inputName);
            }

            return {
                pre: ($scope, _element, _attrs, ctrls) => {
                    const dropdownToggle = _element.find('.gw-dropdown-toggle');
                    const datetimepickerWrapper = _element
                        .find('.datetimepicker-wrapper');
                    $(document).on('keydown', onKeydown);

                    function onKeydown(evt) {
                        if (evt.keyCode === ESC_KEY) {
                            toggleDropdown();
                        }
                    }

                    function toggleDropdown() {
                        dropdownToggle.click();
                    }

                    /**
                     * Uses jQuery to find whether a dropdown is open,
                     * since the current implementation of
                     * dropdown doesn't provide a sufficient API
                     *
                     * @returns {Boolean}
                     */
                    function dropdownIsOpen() {
                        return datetimepickerWrapper.hasClass('open');
                    }

                    $scope.open = dropdownIsOpen();
                    $scope.$watch('open', (newVal, oldVal) => {
                        if (newVal !== oldVal) {
                            if ((newVal && !dropdownIsOpen()) ||
                                (!newVal && dropdownIsOpen())
                            ) {
                                toggleDropdown();
                            }
                            $scope.open = newVal;
                        }
                    });

                    const ngModelCtrl = ctrls[0];
                    $scope.keydown = onKeydown;
                    $scope.uniqueId = `datepicker${_uniqueId++}`; // needed for linking to dropdown
                    $scope.minView = _attrs.minView || 'hour';

                    // handle input's  placeholder and create
                    // a new date format with padded values from locale
                    $scope.fmt = new FormatInfo($scope.minView, TranslateService);

                    const updateLocale = () => {
                        const placeholderLocKeys = _.values(dateFormatLocalizationTokens);
                        placeholderLocKeys.forEach(locKey => TranslateService.instant(locKey)); // Fire up KeyTranslators

                        $q.all(placeholderLocKeys.map(locKey => $translate(locKey))) // Wait until translations are ready
                            .then(() => {
                                $scope.fmt.updateLocale($locale); // Update locale when instant translations are ready.
                            });
                    };
                    updateLocale();

                    // handle direct user input
                    let rawVal = '';
                    Object.defineProperty($scope, 'inputVal', {
                        get: () => {
                            if ($scope.ngModel && !rawVal.length) {
                                return $filter('date')(
                                    $scope.ngModel,
                                    $scope.fmt.dateFormat
                                );
                            }
                            return rawVal;
                        },
                        set: (val) => {
                            if (val === '') {
                                rawVal = '';
                                $scope.ngModel = null;
                                return;
                            }
                            if ($scope.fmt.placeholder.length === val.length) {
                                const d = parseDate($scope.fmt.dateFormat, val);
                                if (d) {
                                    rawVal = '';
                                    $scope.ngModel = d;
                                    return;
                                }
                            }
                            $scope.ngModel = null;
                            rawVal = val;
                        }
                    });

                    $scope.$root.$on('$localeChangeSuccess', updateLocale);

                    $scope.$watch('ngModel', (newVal, oldVal) => {
                        if (oldVal !== newVal) {
                            ngModelCtrl.$setDirty();
                            if (angular.isDate(newVal)) {
                                rawVal = '';
                            }
                        }
                    });


                    // prevents user from closing a dropdown
                    // when he clicks multiple times on input
                    // if he editing the date
                    const _input = _element.find('input');
                    $scope.focus = false;
                    _input.on('focus', () => {
                        $scope.$apply(() => {
                            $scope.focus = 'focus';
                        });
                    });
                    _input.on('blur', () => {
                        $scope.$apply(() => {
                            $scope.focus = false;
                        });
                    });

                    // if user pressed enter key
                    // try to commit date that he added
                    // if is not a valid date,
                    // just close the dropdown
                    _input.on('keypress', (evt) => {
                        if (evt.keyCode === ENTER_KEY) {
                            evt.stopPropagation();
                            evt.preventDefault();
                            $(this).blur();
                            toggleDropdown();
                            const d = parseDate(
                                $scope.fmt.dateFormat,
                                $(this).val()
                            );
                            if (d) {
                                rawVal = '';
                                $scope.ngModel = d;
                            }
                        }
                    });

                    $scope.uniqueId = `datepicker${_uniqueId++}`; // needed for linking to dropdown
                    $scope.startView = _attrs.startView || 'day';

                    $scope.$watch('disabled', (oldVal, newVal) => {
                        if (oldVal === newVal) {
                            return;
                        }
                        if (newVal) {
                            // disable datetimepicker
                            $(`#${$scope.uniqueId}`).dropdown('toggle'); // close datetimepicker
                        }
                    });

                    $scope._onSetTime = (newValue) => {
                        ngModelCtrl.$setViewValue(newValue);
                        $scope.onSetTime();
                    };
                },
                post: (scope, el) => {
                    const stoppropagation = e => e.stopPropagation();
                    el.on('click', stoppropagation);
                    scope.$on('$destroy', () => el.off('click', stoppropagation));
                }
            };
        }
    };
}];