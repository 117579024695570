/**
 * Parse DTOs into groups
 * @param {Object} dtos
 * @returns {Array}
 */
function parseDTOs(dtos) {
    if (!dtos) return [];

    const groups = [];
    Object.keys(dtos).forEach((key)=>{
        groups.push(...extractFieldsetsFromDto(dtos[key]));
    });
    return groups;
}

/**
 * Parses dto to fieldset groups
 * @param {Object} dto
 * @returns {Array}
 */
function extractFieldsetsFromDto(dto) {

    const inputs = Object.keys(dto).map((key) => {
        return Object.assign({}, dto[key], {viewModelName: key});
    });

    // Aggregate the inputs by their group
    const groups = {};
    inputs.forEach(input => {
        if (!groups[input.group]) {
            groups[input.group] = [];
        }
        groups[input.group].push(input);
    });

    return Object.keys(groups).map((name) => {
        return {
            title: name,
            inputs: groups[name].sort((a, b) => a.order - b.order)
        };
    });
}

export default (dtos) =>{
    return parseDTOs(dtos);
};