import _ from 'lodash';
import EventHub from 'gw-portals-tracking-js';

export default () => {
    const payloadTransformers = [];
    const registry = {};
    const transformers = [];

    /**
     * A service allowing to wire various event adapters (such as Google Analytics or Segment.IO)
     * to events generated by the application using either a configuration
     * object or a simple DSL during the Angular's configure phase
     */
    const eventAdapter = {
        /**
         * Expects a configuration object in form of:
         *
         * {
         *   serviceName*:
         *     {
         *       handler*: {
         *         category: String,
         *         eventName: String,
         *         filter: Function,
         *        }
         *     }
         * }
         *
         * there may be many adapters, and every adapter can have multiple
         * handlers,
         *
         * all arguments to handler are optional, in case of an empty handler,
         * the adapter will subscribe to all events and categories without
         *
         * @param {Object} config
         * @returns {Object}
         */
        configure(config) {
            _.forEach(config, (handlers, serviceName) => {
                registry[serviceName] = registry[serviceName] || {};
                Object.assign(registry[serviceName], handlers);
            });
            return eventAdapter;
        },

        /*
         * Registers a handler for adapter optionally filtered by category,
         * eventName and a custom filter function
         */

        register({
            handler, category, eventName, filter, serviceName
        }) {
            if (!_.isString(serviceName) || !_.isString(handler)) {
                throw new Error(`Either handler or serviceName are not strings. handler: ${handler}, serviceName: ${serviceName}`);
            }
            eventAdapter.configure({
                [serviceName]: {
                    [handler]: {
                        filter,
                        category: category || '*',
                        eventName: eventName || '*'
                    }
                }
            });
            return eventAdapter;
        },
        transform(transformFn) {
            console.log('DEPRECATED - Please use registerFunctionalHandler instead!');
            payloadTransformers.push(transformFn);
        },
        registerTransformer({source, target, transformer}) {
            transformers.push({source, target, transformer});
        },
        $get: ['$injector', 'applicationFeatures', ($injector, applicationFeatures) => {
            const hub = new EventHub();
            hub.eventTransformers = payloadTransformers;

            function loadAdapter(adapterName) {
                try {
                    return $injector.get(adapterName);
                } catch (error) {
                    if (error.message.match('Unknown provider')) {
                        throw new Error(`Event provider for '${adapterName}' is not registered.` +
                            ` Add a service called '${adapterName}'` +
                            ' before configuring tracking');
                    }
                    throw error;
                }
            }

            function wireAdapter(adapterConf, serviceName) {
                function getAdapterName(nameOfService) {
                    return (applicationFeatures.tracking || {})[nameOfService];
                }

                function isEnabled(nameOfService) {
                    return !!(getAdapterName(nameOfService));
                }

                if (!isEnabled(serviceName)) return;
                _.forEach(adapterConf, ({
                    filter, category, eventName
                }, handlerName) => {
                    const adapter = loadAdapter(getAdapterName(serviceName));

                    function consumer(event) {
                        if (filter && !filter(event)) return;
                        adapter[handlerName](event);
                    }

                    hub.subscribe(category, eventName, consumer);
                });
            }

            _.forEach(registry, wireAdapter);

            function wireTransformer({source, target, transformer}) {
                function publishToHub(payload) {
                    hub.publish(target.category, target.eventName, payload);
                }

                hub.subscribe(source.category, source.eventName, event => {
                    transformer(event.payload, publishToHub);
                });
            }

            transformers.forEach(wireTransformer);

            return hub;
        }]
    };
    return eventAdapter;
};