import cssUtil from 'gw-portals-util-js/CssUtil';

import styles from './TextInput-hashed.scss';
import template from 'text!./TextInput.html';

const INPUT_CONTROLLER_CLASS = 'js-input-controller';
const INPUT_VIEW_CLASS = 'js-input-view';

/*

 This is a complex input component.

 Attributes:
 model: '='              Variable that receives user input.

 placeholder: '@'        Placeholder text to render.

 autogrow (attribute)    When this attribute exists, a textarea is rendered instead and it's size is adjusted based on content. Note: attribute value is not checked.
 adjacentBottom: '<'     Remove bottom border-radiuses if there's stuff adjacent from below (say, typeahead dropdown).

 Two transclusion slots are supported:
 Use <gw-text-input-left> to transclude content into left side of the input,
 Use <gw-text-input-right> for translude into right side of the input.

 */
export default ['$rootScope', ($rootScope) => ({
    restrict: 'E',
    transclude: {
        'left': '?gwTextInputLeft',
        'right': '?gwTextInputRight'
    },
    template: cssUtil.hashTemplate(template, styles),
    scope: {
        'model': '=',
        'callback': '&',
        'placeholder': '@',
        'flavour': '@',
        'adjacentBottom': '<'
    },
    link: ($scope, $element, $attrs, $controller, $transclude) => {
        $scope.hasLeft = $transclude.isSlotFilled('left');
        $scope.hasRight = $transclude.isSlotFilled('right');

        $scope.$watch('flavour', () => {
            const flavorClass = `input_${$scope.flavour}`;
            $scope._flavour = flavorClass in styles ? styles[flavorClass] : '';
        });

        $scope._model = {
            get value() {
                return $scope.model;
            },

            set value(val) {
                $scope.model = val;
            }
        };

        $scope.onKeypressed = function ($event) {
            if ('callback' in $attrs) {
                $scope.callback()($event);
            }
        };

        const withInputController = function (cb) {
            return function (...args) {
                const $inputControllerEl = $element.find(`.${INPUT_CONTROLLER_CLASS}`);
                if ($inputControllerEl.length === 0) {
                    return;
                }

                return cb($inputControllerEl, ...args);
            };
        };

        if ('autogrow' in $attrs) {
            // Render the textarea instead of input, and set it's height on every change
            $scope.autogrow = true;

            const resetHeight = withInputController($inputControllerEl => {
                $inputControllerEl.height(1); // Make it small so that scrollHeight measures actual size of the content

                const contentHeight = $inputControllerEl.get(0).scrollHeight;
                if (contentHeight === 0) {
                    return false;
                }

                $inputControllerEl.height(contentHeight);

                const $inputViewEl = $element.find(`.${INPUT_VIEW_CLASS}`);
                $inputViewEl.height(0).height('auto'); // Force reflow

                return true;
            });

            // Updates might be external, not user-initiated, and cause no model changes or events
            // Simple interval is pretty good at handling that without much performance overhead
            let prevVal = null;
            const interval = setInterval(withInputController($inputCtrlEl => {
                const currentVal = $inputCtrlEl.val();
                if (prevVal === currentVal) {
                    return; // Nothing changed, ignore
                }

                const success = resetHeight();
                if (success) {
                    prevVal = currentVal; // If successful — stop resetting height.
                }
            }), 10);
            $scope.$on('$destroy', () => clearInterval(interval));
        }

        if ('placeholder' in $attrs) {
            const setPlaceholder = withInputController(($inputCtrl, placeholderStr) => {
                const current = $scope._placeholder;
                $scope._placeholder = $inputCtrl.val() ? '' : placeholderStr;
                if ($scope._placeholder !== current && !$rootScope.$$phase) {
                    $scope.$digest(); // Apply the change
                }
            });

            // Updates might be external, not user-initiated, and cause no model changes or events
            // Simple interval is pretty good at handling that without much performance overhead
            const interval = setInterval(() => setPlaceholder($scope.placeholder), 10);
            $scope.$on('$destroy', () => clearInterval(interval));
        }
    }
})];
