import templateStr from 'text!./templates/quote-details-box.html';
import BaseCtrl from 'edge/platform/base/BaseCtrl';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            buy: '=',
            quote: '=gwQnbQuoteDetailsBox',
            periodStart: '=',
            periodEnd: '=',
            editCoverageStep: '@?',
            lob: '@'
        },
        template: templateStr,
        controller: ['$scope', 'StateUtils', (scope, StateUtils) => {

            const ctrl = Object.create(BaseCtrl);
            const flowModel = StateUtils.activeDataForState(true).flowModel;

            ctrl.init = function () {
                if (!flowModel) {
                    return;
                }

                scope.paymentPeriod = 'fullTerm';
                scope.buyQuote = () => {
                    const termInMonths = (scope.paymentPeriod === 'fullTerm') ? scope.quote.premium.termMonths : 1;
                    scope.buy(scope.quote, termInMonths);
                };
            };
            /**
             * Method used by wizard ribbon to move between steps.
             */
            ctrl.selectStep = function () {
                StateUtils.jumpToTheStep(scope.editCoverageStep);
            };

            return ctrl.create(scope);
        }]
    };
}];