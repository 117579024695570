import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';

export default ['$filter', ($filter) => {
    return (date, format) => {
        let formatted;
        // This creates a JS date object (ie local date). The angular filter is not concerned with timezone in this invocation so date will remain the same.
        const localDate = LocalDateUtil.toMidnightDate(date);
        if (date) {
            if (!date.hasOwnProperty('year')) {
                console.error('Wrong local date format passed to LocalDateFilter');
            }
            formatted = $filter('date')(
                localDate,
                format
            );
        }
        return formatted;
    };
}];