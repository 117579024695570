import appFeatures from 'effective-features!';

function fetchHeaderAndFooterPresentationMetadata() {
    const headerAndFooterPresentationMetadataURI = appFeatures.headerAndFooterPresentationMetadataURI;
    if (!headerAndFooterPresentationMetadataURI) {
        throw Error('presentationMetadataURI is not defined');
    }

    return fetch(headerAndFooterPresentationMetadataURI)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Unable to retrieve presentation metadata for header and footer.');
            }
            return response.json();
        })
        .then(json => json)
        .catch(console.error);
}

export default {

    'getHeaderMetadata': () => {
        return fetchHeaderAndFooterPresentationMetadata().then(headerAndFooterMetadata => {
            if (headerAndFooterMetadata && headerAndFooterMetadata.header) {
                return headerAndFooterMetadata.header;
            }
        });
    },

    'getFooterMetadata': () => {
        return fetchHeaderAndFooterPresentationMetadata().then(headerAndFooterMetadata => {
            if (headerAndFooterMetadata && headerAndFooterMetadata.footer) {
                return headerAndFooterMetadata.footer;
            }
        });
    }
};