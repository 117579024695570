import _ from 'lodash';

function createDiff(key, baseVal, diffVal) {
    return {
        key,
        baseVal,
        diffVal
    };
}

export default {

    /**
     * Does a deep diff comparison of two objects
     *
     * First Object's keys will be used as a reference. Any additional properties on Object b will not be included
     * in the response array.
     *
     * @param {Object} a - the first object to compare
     * @param {Object} b - the second object to compare
     * @param {Array} excludedProps - names of properties on the objects that should not be compared
     * @returns {Array} the differences in properties in the first object in comparison to the second
     */
    diffDeep(a, b, excludedProps = []) {
        const keys = Object.keys(a);
        const diffVals = [];
        _.each(keys, (k) => {
            if (!excludedProps.includes(k) && a[k] && typeof a[k] !== 'function' && !_.isEqual(a[k], b[k])) {
                if (!b[k]) {
                    diffVals.push(createDiff(k, a[k]));
                } else if (Array.isArray(a[k])) {
                    diffVals.push(...this.diffArray(a[k], b[k], excludedProps, k));
                } else if (typeof a[k] !== 'string' && Object.keys(a[k]).length > 0) {
                    diffVals.push(...this.diffDeep(a[k], b[k], excludedProps));
                } else {
                    diffVals.push(createDiff(k, a[k], b[k]));
                }
            }
        });

        return diffVals;
    },

    /**
     * Does a deep diff comparison of two arrays
     *
     * @param {Array} a - the first array to compare
     * @param {Array} b - the second array to compare
     * @param {Array} excludedProps - names of properties on the array values that should not be compared
     * @param {String} key - optional property of the field name, if array is being compared as an object's property
     * @returns {Array} - the differences in properties in the first array in comparison to the second
     */
    diffArray(a, b, excludedProps, key) {
        
        const diffVals = [];

        for (let i = 0; i < a.length; i++) {
            if (b[i]) {
                diffVals.push(...this.diffDeep(a[i], b[i], excludedProps));
            } else {
                diffVals.push(createDiff(key, a[i]));
            }
        }

        if (b.length > a.length) {
            for (let i = a.length; i < b.length; i++) {
                diffVals.push(createDiff(key, undefined, b[i]));
            }
        }
        
        return diffVals;
    }
};