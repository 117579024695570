import CtrlGroupDirective from './CtrlGroupDirective';

export default function () {
    const controlGroupDirectiveObj = new CtrlGroupDirective();
    const origLink = controlGroupDirectiveObj.link;
    controlGroupDirectiveObj.link = function (scope) {
        origLink.apply(this, arguments);
        scope.inline = true;
    };

    return controlGroupDirectiveObj;
}