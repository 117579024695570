import template from 'text!./pm-quote-motor-excess-protection.html';
import controller from '../PmQuoteAddOnCtrl';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '=',
            coverageCode: '<'
        },
        template,
        controller
    };
}];