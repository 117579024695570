import cssUtil from 'gw-portals-util-js/CssUtil';
import summaryTemplate from 'text!./Summary.html';
import summaryStyles from './Summary-hashed.scss';
import listTemplate from 'text!./List.html';
import listStyles from './List-hashed.scss';
import editTemplate from 'text!./Edit.html';
import editStyles from './Edit-hashed.scss';

const hashedSummaryTemplate = cssUtil.hashTemplate(summaryTemplate, summaryStyles);

const hashedListTemplate = cssUtil.hashTemplate(listTemplate, listStyles);

const hashedEditTemplate = cssUtil.hashTemplate(editTemplate, editStyles);

export default (listScheduledItemDirective, editScheduledItemDirective) => {
    return ['ModalService', '$filter', '$timeout', (ModalService, $filter, $timeout) => ({
        restrict: 'E',
        template: hashedSummaryTemplate,
        scope: {
            schedule: '&',
            loading: '='
        },
        link: $scope => {
            // This directive assumes that even though the schedule on the scope may change due to updates,
            // it is till de-facto same schedule.


            // Loading affects some of the behaviours.
            // Since loading flag is optional and loading might never happen,
            // this utility will handle calling a callback when there is and when there isn't any loading.
            // The expectation here, that if loading flag is working, it's going to change to 'true' and then back to 'false'
            const unwatches = new Map();
            const unwatch = callback => {
                if (unwatches.has(callback)) {
                    unwatches.get(callback)();
                    unwatches.delete(callback);
                }
            };
            const whenDoneLoading = callback => {
                unwatch(callback);

                let timeout;
                unwatches.set(callback, $scope.$watch('loading', loading => {
                    if (typeof loading === 'undefined') {
                        return; // Don't handle a case when loading is undefined
                    }

                    if (loading && timeout) {
                        // We're loading, no need for the timeout
                        $timeout.cancel(timeout);
                    } else if (!loading) {
                        // Now we're done loading and can show a notification
                        unwatch(callback);
                        callback();
                    }
                }));

                timeout = $timeout(() => {
                    // When 'loading' flag isn't supplied fallback to showing trigger
                    unwatch(callback);
                    callback();
                }, 100);
            };
            // Done with 'whenDoneLoading'


            $scope.init = function () {
                const notification = {
                    text: '',
                    trigger: angular.noop
                };

                const notify = text => {
                    notification.text = text;
                    whenDoneLoading(notification.trigger);
                };

                let listModal;
                let listScope;
                const closeList = () => {
                    listModal.close();
                    listScope.$destroy();
                };

                const edit = function (scheduledItem, itemIndex, isNew = false) {
                    scheduledItem.draft();
                    const editScope = $scope.$new(true);
                    let editModal;
                    const closeEdit = () => {
                        editModal.close();
                        editScope.$destroy();
                    };

                    angular.extend(editScope, {
                        editScheduledItemDirective: editScheduledItemDirective,
                        onBeforeCommit: cb => {
                            editScope._onBeforeCommit = cb;
                        },

                        isNew: isNew,
                        schedule: $scope.schedule,
                        scheduledItem: scheduledItem,
                        cancel: function () {
                            scheduledItem.undo();
                            closeEdit();

                            if ($scope.schedule().getScheduledItems().length === 0) {
                                closeList();
                            }
                        },

                        shouldShowErrors: false,
                        save: function () {
                            const beforeCommitValid = typeof editScope._onBeforeCommit === 'function' ? editScope._onBeforeCommit() : true;
                            if (!scheduledItem.isValid() || !beforeCommitValid) {
                                this.shouldShowErrors = true;
                            } else {
                                listScope.focusedIndex = itemIndex;
                                whenDoneLoading(() => {
                                    const list = $('.js-gw-mobile-scheduled-items-list');
                                    const focused = $('.js-gw-mobile-scheduled-items-focused');

                                    // Position of focused element within scrollable list
                                    // is it's position currently relative to visible part of the list + list.scrollTop()
                                    list.scrollTop(focused.offset().top + (list.scrollTop() - list.offset().top));
                                });

                                scheduledItem.commit();
                                if (isNew) {
                                    $scope.schedule().add(scheduledItem);
                                }

                                closeEdit();

                                notify(isNew
                                    ? 'policycommon.directives.schedules.table.Item added'
                                    : 'policycommon.directives.schedules.table.Item saved');
                            }
                        }
                    });

                    editModal = ModalService.showFullScreenPopup(hashedEditTemplate, {}, {
                        scope: editScope,
                        windowClass: editStyles.edit,
                        animationType: 'left-right'
                    });
                };
                const add = function () {
                    // Focusing new item works under the assumption that it would be added as last item of the list
                    edit($scope.schedule().createNewItem(), $scope.schedule().getScheduledItems().length, true);
                };

                listScope = $scope.$new();
                angular.extend(listScope, {
                    listScheduledItemDirective: listScheduledItemDirective,

                    add: add,
                    edit: edit,
                    exit: closeList,

                    'delete': function (scheduledItem) {
                        const translate = $filter('translate');
                        ModalService.showWarning(
                            translate('policycommon.directives.schedules.table.Delete item'),
                            translate('policycommon.directives.schedules.table.Are you sure you want to remove this item?'), null,
                            translate('policycommon.directives.schedules.table.Delete')
                        ).result.then(() => {
                            $scope.schedule().remove(scheduledItem);
                            notify('policycommon.directives.schedules.table.Item deleted');
                        });
                    },
                    notification: notification
                });

                listModal = ModalService.showFullScreenPopup(hashedListTemplate, {}, {
                    scope: listScope,
                    windowClass: listStyles.list,
                    animationType: 'left-right'
                });

                if ($scope.schedule().getScheduledItems().length === 0) {
                    add();
                }
            };
        }
    })];
};
