import templateStr from 'text!./defaultBaseCoverages.html';

export default {
    restrict: 'E',
    bindings: {
        baseCoverageArray: '=',
    },
    template: templateStr,
    controller: function controller() {
    }
};