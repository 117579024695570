/**
 * Filter to provide the entity ID - public ID if already saved, tempID if not saved yet.
 * Helps IDing HTML elements.
 */
export default () => {
    return (prefix, entity) => {
        let id;
        if (entity) {
            if (entity.value) {
                entity = entity.value;
            }
            if (entity.publicID) {
                id = entity.publicID;
            } else if (entity.tempId) {
                id = entity.tempId;
            }
        }

        if (id) {
            return `${prefix}-${id}`;
        }
        return prefix;
    };
};
