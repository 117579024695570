import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './ProductZipCodeContainer-hashed.scss';
import templateStr from 'text!./ProductZipCodeContainer.html';

export default [
    () => {
        return {
            restrict: 'E',
            scope: {
                postalCode: '=',
                startNewQuote: '&',
                retrieveQuote: '&',
                quoteId: '=',
                isRetrieveStateActive: '=',
                activateItemRetrieval: '&',
                deActivateRetrieveState: '&'
            },
            template: cssUtil.hashTemplate(templateStr, styles),
            controller: ['$scope', ($scope) => {
                $scope.startQuote = function (zipCodeForm) {
                    zipCodeForm.submitted = true;
                    if (!$scope.postalCode.aspects.valid) {
                        return;
                    }
                    $scope.startNewQuote();
                };

                $scope.startRetrieveQuote = function (quoteIdForm, zipCodeForm) {
                    quoteIdForm.submitted = true;
                    zipCodeForm.submitted = true;

                    if (!$scope.quoteId.aspects.valid || !$scope.postalCode.aspects.valid) {
                        return;
                    }
                    $scope.retrieveQuote();
                };
            }]
        };
    }
];