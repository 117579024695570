class NavigationCtrl {
    constructor(BaseUtils, $window, $element) {
        this._window = $window;
        this._element = $element;

        this.appWindow = angular.element($window);
        this.navMenu = this._element.find('.gw-main-nav_bar').eq(0);
        this.mainClass = this._element.find('.gw-main-nav_menu').eq(0);
        this.moreClassPath = '.gw-header-more__submenu';
        this.moreClassPathList = `${this.moreClassPath} ul`;
        this.moreClassPathListItem = `${this.moreClassPath} li`;
        this.subMenuHide = '.gw-header-more__submenu__hide';
        this.itemHide = 'gw-header__item__hidden';
        this.numElements = 0;
        this.appWindow.bind('resize', () => {
            BaseUtils.debounce(this.adjustMenu(), 100, false);
        });
    }

    $onInit() {
        this.adjustMenuOnLanding();
    }

    /**
     * Adjust Menu once when links have been rendered
     */
    adjustMenuOnLanding() {
        // wait for $digest completes the cycle
        setTimeout(() => {
            this.adjustMenu();
        }, 700);
    }

    adjustMenu() {
        let menuWidth = 0;

        // Get the width of the more menu button
        const moreWidth = this.mainClass.find(this.moreClassPath).outerWidth(true);

        // Get the width of all the items in the menu
        this.mainClass.find(`> li:not(${this.moreClassPath}) > a:not(${this.subMenuHide})`).each(function () {
            menuWidth += $(this).outerWidth(true);
        });

        // Remove the width of the more menu item
        const availableSpace = this.navMenu.width() - moreWidth;

        // Check if there is not enough space on the menu bar
        if (menuWidth > availableSpace) {
            // Get the last item that we would like to add to the more menu
            const lastItemLinkTag = this.mainClass.find(`> li:not(${this.moreClassPath}) > a:not(${this.subMenuHide})`).last();
            const lastItem = lastItemLinkTag.parent();

            // We add the item to the list
            lastItem.clone(true).prependTo(this.mainClass.find(`${this.moreClassPathList}`));

            // Hide the item in the actual menu bar
            $(lastItemLinkTag).addClass(this.subMenuHide.substring(1));

            this.numElements++;

            // Recall the method to find more items that need to be moved to the more menu
            this.adjustMenu();
        } else if (this.numElements > 0) {
            // Get the last item in the main menu list
            let correspondingItem = this.mainClass.find(`> li:not(${this.moreClassPath})`).last();

            // Get the corresponding hidden item in the menu
            for (let i = 1; i < this.numElements; i++) {
                correspondingItem = correspondingItem.prev();
            }

            correspondingItem = correspondingItem.find('.gw-menu-item-link').eq(0);

            // Get the first more menu item
            const firstMoreItem = this.mainClass.find(`li${this.moreClassPathListItem}`).first();

            // If there is enough space on the menu
            if (menuWidth + correspondingItem.outerWidth(true) < availableSpace) {
                // Remove the hide class from the corresponding item
                $(correspondingItem).removeClass(this.subMenuHide.substring(1));
                // Remove the item from the more menu
                firstMoreItem.remove();
                this.numElements--;
                this.adjustMenu();
            }
        }

        // If there are no item in the more menu
        if ($(`${this.moreClassPathListItem}`).length > 0) {
            // Show the more menu item in the main menu
            $(`${this.moreClassPath}`).removeClass(this.itemHide);
        } else {
            // Hide the more menu item in the main menu
            $(`${this.moreClassPath}`).addClass(this.itemHide);
        }
    }
}

NavigationCtrl.$inject = ['BaseUtils', '$window', '$element'];

export default NavigationCtrl;

