import InputCtrlDirective from './InputCtrlDirective';

export default function () {
    const inputCtrlDirective = new InputCtrlDirective();
    const origLink = inputCtrlDirective.link;
    inputCtrlDirective.link = function (scope) {
        origLink.apply(this, arguments);
        scope.inline = true;
    };

    return inputCtrlDirective;
}