import templateStr from 'text!./templates/custom-quote.html';
import 'edge/quoteandbind/common/app';

export default [function () {
    return {
        restrict: 'E',
        template: templateStr,
        require: 'gwQnbCustomQuote',
        scope: {
            quoteData: '=',
            buy: '=',
            error: '='
        },
        controller: ['$scope', 'qnb.CustomQuoteService', '$q', 'ViewModelService', 'StateUtils',
            function ($scope, CustomQuoteService, $q, ViewModelService, StateUtils) {

                // convert OfferingDTO to CustomQuotedTO structure
                $scope.customQuote = {
                    quote: $scope.quoteData.offeredQuotes[0],
                    coverages: $scope.quoteData.offeredQuotes[0].lobs,
                    quoteID: $scope.quoteData.submission.quoteID.value,
                    sessionUUID: $scope.quoteData.submission.sessionUUID.value,
                    periodStart: $scope.quoteData.submission.baseData.periodStartDate.value,
                    periodEnd: $scope.quoteData.submission.baseData.periodEndDate.value
                };

                $scope.offerings = {
                    standardOffering: $scope.quoteData.baseOffering,
                    customOffering: $scope.quoteData.offeredQuotes[0]
                };


                /**
                 * Method used by wizard ribbon to move between steps.
                 *
                 * @param {Object} editCoverageStep
                 */
                $scope.selectStep = function (editCoverageStep) {
                    StateUtils.jumpToTheStep(editCoverageStep);
                };

                $scope.buyQuote = function () {
                    $scope.buy.apply(null, arguments);
                };

                $scope.buyQuoteWithDefaultParams = () => {
                    const termInMonths = $scope.customQuote.quote.premium.termMonths;
                    $scope.buy($scope.customQuote.quote, termInMonths);
                };

            }
        ]
    };
}];