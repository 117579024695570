export default function () {
    return {
        restrict: 'A',
        require: '^tabset',
        link(scope, elm, attrs) {
            const tab = scope.$eval(attrs.tabContentTransclude);

            // Now our tab is ready to be transcluded: both the tab heading area
            // and the tab content area are loaded.  Transclude 'em both.
            tab.$transcludeFn(tab.$parent, (contents) => {
                angular.forEach(contents, (node) => {
                    if (isTabHeading(node)) {
                        // Let tabHeadingTransclude know.
                        tab.headingElement = node;
                    } else {
                        elm.append(node);
                    }
                });
            });
        }
    };

    function isTabHeading(node) {
        return node.tagName && (
            node.hasAttribute('tab-heading') ||
                node.hasAttribute('data-tab-heading') ||
                node.tagName.toLowerCase() === 'tab-heading' ||
                node.tagName.toLowerCase() === 'data-tab-heading'
        );
    }
}