import EditableCoverages from './directives/editablecoverages/EditableCoverages';
import CoverageTerm from './directives/coverageterm/CoverageTerm';
import ScheduledCoverages from './directives/ScheduleCoverages';
import ReadonlyCoverages from './directives/readonlycoverages/ReadonlyCoverages';
import CoveragesList from './directives/coveragesList/CoveragesList';
import CoveragesUtil from './util/CoveragesUtil';
import './directives/schedules/module';

export default angular.module('edge.policycommon.common.app', ['edge.policycommon.schedules'])
    .directive('gwPcEditableCoverages', EditableCoverages)
    .directive('gwPcCoverageTerm', CoverageTerm)
    .directive('gwPcScheduledCoverages', ScheduledCoverages)
    .directive('gwPcReadonlyCoverages', ReadonlyCoverages)
    .directive('gwPcCoveragesList', CoveragesList)
    .factory('CoveragesUtil', CoveragesUtil);
