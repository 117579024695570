import templateStr from 'text!./mobileCoverages.html';

export default {
    template: templateStr,
    bindings: {
        coverages: '<',
        branchCode: '=',
        offeringId: '<'
    }
};
