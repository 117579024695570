import _ from 'lodash';
import cssUtil from 'gw-portals-util-js/CssUtil';

import template from 'text!./cp-building-coverages-summary-directive.html';
import styles from './BuildingCoveragesSummary-hashed.scss';

export default [() => ({
    restrict: 'E',
    replace: true,
    require: 'gwCpPremisesSummary',
    scope: {
        coverages: '=',
        filterSelected: '<',
        buildingId: '@?'
    },
    transclude: true,
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', '$state', ($scope, $state) => {

        $scope.$watchCollection('coverages', coverages => {
            if (!Array.isArray(coverages)) {
                return;
            }

            $scope._coverages = coverages
                .filter(coverage => !$scope.filterSelected || coverage.selected)
                .map((coverage, idx) => _.assign({}, coverage, {
                    id: coverage.publicID || idx
                }));
        });

        $scope.edit = () => $state.go('cpBuildingWizardEditCoverages', {buildingID: $scope.buildingId});
    }]
})];
