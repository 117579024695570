import template from 'text!./templates/Currency.html';

export default [() => ({
    restrict: 'E',
    scope: {
        item: '&'
    },
    template: template,
    link: $scope => $scope.$watch('item()', item => {
        $scope.applyNewValue = val => item.setValue(val);
        $scope.currency = item.getPropertyInfo().getCurrency();
    })
})];