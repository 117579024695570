import templateStr from 'text!./templates/quote-offering.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            quoteId: '=',
            quote: '=gwQnbQuoteOffering',
            buy: '&'
        }
    };
}];
