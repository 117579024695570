import _ from 'lodash';

import WrapperCtrl from './wrapper/WrapperCtrl';
import WrapperTemplate from 'text!./wrapper/Wrapper.html';

import SelectLocationCtrl from './selectLocation/SelectLocationCtrl';
import SelectLocationTemplate from 'text!./selectLocation/SelectLocation.html';

import BuildingInfoCtrl from './buildingInfo/BuildingInfoCtrl';
import BuildingInfoTemplate from 'text!./buildingInfo/BuildingInfo.html';

import BuildingCoveragesCtrl from './buildingCoverages/BuildingCoveragesCtrl';
import BuildingCoveragesTemplate from 'text!./buildingCoverages/BuildingCoverages.html';

import ConfirmationCtrl from './confirmation/ConfirmationCtrl';
import ConfirmationTemplate from 'text!./confirmation/Confirmation.html';

const stateNamePrefix = 'cpBuildingWizard';

const routes = _({
    // Names here should match step names in ./flow.js
    'SelectLocation': {
        controller: SelectLocationCtrl,
        template: SelectLocationTemplate,
        resolve: {
            originState: ['$state', ($state) => {
                // remember the state we came from so we can return when cancelling
                return {
                    state: $state.current,
                    params: $state.params
                };
            }]
        }
    },
    'BuildingInfo': {
        controller: BuildingInfoCtrl,
        template: BuildingInfoTemplate
    },
    'BuildingCoverages': {
        controller: BuildingCoveragesCtrl,
        template: BuildingCoveragesTemplate
    },
    'Confirmation': {
        controller: ConfirmationCtrl,
        template: ConfirmationTemplate
    },
    'Fin': {
        controller: ['$state', ($state) => {
            const origin = $state.get(stateNamePrefix).data.originState; // originState set in SelectLocationCtrl (first step of the wizard)
            if (origin && !routes.hasOwnProperty(origin.state.name) && _.isNull(origin.params.callbackState)) {
                $state.go(origin.state, origin.params);
            } else {
                const params = _.isNull(origin.params.callbackState) ? null : origin.params;
                $state.go('cpBuildingWizardInitialScreen', params);
            }
        }]
    }
})
    .mapKeys((val, key) => stateNamePrefix + key)
    .mapValues(val => _.assign({}, val, {
        parent: stateNamePrefix, // Wrapper state named the same
        params: {
            flow: null // Make 'flow' an accepted param
        }
    }))
    .valueOf();

_.assign(routes, {
    [stateNamePrefix]: {
        parent: 'cpLocationsAndBuildings',
        controller: WrapperCtrl,
        template: WrapperTemplate,
        params: {
            flow: null,
            model: null
        }
    }
});

export {
    routes as default,
    stateNamePrefix,
    stateNamePrefix as wizardParentState
};
