import _ from 'underscore';
/**
 * Function to check if a direct transition to another step is allowed
 *
 * @param {Object} flowModel - the ordered set of steps in the flow
 * @param {Object} currentStep - the current step in the flow
 * @param {Object} targetStep
 * @returns {Boolean}
 */
export default function checkIfTransitionAllowed(flowModel, currentStep, targetStep) {
    const currentStepIndex = _.findIndex(flowModel.steps, {
        name: currentStep.name
    });
    const targetStepIndex = _.findIndex(flowModel.steps, {
        name: targetStep.name
    });
    // only allow going back in the wizard
    return currentStepIndex >= targetStepIndex;
}
