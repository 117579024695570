/*
 * Angular bootstrap dropdown directive is used as basis
 * The biggest difference- append dropdown menu to content to prevent overflow: hidden; etc.
 *
 * dropdownToggle - Provides dropdown menu functionality in place of bootstrap js
 * @restrict class or attribute
 * @example:
 *    <li class="gw-pl-dropdown-wrapper">
 *        <a class="gw-pl-dropdown-toggle"
 *          [closest-element-to-listen]
 *          [event-to-listen]
 *          >My Dropdown Menu</a>
 *        <div class="gw-pl-dropdown-menu-wrapper">
 *          <ul class="dropdown-menu">
 *              <li ng-repeat="choice in dropChoices">
 *                  <a ng-href="{{choice.href}}">{{choice.text}}</a>
 *              </li>
 *          </ul>
 *        </div>
 *    </li>
 *
 * Can use flavor="mobile" to get dropdown positioned over target.
 * Can use .gw-dropdown-menu_noarrow to get rid of arrow.
 */

const CYCLE_LIMIT = 10; // Max iterations refreshDropdownPosition is allowed to do to make position more precise
export default ['$document', ($document) => {
    let openElement = null;
    let closeMenu = angular.noop;

    return {
        restrict: 'A',
        link: (scope, element, attrs) => {
            // VARIABLES
            const $window = $(window);
            const $snapContainer = element.closest('.gw-snap-content-inner');
            const $elToAppendMenu = $snapContainer.length > 0 ? $snapContainer : element.closest('.gw-modal');

            const $dropdownWrapper = element.parent();
            const $dropdownMenuWrapper = $dropdownWrapper.find(attrs.gwPlDropdown || '.gw-pl-dropdown-menu-wrapper');
            const $dropdownMenu = $dropdownMenuWrapper.find('.gw-dropdown-menu');
            // elements for which "open" class would be added
            const $openClassEls = $dropdownWrapper.add($dropdownMenuWrapper);

            let $closestElementToListen = $();
            let eventToListen;
            if (attrs.closestElementToListen) {
                $closestElementToListen = element.closest(attrs.closestElementToListen);
                eventToListen = attrs.eventToListen;
            }

            // -----
            // ANGULAR BOOTSTRAP DROPDOWN DIRECTIVE CODE
            // -----
            scope.$watch('$location.path', () => {
                closeMenu();
            });
            $dropdownWrapper.on('click', () => {
                closeMenu();
            });
            element.on('click', (event) => {
                const elementWasOpen = (element === openElement);

                event.preventDefault();
                event.stopPropagation();

                if (openElement) {
                    closeMenu();
                }

                if (!elementWasOpen && !element.hasClass('gw-disabled') && !element.prop('disabled')) {
                    $openClassEls.addClass('gw-open');
                    openElement = element;
                    closeMenu = () => {
                        // if (event) {
                        //    event.preventDefault();
                        //    event.stopPropagation();
                        // }

                        $document.off('click', closeMenu);

                        // additional code starts
                        $window.off('resize', closeMenu);
                        $closestElementToListen.off(eventToListen, closeMenu);
                        // additional code ends

                        $openClassEls.removeClass('gw-open');
                        closeMenu = angular.noop;
                        openElement = null;

                        if (typeof scope._dropdownOnClose === 'function') {
                            scope._dropdownOnClose();
                        }
                    };

                    $document.on('click', closeMenu);

                    // additional code starts
                    $window.on('resize', closeMenu);
                    refreshDropdownPosition();
                    $closestElementToListen.on(eventToListen, closeMenu);
                    $dropdownMenuWrapper.appendTo($elToAppendMenu);
                    // additional code ends

                    if (typeof scope._dropdownOnOpen === 'function') {
                        scope._dropdownOnOpen();
                    }
                }
            });

            // -----
            // ADDITIONAL CODE
            // -----
            $dropdownMenuWrapper
                .detach(); // detach element to prevent page size change on appearing

            function refreshDropdownPosition(cycle = 0) {
                const elToAppendMenuOffset = $elToAppendMenu.offset();
                const dropdownWrapperOffset = $dropdownWrapper.offset();

                const wrapperHeight = $dropdownWrapper.height();
                let top = dropdownWrapperOffset.top - elToAppendMenuOffset.top;
                if (attrs.flavor === 'mobile') {
                    // This positions a dropdown over a dropdown-trigger (use with gw-dropdown-menu_noarrow)
                    const elementWasOpen = (element === openElement);
                    const menuHeight = $dropdownMenu.height();
                    if (elementWasOpen && menuHeight) {
                        top -= (menuHeight + wrapperHeight) / 2;
                    } else if (cycle < CYCLE_LIMIT) {
                        scope.$applyAsync(() => refreshDropdownPosition(cycle + 1));
                    } else {
                        throw new Error(`Could not properly determine dropdown position after ${CYCLE_LIMIT} iterations`);
                    }
                }

                $dropdownMenuWrapper.css({
                    width: $dropdownWrapper.width(),
                    height: wrapperHeight,
                    top: top,
                    left: dropdownWrapperOffset.left - elToAppendMenuOffset.left
                }).appendTo($elToAppendMenu);
            }
        }
    };
}];
