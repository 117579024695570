import AddressDetails from './AddressDetails';
import AddressSummary from './AddressSummary';
import NameDetails from './NameDetails';
import CompanyNameDetails from './CompanyNameDetails';
import UiMaskDirective from './UiMaskDirective';
import InputMaskGroup from './inputMaskGroup';
import ClampTextDirective from './ClampTextDirective';
import DateDropDownDirective from './DateDropDownDirective';
import AlertDirective from './AlertDirective';
import BinaryChoice from './BinaryChoice';
import BinaryChoiceGroup from './BinaryChoiceGroup';
import YesNo from './YesNo';
import YesNoGroup from './YesNoGroup';
import Stepper from './stepper/Stepper';
import StepperSelect from './stepper/StepperSelect';
import StepperSelectGroup from './stepper/StepperSelectGroup';
import StepperInput from './stepper/StepperInput';
import PhoneDetails from './PhoneDetails';
import Currency from './Currency';
import TextInput from './textinput/TextInput';
import NestedButton from './input-inline-button/input-inline-button';
import 'portalConfig';

export default angular.module('edge.platform.widgets.inputs', ['portal.config'])
    .value('uiMaskConfig', {
        maskDefinitions: {
            '9': /\d/,
            'A': /[a-zA-Z]/,
            '*': /[a-zA-Z0-9]/
        },
        clearOnBlur: false,
        clearOnBlurPlaceholder: false,
        eventsToHandle: ['input', 'keyup', 'click', 'focus'],
        addDefaultPlaceholder: true
    })
    .directive('gwPlAddressDetails', AddressDetails)
    .directive('gwPlAddressSummary', AddressSummary)
    .directive('gwPlNameDetails', NameDetails)
    .directive('gwPlCompanyNameDetails', CompanyNameDetails)
    .directive('gwPlUiMask', UiMaskDirective)
    .directive('gwPlInputMaskGroup', () => new InputMaskGroup())
    .directive('gwPlClampText', ClampTextDirective)
    .directive('gwPlDateDropDown', DateDropDownDirective)
    .directive('gwPlAlert', AlertDirective)
    .directive('gwPlBinaryChoice', BinaryChoice)
    .directive('gwPlBinaryChoiceGroup', BinaryChoiceGroup)
    .directive('gwPlYesNo', YesNo)
    .directive('gwPlYesNoGroup', YesNoGroup)
    .directive('gwPlStepperCtrl', Stepper)
    .directive('gwPlStepperSelect', StepperSelect)
    .directive('gwPlStepperSelectGroup', () => new StepperSelectGroup())
    .directive('gwPlStepperInput', StepperInput)
    .directive('gwPlPhoneDetails', PhoneDetails)
    .directive('gwCurrencyInput', Currency)
    .directive('gwTextInput', TextInput)
    .directive('gwNestedButtonWrapper', NestedButton);