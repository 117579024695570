import templateStr from 'text!./AdditionalCoverages.html';

export default {
    restrict: 'E',
    bindings: {
        lobCode: '&',
        coverageData: '<',
        schedules: '<'
    },
    template: templateStr,
    controller: function controller() {
    }
};