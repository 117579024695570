import templateStr from 'text!./pm-cookie-banner.html';

export default [() => {
    return {
        template: templateStr,
        scope: {
            
        },
        controller: [
            '$cookies', '$scope','brandingData','StaticDocumentsService_AND', '$q',
            function ($cookies, $scope, brandingData, StaticDocumentsService_AND, $q) {
                // VARS
                const $ctrl = this;
                $scope.showSettings = false
                $scope.brand = brandingData;

                $q.all([
                  StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY),
                  
                ]).then((result) => {
                    $scope.privacyPolicyDoc = result[0];
                });
                
                var _consent = $cookies.get('consent');
                if ($scope.brand !== 'tbb'){
                  if (_consent !== undefined){
                    $scope.showCookieBanner = false
                  } else {
                    $scope.showCookieBanner = true
                    document.addEventListener("DOMContentLoaded", function(event) { 
                      document.getElementById("continuebutton").style.display = "none";
                      document.getElementById("pmContactDetails").style.pointerEvents = "none";
                    });
                   }
                }
   
   
               $scope.setConsent = function () {
                  $cookies.put('consent', true);
                  $scope.showCookieBanner = false
                  $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 1);
                  $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 1);
                  $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 1);
                  $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 1);
                  this.enablePageFunctionality();
               }

               $scope.enablePageFunctionality = function () {
                  document.getElementById("pmContactDetails").style.pointerEvents = "auto";
                  document.getElementById("continuebutton").style.display = "block";
               }

               $scope.showSettings = function () {
                if ($scope.cookieExtras){
                  $scope.cookieExtras = false
                } else {
                  $scope.cookieExtras = true
                }
                  document.documentElement.scrollTop = 0;
                  document.getElementById("continuebutton").style.display = "none";
                }

              $scope.dropCookies = function () {
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 0);
                $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 0);
                $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 0);
                $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 0);
                $scope.showCookieBanner = false
                this.enablePageFunctionality();
              }

              $scope.save = function () {
                $scope.strictCookies = document.getElementById('strictlySwitch').checked
                $scope.function = document.getElementById('functionSwitch').checked
                $scope.analytics = document.getElementById('analyticsSwitch').checked
                $scope.advert = document.getElementById('advertSwitch').checked
                this.enablePageFunctionality();
                

                if ($scope.strictCookies === true){
                  $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 1);
                } else {
                  $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 0);
                }

                if ($scope.function === true){
                  $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 1);
                } else {
                  $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 0);
                }

                if ($scope.analytics === true){
                  $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 1);
                } else {
                  $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 0);
                }

                if ($scope.advert === true){
                  $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 1);
                } else {
                  $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 0);
                }

                if ($scope.strictCookies === true && $scope.function === true && $scope.analytics === true && $scope.advert === true){
                  $cookies.put('consent', true);
                }
                if ($scope.strictCookies === false && $scope.function === false && $scope.analytics === false && $scope.advert === false){
                  $scope.dropCookies()
                  }
                if ($scope.strictCookies === true || $scope.function === true || $scope.analytics === true || $scope.advert === true){
                  $cookies.put('consent', true)
                }

                $scope.cookieExtras = false;
                $scope.showCookieBanner = false;
              }
            }
        ]
    };
}];