import NavBarWizardBaseCtrl from 'edge/platform/widgets/wizard/NavBarWizardBaseCtrl';

export default ['$scope', '$element', 'StateUtils', '$rootScope', '$state', '$location', // ANDIE
    function ($scope, $element, StateUtils, $rootScope, $state, $location) {

        const baseNavbarCtrl = new NavBarWizardBaseCtrl($scope, $element);
        const ctrl = Object.create(baseNavbarCtrl);

        ctrl.init = () => {
            $scope.headerMessageKey = '';
            $scope.isContextPageMenuOpen = false;
            $scope.showContactUs = false; // ANDIE
            $rootScope.$on('stickyHeaderExitViewport', ctrl.closeContextPageMenu);
            $scope.$on('updateHeaderMessageKey', (event, messageKey) => {
                $scope.updateHeaderMessageKey(messageKey);
            });

            StateUtils.beforeStateInit(ctrl._setQuoteSummaryData);
            StateUtils.afterStateInit(ctrl._checkIfSideMenuShouldBeClosed);

            // ANDIE
            $scope.faqSearchQuery = '';
        };

        /* --- Context menu methods --- */
        ctrl.toggleContextPageMenu = function () {
            $scope.isContextPageMenuOpen = !$scope.isContextPageMenuOpen;
        };

        ctrl.closeContextPageMenu = function () {
            $scope.isContextPageMenuOpen = false;
        };

        /* --- Header message --- */
        $scope.updateHeaderMessageKey = (messageKey) => {
            $scope.headerMessageKey = messageKey;
        };

        $scope.shouldShowHeaderMessage = () => {
            return $state.$current.name === 'quickQuote' && angular.isDefined($scope.headerMessageKey);
        };

        /* --- Quote summary dropdown methods --- */
        ctrl._setQuoteSummaryData = function () {
            if (StateUtils.hasState('baseTransactionWizard')) {
                $scope.submission = StateUtils.dataForState('baseTransactionWizard').flowModel.snapshot.value;
            } else {
                $scope.submission = null;
            }
        };

        ctrl._isQuoteWizardVisible = function () {
            return StateUtils.hasState('baseTransactionWizard');
        };

        ctrl._checkIfSideMenuShouldBeClosed = function () {
            // ANDIE - This is not applicable for andie current design
            /* if (!ctrl._isQuoteWizardVisible() && ctrl.isSideBarMenuVisible()) {
                ctrl.hideSideBarMenu();
            }*/
        };

        /* ANDIE - Toggle Contact Us information for mobile breakpoints*/
        $scope.toggleContactUsDisplay = () => {
            $scope.showContactUs = !$scope.showContactUs;
        };
        
        /**
         * ANDIE Go to Tesco FAQ page
         */
        $scope.goToFAQ = function () {
            const faqURL = `https://yourcommunity.tescobank.com/t5/forums/searchpage?q=${$scope.faqSearchQuery}&search_type=thread&filter=labels,includeTkbs&include_tkbs=true`;
            window.open(faqURL);
        };

        return ctrl.create($scope);
    }
];
