import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './AvailableProductsAndFeatures-hashed.scss';
import templateStr from 'text!./AvailableProductsAndFeatures.html';
import ProductMediaMapUtil from 'gw-portals-util-js/ProductMediaMapUtil';

export default [
    () => {
        return {
            restrict: 'E',
            scope: {
                productOrFeature: '=',
                prepopulatedModel: '=',
                isRetrieveStateActive: '=',
                activateItem: '&',
                activateRetrieveState: '&',
                deActivateRetrieveState: '&'
            },
            template: cssUtil.hashTemplate(templateStr, styles),
            controller: ['$filter', '$scope', 'ViewModelService', '$state', ($filter, $scope, ViewModelService, $state) => {

                $scope.quoteId = {
                    aspects: {
                        required: true,
                        inputCtrlType: 'text',
                        get valid() {
                            if ($scope.quoteId.value === undefined || $scope.quoteId.value === '') {
                                return false;
                            }
                            return true;
                        },
                        get validationMessages() {
                            return [$filter('translate')('quoteandbind.directives.AvailableProductsAndFeatures.Please enter a quote ID')];
                        }
                    }
                };

                const applicantDetails = {
                    effectiveDate: new Date(),
                    postalCode: '',
                    account: undefined,
                    productCode: undefined
                };

                function updateCurrentModelWithPreopulatedModel() {
                    $scope.view.value.account = $scope.prepopulatedModel.value.account;
                }

                $scope.view = ViewModelService.create(applicantDetails, 'pc', 'edge.capabilities.policycommon.availability.dto.PostalCodeAvailabilityRequestDTO');
                $scope.productOrFeatureImage = ProductMediaMapUtil.getProductCodeImage($scope.productOrFeature.productCode);


                $scope.panelClicked = () => {
                    $scope.activateItem();
                    if ($scope.prepopulatedModel.value.postalCode !== '') {
                        $scope.view.value.postalCode = $scope.prepopulatedModel.value.postalCode;
                    }
                };

                $scope.startNewQuote = () => {
                    $scope.view.productCode.value = $scope.productOrFeature.productCode;
                    if ($scope.prepopulatedModel.value.account !== undefined) {
                        updateCurrentModelWithPreopulatedModel();
                    }
                    if (!$scope.view.aspects.subtreeValid) {
                        return;
                    }
                    $state.go('quoteentry', $scope.view.value); // enter quote wizard
                };

                $scope.goToRetrieveQuote = () => {
                    if (!$scope.view.postalCode.aspects.valid || !$scope.quoteId.value) {
                        return;
                    }
                    $state.go('quoteentry', {
                        quoteID: $scope.quoteId.value,
                        postalCode: $scope.view.postalCode.value,
                        shouldUpdateEffectiveDate: true
                    }); // enter quote wizard
                };

                $scope.activateItemRetrieval = () => {
                    $scope.activateItem();
                    $scope.activateRetrieveState();
                };
            }]
        };
    }
];