import EventEmitter from 'eventemitter2';
import BaseTransportService from 'gw-portals-transport-js';

export default ['$http', '$q', 'httpSettings', 'gwAuthTokenService', 'gwInactivityTimerService', 'SessionService_AND', ($http, $q, httpSettings, AuthTokenService, gwInactivityTimerService, SessionService_AND) => {
    const ee = new EventEmitter();
    let currentRequestId;
    const serviceBaseURLs = httpSettings.serviceBaseURLs;

    const baseHeaders = {
        'Content-Type': 'application/json; charset=utf-8'
    };

    let currentLocale = null;
    let portalLanguageVariant = '';

    return {
        send: (xCenterType, serviceEndpoint, method, params) => {
            const headers = angular.copy(baseHeaders);
            if (currentLocale) {
                headers['Accept-Language'] = `${currentLocale}${portalLanguageVariant}`;
            }

            const request = $q.when(AuthTokenService.accessToken).then(token => {
                if (token) {
                    headers.Authorization = `Bearer ${token}`;
                }
                const callStart = new Date().getTime();
                const endpoint = serviceBaseURLs[xCenterType] + serviceEndpoint;
                gwInactivityTimerService.resetInactivityTimer();
                SessionService_AND.resetInactivityTimer(); // ANDIE Reset frontend session before any XHR call
                return BaseTransportService.send(endpoint, headers, method, params)
                    .then((response) => {
                        currentRequestId = response.id;
                        return response.result;
                    })
                    .catch((error) => {
                        console.error(error);
                        const callTime = new Date().getTime() - callStart;
                        return $q.reject({
                            baseError: error,
                            isTimeout: (error.status === 0) && callTime < httpSettings.timeout,
                            isUnauthenticated: (error.status === 401)
                        });
                    });
            });
            ee.emit('send', request, currentRequestId, xCenterType, serviceEndpoint, method, params);
            return request;
        },

        isSessionExpiredReason: (reason) => {
            return reason.isTimeout === true;
        },

        isUserUnauthenticatedReason: (reason) => {
            return reason.isUnauthenticated === true;
        },

        setLocale: (loc) => {
            currentLocale = loc;
        },

        setLanguageVariant: (variant) => {
            portalLanguageVariant = `_${variant}`;
        },

        subscribe: (cb) => {
            ee.on('send', cb);
        },
        unsubscribe: (cb) => {
            ee.off('send', cb);
        }
    };
}];
