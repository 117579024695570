import Transport from './TransportService';
import 'portalConfig';
import 'plugin?auth-type!gw-portals-auth-angular/module';

export default angular.module('gw-portals-transport-angular', ['portal.config', 'edge.platform.auth', 'edge.platform.l10n'])
    .factory('TransportService', Transport)
    .run(['$rootScope', 'LOCALE_CHANGE_EVENT_NAME', 'TransportService', ($rootScope, LOCALE_CHANGE_EVENT_NAME, TransportService) => {
        // TransportService needs to know current locale for Accept-Language header.
        // To avoid tight coupling the event change is broadcast and subscription is done here.
        $rootScope.$on(LOCALE_CHANGE_EVENT_NAME, (event, loc, variant) => {
            if (variant) {
                TransportService.setLanguageVariant(variant);
            }
            TransportService.setLocale(loc);
        });
    }]);

