export default {
    /**
     * A dummy method to mock the functionality of a text extraction service.
     * This dummy method returns a random VIN Number from the list provided.
     * The values from the VIN_ARRAY are the same as the sample data used in the backend.
     * @param {File} vinImage
     * @returns {String}
     */
    /* eslint-disable no-unused-vars */
    extractTextFromVinImage: (vinImage) => {
        const VIN_ARRAY = ['123456789', '1234560987', '1234561122', '1234568520', '1234569870'];
        const RANDOM_NUMBER = Math.floor(Math.random() * VIN_ARRAY.length);
        return VIN_ARRAY[RANDOM_NUMBER];
    }
};