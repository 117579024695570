import _ from 'underscore';
import messageTemplate from 'text!./templates/message-template.html';

const _showMessage = ($filter, $modal, title, message, description, actionObj, iconClass, templateStr, showCancel, options, cancelBtnLabel, radioOptions, transcludedContent, checkboxOptions, showSaveBtn, showTimeoutBtn) => {
    const opts = {
        template: templateStr,
        backdrop: true,
        controller: 'ModalController',
        resolve: {
            model: () => {
                return {
                    title,
                    message,
                    description,
                    showCancelBtn: showCancel,
                    keyboard: 'false',
                    iconClass,
                    cancelBtnLabel: cancelBtnLabel || $filter('translate')('platform.modal.ModalService.Close'),
                    action: actionObj,
                    radioOptions: radioOptions,
                    checkboxOptions: checkboxOptions,
                    showSaveBtn: showSaveBtn,
                    showTimeoutBtn: showTimeoutBtn
                };
            },
            stateProps: () => ({})
        },
        transcludedContent: transcludedContent
    };
    if (options) {
        angular.extend(opts, options);
    }

    return $modal.open(opts);
};


export default ['$modal', '$filter', ($modal, $filter) => {
    return {
        showInfo: (title, message, description, actionLabel, actionBtnClass) => {
            const actionProperties = {
                label: actionLabel || $filter('translate')('platform.modal.ModalService.Ok'),
                btnClass: actionBtnClass || 'gw-btn-primary',
                backdrop: 'static'
            };

            return _showMessage($filter, $modal, title, message, description, actionProperties, 'gw-messageInformationIcon', messageTemplate);
        },

        showEmail: (title, message, description, actionLabel, actionBtnClass) => {
            const actionProperties = {
                label: actionLabel || $filter('translate')('platform.modal.ModalService.Ok'),
                btnClass: actionBtnClass || 'gw-btn-primary',
                backdrop: 'static'
            };

            return _showMessage($filter, $modal, title, message, description, actionProperties, 'gw-messageEmailIcon', messageTemplate);
        },

        showConfirm: (title, message, description, actionLabel, actionBtnClass, cancelLabel, radioOptions) => {
            const actionProperties = {
                label: actionLabel,
                btnClass: actionBtnClass || 'gw-btn-primary'
            };
            cancelLabel = cancelLabel || $filter('translate')('platform.modal.ModalService.Cancel');
            const opts = {
                keyboard: false
            };
            return _showMessage($filter, $modal, title, message, description, actionProperties, 'gw-messageQuestionIcon', messageTemplate, true, opts, cancelLabel, radioOptions);
        },

        showWarningWithSaveButton: (title, message, description, actionLabel, actionBtnClass, cancelLabel, radioOptions, transcludedContent, showCancelButton, checkboxOptions, iconClass) => {
            const actionProperties = {
                label: $filter('translate')(actionLabel) || $filter('translate')('platform.modal.ModalService.savebtn.Yes'),
                btnClass: actionBtnClass || 'gw-btn-primary'
            };
            const opts = {
                keyboard: false
            };
            cancelLabel = cancelLabel || $filter('translate')('platform.modal.ModalService.Cancel');
            showCancelButton = showCancelButton !== undefined ? showCancelButton : true;
            const showSaveBtn = true;
            iconClass = iconClass || 'gw-messageWarningIcon';
            return _showMessage($filter, $modal, title, message, description, actionProperties, iconClass, messageTemplate, showCancelButton, opts, cancelLabel, radioOptions, transcludedContent, checkboxOptions, showSaveBtn);
        },
        showTimeoutWarning: (title, message, cancelLabel, radioOptions, transcludedContent, showCancelButton, checkboxOptions, iconClass) => {
            const showTimeoutBtn = true;        
            const opts = {
                keyboard: false
            };
            cancelLabel = cancelLabel || $filter('translate')('platform.modal.ModalService.ContinueQuote');
            showCancelButton = showCancelButton !== undefined ? showCancelButton : true;
            iconClass = iconClass || 'gw-messageWarningIcon';
            return _showMessage($filter, $modal, title, message, '', false, false, messageTemplate, showCancelButton, opts, cancelLabel, radioOptions, transcludedContent, checkboxOptions, false, showTimeoutBtn);
        },

        showWarning: (title, message, description, actionLabel, actionBtnClass, cancelLabel, radioOptions, transcludedContent, showCancelButton, checkboxOptions, iconClass) => {
            const actionProperties = {
                label: $filter('translate')(actionLabel) || $filter('translate')('platform.modal.ModalService.Yes'),
                btnClass: actionBtnClass || 'gw-btn-primary'
            };
            const opts = {
                keyboard: false
            };
            cancelLabel = cancelLabel || $filter('translate')('platform.modal.ModalService.Cancel');
            showCancelButton = showCancelButton !== undefined ? showCancelButton : true;
            iconClass = iconClass || 'gw-messageWarningIcon';
            return _showMessage($filter, $modal, title, message, description, actionProperties, iconClass, messageTemplate, showCancelButton, opts, cancelLabel, radioOptions, transcludedContent, checkboxOptions);
        },

        showError: (title, message, description, actionLabel, actionBtnClass) => {
            const actionProperties = {
                label: actionLabel || $filter('translate')('platform.modal.ModalService.Close'),
                btnClass: actionBtnClass
            };
            return _showMessage($filter, $modal, title, message, description, actionProperties, 'gw-messageErrorIcon', messageTemplate);
        },

        showSuccess: (title, message, description, actionLabel, actionBtnClass) => {
            const actionProperties = {
                label: actionLabel || $filter('translate')('platform.modal.ModalService.Ok'),
                btnClass: actionBtnClass || 'gw-btn-primary'
            };
            return _showMessage($filter, $modal, title, message, description, actionProperties, 'gw-messageSuccessIcon', messageTemplate);
        },

        showProgressDialog: () => {
            throw new Error('Progress dialog is deprecated in favour of gw-loader directive');
        },

        showTwoPromiseProgressDialog: () => {
            throw new Error('Two Promise Progress Dialog is deprecated in favour of gw-loader directive');
        },

        showFullScreenPopup: (template, stateProps, options) => {
            const opts = {
                template,
                controller: 'ModalController',
                fullScreen: true,
                resolve: {
                    model: () => ({}),
                    stateProps: () => stateProps
                }
            };

            if (options) {
                angular.extend(opts, options);
            }

            return $modal.open(opts);
        },

        /**
         * Shows a simple dialog composed of external template and controller components. It allows user to create
         * new dialog using just a simple functions and not a separate "controller" instance (which is anyway
         * just a same collection of functions). Not recommended for a complex scenarios (use separate controller
         * instead).
         *
         * @param {String} template data rendering template.
         * @param {undefined|Object} data simple scope data. This map is copied to scope "as-is". Useful to provide initial (or data)
         * model values, some basic (non-scope) functions.
         * @param {undefined|Object} scopeFunctions functions to bind to a scope. This map should have only a functions as it's values.
         * Each that function should receive a $scope argument as it's first value. This functions are bound
         * (partially applied) to a context. Result of that partial application is bound to the scope using
         * original function's key.
         * <p> Example. If we have a pair 'someFunction' : function($scope, a, b, c) {}, resulting scope will have
         * a function 'someFunction': function(a, b, c) {};
         * @param {undefined|Function} initializer optional function to invoke on the scope. This function may install watches,
         * binding, etc...
         * @param {undefined|Object} opts  Extra modal options
         *
         * @returns {Object}
         */
        'showSimpleDialog': (template, data, scopeFunctions, initializer, opts = {}) => {
            return $modal.open(angular.extend({
                template,
                backdrop: true,
                controller: ['$scope', ($scope) => {
                    let key;
                    if (data) {
                        for (key in data) {
                            if (data.hasOwnProperty(key)) {
                                $scope[key] = data[key];
                            }
                        }
                    }

                    if (scopeFunctions) {
                        for (key in scopeFunctions) {
                            if (scopeFunctions.hasOwnProperty(key)) {
                                $scope[key] = _.partial(scopeFunctions[key], $scope);
                            }
                        }
                    }

                    if (initializer) {
                        initializer($scope);
                    }
                }]
            }, opts));
        }

    };
}];
