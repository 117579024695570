import template from 'text!./templates/combo-date-picker_AND.html';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';

const MONTHS = 'January,February,March,April,May,June,July,August,September,October,November,December';

export default ['$timeout', ($timeout) => ({
    restrict: 'AE',
    template: template,
    scope: {
        model: '=',
        minDate: '<?',
        maxDate: '<?',
        customID: '@?customId',
        disabled: '<?',
        readonly: '<?'
    },
    controller: ['$scope', ($scope) => {
        const updateValidDates = () => {
            if ($scope.maxDate) {
                $scope.dateDropdown.maxDate = LocalDateUtil.toMidnightDate($scope.maxDate);
            }
            if ($scope.minDate) {
                $scope.dateDropdown.minDate = LocalDateUtil.toMidnightDate($scope.minDate);
            }
        };

        $scope.$watch('dateDropdown.model', (newValue) => {
            if (newValue !== null) {
                $scope.model.value = {
                    year: newValue.getFullYear(),
                    month: newValue.getMonth(),
                    day: newValue.getDate()
                };
            } else {
                $scope.model.value = undefined;
            }
        });

        $scope.formatDate = (value) => (`0${value}`).slice(-2);

        // Init
        $scope.dateDropdown = {
            model: LocalDateUtil.toMidnightDate($scope.model.value),
            months: MONTHS
        };
        updateValidDates();
        $scope.$watch(() => $scope.minDate, updateValidDates);
        $scope.$watch(() => $scope.maxDate, updateValidDates);
    }],
    link: (scope, element) => {
        // Set IDs on each select dropdown
        $timeout(() => {
            if (element[0] && element[0].children && element[0].children[0] &&
                element[0].children[0].children && element[0].children[0].children.length > 0) {
                const selectElements = element[0].children[0].children;
                selectElements[0].id = `${scope.customID}_day`;
                selectElements[1].id = `${scope.customID}_month`;
                selectElements[2].id = `${scope.customID}_year`;
            }
        });
    }
})];