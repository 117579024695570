import templateStr from 'text!./mobileLobSummary.html';

function controller() {
}


export default {
    template: templateStr,
    bindings: {
        offering: '<',
        branchCode: '<',
        lobCode: '&'
    },
    controller: controller
};
