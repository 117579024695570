const PROGRESS_HIDDEN_CLASS = 'gw-progress_hidden';

export default [() => {
    return {
        restrict: 'E',
        template: '<gw-progress-bounce />',
        scope: {},
        link: ($scope, $element) => {
            $element.addClass(PROGRESS_HIDDEN_CLASS);
            setTimeout(() => $element.removeClass(PROGRESS_HIDDEN_CLASS), 1);
        }
    };
}];
