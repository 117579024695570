import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './wizard-action-hashed.scss';
import template from 'text!../templates/generic-tile.html';

export default [() => ({
    restrict: 'E',
    replace: true,
    scope: {
        state: '&',
        flavor: '@'
    },
    transclude: {
        'footer': '?gwTileFooter',
        'actions': '?gwTileAction',
        'content': 'gwTileContent'
    },
    template: cssUtil.hashTemplate(template, styles),
    controller: ['$scope', 'tiles.tilesUtilFunctions', ($scope, tilesUtilFunctions) => {
        $scope.tileType = 'wizardAction';
        $scope.customFlavor = $scope.flavor || 'Default';
        $scope.allow = {
            header: false,
            content: true,
            actions: true,
            footer: true
        };

        $scope.getActiveState = tilesUtilFunctions.getActiveStateFunction($scope.state);
        $scope.getStyle = tilesUtilFunctions.getStyleFunction(styles);
    }]
})];
