import _ from 'lodash';
import assert from 'assert';

import PropertyInfo from './PropertyInfo';
import ScheduledItem from './ScheduledItem';
import DataItem from './DataItem';

export default class Schedule {
    constructor(scheduleDescriptorObj, onChange, scheduleOnChange, itemOnChange) {
        this._descriptor = scheduleDescriptorObj;
        this._onChange = typeof onChange === 'function' ? onChange : _.noop;
        const scheduleChange = typeof scheduleOnChange === 'function' ? scheduleOnChange : _.noop;
        this._scheduleOnChange = () => {
            /*  Wrapper to alert this class that a child has updated, first call the child's callback then this classes
             callback.
             */
            scheduleChange();
            this._onChange();
            this._hasScheduleChanged = true;
        };
        this._itemOnChange = typeof itemOnChange === 'function' ? itemOnChange : _.noop;
        this._hasScheduleChanged = false;

        this._propertyInfos = _(scheduleDescriptorObj.propertyInfos)
            .map((propDefinition) => new PropertyInfo(propDefinition, this))
            .sortBy(propInfo => propInfo.getOrder())
            .valueOf();
        this._scheduledItems = _(scheduleDescriptorObj.scheduleItems)
            .map(si => new ScheduledItem(si, this._propertyInfos, this._scheduleOnChange, this._itemOnChange))
            .sortBy(item => item.getId())
            .valueOf();
    }

    describe() {
        return this._descriptor;
    }

    getDisplayName() {
        return this._descriptor.displayName;
    }

    getPattern() {
        return this._descriptor.patternName;
    }

    getProperties() {
        return this._propertyInfos;
    }

    getScheduledItems() {
        return this._scheduledItems;
    }

    createNewItem() {
        return new ScheduledItem({
            '@deserialization-class': this._descriptor.deserializationClass, // Passed from backend, needs to be set on an item
            itemNumber: null,
            itemData: _.zipObject(this._propertyInfos.map(pi => [pi.getName(), pi.getDefaultValue(this._itemOnChange).describe()]))
        }, this._propertyInfos);
    }

    add(item) {
        assert(item instanceof ScheduledItem);

        this._descriptor.scheduleItems.push(item.describe());
        this._scheduledItems.push(item);

        this._onChange();
        this._hasScheduleChanged = false;
    }

    dataItemIsUniqueInSchedule(item) {
        assert(item instanceof DataItem);

        return this.getScheduledItems().every((scheduledItem) => {
            const samePropertyItem = scheduledItem.findItemByProperty(item);
            return samePropertyItem === item || samePropertyItem.getValue() !== item.getValue();
        });
    }

    remove(scheduledItem) {
        this._descriptor.scheduleItems.splice(scheduledItem._findIn(this._descriptor.scheduleItems), 1);
        this._scheduledItems.splice(this._scheduledItems.indexOf(scheduledItem), 1);

        this._onChange();
        this._hasScheduleChanged = true;
    }

    isValid() {
        return this.getScheduledItems().every(item => item.isValid());
    }

    getChangedStatus() {
        return this._hasScheduleChanged;
    }

    resetChangedStatus() {
        this._hasScheduleChanged = false;
    }
}