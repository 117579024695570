export default function (step) {
    const defaultSkipFn = () => undefined; // default skip function prevents skipping a step
    let _skipOp;

    /**
     *
     * @param {Function} [skipEvaluationFunction] - If no skip function is provided for a step then the default
     *                                              behavior is not to skip
     * @returns {*} Step or skip function
     */
    return function skipIf(skipEvaluationFunction) {
        if (arguments.length === 0) {
            return _skipOp || defaultSkipFn;
        }

        /**
         *
         * @param {Object} model
         *
         * @returns {String}|{Object} The next step or flow to move to in the flow.
         *                            The function should return undefined if there is no branch or goTo transition
         *                            defined for the step or if the transition is a doAction
         */
        _skipOp = skipEvaluationFunction || defaultSkipFn;
        return step;
    };
}