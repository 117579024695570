// Depends on ./dataStructures/ModalStack.js, but that is loaded as angular module
export default () => {
    const $modalProvider = {
        options: {
            backdrop: true, // can be also false or 'static'
            keyboard: true
        },
        $get: ['$injector', '$rootScope', '$q', '$http', '$templateCache', '$controller', '$modalStack', ($injector, $rootScope, $q, $http, $templateCache, $controller, $modalStack) => {
            const $modal = {};

            function getTemplatePromise(options) {
                return options.template ? $q.when(options.template) :
                    $http.get(options.templateUrl, {
                        cache: $templateCache
                    }).then((result) => {
                        return result.data;
                    });
            }

            function getResolvePromises(resolves) {
                const promisesArr = [];
                angular.forEach(resolves, (value) => {
                    if (angular.isFunction(value) || angular.isArray(value)) {
                        promisesArr.push($q.when($injector.invoke(value)));
                    }
                });
                return promisesArr;
            }

            $modal.open = (modalOptions) => {
                const modalResultDeferred = $q.defer();
                const modalOpenedDeferred = $q.defer();

                // prepare an instance of a modal to be injected into controllers and returned to a caller
                const modalInstance = {
                    result: modalResultDeferred.promise,
                    opened: modalOpenedDeferred.promise,
                    close(result) {
                        $modalStack.close(modalInstance, result);
                    },
                    dismiss(reason) {
                        $modalStack.dismiss(modalInstance, reason);
                    }
                };

                // merge and clean up options
                modalOptions = angular.extend({}, $modalProvider.options, modalOptions);
                modalOptions.resolve = modalOptions.resolve || {};

                // verify options
                if (!modalOptions.template && !modalOptions.templateUrl) {
                    throw new Error('One of template or templateUrl options is required.');
                }

                const templateAndResolvePromise =
                    $q.all([getTemplatePromise(modalOptions)].concat(getResolvePromises(modalOptions.resolve)));


                templateAndResolvePromise.then((tplAndVars) => {
                    const modalScope = (modalOptions.scope || $rootScope).$new();
                    modalScope.$close = modalInstance.close;
                    modalScope.$dismiss = modalInstance.dismiss;

                    const ctrlLocals = {};
                    let resolveIter = 1;

                    // controllers
                    if (modalOptions.controller) {
                        ctrlLocals.$scope = modalScope;
                        ctrlLocals.$modalInstance = modalInstance;
                        angular.forEach(modalOptions.resolve, (value, key) => {
                            ctrlLocals[key] = tplAndVars[resolveIter++];
                        });

                        $controller(modalOptions.controller, ctrlLocals);
                    }

                    $modalStack.open(modalInstance, {
                        scope: modalScope,
                        deferred: modalResultDeferred,
                        content: tplAndVars[0],
                        backdrop: modalOptions.backdrop,
                        keyboard: modalOptions.keyboard,
                        windowClass: modalOptions.windowClass,
                        fullScreen: modalOptions.fullScreen,
                        wide: modalOptions.wide,
                        animationType: modalOptions.animationType,
                        fixedScroll: modalOptions.fixedScroll,
                        transcludedContent: modalOptions.transcludedContent
                    });
                }, (reason) => {
                    modalResultDeferred.reject(reason);
                });

                templateAndResolvePromise.then(() => {
                    modalOpenedDeferred.resolve(true);
                }, () => {
                    modalOpenedDeferred.reject(false);
                });

                return modalInstance;
            };

            return $modal;
        }]
    };

    return $modalProvider;
};