import templateStr from 'text!./templates/quote-policy-box.html';

export default ['$filter', ($filter) => {
    let labelIDCounter = 0; // counter to allow for unique labels to be created for radio buttons

    return {
        restrict: 'A',
        scope: {
            buy: '=',
            quote: '=gwQnbQuotePolicyBox',
            loading: '='
        },
        template: templateStr,
        controller: ['$scope', (scope) => {
            scope.labelIDCounter = labelIDCounter++;
            scope.paymentPeriod = 'fullTerm';


            scope.buyLabel = $filter('translate')('quoteandbind.directives.QuotePolicyBox.Buy Now');
            scope.buyInFullLabel = $filter('translate')('quoteandbind.directives.QuotePolicyBox.X Months', {
                termMonths: scope.quote.premium.termMonths
            });

            scope.buyQuote = () => {
                const termInMonths = (scope.paymentPeriod === 'fullTerm') ? scope.quote.premium.termMonths : 1;
                scope.buy(scope.quote, termInMonths);
            };
        }]
    };
}];
