/**
 * Utilities for operations on the local date.
 */
export default {
    /**
     * Converts a jsDate into a DTO.
     * @param {Date|undefined} jsDate
     * @returns {Date|undefined}
     */
    fromJSDate(jsDate) {
        return jsDate ? {
            'year': jsDate.getFullYear(),
            'month': jsDate.getMonth(),
            'day': jsDate.getDate()
        } : jsDate;
    },

    /**
     * Converts a dto into a local midnight.
     * @param {Object|undefined} dto
     * @returns {Date|undefined}
     */
    toLocalMidnight(dto) {
        return dto ? new Date(dto.year, dto.month, dto.day, 0, 1) : dto;
    },

    /**
     * Converts a dto into a local noon time.
     * @param {Object|undefined} dto
     * @returns {Date|undefined}
     */
    toLocalNoon(dto) {
        return dto ? new Date(dto.year, dto.month, dto.day, 12) : dto;
    }
};