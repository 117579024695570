import commonSubFlowDefnFactories from 'edge/quoteandbind/common/config/FlowConfig';

export default {
    transition: function (
        flowDefnFactory, lobSpecificSteps, submissionViewModel, $q, wizardFlowService, LoadSaveService, ViewModelService, AvailabilityService, TranslateService,
        ViewModelCtx, $stateParams, ActionMessageUtil
    ) {

        const fullQuoteFlowDefn = function (step, junction, flow) {
            const commonSubFlowDefns = commonSubFlowDefnFactories(AvailabilityService, LoadSaveService, $q, TranslateService, ViewModelService, ViewModelCtx, ActionMessageUtil);
            const quoteFlow = flow(commonSubFlowDefns.quote);
            const bindFlow = flow(commonSubFlowDefns.bindAndPay);

            const flowDefn = flowDefnFactory(AvailabilityService, LoadSaveService, $q, TranslateService, ViewModelService, ViewModelCtx);
            const contactDetailsFlow = flow(flowDefn.contactDetails);
            const coverablesFlow = flow(flowDefn.coverables);
            // const moreInfoFlow = flow(flowDefn.moreInfo);
            const paymentFlow = flow(flowDefn.legalPaymentDetails);

            flow(commonSubFlowDefns.globalExits);
            step(contactDetailsFlow.entry, true);
            contactDetailsFlow.exits[lobSpecificSteps.contactDetailsComplete].onNext.goTo(coverablesFlow.entry);
            coverablesFlow.exits[lobSpecificSteps.coverablesComplete].onNext.goTo(quoteFlow.entry);
            quoteFlow.exits[lobSpecificSteps.offeringSelected].onNext.goTo(paymentFlow.entry);
            paymentFlow.exits[lobSpecificSteps.legalPaymentDetailsComplete].onNext.goTo(bindFlow.entry);
            // ANDIE
            /* quoteFlow.exits[lobSpecificSteps.offeringSelected].onNext.goTo(moreInfoFlow.entry);
            moreInfoFlow.exits[lobSpecificSteps.moreInfoComplete].onNext.goTo(bindFlow.entry);*/
        };

        const stepToStateMapping = {
            returnHome: () => ['sessionEnded_AND'], // ANDIE
            confirmation: (model) => ['qnbconfirmation', { // ANDIE
                quoteSummary: model.value,
                returnStateInfo: ['sessionEnded_AND']
            }],
            error: (model, args) => ['contactUs', {
                quoteID: (model.quoteID) ? model.quoteID.value : '',
                errorMsg: args.errorMsg
            }]
        };

        const parentWizardState = 'baseTransactionWizard';
        const flowfactory = wizardFlowService(fullQuoteFlowDefn, parentWizardState, stepToStateMapping);

        flowfactory.start(submissionViewModel, $stateParams);

        return $q.resolve();
    }
};