import templateStr from 'text!./templates/radios-binary.html';
/**
 * Directive to show radio buttons bin ary group
 *
 * Examples uf usage:
 *  <div gw-pl-radios-binary
 *      title1="Yes"
 *      title2="No"
 *          >
 *      <input class="someclass1" type="radio" name="somename" id="someid1"/>
 *      <input class="someclass2" type="radio" name="somename" id="someid2"/>
 *  </div>
 *
 * ID attrs for inputs are optional
 */

export default ['$timeout', ($timeout) => {
    let _id = 0; // auto generated counter for ID. Is used if ID attr is not provided for input

    /**
     * Checks id ID attr is provided for element and adds and stores it otherwise to object field
     * @param {HTMLElement} el
     * @param {Object} objToStore
     * @param {String} fieldToStore
     */
    function storeId(el, objToStore, fieldToStore) {
        let id = el.attr('id');
        if (!id) {
            id = `radio-binary${++_id}`; // + increase counter
            el.attr('id', id);
        }
        objToStore[fieldToStore] = id;
    }

    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        template: templateStr,

        // directive has own scope
        scope: {
            'title1': '@',
            'title2': '@'
        },
        link: (scope, element, attrs, ctrls, transcludeFn) => {
            // scope for content inside block with directive
            // has to use scope.$parent
            // (to keep logic provided before wrapping working)
            transcludeFn(scope.$parent, (content) => {
                element.prepend(content);

                // VARS
                const inputs = element.find('input');
                const input1 = inputs.eq(0);
                const input2 = inputs.eq(1);

                // ADDITIONAL CLASSES
                inputs.addClass('gw-no-label');
                input1.addClass('gw-first');
                input2.addClass('gw-second');

                $timeout(() => { // to let Angular finish $digest cycle
                    // IDs
                    storeId(input1, scope, 'inputId1');
                    storeId(input2, scope, 'inputId2');
                }, 0);
            });
        }
    };
}];
