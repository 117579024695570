import SessionUtil from 'edge/quoteandbind/common/util/SessionUtil';
import _ from 'lodash';
import './EditLocation.scss';

class EditLocationCtrl {
    constructor($scope, $state, $q, StateUtils, ViewModelService, ModalService, CPCoverablesService, LocaleService, $filter, originState) {
        const LocationDTOName = 'edge.capabilities.policyjob.lob.commercialproperty.coverables.dto.LocationDTO';
        this.$state = $state;
        this.ModalService = ModalService;
        this.model = StateUtils.activeDataForState(true).model;
        this.jobID = this.model.quoteID ? this.model.quoteID.value : this.model.jobID.value;
        this.sessionId = SessionUtil.getSessionUUID(this.model);
        this.CPCoverablesService = CPCoverablesService;
        this.$filter = $filter;
        this.originState = originState;
        this.phoneMask = LocaleService.getRegionSamplePhoneNumber().replace(/[0-8]/g, '9');

        this.location = _.find(this.model.lobData.commercialProperty.coverables.locations.value, (location) => {
            return location.publicID === $state.params.publicID;
        });
        this.locationView = ViewModelService.create(_.cloneDeep(this.location), 'pc', LocationDTOName);
    }

    save(locationForm) {
        locationForm.submitted = true;
        if (locationForm.invalid) {
            return;
        }
        this._updateLocation(this.locationView.value);
    }

    cancel(locationForm) {
        const isLocationUpdated = false;
        if (locationForm.$pristine) {
            this._backToOrigin(isLocationUpdated);
        } else {
            this._showCancelModal().then(() => this._backToOrigin(isLocationUpdated));
        }
    }

    navigateToLocationView(locationForm) {
        const navigate = () => this.$state.go('cpBuildingWizardViewLocation', {publicID: this.location.publicID});
        if (locationForm.$pristine) {
            navigate();
        } else {
            this._showCancelModal().then(() => navigate());
        }
    }

    _showCancelModal() {
        const modalTitle = 'quoteandbind.cp.buildingsWizard.EditLocation.Cancel Changes?';
        const modalMessage = 'quoteandbind.cp.buildingsWizard.EditLocation.You will lose all the change youve made. Are you sure you want to cancel?';
        const modalConfirm = this.$filter('translate')('quoteandbind.cp.buildingsWizard.EditLocation.Yes');
        const modalCancel = this.$filter('translate')('quoteandbind.cp.buildingsWizard.EditLocation.No');
        return this.ModalService.showWarning(modalTitle, modalMessage, undefined, modalConfirm, undefined, modalCancel).result;
    }

    _backToOrigin(isLocationUpdated) {
        if (this.$state.params.buildingPublicID) {
            const params = {
                publicID: this.$state.params.buildingPublicID,
                isLocationUpdated,
                isCoverageOpen: false
            };

            this.$state.go('cpBuildingWizardViewBuilding', params);
        } else {
            this.$state.go(this.originState.state, this.originState.params);
        }
    }

    _updateLocation(location) {
        const isLocationUpdated = true;
        this.updateLocationPromise = this.CPCoverablesService.updateCPLocation(this.jobID, location, SessionUtil.getSessionUUID(this.model));
        this.updateLocationPromise
            .then((updatedLocation) => {
                const locations = this.model.lobData.commercialProperty.coverables.locations.value;
                const locIndex = _.findIndex(locations, (aLocation) => {
                    return aLocation.publicID === this.$state.params.publicID;
                });
                locations.splice(locIndex, 1, updatedLocation);
                this._backToOrigin(isLocationUpdated);
            })
            .catch(() => {
                const modalTitle = 'quoteandbind.cp.buildingsWizard.EditLocation.Error updating location';
                const modalMessage = 'quoteandbind.cp.buildingsWizard.EditLocation.Please check for errors and try again';
                this.ModalService.showWarning(modalTitle, modalMessage);
            });
    }
}
EditLocationCtrl.$inject = ['$scope', '$state', '$q', 'StateUtils', 'ViewModelService', 'ModalService', 'qnb.cpCoverablesService', 'LocaleService', '$filter', 'originState'];

export default EditLocationCtrl;
