import localDateChooser from './LocalDateChooser';
import LocalDateChooserGroup from './LocalDateChooserGroup';
import DateDropdown_AND from './DateDropdown_AND'; // ANDIE
import ComboDatePicker_AND from './ComboDatePicker_AND'; // ANDIE
import 'gw-portals-viewmodel-angular';
import 'edge/platform/widgets/datetimepicker/module';
import 'edge/platform/time/module';

export default angular
    .module('edge.platform.widgets.ui.time', ['edge.platform.widgets.ui.bootstrap.datetimepicker', 'edge.platform.time'])
    .directive('gwPlLocalDateChooser', localDateChooser)
    .directive('gwPlLocalDateChooserGroup', () => new LocalDateChooserGroup())
    .directive('andPlDateDropdown', DateDropdown_AND) // ANDIE
    .directive('andPlComboDatePicker', ComboDatePicker_AND); // ANDIE