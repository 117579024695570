import _ from 'lodash';
import template from 'text!./templates/currency.html';

export default ['LocaleService', '$filter', (LocaleService, $filter) => {
    const nondigitRe = /[^\d]/g;

    const format = function (number, currencySymbol) {
        return $filter('currency')(number, currencySymbol).replace(currencySymbol, '');
    };

    /**
     * Parse a number out of a string
     *
     * @param {string}  str             String to parse
     * @param {string}  currencySymbol          Currency symbol
     * @param {boolean} [isInt=false]   Whether int is epxected
     *
     * @returns {Number}
     */
    const parse = function (str, currencySymbol, isInt) {
        // Get example format and extract decimal separator
        const example = format(10000000000.11, currencySymbol);
        const decimalSeparator = (example.match(nondigitRe) || []).pop();

        let val = str.trim();
        if (val.charAt(0) === decimalSeparator) {
            val = `0${val}`; // Starting with a fraction separator, prepend with zero
        }

        // Split into whole numbers and decimals
        const digitGroups = val.split(decimalSeparator);
        let decimals = digitGroups.pop();
        if (decimals && digitGroups.length === 0) {
            // If no numbers left then it's not a fraction we've got. Push it back
            digitGroups.push(decimals);
            decimals = null;
        }

        // Compose the float back
        let compiledFloat = digitGroups.join('').replace(nondigitRe, '');
        if (decimals) {
            compiledFloat = `${compiledFloat}.${decimals}`;
        }

        return isInt ? parseInt(compiledFloat, 10) : parseFloat(compiledFloat); // Create a number
    };

    return {
        restrict: 'E',
        template: template,
        replace: true,

        scope: {
            currency: '@?',
            raw: '=',
            initialValue: '<',
            onChange: '&',
            onBlur: '&',
            int: '<',
            model: '=?'
        },
        link: $scope => {
            $scope.$watch('model', model => {
                if (model) {
                    $scope.initialValue = model.value;
                    $scope.onChange = () => (val) => {
                        model.value = (val === '') ? null : val;
                    };
                }
            });
            $scope.$watch('currency', currency => {
                $scope._currencySymbol = angular.isDefined(currency)
                    ? LocaleService.getCurrencySymbol(currency)
                    : LocaleService.getLocaleInfo().currency.symbol;
            });
            $scope.$watch('initialValue', initialVal => {
                if ($scope.raw === true) {
                    $scope._model = {val: initialVal};
                } else {
                    const initial = typeof initialVal === 'number'
                        ? format(parse(String(initialVal)), $scope._currencySymbol, $scope.int)
                        : '';
                    $scope._model = {val: initial};
                }
            });

            $scope._onChange = () => _.attempt(
                $scope.onChange(),
                $scope.raw === true ? $scope._model.val : parse($scope._model.val, $scope._currencySymbol, $scope.int)
            );
            $scope._onBlur = () => {
                if ($scope.raw === true) {
                    _.attempt($scope.onBlur(), $scope._model.val);
                    return;
                }
                const number = parse($scope._model.val, $scope._currencySymbol, $scope.int);
                $scope._model.val = format(number, $scope._currencySymbol);
                _.attempt($scope.onBlur(), number);
            };
        }
    };
}];