import _ from 'lodash';
import MapUtil from 'gw-portals-util-js/MapUtil';
import PageDefn from './_PresentationPageDefinition';

/* Parses flavor metadata. */
function parsePage(pageRef, flavor, pageMeta, pageName) {
    return PageDefn.createPageInfo(flavor, pageRef, pageName, pageMeta);
}

export default {
    /**
     * Creates a representation of the pages belonging to presentation flavor metadata.
     *
     * The following properties and functions are defined on the object:
     * <dl>
     *     <dt>allPages</dt><dd>List of all the pages defined for the flavor</dd>
     *     <dt>has(pageName)</dt><dd>Checks if pageName is defined for the page definitions.</dd>
     *     <dt>get(pageName)</dt><dd>Retrieves a page by its name. Throws Error if page was not found.
     * </dl>
     *
     * @param {Object} metadata information about the flavor.
     * @param {*} flavor reference.
     *
     * @returns {Object}
     */
    createPages: (metadata, flavor) => {

        const pageMap = _.mapValues(metadata, _.partial(
            parsePage,
            (pageName) => {
                if (!_.has(metadata, pageName)) {
                    throw new Error(`Illegal reference to unknown flavor ${pageName}`);
                }
                return () => {
                    return pageMap[pageName];
                };
            },
            flavor
        ));

        return {
            allPages: _.values(pageMap),
            has() {
                return _.has(pageMap, arguments[0]);
            },
            get() {
                const guess = MapUtil.get(pageMap, arguments[0]);
                if (!guess) {
                    throw new Error(`No page with name ${arguments[0]} is defined`);
                }
                return guess;
            }
        };
    }
};