import BaseCtrl from 'edge/platform/base/BaseCtrl';
import Submission from 'edge/quoteandbind/common/models/Submission';
import WizardUtil_AND from '../util/WizardUtil_AND';
import BrandUtil from '../util/BrandUtil';

const fn = function ($scope, $state, $stateParams, $translate, $q, JsonRPCService, brandingData, ScrollService_AND, SessionService_AND) {
    const PWD_POLICY = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    let NOT_NULL;
    let API_PWD_POLICY;
    let API_ERROR;
    $translate(['displaykey.Edge.Web.Api.Model.NotNull',
        'and.quoteandbind.common.directives.templates.confirmation.Activate.PwdPolicy',
        'and.quoteandbind.common.directives.templates.confirmation.Activate.APIError'])
        .then((translations) => {
            NOT_NULL = translations['displaykey.Edge.Web.Api.Model.NotNull'];
            API_PWD_POLICY = translations['and.quoteandbind.common.directives.templates.confirmation.Activate.PwdPolicy'];
            API_ERROR = translations['and.quoteandbind.common.directives.templates.confirmation.Activate.APIError'];
        });

    const ctrl = Object.create(BaseCtrl);

    const setAPIError = (message) => {
        $scope.$applyAsync(() => {
            $scope.apiError = message;
        });
    };

    const sendUserProfileReq = (method, args = []) => JsonRPCService.send('quote', 'profileinfo/user', method, [$scope.submission.sessionUUID, $scope.submission.quoteID].concat(args));

    const validate = () => $q.all([
        sendUserProfileReq('hasUserAccountBeenActivated'),
        sendUserProfileReq('hasAccountSetupExpired'),
        sendUserProfileReq('canUserAccessAccount')
    ])
        .then((result) => {
            $scope.errorAlreadyActivated = result[0];
            $scope.errorExpired = result[1];
            $scope.errorUserHasNoAccess = !result[2];
        });

    let validationPromise;

    ctrl.init = () => {
        
        if ($stateParams.quoteSummary) { // Coming from the journey
            $scope.name = $stateParams.quoteSummary.baseData.accountHolder.firstName
            $scope.brand = BrandUtil.getBrandName(brandingData)
            $scope.submission = new Submission($stateParams.quoteSummary);
            validationPromise = validate();
            ctrl.setConfirmationScreen();
            
        } else if (!$stateParams.quoteID || !$stateParams.postalCode || !$stateParams.dateOfBirth) { // Invalid
            $state.go('contactUs'); // Display technical error screen
            return;
        } else { // Coming straight to confirmation without going through the journey
            const dob = $stateParams.dateOfBirth.split('-');
            JsonRPCService.send('quote', 'quote', 'retrieveBound', [{
                quoteID: $stateParams.quoteID,
                postalCode: $stateParams.postalCode,
                dateOfBirth: {day: dob[0], month: dob[1], year: dob[2]},
                brand_itb: brandingData.toUpperCase()
            }])
                .then((dto) => {
                    $scope.submission = dto;
                    SessionService_AND.init(dto.sessionLengthInMin_itb);
                })
                .then(() => {
                    validationPromise = validate();
                    return validationPromise;
                })
                .then(() => {
                    ctrl.setConfirmationScreen();
                    ctrl.setErrorScreen();
                })
                .catch(() => {
                    $state.go('contactUs'); // Display technical error screen
                });
        }

        $scope.showPassword = false;
        $scope.wizardSteps = WizardUtil_AND.getWizardStepsReadonly();
        $scope.newPwdVM = {
            value: null,
            aspects: {
                inputCtrlType: 'text',
                required: true,
                get valid() {
                    return !!$scope.newPwdVM.value && PWD_POLICY.test($scope.newPwdVM.value);
                },
                get validationMessages() {
                    if (!$scope.newPwdVM.value) {
                        return [NOT_NULL];
                    } else if (PWD_POLICY.test($scope.newPwdVM.value) !== true) {
                        return [API_PWD_POLICY];
                    }
                    return [];
                }
            }
        };
    };

    ctrl.setConfirmationScreen = () => {
        $scope.screen = 'confirmation';
        ScrollService_AND.scrollToTop();
    };

    ctrl.setActivatePortalScreen = () => {
        $scope.screen = 'activatePortal';
        validationPromise.then(ctrl.setErrorScreen);
        ScrollService_AND.scrollToTop();
    };

    ctrl.setActivatedScreen = () => {
        $scope.screen = 'activated';
        ScrollService_AND.scrollToTop();
    };

    ctrl.setTraditionalMotorHoldingScreen = () => {
        $scope.screen = 'TMIHolding';
        ScrollService_AND.scrollToTop();
    }

    ctrl.setErrorScreen = () => {
        if ($scope.errorUserHasNoAccess === true) {
            $scope.screen = 'errorUserHasNoAccess';
        } else if ($scope.errorAlreadyActivated === true) {
            $scope.screen = 'errorAlreadyActivated';
        } else if ($scope.errorExpired) {
            $scope.screen = 'errorExpired';
        }
    };

    ctrl.clearAPIMessage = () => setAPIError(null);

    ctrl.changePassword = (form) => {
        form.submitted = true;
        $scope.clearAPIMessage();
        if (form.valid === false) {
            return;
        }
        $scope.inFlight = true;
        sendUserProfileReq('setupUserAccount', [$scope.newPwdVM.value])
            .then(() => {
                ctrl.setActivatedScreen();
            })
            .catch(() => {
                setAPIError(API_ERROR);
            })
            .finally(() => {
                $scope.inFlight = false;
            });
    };

    return ctrl.create($scope);
};

fn.inject = ['$scope', '$state', '$stateParams', '$translate', '$q', 'JsonRPCService', 'brandingData', 'ScrollService_AND', 'SessionService_AND'];
export default fn;
