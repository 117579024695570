import templateStr from 'text!./templates/binary-choice_AND.html'; // ANDIE

let controlId = 0;

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            titleLeft: '@',
            titleRight: '@',
            valueLeft: '=',
            valueRight: '=',
            model: '=',
            flavor: '@',
            isDisabled: '&',
            customID: '@?customId' // ANDIE
        },
        controller: ['$scope', ($scope) => {
            if ($scope.customID) { // ANDIE Add Custom ID
                $scope.controlId = $scope.customID;
            } else {
                $scope.controlId = `binaryinputctrlx${controlId++}`;
            }
        }]
    };
}];
