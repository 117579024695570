/**
 * Provides browser data from a given User-Agent string
 */

import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);

const isMobile = Boolean(md.mobile());
const isTablet = Boolean(md.tablet());
const isPhone = Boolean(md.phone());

// detect browser type
let browserType = 'desktop';
if (isPhone) {
    browserType = 'phone';
} else if (isTablet) {
    browserType = 'tablet';
}

export default {
    isMobile: isMobile,
    isPhone: isPhone,
    isTablet: isTablet,
    browserType: browserType
};