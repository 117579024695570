import _ from 'underscore';
import FaqDirective from './directives/FaqDirective';
import FaqLink from './directives/FaqLink';
import FaqService from './services/FaqService';
import FaqStateConfig from './config/RouteConfig';
import 'edge/platform/widgets/youtube/module';
import 'edge/platform/filters/module';
import 'gw-portals-featuredetection-angular';
import 'l10nModule';

export default angular.module('edge.faq.app', [
    'edge.platform.widgets.youtube',
    'edge.platform.filters',
    'edge.platform.featuredetection',
    'edge.platform.l10n'
])
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new FaqStateConfig(RootStateConfig);
        _.values(states).forEach((state) => {
            $stateProvider.state(state);
        });
    }])
    .directive('gwFaq', FaqDirective)
    .directive('gwFaqLink', FaqLink)
    .provider('FaqService', FaqService);