/**
 * Provides the current experiment or -1 if no provider was initialised or an error occurred retrieving an experiment value
 *
 */

let useGoogle;
export default {

    useGoogleProvider: (useGoogleProviderFlag) => {
        useGoogle = useGoogleProviderFlag;
    },

    $get: ['gwDemoGuidewireExperiment', 'gwGoogleExperiment', (gwDemoGuidewireExperiment, gwGoogleExperiment) => {
        const adapterProvider = (useGoogle) ? gwGoogleExperiment : gwDemoGuidewireExperiment;

        return {
            /**
             * @param {String} experiment
             * @param {Object} [params]
             * @returns {Promise}
             */
            getExperimentValue(experiment, params) {
                return adapterProvider.getExperimentValue(experiment, params);
            }
        };
    }]
};