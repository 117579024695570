const BRAND_NUMBERS = {
    "itb": "0333 103 0000",
    "tbb": "0330 022 2202",
    "dgt": "0330 022 1000",
    "toy": "0333 103 9950",
    "aut": "0333 103 9978",
    "lex": "0333 103 9960",
    "kin": "0333 103 9984",
    "kfi": "0333 103 9882"
}

export default {
    /**
     * Works out the product code based on brand
     *
     * @param {Object} [brand]
     * @returns {Integer} null if the view model does not have a sessionUUID, sessionUUID otherwise
     */

    getBrandNumber: (brand) => {
        return BRAND_NUMBERS[brand]
    }
}
