export default {
    /**
     * Sets Angular Controller bindings
     *
     * @Example:
     * const setCtrlParam = ComponentUtil.setCtrlParam($ctrl, $scope);
     * setCtrlParam('isRequestInProgress', true);
     *
     * @param {Object} $ctrl
     * @param {Object} $scope
     *
     * @returns {Object}
     */
    setCtrlParam($ctrl, $scope) {
        /**
         * @param {String} paramName
         * @param {String} value
         */
        return (paramName, value) => {
            $scope.$applyAsync(() => {
                $ctrl[paramName] = value;
            });
        };
    },

    /**
    * @Example:
    * const params = {
    *   foo: 'foo',
    *   bar: 'bar'
    * };
    * const setCtrlParams = ComponentUtil.setCtrlParams($ctrl, $scope);
    * setCtrlParam(params);
    *
    * @param {Object} $ctrl
    * @param {Object} $scope
    *
    * @returns {Object}
    */
    setCtrlParams($ctrl, $scope) {
        /**
         * @param {Object} params
        */
        return (params) => {
            $scope.$applyAsync(() => {
                Reflect.ownKeys(params).forEach(key => {
                    $ctrl[key] = params[key];
                });
            });
        };
    }
};