import MockUpUtil from 'gw-portals-util-js/MockUpUtil';
import flowConfigUtil from 'edge/quoteandbind/common/util/FlowConfigUtil';

const mockUpUtil = new MockUpUtil();

export default (AvailabilityService, LoadSaveService, $q, TranslateService, ViewModelService, ViewModelContext, $rootScope) => {
    const translateFn = TranslateService.instant;
    const wrappedLoadSave = flowConfigUtil.wrappedLoadSave(ViewModelService, ViewModelContext);

    const ACTION_MSG = {
        updateSubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to save submission',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to save quote submission.'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Saving quote...'
            }
        }
    };

    const ACTIONS = {
        updateSubmission: ({value: submission}) => {
            return wrappedLoadSave(LoadSaveService.updateDraftSubmissionAndPrimaryLocation, submission);
        }
    };

    return {
        contactDetails: (step, junction) => {
            step('paContactDetails', true)
                .onNext.branch((model) => {
                    // If we come from quick quote go to intermediate step which will save the submission, otherwise
                    // carry on as normal to the next state.
                    return model.quoteID.value ? 'paQuickQuotePostContactDetails' : 'paContactDetailsComplete';
                })
                .skipOnResumeIf(({value: submission}, {displayYourInfoStep}) => {
                    const person = submission.persons[0];
                    const emailMockData = mockUpUtil.getMockData('baseData.accountHolder.emailAddress1');

                    return !displayYourInfoStep &&
                        person.emailAddress1 &&
                        person.emailAddress1 !== emailMockData;
                })
                .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.wizard.step.Your Info'));

            junction('paQuickQuotePostContactDetails')
                .onNext.doAction(ACTIONS.updateSubmission, ACTION_MSG.updateSubmission)
                .thenGoTo('paContactDetailsComplete', 'error');

            junction('paContactDetailsComplete');

        },
        coverables: (step, junction) => {
            step('paPrequalQuestions', true)
                .onNext.goTo('paDriverDetails')
                .skipOnResumeIf(({
                    value: submission
                }, {
                    gatewayportalnewsubmission
                }) =>
                    submission.lobData.personalAuto.preQualQuestionSets && !gatewayportalnewsubmission)
                .isValid((model, isFormValid) => model.aspects.valid && model.aspects.subtreeValid && isFormValid)
                .label(() => translateFn('quoteandbind.wizard.step.Qualification'));

            step('paDriverDetails')
                .onNext.goTo('paVehicleDetails')
                .skipOnResumeIf(({value: submission}) => {
                    const driver = submission.lobData.personalAuto.coverables.drivers[0];
                    const licenceNumberMockData = mockUpUtil.getMockData('lobData.personalAuto.coverables.drivers.driver.licenseNumber');

                    return driver &&
                        driver.licenseNumber &&
                        driver.licenseNumber !== licenceNumberMockData;
                })
                .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.wizard.step.Drivers'));

            step('paVehicleDetails')
                .onNext.goTo('paCoverablesComplete')
                .skipOnResumeIf(({value: submission}) => {
                    const vehicle = submission.lobData.personalAuto.coverables.vehicles[0];
                    const vinMockData = mockUpUtil.getMockData('lobData.personalAuto.coverables.vehicles.vehicle.vin');

                    return vehicle &&
                        submission.baseData.periodStatus === 'Quoted' &&
                        vehicle.vin !== vinMockData;
                })

                .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.wizard.step.Vehicles.' + $rootScope.productCode));

            junction('paCoverablesComplete');
        },
        moreInfo: (step, junction) => {
            step('paPolicyInfo', true)
                .onNext.goTo('paMoreInfoComplete')
                .skipOnResumeIf(({value: submission}) => {
                    const accountHolder = submission.baseData.accountHolder;
                    return submission.baseData &&
                        accountHolder &&
                        accountHolder.emailAddress1 && (
                        accountHolder.homeNumber ||
                            accountHolder.workNumber ||
                            accountHolder.cellNumber
                    );
                })
                .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
                .label(() => translateFn('quoteandbind.wizard.step.Policy Information'));

            junction('paMoreInfoComplete');
        }
    };
};
